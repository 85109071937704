<template>
  <div>
    <div
      class="
        px-4
        py-5
        mx-auto
        sm:max-w-xl
        md:max-w-full
        lg:max-w-screen-xl
        md:px-24
        lg:px-8
      "
    >
      <div class="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="FlirtTool"
          title="FlirtTool"
          class="inline-flex items-center"
        >
          <img
            src="https://funnyflirts.net/img/logo-light.6ebe202c.png "
            class="h-16"
          />
        </a>

        <!-- Mobile menu -->
        <div class="lg:hidden"></div>
      </div>
    </div>
    <hr style="border: 1px inset" />
    <div class="flex justify-center">
      <div class="flex-row">
        <nav class="flex mt-10" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                to="/help-center"
                class="
                  inline-flex
                  items-center
                  text-sm
                  font-medium
                  text-gray-700
                  hover:text-gray-900
                  dark:text-gray-400 dark:hover:text-white
                "
              >
                <svg
                  class="mr-2 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Help Center
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  to="/safety-and-privacy"
                  class="
                    ml-1
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-900
                    md:ml-2
                    dark:text-gray-400 dark:hover:text-white
                  "
                  >Safety and privacy</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <a
                  href="#"
                  class="
                    ml-1
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-900
                    md:ml-2
                    dark:text-gray-400 dark:hover:text-white
                  "
                  >Confirming Your Age</a
                >
              </div>
            </li>
          </ol>
        </nav>
        <!-- Guide block start -->
        <div class="flex xl:flex-row justify-start mx-3">
          <div>
            <div
              class="
                w-full
                max-w-3xl
                text-left
                font-sans
                tracking-wider
                leading-8
              "
            >
              <p class="text-3xl font-black">Confirming your age</p>
              <p>
                If you were sent to this article directly from the app, we have
                reason to believe that you may be underage. Grindr is strictly
                for those who are legal adults at least 18 years old.
                Accordingly, we have suspended your account until you can verify
                that you are an adult. Once you do so, we’ll restore your access
                to your Grindr account. To verify your age, please fill out this
                form, and follow the relevant instructions below. If you created
                your account with an email address and password, please include
                the following with your form submission: The email address
                associated with your Grindr account, A clear photo of you
                holding a piece of paper with that email address handwritten on
                it, A clear photo of you holding a valid, government-issued ID
                showing your date of birth, and A screenshot of the ban message
                you've received. If you created your account via a third-party
                (ie. Apple, Google, or Facebook), please include the following
                with your form submission: A clear photo of you holding a piece
                of paper with your device ID handwritten on it (you can find
                your device ID listed on the screen you currently see when
                opening the Grindr app), A clear photo of you holding a valid,
                government-issued ID showing your date of birth, and A
                screenshot of the ban message you've received. We appreciate
                your cooperation. Please note that your government ID is
                immediately deleted from our system after we’ve verified your
                age. For more information, feel free to visit our Terms and
                Conditions of Service, Privacy Policy and Community Guidelines.
              </p>
            </div>
          </div>
        </div>
        <!-- Guide block end -->
      </div>
    </div>
    <div class="mb-14"></div>
    <page-footer />
  </div>
</template>
<script>
import pageFooter from "../../pageFooter.vue";
export default {
  components: { pageFooter },
};
</script>