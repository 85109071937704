import store from '../store'
import { getLocations } from './helpers'
import { UPDATE_USER_LOCATION } from '../queries/auth'
import { flirttool } from '../v-apollo'
let apollo = flirttool.apolloClient;

export class Location {

   async geoLocate() {
     await this.setCoordinates()
     await this.reverseGeocode()
     store.commit('setCurrentLocation',this)
     await this.sendLocationToServer((data)=>{
      console.log(data)
     })
   }


   async updateLocation() {
     let location = store.getters.currentLocation 
     console.log(location)
    let currentLat = location.lat
    let currentLng = location.lng
    await this.setCoordinates()
    if( currentLat != this.lat 
     || currentLng != this.lng
    ) {
      console.log("reverse geo again")
      await this.reverseGeocode()
      await this.sendLocationToServer((data)=>{
        console.log(data)
      })
    }else{
      // console.log('same user loc')
    }
   }

   async setUpdateLocationInterval() {
    let locationInterval =  store.getters.updateLocationInterval
    if(!locationInterval) {
      let updateLocationInterval = setInterval( async ()=>{
        await this.updateLocation()
        store.commit('setCurrentLocation',this)
       },1000 * 60 )
       store.commit('setUpdateLocationInterval',updateLocationInterval)
     }
   }

   async sendLocationToServer(callback) {

    let userLocationDetails = { ... store.getters.currentLocation }
    userLocationDetails.country_name = userLocationDetails.location.country.long_name
    let short_name = userLocationDetails.location.country.short_name
    let flag = getLocations().find(c => c.code == short_name).flag
    userLocationDetails.location.country_flag = flag
    apollo
    .mutate({
        mutation: UPDATE_USER_LOCATION,
        variables: {
          input:{
            lat:String(store.getters.currentLocation.lat),
            lng:String(store.getters.currentLocation.lng),
            location:JSON.stringify(userLocationDetails)
          }
        },
    })
    .then(({ data }) => {
        // snippet to clear the cookie
        // document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        callback({ data: data, fail: false })
    })
    .catch((e) => {
        callback({ data: e, fail: true })
        console.log(e);
    });
   }
  
    async setCoordinates() {
      return new Promise(async (resolve,reject)=>{
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((data)=>{
            console.log('GEO DATA FROM HTML API')
            let location = {
              lat: data.coords.latitude,
              lng: data.coords.longitude
            }
            this.source = 'HTML geolocation API'
            this.lat = data.coords.latitude
            this.lng = data.coords.longitude
            resolve(location)
          }, async ()=>{
            let location = await this.googleGeolocation()
            resolve(location)
            console.log('GEO DATA FROM GOOGLE API')
          });
        }else{
          let location = await this.googleGeolocation()
          resolve(location)
          console.log('GEO DATA FROM GOOGLE API')
        }
      })
    }



    async googleGeolocation(){
      let response = await fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCkktP7eEWHs8FJJ6hFe10co5etxojmnKA',{ 
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          considerIp:true
        })
      })
      let data = await response.json()
      let location = data.location
      this.lat = location.lat
      this.lng = location.lng
      this.accuracy= data.accuracy
      this.source = 'Google Geolocation API'
      console.log(location)
      return location
    }

    async reverseGeocode() {
      let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.lat},${this.lng}&key=AIzaSyCkktP7eEWHs8FJJ6hFe10co5etxojmnKA`,{ 
         method: 'POST',
       })
      let data = await response.json()
      let parsedAddress = this.parseAddress(data)
      this.location = parsedAddress
      return true
   }

   parseAddress(response) {
    let results = response.results
    let parseAddress = results.reduce((accumulator,obj)=> {
      let componentCountainsCountry = obj.types.includes('country');
      let componentCountainsPostalCode = obj.types.includes('postal_code');

      if(componentCountainsCountry) {
        let address_components = obj.address_components
        let countryComponenet =  address_components.find(c => c.types.includes('country'))
        accumulator.country = countryComponenet
      }

      if(componentCountainsPostalCode) {
        let address_components = obj.address_components
        let postalComponenet =  address_components.find(c => (c.types.includes('postal_code') && c.types.length == 1 ))
        if(postalComponenet) {
          accumulator.postal_code = postalComponenet.short_name
          accumulator.street_address = obj.formatted_address
        }
      }
        return accumulator
    },{
      country:null,
      postal_code:null,
      street_address:null
    })
    return parseAddress
  }
}