<template>
	<div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-3   relative">
		
		<center>
			<div class="flex justify-center items-center h-screen">
				<h1 class="text-gray-50 py-6 uppercase lg:block hidden">Set up profile picture</h1>
				<div
					v-if="showError"
					class="
						bg-red-100
						dark:bg-gray-700 dark:border-gray-700
						border-red-900
						text-pink-700
						p-4
					"
					role="alert"
				>
					<p class="font-bold text-white">{{$t('picnotupdated')}}</p>
					<p>{{$t(pleaseupdatepicture)}}</p>
				</div>
			
				<div
					class=" dark:bg-gray-700   rounded-sm  bg-accent text-accent-content"
					style="border-radius: 0px"
				>
					<center class="felx justify-center mx-auto items-center">
						<div
							class="avatar mt-5 cursor-pointer"
							@click="$refs.profile_pic.click()"
						>
							<div class="rounded-btn w-36 h-36 ring ring-gray-100">
								<img :src="profileUrl" />
							</div>
						</div>
					</center>
					<div class="card-body"><p class="dark:text-gray-200 -mt-10 font-sans text-white uppercase text-lg">
						{{$t('uploadphoto')}}
						</p>
					
						<input
							style="display: none"
							ref="profile_pic"
							@change="fileAdded"
							type="file"
							class="hidden"
						/>
						<div class="mt-0">
							<div v-if="loadingUpload" class="mt-3 lds-ellipsis">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</center>
	</div>
</template>
<script>
import {
	// LOGGEDIN_DATA, 
	UPDATE_PROFILE_PIC
} from '../../queries/auth';
// import store from '../../store';
export default {
	data: () => ({
		profileUrl: require("@/assets/profilesetup/UploadProfilePic1.png"),
		fileUploaded: false,
		showError: false,
		loadingUpload: false,
	}),
	methods: {
		getBlobUrl(file) {
			var fileReader = new FileReader();
			const url = fileReader.readAsDataURL(file);
			fileReader.onload = (e) => {
				this.profileUrl = e.target.result
			};
			return url;
		},
		fileAdded() {
			this.showError = false;
			this.fileUploaded = true
			const profile_pic = this.$refs.profile_pic.files[0];
			this.getBlobUrl(profile_pic)
			this.uploadPic(profile_pic)
		},
		nextStep() {
			if (this.fileUploaded) {
				this.$emit('movestep')
			} else {
				this.showError = true
			}
		},
		uploadPic(file) {
			console.log(file)
			this.loadingUpload = true
			this.$apollo
				.mutate({
					mutation: UPDATE_PROFILE_PIC,
					variables: { file: file }
				})
				.then(({ data }) => {
					if (data) {
						// update state profile pic and step
						const newUrl = data.updateProfilePic;
						let user = this.$store.state.core.user;
						user.profilePic = newUrl;
						user.profileSetupStep = 1;
						console.clear()
						console.warn(newUrl,"new URL")
						this.$store.commit("setUserData", user)
						this.fileUploaded = true
					}
				}).finally(() => {
					this.loadingUpload = false
				}).then(()=>{
					this.nextStep()
				});
		},
	},
	created() {

	},
}
</script>