<template>
  <div class="px-0 cursor-pointer">
    <div
      class="w-48 text-gray-900 bg-transparent   border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      @click="$router.push('/@' + user.username)"
    ><ul class=" max-w-md list-inside gap-y-4 text-gray-500 dark:text-gray-400">
    <li class="flex items-middle">
        <figure>
          <img
            :src="
              user.profilePic
                ? getURL(user.profilePic)
                : require('@/assets/pholder.webp')
            "
            class="rounded-full h-6 w-6 gap-y-4 " style="object-fit: cover;"
          />
        </figure> 
        <h2
          class="font-sans text-sm text-left card-title align-middle px-3 py text-gray-505 dark:text-gray-50"
          v-if="user.base_profile"
        >
          {{ user.base_profile.firstName }} {{ user.base_profile.lastName }}
          <p class="mb-5 -mt-4 text-2xs dark:text-gray-300">
            {{ user.userLocation }}
          </p>
        </h2>
        <h2 class="card-title" v-else></h2>
    </li>
   
    
   
</ul>
      
    </div>
    <div
      class="mt-2 text-left shadow-noneh-30 w-16 rounded-xl bg-pink-50 dark:bg-gray-800"
      @click="$router.push('/@' + user.username)"
    >
      
    </div>
    <!-- start -->
    <div class="rand_bottom_bar" v-if="page == 'find_matches'">
      <button
        class="btn waves-effect like"
        data-userid="22195"
        data-ajax-post="/useractions/like"
        data-ajax-params="userid=22195&amp;username=u_a9eed3c96"
        data-ajax-callback="callback_like"
        @click="likeProfile"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-pink-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <button
        class="btn waves-effect dislike _dislike_text22195"
        data-userid="22195"
        id="dislike_btn"
        data-ajax-post="/useractions/dislike"
        data-ajax-params="userid=22195"
        data-ajax-callback="callback_dislike"
        @click="unlike()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-blue-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <!-- <div
      class="rand_bottom_bar"
      v-else-if="page == 'likes' || page == 'matches'"
    >
      <button
        class="btn waves-effect like"
        id="like_btn"
        @click="page == 'likes' ? unlike() : unmatch()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-blue-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div> -->

    <div class="rand_bottom_bar" v-else-if="page == 'disliked'">
      <button
        class="btn waves-effect like"
        id="like_btn"
        @click="removeDisliked()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-red-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <!-- end -->
  </div>
</template>

<script>
import { DISLIKE_PROFILE, LIKE_PROFILE } from "@/queries/matches";
import { getURL } from "@/utils/helpers";
export default {
  data: () => ({}),
  props: {
    user: Object,
    page: String,
    geocode: Boolean,
  },
  methods: {
    getURL,
    unlike() {
      this.$emit("popIndex");
      this.$apollo.mutate({
        mutation: DISLIKE_PROFILE,
        variables: {
          user: this.user.username,
        },
      });
    },
    unmatch() {
      this.$emit("popIndex");
    },
    likeProfile() {
      this.$emit("popIndex");
      this.$apollo
        .mutate({
          mutation: LIKE_PROFILE,
          variables: {
            user: this.user.username,
          },
        })
        .then(() => {
          console.log("requset completed");
        })
        .catch((e) => {
          // catch exeption
          console.error(e);
        });
    },
    disLikeProfile() {
      this.$emit("popIndex");
    },
    removeDisliked() {
      this.$emit("popIndex");
    },
  },
};
</script>
