import { HAS_FLIRTONS } from "./queries/matches";
import store from './store'

export const updateFlirtonsAvailable = async (apollo) =>{
  try {
    let has_flirtons = await  apollo.query({
      query: HAS_FLIRTONS,
      fetchPolicy: "network-only",
    })
   let flirtons = has_flirtons.data.hasFlirtOns
   store.commit("setFlirtonsAvailable",flirtons)
   return flirtons
  } catch(e) {
    return false
  }
}