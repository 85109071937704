<template>
  <div class="text-left font-medium mt-4" v-if="!($mq == 'sm')">
    <!-- <credits-chip /> -->
    <!-- <router-link
      to="/community"
      class="
        pt-1
        mt-3
        dark:bg-gray-700 dark:text-gray-700
        w-56
        hover:text-white
        mb-2
        text-base
        font-semibold
        shadow-none
        btn
        text-pink-100
        bg-gray-100
        dark:text-white
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-pink-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
          clip-rule="evenodd"
        />
      </svg>
      <span class="text-pink-500 dark:text-white">Community</span> </router-link
    ><br /> -->
    <!-- <router-link
      to="/flirts"
      class="
        pt-1
        mt-3
        dark:bg-gray-700 dark:text-gray-700
        mb-1
        w-56
        text-left text-base
        hover:text-white
        text-gray-50
        bg-gray-100
        font-semibold
        shadow-none
        btn
        dark:text-white
      "
    >
      <img
        src="@/assets/infinit11111y.png"
        class="h-6 w-7"
        viewBox="0 0 20 20"
      />

      <span class="text-gray-600 px-1 dark:text-white"
        >Infinity</span
      > </router-link -->
    <br />
    <!-- <router-link
      to="/"
      class="
        pt-1
        mt-3
        cursor-not-allowed
        dark:bg-gray-700 dark:text-gray-700
        mb-1
        w-56
        text-left text-base
        hover:text-white
        text-gray-50
        bg-gray-100
        font-semibold
        shadow-none
        btn
        dark:text-white
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-pink-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
          clip-rule="evenodd"
        />
      </svg>

      <span class="text-gray-600 px-1 dark:text-white">Shop</span> </router-link
    > -->
    <br />
    <!-- <router-link
      to="/events"
      class="
        pt-1
        mt-3
        dark:bg-gray-700 dark:text-gray-700
        mb-1
        w-56
        text-left text-base
        hover:text-white
        text-gray-50
        bg-gray-100
        font-semibold
        shadow-none
        btn
        dark:text-white
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-pink-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
        />
      </svg>

      <span class="text-gray-600 px-1 dark:text-white"
        >FlirtTool Live</span
      > </router-link
    ><br /> -->

    <!-- <router-link
      to="/"
      class="
        pt-1
        mt-3
        dark:bg-gray-100 dark:text-gray-700
        cursor-not-allowed
        mb-5
        w-56
        hover:text
        text-base text-pink-100
        bg-gray-100
        font-semibold
        shadow-none
        btn
        hover:text-white
        dark:text-white
        hover:bg-gray-50
      "
    >
      <img
        src="@/assets/indaddylogo1.png"
        class="h-5 w-5"
        viewBox="0 0 20 20"
      />

      <span class="text-gray-700 dark:text-gray-500">nDaddy</span>
    </router-link> -->

    <div v-for="(x, idx) in sideBarItems" :key="idx" class="py-1 mx-2 font-sans text-sm">
      <a @click="$router.push(x.to)">
        <svg
          :style="{ 'color: blue': x.to == $route.path }"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          class="inline-block w-6 h-6 mr-2 stroke-current text-gray-500"
          :class="{ 'text-pink-500': x.to == $route.path }"
          v-html="x.svg"
        ></svg>
        <span
          class="font-sans text-gray-500"
          :class="{ 'text-pink-500': x.to == $route.path }"
        >
          {{ x.text }}
        </span>
      </a>
    </div>
    <div class="divider divide-opacity-5 mx-2"></div>
    <div v-for="(x, idx) in sideBarItemsExtra" :key="idx" class="py-1 mx-2">
      <a style="cursor: pointer" @click="$router.push(x.to)">
        <svg
          :class="{ 'text-pink-500': x.to == $route.path }"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          class="inline-block w-6 h-6 mr-2 stroke-current text-gray-500"
          v-html="x.svg"
        ></svg>
        <span
          class="text-gray-500 font-sans"
          :class="{ 'text-pink-500': x.to == $route.path }"
        >
          {{ x.text }}
        </span>
      </a>
    </div>
  </div>
</template>
<script>
//import creditsChip from "./creditsChip.vue";
export default {
  //components: { creditsChip },
  data: () => ({
    sideBarItems: [
      {
        to: "/find-matches",
        text: "Find Matches",
        svg: `<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>`,
      },
      {
        to: "/matches",
        text: "Matches",
        svg: `  <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />`,
      },

      {
        to: "/visitors",
        text: "Visitors",
        svg: `
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />`,
      },
    ],
    sideBarItemsExtra: [
      // 	{
      // 		to: "/",
      // 		text: "Gifts",
      // 		svg: `
      //   <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
      //   <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />`,
      // 	},
      {
        to: "/likes",
        text: "Likes",
        svg: `
          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
        `,
      },
      {
        to: "/liked",
        text: "Hot",
        svg: `
          <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />`,
      },
      {
        to: "/dislikes",

        text: "Cold",
        svg: `
          <path text-pink-500 fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />`,
      },
    ],
  }),
};
</script>
