<template>
  <div class="flex  lg:p-10    justify-center">
    <div class="col-span- bordered  sm:mx-5 lg:mx-0 globalcompress lg:-mt-24 sm:-mt-20 lg:pl-5 xl:pl-24"
      style="width: 1500px">
      <popup :showing="blockD" @close="blockD = false">
        <h2 class="py-10 text-xl font-bold text-gray-900">
          Are you sure you would like to block
          <span>@{{ userProfile.user.username }}</span>
        </h2>
        <div class="flex flex-row justify-center gap-10">
          <button class="mt-3 bg-pink-100 btn" @click="block">Yes</button>

          <button class="mt-3 bg-pink-100 btn" @click="blockD = false">No</button>
        </div>
      </popup>
      <popup :showing="showing" @close="showing = false">
        <h2 class="text-xl font-bold text-gray-900">Enter you reason for this report</h2>
        <textarea v-model="reportReason" class="h-32 mt-5 border-pink-200 input focus:border-pink-600" />
        <button class="mt-3 bg-pink-100 btn" @click="submitReport">Submit Report</button>
      </popup>
      <notification-bar />
      <!-- layout fix -->
      <div class="bg-yellow-0 dark:bg-gray-600 hidden lg:block">

        <!-- End of Navbar -->

        <div class="container mx-auto my-5 p-6">
          <div class="md:flex no-wrap md:-mx-2 ">
            <!-- Left Side -->
            <!-- <div style="height: 879px;"
              class=" items-center border-0    py-4 justify-center hidden h-full pl-1 pr-1 mt-0  dark:bg-gray-500 w-30 md:w-24 md:mb-0 bg-white bg lg:block md:hidden">
              <new-side-selector />

            </div> -->
            <div class="w-full md:w-3/12 md:mx-2 mt-5">
              <!-- Profile Card -->
              <div class="bg-white dark:bg-gray-600 p-3">
                <!--  -->
                <div class="bg-orange-100 rounded-xl shadow-lg border-white border-4 p-1 col-span-3"
                  style="background-color: #F3E5CD;">
                  <div class=" shadow-none rounded-xl  bg-orange-200">

                    <YellowUserAvartar />
                    <div class="-mt-5 py-2 justify-center text-center bg-white">
                      <span class="uppercase py-2">Notification</span>

                    </div>
                    <div class="text-center bg-orange-100 mt-2 h-32">

                      <YellowNotify />
                      <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
                        <div></div>
                        <div></div>
                      </div>

                    </div>

                  </div>


                  <div class="text-center bg-orange-100 mt-44 h-96 overflow-hidden">
                    <div class="py-2 justify-center text-center bg-white">
                      <span class="uppercase py-2 ">Visitors</span>

                    </div>
                    <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
                      <div></div>
                      <div></div>
                    </div>
                    <!-- <SideHomeSelector /> -->
                    <YellowsideHomeSelector />

                  </div>
                </div>
              </div>
              <!-- End of profile card -->
              <div class="my-4"></div>
              <!-- Friends card -->

              <!-- End of friends card -->
            </div>
            <!-- Right Side -->
            <div class="w-full md:w-9/12 mx-2 mt-6 h-64">
              <!-- Profile tab -->
              <!-- About Section -->
              <div class="bg-white dark:bg-gray-700 p-3 shadow-sm rounded-sm ">
                <div style="height: 1110px; width: 89%; "
                  class="flex items-top justify-center overflow-auto  w-full pl-1 pr-1  md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">
                  <div v-if="loadingProfile">
                    <div class="h-56 mx-auto mt-5 bg-gray-500 rounded-md animate-pulse"></div>
                    <div class="space-y-2">
                      <div class="h-4 mt-3 bg-gray-500 rounded animate-pulse"></div>
                    </div>
                  </div>
                  <main class="profile-page" style="width: 100%;">
                    <!-- profile card -->

                    <div
                      class="bg-gray-100 shadow inset-10 shadow-inner  dark:bg-gray-700 overflow-visible font-sans h-full w-full  grid-cols-1 grid justify-center items-center"
                      style="background-image: url('https://images.funnyflirts.net/yellowchatpage.jpg'); background-size: cover;  background-position: center;">


                      <div
                        class="card w-96 mt-5 shadow-2xl border-2  grid-cols-1 mx-auto bg-white dark:bg-gray-600   hover:shadow">
                       <div class="grid grid-cols-2 ">
                        <div class="flex justify-center mt-5"><img  @click="reportUser" src="@/assets/mazu/report.png" class="h-10" alt=""></div>
                        <div  class="flex justify-center mt-5"><img @click="blockD = true" src="@/assets/mazu/block.png"  class="h-10" alt=""></div>
                       </div>
                        <div class="flex flex-col items-center py-3 space-y-5 bg-white rounded-b-3xl dark:bg-gray-700">
                          <img
                            class="object-cover border-2  z-50   border-pink-300 rounded-full cursor-pointer hover:border-pink-500 w-48 h-48 profileImg scale-125 hover:scale-75 ease-in duration-50"
                            @click="profileClicked" :src="getURL(userProfile.user.profilePic)" />


                        </div>
                        <div class="text-center mt-2 text-3xl  font-medium">
                          <p
                            class="px-5 font-sans text-sm text-center text-yellow-500 uppercase font-semi-bold text-md dark:text-white card-title">
                            <span class="font-sans text-3xl font-bold text-yellow-500"
                              v-if="userProfile.user.base_profile">
                              {{ userProfile.user.base_profile.firstName }}
                              {{ userProfile.user.base_profile.lastName }}</span>
                          </p>
                        </div>
                        <div class="text-center mt-1 font-light dark:text-white text-lg">@ USERNAME</div>
                        <div class="text-center font-normal dark:text-white text-lg"> Location :</div>
                        <!-- <div class="text-center font-normal dark:text-white text-lg"> Sexual Preferance :</div> -->
                        <div class="px-6 text-center mt-2 font-light text-sm">

                        </div>
                        <hr class="mt-8">
                        <!-- <div class="flex bg-transparent bg-opacity-0 p-4">
                          <div class="w-1/2 text-center">
                            <router-link class=" " :to="getChatRoute()"> <span
                                class="font-bold text-pink uppercase font-sans text-pink-500  ">

                                Message</span></router-link>
                          </div>
                          <div class="w-0 border border-gray-300">

                          </div>
                          <div class="w-1/2 text-center">
                            <span class="font-bold btn btn-sm dark:text-white border-0" @click="sendPoke"> POKE</span>
                          </div>
                        </div> -->
                      </div> <br>
                      <div
                        class="card w-96 shadow-none bg-opacity-0 border-0 -mt-56 grid-cols-1 mx-auto flex flex-wrap justify-center bg-white dark:bg-gray-600  "
                        style="grid-row-start: 2;">




                        <hr class="mt-8">
                        <div class="flex bg-transparent bg-opacity-0 p-4">
                          <div class="w-1/2 text-center">
                            <router-link class=" " :to="getChatRoute()"> <span
                                class="font-bold text-pink flex justify-center uppercase font-sans sha text-pink-500  ">

                                <img src="@/assets/mazutheme/msg.png" class="h-28" alt="">
                              </span></router-link>
                          </div>
                          <div class="w-0 border border-gray-300">

                          </div>
                          <div class="w-1/2 text-center">
                            <span class="font-bold btn btn-sm dark:text-white border-0" @click="sendPoke"> <img
                                src="@/assets/mazutheme/poke.png" class="h-28" alt=""></span>
                          </div>
                        </div>
                        <div class="flex bg-white rounded-full shadow-inner border-4 w- p-4">
                          <div class="w-1/2 text-center">
                            <span class="font-bold text-pink flex justify-center uppercase font-sans sha text-yellow-500  ">

                              <div class="mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                    class="font-sans dark:text-white" v-text="userProfile.likes"></span></span><span
                                  class="text-sm text-blueGray-400 dark:text-white">LIKES</span>
                              </div>
                            </span>
                          </div>
                          <div class="w-0 border border-gray-300">

                          </div>
                          <div class="w-1/2 text-center">
                            <span class="font-bold btn btn-sm dark:text-white border-0">
                              <div class="mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                    class="font-sans dark:text-white" v-text="userProfile.visits"></span></span><span
                                  class="text-sm text-blueGray-400 uppercase dark:text-white">Visits</span>
                              </div>
                            </span>
                          </div>
                        </div>

                      </div>

                    </div>
                    <!--  -->


                    <!--  -->
                    <!-- profile card -->
                    <section class="relative py-4 bg-blueGray-200">
                      <div class="container mx-auto px-4">
                        <div
                          class="relative flex flex-col min-w-0 break-words border-4 border-gray-200 bg-white dark:bg-gray-600 w-full mb-6 shadow-2xl rounded-3xl -mt-64">
                          <div class="px-6">
                            <!-- <div class="flex flex-wrap justify-end">
                              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                                <div class="relative">
                                  <img alt="..."
                                    src="https://demos.creative-tim.com/notus-js/assets/img/team-2-800x800.jpg"
                                    class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px">
                                </div>
                              </div>
                              <div class="w-full flex flex-wrap lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                                <div class="flex flex-col items-center col-span-1 px-3">
                                  <button class="mt-2" @click="blockD = true">
                                    <img src="@/assets/icons/Block.png" alt="" class="h-9"><span @click="blockD = true"
                                      class="font-sans text-sm text-pink-200 text-semi-bold dark:text-pink-50 card-title">Block</span>
                                  </button>
                                </div>
                                <div class="flex flex-col items-center col-span-1 px-3">
                                  <button class="mt-2" @click="reportUser">
                                    <img src="@/assets/icons/Report.png" class="h-9 pl-2" alt=""><span @click="reportUser"
                                      class="font-sans text-sm text-pink-200 text-semi-bold dark:text-pink-50 card-title">Report</span>
                                  </button>
                                </div>

                              </div>
                              <div class="w-full lg:w-4/12 px-4 rounded-3xl lg:order-1">
                                <div class="flex justify-center py-4 lg:pt-4 pt-8">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="pink" class="w-24 h-24">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                  </svg>


                                  <div class="mr-4 p-3 text-center">
                                    <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                        class="font-sans dark:text-white" v-text="userProfile.likes"></span></span><span
                                      class="text-sm text-blueGray-400 dark:text-white">LIKES</span>
                                  </div>
                                  <div class="mr-4 p-3 text-center">
                                    <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                        class="font-sans dark:text-white" v-text="userProfile.visits"></span></span><span
                                      class="text-sm text-blueGray-400 uppercase dark:text-white">Visits</span>
                                  </div>
                                 
                                </div>
                              </div>
                            </div> -->
                            <!-- <div class="text-center mt-12">
                        <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                          Jenna Stones
                        </h3>
                        <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                          <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                          Los Angeles, California
                        </div>
                        <div class="mb-2 text-blueGray-600 mt-10">
                          <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>Solution Manager - Creative Tim
                          Officer
                        </div>
                        <div class="mb-2 text-blueGray-600">
                          <i class="fas fa-university mr-2 text-lg text-blueGray-400"></i>University of Computer Science
                        </div>
                      </div> -->
                            <!-- <div class="px-5 card-actions">
                              <div>

                              </div>

                              <div>

                              </div>

                            </div> -->
                            <div class="mt-5 py-5 border-0 border-blueGray-200 text-center">
                              <div class="flex flex-wrap justify-center">
                                <div class="w-full lg:w-9/12 px-0">

                                  <div class="px-2 mt-3  text-left">

                                    <p
                                      class="font-sans font-bold  dark:text-white text-3xl text-center text-yellow-500 uppercase">
                                      BIO
                                    </p>
                                    <p style="font-size: 17px"
                                      class="pt-5 mb-5 font-sans font-normal break-all text-left shadow-none dark:text-gray-200"
                                      v-if="userProfile.user.user_basic">
                                      {{ userProfile.user.user_basic.bio }}
                                    </p>


                                    <p
                                      class="font-sans font-bold  dark:text-white text-3xl text-center text-yellow-500 uppercase">
                                      Personal</p>
                                    <ul v-if="userProfile.user.looks" class="grid grid-cols-4 text">

                                      <li class="mt-4 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.looks.ethnicity">
                                        Ethnicity: {{ userProfile.user.looks.ethnicity }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.looks.body_type">
                                        Body Type: {{ userProfile.user.looks.body_type }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.looks.gender">
                                        Gender: {{ userProfile.user.looks.gender }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.looks.height">
                                        Height: {{ userProfile.user.looks.height }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.user_basic.relationship">
                                        Relationship: {{ userProfile.user.user_basic.relationship }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.user_basic.workstatus">
                                        Work: {{ userProfile.user.user_basic.workstatus }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.user_basic.education_level">
                                        Education: {{ userProfile.user.user_basic.education_level }}
                                      </li>
                                      <CoolLightBox :items="items" :index="index" @close="index = null">
                                      </CoolLightBox>
                                    </ul>

                                  </div>
                                  <div class="px-2 mt-3 text-left" v-if="userProfile.user.lifeStyle">
                                    <p class="font-sans text-3xl font-bold dark:text-white text-center text-yellow-500"
                                      v-if="userProfile.user.lifeStyle">
                                      LIFE STYLE
                                    </p>
                                    <ul class="mt-3 grid grid-cols-4">

                                      <li class="mt-4 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.lifeStyle.live_with">
                                        Lives With: {{ userProfile.user.lifeStyle.live_with }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.lifeStyle.car">
                                        Car: {{ userProfile.user.lifeStyle.car }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.lifeStyle.religion">
                                        Religion: {{ userProfile.user.lifeStyle.religion }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.lifeStyle.smoke">
                                        Smoke: {{ userProfile.user.lifeStyle.smoke }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.lifeStyle.drinking">
                                        Drink: {{ userProfile.user.lifeStyle.drinking }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.lifeStyle.travel">
                                        Travel: {{ userProfile.user.lifeStyle.travel }}
                                      </li>
                                      <li class="mt-3 font-sans text-sm dark:text-white"
                                        v-if="userProfile.user.user_basic.education_level">
                                        Education: {{ userProfile.user.user_basic.education_level }}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <footer class="relative bg-blueGray-200 pt-8 pb-6 mt-8">
                        <div class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-1 xl:gap-x-8">
                          <div class="relative group">
                            <div
                              class="overflow-hidden bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-800 lg:aspect-none lg:h-full"
                              style="" v-if="userProfile.user.photos.length > 0">
                              <img
                                class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem hover:border-pink-400 scale-125 hover:scale-75 ease-in duration-500"
                                v-for="(pic, idx) in userProfile.user.photos" :key="idx" @click="slideClicked(idx)"
                                :src="getURL(pic.image)" :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                                style="width: 230px; height: 150px" />
                            </div>
                          </div>
                          <div
                            class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
                            v-if="false">
                            <div class="relative">
                              <img class="absolute" style="filter: brightness(0.5)" src="@/assets/sexy-couple.jpg" />
                              <div class="relative">
                                <p class="pt-20">Unlock Exclusive Pictures</p>
                                <router-link class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white"
                                  to="/flirts">Unlock
                                  Now</router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </section>
                  </main>
                </div>


              </div>
              <!-- End of about section -->



              <!-- Experience and education -->

              <!-- End of profile tab -->
            </div>
          </div>
        </div>
      </div>
      <!-- layoutfix -->

      <!-- new theme starts here -->


      <!-- mobile section -->

      <div class="w-full md:w-full mx-2 h-full lg:hidden xl:hidden 2xl:hidden">
        <!-- Profile tab -->
        <!-- About Section -->
        <div class="bg-white dark:bg-gray-700 p-3 shadow-sm rounded-sm ">
          <div style="height: 870px; width: 100%; "
            class="flex items-top justify-center overflow-auto  w-full pl-1 pr-1  md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">
            <div v-if="loadingProfile">
              <div class="h-56 mx-auto mt-5 bg-gray-500 rounded-md animate-pulse"></div>
              <div class="space-y-2">
                <div class="h-4 mt-3 bg-gray-500 rounded animate-pulse"></div>
              </div>
            </div>
            <main class="profile-page -mt-28 " style="width: 100%;">
              <!-- profile card -->

              <div class="bg-gray-100  overflow-visible font-sans h-full w-full flex flex-row justify-center items-center"
                style="background-image: url('https://images.funnyflirts.net/yellowchatpage.jpg'); background-size: cover;  background-position: center;">


                <!-- <div class="card w-96 -mt-56 mx-auto bg-white   shadow-xl hover:shadow">
                  <div class="flex flex-col items-center py-3 space-y-5 bg-white rounded-b-3xl dark:bg-gray-700">
                    <img
                      class="object-cover border-2  z-50   border-pink-300 rounded-full cursor-pointer hover:border-pink-500 w-36 h-36 profileImg scale-125 hover:scale-75 ease-in duration-50"
                      @click="profileClicked" :src="getURL(userProfile.user.profilePic)" />


                  </div>
                  <div class="text-center mt-2 text-4xl font-medium">
                    <p
                      class="px-5 font-sans  text-center text-pink-500 uppercase font-semi-bold text-2xl dark:text-white card-title">
                      <span class="font-sans text-4xl font-bold text-pink-500" v-if="userProfile.user.base_profile">
                        {{ userProfile.user.base_profile.firstName }}
                        {{ userProfile.user.base_profile.lastName }}</span>
                    </p>
                  </div>
                  <div class="text-center mt-1 font-light text-sm">@ USERNAME</div>
                  <div class="text-center font-normal text-lg"> Location :</div>
                  <div class="px-6 text-center mt-2 font-light text-sm">

                  </div>
                  <hr class="mt-8">
                  <div class="flex p-4">
                    <div class="w-1/2 text-center">
                      <router-link class=" " :to="getChatRoute()"> <span
                          class="font-bold text-pink uppercase font-sans text-pink-500  ">

                          Message</span></router-link>
                    </div>
                    <div class="w-0 border border-gray-300">

                    </div>
                    <div class="w-1/2 text-center">
                      <span class="font-bold btn btn-sm border-0" @click="sendPoke"> POKE</span>
                    </div>
                  </div>
                </div> -->
                <div
                        class="card w-96 -mt-44 shadow-2xl border-2  grid-cols-1 mx-auto bg-white dark:bg-gray-600   hover:shadow">
                       <div class="grid grid-cols-2 ">
                        <div class="flex justify-center mt-5"><img  @click="reportUser" src="@/assets/mazu/report.png" class="h-10" alt=""></div>
                        <div  class="flex justify-center mt-5"><img @click="blockD = true" src="@/assets/mazu/block.png"  class="h-10" alt=""></div>
                       </div>
                        <div class="flex flex-col items-center py-3 space-y-5 bg-white rounded-b-3xl dark:bg-gray-700">
                          <img
                            class="object-cover border-2  z-50   border-pink-300 rounded-full cursor-pointer hover:border-pink-500 w-48 h-48 profileImg scale-125 hover:scale-75 ease-in duration-50"
                            @click="profileClicked" :src="getURL(userProfile.user.profilePic)" />


                        </div>
                        <div class="text-center mt-2 text-3xl  font-medium">
                          <p
                            class="px-5 font-sans text-sm text-center text-yellow-500 uppercase font-semi-bold text-md dark:text-white card-title">
                            <span class="font-sans text-3xl font-bold text-yellow-500"
                              v-if="userProfile.user.base_profile">
                              {{ userProfile.user.base_profile.firstName }}
                              {{ userProfile.user.base_profile.lastName }}</span>
                          </p>
                        </div>
                        <div class="text-center mt-1 font-light dark:text-white text-lg">@ USERNAME</div>
                        <div class="text-center font-normal dark:text-white text-lg"> Location :</div>
                        <!-- <div class="text-center font-normal dark:text-white text-lg"> Sexual Preferance :</div> -->
                        <div class="px-6 text-center mt-2 font-light text-sm">

                        </div>
                        <hr class="mt-8">
                        <!-- <div class="flex bg-transparent bg-opacity-0 p-4">
                          <div class="w-1/2 text-center">
                            <router-link class=" " :to="getChatRoute()"> <span
                                class="font-bold text-pink uppercase font-sans text-pink-500  ">

                                Message</span></router-link>
                          </div>
                          <div class="w-0 border border-gray-300">

                          </div>
                          <div class="w-1/2 text-center">
                            <span class="font-bold btn btn-sm dark:text-white border-0" @click="sendPoke"> POKE</span>
                          </div>
                        </div> -->
                      </div>
                      
              </div>
              <br>
                      <div
                        class="card w-96  shadow-none bg-opacity-0 border-0 -mt-96 grid-cols-1 mx-auto flex flex-wrap justify-center bg-white dark:bg-gray-600  "
                        style="grid-row-start: 2;">




                        <hr class="mt-8">
                        <div class="flex bg-transparent bg-opacity-0 p-4">
                          <div class="w-1/2 text-center">
                            <router-link class=" " :to="getChatRoute()"> <span
                                class="font-bold text-pink flex justify-center uppercase font-sans sha text-pink-500  ">

                                <img src="@/assets/mazutheme/msg.png" class="h-28" alt="">
                              </span></router-link>
                          </div>
                          <div class="w-0 border border-gray-300">

                          </div>
                          <div class="w-1/2 text-center">
                            <span class="font-bold btn btn-sm dark:text-white border-0" @click="sendPoke"> <img
                                src="@/assets/mazutheme/poke.png" class="h-28" alt=""></span>
                          </div>
                        </div>
                        <div class="flex bg-white rounded-full shadow-inner border-4 w- p-4">
                          <div class="w-1/2 text-center">
                            <span class="font-bold text-pink flex justify-center uppercase font-sans sha text-yellow-500  ">

                              <div class="mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                    class="font-sans dark:text-white" v-text="userProfile.likes"></span></span><span
                                  class="text-sm text-blueGray-400 dark:text-white">LIKES</span>
                              </div>
                            </span>
                          </div>
                          <div class="w-0 border border-gray-300">

                          </div>
                          <div class="w-1/2 text-center">
                            <span class="font-bold btn btn-sm dark:text-white border-0">
                              <div class="mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                    class="font-sans dark:text-white" v-text="userProfile.visits"></span></span><span
                                  class="text-sm text-blueGray-400 uppercase dark:text-white">Visits</span>
                              </div>
                            </span>
                          </div>
                        </div>

                      </div>
              <!-- profile card -->
              <section class="relative mt-24 py-4 bg-blueGray-200">
                <div class="container mx-auto px-4">
                  <div
                    class="relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-xl rounded-lg -mt-64">
                    <div class="px-6">
                      <!-- <div class="flex flex-wrap justify-end">
                        <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                          <div class="relative">
                            <img alt="..." src="https://demos.creative-tim.com/notus-js/assets/img/team-2-800x800.jpg"
                              class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px">
                          </div>
                        </div>
                        <div class="w-full flex flex-wrap lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                          <div class="flex flex-col items-center col-span-1 px-3">
                            <button class="mt-2" @click="blockD = true">
                              <img src="@/assets/icons/Block.png" alt="" class="h-9"><span @click="blockD = true"
                                class="font-sans text-sm text-pink-200 text-semi-bold dark:text-pink-50 card-title">Block</span>
                            </button>
                          </div>
                          <div class="flex flex-col items-center col-span-1 px-3">
                            <button class="mt-2" @click="reportUser">
                              <img src="@/assets/icons/Report.png" class="h-9 pl-2" alt=""><span @click="reportUser"
                                class="font-sans text-sm text-pink-200 text-semi-bold dark:text-pink-50 card-title">Report</span>
                            </button>
                          </div>

                        </div>
                        <div class="w-full lg:w-4/12 px-4 lg:order-1">
                          <div class="flex justify-center py-4 lg:pt-4 pt-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="pink" class="w-24 h-24">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                            </svg>


                            <div class="mr-4 p-3 text-center">
                              <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                  class="font-sans" v-text="userProfile.likes"></span></span><span
                                class="text-sm text-blueGray-400">LIKES</span>
                            </div>
                            <div class="mr-4 p-3 text-center">
                              <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> <span
                                  class="font-sans" v-text="userProfile.visits"></span></span><span
                                class="text-sm text-blueGray-400 uppercase">Visits</span>
                            </div>
                           
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="text-center mt-12">
                        <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                          Jenna Stones
                        </h3>
                        <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                          <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                          Los Angeles, California
                        </div>
                        <div class="mb-2 text-blueGray-600 mt-10">
                          <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>Solution Manager - Creative Tim
                          Officer
                        </div>
                        <div class="mb-2 text-blueGray-600">
                          <i class="fas fa-university mr-2 text-lg text-blueGray-400"></i>University of Computer Science
                        </div>
                      </div> -->
                      <div class="px-5 card-actions">
                        <div>
                          <p class="  font-bold text-left mb-1 font-sans text-pink-500">
                            BIO :
                          </p>
                        </div>

                        <div>
                          <p style="font-size: 17px"
                            class="pt-5 mb-5 font-sans font-normal break-all text-left shadow-none dark:text-gray-200"
                            v-if="userProfile.user.user_basic">
                            {{ userProfile.user.user_basic.bio }}
                          </p>
                        </div>

                      </div>
                      <div class="mt-5 py-5 border-t border-blueGray-200 text-center">
                        <div class="flex flex-wrap justify-center">
                          <div class="w-full lg:w-9/12 px-0">

                            <div class="px-2 mt-3  text-left">
                              <p
                                class="font-sans font-bold  dark:text-white text-3xl text-center text-pink-500 uppercase">
                                Personal</p>
                              <ul v-if="userProfile.user.looks" class="grid grid-cols-2 text">

                                <li class="mt-4 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.looks.ethnicity">
                                  Ethnicity: {{ userProfile.user.looks.ethnicity }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.looks.body_type">
                                  Body Type: {{ userProfile.user.looks.body_type }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white" v-if="userProfile.user.looks.gender">
                                  Gender: {{ userProfile.user.looks.gender }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white" v-if="userProfile.user.looks.height">
                                  Height: {{ userProfile.user.looks.height }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.user_basic.relationship">
                                  Relationship: {{ userProfile.user.user_basic.relationship }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.user_basic.workstatus">
                                  Work: {{ userProfile.user.user_basic.workstatus }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.user_basic.education_level">
                                  Education: {{ userProfile.user.user_basic.education_level }}
                                </li>
                                <CoolLightBox :items="items" :index="index" @close="index = null">
                                </CoolLightBox>
                              </ul>

                            </div>
                            <div class="px-2 mt-3 text-left" v-if="userProfile.user.lifeStyle">
                              <p class="font-sans text-3xl font-bold dark:text-white text-center text-pink-500"
                                v-if="userProfile.user.lifeStyle">
                                LIFE STYLE
                              </p>
                              <ul class="mt-3 grid grid-cols-2">

                                <li class="mt-4 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.lifeStyle.live_with">
                                  Lives With: {{ userProfile.user.lifeStyle.live_with }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white" v-if="userProfile.user.lifeStyle.car">
                                  Car: {{ userProfile.user.lifeStyle.car }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.lifeStyle.religion">
                                  Religion: {{ userProfile.user.lifeStyle.religion }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.lifeStyle.smoke">
                                  Smoke: {{ userProfile.user.lifeStyle.smoke }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.lifeStyle.drinking">
                                  Drink: {{ userProfile.user.lifeStyle.drinking }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.lifeStyle.travel">
                                  Travel: {{ userProfile.user.lifeStyle.travel }}
                                </li>
                                <li class="mt-3 font-sans text-sm dark:text-white"
                                  v-if="userProfile.user.user_basic.education_level">
                                  Education: {{ userProfile.user.user_basic.education_level }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer class="relative bg-blueGray-200 pt-8 pb-6 mt-8">
                  <div class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-1 xl:gap-x-8">
                    <div class="relative group">
                      <div
                        class="overflow-hidden bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-800 lg:aspect-none lg:h-full"
                        style="" v-if="userProfile.user.photos.length > 0">
                        <img
                          class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem hover:border-pink-400 scale-125 hover:scale-75 ease-in duration-500"
                          v-for="(pic, idx) in userProfile.user.photos" :key="idx" @click="slideClicked(idx)"
                          :src="getURL(pic.image)" :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                          style="width: 230px; height: 150px" />
                      </div>
                    </div>
                    <div
                      class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
                      v-if="false">
                      <div class="relative">
                        <img class="absolute" style="filter: brightness(0.5)" src="@/assets/sexy-couple.jpg" />
                        <div class="relative">
                          <p class="pt-20">Unlock Exclusive Pictures</p>
                          <router-link class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white" to="/flirts">Unlock
                            Now</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </section>
            </main>
          </div>


        </div>
        <!-- End of about section -->



        <!-- Experience and education -->

        <!-- End of profile tab -->
      </div>

      <!-- end of mobile section -->




      <!-- end of new theme  -->





    </div>
  </div>
</template>
<script>
import { getURL } from "@/utils/helpers";
import CoolLightBox from "vue-cool-lightbox";
import popup from "@/components/popup.vue";
import { BLOCK_USER, REPORT_USER, SEND_POKE, USER_PROFILE } from "@/queries/auth";
import UserCard from "@/components/userCard.vue";
import NewSideSelector from "@/components/newsideselector ";
import Notify from "@/components/Notify.vue";
import SideHomeSelector from "@/components/sideHomeSelector.vue";
import SideMatchSelector from '@/components/sideMatchSelector';
import UserAvatar from "@/components/userAvatar.vue";
import YellowUserAvartar from "@/components/yellow/YellowuserAvatar.vue";
import YellowNotify from '@/components/yellow/YellowNotify.vue'
import YellowsideHomeSelector from '@/components/yellow/YellowsideHomeSelector.vue'

export default {
  components: { popup, UserAvatar, YellowsideHomeSelector, YellowNotify, YellowUserAvartar, CoolLightBox, UserCard, NewSideSelector, Notify, SideHomeSelector, SideMatchSelector, },
  data: () => ({
    items: [],
    index: null,
    blockD: false,
    showing: false,
    allUserPics: [],
    reportReason: "",
    userProfile: null,
    loadingProfile: true,
    // routeParam: this.$route.params.username
  }),
  apollo: {
    userProfile: {
      query: USER_PROFILE,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      update(data) {
        this.loadingProfile = false;
        console.clear();
        // this.userProfile = data.userProfile
        // console.log(data.user.photos,"Hol up")
        data.userProfile.user.photos.forEach((pic) => {
          this.allUserPics.push(getURL(pic.image));
        });
        return data.userProfile;
      },
    },
  },
  created() {
    this.$apollo.queries.userProfile.refetch();
    // this.fetchUserProfile()
    // const allPics = this.$store.state.core.user.photos;
    // allPics.forEach(e => {
    // 	this.allUserPics.push(getURL(e.image))
    // })
  },
  methods: {
    getURL,
    sendPoke() {
      this.$apollo
        .mutate({
          mutation: SEND_POKE,
          variables: {
            input: this.userProfile.user.username,
          },
        })
        .then(
          ({
            data: {
              sendPoke: { error },
            },
          }) => {
            this.$notify(
              {
                group: "top",
                title: error
                  ? "You have finished your pokes for today :("
                  : `Poke Sent to @${this.userProfile.user.username}`,
                text: error ? "Whoops" : "Success",
                type: "info", // info, warning
              },
              5000
            );
          }
        );
    },
    getChatRoute() {
      switch (this.$mq) {
        case "sm":
          return `/chat-list?dm=${this.userProfile.user.id}`;
        case "md":
          return `/chat-list?dm=${this.userProfile.user.id}`;
        case "lg":
          return `/chat?dm=${this.userProfile.user.id}`;
        default:
          break;
      }
      // ($mq == 'sm' || 'md')
      // 	? `/chat-list?dm=${userProfile.user.id}`
      // 	: `/chat?dm=${userProfile.user.id}`
    },
    slideClicked(idx) {
      console.log(idx, "IDX");
      this.index = idx;
      this.items = this.allUserPics.filter((obj) => {
        return obj.unsafe !== true;
      });

      // this.items = [
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // ];
    },
    profileClicked() {
      this.items = [getURL(this.userProfile.user.profilePic)];
      // console.log(this.userProfile)
      this.index = 0;
    },
    submitReport() {
      this.$notify(
        {
          group: "top",
          title: "You report is submitted thank you !!",
          type: "info", // info, warning
        },
        5000
      );
      this.showing = false;
      this.$apollo.mutate({
        mutation: REPORT_USER,
        variables: {
          input: {
            target: JSON.stringify(this.userProfile.user.id),
            reason: this.reportReason,
          },
        },
      });
    },
    block() {
      this.blockD = false;
      this.$apollo
        .mutate({
          mutation: BLOCK_USER,
          variables: {
            input: JSON.stringify(this.userProfile.user.id),
          },
        })
        .then(() => {
          this.$notify(
            {
              group: "top",
              title: "User blocked successfully!!",
              type: "info", // info, warning
            },
            5000
          );
          this.$router.push("/find-matches");
        });
    },
    reportUser() {
      this.showing = true;
    },
  },
};
</script>
<style scoped>
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 250px;
  white-space: nowrap;
}

.elem {
  display: inline-block;
  object-fit: cover;
  width: 40%;
  height: 200px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(128, 128, 128, 0.418);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.418);
}</style>