<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div
    class="px-2 py-4 mx-auto bg-white   shadow-2xl drop-shadow-2xl max-w-screen-xl md:px-2 lg:px-2 lg:-mt-28"
    style="">

    <div class="bg-white dark:bg-gray-800  hidden lg:block -mt-4  md:-mx-2">
      <div style="height: 1000px"
        class="container   flex gap-0  flex-wrap justify-center   ml-auto mr-auto  bg-opacity-0 dark:bg-gray-800 h-1/2 items-top">
        <div class=" dark:bg-gray-600 hidden lg:block">
          <div
            class="px-2 py-4 mx-auto bg-white    max-w-screen-2xl md:px-2 lg:px-2">
            <!-- Profile tab -->
            <!-- About Section -->
            <div class="bg-white dark:bg-gray-700 p-3 shadow-sm rounded-sm overflow-auto ">
              <div style="height: 910px; width: 100%; "
                class="flex items-top justify-center   w-full pl-1 pr-1  md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">

                <div style=" width: 100% "
                  class="flex items-top justify-center  w-full pl-1 pr-1 mt-3  md:w-1/3 md:mb-0 bg-white dark:bg-gray-600">
                  <div class="mt-3">




                    <div class="mt-">
                      <div v-if="liked.length > 0" class="
            grid grid-cols-2
            gap-5
            mb-12
            sm:grid-cols-2
            md:grid-cols-2
            lg:grid-cols-5
            xl:grid-cols-7
           
          ">
                        <div v-for="(item, idx) in liked" :key="idx">
                          <user-card @popIndex="popItem(idx)" :user="item.user" :page="'likes'" />



                        </div>






                      </div><div v-else-if="!loadingContent" class="pt-10 mx-2 font-sans text-left">
         
                        <p class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          ">
              {{ $t('nomatches') }}
            </p>
            <p class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          ">
              {{ $t('likematches') }}
            </p>
            <div class=""><button @click="$router.push('/find-matches')" class="
            mt-5 
            btn btn-accent
            rounded-xl
            btn-lg bg-purple-500  glass
            mb-72
            btn-wide btn-warning
          ">
              <a style="color: #ffffff">home </a>
            </button></div>
       </div>

                    </div>




                  </div>

                </div>



              </div>


            </div>

          </div>
          <!-- End of Navbar -->

          <div class="container mx-auto my-1 p-5">
            <div class="md:flex no-wrap md:-mx-2 ">
              <!-- Left Side -->
              <!-- <div style="height: 885px"
                class=" items-center border-0    py-2 justify-center hidden h-full pl-1 pr-1 mt-1  dark:bg-gray-500 w-30 md:w-24 md:mb-0 bg-white bg lg:block md:hidden">
                <new-side-selector />

              </div> -->
              <!-- <div class="w-full md:w-3/12 md:mx-2">
                
                <div class="bg-white dark:bg-gray-600 p-3">
                
                  <div style="width: 100%; "
                    class="  justify-center py-4 hidden h-full grid-cols-1 pl-1 pr-1 mt-3 overflow-hidden bg-pink-50  items-top lg:block dark:bg-gray-600 w-30 md:w-49 md:mb-0">
                    <user-avatar />
                    <div class="overflow-hidden p-2 bg-pink-50 dark:bg-gray-700" style="height: 200px">


                      <Notify />

                    </div>

                    <div class="hidden overflow-hidden border-2 border-white bg-gray-50 dark:bg-gray-700 lg:block"
                      style="height: 300px">
                      <p class="pl-6 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
                        People who visited you
                      </p>
                      <side-home-selector class="" />
                    </div>
                    <div style="height: 225px" class="hidden  overflow-hidden bg-pink-50 dark:bg-gray-700 lg:block">
                      <p class="px-5 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
                        People who likes you
                      </p>
                      <side-match-selector />
                    </div>

                  </div>
                </div>
               
                <div class="my-4"></div>
             


              </div> -->
              <!-- Right Side -->

            </div>
          </div>
        </div>




      </div>
    </div>





    <!-- new theme starts here -->


    <div class="bg-white dark:bg-gray-800 -mt-10 lg:hidden xl:hidden">
      <div style="height: 870px"
        class="container flex flex-wrap  justify-center gap-0 ml-auto mr-auto  bg-white dark:bg-gray-800  items-top">


        <!-- <div
          class=" items-center border-0    py-4 justify-center hidden h-full pl-1 pr-1 mt-3  dark:bg-gray-500 w-30 md:w-24 md:mb-0 bg-pink-50  bg lg:block">
          <new-side-selector />

        </div>
        <div style="width: 100%; "
          class="  justify-center py-4 hidden h-full grid-cols-1 pl-1 pr-1 mt-3 overflow-hidden bg-pink-50  items-top lg:block dark:bg-gray-600 w-30 md:w-49 md:mb-0">
          <user-avatar />
          <div class="overflow-hidden p-2 bg-pink-50 dark:bg-gray-700" style="height: 200px">


            <Notify />

          </div>

          <div class="hidden overflow-hidden border-2 border-white bg-gray-50 dark:bg-gray-700 lg:block"
            style="height: 300px">
            <p class="pl-6 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
              People who visited you
            </p>
            <side-home-selector class="" />
          </div>
          <div style="height: 210px" class="hidden  overflow-hidden bg-pink-50 dark:bg-gray-700 lg:block">
            <p class="px-5 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
              People who likes you
            </p>
            <side-match-selector />
          </div>

        </div> -->

        <div style="height: 870px;  width: 790px"
          class="flex items-top justify-center overflow-auto py-4  w-full pl-1 pr-1 mt-3 shadow-md md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">
          <div class="mt-">
            <div v-if="liked.length > 0" class="
            grid grid-cols-3
            gap-5
            mb-12
            sm:grid-cols-2
            md:grid-cols-3
            lg:grid-cols-3
            xl:grid-cols-3
          ">
              <div v-for="(item, idx) in liked" :key="idx">
                <user-card @popIndex="popItem(idx)" :user="item.user" :page="'likes'" />

              </div>
            </div>




            <br />


          </div>
          <div></div>
        </div>




      </div>
    </div>





    <!-- end of new theme  -->

    <div class="col-span-2 bordered">
      <div class="box-border  w-full border-0 md:mx-20">
        <!-- start of avatar row -->
        <!-- <div class="hidden lg:block">
            <user-avatar />
            <side-selector />
          </div> -->
        <!-- end of avatar row -->
      </div>
    </div>
    <!-- empty block to fix padding -->
    <div class="col-span-1"></div>
    <!-- <div class="col-span-9 compressedx">
      <div class="text-left mb-7" v-if="liked.length > 0">
        <a class="pb-10 mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block w-8 h-8 mr-2 stroke-current text-pink-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
              clip-rule="evenodd"
            />
          </svg>

          <span
            class="
              px-5
              font-sans
              text-sm text-center text-pink-500
              uppercase
              font-semi-bold
              text-md
              dark:text-white
              card-title
            "
            style="color: #7e7979"
          >
            HOT PEOPLE
          </span>
        </a>
      </div>
      <div
        v-if="liked.length > 0"
        class="
          grid grid-cols-2
          gap-5
          mb-12
          sm:grid-cols-2
          md:grid-cols-3
          lg:grid-cols-3
          xl:grid-cols-3
        "
      >
        <div v-for="(item, idx) in liked" :key="idx">
          <user-card
            @popIndex="popItem(idx)"
            :user="item.user"
            :page="'likes'"
          />
         
        </div>
      </div>
      <div v-else class="pt-10 font-sans text-left">
        <p
          class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          "
        >
          You haven't
          <span class="text-pink-500"> Liked </span> anyone yet
        </p>
        <p
          class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          "
        >
          You can start matching with profile by liking them
        </p>
        <button
          @click="$router.push('/find-matches')"
          class="
            mt-5
            btn btn-accent
            rounded-xl
            btn-lg
            mb-72
            btn-outline btn-wide btn-warning
          "
        >
          <a style="color: #dc4b78"> Start Now </a>
        </button>
      </div>
      <button
        v-if="liked.length > 0"
        class="
          mb-20
          rounded-full
          shadow-xl
          btn btn-accent btn-lg btn-outline btn-wide btn-warning
        "
        >
          <a style="color: #dc4b78"> Load More </a>
        </button>
      </div> -->

  </div>
</template>
<style scoped></style>
<script>
import UserAvatar from "@/components/userAvatar.vue";
import SideSelector from "@/components/sideSelector.vue";
import { LIKED } from "@/queries/matches";
import { getURL } from "@/utils/helpers";
import UserCard from "@/components/purple/userCard.vue";
import NewSideSelector from "@/components/newsideselector ";
import Notify from "@/components/Notify.vue";
import SideHomeSelector from "@/components/sideHomeSelector.vue";
import SideMatchSelector from '@/components/sideMatchSelector';
const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    UserAvatar,
    SideSelector,
    UserCard,
    NewSideSelector, Notify, SideHomeSelector, SideMatchSelector,
  },
  apollo: {
    liked: {
      query: LIKED,
    },
  },
  data: () => ({
    features,
    matches: [],
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    dislikes: null,
    mobile: false,
  }),
  methods: {
    getURL,
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
    popItem(idx) {
      this.liked.splice(idx, 1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener(
        "onorientationchange",
        this.detectOrientationChange
      );
    });
  },
  created() {
    this.$apollo.queries.liked.refetch();
    this.detectOrientationChange(); // when instance is created
  },
};
</script>
<!-- <style >
@import "@/assets/css/style.css";
</style> -->
