<template>
  <!-- <LoginIndex/> -->
  <MatchesIndex/>
</template>
<script>
import MatchesIndex from '@/templates/Matches/Index.vue'
export default {
  components:{
    MatchesIndex
  }
}
</script>