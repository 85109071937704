<template>
	<div>
		<div class="grid grid-cols-1">
			<div class="flex justify-center px-5 mt-5">
				<div class="flex">
					<h1
						class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>
						{{$t('ChangePassword')}}
					</h1>
				</div>
			</div>
		</div>
		<div
			class="mx-5 mt-8 alert"
			:class="{ 'bg-gray-100': message.error, 'bg-green-100': !message.error }"
			v-if="messageShow"
		>
			<div class="flex-1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					class="w-6 h-6 mx-2 stroke-current"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
					></path>
				</svg>
				<label>
					{{ message.body }}
				</label>
			</div>
		</div>

		<div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
			<label
				class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
				>{{$t(NewPassword)}}</label
			>
			<input
				v-model="password"
				class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl "
				placeholder="New Password"
			/>
		</div>
		<div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
			<label
				class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
				>{{$t('Confirmpassword')}}</label
			>
			<input
				v-model="passwordConfirm"
				class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				type="text"
				placeholder="Confirm Password"
			/>
		</div>

		<div class="flex gap-4 pt-5 pb-5 mx-7 md:gap-6">
			<button
				@click="changePassword()"
				class="w-auto px-4 py-2 font-medium text-white bg-gray-500 rounded-lg shadow-xl hover:bg-gray-700"
			>
				<div v-if="updateLoading" class="mt-3 lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<p v-else>{{$t('SaveChanges')}}</p>
			</button>
		</div>
	</div>
</template>
<script>
import { CHANGE_PASSWORD } from '../../../queries/auth';
export default {
	data: () => ({
		password: "",
		passwordConfirm: "",
		updateLoading: false,
		messageShow: false,
		message: {
			error: false,
			body: ""
		}
	}),
	methods: {
		changePassword() {
			if (this.password.length < 6) {
				this.message.error = true;
				this.messageShow = true;
				this.message.body = "Password should atleast be 6 characters"
				return;
			} else if (this.password != this.passwordConfirm) {
				console.log("confirmation err")
				this.message.error = true;
				this.messageShow = true;
				this.message.body = "Password should must be the same as confirmation"
				return;
			}
			this.updateLoading = true
			this.$apollo.mutate({
				mutation: CHANGE_PASSWORD,
				variables: {
					input: {
						password: this.password,
						passwordConfirm: this.passwordConfirm
					}
				}
			}).then(({ data: { changeUserPassword } }) => {
				console.log(changeUserPassword, "Password Change")
				this.message.error = false;
				this.messageShow = true;
				this.message.body = "Password updated successfully"
			}).catch(e => {
				console.error(e);
			}).finally(() => {
				this.updateLoading = false
			})
		},
	},
};
</script>