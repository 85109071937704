<template>
	<div
		class="relative inline-block text-left text-gray-800"
		v-on-clickaway="closeMenu"
	>
		<div class="w-12 h-12 mx-3" v-if="!($mq === 'sm')">
			<button @click="buttonClicked" class="btn btn-circle btn-md border-0 bg-pink-50">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
</svg>

			</button>
			<span
				v-if="$store.state.core.notifiationCount > 0"
				class="
					absolute
					top-3
					right-3
					inline-flex
					items-center
					justify-center
					px-2
					py-1
					text-xs
					font-bold
					leading-none
					text-red-100
					transform
					translate-x-1/2
					-translate-y-1/2
					bg-red-600
					rounded-full
				"
				>{{ $store.state.core.notifiationCount }}</span
			>
		</div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<div
				v-if="isMenuOpen"
				class="
					origin-top-right
					absolute
					right-0
					mt-2
					w-80
					rounded-md
					shadow-lg
					text-sm
					overflow-hidden
					border-none
					z-20
				"
			>
				<div
					class="rounded-md bg-white dark:bg-gray-700 shadow-xs"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="options-menu"
				>
					<center v-if="loadingNotification">
						<div class="mt-14 lds-ellipsis">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</center>
					<div v-else>
						<ul
							class="overflow-y-scroll max-h-96"
							v-if="notifications.length > 0"
						>
							<li
								v-for="(notice, idx) in notifications"
								:key="idx"
								@click="toLink(notice)"
								class="
									flex
									justify-between
									items-center
									bg-white
									dark:bg-gray-700
									mt-2
									p-2
									hover:shadow-lg
									rounded
									cursor-pointer
									transition
								"
							>
								<div class="flex ml-2">
									<div class="flex-none">
										<div class="avatar h-12 w-12">
											<img
												:src="getURL(notice.ref_user.profilePic)"
												width="40"
												height="40"
												class="rounded-full"
											/>
										</div>
									</div>
									<!-- {{ notice.message }} -->
									<div class="flex flex-col ml-2 mt-1">
										<span
											class="font-medium text-black dark:text-white"
											:class="{ 'font-black': !notice.read }"
											>{{ notice.message.substr(0,22)+'...' }}</span
										>
										<span
											class="text-sm text-gray-400 truncate w-32"
											:class="{ 'font-black': !notice.read }"
										></span>
									</div>
								</div>
								<div class="flex flex-col items-center">
									<span class="text-gray-300">11:26</span>
									<i class="fa fa-star text-green-400"></i>
								</div>
							</li>
						</ul>
						<ul v-else class="overflow-y-scroll max-h-96">
							<li>
								<div
									class="font-sans text-center text-lg my-10 dark:text-gray-200"
								>
									You don't have any notifications
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { getURL } from "../utils/helpers";
import { logout } from "../utils/basicAuth";
import { NOTIFICATIONS } from '../queries/matches';
export default {
	mixins: [clickaway],
	data: () => ({
		notifications: [],
		isMenuOpen: false,
		label: "First label",
		loadingNotification: false,
	}),
	methods: {
		buttonClicked() {
			this.isMenuOpen = !this.isMenuOpen
			if(this.isMenuOpen)
				this.fetchNotifications()
		},
		logout,
		getURL,
		toLink(notice) {
			if (notice.sparoute) {
				const finalLink = notice.link == "/chat" ? `/chat?dm=${notice.ref_user.id}` : notice.link
				this.$router.push(finalLink)
			} else {
				window.location.assign(notice.link)
			}
			// this.$router.push(link)
			this.isMenuOpen = false
		},
		fetchNotifications() {
			this.loadingNotification = true
			this.$apollo.query({
				query: NOTIFICATIONS,
				fetchPolicy:"network-only"
			}).then(({ data }) => {
				this.notifications = data.notifications
			}).finally(() => {
				this.loadingNotification = false
				setTimeout(() => {
					// Empty notificaiton count
					this.$store.commit("changeNotificationCount", 0)
				}, 3000);

			})
		},
		closeMenu() {
			this.isMenuOpen = false;
		},
	},
	computed: {
		notificationObject() {
			return this.$store.getters.notificationObject
		}
	},
	watch: {
		notificationObject(value) {
			const freshNots = [value].concat(this.notifications)
			this.notifications = freshNots;
		}
	}
};
</script>