<template>
  <!-- <FindMatches/> -->
  <Userpage/>
</template>
<script>
import Userpage from '@/templates/Userpage/Index.vue'
export default {
  components:{
    Userpage
  }
}
</script>