var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left mx-2 font-medium lg:w-full xl:w-full px-10 w-full xl:mt-1 lg:mt-1",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"md:flex dark:bg-gray-700  h-screen bg-white lg:border-2"},[_c('div',{staticClass:"w-full lg:p-4"},[(_vm.loadingChats)?_c('center',[_c('div',{staticClass:"mt-14 lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_c('ul',{staticClass:"overflow-y-scroll",staticStyle:{"height":"92%"}},[(_vm.newDm)?_c('li',{staticClass:"flex justify-between items-center  dark:bg-gray-600 mt-2 p-2 hover:shadow-lg border-2 border-green-500 rounded cursor-pointer transition",on:{"click":_vm.dmTargetSelected}},[_c('div',{staticClass:"flex ml-2"},[_c('img',{staticClass:"rounded-full mr-3 w-10 h-10",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.getURL(_vm.newTarget.profilePic)}}),_c('div',{staticClass:"flex flex-col ml-2 text-black text-xs dark:text-white"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.newTarget.base_profile.firstName)+" "+_vm._s(_vm.newTarget.base_profile.lastName))]),_c('span',{staticClass:"text-sm text-green-400 truncate w-44 animate-pulse uppercase "},[_vm._v("New conversation ")])])]),_c('div',{staticClass:"flex flex-col items-center"},[_c('i',{staticClass:"fa fa-star text-green-400"})])]):_vm._e(),_vm._l((_vm.allConversations),function(item,idx){return _c('li',{key:idx,staticClass:"flex justify-between items-center mt-2 p-2 hover:shadow-lg border-2 rounded cursor-pointer transition",class:{
              'dark:bg-gray-600': _vm.selected.id === item.id,
              'dark:bg-gray-800': !(_vm.selected.id === item.id),
              'bg-purple-100': _vm.selected.id === item.id,
              'bg-white': !(_vm.selected.id === item.id),
            },on:{"click":function($event){return _vm.userSelected(item)}}},[_c('div',{staticClass:"flex ml-2 flex-wrap  "},[_c('img',{staticClass:"rounded-full mr-1 w-10 h-10",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.getURL(item.m_one.profilePic)}}),_c('div',{staticClass:"flex flex-col ml-2 text-black text-xs dark:text-white"},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(item.m_one.base_profile.firstName)+" "+_vm._s(item.m_one.base_profile.lastName))]),(item.lastMessage)?_c('span',{staticClass:"truncate text-2xs w-40",class:{
                    'text-gray-600': _vm.selected.id === item.id,
                    ' text-gray-400': !(_vm.selected.id === item.id),
                  }},[_vm._v(_vm._s(item.lastMessage.body))]):_vm._e()])]),_c('span',{staticClass:"flex flex-col justify-end text-black text-xs dark:text-white",staticStyle:{"justify-content":"end","display":"flex"},on:{"click":function($event){return _vm.deleteCoversation($event,item)}}},[_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"red"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"}})])])])})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }