<template>
  <FindMatches/>
</template>
<script>
import FindMatches from '@/templates/FindMatches/Index.vue'
export default {
  components:{
    FindMatches
  }
}
</script>