<template>
  <!-- <LoginIndex/> -->
  <!-- <MatchesIndex/> -->
  <ImprintIndex />
</template>
<script>
import ImprintIndex from "@/templates/Imprint/Index.vue";
export default {
  components: {
    ImprintIndex,
  },
};
</script>
