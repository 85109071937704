<template>
  <MediaPageComponent/>
</template>
<script>
import MediaPageComponent from '../components/valentinegallery/MediaPageComponent.vue'
export default {
  components:{
    MediaPageComponent
  }
}
</script>