<template>
  <div class="chat-message text-left " >
    <div class="flex items-cemter" :class="{ 'justify-end': sent }">
      <div
        :class="sent ? 'order-1 items-end' : 'order-2 items-start'"
        class="flex flex-col space-y-2 text-xs max-w-xs mx-2"
      >
        <div v-for="(message, i) of messages" :key="i">
          <div v-if="message.attachment_url && message.message_type == 'd'" class="flex flex-col">
            <span
              v-html="message.body"
              :class="{
                [sent ? 'rounded-br-none' : 'rounded-bl-none']:
                  i === messages.length - 1,
                'bg-gray-300 text-gray-600': !sent,
                'bg-pink-700 text-white ': sent,
              }"
              class="px-4 py-2 rounded-lg text-sm inline-block font-sans"
            ></span>
            <img :src="getURL(message.attachment_url)" alt="" />
          </div>
          <span
            v-else-if="message.body.length && message.message_type == 'd'"
            v-html="message.body"
            :class="{
              [sent ? 'rounded-br-none' : 'rounded-bl-none']:
                i === messages.length - 1,
              'bg-gray-50 border-2 border-b-8 text-gray-600 font-semi-bold shadow-2xl ': !sent,
              'bg-purple-50 text-ypurple-700 border-2  border-b-8 font-serif font-semi-bold sha shadow-2xl ': sent,
            }"
            class="px-4 py-2 rounded-lg text-sm inline-block font-sans"
          >
          </span>
          <span v-else-if="message.attachment_url == '' && message.body == '' && message.message_type == 'd'"
            class="px-4 py-2 bg-gray-300 rounded-lg text-sm inline-block font-sans"
            v-html="'This message has been deleted'"
          >
          </span>
          <div v-else-if="message.message_type == 'sn'" class="flex flex-col">
            <a
              :href="imageHref"
              target="_blank"
            ><img 
            height="100px"
            width="100%"
            :src="imageUrl" alt="" /></a>
            <span
              v-html="message.body"
              :class="{
                [sent ? 'rounded-br-none' : 'rounded-bl-none']:
                  i === messages.length - 1,
                'bg-gray-300 text-gray-600': !sent,
                'bg-yellow-500 text-white ': sent,
              }"
              class="px-4 py-2 rounded-lg text-sm inline-block font-sans"
            ></span>
          	<a  v-if="!paid" @click="payForNude" style="width:130px" class="bg-yellow-600 font-sans text-sm text-white rounded-md px-1">view with 30 flirts</a>
          </div>
          <div v-else-if="message.attachment_url && message.message_type == 'g'" class="flex flex-col">
            <img :src="getURL(message.attachment_url)" alt="" />
            <span
              v-html="message.body"
              v-if="message.body.length > 0"
              class="px-4 py-2  bg-gray-300 text-gray-600  text-sm inline-block font-sans"
            ></span>
          </div>
        </div>
      </div>
      <div class="avatar h-6 w-6 object-cover border-2 bg-pink-100 border-purple-300 rounded-full cursor-pointer" :class="sent ? 'order-2' : 'order-1'">
        <img class="avatar h-6 w-6 object-cover border-2 bg-pink-100 border-purple-300 rounded-full cursor-pointer" :src="avatar" />
      </div>
    </div>
  </div>
</template>



<script>
import { getURL } from "@/utils/helpers";
import { BUY_NUDE } from '@/queries/chat'
export default {
  name: "ChatMessage",
  data: () =>{
    return {
      imageUrl:'https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921',
      imageHref:null,
      paid:null
    }
  },
  created() {
   let message_type = this.messages[0].message_type
   let message_url = this.messages[0].attachment_url 
   let is_paid = this.messages[0].paid
   this.imageHref = this.getURL(message_url)
   this.paid = is_paid
   if(message_type == 'sn') {
     if(is_paid){
        this.imageUrl = this.getURL(message_url)
     }else{
       this.getNudePic(message_url).then((url)=>{
         this.imageUrl = url
      })
     }
   } 
  },
  methods: {
    getURL,
    async payForNude() {
      console.log(this.messages[0])
      try{
      let { data , errors } = await this.$apollo
        .mutate({
          mutation: BUY_NUDE,
          variables: {
            id:this.messages[0].id
          },
        })
        if(data) {
          this.imageUrl = this.getURL(data.buyNude.url)
          this.$store.commit("setBalance", data.buyNude.balance)
          // this.$emit("refetchChat")
          this.paid = true
          this.imageHref = this.getURL(data.buyNude.url)
          this.$notify(
          {
            group: "top",
            title: "🎉 Media Purchased 🎉",
            text: "🎉🎊 Media purchased successfully  🎉🎊",
            type: "success", // info, warning
          },
          8000
        );

        this.$confetti.start();
          setTimeout(() => {
            this.$confetti.stop();
          }, 3000);
        }
      }
      catch(e){
        let errorM = e.message.split('GraphQL error: ')[1]
        if(errorM == 'creditlow') {
            this.$notify(
            {
              group: "top",
              title: "Credit low",
              text: "You need minimum 30 flirts to unlock this media",
              type: 'warn',
            },
            2000
          );
          setTimeout(()=>{
            this.$emit('showFlirtOn')
          },2000)
        }

        if(errorM == 'paidalready') {
            alert('paid already')
        }
      }
       

    },
     getNudePic(message_url) {
      //  console.log(event,"refrefref")
      return new Promise((resolve,reject)=>{
      fetch('https://funnyflirts.net/rest/img-blur',{
         method: 'POST',
         headers:{
            'Content-Type': 'application/json'
         },
         body:JSON.stringify({image:message_url})
      })
        // Retrieve its body as ReadableStream
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            }
          })
        })
        // Create a new response out of the stream
        .then((stream) => new Response(stream))
        // Create an object URL for the response
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        // Update image
        .then((url)=>{
            resolve(url)
        })
        .catch((err) => console.error(err));
      })
    }
  },
  props: {
    sent: Boolean,
    messages: Array,
    avatar: String,
  },
};
</script>

