<template>
  <div style="height:362px;overflow:scroll" class="tab-pane fade show active" id="tabs-Latest-Pics" role="tabpanel" aria-labelledby="tabs-home-tab">
      <div  class="grid grid-cols-2 md:grid-cols-3 gap-4 ">
        <div class="relative" v-for="m in allMedia" :key="m.id">
            <template v-if="m.media.is_purchased">
              <video width="400" controls>
                <source :src="m.cloudUrl">
                Your browser does not support HTML video.
              </video>
            </template>
            <template v-else>
               <img  class="h-auto max-w-full rounded-lg " :src="getTHumbnail(m.media)" alt="">
            <button @click="unlockMedia(m.media.id)" type="button" class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800  absolute top-1 right-1 ">Purchase at {{m.media.cost}}</button>
            </template>
        </div>
    </div>
  </div>
</template>

<script>
//
import { GET_CLOUD_URL} from "@/queries/auth";
import { PURCHASE_MEDIA } from "@/queries/matches";

export default {
  data() {
    return {
      allMedia:[]
    }
  },
  mounted() {
    let allPromises = []
    this.media.forEach(async m => {
      if(m.format.includes('video')) {
        let data =  this.setCloudUrl(m)
        allPromises.push(data)
      }
    });
    console.log(allPromises)
    Promise.all(allPromises).then((values) => {
      console.log(values,"valuesss");
      this.allMedia = values
    });
  },
  name: "Tab1",
  props:['purchasedMedia','media'],
  methods:{
     getTHumbnail(media) {
      if(media.thumbnail) return  `https://storage.googleapis.com/mazuproductions/${media.thumbnail}` 
      return 'https://static1.bigstockphoto.com/3/9/3/large2/393923879.jpg' 
    },
    async unlockMedia(id) {
      try {
        let { data } = await this.$apollo.mutate({
          mutation: PURCHASE_MEDIA,
          variables: {
            media: id,
          },
        });
        let credit = data.purchaseMedia;
        this.$notify(
          {
            group: "top",
            title: "🎉 Media Unlocked 🎉",
            text: "🎉🎊 Congrats You have unlocked a new Media 🎉🎊",
            type: "success", // info, warning
          },
          8000
        );

        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
        // if (this.getFormat() == "video") {
        //   this.$refs.videoPlayer.load();
        //   this.$refs.videoPlayer.play();
        // }
        // this.mediaGallery.is_purchased = true;
        this.allMedia = this.allMedia.map((m)=>{
          if(m.media.id == id) {
            m.media.is_purchased = true
          }
          return m
        })
        this.$store.commit("setBalance", credit.balance + credit.flirtons);
      } catch (e) {
        alert('You dont have enough flirts')
      }
    },
    async setCloudUrl(media) {
      try {
        const { data, errors } = await this.$apollo.query({
          query: GET_CLOUD_URL,
          variables: {
            filename: media.mediaCloudName,
          },
        });
        this.purchasedMedia.forEach((pm)=>{
          if(pm.id == media.id) {
            media.is_purchased = true
          }
        })
        return {
          media,
          cloudUrl:data.getCloudUrl
        }
        
      } catch (e) {
        console.log(e);
      }
    },
  }
};
</script>
<style scoped>
.blur {
  filter: blur(6px);
}
</style>