import gql from "graphql-tag";

export const AFF_REGISTRATIONS = gql`query affilateRegistrations($aff: String!) {
    affilateRegistrations(aff: $aff) {
      activities {
        user {
          username
          base_profile {
            firstName
            lastName
          }
          createdAt
        }
        transactions {
          id
          transactionId
          amount
          package {
            id
            price
            packageName
            packageDesc
            packageType
          }
          createdAt
        }
      }
      affilate {
        id
        affilateId
        fullName
      }
    }
  }
  `