<template>
  <div class="grid gap-2 sm:grid-cols-2 grid-cols-2 lg:grid-cols-3 myGallery">
    <router-link  v-for="(gallery, i) of galleries" :key="i" :to="`mediapage/${gallery.id}`">
      <div
        class="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl"
      >
        <img
          class="object-cover w-full h-56 md:h-64 xl:h-80"
          :src="`${baseUrl}/${gallery.poster}`"
          alt="Person"
        />
        <div
          class="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100"
        >
          <p class="mb-1 text-lg font-bold text-gray-100">Unlock</p>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import { GET_COMMUNITY_GALLERIES } from "../../queries/matches";

export default {
  data() {
    return {
      galleries: [],
      baseUrl:`https://${window.location.hostname}`
    };
  },
  apollo: {
    getCommunityGalleries: {
      query: GET_COMMUNITY_GALLERIES,
      result({ data: { getCommunityGalleries } }) {
        this.galleries = getCommunityGalleries;
      },
    },
  },
};
</script>
