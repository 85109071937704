<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirtoolMatches from './FlirtoolMatches.vue'
import YellowMatches from './YellowMatches.vue'
import PurpleMatches from './PurpleMatches.vue'
import RedMatches from './RedMatches.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirtoolMatches,
    YellowMatches,
    PurpleMatches,
    RedMatches
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirtoolMatches'
        case 'Erotic':
          return 'FlirtoolMatches'
        case 'Yellow':
          return 'YellowMatches'
          case 'Purple':
            return 'PurpleMatches'
            case 'Red':
              return 'RedMatches'
        default:
          return 'FlirtoolMatches'
      }
    }
  }
}
</script>
