<template>
	<div class="px-2 py-4 mx-auto bg-white  border-gray-200 h-full  shadow-none drop-shadow-2xl border-0 max-w-screen-xl  md:px-2 lg:px-2 lg:-mt-28"
		:class="{
			globalcompressSet: $mq === 'lg' || $mq === 'xl',
		}">
		<!-- <div class="col-span-2 bordered hidden lg:block xl:block  2xl:block">
			<div class="box-border p-3 border-0 lg:fixed md:mx-20">
				<div class="mx-2 font-medium text-left">
					<a>
						<svg
							style="color: #7e7979"
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 24 24"
							class="inline-block w-6 h-6 mr-2 stroke-current"
						></svg>
							<settings-selectors />
					</a>
				</div>
			</div>
		</div>

		<div class="col-span-10 compressed hidden lg:block xl:block  2xl:block">
		
			<div class="col-span-12 sm:mx-0 lg:mx-0 md:mx-5">
				<div class="mx-2 mb-40 main">
					<div>
						<div
							class="grid bg-white rounded-lg shadow-none dark:bg-gray-800 md:w-9/12"
						>
							<router-view />
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div id="chatbox" class=" lg:px-52">

			<div id="friendslist">
				<div id="topmenu">
					<span class="friends"></span>
					<span class="chats"></span>
					<span class="history"></span>
				</div>
				<!-- <div class="navbar bg-gray-800">
		<div class="navbar-start">
			<div class="">
				<router-link to="/"> <label tabindex="0" class="btn btn-ghost bg-white btn-circle">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
							stroke-width="1.5" stroke="red" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round"
								d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
						</svg>

					</label></router-link>
				
			</div>
		</div>
		<div class="navbar-center">
			<a class=" normal-case text-xl text-white">MAZU CHAT</a>
		</div>
		<div class="navbar-end">
			<button class="btn btn-ghost btn-circle">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
					stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
				</svg>
			</button>
			<a href="/chat" class="text-green-500"> <button class="btn btn-ghost bg-white btn-circle">
					<div class="indicator">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
							stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round"
								d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
						</svg>



					</div>
				</button></a>
		</div>
	</div> -->
				<div id="friends"  @click="toggleChatView">



					<div class="friend" >
						<settings-selectors />
						<!-- <div class="status available"></div> -->
					</div>



					<!-- <div id="search">
			<input type="text" id="searchfield" value="Search contacts..." />
		</div> -->

				</div>

			</div>

			<div id="chatview" class="p1"  v-if="isChatVisible">
				<!-- <div id="profile">

					<div id="close">
						<div class="cy bg-red-500 py-2 px-2 glass uppercase text-white font-bold shadow-2xl">click here to go
							back </div>
						
					</div>

					
				</div> -->
				<div id="chat-messages" >


					<div class="message h-screen glass"><div id="profile">

<div id="close">
<a href="/general-settings">	<div class="cy bg-red-500 py-2 px-2 glass uppercase text-white font-bold shadow-2xl cu">click here to go
		back </div></a>
	
</div>


</div>
						<router-view />
					</div>



				</div>

				<!-- <div id="sendmessage">
	
	</div> -->

			</div>
		</div>


		<!-- new version -->




		<!-- new verison -->


		<!-- end of desktop version -->



		<!-- <div class="col-span-2 bordered lg:hidden xl:hidden 2xl:hidden">
			<label for="my-modal-3" class="btn border-0 bg-pink-500 text-sm btn-sm text-white shadow-2xl"> More settings options</label>

			
			<input type="checkbox" id="my-modal-3" class="modal-toggle" />
			<div class="modal">
				<div class="modal-box relative bg-white opacity-85">
					<label for="my-modal-3" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
					<div class="box-border p-3 border-0 lg:fixed md:mx-20">
						<div class="mx-2 font-medium text-left">
							<a>
								<svg style="color: #7e7979" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
									viewBox="0 0 24 24" class="inline-block w-6 h-6 mr-2 stroke-current"></svg>
								
								<settings-selectors />
							</a>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="col-span-10 compressed lg:hidden xl:hidden 2xl:hidden">
		
			<div class="col-span-12 sm:mx-0 lg:mx-0 md:mx-5">
				<div class="mx-2 mb-40 main">
					<div>
						<div class="grid bg-white rounded-lg shadow-none dark:bg-gray-800 md:w-9/12">
							<router-view />
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="w-full ">
			<!-- <section id="bottom-navigation" class="fixed inset-x-0 bottom-0 z-10 block bg-white shadow md:hidden"> // if shown only tablet/mobile-->

		</div>



	</div>
</template>

<script>
import settingsSelectors from '../../components/settingsSelectors.vue';
// import GeneralSettings from "../../components/generalSettings.vue";
import { DEFAULT_SETTINGS } from '../../queries/auth';
import Mynewfuctionfrom from '@/assets/css/chat.min.js'
import Mynewfuction from '@/assets/css/jsforchat.js'

export default {
	components: { settingsSelectors },
	created() {
		// this.fetchDefaultSettings()
	},
	mounted() {
		// Now you can use functions or variables from myScript.js
		Mynewfuctionfrom.someFunction();
		Mynewfuction.someFunction();

	},
	data: () => ({
		defaultSettings: {}
	}
	),
	data() {
    return {
      isChatVisible: false
    };
  },
	methods: {
		fetchDefaultSettings() {
			this.$apollo.query({
				query: DEFAULT_SETTINGS,
			}).then(({ data }) => {
				this.defaultSettings = data.userSettingsData.user;
			}).catch(e => {
				console.error(e)
			})
		},
		toggleChatView() {
      this.isChatVisible = !this.isChatVisible;
    },
	}
};
</script>
<style scope>
.wrapper {
	overflow-x: scroll;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	height: 100%;
	white-space: nowrap;
}

.elem {
	display: inline-block;
	width: 40%;
	height: 100%;
	margin: 10px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 20px;
	background: rgba(128, 128, 128, 0.418);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(128, 128, 128, 0.418);
}

.customActive {
	color: rgb(255, 111, 135);
	border-left: 4px solid rgb(255, 111, 135);
	padding-left: 5px;
	transition: all 0.5s;
}

.mainContent {
	min-height: 70%;
	min-height: -webkit-calc(100% - 186px);
	min-height: -moz-calc(100% - 186px);
	min-height: calc(100% - 186px);
}
</style>