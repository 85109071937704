<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolFindmatches from './FlirttoolFindmatches.vue'
import YellowFindmatches from './YellowFindmatches.vue'
import PurplethemeFindmatches from './PurplethemeFindmatches.vue'
import RedthemeFindmatches from './RedthemeFindmatches.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirttoolFindmatches,
    PurplethemeFindmatches,
    YellowFindmatches,
    RedthemeFindmatches
    

    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolFindmatches'
        case 'Purple':
          return 'PurplethemeFindmatches'
        case 'Erotic':
          return 'FlirttoolFindmatches'
        case 'Yellow':
          return 'YellowFindmatches'
       case 'Red':
        return 'RedthemeFindmatches'
        default:
          return 'FlirttoolFindmatches'
      }
    }
  }
}
</script>
