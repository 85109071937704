<template>
  <!-- component -->
  <section class="font-mono bg-white container mx-auto px-5">
    <div class="flex flex-col items-center py-8">
      <div class="flex flex-col w-full mb-12 text-left">
        <div class="w-full mx-auto lg:w-1/2">
          <h1
            class="mx-auto mb-24 text-2xl font-semi-bold text-black lg:text-3xl font-sans uppercase"
          >
            Spring feelings
          </h1>
          <img class="rounded-sm" src="@/assets/image_2022-02-21_01-27-09.png" />
          <h2 class="mx-auto mt-4 mb-4 text-xl font-semibold text-black">
            1. Weekend getaway
          </h2>
          <p
            class="mx-auto text-base font-medium leading-relaxed text-gray-800 font-sans"
          >
            Getting out of town is always our favorite type of date! It’s a great way to
            break out of a rut that we might be in, do new things, and have new adventures
            together. Take advantage of the beautiful spring weather and go visit a nearby
            town for a weekend getaway.
          </p>
          <h3 class="mx-auto mt-4 mb-4 text-xl font-semibold text-black"></h3>
          <p class="mx-auto text-base font-medium leading-relaxed text-gray-800"></p>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center py-8">
      <div class="flex flex-col w-full mb-12 text-left">
        <div class="w-full mx-auto lg:w-1/2">
          <h1
            class="mx-auto mb-6 text-2xl font-semi-bold text-black lg:text-3xl font-sans uppercase"
          ></h1>
          <img class="rounded-sm" src="@/assets/image_2022-02-21_01-40-18.png" />
          <h2 class="mx-auto mt-4 mb-4 text-xl font-semibold text-black">
            2. Go strawberry picking
          </h2>
          <p
            class="mx-auto text-base font-medium leading-relaxed text-gray-800 font-sans"
          >
            We went strawberry picking together last spring and it was one of our favorite
            spring date ideas! It’s so much fun to be outside working together and
            strawberry fields are so pretty. We took home a giant bucket of plump sweet
            strawberries and had so much fun for the rest of the week figuring out
            different things to make with them. One of our favorites were fresh strawberry
            Bellinis that we served with alfresco brunch on our patio. Such a romantic
            springtime date! <br class="mb-5" />Ideas of things to make with your
            strawberries: <br />
            - Strawberry shortcake<br />- Strawberry cocktails (muddle them up, make them
            into a simple syrup, or blend them into a juice to use in your cocktail)
            <br />- Strawberry ice cream (easy with this ice cream maker) <br />-
            Strawberry smoothies<br />- Put sliced strawberries in your salad<br />
            - Chocolate covered strawberries<br />- Strawberry pie<br />- You could even
            challenge each other to a cook off and see who can come up with the best
            strawberry recipe!
          </p>

          <h3 class="mx-auto mt-4 mb-4 text-xl font-semibold text-black"></h3>
          <p class="mx-auto text-base font-medium leading-relaxed text-gray-800"></p>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center py-8">
      <div class="flex flex-col w-full mb-12 text-left">
        <div class="w-full mx-auto lg:w-1/2">
          <h1
            class="mx-auto mb-6 text-2xl font-semi-bold text-black lg:text-3xl font-sans uppercase"
          ></h1>
          <img class="rounded-sm" src="@/assets/photo_2022-02-22_15-16-33.jpg" />
          <h2 class="mx-auto mt-4 mb-4 text-xl font-semibold text-black">
            3. Rent paddle boats
          </h2>
          <p
            class="mx-auto text-base font-medium leading-relaxed text-gray-800 font-sans"
          >
            Renting paddle boats is one of the cutest spring date ideas! Find a park near
            you that has paddle boats and rent one for a romantic ride together. Doesn’t
            this spring date idea remind you of that cute boat scene in The Notebook?
          </p>
          <h3 class="mx-auto mt-4 mb-4 text-xl font-semibold text-black"></h3>
          <p class="mx-auto text-base font-medium leading-relaxed text-gray-800"></p>
        </div>
      </div>
    </div>
    <div class="float-left mx-4 mt-16 mb-10 lg:mx-32 xl:mx-32">
      <ul v-if="pages" class="list-reset">
        <li class="inline-block mr-1 border border-gray-700 dark:border-gray-200">
          <a
            href="#"
            class="block px-4 py-3 no-underline border-none dark:text-gray-200 text-grey-darker btn"
            :class="{
              'bg-grey-lightest text-grey cursor-not-allowed': currentPage == 1,
            }"
            @click.prevent="getPreviousPage"
            >Previous</a
          >
        </li>
        <li
          v-for="(page, index) in range"
          :key="index"
          class="inline-block border-t border-b border-l border-gray-700 dark:border-gray-200 hover:bg-blue-lightest"
          :class="{ 'border-r': index == range.length - 1 }"
        >
          <a
            v-if="page != '...'"
            href="#"
            class="block px-4 py-3 no-underline border-none text-grey-darker btn dark:text-gray-200 dark:border-gray-200"
            :class="{ 'bg-blue-lighter': page == currentPage }"
            @click.prevent="getPage(page)"
          >
            {{ page }}
          </a>
          <a v-else href="#" class="block px-4 py-3 no-underline text-grey-darker">
            {{ page }}
          </a>
        </li>
        <li
          class="inline-block ml-1 border border-gray-700 hover:bg-blue-lightest dark:border-gray-200"
        >
          <a
            href="#"
            class="block px-4 py-3 no-underline border-none text-grey-darker btn dark:text-gray-200"
            :class="{
              'bg-grey-lightest text-grey cursor-not-allowed': currentPage >= pages,
            }"
            @click.prevent="getNextPage"
            >Next</a
          >
        </li>
      </ul>
    </div>
  </section>
</template>
>
<script>
// import * as contentful from 'contentful'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import {
  ALL_AUTHORS,
  GET_AUTHOR,
  GET_AUTHOR_CONTENT,
  GET_BLOGS,
  GET_RECENT_POST,
  SINGLE_AUTHOR_CONTENT,
} from "../../queries/cms";

export default {
  apollo: {
    blogCollection: {
      query: GET_BLOGS,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
      variables: {
        limit: 3,
      },
    },
    authorCollection: {
      query: ALL_AUTHORS,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
      variables: {
        limit: 4,
      },
      result() {
        this.loadingContent = false;
      },
    },
  },
  computed: {
    propsToWatch() {
      return this.pages, this.currentPage, Date.now();
    },
  },
  watch: {
    propsToWatch: {
      handler: "organisePageLinks",
      immediate: true,
    },
  },
  data: () => ({
    pages: 2,
    range: [],
    currentPage: 1,
    recentPosts: null,
    blogCollection: [],
    currentAuthor: null,
    authorCollection: [],
    loadingContent: true,
    currentContent: null,
    contentHtml: "",
  }),
  created() {
    this.fetchRandomContent();
    this.loadRecentPosts();
  },
  methods: {
    organisePageLinks() {
      this.range = [];
      for (let i = 1; i <= this.pages; i++) {
        if (
          i == 1 || // first page
          i == this.pages || // last page
          i == this.currentPage || // current page
          i == this.currentPage - 1 || // page before current
          i == this.currentPage + 1 || // page after current
          (i <= 4 && this.currentPage < 4) || // "filler" links at start
          (i >= this.pages - 3 && this.currentPage > this.pages - 3) // "filler" links at end
        ) {
          let index = this.range.length;
          if (index > 0 && this.range[index - 1] < i - 1) {
            // if this page is not the next one insequence, add in 3 dots "..."
            this.range.push("...");
          }
          this.range.push(i);
        }
      }
    },
    getPage(page) {
      this.currentPage = page;
      this.fetchRandomContent();
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      // this.$emit('page-changed', page)
    },
    getPreviousPage() {
      this.getPage(this.currentPage - 1);
    },
    getNextPage() {
      this.getPage(this.currentPage + 1);
    },
    formatDate(date) {
      return new Date(date).toDateString();
    },
    getAuthor(content) {
      this.$apollo
        .query({
          query: GET_AUTHOR,
          client: "cms",
          context: {
            headers: {
              Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
          variables: {
            id: content.author.sys.id,
          },
        })
        .then(({ data: { author } }) => {
          this.currentAuthor = author;
          this.currentContent = content;
          this.contentHtml = documentToHtmlString(content.content.json);
        });
    },
    loadRecentPosts() {
      this.$apollo
        .query({
          query: GET_RECENT_POST,
          fetchPolicy: "network-only",
          client: "cms",
          context: {
            headers: {
              Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
          variables: {
            limit: 1,
            order: "sys_firstPublishedAt_DESC",
          },
        })
        .then(
          ({
            data: {
              affilateContentCollection: { items },
            },
          }) => {
            this.recentPosts = items;
          }
        );
    },
    fetchRandomContent() {
      const skip = this.currentPage === 1 ? 0 : this.currentPage - 1;
      this.$apollo
        .query({
          query: SINGLE_AUTHOR_CONTENT,
          client: "cms",
          context: {
            headers: {
              Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
          loadingContent: true,
          variables: {
            limit: 1,
            skip,
          },
        })
        .then(
          ({
            data: {
              affilateContentCollection: { items, total },
            },
          }) => {
            if (!total) {
              console.error("No content to show");
              return;
            }
            this.pages = total;
            this.getAuthor(items[0]);
          }
        )
        .finally(() => {
          this.loadingContent = false;
        });
    },
    getEmbedUrl(link) {
      var res = link.split("=");
      var embeddedUrl = "https://www.youtube.com/embed/" + res[1];
      return embeddedUrl;
    },
    loadAffilateContent(auth) {
      this.loadingContent = true;
      this.currentAuthor = auth;
      this.$apollo
        .query({
          query: GET_AUTHOR_CONTENT,
          client: "cms",
          fetchPolicy: "network-only",
          variables: {
            where: {
              affilateShort: auth.authShort,
            },
          },
          context: {
            headers: {
              Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
        })
        .then(
          ({
            data: {
              affilateContentCollection: { items },
            },
          }) => {
            if (items.length === 0) {
              console.error("Author has zero contents");
              return;
            }
            this.currentContent = items[0];
            this.contentHtml = documentToHtmlString(items[0].content.json);
          }
        )
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loadingContent = false;
        });
    },
    toDetail(blog) {
      window.open("/detail/" + blog, true);
    },
  },
};
</script>
<style>
p {
  display: block;
  padding-top: 20px;
}
</style>