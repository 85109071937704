<template>
  <a href="#" class="">
    <div
      class="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl"
    >
      <a target="_blank" :href="getSrc()" v-if="getFormat() == 'image'"
        ><img
          :src="getSrc()"
          alt="Person"
          class="object-cover w-full h-56 md:h-64 xl:h-80"
      /></a>
      <video
        v-else-if="getFormat() == 'video'"
        class="object-cover w-full h-56 md:h-64 xl:h-80"
        controls
        ref="videoPlayer"
      >
        <source :src="getSrc()" type="video/mp4" />
      </video>
      <div v-if="mediaGallery.is_paid">
        <div
          v-if="mediaGallery.is_purchased == false"
          class="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100"
        >
          <p class="mb-1 text-lg font-bold text-gray-100">
            Unlock Media for <b>{{ mediaGallery.cost }}</b> flirts
          </p>
          <button
            @click.prevent="unlockMedia"
            class="mx-2 text-pink-100 btn bg-pink-500 btn-accent hover:bg-pink-200 dark:hover:bg-gray-800"
          >
            <p class="pt-1 font-sans text-lg text-pink-50 px-7">Unlock Now</p>
          </button>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import { PURCHASE_MEDIA } from "../../queries/matches";
export default {
  props: ["mediaGallery"],
  data() {
    return {
      baseUrl:`https://${window.location.hostname}`
    }
  },
  mounted() {
    //
  },
  methods: {
    getFormat() {
      if (this.mediaGallery.format) {
        return this.mediaGallery.format.split("/")[0];
      }
      return null;
    },
    getSrc() {
      if (!this.mediaGallery.is_paid) {
        return `${this.baseUrl}/rest/stream/${this.mediaGallery.id}`;
      }
      if (this.mediaGallery.is_purchased) {
        return `${this.baseUrl}/rest/stream/${this.mediaGallery.id}?token=${this.$store.state.core.flirtok}`;
      }
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png";
    },
    videoError(e) {
      console.log(e);
    },
    async unlockMedia() {
      try {
        let { data } = await this.$apollo.mutate({
          mutation: PURCHASE_MEDIA,
          variables: {
            media: this.mediaGallery.id,
          },
        });
        let credit = data.purchaseMedia;
        this.$notify(
          {
            group: "top",
            title: "🎉 Media Unlocked 🎉",
            text: "🎉🎊 Congrats You have unlocked a new Media 🎉🎊",
            type: "success", // info, warning
          },
          8000
        );

        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
        if (this.getFormat() == "video") {
          this.$refs.videoPlayer.load();
          this.$refs.videoPlayer.play();
        }
        this.mediaGallery.is_purchased = true;
        this.$store.commit("setBalance", credit.balance + credit.flirtons);
      } catch (e) {}
    },
  },
};
</script>
