<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="px-4 hidden lg:block py-0 -mt-28  mx-auto sm:max-w-xl md:max-w-full shadow-2xl lg:max-w-screen-xl md:px-24 lg:px-1">
		<div class="text-left mb-10 mt-3 mx-2 dark:text-gray-200">
			<h2 class="text-4xl font-black font-sans">{{$t('MyPurchases')}}</h2>
			<p>{{$t('mypurchasedes')}}</p>
		</div>
		<div class="-my-2 mx-2 overflow-x-auto sm:-mx-6 lg:-mx-9">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div
					class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
					v-if="transactions.length > 0"
				>
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50 dark:bg-gray-700">
							<tr>
								<th
									scope="col"
									class="
										dark:text-gray-200
										px-6
										py-3
										text-left text-xs
										font-medium
										text-gray-500
										uppercase
										tracking-wider
									"
								>
									Date
								</th>
								<th
									scope="col"
									class="
										dark:text-gray-200
										px-6
										py-3
										text-left text-xs
										font-medium
										text-gray-500
										uppercase
										tracking-wider
									"
								>
									Package Name
								</th>
								<th
									scope="col"
									class="
										dark:text-gray-200
										px-6
										py-3
										text-left text-xs
										font-medium
										text-gray-500
										uppercase
										tracking-wider
									"
								>
									Status
								</th>
								<th
									scope="col"
									class="
										dark:text-gray-200
										px-6
										py-3
										text-left text-xs
										font-medium
										text-gray-500
										uppercase
										tracking-wider
									"
								>
									Amount
								</th>
								<th
									scope="col"
									class="
										dark:text-gray-200
										px-6
										py-3
										text-left text-xs
										font-medium
										text-gray-500
										uppercase
										tracking-wider
									"
								>
									Source
								</th>
							</tr>
						</thead>
						<tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200">
							<tr v-for="(trans, idx) in transactions" :key="idx">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="flex items-center">
										<div class="ml-4">
											<div
												class="
													text-sm
													font-medium
													text-gray-900
													dark:text-gray-200
												"
											>
												{{ parseDate(trans.createdAt) }}
											</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900 dark:text-gray-200">
										{{ trans.transactionName }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap dark:text-gray-200">
									<span
										v-if="trans.transactionStatus === 'approved'"
										class="
											px-2
											inline-flex
											text-xs
											leading-5
											font-semibold
											rounded-full
											bg-green-100
											text-green-800
										"
									>
										Approved
									</span>
									<span
										v-else-if="trans.transactionStatus === 'declined'"
										class="
											px-2
											inline-flex
											text-xs
											leading-5
											font-semibold
											rounded-full
											bg-red-100
											text-red-800
										"
									>
										Declined
									</span>

									<span
										v-else-if="trans.transactionStatus === 'pending'"
										class="
											px-2
											inline-flex
											text-xs
											leading-5
											font-semibold
											rounded-full
											bg-yellow-100
											text-yellow-800
										"
									>
										Pending
									</span>
								</td>
								<td
									class="
										px-6
										dark:text-gray-200
										py-4
										whitespace-nowrap
										text-sm text-gray-500
									"
								>
									{{ trans.amount }} USD
								</td>
								<td
									class="
										px-6
										dark:text-gray-200
										py-4
										whitespace-nowrap
										text-sm text-gray-500
									"
								>
									{{ trans.paymentSource }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h2 v-else class="px-3 text-xl text-left dark:text-gray-200">{{$t('mypurchacedess')}}</h2>
			</div>
		</div>
	</div>
</template>

<script>
import { TRANSACTIONS } from '../../queries/matches'
// const transactions = [
// 	{
// 		createdAt: new Date().toDateString(),
// 		packageName: 'Cool Package $200',
// 		status: 'pending',
// 		amount: '$200',
// 	},
// 	// More people...
// ]

export default {
	apollo: {
		transactions: {
			query: TRANSACTIONS,
		}
	},
	created() {
		this.$apollo.queries.transactions.refetch()
	},
	methods: {
		parseDate(date) {
			return new Date(parseInt(date)).toDateString()
		},
	},
	data() {
		return {
			transactions: null
		}
	}
}
</script>