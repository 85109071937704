<template>
    <div class="image-slider">
      <div class="slider-container">
        <div class="slider-wrapper">
          <div class="slider-slide">
            <img :src="getSlide" class="relative absolute inset-0 justify-center object-cover w-full h-full" style="object-fit: ; width: 100%; height: 695px;">
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    computed:{
      getSlide() {
         let currentHour = new Date().getHours()
         var slide;
          if(currentHour >= 0 && currentHour <= 6) {
            slide = this.slides[0]
          }else if(currentHour >= 6 && currentHour <= 12) {
            slide = this.slides[1]
          }else if(currentHour >= 12 && currentHour <= 18) {
            slide = this.slides[2]
          }else if(currentHour >= 18 && currentHour <= 23) {
            slide = this.slides[3]
          }else{
            slide = this.slides[0]
          }
          // alert(slide)
        return slide;
      }
    },
    data() {
      return {
        slides: [
          require('@/assets/sliders/img1.jpg'),
          require('@/assets/sliders/img2.jpg'),
          require('@/assets/sliders/img3.jpg'),
          require('@/assets/sliders/img4.jpg'),
        ]
      };
    },
  };
  </script>
  
  <style>
  .image-slider {
    position: relative;
  }
  
  .slider-container {
    overflow: hidden;
  }
  
  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slider-slide {
    flex: 0 0 100%;
  }
  
  .slider-slide img {
    width: 100%;
    height: auto;
  }
  
  .slider-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .slider-control {
    border: none;
    background: none;
    padding: 0;
    margin: 0 5px;
    cursor: pointer;
    outline: none;
  }
  
  .slider-control.active img {
    border: 2px solid blue;
  }
  </style>
  