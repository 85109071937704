<template>
  <!-- component -->
  <footer class="mt-16">
    <div class="border-t md:px-4 md:pt-10 md:pb-5 text-left">
      <div class="flex flex-wrap md:max-w-screen-lg mx-auto">
        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              FLIRTTOOL
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
              text-left
            "
            href="#"
          >
            FlirtTool
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide text-left">
              <li class="my-3 tracking-wide">
                <a href="#">Flirttool US</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Flirttool UK</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">FlirtTool CA</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Flirttool NZ</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Flirttool AU</a>
              </li>
            </ul>
          </article>
        </section>
        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              Ut porta
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
              text-left
            "
            href="#"
          >
            FlirtTool Other
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide text-left">
              <li class="my-3 tracking-wide">
                <a href="#">Login</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Register</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Support</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Community</a>
              </li>
            </ul>
          </article>
        </section>
        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              Praesent elementum
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
              text-left
            "
            href="#"
          >
            Legal Pages
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide text-left">
              <li class="my-3 tracking-wide">
                <a href="#">Terms</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Privacy</a>
              </li>
              <li class="my-3 tracking-wide">
                <a href="#">Imprint</a>
              </li>
            </ul>
          </article>
        </section>

        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              Aenean gravida orci in sem varius
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
              text-left
            "
            href="#"
          >
            Social Media
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide text-left">
              <li class="my-3 tracking-wide">
                <a href="#"></a>
              </li>
            </ul>
          </article>
        </section>
        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              Donec a lorem
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
            "
            href="#"
          >
            FlirtTool Press Release
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide">
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.digitaljournal.com/pr/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 1</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.wicz.com/story/45744098/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 2</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.abnewswire.com/pressreleases/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality_583849.html"
                  >News 3</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.snntv.com/story/45744098/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 4</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.thecowboychannel.com/story/45744098/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 5</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.seoraksantimes.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 6</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.rfdtv.com/story/45744098/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 7</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.benzinga.com/pressreleases/22/01/ab25269740/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-pe"
                  >News 8</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.issuewire.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality-1722862466367253"
                  >News 9</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.ahmedabadlocal.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 10</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.timesofchennai.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 11</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.sahyadritimes.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 12</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.openheadline.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 13</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.kolkatalocal.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 14</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://rivercountry.newschannelnebraska.com/story/45744098/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 15</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.timesofsurat.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 16</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.pitchscoop.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 17</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.gionewsuk.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 18</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.openpr.com/news/2538607/flirttool-s-mazu-artificial-intelligence-personality-match"
                  >News 19</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.openpr.com/news/2538306/flirttool-roll-out-first-dating-community-for-influencers-in"
                  >News 20</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.the2sidestory.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 21</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.independentecho.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 22</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.guardiantalks.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 23</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.floridatimesdaily.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 24</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.yournewsdigest.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 25</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.truthclassified.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 26</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.journalrecital.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 27</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.dailypune.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 28</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.smartherald.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 29</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.bostonnewtimes.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 30</a
                >
              </li>
            </ul>
          </article>
        </section>
        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              Integer interdum
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
            "
            href="#"
          >
            News
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide">
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.digitaljournal.com/pr/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality"
                  >News 31</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.fujitimes.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 32</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.newspostbox.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 33</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.digiobserver.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 34</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.georgiaheralds.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 35</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.prestigestandard.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 36</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.buzzillustrated.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 37</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.miamitimesnow.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 38</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.instadailynews.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                >
                  News 39</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.thedailynewsjournal.us/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 40</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.newslinehub.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 41</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.anewsweek.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 42</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.graphdaily.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 43</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.soundermirror.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 44</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.dailyscotlandnews.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 45</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.newsfeedcentral.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 46</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.newsfeedcentral.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 47</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.weeklyoptimist.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 48</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.weeklyoptimist.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 49</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.justexaminer.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 50</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.justexaminer.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 51</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.fortunebulletin.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 52</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.pacificdaily.us/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 53</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.insiderquest247.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 54</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.counselbroadcast.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 55</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.entrepreneuryak.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 56</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.rainierwatchdog.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 57</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.houstonmetronews.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 58</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.dailymichigannews.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 59</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.dailymichigannews.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 60</a
                >
              </li>
            </ul>
          </article>
        </section>
        <section
          class="
            relative
            text-gray-700
            font-light font-light
            border-b
            px-4
            pb-4
            md:py-3
            w-full
            md:border-none md:w-1/4
          "
        >
          <div class="md:hidden">
            <button
              onclick="toggleFooterSection(event)"
              class="
                uppercase
                text-xs
                font-bold
                tracking-wider
                text-pink-700
                focus:outline-none
                border-t border-white
                py-4
                w-full
                text-left
              "
              type="button"
            >
              Quisque
            </button>
          </div>
          <a
            class="
              uppercase
              text-xs
              font-bold
              tracking-wider
              text-pink-700
              hidden
              md:block
            "
            href="#"
          >
            Quisque
          </a>
          <article class="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden">
            <ul class="my-5 text-sm tracking-wide">
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.peoplereportage.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 61</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.gazettemaker.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 62</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.seoraksantimes.com/rose-sanders-law-mcallen-hidalgo-county-attorneys-represent-clients-injured-in-trucking-accidents/"
                  >News 63</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.coveragepeople.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 64</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.insidernotice.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 65</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.candourtoday.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 66</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.watchmirror.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 67</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.digestexpress.us/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 68</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.fortuneoutlook.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 69</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.dailysworld.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 70</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.editionbiz.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 71</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.opinionbulletin.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 72</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.metrosnoop.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 73</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.timesofchennai.com/2022/01/25/indias-biggest-pooja-accessories-brand-satvik-offers-customization-of-pooja-kits-feature-on-their-website/"
                  >News 74</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.automotivespeak.com/2022/01/25/bookscrit-right-place-to-get-reliable-book-marketing-services-for-your-books/"
                  >News 75</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://enviromagazine.com/2022/01/25/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 76</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.realprimenews.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 77</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://lifevoyageurs.com/news/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/358547"
                  >News 78</a
                >
              </li>

              <li class="my-3 tracking-wide">
                <a
                  href="https://www.heraldport.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 79</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.dailydigitalhealth.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 80</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.emeraldjournal.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 81</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.fortunetabloid.com/flirttool-presents-the-first-online-dating-community-featuring-mazu-an-artificial-intelligence-personality/"
                  >News 82</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://business.guymondailyherald.com/guymondailyherald/news/category?Category=Family+%26+Parenting"
                  >News 83</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://marketnewswire.live/index.php/2022/01/25/flirttool-roll-out-first-dating-community-for-influencers-in-the-us/"
                  >News 84</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="http://www.oklahomacityheadlines.com/news/story/280603/flirttool-roll-out-first-community-for-influencers-in-the-us.html"
                  >News 85</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://virtual-strategy.com/2022/01/31/flirttools-mazu-brings-singles-together/"
                  >News 86</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://www.digitaljournal.com/pr/flirttools-mazu-brings-singles-together"
                  >News 87</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="http://finance.dailyherald.com/dailyherald/news/read/42166402/Valentines_Day_Fun_with_Top_US_Influencer_Dash_Preistley_and_FlirtTool"
                  >News 88</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://fwnbc.marketminute.com/article/issuewire-2022-2-3-valentines-day-fun-with-top-us-influencer-dash-preistley-and-flirttool"
                  >News 89</a
                >
              </li>
              <li class="my-3 tracking-wide">
                <a
                  href="https://yorkpedia.com/2022/02/03/valentines-day-fun-with-top-us-influencer-dash-preistley-and-flirttool/"
                  >News 90</a
                >
              </li>
            </ul>
          </article>
        </section>
      </div>
    </div>
    <div class="max-w-screen-lg mx-auto border-none px-4">
      <section
        class="
          flex flex-col
          md:flex-row md:justify-between md:border-solid md:border-t
          text-gray-700
          font-light
          text-sm
          pt-4
          pb-6
          md:pt-5 md:pb-6
          w-full
        "
      >
        <div>
          <p class="leading-8 tracking-wide">
            <!-- &copy; Lorem Ipsum Co., 123 Lorem Street, New York, NY -->
          </p>
        </div>
        <div>
          <p class="leading-8 tracking-wide">Privacy Policy</p>
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
export default {};
</script>