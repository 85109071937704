<template>
  <div>
    <notificationGroup group="top" position="top">
      <div
        style="transform: scale(80%)"
        class="fixed inset-x-0 z-10 flex items-start justify-end p-6 px-4 py-6 mt-5 pointer-events-no"
      >
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex items-center justify-center w-12 bg-pink-500">
                <svg
                  class="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                  ></path>
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3 text-left">
                  <span class="font-semibold text-pink-500">{{
                    notification.title
                  }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <div
      class="flex flex-wrap w-full h-full object-cover justify-center dark:text-gray-800"
      style="object-fit: cover; width: 100%; height: 100%"
    >
      <!-- Login Section -->
      <img
        style="height: 1500px"
        :src="require('@/assets/modlogin/bg.jpg')"
        class="absolute inset-0 justify-center object-cover w-screen h-full sm:"
        alt=""
      />
      <div
        class="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      >
        <div
          class="relative flex flex-col w- py-16 lg:pt-0 lg:flex-col lg:pb-0"
        >
          <div
            style="height: 1000px"
            class="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 items-start w-full max-w-xl bg-white px-4 mx-auto lg:px-8 lg:max-w-screen-xl rounded-xl"
          >
            <div
              class="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
            >
              <div
                class="flex flex-col justify-left  rounded-lg opacity-95 md:justify-start md:pt-0 px-6 relative h-full lg:full"
              >
                
                <p
                  class="mb-0 text-lg font-bold text-center text-gray-800 uppercase dark:text-gray-800 md:text-sm text-light lg:text-2xl font-sans mt-1"
                >
                  <span class="text-pink-500 text-lg sm:text-lg md:text-lg lg:text-5xl xl:text-5xl">MODEL LOGIN </span>
                </p>
                <p
                  class="mx-4 mb-0 mt-5 text-xs font-semibold text-gray-500 uppercase dark:text-gray-800 md:text-sm text-light"
                >
                  Don't have an account?<br />
                  <router-link
                    to="/modelregister"
                    class="font-semibold text-pink-500 underline"
                  >
                    Register here.
                  </router-link>
                </p>
                
                <div class="max-w-lg mx-auto">
                  <!-- <button @click="showSlider = !showSlider">
                    Toggle Slider
                  </button> -->
                  <fieldset class="mt-5 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-2">
                    <legend class="sr-only">Countries</legend>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-1"
                        @change="openImage(5)"
                        type="radio"
                        value="gay"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-1"
                        aria-describedby="country-option-1"
                        checked=""
                      />
                      <label
                        for="country-option-1"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Gay
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-2"
                        @change="openImage(1)"
                        type="radio"
                        value="bisexual"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-2"
                        aria-describedby="country-option-2"
                      />
                      <label
                        for="country-option-2"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Bisexual
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-3"
                        @change="openImage(2)"
                        type="radio"
                        value="lesbian"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-3"
                        aria-describedby="country-option-3"
                      />
                      <label
                        for="country-option-3"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Lesbian
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-4"
                        type="radio"
                        @change="openImage(3)"
                        value="straight"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-4"
                        aria-describedby="country-option-4"
                      />
                      <label
                        for="country-option-4"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Straight
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-4"
                        type="radio"
                        @change="openImage(4)"
                        value="trans"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 "
                        aria-labelledby="country-option-4"
                        aria-describedby="country-option-4"
                      />
                      <label
                        for="country-option-4"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Trans
                      </label>
                    </div>
                  </fieldset>
                </div>
                <!-- end of raido buttons -->

                <form
                  class="flex flex-col pt-3 md:pt-8"
                  v-if="!loadingSocialAuth"
                >
                  <div class="flex flex-col pt-4">
                    <div class="bg-pink-100 alert" v-if="authErr">
                      <div class="flex-1">
                        <label>
                          {{ authErr }}
                        </label>
                      </div>
                    </div>
                    <div class="form-control">
                      <label class="label">
                        <span
                          class="mb-1 text-xs md:text-2xs sm:text-2xs font-semibold text-left text-gray-500 uppercase dark:text-gray-800 md:text-sm text-light"
                          >Username or Email</span
                        >
                      </label>
                      <input
                        @blur="usernameFocus = false"
                        @focus="usernameFocus = true"
                        v-model.trim="$v.emailOrUname.$model"
                        @input="setUname($event.target.value)"
                        class="px-3 py-2 mt-1 border-2 border-pink-300 rounded-none form__input shadow-xl dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-transparent dark:text-gray-800"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.emailOrUname.required,
                        }"
                        id="my-textfield"
                        type="text"
                        value=""
                        placeholder="Username"
                        @keydown.enter="doLogin"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error"
                      v-if="!$v.emailOrUname.required && usernameFocus"
                    >
                      Field is required
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error"
                      v-if="!$v.emailOrUname.minLength"
                    >
                      Name must have at least
                      {{ $v.emailOrUname.$params.minLength.min }} letters.
                    </div>
                  </div>

                  <div class="flex flex-col pt-4">
                    <div class="form-control">
                      <label class="label">
                        <span
                          class="mb-1 font-semi-bold text-left text-gray-500 uppercase dark:text-gray-800 text-xs"
                          >Your password</span
                        >
                      </label>

                      <div class="relative">
                        <input
                          @blur="passFocus = false"
                          @focus="passFocus = true"
                          @keydown.enter="doLogin"
                          @input="setPass($event.target.value)"
                          v-model.trim="$v.password.$model"
                          class="w-full px-3 py-2 mt-1 border-2 border-pink-300 rounded-none shadow-xl dark:bg-gray-700 dark:text-gray-800 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-transparent"
                          :class="{
                            'border-red-500 focus:ring-2 focus:ring-red-500':
                              !$v.password.required,
                          }"
                          id="my-textfield"
                          :type="showPass ? 'text' : 'password'"
                          value=""
                          placeholder="Password"
                        />
                        <!-- validation errors start -->
                        <div
                          class="pt-2 text-left text-red-500 error"
                          v-if="!$v.password.required && passFocus"
                        >
                          Password is required
                        </div>
                        <div
                          class="pt-2 text-center font-sans font-semi-bold text-pink-500 error"
                        >
                          <p class="mx-2 mt-5">
                            Forgot password ?
                            <router-link
                              to="/initial-reset"
                              class="font-bold font-sans"
                              >Reset</router-link
                            >
                          </p>
                          <label
                            class="inline-flex items-center mt-3 text-left"
                          >
                            <input
                              @change="termsChanged"
                              v-model="acceptedTerms"
                              type="checkbox"
                              class="w-8 h-8 border-2 border-pink-500 text-pink-600 form-checkbox"
                              checked
                            /><span
                              class="mx-4 mb-0 text-xs mt-5 font-semibold text-left text-gray-500 uppercase dark:text-gray-800 md:text-sm text-light"
                              >I AGREE TO THE TERMS, CONDITIONS AND PRIVACY
                              POLICY <br />
                              <a
                                class="text-blue-400"
                                href="https://funnyflirts.net/terms"
                                >Click here to view our terms</a
                              >
                            </span>
                          </label>
                        </div>

                        <!-- validation errors end -->
                        <button
                          type="button"
                          class="absolute top-0 right-0 mx-2 mt-2 btn-sm bg-pink btn-outline btn btn-primary border-0"
                          v-text="showPass ? 'Hide' : 'Show'"
                          @click="showPass = !showPass"
                        >
                          Show
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="p-2 mt-5 text-white bg-pink-500 rounded-full shadow-2xl font-semi-bold btn hover:bg-pink-600 font-sans text-lg"
                    :disabled="loadingLogin"
                    @click="doLogin"
                    style="cursor: pointer"
                  >
                    <div v-if="loadingLogin" class="mt-3 lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p v-else>Log in</p>
                  </button>
                  <div
                    class="grid grid-cols-1 gap-0 mt-1 md:grid-cols-1 sm:grid-cols-1"
                  >
                    <div
                      class="grid grid-cols-1 mt-5 mr-0 md:mr-0 hover:text-white"
                    >
                      <button
                        type="button"
                        @click="socialLoginGoogle"
                        class="mt-5 mb-3 bg-gray-50 btn hover:bg-pink-400 hover:text-white rounded-lg shadow-2xl border-pink-500"
                      >
                        <img
                          :src="
                            require('@/assets/google_logo_png_suite_everything_you_need_know_about_google_newest.png')
                          "
                          class="w-5 h-5"
                          alt="Connect with flirttool & start your personal online dating adventure!"
                        />
                        <span
                          class="px-2 font-sans text-xs hover:text-white text-gray-700 dark:text-white"
                        >
                          Log in with Google
                        </span>
                      </button>
                    </div>
                    <div class="grid grid-cols-1 mr-0 md:mr-0">
                      <button
                        type="button"
                        @click="socialLoginFb"
                        class="mt-5 mb-3 bg-gray-50 btn hover:bg-pink-400 hover:text-white rounded-lg shadow-2xl border-pink-500"
                      >
                        <img
                          :src="
                            require('@/assets/Facebook_f_logo_(2019).svg.webp')
                          "
                          class="w-5 h-5"
                          alt="Connect with flirttool & start your personal online dating adventure!"
                        />

                        <span
                          class="px-2 font-sans text-xs text-gray-700 hover:text-white dark:text-white"
                        >
                          Continue with Facebook
                        </span>
                      </button>
                    </div>
                  </div>
                
                </form>
                <center v-else class="mt-10">
                  <div class="mt-3 lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </center>
              
                <p
                  class="mt-2 mb-5 font-semibold text-center text-gray-500 uppercase text-2xs dark:text-gray-800 md:text-2xs text-light"
                >
                  MAZU™ Powered by Dialogmakers International Ltd. 2021<br />
                  <router-link to="/terms" class="text-pink-500">
                    Terms </router-link
                  >|<router-link to="/privacy" class="text-pink-500">
                    Privacy </router-link
                  >|<router-link to="/imprint" class="text-pink-500">
                    Imprint</router-link
                  >
                </p>
             
              </div>
            </div>
          </div>
          <div
            v-if="showImage"
            class="inset-y-0 right-0 w-full max-w-xl px-4 rounded-xl mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:block hidden lg:w-1/2 lg:max-w-full lg:absolute xl:px-0"
          >
            <img
              class="object-cover w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none sm:h-96 lg:h-full"
              :src="currentImage"
              alt=""
            />
          </div>
        </div>
      </div>

      
    </div>
    
  </div>
</template>
<script>
import axios from "axios";
import { UPDATE_LOC } from "../queries/matches";
import { createProvider, onLogin } from "../v-apollo";
import { required, minLength } from "vuelidate/lib/validators";
import { SOCIAL_AUTH_GOOGLE, USER_LOGIN } from "../queries/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

export default {
  data() {
    return {
      showImage: true,
      sexuality:"gay",
      selectedImage: 0,
      showPass: false,
      emailOrUname: "",
      password: "",
      passFocus: false,
      loadingLogin: false,
      usernameFocus: false,
      authErr: "",
      acceptedTerms: false,
      loadingSocialAuth: false,
      images: [
        require("@/assets/modlogin/gay.jpg"),
        require("@/assets/modlogin/bisex.jpg"),
        require("@/assets/modlogin/lesbian.jpg"),
        require("@/assets/modlogin/straight.jpg"),
        require("@/assets/modlogin/Trans.jpg"),
      ],
    };
  },
  // image silder
  computed: {
    currentImage() {
      if(this.sexuality == 'gay') return require("@/assets/modlogin/gay.jpg")
      if(this.sexuality == 'bisexual') return require("@/assets/modlogin/bisex.jpg")
      if(this.sexuality == 'lesbian') return require("@/assets/modlogin/lesbian.jpg")
      if(this.sexuality == 'straight') return require("@/assets/modlogin/straight.jpg")
      if(this.sexuality == 'trans') return require("@/assets/modlogin/Trans.jpg")
    },
  },
  // image silder
  methods: {
    socialLoginFb() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          console.warn("*************************");
          console.warn(result);
          console.warn("*************************");
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                // onLogin(createProvider().defaultClient)
                this.$store.commit("setUserData", user);
                credit.flirtonsAvailable === true
                  ? this.$store.commit("setFlirtonsAvailable", true)
                  : this.$store.commit("setFlirtonsAvailable", false);
                credit
                  ? //? this.$store.commit("setBalance", credit.balance)
                    this.$store.commit(
                      "setBalance",
                      credit.balance + credit.flirtons
                    )
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  if (this.$route.query.to) {
                    window.location.assign("/" + this.$route.query.to);
                  } else {
                    window.location.assign("/find-matches");
                  }
                } else {
                  this.$router.push("/profile-setup");
                }

                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    socialLoginGoogle() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you continue",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }

      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
                userType:'model',
                preference:this.sexuality
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                onLogin(createProvider().defaultClient);
                this.$store.commit("setUserData", user);
                credit.flirtonsAvailable === true
                  ? this.$store.commit("setFlirtonsAvailable", true)
                  : this.$store.commit("setFlirtonsAvailable", false);
                credit
                  ? //? this.$store.commit("setBalance", credit.balance)
                    this.$store.commit(
                      "setBalance",
                      credit.balance + credit.flirtons
                    )
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  if (this.$route.query.to) {
                    window.location.assign("/" + this.$route.query.to);
                  } else {
                    window.location.assign("/find-matches");
                  }
                } else {
                  this.$router.push("/profile-setup");
                }

                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    doLogin() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (!this.$v.$invalid) {
        this.loadingLogin = true;
        this.$apollo
          .getClient()
          .mutate({
            mutation: USER_LOGIN,
            // authErrs: [],
            variables: {
              addr: this.$store.state.core.addr,
              usernameOrEmail: this.emailOrUname,
              password: this.password,
            },
          })
          .then(({ data: { login } }) => {
            console.log(login, "What the heck");
            const { user, credit, token } = login;
            credit.flirtonsAvailable === true
              ? this.$store.commit("setFlirtonsAvailable", true)
              : this.$store.commit("setFlirtonsAvailable", false);
            onLogin(createProvider().defaultClient);
            this.$store.commit("setUserData", user);
            //this.$store.commit("setBalance", credit.balance);
            this.$store.commit("setBalance", credit.balance + credit.flirtons);
            this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("setFlirtTok", token);
            // reset apollo client
            // console.log(user, "user is logged in");
            // window.location.assign("/")
            if (user.profileComplete) {
              // this.$router.push("/find-matches");
              if (this.$route.query.to) {
                window.location.assign("/" + this.$route.query.to);
              } else {
                window.location.assign("/find-matches");
              }
            } else {
              this.$router.push("/profile-setup");
            }
          })
          .catch((e) => {
            console.warn(Object.keys(e));
            Object.keys(e).forEach((x) => {
              console.warn(e[x]);
            });
            console.error(e);
            this.authErr = "You have entered incorrect credentials";
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
    setUname(value) {
      this.emailOrUname = value;
      this.$v.emailOrUname.$touch();
    },
    setPass(value) {
      this.password = value;
      this.$v.password.$touch();
    },
  },
  validations: {
    emailOrUname: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
    },
  },

  metaInfo() {
    return {
      title: "FlirtTool | Login | The finest global  dating website around. ",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool is the first dating community based on a special artificial intelligence (AI) system. Connect with local singles & start your personal online dating adventure!",
        },
        {
          property: "og:title",
          content:
            "FlirtTool | Online Dating | The finest global  dating website around.",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>
