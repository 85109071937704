<template>
  <!-- <FindMatches/> -->
  <chatroom/>
</template>
<script>
import Chatroom from '@/templates/Chatroom/Index.vue'
export default {
  components:{
    Chatroom
  }
}
</script>