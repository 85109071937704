<template> 
  <div class="mt-5 px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-3   relative">
     <!-- <h1 class="text-gray-50 py-3 uppercase">Profile Information</h1> -->
    <center class="flex justify-center items-center h-screen">
      <div
        class="
          mx-3
          card
         shadow-2xl bg-gray-70 bg-opacity-80
          dark:bg-gray-700 dark:border-gray-800
          border border-b-8 border-gray-500 bg-transparent
        "
      >
        <div class="card  shadow-sm bg-accent mx-3 text-accent-content -mt-2">
        <h1 class="text-gray-50 py-6 lg:text-xl text-lg uppercase  flex flex-wrap justify-center mx-auto gap-2"><img class="h-8" src="@/assets/profilesetup/EditProfile.png" alt="">Profile info </h1>
          <div class="grid  md:grid-cols-2 grid-cols-2 gap-5 md:gap-8 mt-1">
            <div class="grid grid-cols-1 mt-5 md:mr-0">
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  text-xs text-gray-50 text-light
                  font-semibold font-sans
                  dark:text-gray-200
                "
                >{{$t('Height')}}</label
              >

              <input
                class="
                  py-6
                  input-sm
                  px-3
                  ml-2
                  dark:bg-gray-700
                  rounded-lg
                  border-2 border-gray-500 bg-transparent
                  mt-1 shadow-lg
                  focus:outline-none
                  dark:text-gray-200 text-gray-50
                  focus:ring-2 focus:ring-gray-500 focus:border-transparent font-sans uppercase
                "
                v-model="height"
                placeholder="Your Height"
                type="number"
              />
            </div>
            <div class="grid grid-cols-1 mt-5 md:ml-0">
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  dark:text-gray-200
                  text-xs text-gray-50 text-light
                  font-semibold font-sans 
                "
                >{{$t('HairColor')}}</label
              >

              <select
                class="
                  select-md select select-bordered select-secondary
                  w-full py-1
                  border-2 border-gray-500
                  dark:bg-gray-700 dark:text-gray-200 shadow-lg text-gray-50 font-sans uppercase
                "
                v-model="hair_color"
              >
                <option disabled="disabled" selected="selected">
                  What's your Hair Color
                </option>
                <option class="text-black"
                  v-for="(type, idx) in hairTypes"
                  :key="idx"
                  v-text="type"
                ></option>
                <!-- <option>Burnette</option>
								<option>Redhead</option> -->
              </select>
            </div>
          </div>
          <div
            class="
              grid
              xl:grid-cols-3
              lg:grid-cols-3
              md:grid-cols-2 grid-cols-3
              w-full
              mt-5
              gap-5
              text-left px-3
            " 
          >
            <div>
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  dark:text-gray-200
                  text-xs text-gray-50 text-light
                  font-semibold font-sans bg
                "
                >{{$t('BirthYear')}}</label
              >

              <select
                class="
                  select-md select select-bordered select-secondary
                  w-full
                  border-2 border-gray-500  
                  dark:bg-gray-700 dark:text-gray-200 shadow-lg  text-gray-50 font-sans uppercase 
                "
                v-model="byear"
              >
                <option  disabled="disabled" selected="selected">{{$t('Date')}}</option>
                <option class="text-black"
                  v-for="(type, idx) in validYears()"
                  :key="idx"
                  v-text="type"
                ></option>
              </select>
            </div>

            <div>
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  dark:text-gray-200
                  text-xs text-gray-50 text-light
                  font-semibold font-sans
                "
                >{{$t('Month')}}</label
              >
              <select
                class="
                  select-md select select-bordered select-secondary px-3
                  w-full
                  border-2 border-gray-500
                  dark:bg-gray-700 dark:text-gray-200 shadow-lg  text-gray-50 py-1 font-sans uppercase
                "
                v-model="bmonth"
              >
                <option disabled="disabled" selected="selected">Date</option>
                <option class="text-black"
                  v-for="(type, idx) in months"
                  :key="idx"
                  v-text="type"
                ></option>
              </select>
            </div>
            <div>
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  dark:text-gray-200
                  text-xs text-gray-50 text-light
                  font-semibold font-sans
                "
                >{{$t('Day')}}</label
              >
              <select
                class="
                  select-md select select-bordered select-secondary
                  w-full
                  border-2 border-gray-500
                  dark:bg-gray-700 dark:text-gray-200 text-gray-50 shadow-lg py-1 font-sans uppercase
                "
                v-model="bday"
              >
                <option disabled="disabled" selected="selected">Date</option>
                <option class="text-black"
                  v-for="(type, idx) in range(1, 32)"
                  :key="idx"
                  v-text="type"
                ></option>
              </select>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 grid-cols-2 gap-5 md:gap-8 mt-1 px-3">
            <!-- <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mt-5 gap-11 md:ml-0 mx-3"> -->
            <div class="grid grid-cols-1 mt-5 md:mr-0">
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  dark:text-gray-200
                  text-xs text-gray-50 text-light
                  font-semibold font-sans
                "
                >{{$t('Gender')}}</label
              >

              <select
                class="
                  select-md select select-bordered select-secondary
                  w-full
                  border-2 border-gray-500
                  dark:bg-gray-700 dark:text-gray-200 shadow-lg text-gray-50 py-1 font-sans uppercase
                "
                v-model="gender"
              >
                <option disabled="disabled" selected="selected">
                  What's your gender
                </option>
                <option class="text-black"
                  v-for="(type, idx) in genders"
                  :key="idx"
                  v-text="type"
                ></option>
                <!-- <option>Burnette</option>
								<option>Redhead</option> -->
              </select>
            </div>
            <div v-if="$store.state.core.user.usrType != 'model'" class="grid grid-cols-1 mt-5 md:mr-0">
              <label
                class="
                  text-left
                  mb-5
                  uppercase
                  md:text-sm
                  dark:text-gray-200
                  text-xs text-gray-50 text-light
                  font-semibold font-sans
                "
                >{{$t('SexualPreference')}}</label
              >

              <select
                class="
                  select-md select select-bordered select-secondary
                  w-full
                  border-2 border-gray-500
                  dark:bg-gray-700 dark:text-gray-200 shadow-lg py-1 font-sans uppercase text-gray-50
                "
                v-model="preference"
              >
                <option disabled="disabled" selected="selected">
                  {{$t('SexualPreferences')}}
                </option>
                <option class="text-black"
                  v-for="(type, idx) in preferences"
                  :key="idx"
                  v-text="type"
                ></option>
                <!-- <option>Burnette</option>
								<option>Redhead</option> -->
              </select>
            </div>
          </div>

          <div class="grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-8 mt-5 px-3">
            <div class="grid grid-cols-1">
              <label
                class="
                  mb-5
                  uppercase
                  text-left
                  md:text-sm
                  text-xs text-gray-50 text-light
                  font-semibold
                  dark:text-gray-200 font-sans
                "
                >{{$t('YourCountry')}}</label
              >
              <select
                class="
                  select-md select select-bordered select-secondary
                  w-full
                  border-2 border-gray-500
                  dark:bg-gray-700 dark:text-gray-200 shadow-lg py-1 font-sans bg-t uppercase text-gray-50
                "
                v-model="country"
              >
                <option disabled="disabled" selected="selected">
                  {{$t('ChooseYourCountry')}}
                </option>
                <option class="text-black" v-for="(c,i) in counteries2" :key="i" :value="c">{{ c.country }}</option>
              </select>
            </div>

            <div class="grid grid-cols-2">
              <div class="grid grid-cols-1">
                <label
                  class="
                    mb-5
                    text-left
                    uppercase
                    md:text-sm
                    text-xs text-gray-50 text-light
                    font-semibold
                    dark:text-gray-200 font-sans
                  " 
                  >{{$t('countrycode')}}</label
                >
                <input
                disabled
                  class="
                    py-6
                    input-sm
                    px-3
                    ml-2
                    dark:text-gray-200
                    mt-1 w-16
                    rounded-lg
                    dark:bg-gray-700
                    border-2 border-gray-500 bg-transparent
                    focus:outline-none text-gray-50
                    focus:ring-2 shadow-lg
                    focus:ring-gray-500
                    focus:border-transparent
                  "
                  v-model="country.calling_codes"
                  type="text"
                />
              </div>
               <div class="grid grid-cols-1">
                <label
                  class="
                    mb-5
                    text-left
                    uppercase
                    md:text-sm
                    text-xs text-gray-50 text-light
                    font-semibold
                    dark:text-gray-200 font-sans
                  "
                  >{{$t('PhonNumber')}}</label
                >
                <input
                  class="
                    py-6
                    input-sm
                    px-1
                    ml-2 text-gray-50
                    dark:text-gray-200
                    mt-1
                    rounded-lg
                    dark:bg-gray-700
                    border-2 border-gray-500
                    focus:outline-none
                    focus:ring-2 shadow-lg  bg-transparent
                    focus:ring-gray-500
                    focus:border-transparent
                  "
                  v-model="phone"
                  type="number"
                  placeholder="Phone Number"
                />
              </div>
            </div>

           


            
            
          </div>

          <div class="grid grid-cols-1 mt-5 mx-7"></div>
          <div class="flex md:gap-6 gap-4 pt-5 pb-5 justify-center">
            <button
              class="
                btn btn-md
                w-auto
                bg-green-500 glass
                hover:bg-gray-700
                rounded-lg
                shadow-xl
                font-medium
                text-gray-50
                px-4
                py-6
              "
              :class="{ loading: loadingSave }"
              @click="addBasicInfo"
            >
          {{$t('SaveChanges')}}
            </button>
          </div>
          <div class="mb-70"></div>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
// import VueTailwindPicker from 'vue-tailwind-picker'
import { ADD_BASIC_INFO, BASIC_SELCETIONS } from "../../queries/auth";
import { getLocations } from '../../utils/helpers'
import { Location } from '../../utils/locations'

export default {
  components: {
    // VueTailwindPicker
  },
  data: () => ({
    bday: null,
    bmonth: null,
    byear: null,
    hairTypes: [],
    genders: [],
    gender: "",
    height: null,
    checkin: "",
    loadingSave: false,
    hair_color: "",
    phone: "",
    preference: "",
    country: "",
    allDates: [],
    counteries2:[],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    lightTheme: {
      background: "#fce7f3",
      text: "text-gray-800",
      border: "border-gray-700",
      currentColor: "text-gray-200",
      navigation: {
        background: "bg-gray-600",
        hover: "hover:bg-gray-600",
        focus: "bg-gray-700",
      },
      picker: {
        rounded: "rounded-md",
        selected: {
          background: "bg-teal-400",
          border: "border-teal-400",
          hover: "hover:border-teal-400",
        },
        holiday: "text-red-400",
        weekend: "text-green-400",
        event: "bg-blue-500",
      },
      event: {
        border: "border-gray-700",
      },
    },
    darkTheme: {
      background: "#1A202C",
      text: "text-gray-50",
      border: "border-gray-700",
      currentColor: "text-gray-200",
      navigation: {
        background: "bg-gray-800",
        hover: "hover:bg-gray-700",
        focus: "bg-gray-700",
      },
      picker: {
        rounded: "rounded-md",
        selected: {
          background: "bg-teal-400",
          border: "border-teal-400",
          hover: "hover:border-teal-400",
        },
        holiday: "text-red-400",
        weekend: "text-green-400",
        event: "bg-blue-500",
      },
      event: {
        border: "border-gray-700",
      },
    },
  }),
  async created() {
    if(this.$store.state.core.user.usrType == 'model') {
      this.preference = this.$store.state.core.user.looks.preference
    }
    this.fetchBasicInfoSelections();
    this.counteries2 = getLocations()
    let location = new Location
    await location.geoLocate()
  },
  methods: {
    range(start, stop) {
      if (typeof stop == "undefined") {
        stop = start;
        start = 0;
      }

      const result = [...Array(stop).keys()].slice(start, stop);
      return result;
    },
    datePicked(v) {
      this.checkin = v;
      console.warn(v, "changed");
    },
    validYears() {
      let yrs = [];
      const startYear = 1940;
      const currentYear = 2004;
      for (let x = startYear; x < currentYear; x++) {
        yrs.push(x);
      }
      return yrs;
      // console.log(currentYear)
    },
    fetchBasicInfoSelections() {
      this.$apollo
        .query({
          query: BASIC_SELCETIONS,
        })
        .then(
          ({
            data: {
              basicInfoSelections: { hairColorTypes, genders, preferences },
            },
          }) => {
            this.hairTypes = hairColorTypes;
            this.genders = genders;
            this.preferences = preferences;
          }
        );
    },
    addBasicInfo() {
      const bdate = `${this.byear}-${this.months.indexOf(this.bmonth) + 1}-${
        this.bday
      }`;
      console.warn(bdate, "birth date");
      // return;
      console.log(bdate, "birth");
      const input = {
        height: this.height,
        hair_color: this.hair_color,
        phone: `${this.country.calling_codes}${this.phone}`,
        gender: this.gender,
        bdate: bdate,
        country: this.country.code,
        preference: this.preference,
      };
      if(this.country  == '') return alert('Please select country to procede')
      if(this.phone  == '') return alert('Please enter you phonenumber to procede')

      this.loadingSave = true;
      this.$apollo
        .mutate({
          mutation: ADD_BASIC_INFO,
          variables: {
            options: input,
          },
        })
        .then(({ data }) => {
          if (data.addBasicInformation) {
            let user = this.$store.state.core.user;
            user.profileSetupStep = 2;
            this.$store.commit("setUserData", user);
            this.$emit("nextstep");
          }
          
        }).catch((e)=>{
          if(e.message){
            let message = e.message.split("GraphQL error: ")[1]
            if(message){
              alert(message)
            }
          }
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
  },
};
</script>