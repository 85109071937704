<template>
	<!-- <LoginIndex/> -->
	<!-- <MatchesIndex/> -->
	<PrivacyIndex/>
  </template>
  <script>
  import PrivacyIndex from '@/templates/Privacy/Index.vue'
  export default {
	components:{
	  PrivacyIndex
	}
  }
  </script>