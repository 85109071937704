<template>
  <VistorIndex/>
</template>
<script>
import VistorIndex from '@/templates/Vistors/Index.vue'
export default {
  components:{
    VistorIndex,
  }
}
</script>
