<template>
  <div
      class="px-2 lg:-mt-28 -mt-16 py-4 mx-auto bg-white border-pink-200  shadow-2xl drop-shadow-2xl border-4 max-w-screen-xl md:px-2 lg:px-2">
      <!-- component -->
      <!-- <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css">
<link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"> -->

      <popup :showing="blockD" @close="blockD = false">
          <h2 class="py-10 text-xl font-bold font-sans uppercase text-gray-900">
            {{$t('Blockcon')}}
              <span class="text-purple-500">@{{ userProfile.user.username }}</span>
          </h2>
          <div class="flex flex-row justify-center gap-10">
              <button class="mt-3 bg-pink-100 btn" @click="block">{{$t('Yes')}}</button>

              <button class="mt-3 bg-pink-100 btn" @click="blockD = false">{{$t('No')}}</button>
          </div>
      </popup>
      <popup :showing="showing" @close="showing = false">
          <h2 class="text-xl font-bold font-sans text-gray-900 uppercase">{{$t('reportreason')}}</h2>
          <textarea v-model="reportReason" class="h-32 mt-5 border-pink-200 input focus:border-pink-600" />
          <button class="mt-3 bg-pink-100 btn" @click="submitReport">{{$t('SubmitReport')}}</button>
      </popup>
      <main class="profile-page lg:px-28">

          <div v-if="loadingProfile">
              <div class="h-56 mx-auto mt-5 bg-gray-500 rounded-md animate-pulse"></div>
              <div class="space-y-2">
                  <div class="h-4 mt-3 bg-gray-500 rounded animate-pulse"></div>
              </div>
          </div>
          <section class="relative block h-500-px">
              <div class="absolute top-0 w-full h-full rounded-t-box bg-center bg-cover" :style="{
                  'background-image': 'url(' + getURL(userProfile.user.profilePic) + ')',
                  'filter': 'blur(0px)'
              }">

                  <div class="flex flex-wrap justify-end gap-6 items-end px-6 ">
                      <div class="flex justify-center mt-5 cursor-pointer"><img @click="reportUser"
                              src="@/assets/mazu/report.png" class="h-10" alt=""></div>
                      <div class="flex justify-center mt-5 cursor-pointer"><img @click="blockD = true"
                              src="@/assets/mazu/block.png" class="h-10" alt=""></div>
                  </div>
              </div>
              <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                  style="transform: translateZ(0px)">
                  <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                      <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
                  </svg>
              </div>
          </section>
          <section class="relative lg:py-16  bg-transparent">  
              <div class="container mx-auto px-4">
                  <div
                      class="relative flex flex-col min-w-0 break-words bg-white border-8 border-pink-100 w-full mb-6 shadow-xl rounded-lg pb-24 -mt-64">

                      <div class="lg:px-6">
                          <div class="flex flex-wrap justify-start">
                              <div class="w-full lg:w-2/12  px-4 lg:order-1 flex justify-center">
                                  <div class="relative">
                                      <img class="object-cover border-8 z-50   -mt-24  border-white shadow-2xl rounded-3xl  cursor-pointer hover:border-pink-500 w-48 h-36 profileImg scale-125 hover:scale-75 ease-in duration-50"
                                          @click="profileClicked" :src="getURL(userProfile.user.profilePic)" />
                                  </div>
                              </div>
                              <div
                                  class="w-full lg:w-6/12 px-2 lg:block hidden lg:order-2 flex justify-start py-4 lg:pt-4 pt-8">



                                  <div class=" grid-cols-1">

                                      <div class=" ">
                                          <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 flex mx-auto flex-wrap"
                                              v-if="userProfile.user.base_profile">
                                              {{ userProfile.user.base_profile.firstName }}
                                              {{ userProfile.user.base_profile.lastName }} <svg
                                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                  stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                                              </svg>

                                          </h3>
                                          <div class="text-sm text-left mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                              <i class="fas fa-map-marker-alt mr-0 text-md text-blueGray-400"
                                                  v-if="userProfile.user.user_basic">
                                                  <span class="text-black">Bio:</span> {{ userProfile.user.user_basic.bio
                                                  }}</i>
                                              <!-- Los Angeles, California -->
                                          </div>
                                      </div>
                                      <div>
                                          <div class="py-1 px-0 mt-5 sm:mt-0 flex flex-wrap  gap-10">
                                              <!-- <router-link class=" " :to="getChatRoute()"> <button
                                                      class="bg-pink-500 font-sans active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-lg px-4 py-2  rounded-xl outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                      type="button">
                                                      Message
                                                  </button></router-link> -->

                                              <a :href="getChatRoute()"> <button
                                                      class="bg-pink-500 glass font-sans active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-lg px-4 py-2  rounded-xl outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                      type="button">
                                                      {{$t('message')}}
                                                  </button></a>
                                              <div class="lg:mr-4 p-0 text-center">
                                                  <div class="w-1/2 text-center">
                                                      <span
                                                          class="font-bold btn btn-md font-sans glasss text-lg bg rounded-xl bg-pink-200 dark:text-white border-0"
                                                          @click="sendPoke"> POKE
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>

                                      </div>


                                  </div>
                                  <!-- mobile -->



                                  <!-- mobile -->




                              </div>
                              <div class="w-full lg:hidden  px-2 lg:order-2 flex justify-start mx-auto py-4 lg:pt-4 pt-8">



                                  <div class=" grid-cols-1">

                                      <div class=" text-center ">
                                          <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 flex flex-wrap justify-center"
                                              v-if="userProfile.user.base_profile">
                                              {{ userProfile.user.base_profile.firstName }}
                                              {{ userProfile.user.base_profile.lastName }} <svg
                                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                  stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                                              </svg>

                                          </h3>
                                          <div class="text-sm text-left mt-0 mb-2 text-blueGray-400 font-bold uppercase ">
                                              <i class="fas fa-map-marker-alt mr-0 text-md text-blueGray-400"
                                                  v-if="userProfile.user.user_basic">
                                                  <span class="text-black">Bio:</span> {{ userProfile.user.user_basic.bio
                                                  }}</i>
                                              <!-- Los Angeles, California -->
                                          </div>
                                      </div>
                                      <div>
                                          <div class="py-1 px-0 mt-5 sm:mt-0 flex flex-wrap mx-auto justify-center gap-10">
                                              <!-- <router-link class=" " :to="getChatRoute()"> <button
                  class="bg-pink-500 font-sans active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-lg px-4 py-2  rounded-xl outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button">
                  Message
              </button></router-link> -->

                                              <a :href="getChatRoute()"> <button
                                                      class="bg-pink-500 font-sans active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-lg px-4 py-2  rounded-xl outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                      type="button">
                                                      {{$t('message')}}
                                                  </button></a>
                                              <div class="lg:mr-4 p-0 text-center">
                                                  <div class="w-1/2 text-center">
                                                      <span
                                                          class="font-bold btn btn-md font-sans text-lg bg rounded-xl bg-pink-200 dark:text-white border-0"
                                                          @click="sendPoke"> POKE
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>

                                      </div>


                                  </div>




                              </div>
                              <div class="w-full lg:w-4/12 lg:block hidden px-4 lg:order-2">
                                  <div class="flex justify-left py-4 lg:pt-4 pt-8">
                                      <div class="mr-4 p-1 flex flex-wrap gap-2 text-center">
                                          <span
                                              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"><svg
                                                  xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24"
                                                  stroke-width="1.5" stroke="purple" class="w-12 h-12">
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                              </svg>
                                          </span> <span
                                              class="text-xl font-bold block uppercase mt-2 tracking-wide text-blueGray-600">
                                              <span class="font-sans dark:text-white "
                                                  v-text="userProfile.likes"></span><span
                                                  class="text-lg text-blueGray-400 dark:text-white px-2 ">{{$t('likes')}}</span></span>
                                      </div>
                                      <div class="mr-4 p-1  flex flex-wrap gap-2 text-center">
                                          <span
                                              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"><svg
                                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                  stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                              </svg>
                                          </span><span
                                              class="text-xl font-bold block uppercase mt-2 tracking-wide text-blueGray-600">
                                              <span class="font-sans dark:text-white"
                                                  v-text="userProfile.visits"></span><span
                                                  class="text-lg px-1 text-blueGray-400 uppercase dark:text-white">{{$t('Visits')}}</span></span>
                                      </div>

                                  </div>
                              </div>
                              <div class="w-full lg:w-4/12 lg:hidden justify-center mx-auto px-4 lg:order-2">
                                  <div class="flex justify-center mx-auto py-4 lg:pt-4 pt-5">
                                      <div class="mr-4 p-1 flex flex-wrap justify-center mx-auto gap-2 text-center">
                                          <span
                                              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"><svg
                                                  xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24"
                                                  stroke-width="1.5" stroke="purple" class="w-12 h-12">
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                              </svg>
                                          </span> <span
                                              class="text-xl font-bold block uppercase -mt-10 tracking-wide text-blueGray-600">
                                              <span class="font-sans dark:text-white "
                                                  v-text="userProfile.likes"></span><span
                                                  class="text-lg text-blueGray-400 dark:text-white px-2 ">LIKES</span></span>
                                      </div>
                                      <div class="mr-4 p-1  flex flex-wrap justify-center mx-auto gap-2 text-center">
                                          <span
                                              class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"><svg
                                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                  stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                  <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                              </svg>
                                          </span><span
                                              class="text-xl font-bold block uppercase -mt-10 tracking-wide text-blueGray-600">
                                              <span class="font-sans dark:text-white"
                                                  v-text="userProfile.visits"></span><span
                                                  class="text-lg px-1 text-blueGray-400 uppercase dark:text-white">Visits</span></span>
                                      </div>

                                  </div>
                              </div>

                          </div>
                          <div class="text-center mt-0">




                              <div class=" grid lg:grid-cols-12 grid-cols-2 lg:gap-10 h-full">


                                  <div class=" col-span-4 ">
                                      <div class=" flex justify-start px-4"><span
                                              class=" text-left font-sans text-2xl py-4 uppercase font-bold">{{$t('Photos')}}</span>
                                      </div>

                                      <div class="  border-4 rounded-2xl shadow-inner  bg-white border-white h-full">
                                          <div class="grid grid-cols-1 mt-2 sm:grid-cols- lg:grid-cols-1 xl:gap-x-8">
                                              <div class="relative group">
                                                  <div class="overflow-hidden grid grid-cols-3 bg-white rounded-xl  aspect-w- aspect-h-1 dark:bg-gray-800 lg:aspect-none lg:h-full"
                                                      style="" v-if="userProfile.user.photos.length > 0">
                                                      <img class="w-16 h-12 mt-1 border-2 rounded-xl cursor-pointer elem hover:border-pink-400 scale-125 hover:scale-75 ease-in duration-500"
                                                          v-for="(pic, idx) in userProfile.user.photos" :key="idx"
                                                          @click="slideClicked(idx)" :src="getURL(pic.image)"
                                                          :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                                                          style="width: 100px; height: 100px; object-fit: cover;" />
                                                  </div>
                                              </div>
                                              <div class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
                                                  v-if="false">
                                                  <div class="relative">
                                                      <img class="absolute" style="filter: brightness(0.5)"
                                                          src="@/assets/sexy-couple.jpg" />
                                                      <div class="relative">
                                                          <p class="pt-20">{{$t('UnlockExclusive')}}</p>
                                                          <router-link
                                                              class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white"
                                                              to="/flirts">{{$t('UnlockNow')}}</router-link>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class=" col-span-8  ">
                                      <div>

                                          <div class="px-2 mt-0  text-left">

                                              <p
                                                  class="lg:text-left text-center font-sans text-2xl py-4 uppercase font-bold">
                                                  {{$t('Personal')}}</p>
                                              <ul v-if="userProfile.user.looks"
                                                  class="grid lg:grid-cols-3 grid-cols-2 gap-2 border-4 rounded-2xl shadow-lg bg-white border-white h-full p-4">

                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.ethnicity">
                                                      {{$t('Ethnicity')}} : {{ userProfile.user.looks.ethnicity }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.body_type">
                                                      {{$t('BodyType')}} : {{ userProfile.user.looks.body_type }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.gender">
                                                      {{$t('Gender')}} : {{ userProfile.user.looks.gender }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.height">
                                                      {{$t('Height')}} : {{ userProfile.user.looks.height }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.relationship">
                                                      {{$t('Relationship')}} : {{ userProfile.user.user_basic.relationship }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.workstatus">
                                                      {{$t('Work')}} : {{ userProfile.user.user_basic.workstatus }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.education_level">
                                                      {{$t('Education')}} : {{ userProfile.user.user_basic.education_level }}
                                                  </li>
                                                  <CoolLightBox :items="items" :index="index" @close="index = null">
                                                  </CoolLightBox>
                                              </ul>

                                          </div>
                                          <div class="px-2 mt-3 text-left" v-if="userProfile.user.lifeStyle">
                                              <p class="lg:text-left text-center  font-sans text-2xl py-4 uppercase font-bold"
                                                  v-if="userProfile.user.lifeStyle">
                                                  {{$t('LIFESTYLE')}}
                                              </p>
                                              <ul
                                                  class="grid lg:grid-cols-3 grid-cols-2 gap-2 border-4 rounded-2xl shadow-lg bg-white border-white h-full p-4">

                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.lifeStyle.live_with">
                                                      {{$t('LivesWith')}} : {{ userProfile.user.lifeStyle.live_with }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.lifeStyle.car">
                                                      {{$t('Car')}} : {{ userProfile.user.lifeStyle.car }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.lifeStyle.religion">
                                                      {{$t('Religion')}} : {{ userProfile.user.lifeStyle.religion }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.lifeStyle.smoke">
                                                      {{$t('Smoke')}} : {{ userProfile.user.lifeStyle.smoke }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.lifeStyle.drinking">
                                                      {{$t('Drink')}} : {{ userProfile.user.lifeStyle.drinking }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.lifeStyle.travel">
                                                      {{$t('Travel')}} : {{ userProfile.user.lifeStyle.travel }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.education_level">
                                                      {{$t('Education')}} : {{ userProfile.user.user_basic.education_level }}
                                                  </li>
                                              </ul>
                                          </div>

                                      </div>
                                  </div>
                              </div>

                              <!-- <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700"
                                  v-if="userProfile.user.base_profile">
                                  {{ userProfile.user.base_profile.firstName }}
                                  {{ userProfile.user.base_profile.lastName }}
                              </h3> -->

                              <div class="mb-2 text-blueGray-600 mt-1">
                                  <!-- <p style="font-size: 17px"
                                      class="pt-5 mb-5 font-sans font-normal break-all text-left shadow-none dark:text-gray-200"
                                      v-if="userProfile.user.user_basic">
                                      {{ userProfile.user.user_basic.bio }}
                                  </p> -->
                              </div>
                              <!-- <div class="mb-2 text-blueGray-600">
                                  <p
                                      class="font-sans font-bold  dark:text-white text-3xl text-center text-yellow-500 uppercase">
                                      BIO
                                  </p>
                                  <p style="font-size: 17px"
                                      class="pt-5 mb-5 font-sans font-normal break-all text-left shadow-none dark:text-gray-200"
                                      v-if="userProfile.user.user_basic">
                                      {{ userProfile.user.user_basic.bio }}
                                  </p>
                              </div> -->
                          </div>
                          <!-- <div class="mt-5 py-5 border-t border-blueGray-200 text-center">
                              <div class="grid grid-cols-12  justify-center">
                                  <div class="w-full col-span-4 lg:w-full px-4">
                                      <div class="w-full lg:full px-0">

                                          <div class="px-2 mt-0  text-left">

                                              <p
                                                  class="font-sans font-bold  dark:text-white text-3xl text-center text-yellow-500 uppercase">
                                                  Personal</p>
                                              <ul v-if="userProfile.user.looks"
                                                  class="grid grid-cols-2 gap-2 border-2 p-5 text">

                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.ethnicity">
                                                      Ethnicity: {{ userProfile.user.looks.ethnicity }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.body_type">
                                                      Body Type: {{ userProfile.user.looks.body_type }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.gender">
                                                      Gender: {{ userProfile.user.looks.gender }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.looks.height">
                                                      Height: {{ userProfile.user.looks.height }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.relationship">
                                                      Relationship: {{ userProfile.user.user_basic.relationship }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.workstatus">
                                                      Work: {{ userProfile.user.user_basic.workstatus }}
                                                  </li>
                                                  <li class="mt-0 font-sans text-md dark:text-white uppercase"
                                                      v-if="userProfile.user.user_basic.education_level">
                                                      Education: {{ userProfile.user.user_basic.education_level }}
                                                  </li>
                                                  <CoolLightBox :items="items" :index="index" @close="index = null">
                                                  </CoolLightBox>
                                              </ul>

                                          </div>
                                          <div class="px-2 mt-3 text-left" v-if="userProfile.user.lifeStyle">
                                              <p class="font-sans text-3xl font-bold dark:text-white text-center text-yellow-500"
                                                  v-if="userProfile.user.lifeStyle">
                                                  LIFE STYLE
                                              </p>
                                              <ul class="grid grid-cols-2 gap-2 border-2 p-5 text uppercase">

                                                  <li class="mt-4 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.lifeStyle.live_with">
                                                      Lives With: {{ userProfile.user.lifeStyle.live_with }}
                                                  </li>
                                                  <li class="mt-3 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.lifeStyle.car">
                                                      Car: {{ userProfile.user.lifeStyle.car }}
                                                  </li>
                                                  <li class="mt-3 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.lifeStyle.religion">
                                                      Religion: {{ userProfile.user.lifeStyle.religion }}
                                                  </li>
                                                  <li class="mt-3 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.lifeStyle.smoke">
                                                      Smoke: {{ userProfile.user.lifeStyle.smoke }}
                                                  </li>
                                                  <li class="mt-3 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.lifeStyle.drinking">
                                                      Drink: {{ userProfile.user.lifeStyle.drinking }}
                                                  </li>
                                                  <li class="mt-3 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.lifeStyle.travel">
                                                      Travel: {{ userProfile.user.lifeStyle.travel }}
                                                  </li>
                                                  <li class="mt-3 font-sans text-sm dark:text-white"
                                                      v-if="userProfile.user.user_basic.education_level">
                                                      Education: {{ userProfile.user.user_basic.education_level }}
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div class=" col-span-8">
                                      <div class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-1 xl:gap-x-8">
                                          <div class="relative group">
                                              <div class="overflow-hidden flex flex-w bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-800 lg:aspect-none lg:h-full"
                                                  style="" v-if="userProfile.user.photos.length > 0">
                                                  <img class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem hover:border-pink-400 scale-125 hover:scale-75 ease-in duration-500"
                                                      v-for="(pic, idx) in userProfile.user.photos" :key="idx"
                                                      @click="slideClicked(idx)" :src="getURL(pic.image)"
                                                      :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                                                      style="width: 230px; height: 150px" />
                                              </div>
                                          </div>
                                          <div class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
                                              v-if="false">
                                              <div class="relative">
                                                  <img class="absolute" style="filter: brightness(0.5)"
                                                      src="@/assets/sexy-couple.jpg" />
                                                  <div class="relative">
                                                      <p class="pt-20">Unlock Exclusive Pictures</p>
                                                      <router-link
                                                          class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white"
                                                          to="/flirts">Unlock
                                                          Now</router-link>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div> -->
                      </div>
                  </div>
              </div>
            
          </section>
      </main>
  </div>
</template>


<style>
@import '../../assets/css/userprofile.css';

/* Other component-specific styles */
</style>
<script>
import { getURL } from "@/utils/helpers";
import CoolLightBox from "vue-cool-lightbox";
import popup from "@/components/popup.vue";
import { BLOCK_USER, REPORT_USER, SEND_POKE, USER_PROFILE } from "@/queries/auth";
import UserCard from "@/components/userCard.vue";
import NewSideSelector from "@/components/newsideselector ";
import Notify from "@/components/Notify.vue";
import SideHomeSelector from "@/components/sideHomeSelector.vue";
import SideMatchSelector from '@/components/sideMatchSelector';
import UserAvatar from "@/components/userAvatar.vue";
import YellowUserAvartar from "@/components/yellow/YellowuserAvatar.vue";
import YellowNotify from '@/components/yellow/YellowNotify.vue'
import YellowsideHomeSelector from '@/components/yellow/YellowsideHomeSelector.vue'
import mobilenavbar from "@/components/purple/Mobilenavbar.vue";

export default {
  components: { popup, UserAvatar,mobilenavbar, YellowsideHomeSelector, YellowNotify, YellowUserAvartar, CoolLightBox, UserCard, NewSideSelector, Notify, SideHomeSelector, SideMatchSelector, },
  data: () => ({
      items: [],
      index: null,
      blockD: false,
      showing: false,
      allUserPics: [],
      reportReason: "",
      userProfile: null,
      loadingProfile: true,
      // routeParam: this.$route.params.username
  }),
  apollo: {
      userProfile: {
          query: USER_PROFILE,
          variables() {
              return {
                  username: this.$route.params.username,
              };
          },
          update(data) {
              this.loadingProfile = false;
              console.clear();
              // this.userProfile = data.userProfile
              // console.log(data.user.photos,"Hol up")
              data.userProfile.user.photos.forEach((pic) => {
                  this.allUserPics.push(getURL(pic.image));
              });
              return data.userProfile;
          },
      },
  },
  created() {
      this.$apollo.queries.userProfile.refetch();
      // this.fetchUserProfile()
      // const allPics = this.$store.state.core.user.photos;
      // allPics.forEach(e => {
      // 	this.allUserPics.push(getURL(e.image))
      // })
  },
  methods: {
      getURL,
      sendPoke() {
          this.$apollo
              .mutate({
                  mutation: SEND_POKE,
                  variables: {
                      input: this.userProfile.user.username,
                  },
              })
              .then(
                  ({
                      data: {
                          sendPoke: { error },
                      },
                  }) => {
                      this.$notify(
                          {
                              group: "top",
                              title: error
                                  ? "You have finished your pokes for today :("
                                  : `Poke Sent to @${this.userProfile.user.username}`,
                              text: error ? "Whoops" : "Success",
                              type: "info", // info, warning
                          },
                          5000
                      );
                  }
              );
      },
      getChatRoute() {
          switch (this.$mq) {
              case "sm":
                  return `/chat?dm=${this.userProfile.user.id}`;
              case "md":
                  return `/chat?dm=${this.userProfile.user.id}`;
              case "lg":
                  return `/chat?dm=${this.userProfile.user.id}`;
              default:
                  break;
          }
          // ($mq == 'sm' || 'md')
          // 	? `/chat-list?dm=${userProfile.user.id}`
          // 	: `/chat?dm=${userProfile.user.id}`
      },
      slideClicked(idx) {
          console.log(idx, "IDX");
          this.index = idx;
          this.items = this.allUserPics.filter((obj) => {
              return obj.unsafe !== true;
          });

          // this.items = [
          // 	"https://picsum.photos/id/1005/400/250",
          // 	"https://picsum.photos/id/1005/400/250",
          // 	"https://picsum.photos/id/1005/400/250",
          // 	"https://picsum.photos/id/1005/400/250",
          // ];
      },
      profileClicked() {
          this.items = [getURL(this.userProfile.user.profilePic)];
          // console.log(this.userProfile)
          this.index = 0;
      },
      submitReport() {
          this.$notify(
              {
                  group: "top",
                  title: "You report is submitted thank you !!",
                  type: "info", // info, warning
              },
              5000
          );
          this.showing = false;
          this.$apollo.mutate({
              mutation: REPORT_USER,
              variables: {
                  input: {
                      target: JSON.stringify(this.userProfile.user.id),
                      reason: this.reportReason,
                  },
              },
          });
      },
      block() {
          this.blockD = false;
          this.$apollo
              .mutate({
                  mutation: BLOCK_USER,
                  variables: {
                      input: JSON.stringify(this.userProfile.user.id),
                  },
              })
              .then(() => {
                  this.$notify(
                      {
                          group: "top",
                          title: "User blocked successfully!!",
                          type: "info", // info, warning
                      },
                      5000
                  );
                  this.$router.push("/find-matches");
              });
      },
      reportUser() {
          this.showing = true;
      },
  },
};
</script>

