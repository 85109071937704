  <template>
  <div>
    <div
      class="flex flex-wrap w-full h-full object-cover justify-center"
      style="object-fit: cover; width: 100%; height: 100%"
    >
      <!-- Login Section -->
      <img
        :src="require('@/assets/flirttoolbackground.jpg')"
        style="filter: blur(4px)"
        class="absolute inset-0 justify-center object-cover w-full h-screen sm:"
        alt=""
      />
      <div
        class="
          px-4
          py-12
          mx-auto
          sm:max-w-xl
          md:max-w-full
          lg:max-w-screen-lg
          md:px-24
          lg:px-8 lg:py-12
        "
      >
        <div
          class="
            flex flex-col
            justify-center
            bg-white
            shadow-2xl
            rounded-3xl
            opacity-95
            px-8
            pt-8
            my-auto
            lg:my-10
            xl:my-10
            md:justify-start md:pt-0 md:px-24
            relative
            h-full
            lg:full
            text-left
          "
        >
          <router-link to="/">
            <div
              class="
                flex flex-col flex-wrap
                content-center
                mb-5
                xl:mb-16
                lg:mb-16
                md:mb-16
                sm:mb-10
                mt-5
              "
            >
              <img
                class="float-left h-20"
                :src="require('@/assets/logo-light.png')"
                alt="Connect with flirttool & start your personal online dating adventure! "
              />
            </div>
          </router-link>
          <div class="w-full max-w-sm">
            <p class="text-xl font-sans">Change your password</p>
            <p v-if="regexErr" class="text-red-700">
              Please check your password and make sure it's maximum 8
              characters.
            </p>
            <input
              type="password"
              class="
                px-3
                py-2
                w-full
                mt-5
                mb-5
                border-2 border-pink-300
                rounded-full
                form__input
                shadow-xl
                dark:bg-gray-700
                focus:outline-none
                focus:ring-2
                focus:ring-pink-400
                focus:border-transparent
                dark:text-gray-200
              "
              v-model="oldPass"
              id="my-textfield"
              value=""
              placeholder="Old Password"
            />
            <input
              type="password"
              class="
                px-3
                py-2
                w-full
                mt-5
                mb-5
                border-2 border-pink-300
                rounded-full
                form__input
                shadow-xl
                dark:bg-gray-700
                focus:outline-none
                focus:ring-2
                focus:ring-pink-400
                focus:border-transparent
                dark:text-gray-200
              "
              v-model="newPass"
              id="my-textfield"
              value=""
              placeholder="New Password"
            />
            <button
              @click="sendReset"
              type="button"
              class="
                mx-2
                mb-16
                w-full
                h-12
                btn-sm
                bg-pink-600
                hover:bg-pink-700
                btn btn-primary
              "
            >
              <span class="py-2">Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CHANGE_PASS, CHK_TOKEN } from "../../queries/auth";
export default {
  created() {
    this.checkToken();
  },
  methods: {
    changePass() {
      this.$apollo
        .mutate({
          mutation: CHANGE_PASS,
          variables: {
            input: {
              token: this.$route.params.token,
              oldPass: this.oldPass,
              newPass: this.newPass,
            },
          },
        })
        .then((data) => {
          if (data) {
            alert("password changed successfully!!");
            window.location.assign("/login");
          }
        })
        .catch(() => {
          alert("ERROR");
        });
    },
    sendReset() {
      if (/.{8,}/.test(this.oldPass) && /.{8,}/.test(this.newPass)) {
        this.regexErr = false;
        this.changePass();
        // this.$router.push("/login")
      } else {
        this.regexErr = true;
        alert("wrong");
      }
      // this.sendConfirmation = true;
    },
    checkToken() {
      this.$apollo
        .mutate({
          mutation: CHK_TOKEN,
          variables: {
            token: this.$route.params.token,
          },
        })
        .then(({ data }) => {
          console.warn(data);
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
  data: () => ({
    resetEmail: "",
    newPass: "",
    oldPass: "",
    regexErr: false,
    sendConfirmation: false,
  }),
};
</script>