<template>
  <div>
<div style="background:rgb(248, 243, 244);z-index:8000" id="static-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0   w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] h-screen max-h-full">
    <center><div class="relative w-full max-w-2xl max-h-full mt-24">
        <!-- Modal content -->
        <div class="relative bg-white shadow-2xl rounded-lg  dark:bg-gray-700">
            <!-- Modal header -->
            <div class="p-4">
                <h3 class="text-xl font-semibold text-center uppercase font-sans text-gray-900 dark:text-white">
                    Please select you language to continue
                </h3>
                <!-- <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button> -->
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
                <!-- <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                </p> -->
                <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-sans uppercase text-center">Select a language</label>
                  <select @change="changeLang" v-model="$store.state.core.lang" id="countries" class="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                     <option :value="null">No language selected</option>
                    <option value="en">English</option>
                    <option value="tr">Turkish</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="polish">Polish</option>
                      <option value="Spanish">Spanish</option>
                      <option value="italic">Italia</option>
                      <option value="português">Português</option>
                      <option value="sv">Schwedisch</option>
                    <option value="da">Danish</option>
                    <option value="fi">Finnish</option>
                    <option value="sr">Serbian</option>
                    <option value="ru">Russisch</option>
                    <option value="ja">Japanisch</option>
                  <option value="ko">Koreanisch</option>
                  <option value="fa">Iran</option>
                  <option value="arz">Arabic</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Thai">Thai</option>
                  <option value="Amharisch">Amharisch</option>
                  <option value="	Ungarisch">	Ungarisch</option>
                  </select>
                <!-- <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                </p> -->
            </div>
            <!-- Modal footer -->
            <!-- <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="static-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                <button data-modal-hide="static-modal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
            </div> -->
        </div>
    </div></center>
</div>
</div>
</template>
<script>
export default {
  mounted() {
    // alert("Sdf")
    console.log(this.$i18n.locale,"this.$i18n.locale")
  },
  methods:{ 
    changeLang($event) {
      this.$store.commit("setCurrentLang", $event.target.value)
      this.$i18n.locale = $event.target.value
    },
  }
}
</script>
