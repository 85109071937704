<style>
:root {
  --main-color: #4a76a8;
}

.bg-main-color {
  background-color: var(--main-color);
}

.text-main-color {
  color: var(--main-color);
}

.border-main-color {
  border-color: var(--main-color);
}
</style>

<template>
  <!-- component -->

  <div class=" mt-20 h-full pb-72 overflow-auto " >
    <!-- <img
      :src="require('@/assets/ftmodels/13.png')"
      class="absolute inset-0 justify-center object-cover w-full h-full sm:"
      alt=""
      style="object-fit: ; height: 320px"
    /> -->

    <!-- End of Navbar -->

    <div
      class="px-4 py-16 border-2 mx-auto sm:max-w-xl md:max-w-full h-full  -mt-5 lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" style="height: 1000px;"
    >
      <div class="md:flex no-wrap md:-mx-2">
        <!-- Left Side -->
        <div class="w-full md:w-3/12 md:mx-2">
          <!-- Profile Card -->
          <!-- component -->
          <div
            class="rounded-3xl overflow-hidden shadow-xl my-0 w-full bg-pink-50"
          >
            <!-- profile nav bar starts here -->
            <div>
              <nav class="bg-gray-800">
                <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                  <div class="relative flex h-16 items-center justify-between">
                    <div
                      class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
                    >
                      <div class="flex flex-shrink-0 items-center">
                        <!-- <img class="block h-8 w-auto lg:hidden" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company"> -->
                        <img
                          class="h-8 w-auto"
                          src="@/assets/ftmodels/1.png"
                          alt="Your Company"
                        />
                      </div>
                      <div class="sm:ml-6 sm:block">
                        <div class="flex space-x-4">
                          <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                          <!-- <img
                            class="h-8 w-auto"
                            src="@/assets/ftmodels/2.png"
                            alt="Your Company"
                          /> -->

                          <img
                            class="h-8 w-auto"
                            src="@/assets/ftmodels/3.png"
                            alt="Your Company"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
                    >
                      <!-- <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                        />
                      </svg> -->
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <!-- profile nav bar ends here -->
            <!-- profile card starts here -->
            <img
              src="@/assets/ftmodels/place/1.jpg"
              class="w-full h-36 object-cover"
            />

            <div class="flex justify-center -mt-8">
              <img
                :src="getProfilePicture(user)"
                class="object-cover border-2 border-pink-300 rounded-full cursor-pointer hover:border-pink-500 w-36 h-36 profileImg scale-125 hover:scale-75 ease-in duration-50 -mt-12"
              />
            </div>
            <div class="text-center px-3 pb-6 pt-2">
              <h3 class="text-gray-800 text-2xl font-bold font-sans">
                {{ user.username }}. {{ user.age }}Yrs
              </h3>
              <!-- <p class="-mt-2 font-sans font-semi-bold text-gray-800">
                Location : {{ getRandomLocation.country }}
              </p>
              <p class="-mt-5 font-sans font-semi-bold text-gray-800">
                Languages: English
              </p> -->
              <router-link
                :to="'/chat?dm=' + user.id"
                class="mt-3 btn w-full rounded-full border-4 border-white shadow-lg bg-pink-600 glass text-white hover:bg-green-200"
                
              >
                chat with me
              </router-link>
            </div>
            <!-- <div
              class="flex justify-center pb-3 bg-pink-500 py-3 text-gray-800"
            >
              <div class="text-center mr-3 border-r pr-3">
                <img src="@/assets/ftmodels/9.png" alt="" class="h-5" />
                <p class="text-xs -mt-5 font-sans font-bold text-white">8.6k</p>
              </div>
              <div class="text-center mr-3 border-r pr-3">
                <img src="@/assets/ftmodels/8.png" alt="" class="h-5" />
                <p class="text-xs -mt-5 font-sans font-bold text-white">34</p>
              </div>
              <div class="text-center mr-3 border-r pr-3">
                <img src="@/assets/ftmodels/9.png" alt="" class="h-5" />
                <p class="text-xs -mt-5 font-sans font-bold text-white">34</p>
              </div>
              <div class="text-center mr-3 border-r pr-3">
                <img src="@/assets/ftmodels/7.png" alt="" class="h-5" />
                <p class="text-xs -mt-5 font-sans font-bold text-white">34</p>
              </div>
              <div class="text-center mr-3 border-r pr-3">
                <img src="@/assets/ftmodels/11.png" alt="" class="h-5" />
                <p class="text-xs -mt-5 font-sans font-bold text-white">42</p>
              </div>
              <div class="text-center">
                <img src="@/assets/ftmodels/12.png" alt="" class="h-5" />
                <p class="text-xs -mt-5 font-sans font-bold text-white">42</p>
              </div>
            </div> -->
          </div>
          <!-- End of profile card -->
          <div class="my-1"></div>
         
        </div>
        <!-- Right Side -->
        <div class="w-full md:w-9/12 mx-2 h-64">
          <!-- Profile tab -->
          <!-- About Section -->
          <div
            class="bg-opacity-0 p-3 shadow-sm rounded-sm"
            style="background-image: url('/assets/ftmodels/13.png')"
          >
            <img src="" alt="" />

            <!-- status section starts here -->

            <div class="text-gray-700 mt-6">
              <div class="flex justify-start mb-4">
                <img
                  src="@/assets/ftmodels/13.png"
                  class="object-cover h-8 w-8 rounded-full border-2 border-pink-500"
                  alt=""
                />
                <div
                  class="ml-2 py-2 lg:px-16 md:px-2 sm:px2 bg-pink-600 border-b-8  rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white"
                >
                  <h3
                    class="text-gray-50 py-1 text-xl font-bold font-sans text-left"
                  >
                    {{user.username}}. {{user.age}}Yrs
                  </h3>
                  <!-- Hi :) Visit my Private Gallery to see my hot pics at shower
                  <p class="text-xs text-right">Last Updated on 11.2.2023</p> -->
                </div>
              </div>
            </div>
            <!-- end of status section -->

            <!-- send message -->
            <div
              class="lg:max-w-2xl md:max-w-full sm:max-w-full mx-auto max-w-full"
            >
              <form>
                <label for="chat" class="sr-only">Your message</label>
                <div
                  class="flex items-center py-2 px-1 bg-gray-50 bg-transparent rounded-lg"
                >
                  <button
                    type="button"
                    class="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-900 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <img
                      src="@/assets/ftmodels/15.png"
                      alt=""
                      class="h-10 w-12 -mt-5"
                    />
                    <!-- <svg class="w-10 h-10 -mt-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clip-rule="evenodd"></path></svg> -->
                  </button>
                  <textarea
                    id="chat"
                    rows="1"
                    v-model="message"
                    class="block mx-1 h-36 md:h-36 lg:h-10 sm:h-36 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Are you interested? Your message Here 
..."
                  ></textarea>
                  <button
                    @click.prevent="messageSent"
                    class="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
                  >
                    <img
                      src="@/assets/ftmodels/14.png"
                      alt=""
                      class="h-12 w-16 -mt-6"
                    />
                  </button>
                </div>
              </form>
            </div>
            <!-- end send message -->
            <!-- <button
                            class="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-900 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">Show
                            Full Information</button> -->
          </div>
          <!-- End of about section -->

          <!-- Experience and education -->
          <div class="bg-transparent p-3  rounded-sm">
            <div class="grid grid-cols-1">
              <div>
                
                <div>
                  <ul class="list-inside space-y-2">
                    <!-- final tab section -->

                    <!-- end of final tab section -->

                    <div class=" ">
                      <div class="flex mt-5">
                        <ul
                          class="tabs border-r grid grid-cols-3 lg:grid-cols-7 sm:grid-cols-3 md:grid-cols-3 border-gray-300"
                        >
                          <li
                            class="custom-tab-text bg-white"
                            v-for="(tab, index) in tabs"
                            :key="index"
                            @click="changeTab(index)"
                            :class="{
                              'bg-pink-200 custom-tab-text text-white':
                                index === activeTab,
                            }"
                          >
                            <a class="block p-4 font-sans hover:text-white">{{
                              tab.title
                            }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                  <div v-if="media.length && purchasedMediaRetrived" class=" ">
                    <div
                      class="tab-content p-4 bg-gray-00 h-full custom-tab-text mb-24"
                    >
                      <component :is="tabs[activeTab].component"
                        :purchasedMedia="purchasedMedia"
                        :media="media"
                      ></component>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <!-- End of Experience and education grid -->
          </div>
          <!-- End of profile tab -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from "./Tab1.vue";
import Tab2 from "./Tab2.vue";
import { USER_PROFILE ,GET_CLOUD_URL ,USER_PURCHASED_MEDIA} from "@/queries/auth";
import { SEND_MESSAGE } from "@/queries/chat";
import DarknavBar from "../darknavBar.vue";
import darknavBar from "../darknavBar.vue";
import { getLocations } from "../../utils/helpers";
export default {
  apollo: {
    userProfile: {
      query: USER_PROFILE,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      result({ data: { userProfile } }) {
        this.user = userProfile.user;
        let allMedia = []
        if(this.user.mediaGallery.length){ 
          this.user.mediaGallery.forEach(gallery => {
              if(gallery) {
                gallery.media.forEach((media)=>{
                  allMedia.push(media)
                })
              }
          });
        }
        this.media = allMedia
       
      },
    },
    getUserPurchasedMedia: {
      query: USER_PURCHASED_MEDIA,
      result({ data: { getUserPurchasedMedia } }) {
        this.purchasedMediaRetrived = true
        this.purchasedMedia = getUserPurchasedMedia
      },
    },
  },
  computed: {
    getRandomLocation() {
      return getLocations()[this.user.username.length];
    },
  },
  data() {
    return {
      user: {},
      activeTab: 0,
      message: "",
      media:[],
      purchasedMedia:[],
      purchasedMediaRetrived:false,
      tabs: [
        { title: "Pictures", component: Tab1 },
        { title: "Videos", component: Tab2 },
      ],
    };
  },
  methods: {
    async setCloudUrl(media) {
      try {
        const { data, errors } = await this.$apollo.query({
          query: GET_CLOUD_URL,
          variables: {
            filename: media,
          },
        });
        console.log(data)
      } catch (e) {
        console.log(e);
      }
    },
    getProfilePicture(user) {
      let image = null
      if(user.profilePic == 'unsafe_flag.jpg') {
        if(user.photos){ 
          image = user.photos[0].image
        }else {
          image = user.profilePic
        }
      }else {
        image = user.profilePic
      }
      return this.getURL(image)
    },
    getURL(url) {
      return `https://funnyflirts.net/media/${url}`;
    },
    changeTab(index) {
      this.activeTab = index;
    },
    messageSent() {
      if (this.message.length >= 1) {
        this.$apollo
          .mutate({
            mutation: SEND_MESSAGE,
            variables: {
              input: {
                body: this.message,
                user: this.user.username,
                attachment: "",
                message_type: "d",
                attachment_url: "",
              },
            },
          })
          .then(({ data }) => {
            if (data) {
              this.$store.commit("setBalance", data.sendMessage.flirts);
              if (this.$store.getters.showFlirton) this.showing = true;
              this.message = "";
              this.$notify(
                {
                  group: "top",
                  title: "Message sent successfully",
                  text: `Your message has been sent to ${this.user.username}
                    click Chat with me on for further conversation
                  `,
                  type: "info", // info, warning
                },
                5000
              );
            }
          })
          .catch((e) => {
            if (e.message == "GraphQL error: Spam detected") {
              this.$notify(
                {
                  group: "top",
                  title: "chat",
                  text: "please give them a minute",
                  type: "success", // info, warning
                },
                5000
              );
            } else if (
              e.message == "GraphQL error: You don't have enough flirts"
            ) {
              this.giftshowing = true;
            }
          });
      } else {
        alert("can't send empty message");
      }
    },
  },
  components: { DarknavBar },
};
</script>
<style>
.custom-tab-text {
  color: #ffffff;
}
</style>
