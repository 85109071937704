<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolLiked from './FlirttoolLiked.vue'
import YellowLiked from './YellowLiked.vue'
import PurpleLiked from './PurpleLiked.vue'
import RedLiked from './RedLiked.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirttoolLiked,
    YellowLiked,
    PurpleLiked,
    RedLiked
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolLiked'
        case 'Erotic':
          return 'FlirttoolLiked'
        case 'Yellow':
          return 'YellowLiked'
          case 'Purple':
            return 'PurpleLiked'
            case 'Red':
              return 'RedLiked'
        default:
          return 'FlirttoolLiked'
      }
    }
  }
}
</script>
