<template>
	<div class="w-full md:w-full md:mx-0" >
          <!-- Profile Card -->
          <!-- component -->
          <div
            class="rounded-3xl overflow-hidden shadow-xl my-0 w-full bg-pink-50"
          >
            <!-- profile nav bar starts here -->
            <div>
              <nav class="bg-gray-800">
                <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                  <div class="relative flex h-16 items-center justify-between">
                    <div
                      class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
                    >
                      <div class="flex flex-shrink-0 items-center">
                        <!-- <img class="block h-8 w-auto lg:hidden" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company"> -->
                        <img
                          class="h-5 w-auto"
                          src="@/assets/ftmodels/1.png"
                          alt="Your Company"
                        />
                      </div>
                      <div class="sm:ml-6 sm:block">
                        <div class="flex space-x-4">
                          <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                          <img
                            class="h-5 w-auto"
                            src="@/assets/ftmodels/2.png"
                            alt="Your Company"
                          />

                          <img
                            class="h-5 w-auto"
                            src="@/assets/ftmodels/3.png"
                            alt="Your Company"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-white">
  <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
</svg>

                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <!-- profile nav bar ends here -->
            <!-- profile card starts here -->
            <img
              src="@/assets/ftmodels/place/1.jpg"
              class="w-full h-24 object-cover"
            />

            <div class="flex justify-center -mt-8">
              <img
              :src="getURL($store.state.core.user.profilePic)"
                class="rounded-full border-4 h-36 border-pink-500 border-solid -mt-3"
              />
            </div>
            <div class="text-center px-3 pb-6 pt-2">
              <h3 class="text-gray-800 text-2xl font-bold font-sans">
                {{ $store.state.core.user.base_profile.firstName }} {{ $store.state.core.user.base_profile.lastName }}
              </h3>
              <p class="-mt-2 font-sans font-semi-bold text-gray-800">
                Username : @{{ $store.state.core.user.username }}
              </p>
              <p class="-mt-5 font-sans font-semi-bold text-gray-800">
                Languages:
              </p>
              
            </div>
        
          </div>
          <!-- End of profile card -->
          <div class="my-1"></div>
          <!-- Friends card -->
          <div class="bg-dark p-3 hover:shadow">
            
            
          </div>
          <!-- End of friends card -->
        </div>
</template>
<script>
import { getURL } from "../utils/helpers";

export default {
	methods: {
		getURL,
	},
};
</script>