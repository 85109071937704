<template>
  <div
    class="relative inline-block text-left text-gray-800"
    v-on-clickaway="closeMenu"
  >
    <div style="cursor: pointer">
      <span class="rounded-md shadow-sm">
        <div class="flex-none">
          <div class="avatar h-16 w-16" @click="isMenuOpen = !isMenuOpen">
            <!-- <img
              class="rounded-full border-4 border-pink-600 mx-2"
              :src="
                $store.state.core.user.profilePic
                  ? getURL($store.state.core.user.profilePic)
                  : require('@/assets/pholder.webp')
              "
            /> --><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-12 dark:text-white h-12 mt-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" />
</svg>

          </div>
        </div>
      </span>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="
          origin-top-right
          absolute
          right-0
          mt-2
          w-56
          rounded-md
          shadow-lg
          text-sm
          overflow-hidden
          border-none
          z-20
        "
      >
        <div
          class="rounded-md dark:bg-gray-700 bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div v-if="$store.state.core.user.base_profile">
            <div class="bg-gray-100 dark:bg-gray-800 p-4 flex items-center">
              <div class="w-full">
                <p class="font-semibold text-base dark:text-white">
                  {{ $store.state.core.user.base_profile.firstName }}
                  {{ $store.state.core.user.base_profile.lastName }}
                </p>
                <button
                  class="
                    flex
                    items-center
                    justify-between
                    w-full
                    focus:outline-none
                  "
                >
                  <p class="text-gray-600 dark:text-white">
                    @{{ $store.state.core.user.username }}
                  </p>
                 <a href="/general-settings"><img
                  
                    src="https://s.svgbox.net/hero-solid.svg?ic=cog&fill=grey-700"
                    class="h-6 w-6"
                  /></a> 
                </button>
              </div>
            </div>
          </div>
          <div class="py-1" v-for="(item, idx) in dropDownLinks" :key="idx">
            <router-link
              v-if="item.spa"
              :to="item.link"
              @click.native="isMenuOpen = false"
              class="
                p-4
                flex
                items-center
                space-x-2
                hover:bg-red-100
                dark:hover:bg-gray-600
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-5
                  w-5
                  mx-1 font-sans font-semi-bold 
                  text-gray-600
                  dark:text-white dark:hover:text-gray-600
                "
                viewBox="0 0 20 20"
                fill="currentColor"
                v-html="item.image"
              ></svg>
              <span class="font-sans font-bold text-gray-500 dark:text-white">
                {{ item.label }}
              </span>
            </router-link>
            <a
              target="_blank"
              :href="item.link"
              v-else
              class="
                p-4
                flex
                items-center
                space-x-2
                hover:bg-red-100
                dark:hover:bg-gray-600
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="
                  h-5
                  w-5
                  mx-1
                  text-gray-600
                  dark:text-white dark:hover:text-gray-600
                "
                viewBox="0 0 20 20"
                fill="currentColor"
                v-html="item.image"
              ></svg>
              <span class="font-sans font-bold text-gray-500 dark:text-white">
                {{ item.label }}
              </span>
            </a>
          </div>

<!-- important -->

          <!-- <div class="py-1">
            <a
              class="
                p-4
                flex
                items-center
                space-x-2
                hover:bg-red-100
                dark:hover:bg-gray-600 dark:text-white
              "
              @click="setTheme($store.state.core.darkTheme)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mx-1 text-gray-600 dark:text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
                v-html="$store.state.core.darkTheme ? darkSvg : lightSvg"
              ></svg>
              <span class="font-sans font-bold text-gray-500 dark:text-white">{{
                $store.state.core.darkTheme ? "Light mode" : "Dark Mode"
              }}</span>
            </a>
          </div> -->

          <div class="py-1">
            <a
              class="
                p-4
                flex
                items-center
                space-x-2
                hover:bg-red-100
                dark:hover:bg-gray-600 dark:text-white
              "
              @click="logout"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mx-1 text-gray-600 dark:text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="font-sans font-bold text-gray-500 dark:text-white">
                Logout
              </span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { getURL } from "@/utils/helpers";
import { logout } from "@/utils/basicAuth";
export default {
  mixins: [clickaway],
  data: () => ({
    isMenuOpen: false,
    label: "First label",
    isIkm: true,
    dropDownLinks: [
      {
        spa: false,
        link: "https://play.google.com/store/apps/details?id=com.flirttooldating.app",
        label: "Download App",
        image: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
</svg>`,
      },
      // {
      //   spa: true,
      //   image: `<path
      //             fill="currentColor"
      //             d="M22.59,7.92L23.75,9.33L19,14.08L16.25,11.08L17.41,9.92L19,11.5L22.59,7.92M6,5A3,3 0 0,1 9,8A3,3 0 0,1 6,11A3,3 0 0,1 3,8A3,3 0 0,1 6,5M11,5A3,3 0 0,1 14,8A3,3 0 0,1 11,11C10.68,11 10.37,10.95 10.08,10.85C10.65,10.04 11,9.06 11,8C11,6.94 10.65,5.95 10.08,5.14C10.37,5.05 10.68,5 11,5M6,13C8,13 12,14 12,16V18H0V16C0,14 4,13 6,13M12.62,13.16C14.63,13.5 17,14.46 17,16V18H14V16C14,14.82 13.45,13.88 12.62,13.16Z"
      //           ></path>`,
      //   label: "Matches",
      //   link: "/matches",
      // },
      // {
      //   spa: true,
      //   link: "/visitors",
      //   label: "Visitors",
      //   image: `<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
      //             <path
      //               fill-rule="evenodd"
      //               d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
      //               clip-rule="evenodd"
      //             />`,
      // },
      {
        spa: true,
        link: "/chat",
        label: "Chat Room",
        image: `
            <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
            <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
        `,
      },
      {
        spa: true,
        link: "/general-settings",
        label: "Settings",
        image: `<path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                />`,
      },
      {
        spa: true,
        link: "/transactions",
        label: "My Wallet",
        image: `
					<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
				</svg>`,
      },
      {
        spa: false,
        link: "/CustomerSupport",
        label: "Support",
        image: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>`,
      },
    ],
    afLink: {
      link: "/affilate",
      label: "Influencer",
      image: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
				</svg>`,
    },
    lightSvg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
			</svg>`,
    darkSvg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
			</svg>`,
  }),
  created() {
    console.clear()
    const isIkm = this.$store.state.core.isIkm;
    const item = {
      spa: true,
      link: "/affilate",
      label: isIkm ? "IkM Page" : "Influencer",
      image: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
				</svg>`,
    };
    this.dropDownLinks.splice(2, 0, item);
  },
  methods: {
    logout,
    getURL,
    setTheme(theme) {
      // const currentState = this.$store.state.darkTheme
      this.$store.commit("setTheme", !theme);
      this.isMenuOpen = false;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
  // computed: {
  // 	getAffStat() {
  // 		return this.$store.getters.getUserData;
  // 	}
  // },
  // watch: {
  // 	getAffStat(value) {
  // 		console.warn(value)
  // 		if (value)
  // 			this.dropDownLinks.unshift(this.afLink)
  // 	}
  // },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>