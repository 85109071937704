<template>
  <FakeProfie/>
</template>
<script>
import FakeProfie from "@/components/UserProfilePublic/FakeProfile";
import PublicNavBar from "@/components/PublicNavBar.vue";
import ModelProfile from '@/components/UserProfilePublic/ModelProfile'
// import FakeProfie from '@/components/UserProfilePublic/FakeProfile'


export default {
  components: { FakeProfie ,PublicNavBar,ModelProfile },
};
</script>
