<template>
	<div class="w-full lg:hidden xl:hidden   ">
		<!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile-->
		<section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-20 bg-pink-50 shadow-sm dark:bg-gray-600 ">
			<div id="tabs" class="flex justify-between py-2 mb-2">
				<a href="#"
					class="w-full focus:text-teal-500 hover:text-teal-500 justify-center flex fle text-center pt-2 pb-1">


					<router-link to="/"><span class="tab tab-home block text-xs"><svg xmlns="http://www.w3.org/2000/svg"
								fill="white" viewBox="0 0 24 24" stroke-width="1" stroke="#FF69B4"
								class="w-9 text-pink-500 h-9">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
							</svg>
						</span></router-link>
				</a>
				<!-- <a href="#" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
				
				<span  class="tab tab-home block text-xs"><svg xmlns="http://www.w3.org/2000/svg" fill="#FF69B4" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-9 h-9">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
</svg>

</span>
			</a> -->
				<a href="/chat"
					class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">

					<span class="tab tab-home block text-xs"><svg xmlns="http://www.w3.org/2000/svg"
								fill="white" viewBox="0 0 24 24" stroke-width="1" stroke="#FF69B4" class="w-9 h-9">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
							</svg></span>
				</a>
				<a href="#"
					class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">

					<router-link to="/notification"><span class="tab tab-whishlist block text-xs"><svg
								xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1"
								stroke="#FF69B4" class="w-9 h-9">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M10.5 8.25h3l-3 4.5h3" />
							</svg>
							<span v-if="$store.state.core.notifiationCount > 0"
								class="absolute inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-pink-500 rounded-full top-1 right-9">{{
									$store.state.core.notifiationCount }}</span>
						</span></router-link>
				</a>
				<a href="/settings"
					class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">

					<span class="tab tab-account block text-xs"><svg
								xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1"
								stroke="#FF69B4" class="w-9 h-9">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
							</svg>
						</span>
				</a>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	name: "MobnavBar",
};
</script>