<template>
	<div class="">
		<div class=" ">
			<!-- <div class=""
				@click="$router.push('/general-settings')">
				<div class=" " >


					<div class="bg-white border border-white shadow-lg   rounded-3xl p-1 m-4">
						<div class="flex-none sm:flex">
							<div class=" relative 2xl:h-32 2xl:w-32 xl:w-16 xl:h-16   sm:mb-0 mb-3">
								<img @click="isMenuOpen = !isMenuOpen" :src="getURL($store.state.core.user.profilePic)"
									alt="User Image" class=" w-32 h-32 object-cover rounded-2xl">
								<a href="#"
									class="absolute -right-2 bottom-2   -ml-3  text-white p-1 text-xs bg-green-400 hover:bg-green-500 font-medium tracking-wider rounded-full transition ease-in duration-300">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
										class="h-4 w-4">
										<path
											d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z">
										</path>
									</svg>
								</a>
							</div>
							<div class="flex-auto sm:ml-5 justify-evenly">
								<div class="flex items-center justify-between sm:mt-2">
									<div class="flex items-center">
										<div class="flex flex-col">
											<div class="w-full flex-none 2xl:text-lg xl:text-sm lg:text-sm text-gray-800 font-bold leading-none">
												{{ $store.state.core.user.base_profile.firstName }}
												{{ $store.state.core.user.base_profile.lastName }}</div>
											<div class="flex-auto text-gray-500 my-1">
												<span class="mr-3 2xl:text-lg xl:text-sm lg:text-sm ">Active Now</span><span
													class="mr-3 border-r border-gray-200  2xl:text-lg xl:text-sm lg:text-sm max-h-0"></span><span class="2xl:text-lg xl:text-sm lg:text-sm">@{{
														$store.state.core.user.username
													}}</span>
											</div>
										</div>
									</div>
								</div>
						
								<div class="flex pt-2 gap-2  text-sm text-gray-500">
									<div class="flex-1 inline-flex items-center">
										<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20"
											fill="currentColor">
											<path
												d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z">
											</path>
										</svg>
										<p class="-mt-4">1.2k</p>
									</div>
									<div class="flex flex-wrap items-center">
										<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20"
											fill="currentColor">
											<path fill-rule="evenodd"
												d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
												clip-rule="evenodd"></path>
										</svg>
										<p class=" -mt-4">14</p>
									</div>
									<button
										class="flex-no-shrink bg-green-400 hover:bg-green-500 px-5 ml-4 py-2 text-xs shadow-sm hover:shadow-lg font-medium tracking-wider border-2 border-green-300 hover:border-green-500 text-white rounded-full transition ease-in duration-300">Setting</button>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div> -->


		</div>
		<div class="flex bg-white gap-4 py-2 px-2 w-full" >
			<!-- <img class="object-cover mt-3 w-20 h-20  rounded-full shadow" @click="isMenuOpen = !isMenuOpen"
				:src="getURL($store.state.core.user.profilePic)" />
			<div class="flex flex-col justify-center">
				<p class="text-lg font-bold ">
				<div class="flex items-center">
					<div class="flex flex-col">
						<div
							class="flex-auto text-gray-500 my-1">
							{{ $store.state.core.user.base_profile.firstName }}
							{{ $store.state.core.user.base_profile.lastName }}</div>
						<div class="flex-auto text-gray-500 my-1">
						<span
								class="2xl:text-lg xl:text-sm lg:text-sm">@{{
									$store.state.core.user.username
								}}</span>
						</div>
					</div>
				</div>
				</p>

			</div> -->
			<!-- component -->
<div class="h-full w-full bg-gray-50 flex justify-center items-center">
      <div class="h-56 w-72 absolute flex justify-center items-center">
      <a href="/uploadpicture">  <img
          class="object-cover h-20 w-20 rounded-full -mt-10"
		  @click="isMenuOpen = !isMenuOpen"
				:src="getURL($store.state.core.user.profilePic)"
          alt=""
        /></a>
      </div>

      <div
        class="
          h-44
          mx-4
          w-5/6
          bg-purple-400
          rounded-3xl
          shadow-md
          sm:w-80 sm:mx-0
        "
      >
	   <div class="h-1/2 w-full flex justify-between items-baseline px-0 py-1">
          
		<a href="/general-settings"> <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="purple" class="w-9 h-9">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg></a>

        </div>

        <div
          class="
            bg-white
            h-1/2
            w-full
            rounded-3xl
            flex flex-col
            justify-around
            items-center
          "
        >
          <!-- <div class="w-full h-1/2 flex justify-between items-center px-3 pt-2">
            <div class="flex flex-col justify-center items-center">
              <h1 class="text-gray-500 text-xs">Orders</h1>
              <h1 class="text-gray-600 text-sm">340</h1>
            </div>
            <div class="flex flex-col justify-center items-center">
              <h1 class="text-gray-500 text-xs">Spent</h1>
              <h1 class="text-gray-600 text-sm">$2,004</h1>
            </div>
          </div> -->
          <div class="w-full h-1/2 flex flex-col justify-center items-center">
            <span class="text-gray-700 font-sans text-lg">{{ $store.state.core.user.base_profile.firstName }}
							{{ $store.state.core.user.base_profile.lastName }} <span
								class="2xl:text-lg xl:text-sm text-purple-500 lg:text-sm">/ @{{
									$store.state.core.user.username
								}}</span></span>
            <span class=" text-sm uppercase  text-green-500">Şu Anda Aktif</span >
				
          </div>
        </div>
      </div>
    </div>
		</div>
	</div>
</template>
<script>
import { getURL } from "@/utils/helpers";

export default {
	methods: {
		getURL,
	},
};
</script>