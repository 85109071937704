<template>
	<div>
		<div class="grid grid-cols-1">
			<div class="flex justify-center px-5 mt-5">
				<div class="flex">
					<h1
						class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>
						Social Links
					</h1>
				</div>
			</div>
		</div>
		<!-- start of alert message section -->
		<div
			class="mx-5 mt-8 alert"
			:class="{ 'bg-gray-100': message.error, 'bg-green-100': !message.error }"
			v-if="messageShow"
		>
			<div class="flex-1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					class="w-6 h-6 mx-2 stroke-current"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
					></path>
				</svg>
				<label>
					{{ message.body }}
				</label>
			</div>
		</div>
		<!-- end of alert message section -->

		<div class="grid grid-cols-2 mt-5 mr-7 md:mr-0 ml-7">
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>Facebook</label
				>
				<input
					v-model="facebook"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Facebook"
				/>
			</div>
			<div class="grid grid-cols-1 mx-3">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>Twitter</label
				>
				<input
					v-model="twitter"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Twitter"
				/>
			</div>
		</div>

		<div class="grid grid-cols-2 mt-5 mr-7 md:mr-0 ml-7">
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>Instagram</label
				>
				<input
					v-model="instagram"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Instagram"
				/>
			</div>
			<div class="grid grid-cols-1 mx-3">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>LinkedIn</label
				>
				<input
					v-model="linkedIn"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="LinkedIn"
				/>
			</div>
		</div>
		<div class="grid grid-cols-2 mt-5 mr-7 md:mr-0 ml-7">
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>Phonenumber</label
				>
				<input
					v-model="phonenumber"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Phonenumber"
				/>
			</div>
		</div>

		<div class="flex gap-4 pt-5 pb-5 mx-7 md:gap-6">
			<button
				@click="updateSocialLinks()"
				class="w-auto px-4 py-2 font-medium text-white bg-gray-500 rounded-lg shadow-xl hover:bg-gray-700"
			>
				<div v-if="loadingUpdate" class="mt-3 lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<p v-else>Save Changes</p>
			</button>
		</div>
	</div>
</template>
<script>
import { LOGGEDIN_DATA, UPDATE_SOCIAL_LINK } from '../../../queries/auth';
export default {
	data: () => ({
		twitter: "",
		instagram: "",
		linkedIn: "",
		facebook: "",
		phonenumber:"",
		loadingUpdate: false,
		messageShow: false,
		message: {
			error: false,
			body: ""
		}

	}),
	created() {
		this.$apollo.queries.me.refetch()
	},
	apollo: {
		me: {
			query: LOGGEDIN_DATA,
			result({ data: { me: {user : user} } }) {
				this.facebook = user.socialLink.facebook
				this.instagram = user.socialLink.instagram
				this.twitter = user.socialLink.twitter
				this.linkedIn = user.socialLink.linkedIn
				this.phonenumber = user.base_profile.phone
			},
			variables:{
				addr:"sdf"
			},
			error(e) {
				console.error(e)
			}
		}
	},
	methods: {
		updateSocialLinks() {
			this.loadingUpdate = true
			let socialLinksUpdateInput = {
				facebook: this.facebook,
				twitter: this.twitter,
				instagram: this.instagram,
				linkedIn: this.linkedIn,
				phone:this.phonenumber
			}
			Object.keys(socialLinksUpdateInput).forEach((e) => {
				if (socialLinksUpdateInput[e] === "") {
					delete (socialLinksUpdateInput[e])
				}
			})

			this.$apollo.mutate({
				mutation: UPDATE_SOCIAL_LINK,
				variables: {
					input: socialLinksUpdateInput
				}
			}).then(() => {
				this.message.error = false;
				this.messageShow = true;
				this.message.body = "Changes saved successfully"
			}).catch(e => {
				this.messageShow = true;
				this.message.error = true;
				this.message.body = "Error saving data"
				console.error(e)
			}).finally(() => {
				this.loadingUpdate = false
			})
		},
	},
};
</script>