<template>
	<div>
		<div class="flex justify-center px-5 mt-5">
			<div class="flex">
				<h1
					class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
				>
					{{$t('Yourprofile')}}
				</h1>
			</div>
		</div>
		<!-- start of alert message section -->
		<div
			class="mx-5 mt-8 alert"
			:class="{ 'bg-gray-100': message.error, 'bg-green-100': !message.error }"
			v-if="messageShow"
		>
			<div class="flex-1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					class="w-6 h-6 mx-2 stroke-current"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
					></path>
				</svg>
				<label>
					{{ message.body }}
				</label>
			</div>
		</div>
		<!-- end of alert message section -->

		<div class="grid grid-cols-1 gap-1 mt-5 mr-5 md:grid-cols-1 md:gap-8">
			<div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('AboutMe')}}</label
				>

				<textarea
					v-model="bio"
					class="h-24 mt-1 border-2 border-gray-300 shadow-xl textarea dark:text-gray-200 textarea-bordered textarea-primary focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					placeholder="Bio"
				></textarea>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-5 mt-1 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Relationship')}}</label
				>

				<select
					v-model="relationShip"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">
						{{$t('Relationshipstatus')}}
					</option>
					<option
						v-for="(rel, idx) in profileDetailSetupTypes.relationShipStatus"
						:key="idx"
					>
						{{ rel }}
					</option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Education')}}</label
				>

				<select
					v-model="educationLevel"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">
						{{$t('Education')}}
					</option>
					<option
						v-for="(rel, idx) in profileDetailSetupTypes.educationStatus"
						:key="idx"
					>
						{{ rel }}
					</option>
				</select>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-5 mt-1 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Work')}}</label
				>
				<select
					v-model="workStatus"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Work')}}</option>
					<option
						v-for="(rel, idx) in profileDetailSetupTypes.workStatus"
						:key="idx"
					>
						{{ rel }}
					</option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="
						my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light
					"
					>{{$t('YourCountry')}}</label
				>
				<select
					class="
						w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent
					"
					v-model="location"
				>
					<option disabled="disabled" selected="selected">
						{{$t('ChooseYourCountry')}}
					</option>
					<option v-for="(c,i) in counteries" :key="i" :value="c.code">{{ c.country }}</option>
				</select>
      </div>
		</div>
		
		 

		<div class="flex gap-4 pt-5 pb-5 mx-7 md:gap-6">
			<button
				class="w-auto px-4 py-2 font-medium text-white glass bg-green-500 rounded-lg shadow-xl hover:bg-gray-700"
				@click="updateUserProfileDetails"
			>
				<div v-if="loadingUpdate" class="mt-3 lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<p v-else class="-mt-4">{{$t('SaveChanges')}}</p>
			</button>
		</div>
		<div class="mb-70"></div>
	</div>
</template>
<script>
import { DEFAULT_SETTINGS, PROFILE_DETAIL_SETUP_TYPES, UPDATE_PROFILE_DETAILS } from '../../../queries/auth';
import { getLocations } from '../../../utils/helpers'
export default {
	data: () => ({
		workStatus: "",
		relationShip: "",
		bio: "",
		educationLevel: "",
		location:"",
		loadingUpdate: false,
		profileDetailSetupTypes: null,
		messageShow: false,
		message: {
			error: false,
			body: ""
		},
		counteries:[]
	}),
	apollo: {
		userSettingsData: {
			query: DEFAULT_SETTINGS,
			result({ data: { userSettingsData: { user } } }) {
				this.bio = user.user_basic.bio
				this.workStatus = user.user_basic.workstatus
				this.educationLevel = user.user_basic.education_level
				this.relationShip = user.user_basic.relationship
				this.location = user.user_basic.location
			},
			error(e) {
				console.error(e)
			}
		}
	},
	created() {
		this.$apollo.queries.userSettingsData.refetch()
		this.fetchProfileDetailSetupTypes()
		this.counteries = getLocations()
	},
	methods: {
		fetchProfileDetailSetupTypes() {
			this.$apollo.query({
				query: PROFILE_DETAIL_SETUP_TYPES
			}).then(({
				data: { profileDetailSetupTypes }
			}) => {
				this.profileDetailSetupTypes = profileDetailSetupTypes
			})
		},
		updateUserProfileDetails() {
			this.loadingUpdate = true
			this.$apollo.mutate({
				mutation: UPDATE_PROFILE_DETAILS,
				variables: {
					input: {
						bio: this.bio != "" ? this.bio : null,
						relationship: this.relationShip != "" ? this.relationShip : null,
						workStatus: this.workStatus != "" ? this.workStatus : null,
						educationLevel: this.educationLevel != "" ? this.educationLevel : null,
						location: this.location != "" ? this.location : null

					}
				}
			}).then(({ data }) => {
				this.message.error = false;
				this.messageShow = true;
				this.message.body = "Changes saved successfully"
				console.log(data, "User basic profile is updated")
			}).catch(e => {
				this.message.error = true;
				this.messageShow = true;
				this.message.body = "Error saving data"
				console.error(e)

			}).finally(() => {
				this.loadingUpdate = false
			})
		},
	}
};
</script>