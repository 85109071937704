<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolLikes from './FlirttoolLikes.vue'
import YellowLikes from './YellowLikes.vue'
import PurpleLikes from './PurpleLikes.vue'
import RedLikes from './RedLikes.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirttoolLikes,
    YellowLikes,
    PurpleLikes,
    RedLikes
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolLikes'
        case 'Erotic':
          return 'FlirttoolLikes'
        case 'Yellow':
          return 'YellowLikes'
          case 'Purple':
            return 'PurpleLikes'
            case 'Red':
              return 'RedLikes'
        default:
          return 'FlirttoolLikes'
      }
    }
  }
}
</script>
