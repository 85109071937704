<template>
  <DislikeIndex/>
</template>
<script>
import DislikeIndex from '@/templates/Dislike/Index.vue'
export default {
  components:{
    DislikeIndex
  }
}
</script>