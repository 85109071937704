<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirtoolMobileChat from './FlirtoolMobileChat.vue'
import YellowMobileChat from './YellowMobileChat.vue'
import PurpleChatRoom from './PurpleChatRoom.vue'
import RedChatRoom from './RedChatRoom.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirtoolMobileChat,
    YellowMobileChat,
    PurpleChatRoom,
    RedChatRoom
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirtoolMobileChat'
        case 'Erotic':
          return 'FlirttoolFindmatches'
        case 'Yellow':
          return 'YellowMobileChat'
          case 'Purple':
          return 'PurpleChatRoom'
          case 'Red':
            return 'RedChatRoom'
        default:
          return 'FlirtoolMobileChat'
      }
    }
  }
}
</script>
