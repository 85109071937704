<template>
    <component :is="appliedComponent" />
   </template>

<script>
import {activeTheme} from '@/themes.js'
import FlirttoolTerms from './FlirttoolTerms.vue'
import PurpleTerms from './PurpleTerms.vue'
import RedTerms from './RedTerms.vue'

export default {
 components: {
   FlirttoolTerms,
   // YellowTerms,
   PurpleTerms,
   RedTerms
   // EroticLogin
 },
 computed: {
   appliedComponent:function() {
     switch(activeTheme) {
       case 'Flirttool':
         return 'FlirttoolTerms'
       // case 'Erotic':
       //   return 'FlirttoolTerms'
       // case 'Yellow':
       //   return 'YellowTerms'
         case 'Purple':
         return 'PurpleTerms'
         case 'Red':
          return 'RedTerms'
       default:
         return 'FlirttoolTerms'
     }
   }
 }
}

</script>

