<template>
  <div
    v-if="showing"
    class="
      z-10
      fixed
      inset-0
      w-full
      h-screen
      flex
      bg-opacity-80
      items-center
      justify-center
      bg-pink-100
    "
  >
    <div class="w-full max-w-3xl bg-white shadow-lg rounded-lg p-8">
      <button
        aria-label="close"
        class="
          btn btn-sm
          relative
          top-0
          right-0
          border-pink-100
          text-xl text-gray-400
          mx-4
        "
        @click.prevent="close"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showing: {
      required: true,

      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>