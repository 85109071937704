<template>
  <div class="relative">
    <img
      src="/img/7.05fd2976.jpg"
      alt=""
      class="absolute inset-0 object-cover w-full h-full"
    />
    <div class="relative bg-opacity-0">
      <div
        class="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-5 lg:px-8 lg:py-5"
      >
        <div class="flex flex-col items-center justify-between xl:flex-row">
          <div
            class="px-4 bg-transparent py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
          >
            <p
              class="text-xl uppercase font-bold text-white text-left md:text-lg"
            >
              Photos
            </p>
            <div
              class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12"
            ></div>
            <div
              class="grid gap-2 sm:grid-cols-2 grid-cols-2 lg:grid-cols-3 myGallery"
            >
             <MediaComponent
                v-for="(mediaItem, i) of items" :key="i" 
                :mediaGallery="mediaItem"
                @mediaUnlocked="mediaUnlocked"
             />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MODEL_PICTURES } from "../../queries/matches";
import MediaComponent from './MediaComponenet.vue'
export default {
  components:{
    MediaComponent
  },
  data() {
    return {
      items:[]
    }
  },
  apollo: {
    userProfile: {
      query: MODEL_PICTURES,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      result({ data: { getModelPictures } }) {
        this.items = getModelPictures.userPurchasedMedia
        console.log(this.items)
      }
    },
  },
  methods:{
    mediaUnlocked() {
      this.$apollo.queries.getCommunityGalleryMedia.refetch();
    }
  }
};
</script>
