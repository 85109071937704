<template>
	<div>
		<div class="flex justify-center px-5 mt-5">
			<div class="flex">
				<h1
					class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
				>
				{{$t("LIFESTYLE")}}
				</h1>
			</div>
		</div>
		<div
			class="mx-5 mt-8 alert"
			:class="{ 'bg-gray-100': message.error, 'bg-green-100': !message.error }"
			v-if="messageShow"
		>
			<div class="flex-1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					class="w-6 h-6 mx-2 stroke-current"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
					></path>
				</svg>
				<label>
					{{ message.body }}
				</label>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('LivingWith')}}</label
				>

				<select
					v-model="livingWith"
					class="w-full max-w-md border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('LivingWith')}}</option>
					<option
						v-for="(sl, idx) in lFavs.livingWith"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Car')}}</label
				>
				<select
					v-model="car"
					class="w-full max-w-md border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Car')}}</option>
					<option
						v-for="(sl, idx) in lFavs.car"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Religion')}}</label
				>
				<select
					v-model="religion"
					class="w-full max-w-md border-2 border-gray-300 rounded-full shadow-xl select select-bordered dark:text-gray-200 dark:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Religion')}}</option>
					<option
						v-for="(sl, idx) in lFavs.religion"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Smoke')}}</label
				>
				<select
					v-model="smoke"
					class="w-full max-w-md border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Smoke')}}</option>
					<option
						v-for="(sl, idx) in lFavs.smoke"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Drink')}}</label
				>
				<select
					v-model="drink"
					class="w-full max-w-md border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Drink')}}</option>
					<option
						v-for="(sl, idx) in lFavs.drink"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Travel')}}</label
				>
				<select
					v-model="travel"
					class="w-full max-w-md border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Travel')}}</option>
					<option
						v-for="(sl, idx) in lFavs.travel"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>

		<!-- Favourites section -->
		<div class="flex justify-center px-5 mt-5">
			<div class="flex">
				<h1
					class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
				>
					{{$t('Favourites')}}
				</h1>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8">
			<div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>{{$t('MusicGenre')}}</label
				>
				<input
					v-model="musicGenre"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Music Genre"
				/>
			</div>
			<div class="grid grid-cols-1 mt-5 ml-7 md:ml-0 mr-7">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Dish')}}</label
				>
				<input
					v-model="dish"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Dish"
				/>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8">
			<div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm text-light dark:text-gray-200"
					>{{$t('Song')}}</label
				>
				<input
					v-model="song"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Song"
				/>
			</div>
			<div class="grid grid-cols-1 mt-5 ml-7 md:ml-0 mr-7">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
					>{{$t('Hobby')}}</label
				>
				<input
					v-model="hobby"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl dark:text-gray-200 dark:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
					type="text"
					placeholder="Hobby"
				/>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8">
			<div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Book')}}</label
				>
				<input
					v-model="book"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent dark:text-gray-200 dark:bg-gray-500"
					type="text"
					placeholder="Book"
				/>
			</div>
			<div class="grid grid-cols-1 mt-5 ml-7 md:ml-0 mr-7">
				<label
					class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Movie')}}</label
				>
				<input
					v-model="movie"
					class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent dark:text-gray-200 dark:bg-gray-500"
					type="text"
					placeholder="Movie"
				/>
			</div>
		</div>

		<!-- end of Favourites section -->

		<div class="flex gap-4 pt-5 pb-5 mx-7 md:gap-6">
			<button
				class="w-auto px-4 py-2 font-medium text-white bg-gray-500 rounded-lg shadow-xl hover:bg-gray-700"
				@click="updateUserLifeStyleDetail"
			>
				<div v-if="loadingUpdate" class="mt-3 lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<p v-else>{{$t('SaveChanges')}}</p>
			</button>
		</div>
		<div class="mb-70"></div>
	</div>
</template>
<script>
import { LIFE_STYLE_FAVOURITES_LS, LOGGEDIN_DATA, UPDATE_USER_LISFESTYLE } from '../../../queries/auth';
export default {
	data: () => ({
		lFavs: null,
		livingWith: "",
		carType: "",
		religion: "",
		smoke: "",
		drink: "",
		travel: "",
		musicGenre: "",
		dish: "",
		song: "",
		hobby: "",
		movie: "",
		book: "",
		loadingUpdate: false,
		messageShow: false,
		message: {
			error: false,
			body: ""
		}

	}),
	created() {
		this.lifeStyleAndFavouritesSetupTypes()
		this.fetchDefaultSettings()
	},
	methods: {
		lifeStyleAndFavouritesSetupTypes() {
			this.$apollo.query({
				query: LIFE_STYLE_FAVOURITES_LS
			}).then(({ data: { lifeStyleAndFavouritesSetupTypes } }) => {
				this.lFavs = lifeStyleAndFavouritesSetupTypes
			})
		},
		updateUserLifeStyleDetail() {
			this.loadingUpdate = true
			const userLifeSyleInputs = {
				// lifestyle objects
				livingWith: this.livingWith,
				carType: this.carType,
				religion: this.religion,
				smoke: this.smoke,
				drink: this.drink,
				travel: this.travel,
				// favourites type objects
				musicGenre: this.musicGenre,
				dish: this.dish,
				song: this.song,
				hobby: this.hobby,
				movie: this.movie,
				book: this.book
			}
			Object.keys(userLifeSyleInputs).forEach((e) => {
				if (userLifeSyleInputs[e] === "") {
					delete (userLifeSyleInputs[e])
				}
			})
			this.$apollo.mutate({
				mutation: UPDATE_USER_LISFESTYLE,
				variables: { input: userLifeSyleInputs }
			}).then(({ data }) => {

				this.message.error = false;
				this.messageShow = true;
				this.message.body = "Changes saved successfully"
				console.log(data, "User basic profile is updated")
			}).catch(e => {
				this.message.error = true;
				this.messageShow = true;
				this.message.body = "Error saving data"
				console.error(e)
			}).finally(() => {
				this.loadingUpdate = false
				setTimeout(() => {
					window.scrollTo(0, 0);
				}, 200);
			})
		},
		// fetch default settings
		fetchDefaultSettings() {
			this.$apollo.query({
				query: LOGGEDIN_DATA,
			}).then(({ data: { me: { user } } }) => {
				this.livingWith = user.lifeStyle.live_with
				this.carType = user.lifeStyle.car
				this.religion = user.lifeStyle.religion
				this.smoke = user.lifeStyle.smoke
				this.drink = user.lifeStyle.drinking
				this.travel = user.lifeStyle.travel
				this.musicGenre = user.favourite.music_genre
				this.dish = user.favourite.dish
				this.song = user.favourite.song
				this.hobby = user.favourite.hobby
				this.movie = user.favourite.movie
				this.book = user.favourite.book
			}).catch(e => {
				console.error(e)
			})
		},

	}
};
</script>