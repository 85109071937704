<template>
	<div :class="{ dark: $store.state.core.darkTheme }">
		<div id="app" class="dark:bg-gray-900 ">
			 <div>
    <!-- {{ $t('message') }} -->
  </div>
		<Loader v-if="show" />
		<!-- <LanguageSelect v-if="this.$store.state.core.lang == null"/> -->
			<router-view />
		</div>
	</div>
</template>


<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
#nav {
	padding: 30px;
}
#nav a {
	font-weight: bold;
	color: #2c3e50;
}
body {
  display: none;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

</style>

<script>
// import { onBackgroundMessage } from "firebase/messaging/sw";
// import * as firebase from 'firebase/app'
import { getToken, getMessaging } from 'firebase/messaging'
import Loader from './components/Loader.vue'
import axios from "axios";
import { mazuUrl } from './v-apollo.js'
import LanguageSelect from './components/LanguageSelect.vue'
// import * as firebase from 'firebase/app'

export default {
	data(){
		return {
			show:true,
			showLangSelect:false
		}
	},
	components:{
		Loader,
		LanguageSelect
	},
	async created() {
		// let language = this.$store.state.core.lang
		// if(!language){ 
		// 	this.showLangSelect = true
		// }
		this.$i18n.locale = this.$store.state.core.lang
		const messaging = getMessaging(this.$fapp);
		try {
			 await this.setDatingcommunityMeta()
			this.setFavicon()
			getToken(messaging, {
				vapidKey: "BHZ7sqo2npDeR9CpO5XVzIzG_r2ZoLB36TzqwEPonY8958a820ZTt15w6xyApMbhVjBM2sLzU-hUUWrStITRQ18"
			})
				.then((currentToken) => {
					if (currentToken) {
						console.log('client token', currentToken)
					} else {
						console.log('No registration token available. Request permission to generate one.');
					}
				}).catch((err) => {
					console.log('An error occurred while retrieving token. ', err);
				})
		} catch (e) {
			console.error(e);
		}
		// this.receiveMessage()
		// console.warn(this.$messaging)
	},
	methods: {
		setFavicon() {
			const favicon = document.getElementById("favicon");
			let communityMeta = this.$store.state.core.communutyMeta
			if(communityMeta) {
				 favicon.setAttribute("href", `https://mazutech.online/${communityMeta.logo}`); 
			}
		},
		async setDatingcommunityMeta() {
			try{
				let communityData = await axios.post(`${mazuUrl}api/system/community-meta`)
				this.$store.commit("setCommunityMeta",communityData.data)
				this.show = false
			}catch(e){
				console.log(e)
				this.show = false
				this.$store.commit("setCommunityMeta",{logo:'uploads/2dLql.png'})
				// alert(e)
			}
			
		},
		receiveMessage() {
			try {
				this.$messaging.onMessage((payload) => {
					// debugger
					console.warn(payload)
					alert("new message")
					// this.currentMessage = payload;
					// console.log(this.currentMessage);
					// console.log("Message received. ", payload);
					// let message;
					// message =
					// 	payload.data.username + ":\n\n" + payload.data.message;
					// this.setNotificationBoxForm(
					// 	payload.data.shipmentWallNumber,
					// 	payload.data.username,
					// 	payload.data.message
					// );
					// console.log(message)
					// this.notify = true;
					// setTimeout(() => {
					// 	this.notify = false;
					// }, 3000);
				});
			} catch (e) {
				console.log(e);
			}
		},
	}
}
</script>