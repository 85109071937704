<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirtoolMobileChat from './FlirtoolUserProfile.vue'
import YellowUserProfile from './YellowUserProfile.vue'
import PurpleUserProfile from './PurpleUserProfile.vue'
import RedUserProfile from './RedUserProfile.vue'

// import EroticLogin from './EroticLogin.vuess'
export default {
  components: {
    FlirtoolMobileChat,
    YellowUserProfile,
    PurpleUserProfile,
    RedUserProfile
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirtoolMobileChat'
        case 'Erotic':
          return 'FlirttoolFindmatches'
        case 'Yellow':
          return 'YellowMobileChat'
        case 'Purple':
           return 'PurpleUserProfile'
           case 'Red':
           return 'RedUserProfile'
        default:
          return 'FlirtoolMobileChat'
      }
    }
  }
}
</script>
