<template>
  <div>
    <p class="text-3xl font-bold dark:text-white" v-if="affilate">
      {{ affilate.fullName }}'s activity Details
    </p>
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div
        v-if="activities.length"
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table
          class="min-w-full divide-y divide-gray-200 mt-5"
          v-if="activities.length"
        >
          <thead class="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th
                v-for="(hd, idx) in headers"
                :key="idx"
                scope="col"
                class="
                  dark:text-gray-200
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
                v-text="hd.text"
              ></th>
            </tr>
          </thead>
          <tbody
            class="
              bg-white
              dark:text-white dark:bg-gray-800
              divide-y divide-gray-200
            "
          >
            <tr v-for="(act, idx) in activities" :key="idx" class="font-bold">
              <td class="px-6 py-4 whitespace-nowrap">
                {{ act.user.base_profile.firstName }}
                {{ act.user.base_profile.lastName }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ parseDate(act.user.createdAt) }}
              </td>
              <td>
                <div v-if="act.transactions.length">
                  <p class="px-12">
                    {{ act.transactions.length }}
                  </p>
                </div>
                <div v-else>
                  <p class="px-12">No Transaction Yet</p>
                </div>
              </td>
              <td>
                <ul
                  class="
                    w-3/4
                    m-2
                    text-sm
                    font-medium
                    text-gray-900
                    bg-white
                    border border-pink-400
                    rounded-lg
                    dark:bg-gray-700 dark:border-gray-600 dark:text-white
                  "
                >
                  <li
                    v-for="(tr, ix) in act.transactions"
                    :key="ix"
                    class="w-full py-2 px-1 border-pink-400 rounded-t-lg"
                  >
                    <p>
                      Amount:
                      <span class="font-semibold">{{ tr.amount }} USD</span>
                    </p>
                    <p>
                      Package:
                      <span
                        class="font-semibold"
                        v-text="tr.package.packageName"
                      ></span>
                    </p>
                    <p>
                      Created At: <span v-text="parseDate(tr.createdAt)"></span>
                    </p>
                  </li>
                  <li
                    v-if="!act.transactions.length"
                    class="
                      w-full
                      py-2
                      px-1
                      border-gray-200
                      rounded-t-lg
                      dark:border-gray-600
                    "
                  >
                    None
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>{{ affilate.fullName }} has no activity yet</p>
      </div>
    </div>
  </div>
</template>
<script>
import { AFF_REGISTRATIONS } from "../../queries/affilate";
import { parseDate } from "../../utils/helpers";

export default {
  created() {
    this.fetchAffialteRegistrations();
  },
  data: () => ({
    activities: [],
    affilate: null,
    headers: [
      { text: "Full Name" },
      { text: "Registration Date" },
      { text: "Transactions Count" },
      { text: "Transactions" },
    ],
  }),
  methods: {
    parseDate,
    async fetchAffialteRegistrations() {
      const { data, errors } = await this.$apollo.query({
        query: AFF_REGISTRATIONS,
        variables: {
          aff: this.$route.params.aff,
        },
      });
      if (data) {
        const { affilateRegistrations } = data;
        this.activities = affilateRegistrations.activities;
        this.affilate = affilateRegistrations.affilate;
      } else if (errors) {
        alert("error");
      }
    },
  },
};
</script>