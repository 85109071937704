<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div class="px-2 py-4 mx-auto bg-white   shadow-2xl drop-shadow-2xl max-w-screen-xl md:px-2 lg:px-2 lg:-mt-28" style="">
    <!-- <div class="col-span-2 bordered w-full">
      <div class="box-border w-full border-0 md:mx-20">
       
        <div class="hidden lg:block w-full">
          <user-avatar />
          <side-selector />
        </div>
        
      </div>
    </div> -->
    <!-- empty block to fix padding -->
    <div class="col-span-1"></div>
    <div class="col-span-9 compressedx ">
      <div class="text-left mb-7" v-if="dislikes.length > 0">
        <a class="pb-10 mx-auto">
          <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            class="
              inline-block
              w-8
              h-8
              mr-2
              stroke-current
              text-blue-500
              dark:text-gray-200
            "
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
              clip-rule="evenodd"
            />
          </svg> -->
          <span
            class="
              px-5
              font-sans
              text-sm text-center text-pink-500
              uppercase
              font-semi-bold
              text-md
              dark:text-white
              card-title
            "
            :style="
              $store.state.core.darkTheme ? 'color: #e4e3e3' : 'color: #7e7979'
            "
          >
            
          </span>
        </a>
      </div>
      <div
        v-if="dislikes.length > 0"
        class="
          grid grid-cols-2
          gap-3
          mb-12
          sm:grid-cols-2
          md:grid-cols-3
          lg:grid-cols-5
          xl:grid-cols-5
        "
      >
        <div v-for="(item, idx) in dislikes" :key="idx">
          <user-card
            @popIndex="removeItem(idx)"
            :user="item.user"
            :page="'disliked'"
          />
        </div>
      </div>
      <div v-else class="pt-10 font-sans  text-left">
        <p class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          ">
              {{ $t('nomatches') }}
            </p>
            <p class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          ">
              {{ $t('likematches') }}
            </p>
            <div class=""><button @click="$router.push('/find-matches')" class="
            mt-5 
            btn btn-accent
            rounded-xl
            btn-lg bg-red-500  glass
            mb-72
            btn-wide btn-warning
          ">
              <a style="color: #ffffff">home </a>
            </button></div>
        <!-- <p
          class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          "
        >
          You don't have any
          <span class="text-pink-500"> Dislikes</span>
        </p>
        <p
          class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          "
        >
          You can start matching with profile by liking them
        </p>
        <button
          @click="$router.push('/find-matches')"
          class="
            mt-5
            btn btn-accent
            rounded-xl
            btn-lg
            mb-72
            btn-outline btn-wide btn-warning
          "
        >
          <a style="color: #dc4b78"> Find Matches </a>
        </button> -->
      </div>

      <button
        v-if="dislikes.length > 0"
        class="
          mb-20
          btn btn-accent
          rounded-xl
          btn-lg btn-outline btn-wide btn-warning
        "
      >
        <a style="color: #dc4b78"> Load More </a>
      </button>
    </div>
    <div class="w-full">
      <!-- <section id="bottom-navigation" class="fixed inset-x-0 bottom-0 z-10 block bg-white shadow md:hidden"> // if shown only tablet/mobile-->
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import UserAvatar from "../../components/userAvatar.vue";
import SideSelector from "../../components/sideSelector.vue";
import { DISLIKES } from "../../queries/matches";
import { getURL } from "../../utils/helpers";
import UserCard from "../../components/userCard.vue";
const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    UserAvatar,
    SideSelector,
    UserCard,
  },
  apollo: {
    dislikes: {
      query: DISLIKES,
    },
  },
  data: () => ({
    dislikes: null,
    features,
    matches: [],
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],

    mobile: false,
  }),
  methods: {
    getURL,
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
    removeItem(idx) {
      this.dislikes.splice(idx, 1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener(
        "onorientationchange",
        this.detectOrientationChange
      );
    });
  },
  created() {
    this.$apollo.queries.dislikes.refetch();
    this.detectOrientationChange(); // when instance is created
  },
  metaInfo() {
    return {
      title: "GlobalChat | Online Dating | The finest global  dating website around. ",
      meta: [
        {
          name: "description",
          content:
            " GlobalChat is the dating community of the future. GlobalChat will soon define online dating. With MAZU AI System, GlobalChat dating gives everyone a chance to get their dream date",
        },
        {
          property: "og:title",
          content:
            "GlobalChat  | Online Dating | The finest global  dating website around.",
        },
        { property: "og:site_name", content: "GlobalChat  Online Dating" },
        { property: "og:type", content: "GlobalChat  Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };

  },
};
</script>
<style >
@import "../../assets/css/style.css";
</style>
