<template>
  <!-- <LoginIndex/> -->
  <home/>
</template>
<script>
import Home from '@/templates/Home/Index.vue'
export default {
  components:{
    Home
  }
}
</script>