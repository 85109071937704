<template>
  <div class="hello">
   <h1>{{ msg }}</h1>
   <br>
    <button @click="getLocation" style="border:solid 1px red;border-radiud:50%" class="pt-1 font-sans font-bold text-lg  px-7 mr-4">
      click here to get location
    </button>
    <button @click="reverseGeocode" style="border:solid 1px red;border-radiud:50%" class="pt-1 font-sans font-bold text-lg  px-7">
      click here to reverse geocode
    </button>
    <p v-if="source"> Source: {{ source }}</p>
    <p v-if="accuracy"> Accuracy: {{ accuracy }}</p>
    <div id="myMap"></div>
  </div>
</template>

<script>
export default {
  name: 'hello',
  data () {
    return {
      msg: 'Live location tracking test',
      accuracy: null,
      source: null,
      lat:null,
      lng:null,
      latD: 36.09298,
      lngD:113.84053
    }},
  mounted: function() {
    // alert("sdf")
    // let response = this.getDummyRes()
  },
  methods:{
    async reverseGeocode() {
       let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.lat},${this.lng}&key=AIzaSyAP4SfZplK-r1bZ8KJzngBlw6amnjJr-fA`,{ 
          method: 'POST',
          
        })
        let data = await response.json()
        let parsedAddress = this.parseAddress(data)
        console.log(parsedAddress)
    },
    async getLocation() {
     if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((data)=>{
          console.log('GEO DATA FROM HTML API')
          let location = {
            lat: data.coords.latitude,
            lng: data.coords.longitude
          }
          this.source = 'HTML geolocation API'
          this.lat = data.coords.latitude
          this.lng = data.coords.longitude

          this.generateMap(location)
        }, async ()=>{
          let location = await this.googleGeolocation()
          console.log('GEO DATA FROM GOOGLE API')
          this.generateMap(location)
        });
      }else{
        let location = await this.googleGeolocation()
          console.log('GEO DATA FROM GOOGLE API')
          this.generateMap(location)
      }
      
     console.log("done")
    },
    async googleGeolocation(){
        let response = await fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAP4SfZplK-r1bZ8KJzngBlw6amnjJr-fA',{ 
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body:JSON.stringify({
              considerIp:true
            })
          })
        let data = await response.json()
        let location = data.location
        this.lat = location.lat
        this.lng = location.lng
        this.accuracy= data.accuracy
        this.source = 'Google Geolocation API'
        return location
        // this.generateMap(myLatLng)
        // this.googleGeolocation()
        
    },
    parseAddress(response) {
      console.log(response)
      let results = response.results
      let parseAddress = results.reduce((accumulator,obj)=> {
        let componentCountainsCountry = obj.types.includes('country');
        let componentCountainsPostalCode = obj.types.includes('postal_code');

        if(componentCountainsCountry) {
          let address_components = obj.address_components
          let countryComponenet =  address_components.find(c => c.types.includes('country'))
          accumulator.country = countryComponenet
        }

        if(componentCountainsPostalCode) {
          let address_components = obj.address_components
          let postalComponenet =  address_components.find(c => (c.types.includes('postal_code') && c.types.length == 1 ))
          if(postalComponenet) {
            accumulator.postal_code = postalComponenet.short_name
            accumulator.street_address = obj.formatted_address
          }
        }
          return accumulator
      },{
        country:null,
        postal_code:null,
        street_address:null
      })
      // console.log(response )
      // console.log(parseAddress)
      return parseAddress
    },
    generateMap(latLang) {
      this.map = new google.maps.Map(document.getElementById('myMap'), {
        center: latLang,
        scrollwheel: false,
        zoom: 17
      })
      new google.maps.Marker({
        position: latLang,
        map:this.map,
        title: "Hello World!",
      });
    }
  },
  
  
  

}
//https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAP4SfZplK-r1bZ8KJzngBlw6amnjJr-fA
//
</script>
<style scoped>
    #myMap {
    height:500px;
    width: 100%;
   }
</style>

