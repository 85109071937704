<template>
  <div>
    <Loader v-if="loadingContent"/>
    <form class="flex flex-col p-4 shadow-lg m-4">
      <div class="relative border border-px rounded-md p-2 my-4">
        <input v-model="name" name="name" type="text" placeholder="*Name" class="outline-0 w-full" onChange={handleChange} />
      </div>
      <div class="relative border border-px rounded-md p-2 my-4">
          <input v-model="email" name="email" type="email" placeholder="*Email" class="outline-0 w-full" onChange={handleChange} />
      </div>
      <div class="relative border border-px rounded-md p-2 my-4">
          <textarea v-model="message" name="message" placeholder="*Message" class="outline-0 w-full h-14 resize-none" onChange={handleChange}>
          </textarea>
      </div>
      <button type="button" @click="sendMessage()" class="flex items-center bg-purple-900 text-white p-2 justify-center rounded-md my-4">
          <span class="mx-2 text-xl" typeof="submit">Submit</span>
      </button>
  </form>
 </div>
</template>
<script>
import { SEND_SUPPORT_EMAIL } from "@/queries/cms";
import Loader from '../components/Loader.vue'

export default {
  components:{
    Loader
  },
  data() {
    return {
      loadingContent:false,
      name:"",
      email:"",
      message:""
    }
  },
  methods: {
    async sendMessage() {
      this.loadingContent = true;
      try {
        let {data} = await this.$apollo.query({
        query: SEND_SUPPORT_EMAIL,
        fetchPolicy: "network-only",
        variables: {
          name:this.name,
          email:this.email,
          message:this.message
          },
        })
        this.name = ""
        this.email = ""
        this.message = ""
        this.loadingContent = false;
        alert("Your message has been sent")
        window.location.href = "/find-matches"
      }catch(e) {
        this.loadingContent = false;
      }
    }
  }
}
</script>
