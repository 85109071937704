<template>
  <!-- component -->

  <div class="bg-white dark:bg-gray-800">
    <div class="container p-5 mx-auto my-5">
      <div class="md:flex no-wrap md:-mx-2">
        <!-- Left Side -->
        <div class="w-full md:w-3/12 md:mx-2">
          <!-- Profile Card -->
          <div class="p-3 bg-white border-t-4 border-pink-500 dark:bg-gray-600">
            <div class="overflow-hidden image">
              <img
                class="w-full h-auto mx-auto"
                :src="author.picture.url"
                alt=""
              />
            </div>
            <h1 class="my-1 font-sans text-xl text-gray-800 dark:text-white">
              {{ author.name }}
            </h1>
            <h3
              class="
                text-lg
                leading-6
                text-gray-600 text-semibold
                dark:text-white
              "
            >
              Influencer
            </h3>
            <p
              class="
                text-xs
                leading-6
                text-gray-500
                hover:text-gray-600
                dark:text-white
              "
              v-text="author.shortDesc"
            ></p>
            <ul
              class="
                px-3
                py-2
                mt-3
                text-lg text-gray-600
                bg-gray-100
                dark:bg-gray-700
                divide-y
                rounded
                shadow-sm
                hover:text-gray-700 hover:shadow
              "
            >
              <li class="flex items-center py-3 dark:text-white">
                <span>Status</span>
                <span class="ml-auto"
                  ><span
                    class="px-2 py-1 text-sm text-white bg-pink-500 rounded"
                    >Active</span
                  ></span
                >
              </li>
              <li class="flex items-center py-3 dark:text-white">
                <span>Member since</span>
                <span class="ml-auto  dark:text-white">{{
                  formatDate(author.sys.firstPublishedAt)
                }}</span>
              </li>
              <li class="flex items-center py-3">
                <div>
                  <span
                    class="
                      text-base
                      font-bold
                      tracking-wide
                      text-center text-pink-500
                    "
                  ></span>
                  <div class="flex items-center px-20 mt-1 space-x-3">
                    <a
                      :href="author.twitter"
                      class="
                        text-blue-400
                        transition-colors
                        duration-300
                        hover:text-deep-purple-accent-400
                      "
                    >
                      <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
                        <path
                          d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
                        ></path>
                      </svg>
                    </a>
                    <a
                      :href="author.instagram"
                      class="
                        text-red-500
                        transition-colors
                        duration-300
                        hover:text-deep-purple-accent-400
                      "
                    >
                      <svg viewBox="0 0 30 30" fill="currentColor" class="h-6">
                        <circle cx="15" cy="15" r="4"></circle>
                        <path
                          d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
                        ></path>
                      </svg>
                    </a>
                    <a
                      :href="author.facebook"
                      class="
                        text-blue-500
                        transition-colors
                        duration-300
                        hover:text-deep-purple-accent-400
                      "
                    >
                      <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
                        <path
                          d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <p class="mt-4 text-sm text-gray-500"></p>
                </div>
              </li>
            </ul>
          </div>
          <!-- End of profile card -->
          <div class="my-4"></div>
          <!-- Friends card -->
          <div class="p-3 bg-white shadow-2xl hover:shadow dark:bg-gray-700">
            <div
              class="
                flex
                items-center
                space-x-3
                font-sans
                text-xl
                leading-8
                text-gray-700
                shadow-2xl
                font-semi-bold
              "
            >
              <span class="text-pink-500">
                <svg
                  class="h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </span>
              <span class="font-sans uppercase dark:text-white"
                >Influencer Profiles</span
              >
            </div>
            <div class="grid grid-cols-3 font-sans">
              <div
                class="my-2 text-center"
                v-for="(auth, key) in authorCollection.items"
                :key="key"
              >
                <img
                  class="w-16 h-16 mx-auto rounded-full"
                  :src="auth.picture.url"
                  alt=""
                /><br />
                <a href="#" class="text-sm text-pink-500 dark:text-white">{{
                  auth.name
                }}</a>
              </div>
            </div>
          </div>
          <!-- End of friends card -->
        </div>
        <!-- Right Side -->
        <div class="w-full h-64 mx-2 md:w-9/12">
          <!-- Profile tab -->
          <!-- About Section -->
          <div
            class="
              px-4
              py-5
              mx-5
              shadow-2xl
              sm:max-w-xl
              md:max-w-full
              lg:max-w-screen-xl
              md:px-1
              lg:px- lg:py-5
              bordered
              dark:bg-gray-700
            "
          >
            <div
              class="
                px-4
                py-16
                mx-auto
                dark:bg-gray-700
                sm:max-w-xl
                md:max-w-full
                lg:max-w-screen-xl
                md:px-24
                lg:px-8 lg:py-2
              "
            >
              <p class="text-left text-3xl font-bold py-5 dark:text-white">
                Contents From {{ author.name }}
              </p>
              <div
                class="
                  grid
                  gap-8
                  lg:grid-cols-2
                  sm:max-w-sm sm:mx-auto
                  lg:max-w-full
                "
              >
                <div
                  v-for="(content, key) in affilateContent"
                  :key="key"
                  class="
                    overflow-hidden
                    transition-shadow
                    duration-300
                    ease-in
                    dark:bg-gray-700
                    bg-white
                    rounded
                    shadow-sm
                  "
                >
                  <img
                    class="object-cover w-full h-64"
                    alt=""
                    :src="content.cover.url"
                  />
                  <div class="p-5 text-pink-500 border border-t-0">
                    <p
                      class="mb-3 text-xs font-semibold tracking-wide uppercase"
                    >
                      <a
                        href="/"
                        class="text-pink-500 transition-colors duration-200"
                        aria-label="Category"
                        title="traveling"
                      ></a>
                      <span class="text-gray-600 dark:text-white"
                        >— {{ formatDate(content.sys.firstPublishedAt) }}</span
                      >
                    </p>
                    <router-link
                      to="/community"
                      aria-label="Category"
                      title="Visit the East"
                      class="
                        inline-block
                        mb-3
                        text-xl
                        font-bold
                        leading-5
                        text-pink-500
                        transition-colors
                        duration-200
                      "
                      v-text="content.title"
                    ></router-link>
                    <p
                      class="mb-2 text-sm text-gray-700"
                      v-text="content.subtitle"
                    ></p>
                    <router-link
                      to="/community"
                      aria-label=""
                      class="
                        inline-flex
                        items-center
                        font-semibold
                        text-pink-500
                        transition-colors
                        duration-200
                        ease-in
                        btn
                        hover:bg-pink-500 hover:text-white
                      "
                      >more</router-link
                    >
                  </div>
                </div>
              </div>
              <p class="text-left text-3xl font-bold py-5 dark:text-white">
                More From {{ author.name }}
              </p>
              <!-- more content cards list -->
              <div
                class="
                  grid
                  gap-8
                  lg:grid-cols-2
                  sm:max-w-sm sm:mx-auto
                  lg:max-w-full
                "
              >
                <div
                  v-for="(content, key) in affilateExtras"
                  :key="key"
                  class="
                    overflow-hidden
                    transition-shadow
                    duration-300
                    ease-in
                    dark:bg-gray-700
                    bg-white
                    rounded
                    shadow-sm
                  "
                >
                  <img
                    class="object-cover w-full h-64"
                    alt=""
                    :src="content.coverImage.url"
                  />
                  <div class="p-5 text-pink-500 border border-t-0">
                    <p
                      class="mb-3 text-xs font-semibold tracking-wide uppercase"
                    ></p>
                    <a
                      target="”_blank”"
                      :href="content.link"
                      aria-label="Category"
                      title="Visit the East"
                      class="
                        inline-block
                        mb-3
                        text-xl
                        font-bold
                        leading-5
                        text-pink-500
                        transition-colors
                        duration-200
                      "
                      v-text="content.title"
                    ></a
                    ><br />
                    <a
                      target="”_blank”"
                      :href="content.link"
                      aria-label=""
                      class="
                        inline-flex
                        items-center
                        font-semibold
                        text-pink-500
                        transition-colors
                        duration-200
                        ease-in
                        btn
                        hover:bg-pink-500 hover:text-white
                      "
                      >{{ content.buttonText }}</a
                    >
                  </div>
                </div>
              </div>
              <!-- more content cards end -->
            </div>
          </div>
          <!-- End of about section -->

          <div class="my-4 dark:bg-gray-700"></div>

          <!-- Experience and education -->
          <div class="p-3 bg-white rounded-sm shadow-sm dark:bg-gray-800">
            <!-- End of Experience and education grid -->
          </div>
          <!-- End of profile tab -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AUTHOR_COLLECTION,
  GET_AUTHOR_CONTENT,
  GET_AUTHOR_EXTRAS,
  GET_AUTHOR_WSHORT,
} from "../../queries/cms";
export default {
  data: () => ({
    author: {},
    affilateExtras: [],
    affilateContent: [],
    currentShort: "dash",
    authorCollection: {},
    loadingContent: false,
  }),
  created() {
    this.getAuthorInfo();
    this.loadAffilateContent();
    this.loadAffilateExtras();
  },
  apollo: {
    affilateContentCollection: {},
    authorCollection: {
      query: AUTHOR_COLLECTION,
      client: "cms",
      variables() {
        return {
          order: "sys_firstPublishedAt_ASC",
          limit: 4,
          where: {
            authShort_not: this.$route.params.author,
          },
        };
      },
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
  },
  methods: {
    formatDate(stamp) {
      return new Date(stamp).toDateString();
    },
    loadAffilateExtras() {
      this.$apollo
        .query({
          query: GET_AUTHOR_EXTRAS,
          variables: {
            where: {
              affilateShort: this.$route.params.author,
            },
          },
          client: "cms",
          fetchPolicy: "network-only",
          context: {
            headers: {
              Authorization:
                "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
        })
        .then(
          ({
            data: {
              affilateExtrasCollection: { items },
            },
          }) => {
            this.affilateExtras = items;
          }
        );
    },
    loadAffilateContent() {
      this.loadingContent = true;
      this.$apollo
        .query({
          query: GET_AUTHOR_CONTENT,
          client: "cms",
          fetchPolicy: "network-only",
          variables: {
            where: {
              affilateShort: this.$route.params.author,
            },
          },
          context: {
            headers: {
              Authorization:
                "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
        })
        .then(
          ({
            data: {
              affilateContentCollection: { items },
            },
          }) => {
            this.affilateContent = items;
          }
        )
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loadingContent = false;
        });
    },
    getAuthorInfo() {
      this.$apollo
        .query({
          query: GET_AUTHOR_WSHORT,
          client: "cms",
          fetchPolicy: "network-only",
          context: {
            headers: {
              Authorization:
                "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
          variables: {
            where: {
              authShort: this.$route.params.author,
            },
          },
        })
        .then(
          ({
            data: {
              authorCollection: { items },
            },
          }) => {
            if (items.length > 0) {
              this.author = items[0];
            } else {
              return;
            }
          }
        );
    },
  },
};
</script>