var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-screen antialiased text-gray-800 lg:mx-48 xl:mx-48"},[_c('notification-bar'),_c('div',{staticClass:"flex flex-row h-full w-full overflow-x-hidden"},[_c('div',{staticClass:"\n        lg:flex\n        xl:flex\n        flex-col flex-auto\n        xl:h-3/4\n        lg:h-3/4 lg:mt-28\n        xl:mt-28\n        h-full\n      "},[_c('div',{staticClass:"\n          flex flex-col flex-auto flex-shrink-0\n          bg-gray-100\n          dark:bg-gray-700\n          h-full\n        "},[_c('nav',{staticClass:"bg-white dark:bg-gray-800 lg:h-20 xl:h-20 items-start"},[_c('div',{staticClass:"flex items-stretch ..."},[_c('button',{staticClass:"\n                btn btn-sm\n                my-3\n                mx-3\n                rounded-none\n                dark:text-white\n                justify-start\n              ",on:{"click":function($event){return _vm.$router.push(
                  _vm.$route.query.dm
                    ? ("/chat-list?dm=" + (_vm.$route.params.chat))
                    : '/chat-list'
                )}}},[_vm._v(" Backs ")])])]),_c('div',{staticClass:"flex flex-col flex-auto flex-shrink-0  bg-white py-10     dark:bg-gray-700 h-full"},[_c('div',{ref:"messages",staticClass:"flex-auto overflow-y-auto p-0  space-y-4 mt-0",staticStyle:{"background-size":"cover","background-image":"url(https://images.funnyflirts.net/chat.jpg)"}},[(_vm.loadingConvo)?_c('center',[_c('div',{staticClass:"mt-14 lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._l((_vm.allMessages),function(ref,i){
                var id = ref.id;
                var body = ref.body;
                var sender = ref.sender;
                var attachment_url = ref.attachment_url;
                var message_type = ref.message_type;
                var paid = ref.paid;
return _c('d-chat-message',{key:i,attrs:{"avatar":_vm.getURL(sender.profilePic),"messages":[{ id: id, body: body, attachment_url: attachment_url, message_type: message_type, paid: paid }],"sent":sender.username == _vm.$store.state.core.user.username},on:{"showFlirtOn":function($event){_vm.showing = true},"refetchChat":_vm.refetchChat}})})],2),(_vm.$store.state.core.balance > 0 || _vm.$store.state.core.user.has_hpass)?_c('message-field',{ref:"messageField",attrs:{"loading":_vm.loadingSend,"disabled":_vm.disabled},on:{"messageSent":_vm.messageSent,"photoMessageSent":_vm.photoMessageSent}}):(
              _vm.$store.state.core.user.package_activity &&
              _vm.$store.state.core.user.package_activity.active
            )?_c('message-field',{ref:"messageFieldx",attrs:{"loading":_vm.loadingSend,"disabled":_vm.disabled},on:{"messageSent":_vm.messageSent,"photoMessageSent":_vm.photoMessageSent}}):(!_vm.$store.state.core.user.package_activity)?_c('div',{staticClass:"items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4"},[_c('p',{staticClass:"text-center pt-3 dark:text-gray-200"},[_vm._v(" You don't have enough credits ")]),_c('router-link',{staticClass:"btn dark:bg-pink-200",attrs:{"to":"/flirts"}},[_vm._v("Get Flirts")])],1):(!_vm.$store.state.core.user.package_activity.active)?_c('div',{staticClass:"items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4"},[_c('p',{staticClass:"text-center pt-3 dark:text-gray-200"},[_vm._v(" You don't have enough credits ")]),_c('router-link',{staticClass:"btn dark:bg-pink-200",attrs:{"to":"/flirts"}},[_vm._v("Get Flirts")])],1):_vm._e()],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }