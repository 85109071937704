<template>
  <div class="px-1 cursor-pointer">
    <div
      class="mt-0 text-center shadow-none h-30 w-72   border-pink-700  card  bg-pink-50 dark:bg-gray-800"
      @click="$router.push('ft/@' + user.username)"
      style="width: 100%"
    >
      <figure>
        <img
          :src="
            user.profilePic
              ? getURL(user.profilePic)
              : require('@/assets/pholder.webp')
          "
          style="object-fit: cover; width: 100%; height: 150px"
          class=""
        />
        <!-- <div
          class="absolute top-0 right-0 h-16 w-16 px-2 mt-1 text-right avatar online rounded-full"
        >
          <svg
            class="h-3 w-3 shadow-lg text-green-300 bg-green-300 rounded-full shadow-green-500 animate-pulse text-right"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
          </svg>
        </div> -->
        <!-- src="https://picsum.photos/id/1005/400/250"  -->
      </figure>
      <div
        class="absolute bottom-2+ left-2 flex gap-1 text-white text-xs items-center"
      >
        <h2
          class="font-sans text-xs   text-left card-title text-gray-50 dark:text-gray-50"
          v-if="user.base_profile"
        >
          {{ user.base_profile.firstName }} {{ user.base_profile.lastName }}
          <p class="mb-5 -mt-4 text-2xs dark:text-gray-300">
            {{ user.userLocation }}
          </p>
        </h2>
        <h2 class="card-title" v-else></h2>
      </div>
    </div>
  </div>
</template>

<script>
import { DISLIKE_PROFILE, LIKE_PROFILE } from "../queries/matches";
import { getURL } from "../utils/helpers";
export default {
  data: () => ({}),
  props: {
    user: Object,
    page: String,
    geocode: Boolean,
  },
  methods: {
    getURL,
    unlike() {
      this.$emit("popIndex");
      this.$apollo.mutate({
        mutation: DISLIKE_PROFILE,
        variables: {
          user: this.user.username,
        },
      });
    },
    unmatch() {
      this.$emit("popIndex");
    },
    likeProfile() {
      this.$emit("popIndex");
      this.$apollo
        .mutate({
          mutation: LIKE_PROFILE,
          variables: {
            user: this.user.username,
          },
        })
        .then(() => {
          console.log("requset completed");
        })
        .catch((e) => {
          // catch exeption
          console.error(e);
        });
    },
    disLikeProfile() {
      this.$emit("popIndex");
    },
    removeDisliked() {
      this.$emit("popIndex");
    },
  },
};
</script>
