<template>
  <nav
    class="
      navbar
      fixed
      w-full
      z-10
      text-neutral-content
      bg-gray-50
      dark:bg-gray-800
    "
  >
    <div class="container mx-auto">
      <button
        class="btn btn-outline btn-square lg:hidden border-none"
        @click="isOpen = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 dark:text-gray-200"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="flex-1 hidden lg:block xl:block md:block sm:block">
        <a @click="$router.push('/find-matches')" class="cursor-pointer">
          <img
            class="float-left h-16"
            :src="getCommunityMeta.logo"
          />
        </a>
      </div>
      <div class="w-full lg:hidden xl:hidden"></div>
      <div class="flex-1 lg:hidden xl:hidden md:hidden sm:hidden col-end-1">
        <!-- <button
					class="flex items-center justify-between w-full focus:outline-none"
				> -->
        <div class="flex-row items-end shadow-sm">
          <notification-drop-down />

          <button
            @click="$router.push('/notification')"
            class="btn btn-circle btn-md bg-pink-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-7 w-7"
              viewBox="0 0 20 20"
              fill="#dc4b78"
            >
              <path
                d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
              />
            </svg>
          </button>
          <span
            v-if="$store.state.core.notifiationCount > 0"
            class="
              absolute
              top-3
              right-3
              inline-flex
              items-center
              justify-center
              px-2
              py-1
              text-xs
              font-bold
              leading-none
              text-red-100
              transform
              translate-x-1/2
              -translate-y-1/2
              bg-red-600
              rounded-full
            "
            >{{ $store.state.core.notifiationCount }}</span
          >
        </div>
      </div>
      <button
        v-if="!($mq === 'sm') && freeChatsUsed"
        class="
          invisible
          md:visible
          btn
          bg-pink-100
          btn-outline btn-md
          mx-2
          btn-accent
          hover:bg-pink-700
        "
      >
        <a style="color: #dc4b78" class="dark:text-white">
          Use your free chats
        </a>
      </button>
      <countdown
        v-if="
          $store.state.core.user.has_hpass ||
          $store.state.core.user.package_activity
        "
        ref="countdown"
        :time="time"
        :transform="transform"
        :emit-events="false"
      >
        <template slot-scope="props">
          <div
            class="grid grid-cols-1 w-full mt-2 pl-3"
            v-if="$store.state.core.user.package_activity"
          >
            <div
              v-if="$store.state.core.user.package_activity.active"
              class="
                font-mono
                text-2xl
                py-3
                px-2
                countdown
                text-pink-600
                dark:text-pink-600
                bg-pink-100
                dark:bg-gray-700
                bordered
              "
            >
              <span :style="`--value: ${props.days}`"></span>:
              <span :style="`--value: ${props.hours}`"></span>:
              <span :style="`--value: ${props.minutes}`"></span>:

              <span :style="`--value: ${props.seconds}`"></span>
            </div>
          </div>

          <div
            class="grid grid-cols-1 w-full mt-2 pl-3"
            v-else-if="$store.state.core.user.has_hpass"
          >
            <div
              class="
                font-mono
                text-2xl
                py-3
                px-2
                countdown
                text-pink-600
                dark:text-pink-600
                bg-pink-100
                dark:bg-gray-700
                bordered
              "
            >
              <span :style="`--value: ${props.days}`"></span>:
              <span :style="`--value: ${props.hours}`"></span>:
              <span :style="`--value: ${props.minutes}`"></span>:

              <span :style="`--value: ${props.seconds}`"></span>
            </div>
          </div>
        </template>
      </countdown>
      <!-- <credits-chip/> -->
      <router-link
        to="/register"
        class="
          btn btn-md
          shadow-sm
          text-white
          mx-2
          bg-pink-50
          btn-accent
          hover:bg-pink-200
          dark:hover:bg-gray-800
        "
        ><!--<img class="w-7 h-7" :src="require('@/assets/Flirtcoin.png')" />-->
        <p class="text-lg pt-1 px-3 font-sans text-pink-500">Register Now</p>
      </router-link>

      <aside
        class="
          transform
          top-0
          left-0
          w-64
          dark:bg-gray-800
          bg-white
          fixed
          h-full
          overflow-auto
          ease-in-out
          transition-all
          duration-300
          z-30
        "
        :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
      >
        <span
          @click="isOpen = false"
          class="flex w-full items-start p-4 border-b"
        >
          <img
            :src="require('../assets/logo-light.png')"
            alt="Logo"
            class="h-auto w-32"
          />
        </span>
        <!-- user detail block start -->
        <!-- user detail block end -->
        <span
          v-for="(item, idx) in drawerItems"
          :key="idx"
          @click="changeRoute(item)"
          class="
            flex
            items-center
            p-3
            hover:bg-pink-500 hover:text-white
            cursor-pointer
          "
          ><span class="mr-2">
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              class="w-6 h-6 dark:text-gray-200"
              v-html="item.svg"
            ></svg>
          </span>
          <span class="dark:text-gray-200">{{ item.text }}</span></span
        >

        <div class="fixed bottom-0 w-full">
          <button
            @click="logout"
            class="
              flex
              items-center
              p-4
              text-white
              bg-pink-500
              hover:bg-pink-600
              w-full
            "
          >
            <svg
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              stroke="currentColor"
              viewBox="0 0 24 24"
              class="h-6 w-6 mr-2"
            >
              <path
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              ></path>
            </svg>
            <span>Logout</span>
          </button>
        </div>
      </aside>

      <!-- end of aside drawer -->
    </div>
  </nav>
</template>
<script>
import { logout } from "../utils/basicAuth";
import NotificationDropDown from "./notificationDropDown.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { UDPATE_PASS_TIME } from "../queries/auth";
import { getCommunityMeta } from "@/utils/helpers.js";

export default {
  components: {
    NotificationDropDown,
    countdown: VueCountdown,
  },
  name: "navBar",
  props: {
    isOpen: Boolean,
  },
  computed: {
    getUserData() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    getUserData(value) {
      if (value) this.setupPassDate();
      if (this.$store.state.core.user.package_activity)
        this.setPackagePassDate();
    },
  },
  created() {
    if (this.$store.state.core.user.has_hpass) this.setupPassDate();
    else if (this.$store.state.core.user.package_activity)
      this.setPackagePassDate();
  },
  data: () => ({
    getCommunityMeta,
    time: 0,
    // time: 4 * 60 * 60 * 1000,
    freeChatsUsed: false,
    drawerItems: [
      {
        to: "/find-matches",
        text: "Find Matches",
        svg: `<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>`,
      },
      {
        to: "/matches",
        text: "Matches",
        svg: `  <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />`,
      },
      {
        to: "/visitors",
        text: "Visitors",
        svg: `
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />`,
      },
      {
        to: "/flirts",
        text: "Flirts",
        svg: `
				<path d="M3 12v3c0 1.657 3.134 3 7 3s7-1.343 7-3v-3c0 1.657-3.134 3-7 3s-7-1.343-7-3z" />
				<path d="M3 7v3c0 1.657 3.134 3 7 3s7-1.343 7-3V7c0 1.657-3.134 3-7 3S3 8.657 3 7z" />
				<path d="M17 5c0 1.657-3.134 3-7 3S3 6.657 3 5s3.134-3 7-3 7 1.343 7 3z" />`,
      },
      {
        to: "/chat-list",
        text: "Chats",
        svg: `  <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />`,
      },
      
      {
        to: "/likes",
        text: "Likes",
        svg: `<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />`,
      },
      {
        to: "/liked",
        text: "Liked Users",
        svg: `          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />`,
      },
      {
        to: "/dislikes",
        text: "People i disliked",
        svg: `<path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />`,
      },
    ],
    lightSvg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
			</svg>`,
    darkSvg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
			</svg>`,
  }),
  methods: {
    logout,
    setPackagePassDate() {
      const pact = this.$store.state.core.user.package_activity;
      const currentPackage =
        this.$store.state.core.user.package_activity.package;
      // start of the package
      // const startDate = new Date(currentPackage.startDate).packageStart
      console.log(currentPackage, "Cur Pack");

      if (currentPackage.timeValue > 0) {
        console.warn("ASdf");
        // const packageEnd = new Date(currentPackage.packageEndStamp)
        const currentStamp = new Date();
        let refStamp = new Date();

        const actStartStmp = new Date(pact.start_stamp);
        if (actStartStmp > currentStamp) {
          console.log(actStartStmp, currentStamp);
          const interval = actStartStmp - currentStamp;
          console.error(interval);
          refStamp.setMilliseconds(refStamp.getMilliseconds() + interval);
        }
        // refStamp.setMilliseconds(parseInt(currentPackage.timeValue)*60*60*1000)
        console.warn("...............................");
        console.warn(refStamp);
        console.warn("...............................");
        const { hours, minutes, seconds } = this.calculateInterval(
          currentStamp,
          refStamp
        );
        console.warn(hours, "hours");
        const time =
          0 * 24 * 60 * 60 * 1000 +
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000;
        this.time = time;
      } else {
        const packageEnd = new Date(currentPackage.packageEnd);
        const currentStamp = new Date();
        let refStamp = new Date();
        if (packageEnd > currentStamp) {
          const interval = packageEnd - currentStamp;
          refStamp.setMilliseconds(refStamp.getMilliseconds() + interval);
        }
        console.warn(currentStamp, refStamp);
        const { days, hours, minutes, seconds } = this.calculateInterval(
          currentStamp,
          refStamp
        );
        console.warn(days, "days");
        const time =
          days * 24 * 60 * 60 * 1000 +
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000;
        this.time = time;
      }
      // console.warn(days, hours, minutes, seconds)
    },
    setupPassDate() {
      if (
        this.$store.state.core.user.start_timestamp &&
        this.$store.state.core.user.has_hpass
      ) {
        const currentStamp = new Date();
        // add 4 hours here to exceed on the default limit
        const createdStamp = new Date(
          this.$store.state.core.user.start_timestamp
        );
        const { hours, minutes, seconds } = this.calculateInterval(
          currentStamp,
          createdStamp
        );
        const time =
          hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        if (currentStamp.getTime() >= createdStamp.getTime()) {
          console.clear();
          console.log("dude");
          // this.updatePassTime()
        } else {
          this.time = time;
        }
        console.error(time);
        // this.time = hours * 24 * minutes * 60 * seconds *60
      }
    },
    updatePassTime() {
      this.$apollo
        .mutate({
          mutation: UDPATE_PASS_TIME,
        })
        .then(() => {
          // update user data with a package complete state
          let uData = this.$store.state.core.user;
          uData.used_hpass = false;
          uData.has_hpass = false;
          this.$store.commit("setUserData", uData);
          this.$emit("hPassComplete");
        });
    },
    calculateInterval(date_future, date_now) {
      // get total seconds between the times
      var delta = Math.abs(date_future - date_now) / 1000;

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = delta % 60;
      return {
        days,
        hours,
        minutes,
        seconds,
      };
    },
    // resetCountdown() {
    // 	this.time = 5 * 60 * 60 * 1000;
    // },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        props[key] = value < 10 ? `0${value}` : value;
      });

      return props;
    },
    dropDownClicked() {
      alert("Dropdown clicked");
    },
    setTheme(theme) {
      // const currentState = this.$store.state.darkTheme
      this.$store.commit("setTheme", !theme);
      this.isMenuOpen = false;
    },
    changeRoute(item) {
      this.isOpen = false;
      this.$router.push(item.to);
    },
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>