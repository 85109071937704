import { render, staticRenderFns } from "./PurpleDislikes.vue?vue&type=template&id=05cb41dd&scoped=true&"
import script from "./PurpleDislikes.vue?vue&type=script&lang=js&"
export * from "./PurpleDislikes.vue?vue&type=script&lang=js&"
import style0 from "./PurpleDislikes.vue?vue&type=style&index=0&lang=css&"
import style2 from "./PurpleDislikes.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cb41dd",
  null
  
)

export default component.exports