
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

const NotificationModule = {
    state: {
        notification: {},
    },
    mutations: {
        addNotification(state, data) {
            state.notification = data;
        },
    },
    getters: {
        notificationObject(state) {
            return state.notification
        }
    }
}

const LocationModule = {
    state: {
        tempLocationDetail: {}
    },
    mutations: {
        setTempLocationDetail(state, data) {
            state.tempLocationDetail = data
        }
    },
    getters: {
        getTempLocationDetail(state) {
            return state.tempLocationDetail
        }
    }
}
const CoreModule = {
    state: {
        user: {},
        flirtok: null,
        darkTheme: false,
        randState: 0,
        afStat: false,
        isIkm: false,
        afSstat: false,
        notifiationCount: 0,
        addr: "",
        balance: 0,
        flirtonsAvailable:false,
        location:{},
        updateLocationInterval:null,
        lang:'en',
        communutyMeta:{}
    },
    mutations: {
        setCommunityMeta(state, data) {
            state.communutyMeta = data
        },
        setCurrentLocation(state, data) {
            state.location = data
        },
        setCurrentLang(state, data) {
            state.lang = data
        },
        setUpdateLocationInterval(state, data) {
            state.updateLocationInterval = data
        },
        setIsIkm(state, data) {
            state.isIkm = data
        },
        setAfSstat(state, data) {
            state.afSstat = data
        },
        setAfStat(state, data) {
            state.afStat = data
        },
        setAddr(state, data) {
            state.addr = data
        },
        setFlirtTok(state, data) {
            state.flirtok = data
        },
        setTheme(state, data) {
            state.darkTheme = data
        },
        setBalance(state, data) {
            state.balance = data
        },
        setUserData(state, data) {
            state.user = data
        },
        setFlirtonsAvailable(state,data){
            state.flirtonsAvailable = data
        },
        setRandState(state, data) {
            state.randState = data
        },
        changeNotificationCount(state, data) {
            console.log(data)
            state.notifiationCount = data || 0
        },
    },
    actions: {},
    getters: {
        getIkmStat(state) {
            return state.isIkm
        },
        getAffSstat(state) {
            return state.afSstat
        },
        getAffStat(state) {
            return state.afStat
        },
        getUserData: (state) => {
            return state.user
        },
        getCommunityMeta: (state) => {
            return state.communutyMeta
        },
        // address getter push to server with clean updates and bug fix
        getAddr: (state) => {
            return state.addr
        },
        getToken: (state) => {
            return state.flirtok
        },
        profileStatus: (state) => {
            return state.user
        },
        randStateStat: (state) => {
            return state.randState
        },
        currentLocation: (state) => {
            return state.location
        },
        updateLocationInterval:(state) => {
            return state.updateLocationInterval
        },
        showFlirton:(state) =>{
           return  state.balance < 50 && state.flirtonsAvailable ? true : false
        }
    }

}

export default new Vuex.Store({
    modules: {
        core: CoreModule,
        notification: NotificationModule,
        location: LocationModule
    },
    plugins: [createPersistedState({
        paths: ["core.user", "core.balance", "core.notifiationCount", "core.darkTheme", "core.flirtok", "core.addr", "core.afStat", "core.afSstat", "core.isIkm","core.flirtonsAvailable","core.location","core.lang","core.communutyMeta"],
    })],
})