<template>
  <div class="-mt-20 overflow-auto bg-white dark:bg-gray-800">
    <div
      class="max-w-2xl px-4 py-16 -mt-16 mx-auto sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8"
    >
    <CoolLightBox :items="allUserPics" :index="index" @close="index = null">
                  </CoolLightBox>

      <div
        class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-1 xl:gap-x-8"
      >
        <div class="relative group mt-4">
          <div
            class="overflow-hidden bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-700 lg:aspect-none lg:h-full"
            style=""
            v-if="userProfile.user.photos.length > 0"
          >
            <img
              class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem hover:border-pink-500 scale-125 hover:scale-75 ease-in duration-500"
              v-for="(pic, idx) in userProfile.user.photos"
              :key="idx"
              @click="slideClicked(idx)"
              :src="getURL(pic.image)"
              style="width: 150px; height: 150px"
            />
          </div>
        <div v-else>
          <p style="color:darkgrey">No Gallery Images Found</p>
        </div>
        </div>
        <div
          class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
          v-if="false"
        >
          <div class="relative">
            <img
              class="absolute"
              style="filter: brightness(0.5)"
              src="@/assets/sexy-couple.jpg"
            />
            <div class="relative">
              <p class="pt-20">Unlock Exclusive Pictures</p>
              <router-link
                class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white"
                to="/flirts"
                >Unlock Now</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getURL } from "../../utils/helpers";
import CoolLightBox from "vue-cool-lightbox";

export default {
  components:{CoolLightBox},
  props: ["userProfile","allUserPics"],
  data(){
    return {
      index:0
    }
  },
  methods:{
    getURL,
    slideClicked(idx) {
      console.log(idx, "IDX");
      this.index = idx;
     this.items = this.allUserPics
      // console.log(this.items,"Asdasdasd")
      // this.items = [
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // ];
    },
  }
};
</script>
