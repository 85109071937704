<template>
  <div class="antialiased max-w-6xl mx-auto my-12 bg-gray-50 px-8">
    <notification-bar />
    <div class="relative block md:flex items-center">
      <div
        class="w-full md:w-1/2 relative z-1 bg-pink-100 rounded shadow-lg overflow-hidden"
      >
        <div
          class="text-lg font-medium bg-pink-500 text-pink-500 uppercase p-8 text-center border-b border-gray-200 tracking-wide"
        >
          Your balance of Silver Package remaining Flirts 5
        </div>
        <div class="block sm:flex md:block lg:flex items-left justify-left"></div>
        <div class="flex justify-left mt-3">
          <ul>
            <li class="flex items-left">
              <span class="text-gray-700 text-left text-2xl ml-3"
                >Your balance of
                <span class="text-3xl text-bold text-pink-500">Silver Package</span><br />
                Remaining
                <span class="text-3xl text-bold text-pink-500">
                  Flirts {{ $store.state.core.balance }}</span
                >
              </span>
            </li>
            <li class="flex items-center mt-3"></li>
            <li class="flex items-center mt-3"></li>
          </ul>
        </div>
        <div class="grid-col-2">
          <a
            class="block grid-cols-2 flex items-center justify-left bg-gray-200 hover:bg-gray-300 p-8 text-md font-semibold text-gray-800 uppercase mt-16"
            href="#"
          >
            <span class="px-10">Reload</span>
            <span class="font-medium text-gray-700 ml-2">➔</span>
          </a>
          
        </div>
      </div>
      <div class="w-full md:w-1/2 relative z-0 px-1 md:px-0 md:py-16">
        <div
          class="text-white rounded-b md:rounded-b-none md:rounded-r shadow-lg overflow-hidden"
        >
          <div
            class="text-2xl font-bold bg-pink-500 font-medium uppercase p-8 text-center border-b tracking-wide"
          >
            BUY FLIRTONS
          </div>

          <div
            class="text-center text-sm w-full mt-3 sm:text-md max-w-sm mx-auto mt-1 text-gray-500 px-1 lg:px-0"
          >
            CHOOSE YOUR FLIRTONS PLAN
          </div>

          <a 
          v-for="(pkg, idx) in all_flirtons"
         :key="idx"
            class="block flex items-center justify-left bg-pink-50 hover:bg-pink-300 p-8 text-md font-semibold text-pink-500 uppercase mt-8"
            @click="buyPackage(pkg)"
          >
            <span>{{ pkg.packageName}}</span>
            <span class="font-medium text-gray-300 ml-2">➔</span>
            <span class="text-left text-sm text-gray-700 px-2"
              >{{ pkg.packageDesc }}</span
            ><button 
            class="btn px-2" >{{pkg.price}} USD</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "../../components/NotificationBar.vue";
import {
  CHECKOUT_STRIPE,
  GET_FLIRTONS,
} from "../../queries/matches";
export default {
  components: { NotificationBar },
  apollo: {
    all_flirtons: {
      query: GET_FLIRTONS,
    },
  },
  data: () => ({
    all_flirtons: [],
    selectedPkg: {},
    modal: false,
    modalV: false,
    authErr: null,
    discountObj: null,
    loadingPaypal: false,
    couponInput: null,
    loadingStripe: false,
  }),
  async created() {
    this.$apollo.queries.all_flirtons.refetch();
    let flirtonsAvailable = await this.updateFlirtonsAvailable(this.$apollo)
    if(!flirtonsAvailable) {
       this.$notify(
          {
            group: "top",
            title: "Flirtons not available",
            text: "Flirtons not available",
            type: "success", // info, warning
          },
          8000
        );
      this.$router.push('/flirts')
    }
  },
  methods: {
    buyPackage(pkg) {
      this.selectedPkg = pkg;
      this.stripeCheckout()
    },
    stripeCheckout() {
      this.loadingStripe = true;
      this.$apollo
        .mutate({
          mutation: CHECKOUT_STRIPE,
          variables: {
            input: {
              package: this.selectedPkg.packageId,
              discount: this.discountObj,
              coupon: this.couponInput,
            },
          },
        })
        .then(({ data }) => {
          window.open(data.createStripeCheckout);
          this.loadingStripe = false;
        })
        .catch(() => {
          this.loadingStripe = false;
        });
    },
  },
};
</script>
<style scoped>
.your-div {
  position: relative;
}
.your-div-span {
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  left: 0;
  widows: 100%;
  right: 0;
}
.divider:after,
.divider:before {
  content: "";
  flex-grow: 1;
  --tw-bg-opacity: 1;
  --tw-bg-opacity: var(--tw-border-opacity, 0.1);
  background-color: #ed5899;
  height: 0.425rem;
}
.modalx {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
}
.fadeIn-enter {
  opacity: 0;
}
.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}
.fadeIn-enter .modalx,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
button {
  padding: 7px;
  margin-top: 10px;
  font-size: 1.1rem;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  /* transition: opacity s ease; */
}
.container {
  width: 260px;
  height: 200px;
  position: relative;
  margin: auto;
  margin-bottom: 40px;
}
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.stack-top {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}
.glossy {
  border-radius: 33px;
  background: linear-gradient(
    rgb(255 252 254),
    rgb(211 144 202 / 78%) 50% 50px,
    rgb(255 252 254)
  );
  opacity: 27%;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 27%), 0 4px 6px -2px rgb(0 0 0 / 27%);
}
</style>