<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirtoolMobileChat from './FlirttoolHome.vue'
import YellowMobileChat from './YellowHome.vue'
import PurplethemeHome from './PurplethemeHome.vue'
import RedthemeHome from './RedthemeHome .vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirtoolMobileChat,
    YellowMobileChat,
    PurplethemeHome,
    RedthemeHome
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirtoolMobileChat'
        case 'Erotic':
          return 'FlirttoolFindmatches'
        case 'Yellow':
          return 'YellowMobileChat'
        case 'Purple':
        return 'PurplethemeHome'
        case 'Red':
          return 'RedthemeHome'
        default:
          return 'FlirtoolMobileChat'
      }
    }
  }
}
</script>
