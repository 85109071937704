<template>
  <ul
    class="py-2 list-reset md:py-0 mt-16 overflow-hidden" style="height: 100vh;"
    v-scroll-spy-active="{ class: 'customActive' }"
  >
    <li
      v-for="(item, idx) in sections"
      :key="idx"
      :class="{ customActive: item.link == $route.path }"
      class="
        py-1
        border-0 border-transparent
        md:my-2
        hover:text-pink-500
        lg:hover:bg-transparent
        font-regular
      "
    >
      <router-link
        :to="item.link"
        class="
          block
          pl-4
          text-gray-700
          no-underline
          align-middle
          dark:text-gray-200 dark:hover:text-pink-500
          hover:text-pink-500
        "
      >
        <span
          class="
            w-full
            mb-1
            font-sans
            text-lg text-left text-gray-500
            uppercase
            shadow-sm
            btn
            md:text-sm
            dark:text-gray-200
            text-light
          "
          >{{ item.title }}</span
        >
        <!-- <a
				@click="$router.push()"
			>
				<span class="pb-1 pr-10 font-sans text-lg text-left md:pb-0">{{
					item.title
				}}</span>
			</a> -->
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  data: () => ({
    sections: [
      {
        link: "/uploadpicture",
        title: "Upload picture",
      },
      {
        link: "/general-settings",
        title: "General Settings",
      },
      {
        link: "/profile-setttings",
        title: "Profile Setup",
      },
      {
        link: "/looks-personality",
        title: "Looks & Personality",
      },

      {
        link: "/lifestyle",
        title: "Lifestyle & Favourites",
      },
      {
        link: "/password-management",
        title: "Password Management",
      },
      {
        link: "/social-links",
        title: "Social Links",
      },
    ],
  }),
};
</script>