<template>
	<div>
		<header></header>
		<!-- Hero -->
		<section class="relative bg-white dark:bg-gray-50 ">
			<div class="mb-1">
				<div class="bg-white">
					<div
						class="
							px-4
							py-5
							mx-auto
							sm:max-w-xl
							md:max-w-full
							lg:max-w-screen-xl
							md:px-24
							lg:px-8
						"
					>
						<div class="relative flex items-center justify-between">
							<a
								href="/"
								aria-label="FLirtTool"
								title="FLirtTool"
								class="inline-flex items-center"
							>
								<img
									class="float-left h-16"
									:src="require('@/assets/logo-light.png')"
								/>
							</a>
							<ul class="flex items-center invisible hidden space-x-8 lg:flex">
								<li>
									<a
										href="/"
										aria-label="Our product"
										title="Our product"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Product</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="Our product"
										title="Our product"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Features</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="Product pricing"
										title="Product pricing"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Pricing</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="About us"
										title="About us"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>About us</a
									>
								</li>
							</ul>
							<ul class="flex items-center hidden space-x-8 lg:flex">
								<li>
									<button
										class="
											hidden
											rounded
											flbtn
											btn-pink
											hover:bg-white
											hover:text-black
										"
										@click="$router.push('/register')"
										type="button"
									>
										Pre Order
									</button>
								</li>
							</ul>
							<!-- Mobile menu -->
							<div class="lg:hidden">
								<button
									aria-label="Open Menu"
									title="Open Menu"
									class="
										p-2
										-mr-1
										transition
										duration-200
										rounded
										focus:outline-none
										focus:shadow-outline
									"
								>
									<svg class="w-5 text-gray-600" viewBox="0 0 24 24">
										<path
											fill="currentColor"
											d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
										></path>
										<path
											fill="currentColor"
											d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
										></path>
										<path
											fill="currentColor"
											d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
										></path>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div
					class="
						px-4
						py-16
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8
						lg:py-1
					"
				>
					<h2 class="pb-5 text-4xl">Imprint</h2>
					<p></p>
					<div>Dialogmakers International Ltd.</div>
					<div>2F, No. 2-1, Lane 23, Wenhua Street</div>
					<div>Pingzhen District, Taoyuan City, 324 Taiwan</div>
					<div></div>
					<div>Register: 83273694</div>
					<div></div>
					<div></div>
					<p>
						Email: support@funnyflirts.net<br />Internet:
						<a href="https://funnyflirts.net" target="_blank" rel="noopener"
							>https://funnyflirts.net</a
						>
					</p>
					<h2>General Terms and Conditions</h2>
					<p>
						You can find our General Terms and Conditions
						<a href="https://www.funnyflirts.net" target="_blank" rel="noopener"
							>here</a
						>.
					</p>
					<br /><br />
					<h2 class="pb-5 text-4xl">Liability</h2>
					<p>
						In the court decision, 312 O 85/98 - 'Liability for links' from 12
						May 1998 the county court in Hamburg resolved that, should links be
						included on a website, then the requisite site is thereby
						responsible for the content displayed on the linked site. To prevent
						this liability, it is necessary to expressly disassociate oneself
						from this content of these links. For this reason we, hereby, wish
						to ascertain, that we most decidedly do not endorse the content on
						these linked sites. This disclaimer applies to all links which
						appear on our website and to the complete content of these websites;
						including those underlying the displayed banners. These services
						contain links to other internet sites, to resources and to other
						users of those services. With this disclaimer we would like to state
						that we are also not in any way responsible for the availability of
						the external resources, or for their content; for the content of
						which we do not necessarily approve. In addition, we claim no
						responsibility for the advertising content, the products or for
						other matters which appear on these websites.<br />Under no
						circumstances are we responsible for any loss, or damage, caused by
						the use of the content, the goods, or the services of these sites;
						we can be made neither directly, nor indirectly, liable for these.
						All issues relating to external links should be referred to the
						relevant site administrator or webmaster.<br />We also wish to
						disassociate ourselves from offensive, illegal, morally unethical or
						objectionable content. We would be extremely grateful to you, if you
						would inform us, should you be confronted with such content.<br />All
						rights, including those concerning the duplication of materials,
						copyright, publication and translation, are reserved. None of the
						materials publicised may be duplicated in any form (as photocopies,
						microfilms or used within other processes) without the written
						permission of the author; this includes duplication or distribution
						by electronic means.
					</p>
				</div>
				<!-- </div> -->	<page-footer></page-footer>
			</div>
		</section>
	
	</div>
</template>

<script>
import PageFooter from '@/components/pageFooter.vue'
export default {
	components: {
		PageFooter
	}
}
</script>PageFooter