<template>
	<div>
		<header></header>
		<!-- Hero -->
		<section class="relative bg-white dark:bg-gray-50 ">
			<div class="mb-16">
				<div class="bg-white  ">
					<div
						class="
							px-4
							py-5
							mx-auto
							sm:max-w-xl
							md:max-w-full
							lg:max-w-screen-xl
							md:px-24
							lg:px-8
						"
					>
						<div class="relative flex items-center justify-between">
							<a
								href="/"
								aria-label="FLirtTool"
								title="FLirtTool"
								class="inline-flex items-center"
							>
								<img
									class="float-left h-16"
									:src="require('@/assets/logo-light.png')"
								/>
							</a>
							<ul class="flex items-center invisible hidden space-x-8 lg:flex">
								<li>
									<a
										href="/"
										aria-label="Our product"
										title="Our product"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Product</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="Our product"
										title="Our product"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Features</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="Product pricing"
										title="Product pricing"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Pricing</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="About us"
										title="About us"
										class="
											tracking-wide
											text-gray-800
											
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>About us</a
									>
								</li>
							</ul>
							<ul class="flex items-center hidden space-x-8 lg:flex">
								<li>
									<button
										class="
											hidden
											rounded
											flbtn
											btn-pink
											hover:bg-white
											hover:text-black
										"
										@click="$router.push('/register')"
										type="button"
									>
										Pre Order
									</button>
								</li>
							</ul>
							<!-- Mobile menu -->
							<div class="lg:hidden">
								<button
									aria-label="Open Menu"
									title="Open Menu"
									class="
										p-2
										-mr-1
										transition
										duration-200
										rounded
										focus:outline-none
										focus:shadow-outline
									"
								>
									<svg class="w-5 text-gray-600" viewBox="0 0 24 24">
										<path
											fill="currentColor"
											d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
										></path>
										<path
											fill="currentColor"
											d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
										></path>
										<path
											fill="currentColor"
											d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
										></path>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					class="
						px-4
						py-16
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8
						lg:py-20
						
					"
				>
					<h4></h4>
					<p class="pb-5 text-4xl"><strong>Privacy Policy and Terms</strong></p>
					<br />
					<p class="pt-5">
						for the mobile app FlirtTool and the website funnyflirts.net as
						offered by Dialogmakers International Ltd.
					</p>
					<br />
					<p class="pt-5"><strong>Introduction</strong></p>
					<br />
					<p class="pt-5">
						Dialogmakers International Ltd., 2F, No. 2-1, Lane 23, Wenhua
						Street, Pingzhen District, Taoyuan City, 324 (hereinafter
						“Dialogmakers International Ltd.” or “we/us”) operates a platform
						and service for chatting, getting to know and meeting other people
						(hereinafter “FlirtTool”) under the domain funnyflirts.net and with
						the help of the mobile app FlirtTool (hereinafter “FlirtTool app”),
						which is available for download in various app stores for various
						operating systems.
					</p>
					<br />
					<p class="pt-5">
						The user of FlirtTool (in the following: "user" or "you") can create
						a profile (in the following: "user profile") free of charge via the
						FlirtTool domain or FlirtTool app, upload pictures and exchange
						information with other users and their user profiles in case of
						interest. The user has the possibility to use functions such as the
						chat, the flirt radar or the match game and many other functions of
						FlirtTool. A registration is possible from the age of 18.
					</p>
					<br />
					<p class="pt-5">
						Your data will be collected, processed and used in accordance with
						the EU-General Data Protection Regulation (GDPR) 2016/679 applicable
						as of May 25th 2018 and the related privacy laws.
					</p>
					<br />
					<p class="pt-5">
						This privacy policy explains how we handle personal data. Personal
						data is individual information about personal or factual
						circumstances of a certain or determinable natural person. This
						includes, for example, name, birthday, telephone number, but also
						e-mail address and usage data, such as IP address. Furthermore, the
						data protection declaration serves to inform about which data is
						collected, stored and processed and how Dialogmakers International
						ltd. guarantees the protection and security of personal data.
					</p>
					<br />
					<p class="pt-5">
						Dialogmakers International Ltd. does not enforce the use of a real
						name. Instead of a name, the user can register with and make use of
						FlirtTool under a pseudonym.
					</p>
					<br />
					<ul>
						<li>
							<strong>
								1. How is my data collected, processed and used upon download of
								the FlirtTool app?</strong
							>
						</li>
						<br />
						<li><strong> 1.1 Download of the FlirtTool app</strong></li>
					</ul>
					<br />
					<p class="pt-5">
						Upon download of the FlirtTool app, the necessary information is
						transmitted to the respective app store operator. This is for
						example, but without limitation and subject to the app store, the
						email address or the customer number of the app store user for the
						respective app store, the time of download and an individual device
						ID. However, Dialogmakers International Ltd. has no influence on any
						data collection by the app store and is not responsible or liable in
						such way. The corresponding privacy policy / settings of the
						respective app store operator are applicable and it is recommended
						that you check those accordingly.
					</p>
					<br />
					<p class="pt-5">
						Dialogmakers International Ltd. processes the data made available by
						the respective app store operator solely to the extent necessary for
						the download of the FlirtTool app.
					</p>
					<br />
					<ul>
						<li><strong> 1.2 Installation and permissions</strong></li>
					</ul>
					<br />
					<p class="pt-5">
						FlirtTool requires various access capabilities and information for
						the technical functionality of the FlirtTool app and for the
						provision of the services offered through the FlirtTool app.
					</p>
					<br />
					<p class="pt-5">
						Depending on the operating system, your permission to us in order to
						access individual functions and information will be requested during
						the installation process. The access permissions may include, among
						others, location, messages as well as mobile data.
					</p>
					<br />
					<p class="pt-5">
						These permissions can partly be manually controlled and organized by
						you in the settings of your device. In doing so, however, it should
						be considered that, without the respective approvals, the FlirtTool
						app will only provide for limited usability or may be completely
						unusable. Depending on the app version, permissions will be
						requested either before, during or after installation.
					</p>
					<br />
					<ul>
						<li>
							<strong>
								2. How will my data be collected, processed and used upon
								visiting the FlirtTool domain or the FlirtTool app without
								creating a user profile?</strong
							>
						</li>
					</ul>
					<br />
					<p class="pt-5">
						Dialogmakers International Ltd. uses the personal data that the user
						provides or that incurred in the use of FlirtTool without creating a
						user profile, without separate consent solely for the implementation
						of the user relationship and as presented in this privacy policy.
					</p>
					<br />
					<p class="pt-5">
						Upon visiting the FlirtTool domain (to a so-called “landing page”
						or“homepage”) or installing the FlirtTool app without the creation
						of a user profile, Dialogmakers International Ltd. collects the
						following data transmitted by the user’s device/browser:
					</p>
					<br />
					<p class="pt-5">
						IP address, name of the website accessed, data, date and time of the
						request, transferred data volume, browser type and version, referrer
						URL (original URL) of the user who accessed the page. FlirtTool also
						collects the following data transmitted by the user’s device upon
						simple installation of the FlirtTool app without registration or
						creation of a user profile:
					</p>
					<br />
					<p class="pt-5">
						Push handle (for sending push notifications), location data (GPS
						location), country code, language, device name, name and version of
						the operating system.
					</p>
					<br />
					<p class="pt-5">
						These data are necessary for the use of the FlirtTool domain or the
						installation of the FlirtTool app, and as such are necessary for
						statistical purposes and to optimize FlirtTool. The data is
						processed and used to prevent and combat illegal acts and spam as
						well as to ensure the stability and integrity of the FlirtTool
						platform.
					</p>
					<br />
					<p class="pt-5">
						The location data is collected, processed and used so that the user
						can make use of the so-called location-based service, that includes
						tailored recommendations for the respective location. For more
						details please check 3.3 and 4.3 of this policy.
					</p>
					<br />
					<p class="pt-5">
						Furthermore, FlirtTool uses cookies (see item 4.1), analytics
						services and tracking providers (see item 4.2).
					</p>
					<br />
					<ul>
						<li>
							<strong>
								3. How is my data collected, processed and used after creating a
								user profile and upon use of the FlirtTool app and FlirtTool
								domain services?</strong
							>
						</li>
					</ul>
					<br />
					<p class="pt-5">
						Dialogmakers International Ltd.uses the personal data that the user
						communicates or that becomes available when using FlirtTool after
						creating a user profile, solely for the implementation of the user
						relationship and as presented in this privacy policy. For such use,
						your additional consent is not required.
					</p>
					<br />
					<p class="pt-5">
						The creation of a user profile is necessary to be able to use
						FlirtTool to its full extent and you herewith agree to this. In
						addition, you may provide further personal data, which Dialogmakers
						International Ltd.may use in order to provide the respective service
						and the best user experience.
					</p>
					<br />
					<ul>
						<li>
							<strong>
								3.1 Mandatory details for the creation of a user profile</strong
							>
						</li>
					</ul>
					<br />
					<p class="pt-5">
						For the creation of a user profile, the following details are
						essential and shall be provided:
					</p>
					<br />
					<ul>
						<li>Gender</li>
						<li>Age</li>
						<li>User name (pseudonym)</li>
						<li>City</li>
						<li>E-mail address</li>
						<li>Password</li>
					</ul>
					<br />
					<p class="pt-5">
						The data provided by you will be collected, processed and used for
						the purpose of using FlirtTool. Without limitation these details
						serve for the mandatory data like personal address, authentication,
						age verification, profile personalization, the display of suitable
						user profiles. They may also be used in connection with and for
						advertising purposes (see item 4).
					</p>
					<br />
					<ul>
						<li><strong> 3.2 Voluntary data</strong></li>
					</ul>
					<br />
					<p class="pt-5">
						In addition, you can provide various voluntary details in your user
						profile. This serves, in particular, for the FlirtTool function
						whereby recommendations for other user profiles according to various
						criteria are selected for the user and displayed within FlirtTool.
						Additional information increases the chance of, matching and meeting
						suitable user profiles. These details may be provided by you
						optional and can be viewed, entered, modified or deleted by you at
						any time in the profile under Details.
					</p>
					<br />
					<p class="pt-5">
						The data provided is collected, processed, and used by us for the
						purpose of delivering the FlirtTool service.
					</p>
					<p class="pt-5">
						These voluntary details are visible to all users logged in within
						FlirtTool, but can be deleted or modified at any time by the owner
						of the user profile under the settings in the user profile.
					</p>
					<br />
					<ul>
						<li><strong> 3.3 Location data</strong></li>
					</ul>
					<p class="pt-5">
						FlirtTool also includes so-called location-based services, under
						which the user will have access to special functions that are
						tailored to the respective location. Hereby, the user or, in
						particular, other users, who are around and nearby, can be
						displayed. In order to offer these functions within the FlirtTool
						app, FlirtTool collects location data via the GPS of the device in
						use as well as location data via radio access nodes. When the
						location service is active, with permission, the location will be
						regularly transmitted to Dialogmakers-International Ltd., where it
						will be processed and used.
					</p>
					<br />
					<p class="pt-5">
						The user can modify this function in the operating system settings
						on their device at any time. This means to permit or revoke the
						acquisition of location data.
					</p>
					<br />
					<ul>
						<li><strong> 3.4 IP addresses</strong></li>
					</ul>
					<br />
					<p class="pt-5">
						User IP addresses are recorded for security and testing purposes.
						This should prevent any misuse of the service. The data is processed
						and used to prevent and combat illegal activities and spam as well
						as securing the integrity and stability of the FlirtTool platform.
					</p>
					<br />
					<ul>
						<li><strong> 3.5 Payment data</strong></li>
					</ul>
					<br />
					<p class="pt-5">
						Any in-app purchases or the purchase of so-called “Flirts” will be
						charged by external payment providers. No payment data is collected
						or processed by Dialogmakers International Ltd.for any of such
						purchases. The entry as well as the processing of the payment data
						is solely by the payment provider. Technical data (the transaction
						ID and other) is being exchanged between Dialogmakers International
						Ltd.and the chosen payment provider in order to validate the
						purchase.
					</p>
					<br />
					<ul>
						<li>
							<strong>
								4. Which cookies, tracking providers, analytical services does
								FlirtTool use? How are Social Media and Like buttons
								embedded?</strong
							>
						</li>
						<br />
						<li><strong> 4.1 Cookies and cookie policy</strong></li>
					</ul>
					<br />
					<p class="pt-5">
						In order to be able to use the FlirtTool domain as comfortably as
						possible, we use cookies on the FlirtTool domain. Cookies are small
						text files that are stored locally in the cache of the Internet
						browser (so-called session cookies) or on the hard disk of the
						mobile device or computer used (so-called permanent cookies). The
						browser settings can be selected so that cookies are rejected or a
						reference is made to them before cookies are set. By setting
						cookies, users are assigned pseudonymized IDs. Cookies do not run
						programs on the user's end device or even transmit viruses. Session
						cookies are deleted from the cache after the end of the browser
						session.
					</p>
					<br />
					<p class="pt-5">
						Dialogmakers International Ltd.also uses cookies to increase the
						security of the FlirtTool domain. For example, to authenticate the
						user during a session, to avoid cross-site scripting or to prevent
						phishing and fraudulent actions such as scamming.
					</p>
					<br />
					<p class="pt-5">
						Furthermore, tracking providers / analysis services (see also the
						explanations under point 4.2 Use for the use of tracking providers /
						analysis services) place cookies on the user's device on behalf of
						Dialogmakers International Ltd.in order to record the usage data.
						This is data that can be collected while the user is z. B. moves on
						the pages of the FlirtTool domain or clicks on advertising. These
						cookies are used to display targeted advertising, to optimize
						reports on advertising campaigns and to prevent identical
						advertising from being displayed multiple times.
					</p>
					<br />
					<p class="pt-5">
						Users can influence the use of cookies. Most browsers have a setting
						option with which the storage of cookies is restricted or completely
						prevented.
					</p>
					<br />
					<p class="pt-5">
						However, we would like to point out that the use and in particular
						the comfort of use of FlirtTool can be restricted without the use of
						cookies.
					</p>
					<br />
					<ul>
						<li>
							<strong> 4.2 Tracking providers, analytics services</strong>
						</li>
						<br />
					</ul>
					<p class="pt-5">
						FlirtTool carries out user tracking analytics in order to optimize
						the FlirtTool services and user experience. Dialogmakers
						International Ltd.implements analytical methods that enables
						FlirtTool to analyse visits to the FlirtTool domain and FlirtTool
						app. Furthermore, third-party tracking tools are integrated to
						analyse the range of different advertising campaigns and marketing
						activities. Personal data can also be transmitted under use of
						third-party tools.
					</p>
					<br />
					<p class="pt-5">
						In addition to troubleshooting, the purpose of data processing is
						the optimization of FlirtTool with regard to user experience. With
						web analysis methods, Dialogmakers International Ltd.also obtains
						statistics on visitor numbers and their temporal browsing of popular
						content as well as the duration of visits per click and page. When
						appropriate, it can be tracked whether a user profile was created
						subsequent to viewing an advertisement. The data is processed and
						used to prevent and combat fake profiles, unlawful activities and
						spam as well as to ensure the security of the FlirtTool platform.
						User data is transmitted to various third parties to carry out the
						analysis.
					</p>
					<br />
					<p class="pt-5">
						<strong
							>The following analysis and tracking providers are currently in
							use with FlirtTool:</strong
						>
					</p>
					<br />
					<p class="pt-5">
						<strong>Crashlytics</strong><br />
						The FlirtTool app uses the Firebase Crashlytics analysis tool by
						Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
						Irland (hereinafter “Crashlytics”). This analysis program uses IP
						addresses of the users for analysis purposes, which are used only
						anonymously however. It is not possible to trace a specific person.
						Crashlytics delivers analysis to Dialogmakers International Ltd.on
						system crashes and thereby facilitates maintenance and improvement
						of the FlirtTool app and FlirtTool domain. A user ID, the clicks and
						the technical device data is transmitted to analyse the error.
					</p>
					<br />
					<p class="pt-5">
						If you have voluntarily and expressly agreed to submit a crash
						report in the App settings or after a crash, anonymous information
						(device type, version of the operating system, hardware data of the
						mobile device, current position in the source code, time of the
						crash and the state of the application for Time of the crash) will
						be transmitted to the servers of Google and stored for evaluation.
						Crash reports contain no personal information. For more information
						about privacy, visit
						<a href="http://try.crashlytics.com/terms/"
							>https://firebase.google.com/support/privacy</a
						>.
					</p>
					<br />
					<p class="pt-5">
						<strong>Google Analytics</strong><br />
						FlirtTool uses “Google Analytics”, a service by Google Ireland
						Limited, Gordon House, Barrow Street, Dublin 4, Ireland
						(hereinafter: “Google”). Dialogmakers International Ltd.points out
						that for use with FlirtTool, Google Analytics has been extended with
						the IP masking function “_gat. _anonymizeIp”, to guarantee the
						recording of anonymized IP addresses.
					</p>
					<br />
					<p class="pt-5">
						The following information on the use of Google Analytics should be
						noted:
					</p>
					<br />
					<p class="pt-5">
						Google Analytics uses cookies that are stored on the device and
						enable an analysis of the use of FlirtTool. The FlirtTool usage
						information produced by the cookie is generally transmitted to a
						Google server in the USA for storage. However, since IP
						anonymization is active, the IP addresses of all uses in the EU and
						associated countries are shortened by Google prior to transmission
						to the USA. Under contract between Dialogmakers International
						Ltd.and Google, Google uses the information to evaluate the use of
						our services, to compile reports on activities and to provide the
						operator with further services.
					</p>
					<br />
					<p class="pt-5">The user has the option to opt out:</p>
					<p class="pt-5">
						Google Analytics data acquisition and storage can be revoked at any
						time with future effect. The user also has the option to install a
						browser plugin issued by Google. This is available for various
						browser versions and can be downloaded
						<a href="https://tools.google.com/dlpage/gaoptout">here</a>. You can
						learn more about data usage for advertising purposes, settings and
						opting out on the following Google webpages:
					</p>
					<p class="pt-5">
						<a href="https://policies.google.com/technologies/partner-sites"
							>(“Google’s data usage when you use our partners’ websites and
							apps”)</a
						>,
					</p>
					<br />
					<p class="pt-5">
						<a href="https://policies.google.com/technologies/ads"
							>(“Data usage for advertising purposes”)</a
						>,
					</p>
					<br />
					<p class="pt-5">
						<a href="https://adssettings.google.com/authenticated"
							>(“Data usage for advertising purposes”)</a
						>
						and
					</p>
					<br />
					<p class="pt-5">
						<a href="https://adssettings.google.com/authenticated"
							>(“Determine what advertising Google places”).</a
						>
					</p>
					<br />
					<p class="pt-5">
						<strong>Adjust</strong>
						<br />FlirtTool uses the app “adjust”, a product of the company
						adjust GmbH, Saarbrücker Str. 37a in 10405 Berlin, Germany, as a
						usage analysis tool. When the user installs the FlirtTool app,
						adjust stores installation and event data from the iOS or Android
						app. Using this, we can understand how our users interact with the
						FlirtTool app. Moreover, we can thereby analyze and improve our
						mobile advertising campaigns. adjust uses the IDFA or advertiser ID
						as well as your anonymized ID and MAC address. The data is
						unilaterally made anonymous. This means that it is not possible to
						identify you or your mobile device. If you don’t want adjust
						tracking, please select
						<a href="https://www.adjust.com/forget-device/">here</a>.
					</p>
					<br />
					<br />
					<p class="pt-5">
						<strong>Rapidmail</strong><br />
						Send out of newsletters and other emails is carried out by our email
						marketing service provider rapidmail GmbH, Augustinerplatz 2, 79098
						Freiburg i.Br., Germany. You can see the privacy policy of the email
						marketing service provider
						<a href="https://www.rapidmail.com/data-protection">here</a>. The
						email marketing service is used on the basis of our legitimate
						interests in accordance with Article 6, paragraph 1, point f of the
						GDPR and a processor contract in accordance with Article 28,
						paragraph 3 of the GDPR.
					</p>
					<br />
					<p class="pt-5">
						<strong>Google Firebase</strong><br />
						Furthermore, we use the service “Google Firebase” for the analysis
						and characterization of our user groups and the delivery of push
						notifications. You can find Firebase’s privacy policy here (insert
						link) as well as Google’s privacy policy
						<a href="https://firebase.google.com/support/privacy">here</a>.
					</p>
					<br />
					<p class="pt-5">
						<strong>Fyber</strong><br />
						For app monetization, we use Fyber, a Fyber GmbH service from Berlin
						. The company Fyber also sets cookies. We do not use Fyber via
						JavaScript integration. Your IP address is not made accessible.
						Fyber only receives pseudonymized data from us for billing purposes.
						The data cannot be used by Fyber to trace a specific person. Further
						details on data protection can be found
						<a href="https://www.fyber.com/legal/privacy-policy/">here</a>.
					</p>
					<br />
					<p class="pt-5">
						<strong>Google-Tag-Manager</strong><br />
						We use Google Tag Manager. Google Tag Manager is a solution, with
						which marketers can manage website tags via an interface. The Tag
						Manager tool itself is a domain without cookies and the tool does
						not collect any personal data. The tool triggers other tags, which
						for their part may collect data. Google Tag Manager does not access
						this data. If disabled at the domain or cookie level, this remains
						the case for all tracking tags that were implemented with Google Tag
						Manager.
					</p>
					<br />
					<p class="pt-5">
						<strong>Google Adwords</strong><br />
						We use the online advertising program “Google AdWords” and
						conversion tracking within the framework of Google AdWords. Google
						Conversion Tracking is an analytical service by Google Ireland
						Limited, Gordon House, Barrow Street, Dublin 4, Irland ("Google").
						When you click on an advertisement placed by Google, a cookie for
						the conversion tracking will be placed on your computer. The cookies
						have limited validity, contain no personal data and do not enable a
						personal identification. If you visit certain internet pages on our
						website and the cookie has not yet expired, we and Google can
						recognize that you clicked on the advertisement and were redirected
						to this site. Every Google AdWords customer receives a different
						cookie. It is therefore not possible for cookies to be tracked over
						the websites of Google AdWords customers. The information obtained
						with the help of the conversion cookie serves for the creation of
						conversion statistics for AdWords customers, who have selected
						conversion tracking. Hereby, the customers learn the total number of
						users that have clicked on their advert and have been redirected to
						a page with a conversion tracking tag. They receive, however, no
						information that can be used to personally identify users. If you do
						not want to participate in tracking, you can opt out by blocking the
						installation of cookies through a corresponding setting in your
						browser software (deactivation option). Upon opting out you will not
						be recorded in the conversion tracking statistics. You can find
						further information as well as Google’s privacy policy
						<a href="https://www.google.com/policies/privacy/">here</a>.
					</p>
					<br />
					<p class="pt-5">
						<strong>Google ReCaptcha</strong><br />
						We integrate the function for the recognition of bots (“ReCaptcha”),
						e.g. for entries in online forms, offered by Google Ireland Limited,
						Gordon House, Barrow Street, Dublin 4, Ireland. You have the right
						to
						<a href="https://adssettings.google.com/authenticated">opt out</a>.
					</p>
					<br />
					<p class="pt-5">
						<strong>Facebook Pixel and SDK</strong><br />
						We use the "Facebook Pixel" on our websites
						<a href="https://www.yoomee.love/">https://www.FlirtTool.love</a>
						and <a href="https://yoomee.blog/">https://flirttool.blog/</a> and
						in our FlirtTool App the "Software Developer KIT" (SDK) of the
						social network Facebook, 1601 South California Avenue, Palo Alto, CA
						94304, United States. These counting pixels are integrated on our
						pages and in the FlirtTool app. When visiting our websites and using
						our app, a direct connection between your browser or mobile device
						and the Facebook server is being established.
					</p>
					<br />
					<p class="pt-5">
						Facebook gets the information from your browser that our website has
						received from your device. If you are a Facebook user, Facebook may
						assign your visit to our Sites and the use of our App to your
						account. We point out that we are not aware of the content of the
						transmitted data and their use by Facebook. We can only choose which
						category of Facebook users (such as age, interests) our ad should
						display.
					</p>
					<br />
					<p class="pt-5">
						By calling the pixel from your browser, Facebook can also tell if a
						Facebook ad was successful. In this way we record the effectiveness
						of Facebook advertisements for statistical and market research
						purposes.
					</p>
					<br />
					<p class="pt-5">
						We use the data obtained from the Facebook pixel in the Facebook
						analysis tool "Facebook Analytics" to optimize our websites and our
						app.
					</p>
					<br />
					<p class="pt-5">
						For more information, see the Facebook Privacy Policy at
						<a href="https://www.facebook.com/about/privacy/"
							>https://www.facebook.com/about/privacy/</a
						>. Please click on the following link if you do not want to collect
						data via Facebook Pixel:
						<a href="https://www.facebook.com/settings?tab=ads#_=_"
							>Opt out of Facebook Pixel Tracking here</a
						>
					</p>
					<br />
					<ul>
						<li><strong> 4.3 Social media links</strong></li>
					</ul>

					<p class="pt-5">
						Links to Dialogmakers International Ltd.accounts with the social
						networks Instagram and Facebook are incorporated on the FlirtTool
						domain. After clicking on the integrated graphic, the user will be
						redirected to the respective provider of Instagram or Facebook. This
						means that only then will user information be transmitted to the
						respective provider.
					</p>
					<br />
					<p class="pt-5">
						If the user is logged in to their user profile on the respective
						social network, after activation of the button, an association with
						the FlirtTool visit is made.
					</p>
					<br />
					<p class="pt-5">
						If the user does not want data to be collected via the FlirtTool
						domain by the social networks, they should log out of these before
						visiting the FlirtTool domain. However, every time the corresponding
						button is activated through a click, a cookie or cookies with an
						identifier are set by FlirtTool. Data could be collected and a
						profile created through the use of this function, which could, under
						certain circumstances, be traced back to an individual person. If
						the user does not want this, they can deactivate the link within the
						FlirtTool domain with a click. The user can also set up their
						browser in such a way so as to generally exclude the use of cookies.
						We point out, however, that, in this case, functionality of
						FlirtTool could be limited.
					</p>
					<br />
					<p class="pt-5">
						Information on the handling of personal data when using these
						websites can be taken from the respective provider’s privacy policy.
					</p>
					<br />
					<p class="pt-5">
						<strong>Facebook</strong><br />
						Facebooks’s privacy policy (operated by Facebook Inc., 1601 S.
						California Ave, Palo Alto, CA 94304, USA) can be found
						<a href="https://facebook.com/about/privacy/">here</a>.
					</p>
					<br />
					<p class="pt-5">
						<strong>Instagram</strong><br />
						Instagram’s privacy policy (operated by Facebook Inc., 1601 S.
						California Ave, Palo Alto, CA 94304, USA) can be found
						<!-- <a href="https://www.instagram.com/">here</a> -->
					</p>
					<br />
				</div>

				<!-- </div> --><page-footer />
			</div>
		</section>
		
	</div>
</template>
<script lang="ts">
import Vue from "vue";
import pageFooter from "@/components/pageFooter.vue";
export default Vue.extend({
	components: { pageFooter },
});
</script>