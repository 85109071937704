<template>
	<notificationGroup group="top" position="top">
		<div
			class="
				mt-16
				fixed
				inset-0
				flex
				px-4
				py-6
				pointer-events-none
				p-6
				items-start
				justify-end
				z-10
			"
		>
			<div class="max-w-sm w-full">
				<notification v-slot="{ notifications }">
					<div
						class="
							flex
							max-w-sm
							w-full
							mx-auto
							bg-white
							shadow-md
							rounded-lg
							overflow-hidden
							border-0
							mt-4
						"
						v-for="notification in notifications"
						:key="notification.id"
					>
						<div class="flex justify-center items-center w-12 border-0 bg-pink-500">
							<svg
								class="h-6 w-6 fill-current text-white"
								viewBox="0 0 40 40"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
								></path>
							</svg>
						</div>

						<div class="-mx-3 py-2 px-4">
							<div class="mx-3 text-left">
								<span class="text-pink-500 font-semibold">{{
									notification.title
								}}</span>
								<p class="text-gray-600 text-sm">{{ notification.text }}</p>
							</div>
						</div>
					</div>
				</notification>
			</div>
		</div>
	</notificationGroup>
</template>
<script>
export default {

}
</script>