<template>
	<!-- component -->
	<div class="grid-cols-2 overflow-x-hidden dark:bg-gray-800 bg-gray-100">
		<!--main blog  View e-->

		<div class="grid-cols-2 px-6 py-8">
			<div class="container flex justify-between mx-auto">
				<div class="w-full lg:w-8/12">
					<main class="mt-10" v-if="currentContent">
						<div
							class="
								w-full
								max-w-screen-md
								mx-auto
								mb-4
								relative
								overflow-hidden
								rounded-md
								shadow-lg
								cursor-pointer
							"
						>
							<img
								style="filter: brightness(50%)"
								class="object-cover w-full h-80"
								:src="currentContent.cover.url"
								alt="Flower and sky"
							/>
							<div class="absolute top-0 left-0 px-6 py-4 text-left">
								<div class="my-14"></div>
								<h4
									class="
										mb-3
										font-semibold
										lg:text-3xl
										xl:text-3xl
										text-2xl
										tracking-tight
										text-white
									"
									v-text="currentContent.title"
								></h4>
								<p
									class="leading-normal text-gray-100 text-lg"
									v-text="currentContent.subtitle"
								></p>
							</div>
						</div>
						<div
							class="
								max-w-screen-md
								px-4
								mx-auto
								mt-12
								font-sans
								text-lg
								leading-relaxed
								text-left text-gray-700
								dark:text-gray-200
								lg:px-0
							"
						>
							<div class="flex items-center" v-if="currentAuthor">
								<img
									:src="currentAuthor.picture.url"
									alt="avatar"
									class="object-cover xl:w-14 xl:h-14 w-10 h-10 rounded-full"
								/>
								<p>
									<router-link
										:to="'/influencer/' + currentAuthor.authShort"
										class="
											text-md
											sm:text-xl
											xl:text-2xl
											lg:text-2xl
											px-4
											mx-1
											font-bold
											dark:text-pink-200
											text-pink-500
										"
										v-text="currentAuthor.name"
									></router-link
									><br />
									<a
										class="
											px-5
											dark:text-white
											lg:px-4
											text-gray-700
											xl:px-4
											text-sm
											lg:block
											xl:block
											hidden
											md:block
											sm:block
										"
									>
										{{ currentAuthor.shortDesc }}
									</a>
								</p>
							</div>
							<div
								class="mb-5 lg:block xl:block hidden md:block sm:block"
							></div>
							<div class="lg:hidden xl:hidden sm:hidden md:hidden">
								<a class="text-white lg:px-4 xl:px-4 text-sm hover:text-white">
									{{ currentAuthor.shortDesc }}
								</a>
								<div class="mb-5"></div>
								<hr />
							</div>
							<div
								v-if="currentContent.youtubeVideo"
								class="
									justify-center
									mt-12
									relative
									w-full
									h-80
									mb-10
									overflow-hidden
									transition
									duration-300
									ease-in-out
									bg-center bg-cover
									rounded-none
									group
									hover:shadow-2xl
								"
							>
								<div class="video-holder">
									<div id="video-container">
										<iframe
											:src="currentContent.youtubeVideo"
											frameborder="0"
											allowfullscreen
											class="absolute top-0 left-0 w-full h-full"
										></iframe>
									</div>
								</div>
							</div>
							<!-- {{ contentHtml }}
							<div>
								<p>first</p>
								<p style="display: block;padding-top: 20px"></p>
								<p>d</p>
							</div> -->
							<p v-html="contentHtml"></p>
						</div>
						<!-- start of pagination -->
						<div class="mt-16 mb-10 float-left lg:mx-32 xl:mx-32 mx-4">
							<ul v-if="pages" class="list-reset">
								<li
									class="
										inline-block
										border
										mr-1
										border-gray-700
										dark:border-gray-200
									"
								>
									<a
										href="#"
										class="
											no-underline
											dark:text-gray-200
											text-grey-darker
											block
											py-3
											px-4
											btn
											border-none
										"
										:class="{
											'bg-grey-lightest text-grey cursor-not-allowed':
												currentPage == 1,
										}"
										@click.prevent="getPreviousPage"
										>Previous</a
									>
								</li>
								<li
									v-for="(page, index) in range"
									:key="index"
									class="
										border-gray-700
										dark:border-gray-200
										inline-block
										hover:bg-blue-lightest
										border-t border-b border-l
									"
									:class="{ 'border-r': index == range.length - 1 }"
								>
									<a
										v-if="page != '...'"
										href="#"
										class="
											no-underline
											text-grey-darker
											block
											py-3
											px-4
											btn
											dark:text-gray-200 dark:border-gray-200
											border-none
										"
										:class="{ 'bg-blue-lighter': page == currentPage }"
										@click.prevent="getPage(page)"
									>
										{{ page }}
									</a>
									<a
										v-else
										href="#"
										class="no-underline text-grey-darker block py-3 px-4"
									>
										{{ page }}
									</a>
								</li>
								<li
									class="
										inline-block
										hover:bg-blue-lightest
										border
										ml-1
										border-gray-700
										dark:border-gray-200
									"
								>
									<a
										href="#"
										class="
											no-underline
											text-grey-darker
											block
											py-3
											px-4
											btn
											dark:text-gray-200
											border-none
										"
										:class="{
											'bg-grey-lightest text-grey cursor-not-allowed':
												currentPage >= pages,
										}"
										@click.prevent="getNextPage"
										>Next</a
									>
								</li>
							</ul>
						</div>
						<!-- end of pagination -->
					</main>

					<center v-else>
						<div class="mt-14 lds-ellipsis">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</center>
					<div class="mt-8 lg:mx-32">
						<!--main blog  View end here-->
						<!--Mobile View for side grid-->

						<div class="md:w-full lg:hidden">
							<div class="px-8">
								<h1
									class="
										text-left
										mt-5
										mb-4
										pt-5
										font-sans
										text-xl
										font-bold
										dark:text-gray-200
										text-gray-700
										uppercase
									"
								>
									Influencer
								</h1>
								<div
									class="
										flex flex-col
										xl:max-w-sm
										lg:max-w-sm
										px-6
										py-4
										mx-auto
										dark:bg-gray-700
										bg-white
										rounded-none
										shadow-md
									"
								>
									<ul class="-mx-4">
										<li
											class="my-3 flex items-center"
											v-for="(auth, idx) in authorCollection.items"
											:key="idx"
										>
											<img
												:src="auth.picture.url"
												alt="avatar"
												class="object-cover w-10 h-10 mx-4 rounded-full"
											/>
											<p>
												<a
													@click="loadAffilateContent(auth)"
													class="
														mx-1
														font-bold
														dark:text-gray-200
														text-gray-700
														hover:underline
													"
													v-text="auth.name"
												></a
												><span
													class="
														text-sm
														font-light
														text-gray-700
														dark:text-gray-200
													"
													v-text="formatDate(auth.sys.firstPublishedAt)"
												></span>
											</p>
										</li>
									</ul>
								</div>
							</div>
							<div class="px-8 mt-10 text-left">
								<h1
									class="
										mb-4
										font-sans
										text-lg
										font-bold
										text-left
										dark:text-gray-200
										text-gray-700
										uppercase
									"
								>
									Categories
								</h1>
								<div
									class="
										flex flex-col
										xl:max-w-sm
										lg:max-w-sm
										px-4
										py-6
										mx-auto
										bg-white
										dark:bg-gray-700
										rounded-none
										shadow-md
									"
								>
									<div
										v-for="(tags, idx) in currentContent.tags"
										:key="idx"
										class="mt-1"
									>
										<a
											href="#"
											class="
												py-1
												px-2
												text-gray-700 text-sm
												dark:text-pink-100
												bg-pink-100
												dark:bg-gray-600
												rounded
												dark:hover:bg-gray-500
												hover:bg-pink-200
											"
											v-text="tags"
										>
										</a>
									</div>
								</div>
							</div>
							<div class="px-8 mt-10">
								<h1
									class="
										mb-4
										font-sans
										text-xl
										font-bold
										text-left
										dark:text-gray-200
										text-gray-700
										uppercase
									"
								>
									Recent Post
								</h1>
								<div
									class="
										flex flex-col
										lg:max-w-sm
										xl:max-w-sm
										px-8
										py-6
										mx-auto
										dark:bg-gray-700
										bg-white
										rounded-none
										shadow-md
									"
									:key="x"
									v-for="(post, x) in recentPosts"
								>
									<div class="mt-4">
										<a
											href="#"
											class="
												font-bold
												text-sm text-left
												dark:text-gray-200
												text-gray-700
											"
											v-text="post.title"
										></a>
										<p
											href="#"
											class="
												text-lg
												font-medium
												dark:text-gray-200
												text-gray-700
											"
											v-text="post.subtitle"
										></p>
									</div>

									<img
										:src="post.cover.url"
										class="object-cover w-full h-full"
										alt=""
									/>
								</div>
							</div>
						</div>
						<!--Mobile View for side grid ends here-->
					</div>
				</div>
				<!--Desktop View for side grid here-->
				<div class="hidden w-4/12 mt-12 lg:block">
					<div class="px-16 text-left">
						<h1
							class="
								mt-5
								mb-4
								font-sans
								text-xl
								font-bold
								dark:text-gray-200
								text-gray-700
								uppercase
							"
						>
							Influencer
						</h1>
						<div
							class="
								flex flex-col
								max-w-sm
								px-6
								py-4
								mx-auto
								dark:bg-gray-700
								bg-white
								rounded-none
								shadow-md
							"
						>
							<ul class="-mx-4">
								<li
									class="my-3 flex items-center"
									v-for="(auth, idx) in authorCollection.items"
									:key="idx"
								>
									<img
										:src="auth.picture.url"
										alt="avatar"
										class="object-cover w-10 h-10 mx-4 rounded-full"
									/>
									<p>
										<a
											@click="loadAffilateContent(auth)"
											class="
												mx-1
												font-bold
												dark:text-gray-200
												text-gray-700
												hover:underline
											"
											v-text="auth.name"
										></a
										><span
											class="
												text-sm
												font-light
												text-gray-700
												dark:text-gray-200
											"
											v-text="formatDate(auth.sys.firstPublishedAt)"
										></span>
									</p>
								</li>
							</ul>
						</div>
					</div>
					<div class="px-16 mt-10 text-left" v-if="currentContent">
						<h1
							class="
								mb-4
								font-sans
								text-lg
								font-bold
								dark:text-gray-200
								text-gray-700
								uppercase
							"
							v-if="currentContent.tags"
						>
							Categories
						</h1>
						<div
							v-if="currentContent.tags"
							class="
								max-w-sm
								px-2
								grid grid-cols-3
								py-2
								dark:bg-gray-700
								bg-white
								rounded-none
								shadow-md
							"
						>
							<div
								v-for="(tags, idx) in currentContent.tags"
								:key="idx"
								class="mt-1"
							>
								<a
									href="#"
									class="
										py-1
										px-2
										text-gray-700 text-sm
										dark:text-pink-100
										bg-pink-100
										dark:bg-gray-600
										rounded
										dark:hover:bg-gray-500
										hover:bg-pink-200
									"
									v-text="tags"
								>
								</a>
							</div>
						</div>
					</div>
					<div class="px-16 text-left mt-10">
						<h1
							class="
								mb-4
								font-sans
								text-xl
								font-bold
								dark:text-gray-200
								text-gray-700
								uppercase
							"
						>
							Recent Post
						</h1>
						<div
							class="
								flex flex-col
								max-w-sm
								px-8
								py-6
								mx-auto
								bg-white
								dark:bg-gray-700
								rounded-none
								shadow-md
							"
							:key="x"
							v-for="(post, x) in recentPosts"
						>
							<div class="mt-4">
								<a
									href="#"
									class="
										font-bold
										text-lg
										dark:text-gray-200
										text-gray-700
										hover:underline
									"
									v-text="post.title"
								></a>
								<p
									href="#"
									class="text-lg font-medium dark:text-gray-200 text-gray-700"
									v-text="post.subtitle"
								></p>
							</div>

							<img
								:src="post.cover.url"
								class="object-cover w-full h-full"
								alt=""
							/>
						</div>
					</div>
				</div>
				<!--Desktop View for side grid ends here-->
			</div>
		</div>
		<div class="">
			<div
				class="
					px-4
					py-16
					xl:mx-60
					lg:mx-10
					sm:max-w-xl
					md:max-w-full
					lg:max-w-screen-xl
					md:px-24
					lg:px-8 lg:py-20
				"
			>
				<div
					class="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full"
				>
					<!-- start of additional blogs -->
					<div
						class="
							overflow-hidden
							transition-shadow
							duration-300
							bg-white
							dark:bg-gray-800
							rounded
							shadow-sm
						"
						v-for="(blog, x) in blogCollection.items"
						:key="x"
					>
						<img
							:src="blog.cover.url"
							class="object-cover w-full h-64"
							alt=""
						/>
						<div class="p-5 border border-t-0">
							<a
								@click="toDetail(blog.sys.id)"
								aria-label="Category"
								title="Visit the East"
								class="
									inline-block
									mb-3
									text-2xl
									font-bold
									leading-5
									transition-colors
									duration-200
									hover:text-deep-purple-accent-700
								"
								v-text="blog.title"
							></a>
							<p
								class="mb-2 text-gray-700 dark:text-gray-200"
								v-text="blog.subtitle"
							></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import * as contentful from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ALL_AUTHORS, GET_AUTHOR, GET_AUTHOR_CONTENT, GET_BLOGS, GET_RECENT_POST, SINGLE_AUTHOR_CONTENT } from '../../queries/cms'

export default {
	apollo: {
		blogCollection: {
			query: GET_BLOGS,
			client: "cms",
			context: {
				headers: {
					"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
				}
			},
			variables: {
				limit: 3
			}
		},
		authorCollection: {
			query: ALL_AUTHORS,
			client: "cms",
			context: {
				headers: {
					"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
				}
			},
			variables: {
				limit: 4
			},
			result() {
				this.loadingContent = false
			}
		}
	},
	computed: {
		propsToWatch() {
			return this.pages, this.currentPage, Date.now()
		}
	},
	watch: {
		propsToWatch: {
			handler: 'organisePageLinks',
			immediate: true
		}
	},
	data: () => ({
		pages: 2,
		range: [],
		currentPage: 1,
		recentPosts: null,
		blogCollection: [],
		currentAuthor: null,
		authorCollection: [],
		loadingContent: true,
		currentContent: null,
		contentHtml: ""
	}),
	created() {
		this.fetchRandomContent()
		this.loadRecentPosts()
	},
	methods: {
		organisePageLinks() {
			this.range = []
			for (let i = 1; i <= this.pages; i++) {
				if (
					i == 1 || // first page
					i == this.pages || // last page
					i == this.currentPage || // current page
					i == this.currentPage - 1 || // page before current
					i == this.currentPage + 1 || // page after current
					(i <= 4 && this.currentPage < 4) || // "filler" links at start
					(i >= this.pages - 3 && this.currentPage > this.pages - 3) // "filler" links at end
				) {
					let index = this.range.length
					if (index > 0 && this.range[index - 1] < i - 1) {
						// if this page is not the next one insequence, add in 3 dots "..."
						this.range.push('...')
					}
					this.range.push(i)
				}
			}
		},
		getPage(page) {
			this.currentPage = page
			this.fetchRandomContent()
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
			// this.$emit('page-changed', page)
		},
		getPreviousPage() {
			this.getPage(this.currentPage - 1)
		},
		getNextPage() {
			this.getPage(this.currentPage + 1)
		},
		formatDate(date) {
			return new Date(date).toDateString()
		},
		getAuthor(content) {
			this.$apollo.query({
				query: GET_AUTHOR,
				client: "cms",
				context: {
					headers: {
						"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
					}
				},
				variables: {
					id: content.author.sys.id
				}
			}).then(({ data: { author } }) => {
				this.currentAuthor = author
				this.currentContent = content
				this.contentHtml = documentToHtmlString(content.content.json)
			})
		},
		loadRecentPosts() {
			this.$apollo.query({
				query: GET_RECENT_POST,
				fetchPolicy: "network-only",
				client: "cms",
				context: {
					headers: {
						"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
					}
				},
				variables: {
					limit: 1,
					order: "sys_firstPublishedAt_DESC"
				},
			}).then(({ data: { affilateContentCollection: { items } } }) => {
				this.recentPosts = items
			})

		},
		fetchRandomContent() {
			const skip = this.currentPage === 1 ? 0 : this.currentPage - 1;
			this.$apollo.query({
				query: SINGLE_AUTHOR_CONTENT,
				client: "cms",
				context: {
					headers: {
						"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
					}
				},
				loadingContent: true,
				variables: {
					limit: 1,
					skip
				},
			}).then(({ data: { affilateContentCollection: { items, total } } }) => {
				if (!total) {
					console.error("No content to show")
					return;
				}
				this.pages = total
				this.getAuthor(items[0])
			}).finally(() => {
				this.loadingContent = false
			})
		},
		getEmbedUrl(link) {
			var res = link.split("=");
			var embeddedUrl = "https://www.youtube.com/embed/" + res[1];
			return embeddedUrl
		},
		loadAffilateContent(auth) {
			this.loadingContent = true
			this.currentAuthor = auth
			this.$apollo.query({
				query: GET_AUTHOR_CONTENT,
				client: "cms",
				fetchPolicy: "network-only",
				variables: {
					where: {
						affilateShort: auth.authShort
					}
				},
				context: {
					headers: {
						"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
					}
				}
			}).then(({ data: { affilateContentCollection: { items } } }) => {
				if (items.length === 0) {
					console.error("Author has zero contents")
					return;
				}
				this.currentContent = items[0]
				this.contentHtml = documentToHtmlString(items[0].content.json)
			}).catch(e => {
				console.error(e)
			}).finally(() => {
				this.loadingContent = false
			})
		},
		toDetail(blog) {
			window.open("/detail/" + blog, true)
		},
	}
}
</script>
<style>
p {
	display: block;
	padding-top: 20px;
}
</style>