<template>
  <RegisterIndex/>
</template>
<script>
import RegisterIndex from '@/templates/Register/Index.vue'
export default {
  components:{
    RegisterIndex
  }
}
</script>