

<template>
  <div class="" style="">

    <!-- new theme -->
    <div class="bg-white dark:bg-gray-800  hidden lg:block -mt-10  md:-mx-2">
      <div
        class="px-2 py-4 -mt-28 mx-auto bg-white hidden mb-36 lg:block border-pink-50 h-full  shadow-2xl drop-shadow-2xl border-4 max-w-screen-xl  md:px-2 lg:px-2">
        <div class="bg-pink-100 dark:bg-gray-600 hidden lg:block">

          <!-- End of Navbar -->

          <div class="container mx-auto my-1 p-5" >
            <div class="md:flex no-wrap md:-mx-2 ">
              <!-- Left Side -->
              <div style="height: 885px"
                class=" items-center border-0    py-2 justify-center hidden h-full pl-1 pr-1 mt-1  dark:bg-gray-500 w-30 md:w-24 md:mb-0 bg-white bg lg:block md:hidden">
                <new-side-selector />

              </div>
              <div class="w-full md:w-3/12 md:mx-2">
                <!-- Profile Card -->
                <div class="bg-white dark:bg-gray-600 p-3">
                  <!--  -->
                  <div style="width: 100%; "
                    class="  justify-center py-4 hidden h-full grid-cols-1 pl-1 pr-1 mt-3 overflow-hidden bg-pink-50  items-top lg:block dark:bg-gray-600 w-30 md:w-49 md:mb-0">
                    <user-avatar />
                    <div class="overflow-hidden p-2 bg-pink-50 dark:bg-gray-700" style="height: 200px">


                      <Notify />

                    </div>

                    <div class="hidden overflow-hidden border-2 border-white bg-gray-50 dark:bg-gray-700 lg:block"
                      style="height: 300px">
                      <p class="pl-6 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
                        People who visited you
                      </p>
                      <side-home-selector class="" />
                    </div>
                    <div style="height: 135px" class="hidden  overflow-hidden bg-pink-50 dark:bg-gray-700 lg:block">
                      <p class="px-5 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
                        People who likes you
                      </p>
                      <side-match-selector />
                    </div>

                  </div>
                </div>
                <!-- End of profile card -->
                <div class="my-4"></div>
                <!-- Friends card -->

                <!-- End of friends card -->
              </div>
              <!-- Right Side -->
              <div class="w-full md:w-9/12 mx-2 h-64">
                <!-- Profile tab -->
                <!-- About Section -->
                <div class="bg-white dark:bg-gray-700 p-3 shadow-sm rounded-sm overflow-auto ">
                  <div style="height: 870px; width: 100%; "
                    class="flex items-top justify-center   w-full pl-1 pr-1  md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">

                    <div style="height: 870px;  width: 760px"
                      class="flex items-top justify-center overflow-auto py-4  w-full pl-1 pr-1 mt-3 md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">

                      <div v-if="matches.length > 0 && !loadingContent" class="
        grid grid-cols-2 gap-5 mb-12 sm:grid-cols-2 flex-auto md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4
        ">
                        <div v-for="(item, idx) in matches" :key="idx">
                          <user-card :user="item.matched_with" :page="'matches'" />
                        </div>
                      </div>
                      <div v-else-if="!loadingContent" class="pt-10 mx-2 font-sans text-left">
                        <p class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          ">
                      {{$t('nomatches')}}  
                        </p>
                        <p class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          ">
                       {{$t('likematches')}}
                        </p>
                        <button @click="$router.push('/find-matches')" class="
            mt-5
            btn btn-accent
            rounded-xl
            btn-lg
            mb-72
            btn-outline btn-wide btn-warning
          ">
                          <a style="color: #dc4b78">home </a>
                        </button>
                      </div>
                    </div>



                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>




      </div>
    </div>

    <!-- end of new theme -->





    <!-- new theme starts here -->


    <div class="bg-white dark:bg-gray-800 lg:hidden xl:hidden 2xl:hidden ">
      <div style="height: 870px"
        class="container flex flex-wrap  justify-center gap-0 ml-auto mr-auto  bg-white dark:bg-gray-800  items-top">


        <div
          class=" items-center border-0    py-4 justify-center hidden h-full pl-1 pr-1 mt-3  dark:bg-gray-500 w-30 md:w-24 md:mb-0 bg-pink-50 bg lg:block">
          <new-side-selector />

        </div>
        <div style="width: 250px; "
          class="  justify-center py-4 hidden h-full grid-cols-1 pl-1 pr-1 mt-3 overflow-hidden bg-pink-50 items-top lg:block dark:bg-gray-600 w-30 md:w-49 md:mb-0">
          <user-avatar />
          <div class="overflow-hidden p-2 bg-pink-50 dark:bg-gray-700" style="height: 200px">


            <Notify />

          </div>

          <div class="hidden overflow-hidden border-2 border-white bg-gray-50 dark:bg-gray-700 lg:block"
            style="height: 300px">
            <p class="pl-6 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
              People who visited you
            </p>
            <side-home-selector class="" />
          </div>
          <div style="height: 210px" class="hidden  overflow-hidden bg-pink-50 dark:bg-gray-700 lg:block">
            <p class="px-5 mb-2 font-sans font-medium text-left text-pink-500 font-sm">
              People who likes you
            </p>
            <side-match-selector />
          </div>

        </div>

        <div style="height: 870px;  width: 790px"
          class="flex items-top justify-center overflow-auto py-4  w-full pl-1 pr-1 mt-3 md:w-1/3 md:mb-0 bg-white dark:bg-gray-700">

          <div v-if="matches.length > 0 && !loadingContent" class="
        grid grid-cols-2 gap-5 mb-12 sm:grid-cols-2 flex-auto md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3
        ">
            <div v-for="(item, idx) in matches" :key="idx">
              <user-card :user="item.matched_with" :page="'matches'" />
            </div>
          </div>
          <div v-else-if="!loadingContent" class="pt-10 mx-2 font-sans text-left">
            <p class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          ">
             {{$t('nomatches')}} 
            </p>
            <p class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          ">
          {{$t('likematches')}}
            </p>
            <button @click="$router.push('/find-matches')" class="
            mt-5
            btn btn-accent
            rounded-xl
            btn-lg
            mb-72
            btn-outline btn-wide btn-warning
          ">
              <a style="color: #dc4b78"> Home </a>
            </button>
          </div>
        </div>




      </div>
    </div>





    <!-- end of new theme  -->



    <!-- <div class="col-span-2 bordered">
      <div class="box-border w-full border-0 md:mx-20">
       
        <div class="hidden lg:block">
          <user-avatar />
          <side-selector />
        </div>
        
      </div>
    </div> -->

    <div class="col-span-1"></div>
    <!-- <div class="col-span-9 compressedx">
      
      <div class="lds-ripple mt-44" v-if="loadingContent">
        <div></div>
        <div></div>
      </div>
     

      <div class="text-left mb-7" v-if="matches.length > 0 && !loadingContent">
        <a class="pb-10 mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="rgb(126 214 0)"
            viewBox="0 0 24 24"
            class="
              inline-block
              w-8
              h-8
              mr-2
              stroke-current
              text-pink
              dark:text-gray-200
            "
          >
            <path
              fill="currentColor"
              d="M22.59,7.92L23.75,9.33L19,14.08L16.25,11.08L17.41,9.92L19,11.5L22.59,7.92M6,5A3,3 0 0,1 9,8A3,3 0 0,1 6,11A3,3 0 0,1 3,8A3,3 0 0,1 6,5M11,5A3,3 0 0,1 14,8A3,3 0 0,1 11,11C10.68,11 10.37,10.95 10.08,10.85C10.65,10.04 11,9.06 11,8C11,6.94 10.65,5.95 10.08,5.14C10.37,5.05 10.68,5 11,5M6,13C8,13 12,14 12,16V18H0V16C0,14 4,13 6,13M12.62,13.16C14.63,13.5 17,14.46 17,16V18H14V16C14,14.82 13.45,13.88 12.62,13.16Z"
            ></path>
          </svg>

          <span
            class="
              px-5
              font-sans
              text-sm text-center text-pink-500
              uppercase
              font-semi-bold
              text-md
              dark:text-white
              card-title
            "
            :style="
              $store.state.core.darkTheme ? 'color: #e4e3e3' : 'color: #7e7979'
            "
          >
            Matches
          </span>
        </a>
      </div>
      <div
        v-if="matches.length > 0 && !loadingContent"
        class="
          grid grid-cols-2
          gap-5
          mb-12
          sm:grid-cols-2
          md:grid-cols-3
          lg:grid-cols-3
          xl:grid-cols-3
        "
      >
        <div v-for="(item, idx) in matches" :key="idx">
          <user-card :user="item.matched_with" :page="'matches'" />
        </div>
      </div>
      <div v-else-if="!loadingContent" class="pt-10 mx-2 font-sans text-left">
        <p
          class="
            text-2xl
            font-black
            xl:text-4xl
            lg:text-4xl
            sm:text-3xl
            dark:text-white
          "
        >
          You don't have any
          <span class="text-pink-500"> matches </span> yet
        </p>
        <p
          class="
            pt-3
            text-xs
            lg:text-lg
            xl:text-lg
            md:text-lg
            sm:text-md
            dark:text-white
          "
        >
          You can start matching with profile by liking them
        </p>
        <button
          @click="$router.push('/find-matches')"
          class="
            mt-5
            btn btn-accent
            rounded-xl
            btn-lg
            mb-72
            btn-outline btn-wide btn-warning
          "
        >
          <a style="color: #dc4b78"> Find Matches </a>
        </button>
      </div>

      <button
        v-if="matches.length > 0"
        class="
          mb-20
          btn btn-accent
          rounded-xl
          btn-lg btn-outline btn-wide btn-warning
        "
      >
        <a style="color: #dc4b78"> Load More </a>
      </button>
    </div> -->
    <div class="w-full">
      <!-- <section id="bottom-navigation" class="fixed inset-x-0 bottom-0 z-10 block bg-white shadow md:hidden"> // if shown only tablet/mobile-->
    </div>
  </div>
</template>
<style scoped></style>
<script>
import UserAvatar from "../../components/userAvatar.vue";
import SideSelector from "../../components/sideSelector.vue";
import { MATCHES } from "../../queries/matches";
import { getURL } from "../../utils/helpers";
import UserCard from "../../components/userCard.vue";
import NewSideSelector from "@/components/newsideselector ";
import Notify from "../../components/Notify.vue";
import SideHomeSelector from "../../components/sideHomeSelector.vue";
import SideMatchSelector from '@/components/sideMatchSelector';
const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    UserAvatar,
    SideSelector,
    UserCard, NewSideSelector, Notify, SideHomeSelector, SideMatchSelector,
  },
  apollo: {
    matches: {
      query: MATCHES,
    },
  },
  data: () => ({
    features,
    matches: [],
    loadingContent: true,
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],

    mobile: false,
  }),
  methods: {
    getURL,
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener(
        "onorientationchange",
        this.detectOrientationChange
      );
    });
  },
  created() {
    this.$apollo.queries.matches.refetch().finally(() => {
      this.loadingContent = false;
    });
    this.detectOrientationChange(); // when instance is created
  },
};
</script>
<style >
@import "../../assets/css/style.css";
</style>
