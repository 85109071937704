<template>
  <div>
    <notification-bar />
    <div>
      <transition name="payoutRequest">
        <div v-if="payoutRequest" class="dark:bg-white">
          <div class="overlay">
            <div
              class="payoutRequest my-10 dark:bg-gray-800 bg-white mx-10"
              v-click-outside="
                () => {
                  payoutRequest = false;
                }
              "
            >
              <h2 class="text-lg font-black">
                Enter the Amount you would like to request
              </h2>
              <input
                type="number"
                class="
                  px-3
                  py-2
                  mt-5
                  border-2 border-pink-300
                  rounded-lg
                  form__input
                  dark:bg-gray-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-pink-400
                  focus:border-transparent
                  dark:text-gray-200
                "
                v-model="amount"
                placeholder="Enter the requested amount"
              /><br />
              <button
                @click="sendPayout"
                class="mt-3 btn"
                :class="{ loading: loading }"
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="flex flex-col lg:mx-72">
      <div class="text-left mb-10 mt-3 mx-2 dark:text-gray-200">
        <h2 class="text-3xl font-bold font-sans up">My Referrals</h2>
        <p class="text-lg  font-sans">
          Here you can view your referral Activities, Registrations and Purchases.
        </p>
        <div>
          <input
            :value="affilateLink"
            class="
              px-3
              py-2
              text-left
              justify-items-start
              col-start-1
              mt-5
              border-2 border-pink-300
              rounded-lg
              form__input
              dark:bg-gray-700
              focus:outline-none
              focus:ring-2
              focus:ring-pink-400
              focus:border-transparent
              dark:text-gray-200
            "
          />
          <button class="btn border-0 bg-pink-500 text-gray-50 font-sans  hover:bg-pink-300 btn-md mx-2" @click="copyRefLink">copy</button>
        </div>
      </div>
      <div class="-my-2 mx-2 overflow-x-auto sm:-mx-6 lg:-mx-9">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="flex flex-row mx-2 gap-2 mb-5">
            <router-link
              v-if="$store.state.core.afSstat"
              to="/aff-activity"
              class="
                btn
                border-pink-500 border-0
                bg-pink-500
                text-gray-50
                font-sans
                font-bold
                hover:border-pink-800
              "
            >
              Affilates Activity
            </router-link>
            <button
              v-if="!$store.state.core.isIkm"
              class="btn bg-pink-500 text-white font-sans hover:bg-pink-700 font-sans"
              @click="requestPayout"
            >
              Request Payout
            </button>
          </div>
          <div
            v-if="affilateActivity.acts.length > 0"
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <div class="flex flex-row mb-12 my-2 mt-3">
              <div class="bordered w-1/4 h-20 shadow-lg bg-gray-100 mx-2">
                <h1 class="text-3xl font-black font-sans">
                  {{ affilateActivity.actToday }}
                </h1>
                <p class="pt-1 font-sans ">Activity Today</p>
              </div>
              <div class="bordered w-1/4 h-20 shadow-lg bg-gray-100 mx-2">
                <h1 class="text-3xl font-black font-sans">
                  {{ affilateActivity.actMonth }}
                </h1>
                <p class="pt-1 font-sans">This month</p>
              </div>
              <div class="bordered w-1/4 h-20 shadow-lg bg-gray-100 mx-2">
                <h1 class="text-3xl font-black font-sans">
                  {{ affilateActivity.actTotal }}
                </h1>
                <p class="pt-1 font-sans">Total</p>
              </div>
            </div>
            <table class="min-w-full divide-y divide-gray-200 border-4">
              <thead class="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    v-for="(hd, idx) in headers"
                    :key="idx"
                    scope="col"
                    class="
                      dark:text-gray-200
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                    v-text="hd.text"
                  ></th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                <tr v-for="(refr, idx) in affilateActivity.acts" :key="idx">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-sans text-gray-900 dark:text-gray-200 uppercase">
                      {{ refr.user.base_profile.firstName }}
                      {{ refr.user.base_profile.lastName }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div
                          class="
                            text-sm
                            font-sans
                            text-gray-900
                            dark:text-gray-200
                            uppercase
                            text-left
                          "
                        >
                          {{ parseDate(refr.createdAt) }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900 uppercase font-sans dark:text-gray-200">
                      {{ refr.activityType }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900 dark:text-gray-200">
                      {{ refr.creditAmount }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 v-else class="px-3  text-left font-sans text-lg dark:text-gray-200">
            You don't have any activities yet
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "../../components/NotificationBar.vue";
import { AFF_ACT, REQUEST_POUT } from "../../queries/matches";
export default {
  components: {
    NotificationBar,
  },
  apollo: {
    affilateActivity: {
      query: AFF_ACT,
      result({ data }) {
        const afLink =
          "http://funnyflirts.net/register?af=" +
          data.affilateActivity.affilate.affilateId;
        this.affilateLink = afLink;
      },
    },
  },
  created() {
    this.$apollo.queries.affilateActivity.refetch();
  },
  data: () => ({
    afId: "",
    affilateLink: "asdf",
    amount: null,
    loading: false,
    headers: [
      { text: "User" },
      { text: "Date" },
      { text: "Activity Type" },
      { text: "Earning" },
    ],
    affilateActivity: [],
    payoutRequest: false,
  }),
  methods: {
    async copyRefLink() {
      await navigator.clipboard.writeText(this.affilateLink);
      this.$notify(
        {
          group: "top",
          title: "Link Copied",
          text: "Link Copied Successfully",
          type: "info", // info, warning
        },
        5000
      );
    },
    sendPayout() {
      this.loading = true;
      if (!this.amount) {
        this.$notify(
          {
            group: "top",
            title: "Amount is not valid",
            text: "Notification Message",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (this.amount > 0) {
        this.$apollo
          .mutate({
            mutation: REQUEST_POUT,
            variables: {
              amount: parseFloat(this.amount) ?? 0,
              // afId: this.afId
            },
          })
          .then(({ data }) => {
            if (data) {
              this.payoutRequest = false;
              this.$notify(
                {
                  group: "top",
                  title: "Payout request is sent successfully",
                  text: "Notification Message",
                  type: "info", // info, warning
                },
                5000
              );
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        alert("payout must be greater than 0");
      }
    },
    requestPayout() {
      this.payoutRequest = true;
    },
    parseDate(date) {
      return new Date(parseInt(date)).toDateString();
    },
  },
};
</script>
<style scoped>
.payoutRequest {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
}

.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .payoutRequest,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}
</style>