<template>


<div>

<nav><navBar/></nav>
    <div
      class="px-2 py-4 mx-auto bg-white hidden lg:block border-purple-200 h-full  shadow-2xl drop-shadow-2xl border-4 max-w-screen-xl  md:px-2 lg:px-2">
      <div class="grid grid-cols-12    ">

        <div class="col-span-1 flex flex-wrap justify-start">

          <new-side-selector />
        </div>
        <div style=" height: 810px;" class=" col-span-2 flex justify-center mx-auto   overflow-hidden  bg-white p-0">

          <div class="px-1 lds-ripple " v-if="loadingContent">
            <div></div>
            <div></div>
          </div>
          <div class="grid grid-cols-2 h-full   text-left  md:grid-cols-2 xl:grid-cols-2" v-if="!loadingContent">
            <vip-user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip" :key="idx"
              :user="user" :page="'find_matches'" />
          </div>
        </div>

        <div class="col-span-9 mx-auto justify-center  grid grid-cols-12 gap-1">
          <div class="col-span-8 px-5">
            <hot-or-not />
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="col-span-4 hidden lg:block">
            <!-- Your content for the right half of the main area goes here -->
            <div> <!-- Friends card -->
              <div
                class="grid grid-cols-1 bg-white items-center text-center space-x-3 font-semibold text-gray-900 text-xl leading-8" >
                <div class="flex justify-center mb-7">
                  <user-avatar />
                </div>
                <div class="overflow-hidden" style=" height: 570px;" >
                  <Notify />

                  <!-- <div class="search-container flex justify-center mx-auto mt-36">
        <div class="search-icon">🔍</div>
        <div class="search-text font-sans uppercase">For Matches</div>
    </div> -->

                </div>

              </div>

              <!-- End of friends card -->
            </div>
          </div>
          
          <!-- <div>
            <div class="grid col-span-4">
              <div class=" sm:hidden md:hidden lg:block flex justify-center items-center mx-auto">
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="col-span-12  px-44 px- hidden lg:block h-full relative -mt-24  ">
          <div class="mt-10"><span
              class="bg-purple-700 py-2 px-2 text-xl font-sans font-bold text-white rounded-full">MAZU USERS</span></div>
          <div class="dark:bg-gray-800  " v-if="!loadingContent">
            <select @change="filterChanged" v-model="nFilter"
              class="w-36 py-2 px-2 mt-5 mb-1 justify-start flex border-2 text-sm  bg-white dark:bg-gray-700 dark:text-gray-200 rounded-2xl shadow-xl">
              <option value="" disabled selected class="text-sm">Filter Users</option>
              <option disabled="disabled" selected="selected">I am looking for</option>
              <option>Women</option>
              <option>Men</option>
              <option>Gay</option>
              <option>Lesbian</option>
            </select>
          </div>


          <div class="grid grid-cols-5 gap-1 py-0 overflow-auto h-full">
            <Purpleusercards :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches" :key="idx"
              :user="user" :page="'find_matches'" /> <div class="flex justify-center mx-auto"><button v-if="!loadingContent"
              class="mb-10 mt-0 border-yellow-500 rounded-full  items-center shadow-xl  boder-0 border-" @click="loadMore">
              <div v-if="loadingUsers" class="mt-5 lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <a v-else class="font-sans text-yellow-500 text-xs uppercase font-bold"> Load More </a>
            </button></div>
          </div>
        </div> -->
      </div>
      
    </div>

</div>

</template>
<script>
import NewSideSelector from "@/components/newsideselector ";
import navBar from "../../components/navBar.vue";

export default {

components: {

    NewSideSelector ,  navBar
},




}











</script>

