<template>
	<div class="  ">
		<!-- <nav-bar
			v-if="
				!(($route.name == 'Chat' || $route.name == 'DirectChat') && $mq == 'sm')
			"
		/> -->
		<div v-if="!(($route.name == 'Chat' || $route.name == 'DirectChat') && $mq == 'sm')
			"></div>

		<div class="bg-cover bg-center h-screen blur-3xl -mt-28 "
		:style="{ backgroundImage: 'url(' + require('@/assets/profilesetup/Bg1.png') + ')', height: '100vh' }">

			<main class="md:mx-0 mt-28 bg-gray-900  h-full  w-full bg-opacity-90" :class="{
				'overflow-hidden': $route.name == 'Chat',
			}"> 

			<div class="">
				<div class=" 
						hidden
						sm:mx-72
						md:mx-72
						xl:mx-72
						lg:mx-72
						dark:bg-gray-700 
						
				
						sm:w-2/3
						mx-3 
					" style="margin: auto">
					<div class=" px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
						<!-- new -->

						<!-- component -->
						<div class="">
							<h1 class="mb-4 text-center  font-sans uppercase text-gray-50">{{ $t('SETUPYOURDATINGPROFILE')
							}}
							</h1>
							<div class="flex py-10 lg:px-26">
								<div class="w-1/3 text-center ">
									<div class="bg-gray-50 border-b-8  rounded-lg shadow-2xl flex items-center justify-center border border-gray-200"
										:class="{ 'step-primary': currentStep == 0 || 1 || 2 || 3 }">
										<div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
												viewBox="0 0 24 24">
												<path
													d="M23.995 24h-1.995c0-3.104.119-3.55-1.761-3.986-2.877-.664-5.594-1.291-6.584-3.458-.361-.791-.601-2.095.31-3.814 2.042-3.857 2.554-7.165 1.403-9.076-1.341-2.229-5.413-2.241-6.766.034-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 4.983 0 8.451 4.766 3.732 13.678-1.551 2.928 1.65 3.624 5.09 4.418 2.979.688 3.178 2.143 3.178 4.663l-.005 1.241zm-13.478-6l.91 2h1.164l.92-2h-2.994zm2.995 6l-.704-3h-1.615l-.704 3h3.023z" />
											</svg>
										</div>
										<div
											class="w-2/3 bg-gray-200 h-24 glass flex flex-col items-center justify-center px-1 rounded-r-lg body-step  ">
											<h2 class="font-sans uppercase text-xs"> Profile Photo</h2>

										</div>
									</div>
								</div>
								<div class="flex-1 flex items-center justify-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="white">
										<path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
									</svg>
								</div>
								<div class="w-1/3 text-center lg:px-6" :class="{
									'step-primary': (currentStep == 1 || 2) && currentStep != 0,
								}">
									<div
										class="bg-gray-50 border-b-8  rounded-lg shadow-2xl flex items-center justify-center border border-gray-200">
										<div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
											<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
												fill-rule="evenodd" clip-rule="evenodd">
												<path
													d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-4 13v1h-4v-1h4zm-6.002 1h-10.997l-.001-.914c-.004-1.05-.007-2.136 1.711-2.533.789-.182 1.753-.404 1.892-.709.048-.108-.04-.301-.098-.407-1.103-2.036-1.305-3.838-.567-5.078.514-.863 1.448-1.359 2.562-1.359 1.105 0 2.033.488 2.545 1.339.737 1.224.542 3.033-.548 5.095-.057.106-.144.301-.095.41.14.305 1.118.531 1.83.696 1.779.41 1.773 1.503 1.767 2.56l-.001.9zm-9.998-1h8.999c.003-1.014-.055-1.27-.936-1.473-1.171-.27-2.226-.514-2.57-1.267-.174-.381-.134-.816.119-1.294.921-1.739 1.125-3.199.576-4.111-.332-.551-.931-.855-1.688-.855-.764 0-1.369.31-1.703.871-.542.91-.328 2.401.587 4.09.259.476.303.912.13 1.295-.342.757-1.387.997-2.493 1.252-.966.222-1.022.478-1.021 1.492zm18-3v1h-6v-1h6zm0-3v1h-6v-1h6zm0-3v1h-6v-1h6z" />
											</svg>
										</div>
										<div
											class="w-2/3 bg-gray-200 h-24 flex-col flex  mx-a  items-center justify-center px-1 rounded-r-lg body-step  ">
											<h2 class="font-sans uppercase text-xs">Setup</h2>

										</div>
									</div>
								</div>
								<div class="flex-1 flex items-center justify-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
										fill="white">
										<path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
									</svg>
								</div>
								<div class="w-1/3 text-center lg:px-6" :class="{ 'step-primary': currentStep == 2 }">
									<div
										class="bg-gray-50 border-b-8  rounded-lg shadow-2xl flex items-center justify-center border border-gray-200">
										<div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
											<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"
												fill-rule="evenodd" clip-rule="evenodd">
												<path
													d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
											</svg>
										</div>
										<div
											class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step  ">
											<h2 class="font-sans uppercase text-xs">Verify</h2>

										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- new -->
						<ul class="w-full steps flex flex-wrap gap-16 justify-center mx-auto text-lg font-sans mb-7 mt-3">
							<!-- <li :class="{ 'step-primary': currentStep == 0 || 1 || 2 || 3 }"
							class="step dark:text-gray-200 font-sans font-bold text-sm uppercase">
							<img src="@/assets/profilesetup/Cam.png" class="h-10 font" alt="">
							<p class=" text-2xs">Profile Picture</p>
						</li>
						<li :class="{
							'step-primary': (currentStep == 1 || 2) && currentStep != 0,
						}" class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"> <img
								src="@/assets/profilesetup/details.png" class="h-10 font" alt="">
							<p class="text-2xs">Basic Information</p>
						</li> -->

							<!-- {{
							$store.state.core.user.authSource
						}} -->
							<!-- <li v-if="$store.state.core.user.authSource === 'default'"
							class="step dark:text-gray-200 font-sans font-bold text-sm uppercase"
							:class="{ 'step-primary': currentStep == 2 }"> <img src="@/assets/profilesetup/verify.png"
								class="h-10 font" alt="">
							<p class="text-2xs"> Verify</p>
						</li> -->
						</ul>
					</div>

				</div>
				<div style="margin: auto" class="
						mx-72 
						grid
						lg:w-2/5
						xl:w-2/5
						md:w-2/3
						sm:w-2/3
						bg-pink-00
						dark:bg-gray-900
						rounded-lg
						
					">
					<profile-upload @movestep="currentStep = 1" v-if="currentStep == 0" />
					<basic-info @nextstep="
						$store.state.core.user.authSource === 'default'
							? (currentStep = 2)
							: completeRegistration()
						" v-else-if="currentStep == 1" />
					<profile-verification v-else-if="currentStep == 2 && $store.state.core.user.authSource === 'default'
						" />
					<!-- <div class="mt-12" v-else>
						<center>
							<div class="
									card
									dark:border-gray-700 dark:bg-gray-700
									border-pink-100 border
								">
								<div class="
										pt-5
										bg-accent
										mx-3
										text-center text-accent-content
										mb-20
										dark:text-gray-200
									">
									<p class="text-4xl font-sans py-3 px-40">Congrats</p>
									<p>your registratrion is complete</p>
								</div>
							</div>
						</center>
					</div> -->
					<div class="mt-12" v-else>
    <center>
        <div class="card dark:border-gray-700 dark:bg-gray-700 border-pink-100 border shadow-lg rounded-md">
            <div class="pt-8 bg-accent text-center text-accent-content mb-8 dark:text-gray-200">
                <p class="text-4xl font-sans  py-4">Congratulations!</p>
                <p class="text-lg">Your registration is complete</p>
            </div>
        </div>
    </center>
</div>


				</div>
				<div class="pb-16"> </div>
			</div>
			</main>
			<div class="spacer mb-10"></div>


			<footer v-if="!($route.name == 'Chat' || $route.name == 'DirectChat')" class=" z-10  w-full  py-5">

				<!-- <div class="flex flex-wrap justify-center">
	<p class="font-sans font-bold uppercase -mt-2">Submit your Information to get verified</p>
	<div class=" grid lg:grid-cols-2 gap-2 grid-cols-2 mt-10">
	</div>

	<div class=" "><img class=" lg:float-right lg:h-12 h-12"
			:src="require('../assets/icons/PorntoolLogo.png')"
			alt="Connect with flirttool & start your personal online dating adventure! " /></div>
	<div><img class="float-left lg:h-12 h-12 pl-5" :src="require('../assets/logo-light.png')"
			alt="Connect with flirttool & start your personal online dating adventure! " /></div>
</div> -->
			</footer>




			<!-- <page-footer
			v-if="!($route.name == 'Chat' || $route.name == 'DirectChat')"
		/> -->

			<!-- <div class="mb-5"></div> -->
			<!-- start of basic form  -->
		</div>
	</div>
</template>
<script>
import navBar from '../components/navBar.vue'
import PageFooter from '../components/pageFooter.vue'
import BasicInfo from '@/components/profile/basicInfo.vue'
import ProfileUpload from '../components/profile/profileUpload.vue'
import ProfileVerification from '../components/profile/profileVerification.vue'
import { LOGGEDIN_DATA } from '../queries/auth'
import store from '../store'
export default {
	components: {
		navBar,
		PageFooter,
		BasicInfo, ProfileUpload, ProfileVerification
	},
	data: () => ({
		currentStep: 0,
		me: {},
	}),
	created() {
		this.$apollo.queries.me.refetch()
	},
	apollo: {
		me: {
			query: LOGGEDIN_DATA,
			variables() {
				return {
					addr: this.$store.state.core.addr
				}
			},
			result({ data: { me: { user, credits, notificationCount } } }) {
				store.commit("setUserData", user)
				store.commit("setBalance", credits.balance + credits.flirtons)
				store.commit("changeNotificationCount", notificationCount)
				store.commit("setTempLocationDetail", user.tempLocationDetail)
				// this.me = user
				return user
			}
		}
	},
	methods: {
		completeRegistration() {
			this.$apollo.queries.me.refetch().then(() => {
				let old = store.state.core.user;
				old.profileComplete = true
				store.commit("setUserData", old)
				setTimeout(() => {
					window.location.replace("/find-matches")
				}, 100);
			})
			// alert("reg should be complete now")
		}
	},
	mounted() {
		this.currentStep = this.$store.state.core.user.profileSetupStep
	},
}
</script>
<style>
.steps .step-primary+.step-primary:before,
.steps .step-primary:after {
	--tw-bg-opacity: 1;
	background-color: #f16592;
	/* background-color: hsla(var(--p) / var(--tw-bg-opacity, 1)); */
	--tw-text-opacity: 1;
	color: hsla(var(--pc) / var(--tw-text-opacity, 1));
}
</style>