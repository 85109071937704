<template>
	<div class="text-left text-gray-800" v-on-clickaway="closeMenu">
		<div
			class="
				right-0
				shadow-none
				text-sm
				overflow-hidden
				border-none
				z-20
			"
		>
			<div
				class="bg-yellow-5100 dark:bg-gray-700 shadow-xs"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<center v-if="loadingNotification">
					<div class="mt-14 lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</center>
				<div v-else>
					<ul
						class="overflow-y-scroll"
                        style="height: 290px"
						v-if="notifications.length > 0"
					>
						<li
							v-for="(notice, idx) in notifications"
							:key="idx"
							@click="toLink(notice)"
							class="
								flex
								justify-between
								items-center
								bg-yellow-100
								dark:bg-gray-500
								mt-2
								p-2
                             shadow-sm
								hover:shadow-lg
								rounded
								cursor-pointer
								transition
							"
						>
							<div class="flex ml-2">
								<div class="flex-none">
									<div class="avatar h-8 w-8">
										<img
											:src="getURL(notice.ref_user.profilePic)"
											width="40"
											height="40"
											class="rounded-full"
										/>
									</div>
								</div>
								<!-- {{ notice.message }} -->
								<div class="flex flex-col ml-2 mt-0">
    <span
        class="font-sans  text-black dark:text-white"
        :class="{ 'font-sans': !notice.read }"
    >
        {{ notice.message.slice(0, 50) }}
        {{ notice.message.length > 50 ? '...' : '' }}
    </span>
</div>

							</div>
							<div class="flex flex-col items-center">
								<span class="text-gray-300"></span>
								<i class="fa fa-star text-green-400"></i>
							</div>
						</li>
					</ul>
					<ul v-else class="overflow-y-scroll max-h-96">
						<li>
							<div
								class="font-sans text-center font-sans text-lg my-10 dark:text-gray-200"
							>
								You don't have any New notifications
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

	
	</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { getURL } from "@/utils/helpers";
import { logout } from "@/utils/basicAuth";
import { NOTIFICATIONS } from '@/queries/matches';
export default {
	mixins: [clickaway],
	data: () => ({
		notifications: [],
		label: "First label",
		loadingNotification: false,
	}),
	apollo: {
		notifications: {
			query: NOTIFICATIONS,
			result({ data: { notifications } }) {
				this.notifications = notifications;
				this.loadingNotification = false
				setTimeout(() => {
					// Empty notificaiton count
					this.$store.commit("changeNotificationCount", 0)
				}, 3000);
			}
		}
	},
	methods: {
		logout,
		getURL,
		toLink(notice) {
			if (notice.sparoute) {
				const finalLink = notice.link == "/chat" ? `/chat?dm=${notice.ref_user.id}` : notice.link
				this.$router.push(finalLink)
			} else {
				window.location.assign(notice.link)
			}
			// this.$router.push(link)
		},
		closeMenu() {
			this.isMenuOpen = false;
		},
	},
	computed: {
		notificationObject() {
			return this.$store.getters.notificationObject
		}
	},
	watch: {
		notificationObject(value) {
			const freshNots = [value].concat(this.notifications)
			this.notifications = freshNots;
		}
	}
};
</script>