<template>
  <div class="mb-24">
    <div class="flex justify-center px-5 mt-5">
      <div class="flex">
        <h1
          class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
        >
          {{$t('GeneralSettings')}}
        </h1>
      </div>
    </div>
    <div
      class="mx-5 mt-8 alert"
      :class="{ 'bg-gray-100': message.error, 'bg-green-100': !message.error }"
      v-if="messageShow"
    >
      <div class="flex-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="w-6 h-6 mx-2 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          ></path>
        </svg>
        <label>
          {{ message.body }}
        </label>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-1 mt-5 md:grid-cols-2 md:gap-8">
      <div class="grid grid-cols-1 mt-5 mr-7 md:mr-0 ml-7">
        <label
          class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
          >{{$t('FIRSTNAME')}}</label
        >
        <input
          v-model="firstName"
          class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-none shadow-none dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          error="text"
          placeholder="First Name"
        />
      </div>
      <div class="grid grid-cols-1 mt-5 ml-7 md:ml-0 mr-7">
        <label
          class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
          >{{$t('LastName')}}</label
        >
        <input
          v-model="lastName"
          class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-none shadow-none dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          type="text"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 md:gap-8 mx-7">
      <div class="grid grid-cols-1">
        <label
          class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
          >E-mail</label
        >
        <input
          v-model="email"
          class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-none shadow-none dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          type="email"
          placeholder="Email"
        />
      </div>
      <div class="grid grid-cols-1">
        <label
          class="mb-5 text-xs font-semibold text-left text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
          >{{$t('PhoneNumber')}}</label
        >
        <input
          v-model="phone"
          class="px-3 py-2 mt-1 border-2 border-gray-300 rounded-none shadow-none dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          type="text"
          placeholder="Phone Number"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-5 mx-7"></div>
    <div class="flex gap-4 pt-5 pb-5 mx-7 md:gap-6">
      <button
        class="w-auto px-4 py-2 font-medium text-white bg-green-500 glass rounded-lg shadow-none hover:bg-gray-700"
        @click="updateBaseProfile()"
      >
        <div v-if="loadingUpdate" class="mt-3 lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p v-else class="text-sm -mt-4">{{$t('SaveChanges')}}</p>
      </button>
      <button
        class="w-auto px-4 py-2 font-medium text-white bg-red-700 glass  rounded-lg shadow-none hover:bg-red-800"
        @click="removeAccount()"
      >
        <p class="text-sm -mt-4">{{$t('RemoveAccount')}}</p>
      </button>
    </div>
    <div class="mb-70"></div>
  </div>
</template>

<script>
import {
  DEFAULT_SETTINGS,
  REMOVE_ACCOUNT,
  UPDATE_BASE_PROFILE,
} from "../../../queries/auth";
export default {
  created() {
    this.$apollo.queries.userSettingsData.refetch();
  },
  data: () => ({
    defaultSettings: {},
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    loadingUpdate: false,
    messageShow: false,
    message: {
      error: false,
      body: "",
    },
  }),
  apollo: {
    userSettingsData: {
      query: DEFAULT_SETTINGS,
      result({
        data: {
          userSettingsData: { user },
        },
      }) {
        this.firstName = user.base_profile.firstName;
        this.lastName = user.base_profile.lastName;
        this.email = user.email;
        this.phone = user.phone;
      },
      error(e) {
        console.error(e);
      },
    },
  },
  methods: {
    removeAccount() {
      const cfrm = confirm("Are you sure you want to remove your account ?");
      if (cfrm) {
        this.$apollo
          .mutate({
            mutation: REMOVE_ACCOUNT,
          })
          .then(() => {
            window.location.assign("/login");
          });
      }
    },
    updateBaseProfile() {
      this.loadingUpdate = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_BASE_PROFILE,
          variables: {
            input: {
              email: this.email === "" ? null : this.email,
              firstName: this.firstName === "" ? null : this.firstName,
              lastName: this.lastName === "" ? null : this.lastName,
              phone: this.phone === "" ? null : this.phone,
            },
          },
        })
        .then(({ data }) => {
          this.message.error = false;
          this.messageShow = true;
          this.message.body = "Changes saved successfully";
          console.log(data, "User basic profile is updated");
        })
        .catch((e) => {
          this.message.error = true;
          this.messageShow = true;
          this.message.body = "Error saving data";
          console.error(e);
        })
        .finally(() => {
          this.loadingUpdate = false;
        });
    },
  },
};
</script>
