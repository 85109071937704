import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMq from 'vue-mq'
import Scrollspy, { Easing } from 'vue2-scrollspy';
import store from './store'
import Vuelidate from 'vuelidate'
import { createProvider } from './v-apollo'
import vClickOutside from 'v-click-outside'
import Notifications from "vt-notifications";
import VueApollo from "vue-apollo";
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import { FIREBASE_CONFIG } from './firebase.config.js'
import * as firebase from '@firebase/app';
import '@firebase/messaging';
import Ads from 'vue-google-adsense'
import './index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import {updateFlirtonsAvailable} from './helper'
// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'
import VueI18n from 'vue-i18n'
import translations from './translations'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import VueConfetti from "vue-confetti"


const app = firebase.initializeApp(FIREBASE_CONFIG)
navigator.serviceWorker.register('./static/firebase-messaging-sw.js', {
    scope: "firebase-cloud-messaging-push-scope"
}).then((registration) => {
    const messaging = firebase.messaging()
    console.warn(messaging)
    messaging.useServiceWorker(registration)
}).catch(err => {
    console.error(err);
})
Vue.use(VueConfetti)
Vue.use(Notifications);
Vue.use(vClickOutside)
Vue.use(VModal)
Vue.use(Vuelidate)
Vue.use(Scrollspy, {
    easing: Easing.Sinusoidal.In
});
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
Vue.use(VueMeta)
Vue.use(VueApollo);
// Vue.use(i18n)
Vue.use(VueI18n)
const i18n = new VueI18n(translations);
Vue.use(VueMq, {
    breakpoints: {
        sm: 650,
        md: 1250,
        lg: Infinity,
    }
})
Vue.use(VueAwesomeSwiper)
Vue.prototype.$fapp = app
Vue.config.productionTip = false
Vue.mixin({
  methods: {
    updateFlirtonsAvailable:updateFlirtonsAvailable
  },
})

new Vue({
    // apolloProvider: createProvider(),
    router,
    store,
    render: h => h(App),
    apolloProvider: createProvider(),
    i18n
}).$mount('#app')