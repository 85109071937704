<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div>
    <notification-bar />
    <center>
      <div class="grid grid-cols-1 lg:grid-cols-12 h-screen overflow-y-hidden">
        <!-- <div class="col-span-1"></div> -->
        <!-- <div class="col-span-9 lg:fixed chatBox"> -->
        <!-- main content start -->
        <div
          class="
            lg:mt-5
            flex-1
            sm:p-4
            lg:h-4/5
            justify-between
            flex flex-col
            col-span-9
            lg:fixed
            chatBox
            lg:shadow-2xl
            card
            rounded-none
          "
        >
          <div
            class="sm:flex-row items-center justify-between dark:bg-gray-800"
            v-if="$store.state.core.balance >= 10"
          >
            <div
              class="
                flex
                items-center
                space-x-4
                border-b-2 border-pink-200
                dark:border-gray-700
              "
            >
              <div
                class="avatar h-16 w-16 ml-5"
                @click="isMenuOpen = !isMenuOpen"
              >
                <img
                  class="rounded-full border-2 border-pink-300 mr-3"
                  :src="getURL(userProfile.profilePic)"
                />
              </div>

              <div class="flex flex-col leading-tight font-sans">
                <div class="text-2xl mt-1 flex items-center">
                  <div
                    class="avatar ring-black py-1 dark:text-gray-200"
                    v-if="userProfile.base_profile"
                  >
                    <span class="mr-3"
                      >{{ userProfile.base_profile.firstName }}
                      {{ userProfile.base_profile.lastName }}
                      <p
                        style="font-size: 18px"
                        class="text-left text-gray-500 dark:text-gray-300"
                      >
                        online
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <center v-else-if="$store.state.core.balance < 10">
            <center class="my-20">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-28 w-28"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#80808057"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
            </center>
            <div class="font-sans">
              <p
                v-if="$store.state.core.balance >= 10"
                class="font-semibold text-2xl text-gray-600"
              >
                You don't have any open
                <span class="text-pink-500"> conversation </span>
              </p>

              <p v-else class="font-semibold text-2xl text-gray-600">
                You don't have any
                <span class="text-pink-500"> flirts </span>
              </p>
              <p class="lg:px-40 pt-2">
                Select a conversation from the side to start interacting with
                users, have fun and please be nice ;)
              </p>
            </div>
            <button
              class="btn bg-pink-50 my-5"
              @click="$router.push('/find-matches')"
            >
              Find Matches
            </button>
            <button
              class="mx-2 btn bg-pink-50 my-5"
              @click="$router.push('/flirts')"
            >
              Get Flirts
            </button>
          </center>
          <div
            ref="messages"
            class="flex-auto overflow-y-auto p-5 space-y-4 dark:bg-gray-700"
            style="
              height: 79.333333%;
              background-image: url(https://static.intercomassets.com/ember/assets/images/messenger-backgrounds/background-1-99a36524645be823aabcd0e673cb47f8.png);
            "
          >
            <d-chat-message
              v-for="({ body, sender, attachment_url }, i) of renderedMessages"
              :key="i"
              :avatar="getURL(sender.profilePic)"
              :messages="[{ body, attachment_url }]"
              :sent="sender.username == $store.state.core.user.username"
            />
          </div>
          <message-field
            :loading="loadingSend"
            v-if="$store.state.core.balance >= 10"
            ref="messageField"
            @messageSent="messageSent"
            :convoSelected="convoSelected"
            @photoMessageSent="photoMessageSent"
          />
        </div>
      </div>
    </center>

    <fab
      v-if="$mq == 'lg'"
      :position="position"
      :bg-color="bgColor"
      :actions="fabActions"
      :main-icon="'favorite'"
      @toMatches="$router.push('/find-matches')"
    ></fab>
  </div>
</template>
<script>
import DChatMessage from "@/components/ChatMessage";
import MessageField from "../../components/chat/messageField.vue";
import { getURL } from "../../utils/helpers";
// import MessageField from '../../components/chat/messageField.vue';
import fab from "vue-fab";
import { USER_PROFILE } from "../../queries/auth";
import {
  // GET_DM_MESSAGES,
  MESSAGE_LISTEN,
  SEND_MESSAGE,
} from "../../queries/chat";
import NotificationBar from "../../components/NotificationBar.vue";
export default {
  components: {
    DChatMessage,
    MessageField,
    fab,
    NotificationBar,
  },
  apollo: {
    $subscribe: {
      messageAdded: {
        query: MESSAGE_LISTEN,
        result({ data }) {
          // console.clear()
          console.log(data, "NEW MESSAGE", this.$store.state);
          const message = data.messageAdded.mes;
          console.log(message, "MESSAGE <<<<<=");
          if (
            message.sender.username == this.$route.params.chat ||
            message.sender.username == this.$store.state.core.user.username
          ) {
            this.renderedMessages.push(data.messageAdded.mes);
            setTimeout(() => {
              const container = this.$refs.messages;
              container.scrollTop = container.scrollHeight;
            }, 200);
          } else {
            alert("this is notification");
          }
        },
      },
    },
    userProfile: {
      query: USER_PROFILE,
      variables() {
        return {
          username: this.$route.params.chat,
        };
      },
      update: (data) => {
        return data.userProfile.user;
      },
    },
  },
  methods: {
    getURL,
    photoMessageSent(message, file) {
      this.loadingSend = true;
      this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            input: {
              body: "",
              user: this.selectedConvo
                ? this.selectedConvo.m_one.username
                : this.newTarget.username,
              attachment: "",
            },
            file: file.target.files[0],
          },
        })
        .then(({ data }) => {
          this.loadingSend = false;
          if (data) {
            this.$refs.messageFieldx
              ? this.$refs.messageFieldx.emptyMessageField()
              : this.$refs.messageField.emptyMessageField();
              this.$store.commit("setBalance", data.sendMessage.flirts);
          }
        })
        .catch((e) => {
          this.loadingSend = false;
          if (e.message == "GraphQL error: Spam detected") {
            this.$notify(
              {
                group: "top",
                title: "chat",
                text: "please give them a minute",
                type: "info", // info, warning
              },
              5000
            );
          }
        });
      console.log(message, file);
    },
    messageSent(message) {
      this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            input: {
              body: message,
              user: this.$route.params.chat,
              attachment: "",
            },
          },
        })
        .then(({ data }) => {
          if (data) {
            this.$refs.messageField.emptyMessageField();
            this.$store.commit("setBalance", data.sendMessage.flirts);
          }
        })
        .catch((e) => {
          if (e.message == "GraphQL error: Spam detected") {
            this.$notify(
              {
                group: "top",
                title: "chat",
                text: "please give them a minute",
                type: "info", // info, warning
              },
              5000
            );
          }
        });
    },
    // userSelected() {
    // 	this.convoSelected = true
    // 	this.fetchConvo()
    // },
  },
  created() {
    // console.log(this.$apollo.subscriptions,"NIGGA")
    // this.$apollo.subscriptions.messageAdded.refresh()
    // console.log(this.$apollo.queries.userProfile, "ALL QUEROIES")
    this.$apollo.subscriptions.messageAdded.refresh();
    this.$apollo.queries.userProfile.refetch();
    // this.$apollo.queries.allDmMessages.refetch()
    // this.userSelected()
  },
  data: () => ({
    userProfile: {},
    renderedMessages: [],
    allMessages: [],
    loadingSend: false,
    selectedConvo: null,
    convoStatus: false,
    convoSelected: false,
    bgColor: "#dc4b78",
    position: "bottom-right",
    fabActions: [
      {
        name: "toMatches",
        icon: "favorite",
      },
    ],
  }),
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(150, 148, 148, 0.212);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 213, 213, 0.274);
}
.spacer {
  flex-grow: 1;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
}
</style>