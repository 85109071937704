<template>
  <div>
    <div
      class="
        px-4
        py-5
        mx-auto
        sm:max-w-xl
        md:max-w-full
        lg:max-w-screen-xl
        md:px-24
        lg:px-8
      "
    >
      <div class="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="FlirtTool"
          title="FlirtTool"
          class="inline-flex items-center"
        >
          <img
            src="https://funnyflirts.net/img/logo-light.6ebe202c.png "
            class="h-16"
          />
        </a>

        <!-- Mobile menu -->
        <div class="lg:hidden"></div>
      </div>
    </div>
    <hr style="border: 1px inset" />
    <div class="flex justify-center">
      <div class="flex-row">
        <nav class="flex mt-10" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                to="/help-center"
                class="
                  inline-flex
                  items-center
                  text-sm
                  font-medium
                  text-gray-700
                  hover:text-gray-900
                  dark:text-gray-400 dark:hover:text-white
                "
              >
                <svg
                  class="mr-2 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Help Center
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <a
                  href="#"
                  class="
                    ml-1
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-900
                    md:ml-2
                    dark:text-gray-400 dark:hover:text-white
                  "
                  >Safety And Privacy</a
                >
              </div>
            </li>
          </ol>
        </nav>
        <!-- Guide block start -->
        <div class="flex xl:flex-row justify-start mx-3">
          <div
            class="
              grid grid-cols-2
              md:grid-cols-3
              lg:grid-cols-3
              xl:grid-cols-3
            "
          >
            <div class="text-left max-w-5xl w-full">
              <div>
                <p class="text-3xl font-bold">Safety And Privacy</p>
                <div class="flex flex-col divide-y divide-y-reverse text-xl">
                  <div class="my-3" v-for="(q, x) in gudies" :key="x">
                    <router-link
                      :to="`/saftey/${q.slug}`"
                      v-text="q.title"
                      class="text-pink-600"
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-32 lg:mx-44 xl:mx-32"></div>
          </div>
        </div>
        <!-- Guide block end -->
      </div>
    </div>
    <div class="xl:mb-72 lg:mb-72"></div>
    <page-footer />
  </div>
</template>
<script>
import pageFooter from "../../components/pageFooter.vue";
import { GUIDE_WSLUG } from "../../queries/cms";
export default {
  components: { pageFooter },
  data: () => ({
    questions: [
      { title: "Confirming your age", link: "/help-detail" },
      { title: "Safety Tips", link: "/help-detail" },
      { title: "Verifying your identity", link: "/help-detail" },
      { title: "Banned profiles", link: "/help-detail" },
      { title: "Blocking & reporting profiles", link: "/help-detail" },
    ],
    gudies: [],
  }),
  methods: {
    getGuidePages() {
      this.$apollo
        .query({
          query: GUIDE_WSLUG,
          client: "cms",
          variables: {
            where: { type_contains: "safetyTips" },
          },
          context: {
            headers: {
              Authorization:
                "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
        })
        .then(
          ({
            data: {
              guidePagesCollection: { items },
            },
          }) => {
            console.clear()
            console.warn(items)
            this.gudies = items;
          }
        );
    },
  },
  created() {
    this.getGuidePages();
  },
};
</script>

