<template>
  <FlirtsIndex/>
</template>
<script>
import FlirtsIndex from '@/templates/Flirts/Index.vue'
export default {
  components:{
    FlirtsIndex
  }
}
</script>