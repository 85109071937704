<template>
  <div>
    <div class="mtc_usrd_slider  hidden lg:block xl:block" v-if="hotOrNotUsers.length > 0">
      <div class="card text-center rounded-lg shadow-lg border-2 border-white shadow-lg mb-12">
        <figure>
          <img :src="getURL(hotOrNotUsers[0].profilePic)" style="object-fit: cover; width: 600px; height: 700px" />
        </figure>
      </div>
      <div class="mtc_usrd_actions -m-32">
        <button v-if="hotOrNotUsers[0].usrType != 'model'" @click="$router.push('/@' + hotOrNotUsers[0].username)"
          href="javascript:void(0);" id="matches_dislike_btn" class="btn waves-effect dislike shadow-md hover:bg-pink-50">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="pink"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>

        </button>
        <button v-else @click="$router.push('/ft/@' + hotOrNotUsers[0].username)" href="javascript:void(0);"
          id="matches_dislike_btn" class="btn waves-effect dislike shadow-md hover:bg-pink-50">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="pink"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>

        </button>

        <button href="javascript:void(0);" id="matches_like_btn" data-ajax-post="/useractions/like"
          data-ajax-callback="callback_like" class="btn waves-effect like w-36  hover:bg-pink-50 " @click="likeProfile">
           <div > <img src="@/assets/mazu/hot.png" style=" object-fit: cover;" class="h-16 w-36 " alt=""></div>
        </button>
        <button href="javascript:void(0);" id="matches_dislike_btn" class="btn waves-effect dislike hover:bg-pink-50"
          @click="unlike">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
              clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>

    <!-- mobi -->
    <div class="mtc_usrd_slider mb-10  lg:hidden xl:hidden " v-if="hotOrNotUsers.length > 0">
      <div class="card text-center rounded-lg shadow-lg border-2 border-white shadow-lg mb-12">
        <figure>
          <img :src="getURL(hotOrNotUsers[0].profilePic)" style="object-fit: cover; width: 600px; height: 500px" />
        </figure>
      </div>
      <div class="mtc_usrd_actions -m-16">
        <button v-if="hotOrNotUsers[0].usrType != 'model'" @click="$router.push('/@' + hotOrNotUsers[0].username)"
          href="javascript:void(0);" id="matches_dislike_btn" class="btn waves-effect dislike shadow-md hover:bg-pink-50">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="pink"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>

        </button>
        <button v-else @click="$router.push('/ft/@' + hotOrNotUsers[0].username)" href="javascript:void(0);"
          id="matches_dislike_btn" class="btn waves-effect dislike shadow-md hover:bg-pink-50">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="pink"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>

        </button>

        <button href="javascript:void(0);" id="matches_like_btn" data-ajax-post="/useractions/like"
          data-ajax-callback="callback_like" class="btn waves-effect like w-36  hover:bg-pink-50 " @click="likeProfile">
           <div > <img src="@/assets/mazu/hot.png" style=" object-fit: cover;" class="h-16 w-36 " alt=""></div>
        </button>
        <button href="javascript:void(0);" id="matches_dislike_btn" class="btn waves-effect dislike hover:bg-pink-50"
          @click="unlike">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
              clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>

    <!-- mob -->
  </div>
</template>
<script>
import { DISLIKE_PROFILE, HOT_ORNOT_SET, LIKE_PROFILE } from "../../queries/matches";
import { getURL } from "@/utils/helpers";
export default {
  name: "HorOrNot",
  data: () => ({
    hotOrNotUsers: [],
  }),
  mounted() {
    this.$apollo.queries.hotOrNotUsers.refetch();
  },
  methods: {
    getURL,
    likeProfile() {
      // this.hotOrNotUsers.splice(0, 1)
      const selectedUser = this.hotOrNotUsers[0];
      this.hotOrNotUsers.shift();
      console.log(console.log(this.hotOrNotUsers), "This here is the selected user");
      this.$apollo
        .mutate({
          mutation: LIKE_PROFILE,
          variables: {
            user: selectedUser.username,
          },
        })
        .then(() => {
          console.log("requset completed");
        })
        .catch((e) => {
          // catch exeption
          console.error(e);
        })
        .finally(() => { });
    },
    unlike() {
      const selectedUser = this.hotOrNotUsers[0];
      this.hotOrNotUsers.shift();
      this.$apollo.mutate({
        mutation: DISLIKE_PROFILE,
        variables: {
          user: selectedUser.username,
        },
      });
      // .finally(() => {
      //   this.hotOrNotUsers.shift();
      // });
    },
  },
  apollo: {
    hotOrNotUsers: {
      query: HOT_ORNOT_SET,
      variables: {
        input: {
          page: 1,
          pageSize: 50,
        },
      },
      update: (data) => {
        return data.hotOrNotUsers;
      },
    },
  },
};
</script>
<!-- <script>
import { DISLIKE_PROFILE, HOT_ORNOT_SET, LIKE_PROFILE } from "../queries/matches";
import { getURL } from "../utils/helpers";
export default {
  name: "HorOrNot",
  data: () => ({
    hotOrNotUsers: [],
  }),
  mounted() {
    this.$apollo.queries.hotOrNotUsers.refetch();
  },
  methods: {
    getURL,
    handleProfileAction(liked) {
      const selectedUser = this.hotOrNotUsers[0];
      this.hotOrNotUsers.shift();
      const mutation = liked ? LIKE_PROFILE : DISLIKE_PROFILE;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            user: selectedUser.username,
          },
        })
        .then(() => {
          console.log("request completed");
        })
        .catch((e) => {
          // catch exception
          console.error(e);
        })
        .finally(() => { 
          this.$apollo.queries.hotOrNotUsers.refetch(); // refetch the hotOrNotUsers query after like/dislike
        });
    },
  },
  apollo: {
    hotOrNotUsers: {
      query: HOT_ORNOT_SET,
      variables: {
        input: {
          page: 1, // set page to 1
          pageSize: 1000,
        },
      },
      update: (data) => {
        return data.hotOrNotUsers;
      },
    },
  },
};
</script> -->



