<template>
	<!-- <LoginIndex/> -->
	<!-- <MatchesIndex/> -->
	<TermsIndex/>
  </template>
  <script>
  import TermsIndex from '@/templates/Terms/Index.vue'
  export default {
	components:{
	  TermsIndex
	}
  }
  </script>