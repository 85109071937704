<template>
  <!-- <LoginIndex/> -->
  <!-- <MatchesIndex/> -->
  <LikedIndex/>
</template>
<script>
import LikedIndex from '@/templates/Liked/Index.vue'
export default {
  components:{
    LikedIndex
  }
}
</script>