<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div class="w-full bg-white  ">
    <popup :showing="showing" @close="showing = false">
      <br>
      <p
        class="px-5 font-sans text-sm text-center text-pink-500 uppercase font-semi-bold text-md dark:text-white card-title">
        You are running out of flirts, to topup click link below</p>
      <router-link class="mt-3 bg-pink-100 btn" to='/flirtons'>
      Get Flirtons
    </router-link>
  </popup>
  <!-- <nav-bar
        v-if="
          !(($route.name == 'Chat' || $route.name == 'DirectChat') && $mq == 'sm')
        "
      /> -->
    <!-- <Yellownavbar class="-mt-24" v-if="
      !(($route.name == 'Chat' || $route.name == 'DirectChat') && $mq == 'sm')
    " /> -->
    <!-- new theme starts here -->
    <!-- <div class="px-4 py-0 -mt-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <p class="uppercase animate-bounce text-yellow-500 font-sans">Swip left or right to see more icons</p>
      <iconnavbar />
    </div> -->

    <div class="grid grid-cols-12   px-4 py-5 gap-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <!-- Left sidebar for navigation -->

      <div class="col-span-3  hidden lg:block">
        <!-- Your navigation content goes here -->
        <div class="bg-orange-100 rounded-xl shadow-lg border-white border-4 p-4 col-span-3"
          style="background-color: #F3E5CD;">
          <div class=" shadow-none rounded-xl  bg-orange-200">

            <YellowUserAvartar />
            <div class="-mt-5 py-2 justify-center text-center bg-white">
              <span class="uppercase py-2">Notification</span>

            </div>
            <div class="text-center bg-orange-100 mt-2 h-32">

              <YellowNotify />
              <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
                <div></div>
                <div></div>
              </div>

            </div>

          </div>


          <div class="text-center bg-orange-100 mt-44 h-96 overflow-hidden">
            <div class="py-2 justify-center text-center bg-white">
              <span class="uppercase py-2 ">Visitors</span>

            </div>
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>
            <!-- <SideHomeSelector /> -->
            <YellowsideHomeSelector />

          </div>
        </div>

      </div>

      <!-- Main content area with 2 nested grids -->
      <div class="col-span-9  grid grid-cols-12 gap-4">
        <!-- First nested grid, takes up the left half of the main content area -->
        <div class="col-span-8 hidden lg:block">
          <!-- Your content for the left half of the main area goes here -->
          <div>
            <YellowHotOrNot />



          </div>
        </div>

        <!-- Second nested grid, takes up the right half of the main content area -->
        <div class="col-span-4 hidden lg:block">
          <!-- Your content for the right half of the main area goes here -->
          <div> <!-- Friends card -->
            <div class="flex items-center text-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
              <div class="flex justify-center mb-7"> <img class="h-16 mt-5" src="@/assets/mazu/VIP.png" alt=""></div>

            </div>
            <div class="bg-orange-200 rounded-lg p-3  " style="background-color: #F3E5CD;">
              <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
                <div></div>
                <div></div>
              </div>
            <div class="grid shadow-xl lg:grid-cols-2 md:grid-cols-1  grid-cols-1 gap-3">
                <Yellowvipusercard :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip"
                  :key="idx" :user="user" :page="'find_matches'" />
                <!-- <vip-user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip"
                    :key="idx" :user="user" :page="'find_matches'" /> -->
              </div>

            </div>
            <!-- End of friends card -->
          </div>
        </div>
        <div class="col-span-12 hidden lg:block">
          <div class="dark:bg-gray-800" v-if="!loadingContent">
            <select @change="filterChanged" v-model="nFilter"
              class="w-36 py-2 px-2 mt-5 mb-1 justify-start flex border-2 text-sm  bg-white dark:bg-gray-700 dark:text-gray-200 rounded-2xl shadow-xl">
              <option value="" disabled selected class="text-sm">Filter Users</option>
              <option disabled="disabled" selected="selected">I am looking for</option>
              <option>Women</option>
              <option>Men</option>
              <option>Gay</option>
              <option>Lesbian</option>
            </select>
          </div>

          <!-- Your content for the second row of the left half of the second nested grid goes here -->
          <div class="flex flex-wrap gap-1 py-0 sha">
            <YellowuserCard :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches" :key="idx"
              :user="user" :page="'find_matches'" />
          </div><button v-if="!loadingContent"
            class="mb-10 mt-6 border-yellow-500 rounded-full shadow-xl shadow-none boder-0 border-" @click="loadMore">
            <div v-if="loadingUsers" class="mt-5 lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

            <a v-else class="font-sans text-yellow-500 text-xs uppercase font-bold"> Load More </a>
          </button>
        </div>

      </div>
    </div>
    <!-- bg-yellow-0 theme  -->

    <!-- end of yellowtheme -->

  <!-- dont remove the bellow content  -->

  <div class="lg:hidden xl:hidden 2xl:hidden   dark:bg-gray-700 p-5 -mt-5">

      <div class="flex items-top justify-center  w-full pl-1 pr-1 -mt-6  md:w-full md:mb-0 bg-white dark:bg-gray-700">
        <div class="mt-0">



          <YellowHotOrNot />
          
          <br />

         
        </div>
        <div></div>
      </div>

      <div class="grid grid-cols-1">
        <!-- <p class="-mt-4 mb-4"> <img src="@/assets/newchat/24.png" class="h-5" alt=""></p> -->
        <!-- <div
          class=" items-center shadow-sm justify-center grid-cols-1 w-full  h-full  pl-0 pr-0 overflow-auto    dark:bg-gray-700 scroll-smooth scrollbar-hide whitespace-nowrap  md:mb-0 bg-white lg:grid-cols-1 md:grid-cols-2">
          <div style="height: ;">
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>
            <div class="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-5  grid-cols-1 gap-3">
                <Yellowvipusercard :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip"
                  :key="idx" :user="user" :page="'find_matches'" />
                <vip-user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip"
                    :key="idx" :user="user" :page="'find_matches'" />
              </div>
          </div>





        </div> -->
        <p class="-mt-5 uppercase font-sans font-bold text-yellow-600">mazu user</p>
        <div class="dark:bg-gray-800 flex justify-center" v-if="!loadingContent">
            <select @change="filterChanged" v-model="nFilter"
              class="w-36 py-2 px-2 mt-5 mb-1 justify-start flex border-2 text-sm  bg-white dark:bg-gray-700 dark:text-gray-200 rounded-2xl shadow-xl">
              <option value="" disabled selected class="text-sm">Filter Users</option>
              <option disabled="disabled" selected="selected">I am looking for</option>
              <option>Women</option>
              <option>Men</option>
              <option>Gay</option>
              <option>Lesbian</option>
            </select>
          </div>
        <div
          class=" items-center mt-5 z-0 justify-center grid-cols-1 w-full  h-full  pl-0 pr-0    dark:bg-gray-700 scroll-smooth scrollbar-hide whitespace-nowrap  md:mb-0 bg-white lg:grid-cols-1 md:grid-cols-2">
          <div style="height: ;">
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>
            <div class="grid grid-cols-2   text-left md:col-span-5 md:grid-cols-4 xl:grid-cols-2" v-if="!loadingContent">
              <YellowuserCard :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches" :key="idx"
              :user="user" :page="'find_matches'" />
            </div>

          </div>

          <button v-if="!loadingContent"
            class="mb-10 mt-6 border-yellow-500 rounded-full shadow-xl shadow-none boder-0 border-" @click="loadMore">
            <div v-if="loadingUsers" class="mt-5 lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

            <a v-else class="font-sans text-yellow-500 text-xs uppercase font-bold"> Load More </a>
          </button>


          <Mobilenavbar />
          </div>

        </div>
      </div>



  </div>
</template>
<style scoped></style>
<script>
import Adsense from 'vue-google-adsense'
import axios from "axios";
import iconnavbar from '@/components/yellow/iconnavbar.vue';
// import Notify from "@/components/yellow/Notify.vue"
// import HotOrNot from "@/components/yellow/HotOrNot.vue";
// import UserAvatar from "@/components/yellow/userAvatar.vue";
//import SideSelector from "../../components/yellow/sideSelector.vue";
// import SideHomeSelector from "@/components/yellow/sideHomeSelector.vue";
import { LOGGEDIN_DATA, START_PASS } from "@/queries/auth";
// import UserCard from "@/components/yellow/userCard.vue";
import { FIND_MATCHES_LIST, UPDATE_LOC, FIND_VIP_MATCHES_LIST } from "@/queries/matches";
//import MessageField from "../../components/yellow/chat/messageField.vue";
//import HomeChat from "../../components/yellow/homeChat.vue";
import store from "@/store";
// import PromoAlert from '../../components/yellow/promoAlert.vue';
//import PromoSection from "../../components/yellow/promoSection.vue";
// import VueConfetti from "VueConfetti"
// import NewSideSelector from "@/components/yellow/newsideselector ";
// import SideMatchSelector from '@/components/yellow/sideMatchSelector';
import popup from "@/components/popup.vue";
import { Location } from '@/utils/locations'
// import VipUserCard from "@/components/yellow/vipUserCard.vue";
// import Mobilenavbar from '@/components/yellow/Mobilenavbar.vue';
// import Vipchatsideselector from '@/components/yellow/Vipchatsideselector.vue';
import YellowHotOrNot from '@/components/yellow/YellowHotOrNot.vue';
import YellowNotify from '@/components/yellow/YellowNotify.vue'
import YellowuserCard from '@/components/yellow/YellowuserCard.vue'
import Yellowvipusercard from '@/components/yellow/YellowvipUserCard.vue'
import YellowuserCards from '@/components/yellow/YellowuserCards.vue'
import YellowsideHomeSelector from '@/components/yellow/YellowsideHomeSelector.vue'
import YellowUserAvartar from '@/components/yellow/YellowuserAvatar.vue'
import Yellownavbar from '@/components/yellow/yellownavBar.vue'


const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    // HotOrNot,
    // UserAvatar,
    // Adsense,
    // Vipchatsideselector,
    // MessageField,
    // NewSideSelector,
    // SideHomeSelector,
    // SideMatchSelector,
    YellowHotOrNot,
    // UserCard,
    // Notify,
    // VipUserCard,
    // Mobilenavbar,
    iconnavbar,
    YellowuserCard,
    YellowuserCards,
    YellowsideHomeSelector,
    YellowUserAvartar,
    // HomeChat,
    // PromoAlert,
    //PromoSection,
    // CreditsChip,
    //MobileSideSelector,
    popup,
    YellowNotify,
    Yellowvipusercard,
    Yellownavbar,
    Location
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    me: {
      query: LOGGEDIN_DATA,
      variables() {
        return {
          addr: this.$store.state.core.addr,
        };
      },
      result({
        data: {
          me: { user, credits, notificationCount, isAff, isSuper, isIkm },
        },
      }) {
        store.commit("setAfStat", isAff);
        store.commit("setAfSstat", isSuper);
        store.commit("setIsIkm", isIkm);
        store.commit("setUserData", user);
        store.commit("setBalance", credits.balance + credits.flirtons);
        store.commit("changeNotificationCount", notificationCount);
        store.commit("setTempLocationDetail", user.tempLocationDetail);
        this.me = user;
      },
    },
  },
  data: () => ({
    pagin: {
      page: 1,
      pageSize: 5,
      filter: "all",
    },
    paginVip: {
      page: 1,
      pageSize: 10,
      filter: "all",
    },
    nFilter: "",
    showing: false,
    welcomeBanner: true,
    loadingContent: false,
    loadingUsers: false,
    bgColor: "#dc4b78",
    position: "bottom-right",
    fabActions: [
      {
        name: "alertMe",
        icon: "chat",
      },
    ],

    features,
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    filter: "all",
    find_matches: [],
    find_matches_vip: [],

    me: null,
    mobile: false,
    showPop: false,
  }),
  methods: {
    startPackage() {
      const minMin = 1;
      const createdMs =
        new Date() - new Date(parseInt(this.$store.state.core.user.createdAt));
      console.warn("created MS: ", createdMs);
      const min = Math.floor((createdMs / 1000 / 60) << 0);
      const recentOnline = min <= minMin;
      console.warn(
        "Recent online time: ",
        recentOnline,
        "min: ",
        min,
        "minMin: ",
        minMin
      );
      if (recentOnline) {
        this.$notify(
          {
            group: "top",
            title: "🎉 Welcome To FlirtTool 🎉",
            text: "🎉🎊 Congrats and enjoy 1 hour free chat access 🎉🎊",
            type: "success", // info, warning
          },
          8000
        );

        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
        // this.loading = true;
        this.$apollo
          .mutate({
            mutation: START_PASS,
          })
          .then(() => {
            this.passGranted();
          })
          .catch(() => {
            alert("err");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    findMatches() {
      this.loadingContent = true;
      this.$apollo
        .query({
          query: FIND_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.pagin,
            addr: this.$store.state.core.addr,
          },
        })
        .then(({ data }) => {
          this.find_matches = data.find_matches.data;
        })
        .finally(() => {
          this.loadingContent = false;
        });
    },
    findMatchesVip() {
      // this.loadingContent = true;
      this.$apollo
        .query({
          query: FIND_VIP_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.paginVip,
          },
        })
        .then(({ data }) => {
          this.find_matches_vip = data.find_matches_vip.data;
        }).catch((e) => {

        })
        .finally(() => {
          // this.loadingContent = false;
        });
    },
    filterChanged() {
      this.find_matches = [];
      switch (this.nFilter) {
        case "Women":
          this.pagin.filter = "female";
          break;
        case "Men":
          this.pagin.filter = "male";
          break;
        case "Lesbian":
          this.pagin.filter = "lesbain";
          break;
        // case "Transgender":
        // 	this.pagin.filter = "trans"
        // 	break;
        case "Gay":
          this.pagin.filter = "gay";
          break;
        default:
          break;
      }
      this.loadingContent = true;
      this.findMatches();
    },
    loadMore() {
      this.loadingUsers = true;
      this.pagin.page++;
      this.$apollo
        .query({
          query: FIND_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.pagin,
            addr: this.$store.state.core.addr,
          },
        })
        .then(
          ({
            data: {
              find_matches: { data },
            },
          }) => {
            // loop and just push
            for (let x = 0; x < data.length; x++) {
              const element = data[x];
              this.find_matches.push(element);
            }
            // console.log(this.find_matches())
            // this.find_matches.push(data)
            // let loadedUsers = data.find_matches.data
            // let finalSet = [...this.find_matches, ...loadedUsers]
            // this.find_matches = finalSet
          }
        )
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    loadMoreVip() {
      this.loadingUsers = true;
      this.pagin.page++;
      this.$apollo
        .query({
          query: FIND_VIP_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.paginVip,
          },
        })
        .then(
          ({
            data: {
              find_matches: { data },
            },
          }) => {
            // loop and just push
            for (let x = 0; x < data.length; x++) {
              const element = data[x];
              this.find_matches_vip.push(element);
            }
            // console.log(this.find_matches())
            // this.find_matches.push(data)
            // let loadedUsers = data.find_matches.data
            // let finalSet = [...this.find_matches, ...loadedUsers]
            // this.find_matches = finalSet
          }
        )
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    xalert() {
      alert("bruv");
    },
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
    passGranted() {
      this.$apollo.queries.me.refetch();
    },
    removeItem(idx) {
      this.find_matches.splice(idx, 1);
    },
    async trackLocation() {
      let locationInterval = store.getters.updateLocationInterval
      if (!locationInterval) {
        let location = new Location
        await location.geoLocate()
        await location.setUpdateLocationInterval()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("onorientationchange", this.detectOrientationChange);
    });
  },
  created() {
    setTimeout(() => {
      if (this.$store.getters.showFlirton) this.showing = true
    }, 3000)
    this.trackLocation()
    //   show popup on initial registration
    this.showPop = true;
    // refetch on route change
    this.pagin = {
      page: 1,
      pageSize: 5,
    };
    // this.loadingContent = true
    axios
      .get(
        "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
      )
      .then(({ data }) => {
        this.$store.commit("setAddr", data.ip);
        this.$apollo.mutate({
          mutation: UPDATE_LOC,
          variables: {
            addr: data.ip,
          },
        });
      })
      .finally(() => {
        this.$apollo.queries.me.refetch().then(() => {
          setTimeout(() => {
            // this.startPackage();
          }, 1000);
        });
        this.findMatches();
        this.findMatchesVip()
      });
    this.$apollo.queries.visits.refetch().finally(() => {
      this.loadingContent = false;
    });
    this.detectOrientationChange(); // when instance is created

    // const token = this.$store.state.core.flirtok ? `${this.$store.state.core.flirtok}` : null;
  },
};
</script>
<!-- <style scoped>
p {
  padding: 0px;
  display: block;
}
</style> -->
<!-- <style>
@import "../../assets/css/style.css";
</style> -->
