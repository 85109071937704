<template>
  <div class="image-slider">
    <div class="slider-container">
      <div class="slider-wrapper">
        <transition name="fade" mode="out-in">
          <div class="slider-slide" :key="currentIndex">
            <img :src="currentSlide" class="relative e inset-0 justify-center object-cover w-full h-full"  style="width: 100%; height: 795px;">
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        require('@/assets/flirttoolstory123.jpg'),
        require('@/assets/flirttoolus123.jpg'),
        require('@/assets/flirttoollovestoyrca.jpg'),
        require('@/assets/Flirttoolunitedstates.jpg'),
      ],
      currentIndex: 0,
    };
  },
  computed: {
    currentSlide() {
      return this.slides[this.currentIndex];
    },
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
  },
  mounted() {
    // Automatically advance the slider every few seconds (e.g., every 5 seconds)
    setInterval(this.nextSlide, 5000);
  },
};
</script>

<style>
.image-slider {
  position: relative;
}

.slider-container {
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-slide {
  flex: 0 0 100%;
}

.slider-slide img {
  width: 100%;
  height: auto;
}

/* Add a fade animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-control {
  border: none;
  background: none;
  padding: 0;
  margin: 0 5px;
  cursor: pointer;
  outline: none;
}

.slider-control.active img {
  border: 2px solid blue;
}
</style>
