<template>
  <div class="text-left mx-0 px-1 font-medium xl:mt-1 lg:mt-1">
    <div class="md:flex dark:bg-gray-700">
      <div class="w-full p-2">
        <!-- <div class="relative">
            <input
              type="text"
              class="w-full dark:text-gray-200 dark:bg-gray-800 h-12 rounded focus:outline-none px-3 focus:shadow-md"
              placeholder="Search..."
            />
            <i class="fa fa-search absolute right-3 top-4 text-gray-300"></i>
          </div> -->
        <center v-if="loadingChats">
          <div class="mt-14 lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </center>

        <ul
          class="overflow-y-scroll grid grid-cols-2"
          style="height: 92%; width: 250px"
          v-else
        >
          <li
            v-if="newDm && newTarget.usrType == 'model'"
            @click="dmTargetSelected"
            class="flex justify-between items-center bg-white dark:bg-gray-600 mt-2 p-2 hover:shadow-lg rounded cursor-pointer transition"
          >
            <div class="flex ml-2">
              <img
                style="object-fit: cover; width: 100px; height: 100px"
                :src="getURL(newTarget.profilePic)"
              />
              <!-- <div class="flex flex-col ml-2 text-black text-xs dark:text-white">
                  <span class="font-medium"
                    >{{ newTarget.base_profile.firstName }}
                    {{ newTarget.base_profile.lastName }}</span
                  >
                  <span class="text-sm text-gray-400 truncate w-44"></span>
                </div> -->
            </div>
            <!-- <div class="flex flex-col items-center">
                <span class="text-gray-300">11:26</span>
                <i class="fa fa-star text-green-400"></i>
              </div> -->
          </li>
          <li
            @click="userSelected(item)"
            v-for="(item, idx) in vipConv"
            :key="idx"
            class="flex justify-between roun items-center gap-2 mt-2 p-0 hover:shadow-lg rounded-xl border-1 cursor-pointer transition"
            :class="{
              'dark:bg-gray-600': selected.id === item.id,
              'dark:bg-gray-800': !(selected.id === item.id),
              'bg-pink-200': selected.id === item.id,
              'bg-white': !(selected.id === item.id),
            }"
          >
            <div class="flex ml-2">
              <img
                style="object-fit: cover; width: 130px; height: 150px"
                :src="getURL(item.m_one.profilePic)"
              />
              <!-- <div class="flex flex-col ml-2 text-black text-xs dark:text-white">
                  <span class="font-medium"
                    >{{ item.m_one.base_profile.firstName }}
                    {{ item.m_one.base_profile.lastName }}</span
                  >
                  <span
                    v-if="item.lastMessage"
                    class="truncate text-2xs w-44"
                    :class="{
                      'text-gray-600': selected.id === item.id,
                      ' text-gray-400': !(selected.id === item.id),
                    }"
                    >{{ item.lastMessage.body }}</span
                  >
                </div> -->
            </div>
            <div class="flex flex-col items-center">
              <!-- <span class="text-gray-300" v-if="item.lastMessage">{{
                  parseTimeStr(item.lastMessage.createdAt)
                }}</span> -->
              <i class="fa fa-star text-green-400"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getURL, parseTimeStr } from "@/utils/helpers";
export default {
  props: {
    selected: {},
    newDm: Boolean,
    newTarget: Object,
    allConversations: Array,
    loadingChats: Boolean,
  },
  computed: {
    vipConv:function() {
      let vipCOnv = this.allConversations.filter(c => c.m_one.usrType == 'model')
      return vipCOnv
    }
  },
  methods: {
    getURL,
    parseTimeStr,
    pickDmTarget(target) {
      this.$emit("pickDmTarget", target);
    },
    userSelected(item) {
      this.$emit("userSelected", item);
    },
    dmTargetSelected() {
      this.$emit("dmUserSelected");
    },
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(150, 148, 148, 0.212);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 213, 213, 0.274);
}
</style>
