<template>
  <div class="px-1 cursor-pointer">
    <div class="mt-2 text-center shadow-lg h-42 w-72 gap rounded-xl card bg-pink-50 dark:bg-gray-800"
       style="width: 100%">
      <div class="flex flex-col bg-white shadow-xl items-center pb-1 border-2 py-5 px-3 w-52 card" >
        <img class="mb-3 w-24 h-24 rounded-full shadow-lg" style="object-fit: cover;" :src="
          user.profilePic ? getURL(user.profilePic) : require('@/assets/pholder.webp')
        " alt="Bonnie image">
        <h3 class="mb-1 text-lg font-sans font-medium text-gray-900 dark:text-white" v-if="user.base_profile"> {{
          user.base_profile.firstName }} {{ user.base_profile.lastName }}</h3>

        <div class=" mt-4 grid grid-cols-1 gap-2 space-x-3 lg:mt-6">
          <a href="#"
            class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-black bg-white rounded-lg   focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {{ user.userLocation }}</a>
          <a href="#"
            class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 text-center hover:bg-white focus:ring-4 focus:ring-blue-300 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-800" @click="$router.push('/@' + user.username)">Send Message</a>
        </div>


        
        <div class="rand_bottom_bar mt-16" v-if="page == 'find_matches'">
          <button class="btn waves-effect like scale-125 hover:scale-75 ease-in duration-500" data-userid="22195"
            data-ajax-post="/useractions/like" data-ajax-params="userid=22195&amp;username=u_a9eed3c96"
            data-ajax-callback="callback_like" @click="likeProfile">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-pink-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                clip-rule="evenodd" />
            </svg>
          </button>
          <button class="btn waves-effect dislike _dislike_text22195 scale-125 hover:scale-75 ease-in duration-500"
            data-userid="22195" id="dislike_btn" data-ajax-post="/useractions/dislike" data-ajax-params="userid=22195"
            data-ajax-callback="callback_dislike" @click="unlike()">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                clip-rule="evenodd" />
            </svg>
          </button>
        </div>

      </div>

    </div>
    
  </div>
</template>

<script>
import { DISLIKE_PROFILE, LIKE_PROFILE } from "@/queries/matches";
import { getURL } from "@/utils/helpers";
export default {
  data: () => ({}),
  props: {
    user: Object,
    page: String,
    geocode: Boolean,
  },
  methods: {
    getURL,
    unlike() {
      this.$emit("popIndex");
      this.$apollo.mutate({
        mutation: DISLIKE_PROFILE,
        variables: {
          user: this.user.username,
        },
      });
    },
    unmatch() {
      this.$emit("popIndex");
    },
    likeProfile() {
      this.$emit("popIndex");
      this.$apollo
        .mutate({
          mutation: LIKE_PROFILE,
          variables: {
            user: this.user.username,
          },
        })
        .then(() => {
          console.log("requset completed");
        })
        .catch((e) => {
          // catch exeption
          console.error(e);
        });
    },
    disLikeProfile() {
      this.$emit("popIndex");
    },
    removeDisliked() {
      this.$emit("popIndex");
    },
  },
};
</script>
