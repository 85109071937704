<template>
  <div class="text-left mx-0 px-1 font-medium xl:mt-1 lg:mt-1" >
    <div class="md:flex dark:bg-gray-700 " >
      <div class="w-full p-2">
        <center v-if="loadingChats">
          <div class="mt-14 lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </center>
        <div v-else>
          <ul v-if="nonVipConv.length > 0" class="overflow-y-scroll grid grid-cols-3" style="height: 92%; width: 250px">
            <li
              v-if="newDm && newTarget.usrType != 'model'"
              @click="dmTargetSelected"
              class="flex justify-between items-center bg-white dark:bg-gray-600 mt-2 p-2 hover:shadow-lg rounded cursor-pointer transition"
            >
              <div class="flex ml-2">
                <img style="object-fit: cover; width: 80px; height: 70px" :src="getURL(newTarget.profilePic)" class="rounded-full" />
              </div>
            </li>
            <li
              @click="userSelected(item)"
              v-for="(item, idx) in nonVipConv"
              :key="idx"
              class="flex justify-between roun items-center gap-2 mt-2 p-0 hover:shadow-lg rounded-xl border-1 cursor-pointer transition"
              :class="{
                'dark:bg-gray-600': selected.id === item.id,
                'dark:bg-gray-800': !(selected.id === item.id),
                'bg-yellow-200  rounded-full': selected.id === item.id,
                'bg-white': !(selected.id === item.id),
              }"
            >
              <div class="flex ml-2">
                <img style="object-fit: cover; width: 80px; height: 70px" :src="getURL(item.m_one.profilePic)" class="rounded-full" />
              </div>
              <div class="flex flex-col items-center">
                <i class="fa fa-star text-green-400"></i>
              </div>
            </li>
          </ul>
          <div class="flex pl-10" v-else>  <router-link to="/">
            <p class="btn  border-white border-2 shadow-lg text-gray-800 " style="background-color: #F3E5CD;"> <span class="-mt-5"> Find Users To chat</span> </p>
          </router-link> </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getURL, parseTimeStr } from "@/utils/helpers";
export default {
  props: {
    selected: {},
    newDm: Boolean,
    newTarget: Object,
    allConversations: Array,
    loadingChats: Boolean,
  },
  computed: {
    nonVipConv:function() {
      let vipCOnv = this.allConversations.filter(c => c.m_one.usrType != 'model')
      return vipCOnv
    }
  },
  methods: {
    getURL,
    parseTimeStr,
    pickDmTarget(target) {
      this.$emit("pickDmTarget", target);
    },
    userSelected(item) {
      this.$emit("userSelected", item);
    },
    dmTargetSelected() {
      this.$emit("dmUserSelected");
    },
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(150, 148, 148, 0.212);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 213, 213, 0.274);
}
</style>
