<template>
  <div>
    <notificationGroup group="top" position="top">
      <div
        style="transform: scale(80%)"
        class="fixed inset-x-0 z-10 flex items-start justify-end p-6 px-4 py-6 mt-5 pointer-events-no"
      >
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex items-center justify-center w-12 bg-pink-500">
                <svg
                  class="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                  ></path>
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3 text-left">
                  <span class="font-semibold text-pink-500">{{
                    notification.title
                  }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <div
      class="flex flex-wrap w-full h-full object-cover justify-center dark:text-gray-800"
      style="object-fit: cover; width: 100%; height: 100%"
    >
      <!-- Login Section -->
      <img
        style="height: 1500px"
        :src="require('@/assets/modlogin/bg.jpg')"
        class="absolute inset-0 justify-center object-cover w-screen h-full sm:"
        alt=""
      />
      <div
        class="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
      >
        <div
          class="relative flex flex-col w- py-16 lg:pt-0 lg:flex-col lg:pb-0"
        >
          <div
            style="height: 1200px"
            class="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 items-start w-full max-w-xl bg-white px-4 mx-auto lg:px-8 lg:max-w-screen-xl rounded-xl"
          >
            <div
              class="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
            >
              <div
                class="flex flex-col justify-left rounded-lg opacity-95 md:justify-start md:pt-0 px-6 relative h-full lg:full"
              >
                <p
                  class="mb-0 text-lg font-bold text-center text-gray-800 uppercase dark:text-gray-800 md:text-sm text-light lg:text-2xl font-sans mt-1"
                >
                  <span
                    class="text-pink-500 text-lg sm:text-lg md:text-lg lg:text-5xl xl:text-5xl"
                    >MODEL Register
                  </span>
                </p>
                <p
                  class="mx-4 mb-0 mt-5 mb-5 text-xs font-semibold text-gray-500 uppercase dark:text-gray-800 md:text-sm text-light"
                >
                  Already Have an account?<br />
                  <router-link
                    to="/modellogin"
                    class="font-semibold text-pink-500 underline"
                  >
                    Login Here
                  </router-link>
                </p>

                <!-- i wanto offer raidio box -->
                <div class="">
                  <!-- <button @click="showSlider = !showSlider">
                  Toggle Slider
                </button> -->

                  <fieldset
                    class="mt-5 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 gap-2"
                  >
                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-1"
                        type="radio"
                        value="gay"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-1"
                        aria-describedby="country-option-1"
                        checked=""
                      />
                      <label
                        for="country-option-1"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Gay
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-2"
                        type="radio"
                        value="bisexual"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-2"
                        aria-describedby="country-option-2"
                      />
                      <label
                        for="country-option-2"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Bisexual
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-3"
                        type="radio"
                        value="lesbain"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-3"
                        aria-describedby="country-option-3"
                      />
                      <label
                        for="country-option-3"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Lesbian
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-4"
                        type="radio"
                        value="straight"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-4"
                        aria-describedby="country-option-4"
                      />
                      <label
                        for="country-option-4"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Straight
                      </label>
                    </div>

                    <div class="flex items-center mb-4">
                      <input
                        id="country-option-4"
                        type="radio"
                        value="trans"
                        name="sexuality"
                        v-model="sexuality"
                        class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                        aria-labelledby="country-option-4"
                        aria-describedby="country-option-4"
                      />
                      <label
                        for="country-option-4"
                        class="text-sm font-medium font-sans text-gray-900 ml-2 block"
                      >
                        Trans
                      </label>
                    </div>
                  </fieldset>
                </div>

                <P class="font-sans text-left py-2 text-pink-500"
                  >I want to offer</P
                >
                <div class="font-sans grid grid-cols-2 lg:grid-cols-5 py-2">
                  <div class="flex items-center">
                    <input
                      checked
                      id="checked-checkbox"
                      type="checkbox"
                      v-model="offer.chat"
                      value=""
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="checked-checkbox"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Chat</label
                    >
                  </div>
                  <div class="flex items-center">
                    <input
                      checked
                      id="checked-checkbox"
                      type="checkbox"
                      value=""
                      v-model="offer.livecam"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="checked-checkbox"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Live Cam</label
                    >
                  </div>

                  <div class="flex items-center">
                    <input
                      checked
                      id="checked-checkbox"
                      type="checkbox"
                      value=""
                      v-model="offer.couple"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="checked-checkbox"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Couple</label
                    >
                  </div>
                  <div class="flex items-center">
                    <input
                      checked
                      id="checked-checkbox"
                      type="checkbox"
                      value=""
                      v-model="offer.adultpics"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="checked-checkbox"
                      class="ml-2 w- text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Adult Pictures</label
                    >
                  </div>
                </div>

                <div class="">
                  <!-- <button @click="showSlider = !showSlider">
                  Toggle Slider
                </button> -->
                  <P class="font-sans text-left text-pink-500"
                    >I want to offer to</P
                  >
                  <fieldset class="">
                    <div class="font-sans grid grid-cols-2 lg:grid-cols-5 py-2">
                      <div class="flex items-center">
                        <input
                          checked
                          id="checked-checkbox"
                          type="checkbox"
                          v-model="offerto.gay"
                          value=""
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Gay</label
                        >
                      </div>
                      <div class="flex items-center">
                        <input
                          checked
                          id="checked-checkbox"
                          type="checkbox"
                          v-model="offerto.bisexual"
                          value=""
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Bisexual</label
                        >
                      </div>

                      <div class="flex items-center">
                        <input
                          checked
                          id="checked-checkbox"
                          type="checkbox"
                          value=""
                          v-model="offerto.lesbian"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Lesbian</label
                        >
                      </div>
                      <div class="flex items-center">
                        <input
                          checked
                          id="checked-checkbox"
                          type="checkbox"
                          value=""
                          v-model="offerto.straight"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          class="ml-2 w- text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Straight</label
                        >
                      </div>
                      <div class="flex items-center">
                        <input
                          checked
                          id="checked-checkbox"
                          type="checkbox"
                          value=""
                          v-model="offerto.trans"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checked-checkbox"
                          class="ml-2 w- text-sm font-medium text-gray-900 dark:text-gray-300"
                          >Trans</label
                        >
                      </div>
                    </div>
                  </fieldset>
                </div>
                <!-- end of raido buttons -->

                <!-- check box -->
                <div
                  class="pt-2 text-center font-sans font-semi-bold text-pink-500 error"
                >
                  <label class="inline-flex items-center mt-3 text-left">
                    <input
                      v-model="acceptedTerms"
                      type="checkbox"
                      class="w-8 h-8 border-2 border-pink-500 text-pink-600 form-checkbox"
                      checked
                    /><span
                      class="mx-4 mb-0 text-2xs mt-5 font-semibold text-left text-gray-500 uppercase dark:text-gray-800 md:text-sm text-light"
                      >I I´m at least 18 years old and i´m the owner of the
                      rights of my own pictures, videos and audio files. I
                      transfer this right with my contract agreement to
                      Dialogmakers International Ltd <br />
                      <a
                        class="text-blue-400"
                        href="https://funnyflirts.net/terms"
                        >Click here to view our terms</a
                      >
                    </span>
                  </label>
                </div>
                <!-- end of check box -->

                <div class="grid gap-1 grid-cols-2 py-8">
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="firstName"
                        class="inline-block mb-1 text-left font-medium"
                      >
                        Name</label
                      >
                      <input
                        placeholder="Name"
                        required=""
                        type="text"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        id="firstName"
                        name="firstName"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.firstName.required,
                        }"
                        v-model.trim="$v.firstName.$model"
                        @input="setFirstName($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.firstName.required"
                    >
                      Firstname is required
                    </div>
                  </div>
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="firstName"
                        class="inline-block mb-1 text-left font-medium"
                        >Sir name</label
                      >
                      <input
                        placeholder="Sir name"
                        required=""
                        type="text"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.lastName.required,
                        }"
                        v-model.trim="$v.lastName.$model"
                        @input="setLastname($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.lastName.required"
                    >
                      Lastname is required
                    </div>
                  </div>
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="Email"
                        class="inline-block mb-1 text-left font-medium"
                        >Email</label
                      >
                      <input
                        placeholder="Email"
                        required=""
                        type="text"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        id="Email"
                        name="Email"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.email.required,
                        }"
                        v-model.trim="$v.email.$model"
                        @input="setEmail($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.email.required"
                    >
                      Email is required
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.email.email"
                    >
                      Invalid Email
                    </div>
                  </div>
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="Email"
                        class="inline-block mb-1 text-left font-medium"
                        >Username</label
                      >
                      <input
                        placeholder="Username"
                        required=""
                        type="text"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        id="Email"
                        name="Email"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.username.required,
                        }"
                        v-model.trim="$v.username.$model"
                        @input="setUsername($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.username.required"
                    >
                      Username is required
                    </div>
                  </div>
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="Email"
                        class="inline-block mb-1 text-left font-medium"
                        >Password</label
                      >
                      <input
                        placeholder="Password"
                        required=""
                        type="password"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.password.required,
                        }"
                        v-model.trim="$v.password.$model"
                        @input="setPassword($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.password.required"
                    >
                      Password is required
                    </div>
                  </div>
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="Email"
                        class="inline-block mb-1 text-left font-medium"
                        >Confirm Password</label
                      >
                      <input
                        placeholder="Confirm Password"
                        required=""
                        type="password"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.c_password.required,
                        }"
                        v-model.trim="$v.c_password.$model"
                        @input="setCPassword($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.c_password.sameAsPassword"
                    >
                      Password is not valid
                    </div>
                  </div>
                  <div>
                    <div class="mb-1 text-left sm:mb-2">
                      <label
                        for="Pseudonym:"
                        class="inline-block mb-1 text-left font-medium"
                      >
                        Pseudonym:
                      </label>
                      <input
                        placeholder="Pseudonym: "
                        required=""
                        type="text"
                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                        id="Pseudonym:"
                        name="Pseudonym:"
                        :class="{
                          'border-red-500 focus:ring-2 focus:ring-red-500':
                            !$v.pseudonym.required,
                        }"
                        v-model.trim="$v.pseudonym.$model"
                        @input="setPseudonym($event.target.value)"
                      />
                    </div>
                    <div
                      class="pt-2 text-left text-red-500 error text-xs"
                      v-if="!$v.pseudonym.required"
                    >
                      Pseudonym is required
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-2 -mt-6">
                  <div>
                    <div>
                      <div class="mb-1 text-left sm:mb-2">
                        <label
                          for="Pseudonym:"
                          class="inline-block mb-1 text-left font-medium"
                        >
                          Age:
                        </label>
                        <input
                          placeholder="Age: "
                          required=""
                          type="number"
                          class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          :class="{
                            'border-red-500 focus:ring-2 focus:ring-red-500':
                              !$v.age.required,
                          }"
                          v-model.trim="$v.age.$model"
                          @input="setAge($event.target.value)"
                        />
                      </div>
                      <div
                        class="pt-2 text-left text-red-500 error text-xs"
                        v-if="!$v.age.required"
                      >
                        Age is required
                      </div>
                    </div>
                  </div>
                  <!--  -->
                </div>
                <div>
                  <button
                    class="btn border-0 bg-pink-500 text-white w-full"
                    :disabled="loadingRegister"
                    @click="doSignUp"
                  >
                    Register
                  </button>
                </div>
                <!-- end of register button -->

                <p
                  class="mt-2 mb-5 font-semibold text-center text-gray-500 uppercase text-2xs dark:text-gray-800 md:text-2xs text-light"
                >
                  MAZU™ Powered by Dialogmakers International Ltd. 2021<br />
                  <router-link to="/terms" class="text-pink-500">
                    Terms </router-link
                  >|<router-link to="/privacy" class="text-pink-500">
                    Privacy </router-link
                  >|<router-link to="/imprint" class="text-pink-500">
                    Imprint</router-link
                  >
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="showImage"
            class="inset-y-0 right-0 w-full max-w-xl px-4 rounded-xl mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:block hidden lg:w-1/2 lg:max-w-full lg:absolute xl:px-0"
          >
            <img
              class="object-cover w-full h-56 rounded-xl shadow-lg lg:rounded-none lg:shadow-none sm:h-96 lg:h-full"
              :src="currentImage"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { USER_REGISTER } from "../queries/auth";

import { UPDATE_LOC } from "../queries/matches";
import { createProvider, onLogin } from "../v-apollo";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { SOCIAL_AUTH_GOOGLE, USER_LOGIN } from "../queries/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { register } from "../utils/basicAuth";

export default {
  data() {
    return {
      showImage: true,
      selectedImage: 0,
      showPass: false,
      passFocus: false,
      loadingLogin: false,
      usernameFocus: false,
      authErr: "",
      acceptedTerms: false,
      loadingSocialAuth: false,
      loadingRegister: false,
      images: [
        require("@/assets/modlogin/gay.jpg"),
        require("@/assets/modlogin/bisex.jpg"),
        require("@/assets/modlogin/lesbian.jpg"),
        require("@/assets/modlogin/straight.jpg"),
        require("@/assets/modlogin/Trans.jpg"),
      ],
      sexuality: "gay",
      offer: {
        chat: false,
        livecam: true,
        couple: true,
        adultpics: true,
      },
      offerto: {
        gay: false,
        bisexual: false,
        lesbian: false,
        straight: true,
        trans: false,
      },
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      pseudonym: "",
      age: 20,
      c_password: "",
    };
  },
  computed: {
    currentImage() {
      if (this.sexuality == "gay") return require("@/assets/modlogin/gay.jpg");
      if (this.sexuality == "bisexual")
        return require("@/assets/modlogin/bisex.jpg");
      if (this.sexuality == "lesbain")
        return require("@/assets/modlogin/lesbian.jpg");
      if (this.sexuality == "straight")
        return require("@/assets/modlogin/straight.jpg");
      if (this.sexuality == "trans")
        return require("@/assets/modlogin/Trans.jpg");
    },
  },
  methods: {
    doSignUp() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (!this.$v.$invalid) {
        this.loadingRegister = true;
        this.$apollo
          .mutate({
            mutation: USER_REGISTER,
            variables: {
              addr: "",
              options: {
                af_id: "",
                email: this.email,
                username: this.username,
                password: this.password,
                firstName: this.firstName,
                lastName: this.lastName,
                age: Number(this.age),
                pseudonym: this.pseudonym,
              },
              userType: "model",
              preference: this.sexuality,
              modelData: {
                offer_chat: this.offer.chat,
                offer_livecam: this.offer.livecam,
                offer_couple: this.offer.couple,
                offer_adultpics: this.offer.adultpics,
                offerto_gay: this.offerto.gay,
                offerto_bisexual: this.offerto.gay,
                offerto_lesbian: this.offerto.lesbian,
                offerto_straight: this.offerto.straight,
                offerto_trans: this.offerto.trans,
              },
            },
          })
          .then(({ data }) => {
            console.log(data);
            const { user, token, credit } = data.register;
            this.loadingRegister = false;
            this.$store.commit("setUserData", user);
            //this.$store.commit("setBalance", credit.balance);
            this.$store.commit("setBalance", credit.balance + credit.flirtons);
            this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("setFlirtTok", token);
            this.$router.push("/find-matches");
          })
          .catch((e) => {
            console.log(Object.keys(e), "enumerate");
            alert("Error registering user");
            this.loadingRegister = false;
          });
      } else {
        console.log(this.$v);
      }
    },
    setFirstName(value) {
      this.firstName = value;
      this.$v.firstName.$touch();
    },
    setLastname(value) {
      this.lastName = value;
      this.$v.lastName.$touch();
    },
    setEmail(value) {
      this.email = value;
      this.$v.email.$touch();
    },
    setUsername(value) {
      this.username = value;
      this.$v.username.$touch();
    },
    setPassword(value) {
      this.password = value;
      this.$v.password.$touch();
    },
    setCPassword(value) {
      this.c_password = value;
      this.$v.c_password.$touch();
    },
    setPseudonym(value) {
      this.pseudonym = value;
      this.$v.pseudonym.$touch();
    },
    setAge(value) {
      this.age = value;
      this.$v.age.$touch();
    },
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
    pseudonym: {
      required,
    },
    age: {
      required,
    },
    password: {
      required,
    },
    c_password: {
      required,
      sameAsPassword: sameAs("password"),
    },
    username: {
      required,
    },
  },
  // image silder
  // methods: {
  //     socialLoginFb() {
  //         if (!this.acceptedTerms) {
  //             this.$notify(
  //                 {
  //                     group: "top",
  //                     title: "you need to agree with the terms before you contine",
  //                     text: "Auth Error",
  //                     type: "info", // info, warning
  //                 },
  //                 5000
  //             );
  //             return;
  //         }
  //         const auth = getAuth();
  //         const provider = new FacebookAuthProvider();
  //         this.loadingSocialAuth = true;
  //         signInWithPopup(auth, provider)
  //             .then(async (result) => {
  //                 // const credential = GoogleAuthProvider.credentialFromResult(result)
  //                 console.warn("*************************");
  //                 console.warn(result);
  //                 console.warn("*************************");
  //                 await this.$apollo
  //                     .mutate({
  //                         mutation: SOCIAL_AUTH_GOOGLE,
  //                         variables: {
  //                             addr: this.$store.state.core.addr,
  //                             input: result.user.accessToken,
  //                         },
  //                     })
  //                     .then(
  //                         ({
  //                             data: {
  //                                 socialMediaRegisterGoogle: { errors, user, credit, token },
  //                             },
  //                         }) => {
  //                             // onLogin(createProvider().defaultClient)
  //                             this.$store.commit("setUserData", user);
  //                             credit.flirtonsAvailable === true
  //                                 ? this.$store.commit("setFlirtonsAvailable", true)
  //                                 : this.$store.commit("setFlirtonsAvailable", false);
  //                             credit
  //                                 ? //? this.$store.commit("setBalance", credit.balance)
  //                                 this.$store.commit(
  //                                     "setBalance",
  //                                     credit.balance + credit.flirtons
  //                                 )
  //                                 : null,
  //                                 this.$store.commit("setRandState", Math.random(100));
  //                             this.$store.commit("setFlirtTok", token);
  //                             // reset apollo client
  //                             // console.log(user, "user is logged in");
  //                             // window.location.assign("/")
  //                             if (user.profileComplete) {
  //                                 // this.$router.push("/find-matches");
  //                                 if (this.$route.query.to) {
  //                                     window.location.assign("/" + this.$route.query.to);
  //                                 } else {
  //                                     window.location.assign("/find-matches");
  //                                 }
  //                             } else {
  //                                 this.$router.push("/profile-setup");
  //                             }

  //                             console.error(errors);
  //                             console.warn(user);
  //                         }
  //                     );
  //             })
  //             .catch((err) => {
  //                 const errCode = err.code;
  //                 console.error(err, errCode);
  //             })
  //             .finally(() => {
  //                 this.loadingSocialAuth = false;
  //             });
  //     },
  //     socialLoginGoogle() {
  //         if (!this.acceptedTerms) {
  //             this.$notify(
  //                 {
  //                     group: "top",
  //                     title: "you need to agree with the terms before you continue",
  //                     text: "Auth Error",
  //                     type: "info", // info, warning
  //                 },
  //                 5000
  //             );
  //             return;
  //         }

  //         const auth = getAuth();
  //         const provider = new GoogleAuthProvider();
  //         this.loadingSocialAuth = true;
  //         signInWithPopup(auth, provider)
  //             .then(async (result) => {
  //                 // const credential = GoogleAuthProvider.credentialFromResult(result)
  //                 await this.$apollo
  //                     .mutate({
  //                         mutation: SOCIAL_AUTH_GOOGLE,
  //                         variables: {
  //                             addr: this.$store.state.core.addr,
  //                             input: result.user.accessToken,
  //                         },
  //                     })
  //                     .then(
  //                         ({
  //                             data: {
  //                                 socialMediaRegisterGoogle: { errors, user, credit, token },
  //                             },
  //                         }) => {
  //                             onLogin(createProvider().defaultClient);
  //                             this.$store.commit("setUserData", user);
  //                             credit.flirtonsAvailable === true
  //                                 ? this.$store.commit("setFlirtonsAvailable", true)
  //                                 : this.$store.commit("setFlirtonsAvailable", false);
  //                             credit
  //                                 ? //? this.$store.commit("setBalance", credit.balance)
  //                                 this.$store.commit(
  //                                     "setBalance",
  //                                     credit.balance + credit.flirtons
  //                                 )
  //                                 : null,
  //                                 this.$store.commit("setRandState", Math.random(100));
  //                             this.$store.commit("setFlirtTok", token);
  //                             // reset apollo client
  //                             // console.log(user, "user is logged in");
  //                             // window.location.assign("/")
  //                             if (user.profileComplete) {
  //                                 if (this.$route.query.to) {
  //                                     window.location.assign("/" + this.$route.query.to);
  //                                 } else {
  //                                     window.location.assign("/find-matches");
  //                                 }
  //                             } else {
  //                                 this.$router.push("/profile-setup");
  //                             }

  //                             console.error(errors);
  //                             console.warn(user);
  //                         }
  //                     );
  //             })
  //             .catch((err) => {
  //                 const errCode = err.code;
  //                 console.error(err, errCode);
  //             })
  //             .finally(() => {
  //                 this.loadingSocialAuth = false;
  //             });
  //     },
  //     doLogin() {
  //         if (!this.acceptedTerms) {
  //             this.$notify(
  //                 {
  //                     group: "top",
  //                     title: "you need to agree with the terms before you contine",
  //                     text: "Auth Error",
  //                     type: "info", // info, warning
  //                 },
  //                 5000
  //             );
  //             return;
  //         }
  //         if (!this.$v.$invalid) {
  //             this.loadingLogin = true;
  //             this.$apollo
  //                 .getClient()
  //                 .mutate({
  //                     mutation: USER_LOGIN,
  //                     // authErrs: [],
  //                     variables: {
  //                         addr: this.$store.state.core.addr,
  //                         usernameOrEmail: this.emailOrUname,
  //                         password: this.password,
  //                     },
  //                 })
  //                 .then(({ data: { login } }) => {
  //                     console.log(login, "What the heck");
  //                     const { user, credit, token } = login;
  //                     credit.flirtonsAvailable === true
  //                         ? this.$store.commit("setFlirtonsAvailable", true)
  //                         : this.$store.commit("setFlirtonsAvailable", false);
  //                     onLogin(createProvider().defaultClient);
  //                     this.$store.commit("setUserData", user);
  //                     //this.$store.commit("setBalance", credit.balance);
  //                     this.$store.commit("setBalance", credit.balance + credit.flirtons);
  //                     this.$store.commit("setRandState", Math.random(100));
  //                     this.$store.commit("setFlirtTok", token);
  //                     // reset apollo client
  //                     // console.log(user, "user is logged in");
  //                     // window.location.assign("/")
  //                     if (user.profileComplete) {
  //                         // this.$router.push("/find-matches");
  //                         if (this.$route.query.to) {
  //                             window.location.assign("/" + this.$route.query.to);
  //                         } else {
  //                             window.location.assign("/find-matches");
  //                         }
  //                     } else {
  //                         this.$router.push("/profile-setup");
  //                     }
  //                 })
  //                 .catch((e) => {
  //                     console.warn(Object.keys(e));
  //                     Object.keys(e).forEach((x) => {
  //                         console.warn(e[x]);
  //                     });
  //                     console.error(e);
  //                     this.authErr = "You have entered incorrect credentials";
  //                 })
  //                 .finally(() => {
  //                     this.loadingLogin = false;
  //                 });
  //         }
  //     },
  //     setUname(value) {
  //         this.emailOrUname = value;
  //         this.$v.emailOrUname.$touch();
  //     },
  //     setPass(value) {
  //         this.password = value;
  //         this.$v.password.$touch();
  //     },
  // },
  // validations: {
  //     emailOrUname: {
  //         required,
  //         minLength: minLength(4),
  //     },
  //     password: {
  //         required,
  //     },
  // },

  metaInfo() {
    return {
      title: "FlirtTool | Login | The finest global  dating website around. ",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool is the first dating community based on a special artificial intelligence (AI) system. Connect with local singles & start your personal online dating adventure!",
        },
        {
          property: "og:title",
          content:
            "FlirtTool | Online Dating | The finest global  dating website around.",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>
