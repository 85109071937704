<template>
    <div
      class="flex justify-center   col-span-3 mt-16 bordered sm:mx-5 lg:mx-0 lg:pl-5 xl:pl-24"
    ><template>
    <ul
      class="py-2 list-reset md:py-0"
      v-scroll-spy-active="{ class: 'customActive' }"
    >
      <li
        v-for="(item, idx) in sections"
        :key="idx"
        :class="{ customActive: item.link == $route.path }"
        class="
          py-1
          border-l-4 border-transparent
          md:my-2
          hover:text-red-500
          lg:hover:bg-transparent
          font-regular
        "
      >
        <router-link
          :to="item.link"
          class="
            block
            pl-4
            text-gray-700
            no-underline
            align-middle
            dark:text-gray-200 dark:hover:text-red-500
            hover:text-red-500
          "
        >
          <span
            class="
              w-full
              mb-1
              font-sans
              text-lg text-left text-gray-500
              uppercase
              shadow-sm
              btn
              md:text-sm
              dark:text-gray-200
              text-light
            "
            >{{ item.title }}</span
          >
          <!-- <a
                  @click="$router.push()"
              >
                  <span class="pb-1 pr-10 font-sans text-lg text-left md:pb-0">{{
                      item.title
                  }}</span>
              </a> -->
        </router-link>
      </li>
    </ul>
  </template>
      <!-- Content loader start -->
      <div class="lds-ripple mt-44" v-if="!me">
        <div></div>
        <div></div>
      </div>
      <!-- Content loader end -->
  
      <div
        v-else
        class="grid w-full max-w-6xl   grid-cols-1 lg:grid-cols-1 lg:mt-16 lg:mb-96 md:mx-20"
        style=""
      >
        <div class="col-span-3  mx-auto justify-center sm:mx-5 lg:mx-0">
          <div v-if="loadingProfile">
            <div class="h-56 mx-auto mt-5 bg-gray-500 rounded-md animate-pulse"></div>
            <div class="space-y-2">
              <div class="h-4 mt-3 bg-gray-500 rounded animate-pulse"></div>
            </div>
          </div>
  
          <div class="rounded-none   card border-0 dark:bg-gray-800" v-else>
            <div class="cursor-pointer card-body w-96 h-96 flex mx-auto  justify-center">
              <figure>
                <img :src="getURL(me.user.profilePic)" @click="profileClicked" class="h-80 w-96 object-cover" />
                <input @change="upload" ref="profileInput" type="file" class="hidden" />
              </figure>
              <button
                :style="
                  blendColor == '' ? '' : `background-color: ${blendColor};opacity: 0.7;`
                "
                :class="{
                  'text-white': lightOrDark(blendColor) == 'dark',
                  'text-gray-600': lightOrDark(blendColor) == 'light',
                }"
                class="bg-red-300 rounded-none btn hover:text-red-500"
                @click="uploadProfilePic"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
              
            </div>
            <!-- video section for those with video -->
            <div>
              <!-- This is an example component -->
              <div>
                <div
                  class="flex w-full max-w-sm mx-auto overflow-hidden bg-white dark:bg-gray-700 rounded-lg shadow-md"
                >
                  <div class="flex flex-col m-5" v-if="me.user.package_activity">
                    <div class="flex flex-row gap-20 mb-2">
                      <p
                        class="text-sm font-semi-bold font-sans dark:text-gray-50 text-left"
                      >
                        Profile Video
                      </p>
                      <input
                        @change="uploadv"
                        ref="videoInput"
                        type="file"
                        class="hidden"
                      />
  
                      <button
                        class="mt-4 btn dark:text-gray-50 dark:bg-gray-500 btn-sm"
                        @click="$refs.videoInput.click()"
                      >
                        upload
                      </button>
                    </div>
                    <div class="relative">
                      <video
                        class="w-screen"
                        :key="getURL(me.user.coverVideo)"
                        ref="videoPlayer"
                        :autoplay="false"
                        controls
                      >
                        <source
                          class="w-30 h-30"
                          :src="getURL(me.user.coverVideo)"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>
        
    
              </div>
            </div>
            <!-- end of video section -->
          </div>
        </div>
        <!-- send section start -->
        <div class="col-span-7 -mt-5 sm:mx-5 lg:mx-0 md:mx-5">
          <div class="col-span-3 bordered dark:bg-gray-800">
            <!-- profile name and basic section -->
            
            <!-- basic profile info section end -->
           
            <!-- Profile pics section -->
            <div class="flex justify-center mt-5 ...">
              <button
                class="mx-3 mt-5 mb-2 btn btn-lg font-sans text-white hover:bg-red-300 bg-red-500 dark:text-gray-200"
                @click="$refs.multPro.click()"
              >
                Add MORE Pics
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 mx-2 mb-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
              <input
                @change="multipleUpload"
                multiple
                class="hidden"
                ref="multPro"
                type="file"
              />
            </div>
            <div class="h-full mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800">
              <div
                class="overflow-hidden bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-700 lg:aspect-none lg:h-full"
              >
                <figure>
                  <img
                    class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem"
                    v-for="(pic, idx) in me.user.photos"
                    :key="idx"
                    @click="slideClicked(idx)"
                    :src="getURL(pic.image)"
                    :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                    style="width: 150px; height: 150px"
                  />
                  <!-- src="https://picsum.photos/id/1005/400/250"  -->
                </figure>
              </div>
            </div>
  
            <!-- user profile looks -->
        
            <!-- user profile looks -->
  
            <!-- basic info card start -->
            
            <!-- basic info card end -->
  
            <!-- lifeStyle -->
            
  
            <!-- lifeStyle -->
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { getURL, imageAverageColor } from "../../../utils/helpers";
  import CoolLightBox from "vue-cool-lightbox";
  import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
  import settingsSelectors from '../../../components/settingsSelectors.vue';
  // import GeneralSettings from "../../../components/generalSettings.vue";
  import { DEFAULT_SETTINGS } from '../../../queries/auth';
  import {
    LOGGEDIN_DATA,
    MULTIPLE_UPLOAD,
    PROFILE_PIC_UPDATE,
    UPDATE_PROFILE_COVER_VIDEO,
  } from "../../../queries/auth";
  import { lightOrDark } from "../../../utils/helpers";
  export default {
    data: () => ({
      me: null,
      items: [],
      index: null,
      blendColor: "",
      allUserPics: [],
      loadingProfile: true,
      loadingContent: true,
      defaultSettings: {}
    }),
    
    apollo: {
      me: {
        query: LOGGEDIN_DATA,
        variables() {
          return {
            addr: this.$store.state.core.addr,
          };
        },
        fetchPolicy: "network-only",
        result({ data: { me } }) {
          // console.clear()
          // console.log(data)
          this.loadingProfile = false;
          return me;
        },
      },
    },
    components: { CoolLightBox , settingsSelectors,DEFAULT_SETTINGS },
    created() {
      this.$apollo.queries.me.refetch().finally(() => {
        this.loadingContent = false;
      });
      if (this.me) {
        const allPics = this.me.user.photos;
        allPics.forEach((e) => {
          this.allUserPics.push(getURL(e.image));
        });
        imageAverageColor(getURL(this.me.user.profilePic)).then((data) => {
          const imgData = data;
          this.blendColor = `rgba(${imgData[0]},${imgData[1]},${imgData[2]})`;
        });
      }
    },
    methods: {
      getURL,
      fetchProfileData() {
        this.$apollo.query({
          query: LOGGEDIN_DATA,DEFAULT_SETTINGS,
              }).then(({ data }) => {
                  this.defaultSettings = data.userSettingsData.user;
              }).catch(e => {
                  console.error(e)
              })
  
        
      },
      lightOrDark,
      slideClicked(idx) {
        console.clear();
        console.log(idx, "IDX");
        this.index = idx;
        console.log(this.allUserPics, "Dawg");
        this.items = this.allUserPics.filter((obj) => {
          return obj.unsafe !== true;
        });
        this.items = this.me.user.photos.map((e) => getURL(e.image));
        console.warn(this.me.user.photos, "items");
        // this.items = [
        // 	"https://picsum.photos/id/1005/400/250",
        // 	"https://picsum.photos/id/1005/400/250",
        // 	"https://picsum.photos/id/1005/400/250",
        // 	"https://picsum.photos/id/1005/400/250",
        // ];
      },
      profileClicked() {
        this.items = [getURL(this.me.user.profilePic)];
        this.index = 0;
      },
      uploadProfilePic() {
        this.$refs.profileInput.click();
        console.log();
      },
      // files positional arg
      uploadv({ target: { files = [] } }) {
        if (!files.length) {
          return;
        }
        this.$apollo
          .mutate({
            mutation: UPDATE_PROFILE_COVER_VIDEO,
            variables: {
              file: files[0],
            },
          })
          .then(({ data }) => {
            this.me.user.coverVideo = data.updateProfileCoverVideo;
          });
      },
      // Handle profile pic upload
      upload({ target: { files = [] } }) {
        if (!files.length) {
          return;
        }
        this.$apollo
          .mutate({
            mutation: PROFILE_PIC_UPDATE,
            variables: {
              file: files[0],
            },
          })
          .then(({ data }) => {
            const newProfile = data.updateProfilePic;
  
            let user = this.$store.state.core.user;
            user.profilePic = newProfile;
            this.$store.commit("setUserData", user);
            this.me.user.profilePic = newProfile;
            imageAverageColor(getURL(newProfile)).then((data) => {
              const imgData = data;
              this.blendColor = `rgba(${imgData[0]},${imgData[1]},${imgData[2]})`;
            });
            console.warn(data);
          });
      },
      multipleUpload({ target: { files = [] } }) {
        var reader = new FileReader();
        if (!files.length) {
          return;
        }
        reader.addEventListener(
          "load",
          function () {
            var avatarImg = new Image();
            var src = reader.result;
            avatarImg.src = src;
            document.getElementById("dataUrl").innerText = src;
            avatarImg.onload = function () {
              var c = document.getElementById("myCanvas");
              var ctx = c.getContext("2d");
              ctx.canvas.width = avatarImg.width;
              ctx.canvas.height = avatarImg.height;
              ctx.drawImage(avatarImg, 0, 0);
            };
          },
          false
        );
        files.forEach((fl) => {
          const dataUrl = reader.readAsDataURL(fl);
          this.me.user.photos.push({
            unsafe: false,
            image: dataUrl,
            newx: true,
          });
        });
        this.$apollo
          .mutate({
            mutation: MULTIPLE_UPLOAD,
            variables: {
              files: files,
            },
          })
          .then(({ data }) => {
            this.$apollo.queries.me.refetch();
            // this.me.user.photos = data.addProfilePics;
            console.warn(data);
          });
      },
    },
  };
  </script>
  <style scoped>
  .wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 250px;
    white-space: nowrap;
  }
  .elem {
    display: inline-block;
    object-fit: cover;
    width: 40%;
    height: 200px;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: rgba(128, 128, 128, 0.418);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.418);
  }
  .profileImg {
    position: relative;
  }
  .profileEditbtn {
    position: absolute;
    width: 80px;
    opacity: 0.8;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-left: -50px; /*half of the width */
    margin-top: -20px; /*half of the height */
  }
  </style>
  