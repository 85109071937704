import { render, staticRenderFns } from "./YellowsideHomeSelector.vue?vue&type=template&id=80068b90&scoped=true&"
import script from "./YellowsideHomeSelector.vue?vue&type=script&lang=js&"
export * from "./YellowsideHomeSelector.vue?vue&type=script&lang=js&"
import style0 from "./YellowsideHomeSelector.vue?vue&type=style&index=0&lang=css&"
import style2 from "./YellowsideHomeSelector.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80068b90",
  null
  
)

export default component.exports