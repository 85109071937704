<!-- <template>
  <div class="relative h-screen"  >
    <img src="@/assets/Prelanding/prelanding.jpg" class="absolute inset-0 object-cover w-full h-screen" alt="" />
    <div class="relative bg-gray-900 bg-opacity-0 h-screen py-24">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-col items-center justify-between xl:flex-row">
          <div class="w-full max-w-xl  align-bottom lg:mt-10 h- xl:mb-0 xl:pr-16 xl:w-7/12 ">
           <router-link to="/home">
          <img src="@/assets/Prelanding/Package.png" alt="" class="mt-54  transform transition duration-500 hover:scale-110 "></router-link>
          </div>
          <div class="w-full max-w-xl xl:px-8 xl:w-5/12 align-bottom">
             <div class=" rounded  p-7 sm:p-10 align-bottom">
              <router-link to="/home">
            <button class="btn bg-white text-pink-600 hover:text-white border-0 text-lg -mt-10 lg:text-3xl lg:mt-80 h sm:text-sm  transform transition duration-500 hover:scale-110 hover:bg-pink-500 animate-pulse align-bottom">Enter Flirttool</button>
          </router-link>
            </div>
          </div>
        </div>
      </div>
  

    </div>
  </div>
</template> -->
<template>
  <div>
    <popup :showing="openPop" @close="openPop = false">
    <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
        <iframe src="https://www.youtube.com/embed/VI4iURFwJ3Y" frameborder="0" allowfullscreen
          class="absolute top-0 left-0 w-full h-full"></iframe>
      </div>
    </popup>

    <!--hero banner-->
    <div class="flex overflow-hidden hidden lg:block xl:block 2xl:block bg-white pt-0">
      <div class="bg-gray-900 opacity-100 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <!--NAV-->
      <div>

      </div>
      <!--NAV-->
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto">
        <main>

          <div class="pt-0 px-0">
            <!--here-->
            <section class="relative mt-0 bg-white dark:bg-pink-200 md:h-screen ">
              <div class="mb-0">
                <div>
                  <div class="relative" style="object-fit: cover ; width: 100%; height: 695px;">
                    <!-- <img :src="require('@/assets/perfect-morning-love-man-with-beard-pointing-lady4.jpg')"
                          class="absolute inset-0 justify-center object-cover w-full h-full sm:" alt="" /> -->
                    <imageslider class="absolute inset-0 justify-center object-cover w-full h-full " />

                    <!--Navigation -->
                    <!-- nav starts here -->
                    <div
                      class="bg-red-900 fixed shadow-md  z-50 w-full px-5 py-2 flex justify-between items-center bg-opacity-30">

                      <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl 0 md:px-24 lg:px-8 0 ">
                        <div class="relative flex items-center justify-between">
                          <div class="flex items-center"><img src="@/assets/logo-light.png" class="h-16" alt="">
                            <a href="/" aria-label="Flirttool" title="Flirttool" class="inline-flex items-center mr-8">
                              <div class="flex justify-center space-x-2">
                                <!-- Facebook -->

                                <a traget="_blank" href="https://www.facebook.com/flirttool/"><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-5 h-5"
                                    style="color: #ffffff">
                                    <path fill="currentColor"
                                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                  </svg></a>

                                <!-- Instagram -->
                                <a traget="_blank" href="https://www.instagram.com/flirttool/"><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-5 h-5"
                                    style="color: #ffffff">
                                    <path fill="currentColor"
                                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                  </svg></a>

                                <!-- Youtube -->
                              <a traget="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA/"><svg
                                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-5 h-5"
                                  style="color: #ffffff">
                                  <path fill="currentColor"
                                    d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                                </svg></a>

                              <!-- TikTok -->

                                <!-- Twitter -->
                                <a traget="_blank" href="https://twitter.com/flirttool/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-5 h-5"
                                  style="color: #ffffff">
                                  <path fill="currentColor"
                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                                </svg></a>
                              <ul class="flex items-center hidden text-white space-x-8 lg:flex">
                                <li>
                                  <a href="https://flirttool.blogspot.com/" target="_blank" aria-label="Flirttool News"
                                    title="Flirttool News"
                                    class="text-sm font-sans tracking-wide text-gray-50 transition-colors duration-200 px-8 hover:text-pink-600">FlirtTool
                                    News</a>
                                </li>
                                <!-- <li>
                                  <a href="https://flirttoollovestories.blogspot.com/" aria-label="" title=""
                                    class="text-sm tracking-wide text-gray-50 transition-colors duration-200 font-sans hover:text-pink-600">Love
                                    Stories</a>
                                </li>
                                <li>
                                  <a href="/Ftlive" aria-label="Live" title="live"
                                    class="text-sm tracking-wide text-gray-50 transition-colors duration-200 font-sans">FlirtTool
                                    Live</a>
                                </li> -->

                                <!--<li>
                      <a
                        href="/"
                        aria-label="About us"
                        title="About us"
                        class="
                          text-sm
                          tracking-wide
                          text-gray-50
                          transition-colors
                          duration-200
                          font-sans
                        "
                        >Media Center</a
                      >
                    </li>
                    <li>
                      <a
                        href="/"
                        aria-label="About us"
                        title="About us"
                        class="
                          text-sm
                          tracking-wide
                              text-gray-50
                              transition-colors
                              duration-200
                              font-sans
                            "
                            >Speed Dating
                          </a>
                        </li>
                        <li>
                          <a
                            href="/"
                            aria-label="About us"
                            title="About us"
                            class="
                              text-sm
                              tracking-wide
                              text-gray-50
                              transition-colors
                              duration-200
                              font-sans
                            "
                            >Promotion</a
                          >
                        </li>-->
                                </ul>
                              </div>
                          </a>
                        </div>
                        <ul class="flex items-center hidden space-x-8 lg:flex">
                          <li>
                            <img src="@/assets/infinity_logo.png"
                              class="h-full w-24 inline-flex items-center justify-center" />
                            <hr />
                          </li>
                          <li>
                            <span aria-label="Sign in" title="Sign in"
                              class="text-sm font-sans tracking-wide text-pink-50 font-bold uppercase">Already a
                              member?</span>
                          </li>
                          <router-link to="/login">
                            <li>
                              <a href="/login"
                                class="inline-flex font-sans items-center justify-center h-8 px-6 font-medium tracking-wide text-white transition rounded shadow-md bg-pink-600 focus:shadow-outline focus:outline-none hover:text-pink-600 ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300"
                                aria-label="Login" title="login">
                                Login
                              </a>
                            </li>
                          </router-link>
                          <li>
                            <!-- <button
                  aria-label="Open Menu"
                  title="Open Menu"
                  class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                >
                  
                  <li>
          <div class="dropdown dropdown-end">
            <div
              tabindex="0"
              class="m-1 btn bg-white hover:bg-red-400 hover:text-red-400"
            >
             <svg class="w-5 text-pink-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                    ></path>
                  </svg>
            </div>
            <ul
              tabindex="0"
              class="p-2 shadow menu dropdown-content rounded-box w-52 bg-white"
                >
                  <router-link to="/"
                    ><li>
                      <a href="/">US</a>
                    </li></router-link
                  >
                  <router-link to="/nz"
                    ><li>
                      <a href="/nz">NZ</a>
                </li></router-link
              >
              <router-link to="/uk"
                ><li>
                  <a href="uk">UK</a>
                </li></router-link
              ><router-link to="/au"
                ><li>
                  <a href="/au">AU</a>
                </li></router-link
              >
            </ul>
          </div>
            </li>
                    </button> -->
                            </li>
                        </ul>

                        <!-- Mobile menu -->
                        <div class="lg:hidden">
                          <router-link to="/login"><button aria-label="Open Menu" title="Open Menu"
                              class="p-2 -mr-1 rounded bg-pink-50 w-32 font-bold text-pink-500 focus:outline-none focus:shadow-outline hover:text-pink-600 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300">
                              Login
                              <!--<svg class="w-5 text-pink-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  ></path>
                </svg>-->
                            </button></router-link>

                          <!-- <button aria-label="Open Menu" title="Open Menu"
                              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50">

                              <li>
                                <div class="dropdown dropdown-end">
                                  <div tabindex="0" class="m-1 btn bg-white hover:bg-red-400 hover:text-red-400">
                                    <svg class="w-5 h-5 text-pink-600" viewBox="0 0 24 24">
                                      <path fill="currentColor"
                                        d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z">
                                      </path>
                                      <path fill="currentColor"
                                        d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"></path>
                                      <path fill="currentColor"
                                        d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z">
                                          </path>
                                        </svg>
                                      </div>
                                      <ul tabindex="0" class="p-2 shadow menu dropdown-content rounded-box w-52 bg-white">
                                        <li>
                                          <a class="border-2 text-pink-500" target="_blank"
                                            href="https://www.flirttool-lovestories.com/">Love Stories</a>
                                        </li>

                                        <li>
                                          <a class="border-2 text-pink-500" target="_blank"
                                            href="https://www.flirttool-news.com/">FlirtTool News</a>
                                        </li>
                                        <router-link to="/Ftlive">
                                          <li>
                                            <a class="border-2 text-pink-500" target="_blank">Live</a>
                                          </li>
                                        </router-link>


                                      </ul>
                                    </div>
                                  </li>
                                </button> -->
                          </div>
                        </div>
                      </div>



                    </div>
                    <!-- nav ends here -->

                    <div class="relative bg-gray-800 bg-opacity-0 h-full  " style="height: 690; ">
                      <!--test start here--><!--test ends here-->
                      <template>
                        <div
                          class="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-">
                          <div class="flex flex-col justify-between lg:flex-row   mt-48">
                          <div class="mb-5 lg:max-w-lg lg:pr-5 lg:mb-0">
                            <div class="max-w-xl mb-16 justify-center">
                              <p
                                class="max-w-lg text-center italic mb-6 font-sans text-6xl font-bold tracking-tight text-gray-50 sm:text-5xl lg:text-7xl   sm:leading-none">
                                Join
                                <span class="text-pink-500 italic stroke-white">free </span> Today<br
                                  class="hidden md:block" />
                              </p>


                            </div>
                            <div class="justify-center items-center  sm:flex sm:space-y-0  mb-5">

                              <router-link to="/register"><button
                                  class="btn rounded-full hover:bg-pink-400 hover:text-white px-10 font-bold font-sans bg-white text-gray-800 border-0 ">
                                  Create an account
                                  </button></router-link>

                              </div>
                              <p class="text-gray-50 font-sans font-bold mb-6 text-xl text-center">
                                or get the app
                              </p>
                              <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mb-36">
                                <!-- <a href="#"
                                    class="hover:text-white w-full cursor-not-allowed sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                                    <svg class="mr-3 w-7 h-7 hover:text-white" aria-hidden="true" focusable="false"
                                      data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512">
                                      <path fill="currentColor"
                                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                                      </path>
                                    </svg>

                                    <div class="text-left hover:text-white">
                                      <div class="mb-1 text-xs">Download on the</div>
                                      <div class="-mt-1 font-sans text-sm font-semibold">
                                      App Store
                                  </div>
                                </div>
                              </a> -->
                              <a target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.flirttooldating.app"
                                class="hover:text-white w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                                style="width: 300px;">
                                  <svg class="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab"
                                    data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                  <path fill="currentColor"
                                    d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z">
                                  </path>
                                </svg>
                                <div class="text-left">
                                  <div class="mb-1 text-xs">Get in on</div>
                                  <div class="-mt-1 font-sans text-sm font-semibold">
                                    Google Play
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div class="relative overflow-hidden">
                              <!-- <div
                                  class="absolute p-5 top-0 w-36 h-36 border-2 border-gray-50 left-auto float-right rounded-full text-xl inset-x-0 bg-pink-600 text-white text-center leading-4 font-sans mb-5"
                                >
                                  <p class="text-xs font-sans">
                                    Flirttool<br /><span class="text-2xl">FREE</span
                                    ><br />Basic Package<br />Join Now
                                  </p>
                                </div> -->
                              <div
                                class="px-5 pt-6 mt-6 pb-5 text-center opacity-0 border border-gray-300 rounded w-11/12 bg-white lg:w-11/12">
                                <!-- <form @submit="formSubmitted">
                                    
                                    <div class="mb-1 sm:mb-2">
                                      <div class="mb-1 sm:mb-2">
                                        
                                        <img
                                          src="@/assets/logo-light.png"
                                          class="h-full w-36 inline-flex items-center justify-center"
                                        />
                                        <hr />
                                      </div>

                                      <div
                                        class="flex justify-center h-full overflow-hidden lg:w-full xl:w-1/2 lg:absolute lg:justify-start lg:bottom-0 lg:right-0 lg:items-end"
                                      ></div>
                                      <div class="gird grid-cols-2">
                                        <div>
                                          <input
                                            v-model="firstName"
                                            placeholder="First name"
                                            required
                                            type="text"
                                            class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                            id="firstName"
                                            name="firstName"
                                          />
                                        </div>

                                        <input
                                          v-model="lastName"
                                          placeholder="Last name"
                                          required
                                          type="text"
                                          class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                          id="firstName"
                                          name="firstName"
                                        />
                                      </div>
                                    </div>
                                    <div class="mb-1 sm:mb-2">
                                      <input
                                        pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                                        v-model="email"
                                        placeholder="Your e-mail Address"
                                        required
                                        type="text"
                                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                        id="email"
                                        name="email"
                                      />
                                    </div>
                                    <div class="mb-1 sm:mb-2">
                                      <input
                                        v-model="username"
                                        placeholder="Create Username"
                                        required
                                        type="text"
                                        class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                        id="text"
                                        name="text"
                                      />
                                    </div>
                                    <div class="mb-1 sm:mb-2">
                                      <input
                                            v-model="password"
                                            placeholder="Password"
                                            required=""
                                            type="text"
                                            class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                            id="text"
                                            name="text"
                                          />
                                        </div>
                                        <div class="mt-4 mb-2 sm:mb-4">
                                          <button
                                            type="submit"
                                            class="inline-flex items-center justify-center font-sans w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-pink-600 focus:shadow-outline focus:outline-none"
                                            :class="{
                                              'cursor-not-allowed': loadingReg,
                                            }"
                                          >
                                            <span v-if="loadingReg" class="visually-hidden"
                                              >Loading...</span
                                            >
                                            <span v-else> Register Now </span>
                                          </button>
                                        </div>
                                      </form> -->
                                </div>
                              </div>
                            <hr class="mb-6 border-gray-300" />
                          </div>
                          <div class="w-full max-w-xl xl:px-1 xl:w-5/12 inset-y-0 right-0 mt-24  lg:block hidden ">
                            <div class="flex lg:justify-end md:justify-center sm:justify-center     object-right ">

                              <!--box-->
                              <div class="card w-72 h-28 bg-white shadow-xl opacity-90 ">
                                <div class="card-body -mt-10 ">
                                  <p class="font-sans">
                                    <span class="text-lg font-sans font-bold">Basic Package</span>
                                    <br /><span class="text-xs mb-5"> 2 hours free chat experience</span><router-link
                                      to="/login"><button
                                        class="btn btn-primary btn-sm bg-pink-600 w-36  hover:bg-pink-300 shadow-lg font-sans border-0 hover:pink">
                                        Try it for Free
                                      </button></router-link>
                                  </p>
                                    <div class="card-actions justify-center ">

                                  </div>
                                </div>
                              </div>
                              <br />

                              <!-- <p class="px-4 pt-12 text-3xl text-white font-san">
                                  FlirtTool Guide
                                </p>
                                    <div
                                      @click="playBtn"
                                      class="flex mt-5 items-center justify-center w-20 h-20 text-white transition duration-300 transform rounded-full shadow-md bg-pink-600 hover:bg-pink-400 hover:scale-110 cursor-pointer bottom-right"
                                    >
                                      <svg
                                        class="w-16"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z"
                                        ></path>
                                      </svg>
                                    </div> -->
                                <!--<p
                                      class="
                                        px-4
                                        pt-12
                                        text-3xl text-white
                                        font-black
                                      "
                                    >
                                      FlirtTool Guide
                                    </p>-->
                              </div>
                              <div class="flex lg:justify-end md:w-full md:justify-center sm:justify-center mt-1  ">
                                <!--box-->
                                <div class="card  w-72 h-28 bg-white shadow-xl opacity-90 ">
                                  <div class="card-body -mt-10">
                                    <p class="font-sans">
                                    <span class="text-lg font-sans font-bold ">FlirtTool for 1 Day
                                      </span>
                                      <br /><span class="text-xs mb-5"> Real Chat Flatrate ONLY $2</span>
                                    </p><router-link to="/login"><button
                                        class="btn btn-primary btn-sm border-0 animate-pluse delay-75 hover:bg-pink-300 hover:border-white hover:text-white bg-pink-600 border-gray-500 font-sans w-36 shadow-lg text-pink-50">
                                        Grab it
                                      </button></router-link>
                                    <div class="card-actions justify-center">

                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>

                        </div>
                      </template>
                      <div class="lg:block hidden">
                        <div
                          class="flex  lg:justify-end md:w-full lg:visible md:invisible sm:invisible md:justify-end sm:justify-end -mt-96  bottom-0 right-0 ">

                          <!-- <img
                              src="@/assets/modlogin/label.png" class="bottom-0 left-0 h-36  lg:h-72 sm:h-36 md:h-36 " alt=""> -->

                        </div>
                      </div>



                    </div>
                  </div>
                  <!-- ads here -->
                  <Adsense data-ad-client="ca-pub-2781511921274321" data-ad-slot="4412224045">
                  </Adsense>
                  <!-- ads here -->
                  <div class="bg-pink-600 relative " style="object-fit: cover ; width: 100%; height: 290px;">
                    <div
                      class="px-4 py-8 mx-auto bg-opacity-0   sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-5 lg:py-5">
                      <img src="@/assets/easterimages/5.jpg" alt=""
                        class="absolute inset-0 z-0 justify-center object-cover w-full h-full">
                      <p
                        class="text-2xl  my-section transform translate-x-4 lg:text-6xl md:text-4xl sm:text-3xl font-sans text-pink-50">
                        REGISTER FOR FREE AND GRAB<br>
                        YOUR EASTER GIFT NOW!<br>

                      </p>
                      <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div class="flex mb-8 sm:text-center sm:mb-0">
                          <div class="max-w-xl mb-1 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                            <h2
                              class="max-w-lg mb-1 mt-1 w-full font-sans text-4xl italic leading-none tracking-tight text-white sm:text-4xl">
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template>
                    <div
                      class="hidden md:block px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                      <div class="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4 lg:max-w-screen-lg">
                        <div>
                          <div class="relative pb-56 mb-4 rounded-full avatar w-full h-32 shadow ">
                            <img class="absolute object-cover w-full h-full rounded-full"
                              src="https://funnyflirts.net/media/upload/f_pics/52587307_1.jpg" alt="Person" />
                          </div>
                          <div class="flex flex-col sm:text-center -mt-7">
                            <p class="text-lg font-semi-bold font-sans"> GabbyBaby316 </p>
                            <p class="mb-5 text-xs text-gray-800 font-sans">300+ Matching Profiles</p>
                            <div class="flex items-center space-x-3 sm:justify-center">
                              <div class="justify-center">
                                <router-link to="/p/GabbyBaby316?af=8C9E256D"><button
                                    href="https://funnyflirts.net/p/GabbyBaby316?af=8C9E256D"
                                    class="btn btn-primary btn-sm hover:bg-pink-300 hover:border-white hover:text-white bg-pink-50 border-2 border-gray-500 font-sans w-36 shadow-lg text-pink-500">
                                    Message
                                  </button></router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="relative pb-56 mb-4 rounded-full shadow ">
                            <img class="absolute object-cover w-full h-full rounded-full "
                              src="https://funnyflirts.net/media/upload/f_pics/52810947_1.jpg" alt="Person" />
                          </div>
                          <div class="flex flex-col sm:text-center  -mt-7">

                            <p class="text-lg font-semi-bold font-sans">SabrinaH24</p>
                            <p class="mb-5 text-xs text-gray-800 font-sans">168+ Matching Profiles</p>
                            <div class="flex items-center space-x-3 sm:justify-center">
                              <div class="justify-center">
                                <router-link to="/p/SabrinaH24?af=8C9E256D"><button
                                    class="btn btn-primary btn-sm hover:bg-pink-300 hover:border-white hover:text-white bg-pink-50 border-2 border-gray-500 font-sans w-36 shadow-lg text-pink-500">
                                    Message
                                  </button></router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="relative pb-56 mb-4 rounded-full shadow ">
                            <img class="absolute object-cover w-full h-full rounded-full items-center"
                              src="https://funnyflirts.net/media/upload/trans_pics/9830777_0.jpg" alt="Person" />
                          </div>
                          <div class="flex flex-col sm:text-center  -mt-7">
                            <p class="text-lg font-semi-bold font-sans">DungonDad</p>
                            <p class="mb-5 text-xs text-gray-800 font-sans">101+ Matching Profiles</p>
                            <div class="flex items-center space-x-3 sm:justify-center">
                              <div class="justify-center">
                                <router-link to="/p/DungonDad?=8C9E256D"><button
                                    class="btn btn-primary btn-sm hover:bg-pink-300 hover:border-white hover:text-white bg-pink-50 border-2 border-gray-500 font-sans w-36 shadow-lg text-pink-500">
                                    Message
                                  </button></router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div>
                        <div class="relative pb-56 mb-4 rounded-full shadow ">
                          <img class="absolute object-cover w-full h-full rounded-full"
                            src="https://funnyflirts.net/media/upload/f_pics/52779689_1.jpg" />
                        </div>
                        <div class="flex flex-col sm:text-center  -mt-7">
                          <p class="text-lg font-semi-bold font-sans">Jessikacay99</p>
                          <p class="mb-5 text-xs text-gray-800 font-sans">164+ Matching Profiles</p>
                          <div class="flex items-center space-x-3 sm:justify-center">
                            <div class="justify-center">
                              <router-link to="/p/jessikacay99?=8C9E256D"><button
                                  class="btn btn-primary btn-sm hover:bg-pink-300 hover:border-white hover:text-white bg-pink-50 border-2 border-gray-500 font-sans w-36 shadow-lg text-pink-500">
                                  Message
                                </button></router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <!--new download section-->
                <!-- <div class="relative h-full">
                    <img
                      src="@/assets/Frame-4.jpg"
                      class="absolute inset-0 object-cover w-full h-full"
                      alt=""
                    />
                    <div
                      class="relative bg-gradient-to-r from-pink-200 bg-opacity-30 h-full"
                    >
                      <div
                        class="px-4 py-36 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
                      >
                        <div
                          class="flex flex-col items-center justify-between xl:flex-row"
                        >
                          <div
                            class="w-full max-w-xl xl:mb-0 xl:pr-16 xl:w-7/12 mt-36 mb-24"
                          >
                            <h1
                              class="inline-block text-gray-800 text-2xl sm:text-4xl uppercase italic"
                            >
                              Download Now
                              <br class="hidden lg:inline-block" />
                            </h1>
                            <p class="mb-8 font-sans leading-relaxed font-semi-bold">
                              THE WORLD’S FIRST AI-BASED DATING COMMUNITY APP CREATED
                              <br />
                              <span class="italic py-5 font-bold"
                                >WITH INFLUENCERS WORLDWIDE!</span
                              >
                              <br />
                              <span class="italic font-bold"> </span>
                            </p>
                            <div class="flex justify-center">
                              <div
                                class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mb-36"
                              >
                                <a
                                  href="#"
                                  class="hover:text-white w-full cursor-not-allowed sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                                >
                                  <svg
                                    class="mr-3 w-7 h-7 hover:text-white"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="apple"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                                    ></path>
                                  </svg>

                                  <div class="text-left hover:text-white">
                                    <div class="mb-1 text-xs">Download on the</div>
                                    <div class="-mt-1 font-sans text-sm font-semibold">
                                      App Store
                                    </div>
                                  </div>
                                </a>
                                <a
                                  target="_blank"
                                  href="https://play.google.com/store/apps/details?id=com.flirttooldating.app"
                                  class="hover:text-white w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                                >
                                  <svg
                                    class="mr-3 w-7 h-7"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="google-play"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                          d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                                        ></path>
                                  </svg>
                                  <div class="text-left">
                                    <div class="mb-1 text-xs">Get in on</div>
                                    <div class="-mt-1 font-sans text-sm font-semibold">
                                      Google Play
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
                            <img
                              src="@/assets/Rectangle.png"
                              class="object-cover object-top w-36 h-36 mx-auto lg:h-auto xl:mr-24 md:max-w-sm"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                <!--end of new download section-->
                <!-- <div class="bg-gradient-to-r from-pink-200 via-pink-400 to-pink-200">
                    <div
                      class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
                    >
                      <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div class="flex flex-col mb-16 sm:text-center sm:mb-0">
                          <a href="/" class="mb-6 sm:mx-auto"> </a>
                          <div
                            class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12"
                          >
                            <div class="max-w-screen-sm sm:text-center sm:mx-auto">
                              <a
                                href="/"
                                aria-label="View"
                                class="inline-block mb-5 rounded-full sm:mx-auto"
                              >
                              </a>
                              <div class="">
                                <div
                                  class="flex items-center justify-center font-medium inline-block sm:text-3xl uppercase italic text-xl text-center tracking-wide font-bold text-gray-50"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-24 w-24 text-white text-center"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    />
                                  </svg>
                                </div>
                                <span
                                  class="font-medium inline-block sm:text-3xl uppercase italic text-xl text-center tracking-wide font-bold text-gray-50"
                                  >Subscribe To our newsletter</span
                                >
                                <p class="mt-1 text-sm text-gray-50">
                                      Stay upto date with our latest news and updates
                                    </p>
                                    <form class="flex flex-col mt-4 md:flex-row">
                                      <input
                                    placeholder="Email"
                                    required=""
                                    type="text"
                                    class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                  />
                                  <button
                                    type="submit"
                                    class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-gra text-white transition duration-200 rounded shadow-md bg-gray-900 focus:shadow-outline focus:outline-none"
                                  >
                                    Subscribe
                                  </button>
                                </form>
                              </div>
                              <hr class="w-full my-8 border-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                <!--end of new download section-->
                <!--news letter-->

                <!--<div class="px-4 py-36 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-gradient-to-r  from-pink-200 via-pink-400 to-pink-200">
  <div class="max-w-screen-sm sm:text-center sm:mx-auto ">
    <a href="/" aria-label="View" class="inline-block mb-5 rounded-full sm:mx-auto">
      <div class="flex items-center justify-center w-16 h-16 mb-4 ">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-36 w-36 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
</svg>
      </div>
    </a>
    <div class="">
        <span class="text-base font-medium inline-block
                           
                            sm:text-3xl
                                uppercase
                                italic text-xl text-center tracking-wide font-bold text-gray-50">Subscribe To our newsletter</span>
            <p class="mt-1 text-sm text-gray-50">
              Stay upto date with our latest news and updates
            </p>
        <form class="flex flex-col mt-4 md:flex-row">
          <input
            placeholder="Email"
            required=""
            type="text"
            class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
          />
          <button
            type="submit"
            class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-gra text-white transition duration-200 rounded shadow-md  bg-gray-900 focus:shadow-outline focus:outline-none"
          >
            Subscribe 
          </button>
        </form>
        
      </div>
    <hr class="w-full my-8 border-gray-300" />
  </div>
</div>-->

                <!--news letter-->

                <!--Download App nOw-->
                <!--<section
                    class="text-gray-600 body-font transition-opacity"
                    id="download"
                    
                  >
                    <div
                      class="
                        container
                        mx-auto
                        flex
                        px-5
                        py-24
                        md:flex-row
                        flex-col
                        items-center
                      "
                    >
                      <div
                        class="
                          lg:flex-grow
                          md:w-1/2
                          lg:pr-24
                          md:pr-16
                          flex flex-col
                          md:items-start md:text-left
                          mb-16
                          md:mb-0
                          items-center
                          text-center
                        "
                      >
                        <h1
                          class="
                            inline-block
                            text-gray-800 text-2xl
                            sm:text-4xl
                            uppercase
                            italic
                          "
                        >
                          Download Now
                          <br class="hidden lg:inline-block" />
                        </h1>
                        <p
                          class="mb-8 font-sans leading-relaxed font-semi-bold"
                        >
                          THE WORLD’S FIRST AI-BASED DATING COMMUNITY APP<br />
                          <span class="italic py-5 font-bold"
                            >CREATE FROM INFLUENCERS</span
                          >
                          <br />
                          <span class="italic font-bold"> WORLDWIDE!</span>
                        </p><br>
                        
                        <div class="flex justify-center">
                          <div
                            class="
                              justify-center
                              items-center
                              space-y-4
                              sm:flex sm:space-y-0 sm:space-x-4
                            "
                          >
                            <a
                              href="#"
                              class="
                                hover:text-white
                                w-full
                                cursor-not-allowed
                                sm:w-auto
                                flex
                                bg-gray-800
                                hover:bg-gray-700
                                focus:ring-4 focus:ring-gray-300
                                text-white
                                rounded-lg
                                inline-flex
                                items-center
                                justify-center
                                px-4
                                py-2.5
                                dark:bg-gray-700
                                dark:hover:bg-gray-600
                                dark:focus:ring-gray-700
                              "
                            >
                              <svg
                                class="mr-3 w-7 h-7 hover:text-white"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="apple"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                                ></path>
                              </svg>

                              <div class="text-left hover:text-white">
                                <div class="mb-1 text-xs">Download on the</div>
                                <div
                                  class="-mt-1 font-sans text-sm font-semibold"
                                >
                                  App Store
                                </div>
                              </div>
                            </a>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.flirttooldating.app"
                              class="
                                hover:text-white
                                w-full
                                sm:w-auto
                                flex
                                bg-gray-800
                                hover:bg-gray-700
                                focus:ring-4 focus:ring-gray-300
                                text-white
                                rounded-lg
                                inline-flex
                                items-center
                                justify-center
                                px-4
                                py-2.5
                                dark:bg-gray-700
                                dark:hover:bg-gray-600
                                dark:focus:ring-gray-700
                              "
                            >
                              <svg
                                class="mr-3 w-7 h-7"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="google-play"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                      d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                                    ></path>
                              </svg>
                              <div class="text-left">
                                <div class="mb-1 text-xs">Get in on</div>
                                <div
                                  class="-mt-1 font-sans text-sm font-semibold"
                                >
                                  Google Play
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img
                          class="object-cover object-center rounded"
                          alt="hero"
                          src="@/assets/flirttoolapppreview.png"
                        />
                      </div>
                    </div>
                  </section>-->
                <!--Download App nOw-->
                <!--influencer
                  <section
                    class="text-gray-600 body-font bg:pink-50"
                    id="influencer"
                  >
                    <li></li>

                    <div class="container px-12 py-24 mx-auto">
                      <div class="flex flex-col">
                        <div
                          class="flex flex-wrap sm:flex-row flex-col py-6 mb-12"
                        >
                          <h1
                            class="
                              inline-block
                              text-gray-800 text-2xl
                              sm:text-4xl
                              uppercase
                              italic
                            "
                          >
                            Influencer
                            <br class="hidden lg:inline-block" />
                          </h1>
                        </div>
                      </div>

                      <div class="container mb-5">
                        <router-link to="/register?to=flirts">
                          <img
                            :src="require('@/assets/yamatih_banner.jpg')"
                            alt="Snow"
                            style="width: 100%"
                          />
                        </router-link>
                        <div
                          class="top-left pt-2 text-5xl font-bold"
                          style="margin-top: 15%; margin-left: 13%"
                        >
                          00:00:{{ timerCount }}
                        </div>
                      </div>

                      <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                        <div class="p-4 md:w-1/2 sm:mb-0 mb-6">
                          <h2
                            class="
                              inline-block
                              text-gray-800 text-2xl
                              sm:text-2xl
                              uppercase
                              italic
                              font-sans font-bold
                            "
                          >
                            Dash
                          </h2>
                          <br />
                          <div
                            class="
                              btn-group
                              text-2xs
                              flex
                              justify-center
                              my-3
                              text-gray-50
                            "
                          >
                            <button class="btn btn-xs">Relationship</button>
                            <button class="btn btn-xs">Dating</button>
                            <button class="btn btn-xs">
                              Beauty And Make-Up
                            </button>
                            <button class="btn btn-xs">Sugar Babe</button>
                          </div>
                          <div class="rounded-lg mx-4 overflow-hidden">
                            <img
                              alt="content"
                              class="object-cover object-center h-full w-full"
                              src="@/assets/Dashflirttooldatingcommunity.png"
                            />
                          </div>
                          <div class="p-4 md:w-full sm:mb-0 mb-6">
                            <div class="rounded-lg h-full overflow-hidden">
                              <a
                                href="https://youtu.be/yNNdSokvReQ"
                                target="_blank"
                                class="
                                  text-xs text-gray-50
                                  rounded-lg
                                  flex
                                  items-center
                                  p-2
                                  hover:bg-pink-600
                                  group
                                  font-sans font-bold
                                  bg-gray-50
                                  shadow-sm
                                  hover:text-gray-50
                                "
                              >
                                <img src="@/assets/dashthumb.jpg"
                              /></a>
                            </div>
                          </div>
                        </div>
                        <div class="p-4 md:w-1/2 sm:mb-0 mb-6">
                          <h2
                            class="
                              inline-block
                              px-5
                              text-gray-800 text-2xl
                              sm:text-2xl
                              uppercase
                              italic
                              font-sans font-bold
                            "
                          >
                            Dominic
                          </h2>
                          <br />
                          <div
                            class="
                              btn-group
                              text-2xs
                              flex
                              justify-center
                              my-3
                              text-gray-50
                            "
                          >
                            <button class="btn btn-xs">Spirituality</button>
                            <button class="btn btn-xs">Dating</button>
                            <button class="btn btn-xs">Relationships</button>
                            <button class="btn btn-xs">Coaching</button>
                          </div>
                          <div
                            class="mx-4 rounded-lg shadow-sm overflow-hidden"
                          >
                            <img
                              alt="content"
                              class="object-cover object-center h-full w-full"
                              src="@/assets/dominic.png"
                            />
                          </div>
                          <div class="p-4 md:w-full w-full sm:mb-0 mb-6">
                            <div class="rounded-lg h-full overflow-hidden">
                              <a
                                    href="https://www.youtube.com/watch?v=7MGvrTlHFzQ"
                                    target="_blank"
                                    class="
                                  text-xs text-gray-50
                                  rounded-lg
                                  flex
                                  items-center
                                  p-2
                                  hover:bg-pink-600
                                  group
                                  font-sans font-bold
                                  bg-gray-50
                                  shadow-sm
                                  hover:text-gray-50
                                "
                              >
                                <img src="@/assets/domthumb.jpg"
                              /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>-->

                <!-- Events section start -->
                <!--<section
                    id="events"
                    class="text-gray-600 body-font h-full py-16 bg-gray-100"
                  >
                    <h1
                      class="
                            inline-block
                            text-gray-800 text-5xl
                            sm:text-7xl
                            uppercase
                            italic
                      "
                    >
                      FlirtTool Live
                    </h1>
                    <div
                      class="
                        container
                        mx-auto
                        flex
                        px-5
                        py-24
                        md:flex-row
                        flex-col
                        items-center
                      "
                    >
                      <events-box />
                    </div>
                  </section>-->

                <!-- Events section  -->

                <!--video-->
                <!--<section
                    class="
                      text-gray-600
                      body-font
                      h-screen
                      bg-gray-50
                      hidden
                      xl:block
                      lg:block
                      md:block
                    "
                    id="video"
                  >
                    <div class="container px-5 py-24 mx-auto flex flex-wrap">
                      <div class="flex w-full mb-20 flex-wrap">
                        <h1
                          class="
                            inline-block
                            text-gray-800 text-5xl
                            sm:text-7xl
                            uppercase
                            italic
                          "
                        >
                          Video
                          <br class="hidden lg:inline-block" />
                        </h1>
                        <p
                          class="
                            lg:pl-6 lg:w-2/3
                            mx-auto
                            leading-relaxed
                            text-base
                            font-sans
                          "
                        >
                          All Videos in one corner! Enjoy our media center for
                          free.
                        </p>
                      </div>
                      <div>
                        <Adsense
                          data-ad-client="ca-pub-2961260170620647"
                          data-ad-slot="6267951335"
                          data-ad-format="auto"
                          :data-full-width-responsive="true"
                        >
                        </Adsense>
                      </div>
                      <div class="flex flex-wrap md:-m-2 -m-1">
                        <div class="flex flex-wrap w-1/2">
                          <div class="md:p-2 p-1 w-1/2">
                            <a
                              href="https://youtu.be/yNNdSokvReQ"
                              target="_blank"
                              class="
                                text-xs text-gray-50
                                rounded-lg
                                flex
                                items-center
                                p-2
                                hover:bg-pink-600
                                group
                                font-sans font-bold
                                bg-gray-50
                                shadow-sm
                                hover:text-gray-50
                              "
                            >
                              <img src="@/assets/DASHVIDEO1.png"
                            /></a>
                          </div>
                          <div class="md:p-2 p-1 w-1/2">
                            <a
                              href="https://youtube.com/shorts/AhRAviL14ak?feature=share"
                              target="_blank"
                              class="
                                text-xs text-gray-50
                                rounded-lg
                                flex
                                items-center
                                p-2
                                hover:bg-pink-600
                                group
                                font-sans font-bold
                                bg-gray-50
                                shadow-sm
                                hover:text-gray-50
                              "
                            >
                              <img src="@/assets/USERVIDEO1.png"
                            /></a>
                          </div>
                          <div class="md:p-2 p-1 w-full">
                            <a
                              href="https://youtu.be/HYbGo9NbPs8"
                              target="_blank"
                              class="
                                text-xs text-gray-50
                                rounded-lg
                                flex
                                items-center
                                p-2
                                hover:bg-pink-600
                                group
                                font-sans font-bold
                                bg-gray-50
                                shadow-sm
                                hover:text-gray-50
                              "
                            >
                              <img src="@/assets/PROMOVIDEO1.png"
                            /></a>
                          </div>
                        </div>
                        <div class="flex flex-wrap w-1/2">
                          <div class="md:p-2 p-1 w-full">
                            <a
                              href="https://www.youtube.com/watch?v=mu5-oGeouUE&t=982s&ab_channel=FlirtTool"
                              target="_blank"
                              class="
                                text-xs text-gray-50
                                rounded-lg
                                flex
                                items-center
                                p-2
                                hover:bg-pink-600
                                group
                                font-sans font-bold
                                bg-gray-50
                                shadow-sm
                                hover:text-gray-50
                              "
                            >
                              <img
                                class="w-full"
                                src="https://i3.ytimg.com/vi/BdmQMgZTG-g/maxresdefault.jpg"
                            /></a>
                          </div>
                          <div class="md:p-2 p-1 w-1/2">
                            <a
                              href="https://youtube.com/shorts/AjLuhrLam3w?feature=share"
                              target="_blank"
                              class="
                                text-xs text-gray-50
                                rounded-lg
                                flex
                                items-center
                                p-2
                                hover:bg-pink-600
                                group
                                font-sans font-bold
                                bg-gray-50
                                shadow-sm
                                hover:text-gray-50
                              "
                            >
                              <img src="@/assets/USERVIDEO2.png"
                            /></a>
                          </div>
                              <div class="md:p-2 p-1 w-1/2">
                                <a
                              href="https://www.youtube.com/watch?v=4DnSJxMIki8"
                              target="_blank"
                              class="
                                text-xs text-gray-50
                                rounded-lg
                                flex
                                items-center
                                p-2
                                hover:bg-pink-600
                                group
                                font-sans font-bold
                                bg-gray-50
                                shadow-sm
                                hover:text-gray-50
                              "
                              ><img src="@/assets/USERVIDEO33.png"
                            /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>-->
                <!-- indaddy section start -->
                <!--<section
                    class="
                      text-gray-600
                      body-font
                      h-screen
                      mt-10
                      bg-gray-50
                      hidden
                          xl:block
                          lg:block
                      md:block
                    "
                    id="indaddy"
                  >
                    <div class="container px-5 py-24 mx-auto flex flex-wrap">
                      <div class="flex w-full flex-wrap">
                        <h1
                          class="
                            inline-block
                            text-gray-800 text-5xl
                            sm:text-7xl
                            uppercase
                            italic
                          "
                        >
                          Indaddy
                          <br class="hidden lg:inline-block" />
                        </h1>
                      </div>
                      <indaddy-pre-p />
                    </div>
                  </section>-->
                <!-- indaddy section end -->
                <!--<section
                    class="
                      text-gray-600
                      body-font
                      h-full
                      py-16
                      bg-gray-100
                      lg:hidden
                    "
                  >
                    <div
                      class="
                        container
                        mx-auto
                        flex
                        px-5
                        py-24
                        md:flex-row
                        flex-col
                        items-center
                      "
                    >
                      <div
                        class="
                          lg:flex-grow
                          md:w-1/2
                          lg:pr-24
                          md:pr-16
                          flex flex-col
                          md:items-start md:text-left
                          mb-16
                          md:mb-0
                          items-center
                          text-center
                        "
                      >
                        <h1
                          class="
                            inline-block
                            text-3xl
                            sm:text-5xl
                            uppercase
                            italic
                            text-gray-800
                          "
                        >
                          FlirTool Video
                          <br class="hidden lg:inline-block" />
                        </h1>
                        <p class="mb-8 leading-relaxed">
                          All Videos in one corner! Enjoy our media center for
                          free.
                        </p>
                        <div class="flex justify-center">
                          <a href="https://www.youtube.com/watch?v=HYbGo9NbPs8"
                            ><button
                              class="
                                inline-flex
                                text-white
                                bg-pink-600
                                border-0
                                py-2
                                px-6
                                focus:outline-none
                                hover:bg-gray-
                                rounded
                                text-lg
                                  "
                                >
                                  View More
                            </button></a
                          >
                        </div>
                      </div>
                      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <video
                          poster="placeholder1.jpg"
                          muted
                          controls="controls"
                          width="100%"
                          height="100%"
                          controlsList="nodownload”"
                        >
                          <source
                            src="@/assets/flirttoolpromotionvideo.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </section>-->
                <!-- video-->
                <!--contact us-->
                <!--<section class="" id="infinity">
                    <div class="bg-gray-900">
                      <div
                        class="
                          px-4
                          mt-28
                          py-10
                          mx-auto
                          sm:max-w-xl
                          md:max-w-full
                          lg:max-w-screen-xl
                          md:px-24
                          lg:px-8 lg:py-0
                        "
                      >
                        <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                          <div
                            class="flex flex-col mb-10 sm:text-center sm:mb-0"
                          >
                            <div
                              class="
                                max-w-xl
                                flex
                                justify-center
                                mb-10
                                md:mx-auto
                                sm:text-center
                                lg:max-w-2xl
                                md:mb-12
                              "
                            >
                              <img
                                class="animate-pulse max-w-7xl min-w-full"
                                src="@/assets/INFIN1ITYFINALLOGO.png"
                              />
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <section id="infinity">
                      <div class="bg-gray-800 text-white py-8 text-left">
                        <div
                          class="
                            container
                            mx-auto
                            flex flex-col
                            items-start
                            md:flex-row
                            my-12
                            md:my-24
                          "
                        >
                          <div
                            class="
                              flex flex-col
                              w-full
                              sticky
                              md:top-36
                              lg:w-1/3
                              mt-2
                              md:mt-12
                              px-8
                            "
                          >
                            <p
                              class="
                                ml-2
                                text-pink-600
                                font-sans font-bold
                                uppercase
                                tracking-loose
                              "
                            >
                              FlirtTool
                            </p>
                            <p
                              class="
                                text-3xl
                                uppercase
                                md:text-4xl
                                leading-normal
                                md:leading-relaxed
                                mb-2
                              "
                            >
                              Infinity
                            </p>
                            <p class="text-sm md:text-base text-gray-50 mb-4">
                              Start your morning every day with 30 new chat
                              messages, unlock XXX profile pictures, watch
                              exclusive video content and join our influencer
                              community events for free. And Get 20 % discount
                              in our FlirtTool shop.
                            </p>
                            <a
                              href="/login"
                              class="
                                inline-flex
                                items-center
                                justify-center
                                h-12
                                px-6
                                font-semibold
                                tracking-wide
                                text-white
                                transition
                                duration-200
                                rounded
                                shadow-md
                                hover:text-deep-purple-900
                                bg-pink-600
                                hover:bg-pink-50
                                focus:shadow-outline focus:outline-none
                                hover:text-gray-800
                              "
                            >
                              Get Started</a
                            >
                          </div>
                          <div class="ml-0 md:ml-12 lg:w-2/3 sticky">
                            <div class="container mx-auto w-full h-full">
                              <div
                                class="
                                  relative
                                  wrap
                                  overflow-hidden
                                  p-10
                                  h-full
                                "
                              >
                                <div
                                  class="
                                    border-2-2 border-pink-600
                                    absolute
                                    h-full
                                    border
                                  "
                                  style="
                                    right: 50%;
                                    border: 2px solid #ffc100;
                                    border-radius: 1%;
                                  "
                                ></div>
                                <div
                                  class="
                                    border-2-2 border-pink-600
                                    absolute
                                    h-full
                                    border
                                  "
                                  style="
                                    left: 50%;
                                    border: 2px solid #ffc100;
                                    border-radius: 1%;
                                  "
                                ></div>
                                <div
                                  class="
                                    mb-8
                                    flex
                                    justify-between
                                    flex-row-reverse
                                    items-center
                                    w-full
                                    left-timeline
                                  "
                                >
                                  <div class="order-1 w-5/12"></div>
                                  <div
                                    class="order-1 w-5/12 px-1 py-4 text-right"
                                  >
                                    <p
                                      class="
                                        mb-3
                                        text-base text-pink-600
                                        font-sans font-bold
                                      "
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                          h-28
                                          w-28
                                          px-3
                                          text-center
                                          animate-bounce
                                        "
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                                        />
                                      </svg>
                                    </p>
                                    <h4
                                      class="mb-3 font-bold text-lg md:text-2xl"
                                    >
                                      Free Chat
                                    </h4>
                                    <p
                                      class="
                                        text-sm
                                        md:text-base
                                        leading-snug
                                        text-gray-50 text-opacity-100
                                      "
                                    >
                                      1 Month chat Flatrate (Volume Based) very
                                      day 30 messages for free
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="
                                    mb-8
                                    flex
                                    justify-between
                                    items-center
                                    w-full
                                    right-timeline
                                  "
                                >
                                  <div class="order-1 w-5/12"></div>
                                  <div
                                    class="order-1 w-5/12 px-1 py-4 text-left"
                                  >
                                    <p
                                      class="
                                        mb-3
                                        text-base text-pink-600
                                        font-sans font-bold
                                      "
                                    >
                                      <img
                                        class="animate-pulse h-54 w-96"
                                        src="@/assets/flirttoolspeeddating.png"
                                      />
                                    </p>
                                    <h4
                                      class="mb-3 font-bold text-lg md:text-2xl"
                                    >
                                      FlirtTool Speed Dating
                                    </h4>
                                    <p
                                      class="
                                        text-sm
                                        md:text-base
                                        leading-snug
                                        text-gray-50 text-opacity-100
                                      "
                                    >
                                      IN THE TIME OF COVID, IT'S HARD TO FIND
                                      TIME TO MEET SOMEONE... and when the
                                      meeting finally happens, sometimes the
                                      compatibility is just gone. Then, you
                                      wonder: wouldn’t it be great if I could
                                      meet real and serious singles for the
                                      first date right in the comfort of my
                                      living room without wasting one whole
                                      night on a bad first date? FlirtTool speed
                                      dating events are what you’ve been waiting
                                      for. Great dates stat right at home!
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="
                                    mb-8
                                    flex
                                    justify-between
                                    flex-row-reverse
                                    items-center
                                    w-full
                                    left-timeline
                                  "
                                >
                                  <div class="order-1 w-5/12"></div>
                                  <div
                                    class="order-1 w-5/12 px-1 py-4 text-right"
                                  >
                                    <p
                                      class="
                                        mb-3
                                        text-base text-pink-600
                                        font-sans font-bold
                                      "
                                    >
                                      <img
                                        class="animate-pulse h-56 w-72"
                                        src="@/assets/flirttoo18plus.png"
                                      />
                                    </p>
                                    <h4
                                      class="mb-3 font-bold text-lg md:text-2xl"
                                    >
                                      Free XXX Pictures
                                    </h4>
                                    <p
                                      class="
                                        text-sm
                                        md:text-base
                                        leading-snug
                                        text-gray-50 text-opacity-100
                                      "
                                    >
                                      For free access to all profile
                                      information, video, and XXX-pictures, Get
                                      FlirtTool INFINITY and unlock the dating
                                      world!
                                    </p>
                                  </div>
                                </div>

                                <div
                                  class="
                                    mb-8
                                    flex
                                    justify-between
                                    items-center
                                    w-full
                                    right-timeline
                                  "
                                >
                                  <div class="order-1 w-5/12"></div>

                                  <div class="order-1 w-5/12 px-1 py-4">
                                    <p
                                      class="
                                        mb-3
                                        text-base text-pink-600
                                        font-sans font-bold
                                      "
                                    >
                                      <img
                                        class="animate-pulse h-54 w-96"
                                        src="@/assets/flirttoollivestream.png"
                                      />
                                    </p>
                                    <h4
                                      class="
                                        mb-3
                                        font-bold
                                        text-lg
                                        md:text-2xl
                                        text-left
                                      "
                                    >
                                      Exclusive Influencer Live Events
                                    </h4>
                                    <p
                                      class="
                                        text-sm
                                        md:text-base
                                        leading-snug
                                        text-gray-50 text-opacity-100
                                      "
                                        >
                                          Get instant access to performance by
                                          influencers from different countries and
                                          follow their blog posts, videos, and
                                          photos. Twice per week, we go on - LIVE!
                                      In this 45 minutes, you can join our
                                      influencers at various live events, ask
                                      questions and enjoy the real-time
                                      interaction with your favorite
                                      influencers.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <img
                                class="mx-auto -mt-36 md:-mt-36"
                                src="@/assets/INFINITYFINAL LOGO.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>-->
                <!--contact us-->
              </div>
            </div>
            <!-- Roudned rectangle -->
            <!-- <div
                class="px-4 pt-16 mx-auto bg-gray-800 border-t lg-w-full sm:max-w-xl md:max-w-full lg:max-w-full-xl md:px-24 lg:px-8"
              >
                <div
                  class="grid gap-10 row-gap-6 mb-8 text-left sm:grid-cols-2 lg:grid-cols-3"
                >
                  <div class="sm:col-span-2">
                    <a
                      href="/"
                      aria-label="FlirtTool Home"
                      title="FlirtTool Dating Community"
                      class="inline-flex items-center"
                    >
                      <img
                        src="https://funnyflirts.net/img/logo-light.6ebe202c.png"
                        class="h-16"
                        alt="FlirtTool online dating community "
                      />
                      <span
                        class="ml-2 text-xl font-bold tracking-wide text-white uppercase"
                      ></span>
                    </a>
                    <div class="mt-6 lg:max-w-sm">
                      <p class="text-sm text-white">
                        FlirtTool offers you new levels of online dating. FlirtTool’s
                        ultimate creation of AI personality MAZU will help users be paired
                        with someone that is 94% their perfect match.
                      </p>

                      <p class="mt-4 text-sm text-white">
                        The more time users spend on the app, the more MAZU can get to
                        know you and your interest. This creates a personal and
                        unforgettable experience for users worldwide to be able to find
                        true love connections.
                      </p>
                    </div>
                  </div>
                  <div class="space-y-2 text-sm">
                    <p class="text-base font-bold tracking-wide text-white">Contacts</p>
                    <div class="flex">
                      <p class="text-white">
                        Live Chat :<a
                          href="https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v"
                          aria-label="Chat Now"
                          title="Chat Now"
                          class="text-pink-600 transition-colors duration-300 hover:text-white px-1"
                          >Chat Now</a
                        >
                      </p>
                    </div>
                    <div class="flex">
                      <p class="text-white">
                        Email:<a
                          href="#"
                          aria-label="Our email"
                          title="Our email"
                          class="px-1 text-pink-600 transition-colors duration-300 hover:text-white"
                          >support@funnyflirts.net</a
                        >
                      </p>
                    </div>
                    <div class="flex">
                      <p class="text-white">
                        Address:<span>
                          <a
                            href="https://www.google.com/maps"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Our address"
                            title="Our address"
                            class="text-pink-600 transition-colors duration-300 text-left hover:text-white"
                          >
                            2F, No. 2-1, Lane 23, Wenhua
                            <span class="lg:px-18"
                              >Street Pingzhen District, Taoyuan City, 324 Taiwan</span
                            >
                          </a></span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row"
                >
                  <p class="text-sm text-white">
                    © Copyright 2022 FlirtTool. All rights reserved. Powered by
                    <a
                      href="https://dialogmakers-international.com/"
                      class="text-pink-600 hover:text-white"
                      >Dialogmakers International Ltd.</a
                    >
                  </p>
                  <ul
                    class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row"
                  >
                        <li>
                          <a
                            href="/imprint"
                            class="text-sm text-white transition-colors duration-300 hover:text-white"
                            >Imprint</a
                          >
                        </li>
                        <li>
                          <a
                            href="/Privacy"
                            class="text-sm text-white transition-colors duration-300 hover:text-white"
                            >Privacy Policy</a
                          >
                        </li>
                        <li>
                          <a
                            href="/terms"
                            class="text-sm text-white transition-colors duration-300 hover:text-white"
                            >Terms &amp; Conditions</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div> -->
              <!--new footer-->
              <!-- promotion banner -->
              <div class="bg-pink-100">
                <div
                  class="px-4 bg-pink-100 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                  <p class="text-center py-5 text-5xl italic">Upcoming on FlirtTool</p>
                  <div class="grid  grid-cols-2 gap-2 mx-auto flex-wrap px-5">

                    <div> <img src="@/assets/sliders/5.png" alt="" class="w-full"></div>
                    <div><img src="@/assets/sliders/6.png" class=" h-80 w-full" alt=""></div>

                  </div>
                </div>
              </div>



              <!-- end of promotion banner -->



              <div class="bg-pink-50">
                <div
                  class="px-4 bg-pink-50 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                  <p class="font-sans text-4xl font-bold">
                    Find Real Life With FlirtTool!
                  </p>
                  <div class="flex flex-col bg-pink-50 border-0 rounded shadow-sm md:justify-center lg:flex-row">
                    <div class="flex flex-col justify-between p-5 border-0 sm:p-10 lg:border-0  lg:w-1/2">
                    <div class="relative w-full transition-shadow duration-300 hover:shadow-xl shadow-lg border-2">

                      <a href="/" aria-label="Play Video"
                        class="object-cover w-full h-full rounded shadow-lg sm:h-64 md:h-80 lg:h-96 absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/VI4iURFwJ3Y?start=2"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe></a>
                      </div>
                    </div>
                    <div class="flex flex-col justify-between p-5 sm:p-10 lg:w-1/2">
                      <div>
                        <h5 class="max-w-md mb-1 py-7 text-left text-lg font-bold font-sans leading-none sm:text-2xl">
                        </h5>
                        <p class="mb-3 text-sm text-left text-gray-700 md:text-sm sm:mb-8">
                          <span class="text-lg font-sans font-bold">
                            How FlirtTool Works</span><br />
                          FlirtTool offers you new levels of online dating. FlirtTool’s
                          ultimate creation of AI personality MAZU will help users be
                          paired with someone that is 94% their perfect match
                        </p>

                        <div class="items-start">
                          <!-- <button
                                    type="submit"
                                    class="inline-flex items-left justify-left h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                                  >
                                    Get started
                                  </button> -->
                        </div>
                      </div>
                      <div class="flex mb-36"><a target="_blank"
                          href="https://www.youtube.com/watch?v=VI4iURFwJ3Y"><button
                            class="btn btn-primary hover:bg-pink-300 hover:border-white hover:text-white bg-pink-50 border-2 border-gray-500 font-sans w-36 shadow-lg text-gray-900">
                            LEARN MORE
                          </button></a></div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="bg-pink-600">
                <div
                  class="px-4 pt-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-pink-600 md:px-24 lg:px-8">
                  <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-3 lg:grid-cols-4">
                    <div class="sm:col-span-2">
                      <div class="text-sm">
                        <p class="font-bold font-sans text-xl tracking-wide uppercase text-gray-50">
                          About
                        </p>
                        <div class="flex">
                          <p class="mr-1 text-pink-100 font-sans text-sm">Flirttool</p>
                        </div>
                        <div class="flex">
                          <p class="mr-1 text-pink-100 text-sm font-sans">MAZU</p>
                        </div>
                        <div class="flex">
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              Press And Media
                            </p>
                          </div>
                        </div>
                        <div class="flex">
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">Blogs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-sm">
                      <p class="font-bold tracking-wide text-xl uppercase text-gray-50">
                        Contact Us
                      </p>
                      <div class="flex">
                        <p class="mr-1 text-pink-100 text-sm font-sans">
                          Live Chat :<a class="px-2 text-white hover:text-pink-300"
                            href="https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v">Chat Now</a>
                        </p>
                      </div>
                      <div class="flex">
                        <p class="mr-1 text-pink-100 text-sm font-sans">
                          Email :<a href="mailto:support@funnyflirts.net
    " aria-label="Our email" title="Our email"
                            class="transition-colors px-7 duration-300 text-pink-50 hover:text-pink-300">support@funnyflirts.net
                          </a>
                        </p>
                      </div>
                      <div class="flex">
                        <p class="mr-1 text-pink-100 text-sm font-sans">
                          Address:<a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer"
                            aria-label="Our address" title="Our address"
                            class="transition-colors duration-300 px-2 text-pink-50 hover:text-pink-300">
                            2F, No. 2-1, Lane 23, Wenhua Street Pingzhen District,
                            Taoyuan City, 324 Taiwan
                          </a>
                        </p>
                      </div>
                    </div>
                    <div>
                      <span class="text-xl font-sans font-bold mt-3 tracking-wide uppercase text-gray-50">Social
                        Media</span>
                      <div class="flex items-center mt-1 space-x-3">
                        <a traget="_blank" href="https://twitter.com/flirttool/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                          <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                            <path
                              d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.instagram.com/flirttool/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-400">
                          <svg viewBox="0 0 30 30" fill="currentColor" class="h-6 hover:text-pink-300">
                            <circle cx="15" cy="15" r="4" class=""></circle>
                            <path
                              d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.facebook.com/flirttool/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                          <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                          <path
                            d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z">
                          </path>
                        </svg>
                      </a>
                      <a traget="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi bi-youtube h-6 hover:text-pink-300" viewBox="0 0 16 16">
                            <path
                              d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                    <p class="text-sm text-gray-50">
                      © Copyright 2022 FlirtTool. All rights reserved. Powered by
                      <a target="_blank" class="text-white hover:text-pink-300"
                        href="https://dialogmakers-international.com/">Dialogmakers International Ltd.</a>
                    </p>
                    <ul class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                      <!-- <li>
                            <a
                              href="/"
                              class="text-sm text-gray-50"
                              >F.A.Q</a
                            >
                          </li> -->
                      <li>
                        <a target="_blank" href="/imprint" class="text-sm hover:text-pink-300 text-gray-50">Imprint</a>
                      </li>
                      <li>
                        <a target="_blank" href="/privacy" class="text-sm hover:text-pink-300 text-gray-50">Privacy
                          Policy</a>
                      </li>
                      <li>
                        <a target="_blank" href="/terms" class="text-sm hover:text-pink-300 text-gray-50">Terms &amp;
                          Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div><ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-2781511921274321"
                data-ad-slot="4412224045" data-ad-format="auto" data-full-width-responsive="true"></ins>
              <!--New footer-->
            </section>
            <Adsense data-ad-client="ca-pub-2781511921274321" data-ad-slot="4412224045">
            </Adsense>
            <Adsense data-ad-client="ca-pub-2781511921274321" data-ad-slot="4412224045">
            </Adsense>
          </div>
        </main>
      </div>
    </div>




    <template>
      <div>
        <div class="relative h-sceen justify-center  lg:hidden xl:hidden align-middle bg-pink-600">
          <div class="relative h-screen ">
            <div class="absolute inset-0 object-cover w-full h-screen z-10 lg:hidden xl:hidden">
              <imageslider class="" />
              <!-- <appimageslider/> -->
              <!-- <mobimageslider style="height: 1200px;" /> -->
              <div class="absolute inset-0   h-screen  bg-opacity-0 z-0 flex justify-center items-center">
                <!-- Content goes here -->
                <div
                  class="relative   bg-gradient-to-t from-gray-900 bg-opacity-0 h-screen  justify-center align-middle">
                  <div class="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div class="flex flex-col items-center justify-between xl:flex-row">
                      <div> <img src="@/assets/logo-light.png" alt="" class="w-40 lg:w-54 -mt-5"></div>
                      <div>
                        <p class="font-sans italic text-white font-bold text-xl">Welcome to Flirttool</p>
                      </div>
                    </div>
                  </div>
                  <!-- join section starts here -->
                  <div
                    class="px-4 mt-24 mb-5 mx-auto sm:max-w-xl md:max-w-screen justify-center align-middle lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                      <div class="flex flex-col mb-16 sm:text-center sm:mb-0">

                        <div class="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                          <p
                            class="max-w-lg mb-6  text-4xl font-bold leading-none tracking-tight text-gray-50 font-sans italic sm:text-4xl md:mx-auto">

                            Join <span class="text-pink-500">free</span> Today
                        </p>

                        </div>
                        <div>
                          <router-link to="/login"><button href="/"
                              class="inline-flex items-center btn-sm bg-pink-600 rounded-full font-sans px-16 btn border-0 justify-center h-10  font-medium tracking-wide text-white transition duration-200 shadow-lg hover:bg-white hover:text-pink-500 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                              Login
                            </button></router-link> <br>
                          <p class="text-white font-sans font-bold -mt-4">or</p>
                          <router-link to="register"> <button
                              class="inline-flex py-2 items-center btn-sm bg-white rounded-full font-sans px-3 btn border-0 justify-center h-10  font-bold tracking-wide text-pink-500 transition duration-200 shadow-lg hover:bg-gray-500 hover:text-gray-50 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">Create
                              an account</button></router-link>
                        </div>
                      </div>

                    </div>

                    <div>

                      <div class="flex align-middle justify-center mb-5  items-center mt-1 space-x-3 mx-auto ">
                        <a href="/"
                          class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                          <svg viewBox="0 0 24 24" fill="#ffffff" class="h-5">
                            <path
                              d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z">
                            </path>
                          </svg>
                        </a>
                        <a href="/"
                          class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                          <svg viewBox="0 0 30 30" fill="#ffffff" class="h-6">
                            <circle cx="15" cy="15" r="4"></circle>
                            <path
                              d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                            </path>
                          </svg>
                        </a>
                        <a href="/"
                          class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                          <svg viewBox="0 0 24 24" fill="#ffffff" class="h-5">
                            <path
                              d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA/"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-7 h-7  "
                            style="color: #ffffff">
                            <path fill="currentColor"
                              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                          </svg></a>

                      </div>




                    </div>

                  </div>

                  <div>
                    <p class="bg-pink-50 py-5 text-pink-500 font-sans font-bold  ">OVER 1M+ SINGLE’S WORLDWIDE </p>
                  </div>
                  <div class="bg-pink-600 relative " style="object-fit: cover ; width: 100%; height: 290px;">
                    <div
                      class="px-4 py-8 mx-auto bg-opacity-0   sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-5 lg:py-5">
                      <img src="@/assets/easterimages/5.jpg" alt=""
                        class="absolute inset-0 z-0 justify-center object-cover w-full h-full">
                      <p
                        class="text-xl  my-section transform translate-x-4 lg:text-6xl md:text-4xl sm:text-3xl font-sans text-pink-50">
                        REGISTER FOR FREE AND GRAB<br>
                        YOUR EASTER GIFT NOW!<br>

                      </p>
                      <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div class="flex mb-8 sm:text-center sm:mb-0">
                          <div class="max-w-xl mb-1 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                            <h2
                              class="max-w-lg mb-1 mt-1 w-full font-sans text-4xl italic leading-none tracking-tight text-white sm:text-4xl">
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="bg-pink-400">
                  <div
                    class="px-4 pt-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-pink-600 md:px-24 lg:px-8">
                    <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-3 lg:grid-cols-4">
                        <div class="sm:col-span-2">
                          <p class="font-bold font-sans text-xl tracking-wide uppercase text-gray-50">
                            About
                          </p>
                          <div class="text-sm grid ">

                            <div class="flex">
                              <p class="mr-1 text-pink-100 font-sans text-sm">Flirttool</p>
                            </div>
                            <div class="flex">
                              <p class="mr-1 text-pink-100 text-sm font-sans">MAZU</p>
                            </div>
                            <div class="flex">
                              <div class="flex">
                                <p class="mr-1 text-pink-100 text-sm font-sans">
                                  Press And Media
                                </p>
                              </div>
                            </div>
                            <!-- <div class="flex">
                        <div class="flex">
                          <p class="mr-1 text-pink-100 text-sm font-sans">Blogs</p>
                        </div>
                      </div> -->
                          </div>
                        </div>
                        <div class="text-sm">
                          <p class="font-bold tracking-wide text-xl uppercase text-gray-50">
                            Contact Us
                          </p>
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              Live Chat :<a class="px-2 text-white hover:text-pink-300"
                                href="https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v">Chat Now</a>
                            </p>
                          </div>
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              Email :<a href="mailto:support@funnyflirts.net
  " aria-label="Our email" title="Our email"
                                class="transition-colors px-7 duration-300 text-pink-50 hover:text-pink-300">support@funnyflirts.net
                              </a>
                            </p>
                          </div>
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              Address:<a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer"
                                aria-label="Our address" title="Our address"
                                class="transition-colors duration-300 px-2 text-pink-50 hover:text-pink-300">
                                2F, No. 2-1, Lane 23, Wenhua Street Pingzhen District,
                                Taoyuan City, 324 Taiwan
                              </a>
                            </p>
                          </div>
                        </div>
                        <div>
                          <span class="text-xl font-sans font-bold mt-3 tracking-wide uppercase text-gray-50">Social
                            Media</span>
                          <div class="flex items-center mt-1 space-x-3">
                            <a traget="_blank" href="https://twitter.com/flirttool/"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                              <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                                <path
                                  d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z">
                                </path>
                              </svg>
                            </a>
                            <a traget="_blank" href="https://www.instagram.com/flirttool/"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-400">
                              <svg viewBox="0 0 30 30" fill="currentColor" class="h-6 hover:text-pink-300">
                                <circle cx="15" cy="15" r="4" class=""></circle>
                                <path
                                  d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                                </path>
                              </svg>
                            </a>
                            <a traget="_blank" href="https://www.facebook.com/flirttool/"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                              <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                              <path
                                d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z">
                              </path>
                            </svg>
                          </a>
                          <a traget="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                class="bi bi-youtube h-6 hover:text-pink-300" viewBox="0 0 16 16">
                                <path
                                  d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                        <p class="text-sm text-gray-50">
                          © Copyright 2022 FlirtTool. All rights reserved. Powered by
                          <a target="_blank" class="text-white hover:text-pink-300"
                            href="https://dialogmakers-international.com/">Dialogmakers International Ltd.</a>
                        </p>
                        <ul class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                          <!-- <li>
                        <a
                          href="/"
                          class="text-sm text-gray-50"
                          >F.A.Q</a
                        >
                      </li> -->
                          <li>
                            <a target="_blank" href="/imprint"
                              class="text-sm hover:text-pink-300 text-gray-50">Imprint</a>
                          </li>
                          <li>
                            <a target="_blank" href="/privacy" class="text-sm hover:text-pink-300 text-gray-50">Privacy
                              Policy</a>
                          </li>
                          <li>
                            <a target="_blank" href="/terms" class="text-sm hover:text-pink-300 text-gray-50">Terms &amp;
                              Conditions</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- social end -->
                </div>
              </div>
            </div>

          </div>

          <!-- <img src="@/assets/appbg/1.jpg" class="absolute inset-0 object-cover w-full h-screen" alt="" /> -->

          <!-- join section ends here -->


        </div>
      </div>
    </template>
  </div>
</template>
<script type="text/babel">
import { RAND_USERS } from "@/queries/auth";
import { register } from "@/utils/basicAuth";
import popup from "@/components/popup.vue";
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'

import imageslider from "@/components/imageslider.vue";


import {
  GET_BLOGS,
  GET_THEADS,
  PROMO_PIC,
  THEAD_PROMO,
  ADD_COL,
  VIDEO,
  BG_SLIDERS,
} from "../../queries/cms";

export default {
  name: "demo",
  components: {
    popup,
    Adsense,
    imageslider,
  },
  apollo: {
    additionalCollection: {
      query: ADD_COL,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    backgroundSliderCollection: {
      query: BG_SLIDERS,
      client: "cms",
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    topInternalPromoCollection: {
      query: THEAD_PROMO,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    topHeaderCollection: {
      query: GET_THEADS,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    blogCollection: {
      query: GET_BLOGS,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    promoCollection: {
      query: PROMO_PIC,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    promoVideoCollection: {
      query: VIDEO,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    randUserSet: {
      query: RAND_USERS,
      result({
        data: {
          randUserSet: { counts },
        },
      }) {
        this.usersOnlie = counts[0];
        this.girlsOnlie = counts[1];
        this.menOnlie = counts[2];
        this.coupleOnlie = counts[3];
        this.transOnlie = counts[4];
        this.gaysOnlie = counts[5];
        this.lesOnlie = counts[6];
      },
    },
  },
  data() {
    return {
      openPop: false,
      loadingReg: false,
      promoImages: [
        {
          url: require("../../assets/prom1.png"),
        },
        {
          url: require("../../assets/prom2.png"),
        },
        {
          url: require("../../assets/pride.png"),
        },
      ],
      topInternalPromoCollection: {},
      promoVideoCollection: {},
      topHeaderCollection: {},
      backgroundSliderCollection: {},
      promoCollection: {},
      blogCollection: {},
      randUserSet: null,
      delay: 1000,
      // endVal: 120500,
      usersOnlie: 0,
      girlsOnlie: 0,
      menOnlie: 0,
      coupleOnlie: 0,
      transOnlie: 0,
      gaysOnlie: 0,
      lesOnlie: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      password: "",
    };
  },
  created() {
    this.$apollo.queries.randUserSet.refetch();
    this.$apollo.queries.backgroundSliderCollection.refetch();
    const fragment = window.location.hash;
    if (fragment != "") {
      setTimeout(() => {
        document.getElementById(fragment.slice(1)).scrollIntoView(true);
      }, 1);
    }
    if (this.$store.state.core.flirtok) {
      this.$router.push("/find-matches");
    }
  },
  watch: {},
  methods: {
    doSignUp() {
      this.loadingReg = true;
      register(
        this.username,
        this.email,
        this.firstName,
        this.lastName,
        this.password,
        "",
        this.$store.state.core.addr,
        ({ data, fail }) => {
          this.loadingReg = false;
          if (!fail) {
            const { user, token, credit } = data.register;
            this.$store.commit("setUserData", user);
            // this.$store.commit("setBalance", credit.balance);
            this.$store.commit("setBalance", credit.balance + credit.flirtons);
            this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("setFlirtTok", token);
            this.$router.push("/find-matches");
          } else {
            console.error("Error creating a new user", Object.keys(data));
          }
        }
      );
    },
    formSubmitted(e) {
      e.preventDefault();
      console.log("submitted");
      this.doSignUp();
    },
    playBtn() {
      this.openPop = true;
    },
    dateNow() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const d = new Date();
      // document.write("The current month is " + monthNames[d.getMonth()]);
      return `${d.getDate()}, ${monthNames[d.getMonth()]} ${d.getFullYear()}`;
      // return new Date().toLocaleDateString()
    },
    toLogin() {
      if (this.$store.state.core.flirtok) {
        window.location.assign("/find-matches");
      } else {
        this.$router.push("/login");
      }
    },
    // onReady: function (instance, CountUp, ICountUp2) {
    // const self = this;
    // // instance.update(self.endVal + 100);
    // instance.update(self.usersOnlie + 100);
    // instance.update(self.girlsOnlie + 100);
    // instance.update(self.menOnlie + 100);
    // instance.update(self.coupleOnlie + 100);
    // instance.update(self.gaysOnlie + 100);
    // instance.update(self.lesOnlie + 100);
    // }
  },


  metaInfo() {
    return {
      title: "FlirtTool | Online Dating | The finest global  dating website around. ",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool is the dating community of the future. FlirtTool will soon define online dating. With MAZU AI System, FlirtTool dating gives everyone a chance to get their dream date",
        },
        {
          property: "og:title",
          content:
            "FlirtTool | Online Dating | The finest global  dating website around.",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };

  },


};

</script>

<style scoped>
.iCountUp {
  font-size: 20px;
  margin: 0;
  color: #252525;
}

#video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

iframe {
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  max-height: 563px;
}

.video-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  max-height: 563px;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}</style>
<style>.slick-slide {
  height: 500px;
}

.slick-slide img {
  height: 1024px;
}</style>
