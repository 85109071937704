<template>
  <section class="mx-auto max-w-6xl py-12">
    <div class="flex flex-col">
      <div class="flex flex-col md:flex-row justify-center items-center">
        <div
          class="
            transition-all
            ease-in-out
            duration-1000
            flex flex-col
            justify-center
          "
        ></div>
        <div
          class="
            transition-all
            ease-in-out
            duration-1000
            flex flex-col
            justify-center
          "
        ></div>
        <div
          class="
            transition-all
            ease-in-out
            duration-1000
            flex flex-col
            justify-center
          "
        ></div>
      </div>
      <div class="flex flex-col md:flex-row justify-start items-center">
        <div
          class="
            transition-all
            ease-in-out
            duration-1000
            flex flex-col
            justify-center
          "
        >
          <div slot="middle-left" class="max-w-2xl">
            <div class="flex flex-row">
              <div
                class="
                  w-2/3
                  bg-pink-600
                  p-5
                  text-teal-100
                  flex
                  justify-center
                  items-center
                  h-48
                  text-white text-3xl
                  font-black
                  uppercase
                "
              >
                FlirtTool Live Events
              </div>
              <div
                class="
                  w-1/3
                  bg-gray-700
                  text-orange-100
                  p-5
                  flex
                  justify-center
                  text-white
                  items-center
                "
              >
                All Events in one corner! Enjoy our influencer contents center for free
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            transition-all
            ease-in-out
            duration-1000
            flex flex-col
            justify-center
          "
        ></div>
        <div
          class="
            transition-all
            ease-in-out
            duration-1000
            flex flex-col
            justify-center
          "
        >
        </div>
      </div>
      <div class="flex flex-col md:flex-row justify-start items-center">
        <router-link
          :to="`/event/${eve.packageId}`"
          v-for="(eve, ix) in events"
          :key="ix"
          ><div
            class="
              transition-all
              ease-in-out
              duration-1000
              flex flex-col~
              justify-center
              mr-0
              md:mr-2
            "
          >
            <div slot="bottom-left" class="max-w-xs">
              <div class="p-5 shadow-md m-2 mt-4 text-gray-600">
                <img
                  class="object-scale-down h-30"
                  :src="getURL(eve.cover)"
                  alt="step3"
                />
                <div
                  class="text-xl uppercase font-bold mb-2 font-sans py-4"
                  v-text="eve.name"
                ></div>
                <div class="truncate" v-text="eve.detail"></div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
import { GET_EVENTS } from "../../queries/auth";
import { getURL } from "../../utils/helpers";

Vue.use(VueConfetti);

export default {
  created() {
    this.getEvents();
  },
  data: () => ({
    events: [],
  }),
  methods: {
    getURL,
    getEvents() {
      this.$apollo
        .query({
          fetchPolicy: "network-only",
          query: GET_EVENTS,
        })
        .then(({ data }) => {
          const { flirttoolEvents } = data;
          this.events = flirttoolEvents;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    start() {
      this.$confetti.start();
    },

    stop() {
      this.$confetti.stop();
    },

    love() {
      this.$confetti.update({
        particles: [
          {
            type: "heart",
          },
          {
            type: "circle",
          },
        ],
        defaultColors: ["red", "pink", "#ba0000"],
      });
    },
  },
};
</script>