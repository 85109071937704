<template>
  <div>
    <div
      class="
        px-4
        py-5
        mx-auto
        sm:max-w-xl
        md:max-w-full
        lg:max-w-screen-xl
        md:px-24
        lg:px-8
      "
    >
      <div class="relative flex items-center justify-between">
        <a
          href="/"
          aria-label="FlirtTool"
          title="FlirtTool"
          class="inline-flex items-center"
        >
          <img
            src="https://funnyflirts.net/img/logo-light.6ebe202c.png "
            class="h-16"
          />
        </a>

        <!-- Mobile menu -->
        <div class="lg:hidden"></div>
      </div>
    </div>
    <hr style="border: 1px inset" />
    <div class="flex justify-center">
      <div class="flex-row">
        <nav class="flex mt-10" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                to="/help-center"
                class="
                  inline-flex
                  items-center
                  text-sm
                  font-medium
                  text-gray-700
                  hover:text-gray-900
                  dark:text-gray-400 dark:hover:text-white
                "
              >
                <svg
                  class="mr-2 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Help Center
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <a
                  href="#"
                  class="
                    ml-1
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-900
                    md:ml-2
                    dark:text-gray-400 dark:hover:text-white
                  "
                  >{{ item.title }}</a
                >
              </div>
            </li>
          </ol>
        </nav>
        <!-- Guide block start -->
        <div class="flex xl:flex-row justify-start mx-3">
          <div>
            <div
              class="
                w-full
                max-w-3xl
                text-left
                font-sans
                tracking-wider
                leading-8
              "
            >
              <p v-html="documentToHtmlString(item.content.json)"></p>
            </div>
          </div>
        </div>
        <!-- Guide block end -->
      </div>
    </div>
    <div class="mb-14"></div>
    <page-footer />
  </div>
</template>
<script>
import { GUIDE_WSLUG } from "../../queries/cms";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  created() {
    this.getContent();
  },
  data: () => ({
    item: {},
  }),
  methods: {
    documentToHtmlString,
    getContent() {
      this.$apollo
        .query({
          query: GUIDE_WSLUG,
          client: "cms",
          context: {
            headers: {
              Authorization:
                "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
            },
          },
          variables: {
            where: { slug_contains: "community-guidelines" },
          },
        })
        .then(
          ({
            data: {
              guidePagesCollection: { items },
            },
          }) => {
            if (items.length > 0) {
              this.item = items[0];
            } else {
              alert("There's nothing there");
            }
            console.log(items);
          }
        );
    },
  },
};
</script>