<template>
	<div class="text-center shadow-xl mt-12 card lg:mx-48 xl:mx-48">
		<figure class="h-full px-10 pt-10">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="w-2/3 text-green-600 h-52"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
		</figure>
		<div class="card-body">
			<h2 class="card-title">You're payment is successful</h2>
			<p>Your payment is successful and your package will be added shortly.</p>
			<div class="justify-center card-actions">
				<router-link :to="redirect"
					><button class="btn btn-outline btn-accent">Find Matches</button></router-link
				>
			</div>
		</div>
	</div>
	<!-- Does this resource worth a follow? -->
</template>
<script>
export default {
	computed: {
		redirect:function() {
			return `/find-matches`
		}
	},
	async created() {
		try{
			await this.updateFlirtonsAvailable(this.$apollo)
		}catch(e){
			console.log(e)
		}
	}
}
</script>