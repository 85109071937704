<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div class="globalcompress pr-6" style="">
    <!-- <div class="col-span-1 bordered">
      <div class="box-border w-full border-0 md:mx-20">
       
        <div class="hidden lg:block">
          <user-avatar />
          <side-selector />
        </div>
       
      </div>
    </div> -->
    <div
      v-if="visits.length > 0 && !loadingContent"
      class="grid grid-cols-2 mb-1  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 justify-start mt-10"
    >
      <div v-for="(user, idx) in visits" :key="idx" class="-mt-9 ">
        <user-cards :user="user.visitor" :page="'visitors'"></user-cards>
      </div>
    </div>
    <div v-else class="text-center mt-10">
      <p class="text-xl font-bold mb-2 font-sans up">No visitors found.</p>
      <p class="text-gray-500">There are no visitors at this time. Please check back later.</p>
    </div>
    <!-- empty block to fix padding -->
    <div class="col-span-1"></div>
    <div class="col-span-9 compressedx">
      <!-- Content loader start -->
      <div class="lds-ripple mt-44" v-if="loadingContent">
        <div></div>
        <div></div>
      </div>
    
    </div>
  </div>
</template>
<style scoped></style>
<script>
// import UserAvatar from "../../components/userAvatar.vue";
// import SideSelector from "../../components/sideSelector.vue";
import { VISITS } from "@/queries/matches";
import UserCards from "@/components/yellow/YellowuserCards.vue";
const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    //     UserAvatar,
    //   SideSelector,
    UserCards,
  },
  apollo: {
    visits: {
      query: VISITS,
    },
  },
  data: () => ({
    features,
    visits: null,
    loadingContent: true,
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    mobile: false,
  }),
  methods: {
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("onorientationchange", this.detectOrientationChange);
    });
  },
  created() {
    this.$apollo.queries.visits.refetch().finally(() => {
      this.loadingContent = false;
    });
    this.detectOrientationChange(); // when instance is created
  },
};
</script>
<style>
@import "../../assets/css/style.css";
</style>
I
