<template>
  <LoginIndex/>
</template>
<script>
import LoginIndex from '@/templates/Login/Index.vue'
export default {
  components:{
    LoginIndex
  }
}
</script>