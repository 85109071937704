<template>
  <div>
    <notificationGroup group="top" position="top">
      <div class="
              fixed
              inset-0
              z-10
              flex
              items-start
              justify-end
              p-6
              px-4
              py-6
              mt-2
              pointer-events-none
            ">
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div class="
                    flex
                    w-full
                    max-w-sm
                    mx-auto
                    mt-4
                    overflow-hidden
                    bg-white
                    rounded-none
                    shadow-md
                  " v-for="notification in notifications" :key="notification.id">
              <div class="flex items-center justify-center w-12 bg-pink-500">
                <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z">
                  </path>
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3 text-left">
                  <span class="font-semibold text-pink-500">{{
                    notification.title
                  }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>


    <!--register  -->


    <!-- register -->
    <div class="flex h-screen w-full items-center justify-center bg-gray-900 bg-cover bg-no-repeat ">
      <img src="@/assets/purplethemefiles/ballon.jpg" class="absolute h-full inset-0 object-cover  w-full " alt=""
        style=" height: 1500px;" />



      <div
        class="relative px-0 py-1   mx-auto sm:max-w-xl md:max-w-full items-center lg:max-w-screen-2xl md:px-10 lg:px-0 lg:py-5 xl:py-10 2xl:py-16 h-screen bg-pink-500 bg-opacity-0">
        <div class="
                px-0
                py-1
                sm:max-w-xl
                md:max-w-full
                lg:max-w-screen-xl
                md:px-0
                lg:px-8 lg:py-10
              ">
          <div class="
                text-white py-2
                ">
            <!-- Login Section -->
            <div class="
                    px-4
                    py-2
                    sm:max-w-xl
                    md:max-w-full
                    lg:max-w-screen-lg
                    md:px-24
                    lg:px-4 lg:py-1
                    inset-y-0
                    left-0 
                  ">
              <div class="
                      flex flex-col
                      justify-center
                      bg-pink-50
                      shadow-2xl
                      rounded-3xl
                      opacity-95
                      px-8
                      pt-8
                      my-auto
                      md:justify-start md:pt-0 md:px-24
                      lg:px-10
                      relative
                      h-full
                      lg:full
                    ">
                <router-link to="/">
                  <!-- <div class="flex flex-col flex-wrap content-center mt-3 mb-10">
                    <p class="text-5xl uppercase font-sans italic font-bold text-pink-500">Register once, <br> <span class="text-red-500 "> use twice!</span></p>
                  </div> -->
                </router-link>
                <p class="
                        mb-0 mt-10
                        text-2xl
                        font-semi-bold
                        text-center text-pink-500 
                        uppercase
                        dark:text-gray-800
                        md:text-sm
                        text-light
                        lg:text-2xl
                        
                        font-sans
                      ">
                 Register Now
                  <span class="text-pink-500 italic"></span>
                </p>
                <!-- <div class=" grid lg:grid-cols-2 gap-2 grid-cols-2 mt-10">

                  <div class=" "><img class=" lg:float-right lg:h-10 h-12" :src="require('@/assets/icons/PorntoolLogo.png')"
                      alt="Connect with flirttool & start your personal online dating adventure! " /></div>
                  <div><img class="float-left lg:h-16 h-12 pl-5" :src="require('@/assets/logo-light.png')"
                      alt="Connect with flirttool & start your personal online dating adventure! " /></div>
                </div> -->


                <div class="
                        grid grid-cols-1
                        gap-2
                        mt-1
                        md:grid-cols-2
                        sm:grid-cols-2
                      ">
                  <div class="grid grid-cols-1 lg:pl-5 pl-8 mt-2 mr-0 md:mr-0 justify-end">
                    <button type="button" @click="socialLoginGoogle" class="
                            mt-2
                            mb-3
                            bg-pink-50
                            btn lg:btn-lg
                            hover:bg-pink-400 hover:text-white
                            rounded-full 
                            shadow-2xl 
                            border-white
                          ">
                      <img
                        :src="require('@/assets/google_logo_png_suite_everything_you_need_know_about_google_newest.png')"
                        class="w-10 h-10" alt="Connect with flirttool & start your personal online dating adventure!" />
                      <span class="
                              px-2
                              font-sans
                              text-lg text-gray-700
                              dark:text-white
                            ">
                        Google
                      </span>
                    </button>
                  </div>
                  <div class="grid grid-cols-1 pl-8 mr-0 md:mr-0">
                    <button type="button" @click="socialLoginFb" class="
                            bg-blue-500
                            btn btn-outline-none
                            md:mt-2
                            xl:mt-2 glass
                            lg:mt-2 lg:btn-lg
                            hover:bg-blue-900
                            rounded-full 
                            shadow-2xl
                            border-0  w-56
                          ">
                      <img :src="require('@/assets/Facebook_f_logo_(2019).svg.webp')" class="w-10 h-10"
                        alt="Connect with flirttool & start your personal online dating adventure!" />

                      <span class="
                              px-2
                              font-sans
                              text-lg text-white
                              dark:text-white
                            ">
                        Facebook
                      </span>
                    </button>
                  </div>
                </div>

                <form class="flex flex-col pt-3 md:pt-0">
                  <!-- first row start -->
                  <div class="grid grid-cols-2 gap-5 mt-2 md:grid-cols-2 md:gap-8">
                    <div class="grid grid-cols-1 mt-2 mr-0 md:mr-0">
                      <label class="
                              mb-1
                              text-xs
                              font-semibold
                              text-left text-gray-900
                              uppercase
                              dark:text-gray-800
                              md:text-sm
                              text-light
                            ">{{$t('FIRSTNAME')}}</label>
                      <input class="
                              px-3
                              py-2
                              mt-1
                              border-4 border-white
                              rounded-lg
                              dark:text-gray-800 dark:bg-gray-700
                              focus:outline-none
                              focus:ring-2
                              focus:ring-pink-400 text-black
                              focus:border-transparent
                            " :class="{
                              'border-white focus:ring-2 focus:ring-pink-500':
                                !$v.firstName.required,
                            }" v-model.trim="$v.firstName.$model" @input="setFname($event.target.value)" type="text"
                        placeholder="First Name" />
                      <!-- validation area start -->
                      <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.firstName.required">
                        <!-- First name is required -->
                      </div>
                      <!-- end of validation area -->
                    </div>
                    <div class="grid grid-cols-1 mt-2 mr-0 md:mr-0">
                      <label class="
                              mb-1
                              text-xs
                              font-semibold
                              text-left text-gray-900
                              uppercase
                              dark:text-gray-800
                              md:text-sm
                              text-light
                            ">{{$t('LastName')}}</label>
                      <input class="
                              px-3
                              py-2
                              mt-1
                              border-4 border-white
                              rounded-lg text-black
                              dark:text-gray-800 dark:bg-gray-700
                              focus:outline-none
                              focus:ring-2 text-black
                              focus:ring-pink-500
                              focus:border-transparent
                            " :class="{
                              'border-white focus:ring-2 focus:ring-pink-500':
                                !$v.lastName.required,
                            }" v-model.trim="$v.lastName.$model" @input="setLname($event.target.value)" type="text"
                        placeholder="Last Name" />
                      <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.lastName.required">
                        <!-- Last name is required -->
                      </div>
                    </div>
                  </div>
                  <!-- first row end -->

                  <!-- first row start -->
                  <div class="grid grid-cols-2 gap-5 mt-2 md:grid-cols-2 md:gap-8">
                    <div class="grid grid-cols-1 mt-2 mr-0 md:mr-0">
                      <label class="
                              mb-1
                              text-xs
                              font-semibold
                              text-left text-gray-900
                              uppercase
                              md:text-sm
                              text-light
                              dark:text-gray-800
                            ">{{$t('Username')}}</label>
                      <input class="
                              px-3
                              py-2
                              mt-1
                              border-4 border-white
                              rounded-lg
                              dark:bg-gray-700 dark:text-gray-800 text-black
                              focus:outline-none
                              focus:ring-2
                              focus:ring-pink-400
                              focus:border-transparent
                            " :class="{
                              'border-white focus:ring-2 focus:ring-pink-500':
                                !$v.username.required,
                            }" v-model.trim="$v.username.$model" @input="setUname($event.target.value)" type="text"
                        placeholder="Username" />
                      <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.username.required">
                        <!-- Username is required -->
                      </div>
                      <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.username.minLength">
                        {{$t('Usernamemusthaveatleast')}}
                        {{ $v.username.$params.minLength.min }} letters.
                      </div>
                    </div>
                    <div class="grid grid-cols-1 mt-2 mr-0 md:mr-0">
                      <label class="
                              mb-1
                              text-xs
                              font-semibold
                              text-left text-gray-900
                              uppercase
                              md:text-sm
                              text-light
                              dark:text-gray-800
                            ">E-mail</label>
                      <input class="
                              px-3
                              py-2
                              mt-1
                              border-4 border-white
                              rounded-lg
                              dark:bg-gray-700 dark:text-gray-800
                              focus:outline-none text-black
                              focus:ring-2
                              focus:ring-pink-500
                              focus:border-transparent
                            " :class="{
                              'border-white focus:ring-2 focus:ring-pink-500':
                                !$v.email.required,
                            }" v-model.trim="$v.email.$model" @input="setEmail($event.target.value)" type="text"
                        placeholder="E-mail" />
                      <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.email.required">
                        <!-- E-mail is required -->
                      </div>
                      <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.email.email">
                        {{$t('Emailisnotvalid')}}
                      </div>
                    </div>
                  </div>
                  <!-- first row end -->

                  <!-- second row start -->
                  <div class="grid grid-cols-2 gap-5 md:grid-cols-2 md:gap-8">
                    <div class="grid grid-cols-1 mt-2 mr-0 md:mr-0">
                      <label class="label dark:text-gray-800">
                        <span class="
                                mb-1
                                text-xs
                                font-semibold
                                text-left text-gray-900
                                uppercase
                                md:text-sm
                                text-light
                                dark:text-gray-800
                              ">{{$t('Yourpasswords')}}</span>
                      </label>

                      <div class="relative">
                        <input @keydown.enter="doSignUp" :class="{
                          'border-white focus:ring-2 focus:ring-pink-500':
                            !$v.password.required,
                        }" v-model.trim="$v.password.$model" @input="setPass($event.target.value)" class="
                                w-full
                                px-3
                                py-2
                                mt-1
                                border-4 border-white
                                rounded-lg
                                dark:bg-gray-700 dark:text-gray-800
                                focus:outline-none
                                focus:ring-2 text-black
                                focus:ring-pink-400
                                focus:border-transparent
                              " id="my-textfield" :type="showPass ? 'text' : 'password'" value=""
                          placeholder="********" />
                        <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.password.required">
                          <!-- Password is required -->
                        </div>
                        <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.password.minLength">
                          {{$t('Passwordisnotvalid')}}
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 mt-2 mr-0 md:mr-0">
                      <label class="label dark:text-gray-800">
                        <span class="
                                mb-1
                                text-xs
                                font-semibold
                                text-left text-gray-900
                                uppercase
                                md:text-sm
                                text-light
                                dark:text-gray-800
                              ">{{$t('Confirmpassword')}}</span>
                      </label>

                      <div class="relative">
                        <input @keydown.enter="doSignUp" :class="{
                          'border-white focus:ring-2 focus:ring-pink-500':
                            !$v.c_password.required,
                        }" v-model.trim="$v.c_password.$model" @input="setCpass($event.target.value)" class="
                                w-full
                                px-3
                                py-2
                                mt-1
                                border-4 border-white
                                rounded-lg
                                dark:bg-gray-700
                                focus:outline-none
                                dark:text-gray-800
                                focus:ring-2
                                focus:ring-pink-400
                                focus:border-transparent text-black
                              " id="my-textfield" :type="showPass ? 'text' : 'password'" value=""
                          placeholder="******" />
                        <!-- <div
										class="pt-2 text-left text-red-500 error text-xs"
										v-if="!$v.c_password.required"
									>
										Confirmation required
									</div> -->
                        <div class="pt-2 text-left text-red-500 error text-xs" v-if="!$v.c_password.sameAsPassword">
                          {{$t('Passwordisnotvalid')}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="pt-2 text-left text-red-500 error text-xs">
                    <label class="inline-flex items-center mt-3 text-left">
                      <input @change="termsChanged" v-model="acceptedTerms" type="checkbox" class="
                              w-5
                              h-5
                              form-checkbox
                              border-4 border-pink-700
                              text-pink-600
                            " checked /><span class="ml-2 uppercase text-gray-700 dark:text-gray-800">{{$t('IAGREE')}}
                       <br> <a class="text-blue-400" href="https://funnyflirts.net/terms">{{$t('Clickhereto')}}</a>
                      </span>
                    </label>
                  </div>
                  <!-- second row end -->
                  <button type="button" class="
                          p-2
                          mt-2
                          text-sm text-white
                          bg-pink-500
                          rounded-full
                          shadow-2xl
                          font-semi-bold
                          btn glass
                          btn-lg
                          hover:bg-pink-600
                        " :disabled="loadingRegister" @click="doSignUp" style="cursor: pointer">
                    <div v-if="loadingRegister" class="mt-3 lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p v-else>Register</p>
                  </button>
                </form>
                <p class="
                        mx-4
                        mb-0
                        text-xs
                        font-semibold
                        text-gray-900
                    
                        dark:text-gray-800
                        md:text-sm
                        text-light
                        mt-4
                      ">
                  {{$t('Alreadyhaveanaccount')}} ?
                  <router-link to="/login" class="font-semibold text-pink-500 underline">
                    {{$t('LoginHere')}} .
                  </router-link>
                </p>
                <p class="
                        mt-2
                        mb-3
                        font-semi-bold
                        text-center text-gray-900
                        uppercase
                        text-2xs
                        dark:text-gray-800
                        md:text-sm
                        text-light
                      ">
                  MAZU™

                  Powered by Dialogmakers International Ltd. 2022<br />
                  <router-link to="/terms" class="text-pink-500">
                    Terms </router-link>|<router-link to="/privacy" class="text-pink-500">
                    Privacy </router-link>|<router-link to="/imprint" class="text-pink-500">
                    Imprint</router-link>
                </p>
                <div class="pt-12 pb-2 text-center dark:text-gray-800"></div>
              </div>
            </div>
            <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
              <!-- <p class="absolute   text-left bottom-36 font-sans font-bold text-white  text-5xl
              ">    <span class="text-7xl  uppercase">Register once,</span> <br> <span class="font-sans">Use twice!</span></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { register } from "@/utils/basicAuth";
import { createProvider, onLogin } from "@/v-apollo";
import { sameAs, required, minLength, email } from "vuelidate/lib/validators";
import { UPDATE_LOC } from "@/queries/matches";
import { SOCIAL_AUTH_GOOGLE } from "@/queries/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

export default {
  data: () => ({
    email: "",
    username: "",
    password: "",
    c_password: "",
    firstName: "",
    lastName: "",
    showPass: false,
    afStr: null,
    loadingRegister: false,
    acceptedTerms: false,
  }),
  created() {
    this.setAddress();
    const afStr = this.$route.query.af;
    // check if the affilate link is valid
    if (afStr) {
      this.afStr = afStr;
    }
    if (this.$store.state.core.flirtok) {
      this.$router.push("/find-matches");
    }
  },
  methods: {
    socialLoginFb() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          console.warn("*************************");
          console.warn(result);
          console.warn("*************************");
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                // onLogin(createProvider().defaultClient)
                this.$store.commit("setUserData", user);
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  window.location.assign("/find-matches");
                } else {
                  this.$router.push("/profile-setup");
                }

                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    socialLoginGoogle() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }

      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
                userType: '',
                preference: ""
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                onLogin(createProvider().defaultClient);
                this.$store.commit("setUserData", user);
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  window.location.assign("/find-matches");
                } else {
                  this.$router.push("/profile-setup");
                }

                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },

    setAddress() {
      axios
        .get(
          "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
        )
        .then(({ data }) => {
          this.$store.commit("setAddr", data.ip);
          this.$apollo.mutate({
            mutation: UPDATE_LOC,
            variables: {
              addr: data.ip,
            },
          });
        });
    },
    doSignUp() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (!this.$v.$invalid) {
        this.loadingRegister = true;
        register(
          this.username,
          this.email,
          this.firstName,
          this.lastName,
          this.password,
          this.afStr,
          this.$store.state.core.addr,
          null, null,
          ({ data, fail }) => {
            this.loadingRegister = false;

            if (!fail) {
              const { user, token, credit } = data.register;
              this.$store.commit("setUserData", user);
              //this.$store.commit("setBalance", credit.balance);
              this.$store.commit("setBalance", credit.balance + credit.flirtons)
              this.$store.commit("setRandState", Math.random(100));
              this.$store.commit("setFlirtTok", token);
              this.$router.push("/find-matches");
            } else {
              console.error("Error creating a new user", Object.keys(data));
            }
          }
        );

      }
    },
    setFname(value) {
      this.firstName = value;
      this.$v.firstName.$touch();
    },
    setLname(value) {
      this.lastName = value;
      this.$v.lastName.$touch();
    },
    setPass(value) {
      this.password = value;
      this.$v.password.$touch();
    },
    setCpass(value) {
      this.c_password = value;
      this.$v.c_password.$touch();
    },

    setUname(value) {
      this.username = value;
      this.$v.username.$touch();
    },
    setEmail(value) {
      this.email = value;
      this.$v.email.$touch();
    },
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
    },
    email: {
      required,
      email,
    },
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    c_password: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  metaInfo() {
    return {
      title: "Free Registration | Looking For a Date? | Join The finest global dating website around | FlirtTool",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool offers you new levels of online dating. FlirtTool’s ultimate creation of AI personality MAZU will help users be paired with someone that is 94% their perfect match",
        },
        {
          property: "og:title",
          content:
            " Free Registration | Looking For a Date? | The finest global  dating website around |FlirtTool",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}</style>