<template>
  <div
    class="text-left mx-2 font-medium lg:w-full xl:w-full px-10 w-full xl:mt-1 lg:mt-1"
    style="width: 100%"
  >
    <div class="md:flex dark:bg-gray-700  h-screen bg-white lg:border-2"   >
      <div class="w-full lg:p-4">
        <center v-if="loadingChats">
          <div class="mt-14 lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </center>

        <ul class="overflow-y-scroll" style="height: 92% " v-else>
          <li
            v-if="newDm"
            @click="dmTargetSelected"
            class="flex justify-between items-center  dark:bg-gray-600 mt-2 p-2 hover:shadow-lg border-2 border-green-500 rounded cursor-pointer transition"
             >
            <div class="flex ml-2">
              <img
                class="rounded-full mr-3 w-10 h-10"
                :src="getURL(newTarget.profilePic)" style="object-fit: cover;"
              />
              <div class="flex flex-col ml-2 text-black text-xs dark:text-white">
                <span class="font-medium"
                  >{{ newTarget.base_profile.firstName }}
                  {{ newTarget.base_profile.lastName }}</span
                >
                <span class="text-sm text-green-400 truncate w-44 animate-pulse uppercase ">{{$t("Newconversation")}} </span>
              </div>
            </div>
            <div class="flex flex-col items-center">
              
              <i class="fa fa-star text-green-400"></i>
            </div>
          </li>
          <li
            @click="userSelected(item)"
            v-for="(item, idx) in allConversations"
            :key="idx"
            class="flex justify-between items-center mt-2 p-2 hover:shadow-lg border-2 rounded cursor-pointer transition"
            :class="{
              'dark:bg-gray-600': selected.id === item.id,
              'dark:bg-gray-800': !(selected.id === item.id),
              'bg-red-100': selected.id === item.id,
              'bg-white': !(selected.id === item.id),
            }"
          >
            <div class="flex ml-2 flex-wrap  ">
              <img
                class="rounded-full mr-1 w-10 h-10" style="object-fit: cover;"
                :src="getURL(item.m_one.profilePic)"
              />
              <div class="flex flex-col ml-2 text-black text-xs dark:text-white">
                <span class="font-medium"
                  >{{ item.m_one.base_profile.firstName }}
                  {{ item.m_one.base_profile.lastName }}</span
                >
                <span
                  v-if="item.lastMessage"
                  class="truncate text-2xs w-40"
                  :class="{
                    'text-gray-600': selected.id === item.id,
                    ' text-gray-400': !(selected.id === item.id),
                  }"
                  >{{ item.lastMessage.body }}</span
                >
              </div>
             
            </div><span 
                @click="deleteCoversation($event,item)" 
                class='flex flex-col justify-end text-black text-xs dark:text-white' style="justify-content: end; display: flex;"
              >	
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
               </span>
           
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</template>
<script>
import { getURL, parseTimeStr } from "@/utils/helpers";
import { DELETE_USER_MESSAGES } from '@/queries/chat';
export default {
  props: {
    selected: {},
    newDm: Boolean,
    newTarget: Object,
    allConversations: Array,
    loadingChats: Boolean,
  },
  methods: {
    getURL,
    parseTimeStr,
    pickDmTarget(target) {
      this.$emit("pickDmTarget", target);
    },
    userSelected(item) {
      this.$emit("userSelected", item);
    },
    dmTargetSelected() {
      this.$emit("dmUserSelected");
    },
    async deleteCoversation(event,conversation) {
      let conf=  confirm('Are you sure to delete this conversation ')
      if(conf) {
        event.preventDefault();
        event.stopPropagation()
         const { data } =  await this.$apollo
        .mutate({
          mutation: DELETE_USER_MESSAGES,
          variables: {
            convo_id:conversation.id
          },
        })

        if(data){
          console.log(conversation,"emitting convoDeleted")
          this.$emit("convoDeleted",conversation)
          const query = Object.assign({}, this.$route.query);
          if(query.dm){
            delete query.dm;
            this.$router.replace({ query });
          }
        }
      }
   
    }
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(150, 148, 148, 0.212);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 213, 213, 0.274);
}
</style>
