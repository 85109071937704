<template>
  <div>
    <div class="grid grid-cols-1">
      <div class="grid grid-cols-1">
        <div class="flex justify-start px-5 mt-20">
          <div class="flex">
            <h1 class="text-gray-600 font-bold md:text-2xl text-xl">
              Privacy Settings
            </h1>
          </div>
        </div>
      </div>

      <div class="p-6 mx-5 mt-5 card bordered">
        <div class="form-control" v-for="x in 4" :key="x">
          <label class="cursor-pointer label">
            <div class="text-left px-7 font-sans font-black py-3 text-md">
              Show my profile on search engine
            </div>

            <div
              class="flex justify-between items-center"
              @click="toggleActive = !toggleActive"
            >
              <div
                class="
                  w-14
                  h-5
                  flex
                  items-center
                  bg-gray-300
                  rounded-full
                  p-1
                  duration-300
                  ease-in-out
                "
                :class="{ 'bg-pink-400': toggleActive }"
              >
                <div
                  class="
                    bg-white
                    w-6
                    h-6
                    rounded-full
                    shadow-md
                    transform
                    duration-300
                    ease-in-out
                  "
                  :class="{ 'translate-x-7': toggleActive }"
                ></div>
              </div>
            </div>
            <!-- <input
            @click="checked"
              type="checkbox"
              checked="checked"
              class="toggle toggle-secondary"
            /> -->
          </label>
        </div>
    <div class="flex mx-7 md:gap-6 gap-4 pt-5 pb-5">
      <button
        class="
          w-auto
          bg-pink-500
          hover:bg-pink-700
          rounded-lg
          shadow-xl
          font-medium
          text-white
          px-4
          py-2
        "
      >
        Save Changes
      </button>
    </div>
  </div>
      </div>
    </div>
</template>
<script>
export default {
  data: () => ({
    toggleActive: false,
  }),
  methods: {
    handleToggleActive() {
      this.toggleActive = !this.toggleActive;
    },
  },
};
</script>