import { render, staticRenderFns } from "./RedLikes.vue?vue&type=template&id=20dfe860&scoped=true&"
import script from "./RedLikes.vue?vue&type=script&lang=js&"
export * from "./RedLikes.vue?vue&type=script&lang=js&"
import style0 from "./RedLikes.vue?vue&type=style&index=0&lang=css&"
import style2 from "./RedLikes.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20dfe860",
  null
  
)

export default component.exports