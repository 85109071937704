<template>
  <div
    class="
      flex flex-row
      items-center
      xl:h-16
      lg:h-16
      h-24
      mb-3
      mt-3
      dark:bg-gray-700
      bg-white
      w-full
      relative
      lg:mb-4
      md:mb-4
    "
    :class="{
      'cursor-not-allowed': loading,
    }"
  >
    <modal name="gift-modal" width='100%' height='600px' shiftY='0.2'>
      <div class="gift-wrapper">
        <div class="gifts" style="padding:5px;overflow-y: scroll; height:300px;">
          <div class="grid lg:grid-cols-6 grid-cols-2" style="">
            <div  v-for="gift in gifts " :key="gift.id" 
                @click="selectGift(gift)" 
                class="gift-item"
                :class="{ activeGift : gift.id == selectedGift.id}"
              >
              <img :src="getURL(gift.asset)">
              <p> price:{{ gift.cost }}</p>
            </div>
          </div>
        </div>
        <input
          :class="{
            'cursor-not-allowed': loading || disabled,
          }"
          :disabled="disabled || loading"
          v-model="giftmessage"
          type="text"
          class="
          block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
          "
          placeholder="Write Something"
          @keypress.enter="sendGift"
        />
        <button @click="sendGift" class="p-2 -mr-1 rounded bg-pink-600 w-32 font-bold text-gray-50 mt-10">Send gift</button>
        <button @click="closeGifts" class="p-2 -mr-1 rounded bg-pink-600 w-32 font-bold text-gray-50 mt-10 ml-5">Cancel</button>

      </div>
    </modal>
    <input
      :class="{
        'cursor-not-allowed': loading || disabled,
      }"
      :disabled="disabled || loading"
      v-model="message"
      type="text"
      class="
      block mx-4 p-6 lg:p-2 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
      " style="width: 300px;"
      placeholder="Write Something"
      @keypress.enter="sent"
    />
    <input
      ref="whoa"
      type="file"
      @change="uploaded"
      accept="image/*"
      class="hidden"
      :class="{
        'cursor-not-allowed': loading,
      }"
    />
    <div
      class="absolute right-0 items-center inset-y-0  sm:flex"
      :class="{
        'cursor-not-allowed': loading,
      }"
    >
      <button
        :disabled="loading || disabled"
        :class="{
          'cursor-not-allowed': loading,
        }"
        @click="$refs.whoa.click()"
        type="button"
        class="
          mt-0
          inline-flex
          items-center
          dark:bg-gray-300
          justify-center
          rounded-full
          h-10
          w-10 mb-5
          transition
          duration-500
          ease-in-out
          text-whtie-500
          hover:bg-whtie-300
          focus:outline-none
        "
      >
        <svg
          class="h-6 w-6 text-whtie-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </button>
      <!-- <button
        :disabled="loading || disabled"
        :class="{
          'cursor-not-allowed': loading,
        }"
        type="button"
        class="
          ml-2
          dark:mx-2
          block
          mt-6
          inline-flex
          dark:bg-gray-300
          items-center
          justify-center
          rounded-full
          h-10
          w-10
          transition
          duration-500
          ease-in-out
          text-whtie-500
          hover:bg-whtie-300
          focus:outline-none
        "
      >
        <svg
          class="h-6 w-6 text-whtie-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button> -->
       <button
        :disabled="loading || disabled"
        :class="{
          'cursor-not-allowed': loading,
        }"
        @click="openGifts"
        type="button"
        class="
          ml-2
          dark:mx-2
          block
          mt-6
          inline-flex
          dark:bg-gray-300
          items-center
          justify-center
          rounded-full
          h-10
          w-10
          
          transition
          duration-500
          ease-in-out
          text-whtie-500
          hover:bg-whtie-300
          focus:outline-none
        "
      >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mb-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
</svg>
      </button>
      <button
        :disabled="loading || disabled"
        :class="{
          'cursor-not-allowed': loading,
        }"
        type="button"
        class="
          block
          inline-flex
          items-center
          justify-center
          rounded-full
          h-10
          w-10
          transition
          duration-500
          ease-in-out
          text-white
          bg-pink-500
          hover:bg-pink-400
          focus:outline-none
          mr-5
          ml-2
          mb-5
        "
        @click="sent"
      >
        <div class="loader" v-if="loading"></div>
        <svg
          v-else
          class="h-6 w-6 transform rotate-90"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import {ALL_GIFTS } from '../../queries/chat'
import { getURL } from "../../utils/helpers";

export default {
  components:{
    getURL
  },
  apollo: {
    gifts: {
      query: ALL_GIFTS,
      result() {
        console.log(this.gifts)
      },
    },
  },
  props: {
    convoSelected: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },
  data: () => ({
    message: "",
    giftmessage:"",
    showGiftModal:false,
    selectedGift: {},
  }),
  methods: {
    getURL,
    uploaded(e) {
      const allowed = ["image/png", "image/gif", "image/jpeg"];
      const filetype = e.target.files[0].type;
      if (allowed.includes(filetype)) {
        this.$emit("photoMessageSent", this.message, e);
      }else{
        alert("only picture formats are allowed")
      }
    },
    emptyMessageField() {
      this.message = "";
    },
    sent() {
      this.$emit("messageSent", {
        body:this.message,
        type:'d',
        attachment:"",
        attachment_url:null
      });
    },
    openGifts() {
      this.$modal.show('gift-modal');
    },
    selectGift(gift){
      this.selectedGift = gift
      console.log(gift)
    },
    closeGifts() {
      this.giftmessage = ''
      this.selectedGift = {}
      this.$modal.hide('gift-modal');
    },
    sendGift() {
      if(!this.selectedGift.id) return alert('You need to select a gift')
       this.$emit("messageSent", {
        body:this.giftmessage,
        attachment:"",
        attachment_url:this.selectedGift.asset,
        type:'g',
        gift_id:this.selectedGift.id
      });
      this.closeGifts()
    }
  },
  
};
</script>

<style scoped>
.loader {
  color: #ffffff;
  font-size: 4px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
.gift-wrapper{
  padding:50px;
}
.gift-item img {
  width: 200px;
  height: 150px;
  padding:10px;
}
.gift-item {
  margin-bottom:42px;
  opacity: 0.5;

}
.gift-item:hover{
  cursor: pointer;
  opacity: 1;

}
.gifts{
      margin-bottom: 100px;
     
}
.activeGift{
    opacity: 1;
    border-bottom :solid 3px #ED3F7F
}
</style>