import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Article from '../views/Article.vue'
import ProfilePage from '../views/pages/ProfilePage.vue'
import Matches from '../views/pages/Matches.vue'
import Visitors from '../views/pages/Visitors.vue'
import Flirts from '../views/pages/Flirts.vue'
import FLirtOn from '../views/pages/FlirtOns.vue'
import Settings from '../views/pages/Settings.vue'
import Notification from '../views/pages/Notification.vue'
import Test from '../views/Test.vue'
import RegisterPre from '../views/RegisterPre.vue'
import Register from '../views/Register.vue'
import ModelRegister from '../views/ModelRegister.vue'
import Ftmodels from '../views/pages/Ftmodels.vue'

import Login from '../views/Login.vue'
import ModelLogin from '../views/ModelLogin.vue'
import ValentineSpecial from '../views/Valentine.vue'
import MediaPage from '../views/MediaPage.vue'

// import ModelRegister from '../views/ModelRegister.vue'

import UserProfilePublic from '../views/UserProfilePublic.vue'
import UserProfile from '../views/UserProfile.vue'
import ModelProfile from '../views/ModelProfile.vue'
import ModelGallery from '../views/ModelGallery.vue'


import ProfileSetup from '../views/ProfileSetup.vue'
import Likes from '../views/pages/Likes.vue'
import Liked from '../views/pages/Liked.vue'
import Dislikes from '../views/pages/Dislikes'
import DirectChat from '../views/pages/DirectChat.vue'
import Nchat from '../views/pages/MobileChat.vue'
import ChatDetails from '../views/pages/ChatDetail.vue'
import Landing from '../views/pages/Landing.vue'
// import FtHome from '../views/pages/FtHome.vue'
import About from '../views/About.vue'
// import LandingUk from '../views/pages/LandingUk.vue'
// import LandingAu from '../views/pages/LandingAu.vue'
// import LandingNz from '../views/pages/LandingNz.vue'
import { authGuard, affGuard, countryRouting, affsGuard } from '../utils/basicAuth'
import FindMatches from '../views/pages/FindMatches.vue'
import ChatList from '../views/pages/ChatList.vue'
import Affilate from '../views/pages/Affilate.vue'
import AffActivity from '../views/pages/AffActivity.vue'
import AffActivityDetails from '../views/pages/AffActivityDetails.vue'
import GeneralSettings from '../views/pages/settings/GeneralSettings.vue'
import LooksAndPersonality from '../views/pages/settings/looksAndPersonality.vue'
import LifeStyle from '../views/pages/settings/lifeStyleAndPersonality.vue'
import PrivacyAndSecurity from '../views/pages/settings/privacySetting.vue'
import PasswordManagement from '../views/pages/settings/passwordManagement.vue'
import SocialLinks from '../views/pages/settings/socialLinks.vue'
import ProfileSetupSettings from '../views/pages/settings/profileSetup.vue'
import UploadPicture from '../views/pages/settings/UploadPicture.vue'
import Community from '../views/pages/Community.vue'
import CommunityHome from '../views/pages/CommunityHome.vue'
import Influencer from '../views/pages/Influencer.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import Support from '../views/pages/Support.vue'
import Transactions from '../views/pages/Transactions.vue'
import Success from '../views/pages/Success.vue'
import Error from '../views/pages/Fail.vue'
import BlogDetail from '../views/BlogDetail.vue'
import LoveStories from '../views/LoveStories.vue'
import LoveStoriesAu from '../views/LoveStoriesAu.vue'
import LoveStoriesUk from '../views/LoveStoriesUk.vue'
import CountryExempt from '../views/CountryExempt.vue'
import Unsubscribe from '../views/Unsubscribe.vue'
// import LandingCa from '../views/pages/LandingCa.vue'
import Sitemap from '../views/pages/Sitemap.vue'
import Recipe from '../views/pages/Recipe.vue'
import Decoration from '../views/pages/Decoration.vue'
import Spring from '../views/pages/Spring.vue'
import Help from '../views/pages/Help.vue'
import Event from '../views/pages/SingleEvent.vue'
import Events from '../views/pages/Events.vue'
import Guide from '../views/pages/Guide.vue'
import GuideDetail from '../views/pages/GuideDetail.vue'
import HelpDetail from '../views/pages/guides/ConfirmAge.vue'
import Saftey from '../views/pages/Saftey.vue'
import SafetyAndPrivacy from '../views/pages/SafetyAndPrivacy.vue'
import TroubleShooting from '../views/pages/TroubleShooting.vue'
import CommunityGuideLines from '../views/pages/CommunityGuideLines.vue'
import InitialPassReset from '../views/InitialPassReset.vue'
import ChangePassword from '../views/pages/ChangePassword.vue'
import Live from '../views/Live.vue'
import Liveone from '../views/Liveone.vue'
import Ftlive from '../views/Ftlive.vue'
import Ftinfinity from "../views/Ftinfinity.vue"
import MapTest from '../views/map.vue'
import Video from '../views/Video.vue'
import Gallery from '../views/Gallery.vue'
import Booking from '../views/pages/BookingDotcom.vue'
import CustomerSupport from '../views/Support.vue'



Vue.use(VueRouter)

const routes = [{
    path: "/success",
    name: "Success",
    component: Success,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/decoration",
    name: "Decoration",
    component: Decoration
},
{
    path: "/gallery",
    name: "Gallery",
    component: Gallery
},
{
    path: "/video",
    name: "Video",
    component: Video
},
{
    path: "/community-guidelines",
    name: "CommunityGuideLines",
    component: CommunityGuideLines
},
{
    path: '/booking',
    name: 'Booking',
    component: Booking


},
{
    path: "/decorations",
    name: "Decorations",
    component: Decoration
},
{
    path: "/guide",
    name: "Guide",
    component: Guide
},
{
    path: "/guide/:slug",
    name: "Guide",
    component: GuideDetail
},
{
    path: "/troubleshooting",
    name: "TroubleShooting",
    component: TroubleShooting
},
{
    path: "/saftey/:slug",
    name: "Saftey",
    component: Saftey
},
{
    path: "/help-detail",
    name: "HelpDetail",
    component: HelpDetail
},
{
    path: "/safety-and-privacy",
    name: "SafetyAndPrivacy",
    component: SafetyAndPrivacy
},
{
    path: "/help-center",
    name: "Help",
    component: Help
},
{
    path: "/support",
    name: "Support",
    component: Support
},
{
    path: "/recipe",
    name: "Recipe",
    component: Recipe
},
{
    path: "/live",
    name: "Live",
    component: Live,

},
{
    path: "/liveone",
    name: "Liveone",
    component: Liveone,

},
{
    path: "/ftlive",
    name: "Ftlive",
    component: Ftlive,
},
{
    path: "/sitemap",
    name: "Sitemap",
    component: Sitemap
},
{
    path: "/love-stories-au",
    name: "LoveStoriesAu",
    component: LoveStoriesAu
},
{
    path: "/love-stories-uk",
    name: "LoveStoriesUk",
    component: LoveStoriesUk
},
{
    path: "/love-stories",
    name: "LoveStories",
    component: LoveStories
},
{
    path: "/ftmodels",
    name: "Ftmodels",
    component: Ftmodels,

},
{
    path: "/error",
    name: "Error",
    component: Error,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
}, {
    path: "/chat-details/:chat",
    name: "ChatDetails",
    component: ChatDetails,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/detail/:id",
    name: "BlogDetail",
    component: BlogDetail,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/aboutus",
    name: "AboutUs",
    component: About,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/country-exempt",
    name: "CountryExempt",
    component: CountryExempt
},
{
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: Unsubscribe
},
{
    path: "/",
    name: "MainHome",
    component: Landing,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
// {
//         path: "/ca",
//         name: "Canada",
//         component: LandingCa,
//         beforeEnter: (to, from, next) => {
//             countryRouting(to, from, next)
//         }
//     },
// {
//     path: "/ca",
//     name: "Canada",
//     component: LandingCa,
//     beforeEnter: (to, from, next) => {
//         countryRouting(to, from, next)
//     }
// },
// {
//     path: "/nz",
//     name: "NzLanding",
//     component: LandingNz,
//     beforeEnter: (to, from, next) => {
//         countryRouting(to, from, next)
//     }
// },
// {
//     path: "/uk",
//     name: "UkLanding",
//     component: LandingUk,
//     beforeEnter: (to, from, next) => {
//         countryRouting(to, from, next)
//     }
// },
// {
//     path: "/home",
//     name: "FtHome",
//     component: FtHome,
//     beforeEnter: (to, from, next) => {
//         countryRouting(to, from, next)
//     }
// },
{
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/terms",
    name: "Terms",
    component: Terms,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/chat",
    name: "Chat",
    component: Nchat,
    beforeEnter: (to, from, next) => {
        authGuard(to, from, next)
    }
},
{
    path: "/profile-setup",
    name: "ProfileSetup",
    component: ProfileSetup,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: "/CustomerSupport",
    name: "CustomerSupport",
    component: CustomerSupport
},
{
    path: "/ftinfinity",
    name: "FtInfinity",
    component: Ftinfinity
},
{
    path: "/p/:username",
    name: "UserProfilePublic",
    component: UserProfilePublic
},
{
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
        authGuard(to, from, next)
    },
    children: [{
        path: "/events",
        name: "Events",
        component: Events
    },
    {
        path: "/event/:id",
        name: "Events",
        component: Event
    },
    {
        path: "/community/home",
        name: "CommunityHome",
        component: CommunityHome,
    },
    {
        path: "/community",
        name: "Community",
        component: Community,
    },
    {
        path: "/community/spring",
        name: "Spring",
        component: Spring
    },
    {
        path: "/influencer/:author",
        name: "Influencer",
        component: Influencer,
    },
    {
        path: "/aff-activity",
        name: "AffActivity",
        component: AffActivity,
        beforeEnter: (to, from, next) => {
            affsGuard(to, from, next)
        },
    },
    {
        path: "/aff-activity/:aff",
        name: "AffActivityDetails",
        component: AffActivityDetails,
        beforeEnter: (to, from, next) => {
            affsGuard(to, from, next)
        },
    },
    {
        path: "/affilate",
        name: "Affilate",
        component: Affilate,
        beforeEnter: (to, from, next) => {
            affGuard(to, from, next)
        },
    },
    {
        path: "/transactions",
        name: "Transactions",
        component: Transactions
    },
    {
        path: "/notification",
        name: "Notification",
        component: Notification
    },
    {
        path: "/chat/:chat",
        name: "DirectChat",
        component: DirectChat,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/chat-list",
        name: "ChatList",
        component: ChatList,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/find-matches",
        name: "FindMatches",
        component: FindMatches,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/valentine",
        name: "ValentineSpecial",
        component: ValentineSpecial,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/mediapage/:id",
        name: "MediaPage",
        component: MediaPage,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/liked",
        name: "Liked",
        component: Liked,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
   

    {
        path: "/booking",
        name: "Booking",
        component: Liked,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/liked",
        name: "Liked",
        component: Liked,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/likes",
        name: "Likes",
        component: Likes,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/dislikes",
        name: "Dislikes",
        component: Dislikes,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },

    {
        path: "/test",
        name: "Test",
        component: Test
    },
    {
        path: "/map",
        name: "MapTest",
        component: MapTest
    },

    {
        path: "/(@):username",
        name: "UserProfilePublic",
        component: UserProfile
    },
    {
        path: "/ft/(@):username",
        name: "ModelProfile",
        component: ModelProfile
    },
    {
        path: "/model-gallery/(@):username",
        name: "ModelGallery",
        component: ModelGallery
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/matches",
        name: "Matches",
        component: Matches,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/visitors",
        name: "Visitors",
        component: Visitors,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/flirts",
        name: "Flirts",
        component: Flirts,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/flirtons",
        name: "Flirtons",
        component: FLirtOn,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        }
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        beforeEnter: (to, from, next) => {
            authGuard(to, from, next)
        },
        children: [{
            name: "GeneralSettings",
            path: "/general-settings",
            component: GeneralSettings
        },

        {
            name: "UploadPicture",
            path: "/uploadpicture",
            component: UploadPicture
        },
        {
            name: "ProfileSettings",
            path: "/profile-setttings",
            component: ProfileSetupSettings
        },
        {
            name: "LooksAndPer",
            path: "/looks-personality",
            component: LooksAndPersonality
        },
        {
            name: "LifeStyle",
            path: "/lifestyle",
            component: LifeStyle
        },
        {
            name: "Ftinfinity",
            path: "/ftflirttoolinfinity",
            component: Ftinfinity
        },
        {
            name: "Privacy",
            path: "/privacy",
            component: PrivacyAndSecurity
        },
        {
            name: "PasswordManagement",
            path: "/password-management",
            component: PasswordManagement
        },
        {
            name: "Live",
            path: "/live",
            component: Live
        },
        {
            name: "Liveone",
            path: "/liveone",
            component: Liveone
        },
        {
            name: "Ftlive",
            path: "/ftlive",
            component: Ftlive
        },
        {
            name: "SocialLinks",
            path: "/social-links",
            component: SocialLinks
        }
        ],
    },

    ],
},
{
    path: "/change-password/:token",
    name: "ChangePassword",
    component: ChangePassword
},
{
    path: "/initial-reset",
    name: "InitialPassReset",
    component: InitialPassReset
},
{
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: '/modellogin',
    name: 'ModelLogin',
    component: ModelLogin,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: '/modelregister',
    name: 'ModelRegister',
    component: ModelRegister,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
{
    path: '/modelregister',
    name: 'ModelRegister',
    component: ModelRegister,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
},
//
{
    path: '/register-pre',
    name: 'RegisterPre',
    component: RegisterPre,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
}, {
    path: "/article",
    name: "Article",
    component: Article,
    beforeEnter: (to, from, next) => {
        countryRouting(to, from, next)
    }
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(function (to, from, next) {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
    next();
});
export default router