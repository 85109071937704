<template>
  <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolRegister from './FlirttoolRegister.vue'
import YellowRegister from './YellowRegister.vue'
import PurpleRegister from './PurpleRegister.vue'
import RedRegister from './RedRegister.vue'
// import EroticLogin from './EroticLogin.vue'
export default {
 components: {
   FlirttoolRegister,
   YellowRegister,
   PurpleRegister,
   RedRegister
   // EroticLogin
 },
 computed: {
   appliedComponent:function() {
     switch(activeTheme) {
       case 'Flirttool':
         return 'FlirttoolRegister'
       case 'Purple':
         return 'PurpleRegister'
       case 'Yellow':
         return 'YellowRegister'
         case 'Red':
          return 'RedRegister'
       default:
         return 'FlirttoolRegister'
     }
   }
 }
}
</script>
