<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div class="px-2 py-4 mx-auto bg-white border-pink-200  shadow-2xl drop-shadow-2xl border-4 max-w-screen-xl md:px-2 lg:px-2 lg:-mt-28">
    <notification-bar />
    <!--  start of coupon payment validation dialog -->
    <div>
      <transition name="modalv">
        <div v-if="modalV" class="dark:bg-white ">
          <div class="overlay">
            <div
              class="modalx my-10 dark:bg-gray-800 bg-white mx-10"
              v-click-outside="clickedOutsideV"
            >
              <h1
                class="text-3xl  font-sans dark:text-gray-200"
                v-text="selectedPkg.packageName"
              ></h1>
              <p class="text-xl font-sans font-normal pb-5 dark:text-gray-200">
                Select your payment option
              </p>

              <div
                class="
                  grid grid-cols-1
                  sm:grid-cols-2
                  md:grid-cols-3
                  lg:grid-cols-2
                "
              >
                <button
                  class="
                    mx-2
                    btn btn-md
                    border-none
                    paypal
                    valign-wrapper
                    bg-yellow-400
                    hover:bg-yellow-500
                  "
                  @click="checkOutPayal()"
                >
                  <svg
                    v-if="loadingPaypal"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    :class="{ 'animate-spin': loadingPaypal }"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    class="text-blue-800"
                  >
                    <path
                      fill="currentColor"
                      d="M8.32,21.97C8.21,21.92 8.08,21.76 8.06,21.65C8.03,21.5 8,21.76 8.66,17.56C9.26,13.76 9.25,13.82 9.33,13.71C9.46,13.54 9.44,13.54 10.94,13.53C12.26,13.5 12.54,13.5 13.13,13.41C16.38,12.96 18.39,11.05 19.09,7.75C19.13,7.53 19.17,7.34 19.18,7.34C19.18,7.33 19.25,7.38 19.33,7.44C20.36,8.22 20.71,9.66 20.32,11.58C19.86,13.87 18.64,15.39 16.74,16.04C15.93,16.32 15.25,16.43 14.05,16.46C13.25,16.5 13.23,16.5 13,16.65C12.83,16.82 12.84,16.79 12.45,19.2C12.18,20.9 12.08,21.45 12.04,21.55C11.97,21.71 11.83,21.85 11.67,21.93L11.56,22H10C8.71,22 8.38,22 8.32,21.97V21.97M3.82,19.74C3.63,19.64 3.5,19.47 3.5,19.27C3.5,19 6.11,2.68 6.18,2.5C6.27,2.32 6.5,2.13 6.68,2.06L6.83,2H10.36C14.27,2 14.12,2 15,2.2C17.62,2.75 18.82,4.5 18.37,7.13C17.87,10.06 16.39,11.8 13.87,12.43C13,12.64 12.39,12.7 10.73,12.7C9.42,12.7 9.32,12.71 9.06,12.85C8.8,13 8.59,13.27 8.5,13.6C8.46,13.67 8.23,15.07 7.97,16.7C7.71,18.33 7.5,19.69 7.5,19.72L7.47,19.78H5.69C4.11,19.78 3.89,19.78 3.82,19.74V19.74Z"
                    />
                  </svg>
                  <span class="font-sans text-xl pl-2"
                    >Pay<span class="text-blue-500">pal</span></span
                  >
                  <svg
                    class="spinner"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      class="spinner__path"
                      fill="none"
                      stroke-width="7"
                      stroke-linecap="round"
                      cx="33"
                      cy="33"
                      r="29"
                    ></circle>
                  </svg>
                </button>
                <!-- end of paypal -->
                <!-- start of stripe -->

                <button
                  class="
                    mx-2
                    btn
                    stripe
                    valign-wrapper
                    bg-yellow-500
                    hover:bg-pink-600
                  "
                  @click="stripeCheckout"
                >
                  <svg
                    v-if="loadingStripe"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    :class="{ 'animate-spin': loadingStripe }"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                    />
                  </svg>
                  Card
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- end of coupon payment validation dialog -->
    <div class="">
      <transition name="modalx">
        <div v-if="modal" class="dark:bg-white">
          <div class="overlay">
            <div
              class="modalx my-10 dark:bg-gray-800 bg-white mx-10"
              v-click-outside="clickedOutside"
            >
              <div class="flex flex-row justify-center">
                <img class="w-1/4 my-2" :src="getURL(selectedPkg.cover)" />
              </div>
              <h1
                class="text-3xl font-black dark:text-gray-200"
                v-text="selectedPkg.packageName"
              ></h1>
              <p class="text-xl font-sans font-normal pb-5 dark:text-gray-200">
                Select your payment option
              </p>
              <div
                v-if="selectedPkg.price > 0"
                class="
                  grid grid-cols-1
                  sm:grid-cols-2
                  md:grid-cols-3
                  lg:grid-cols-2
                "
              >
                <button
                  class="
                    mx-2
                    btn btn-md
                    border-none
                    paypal
                    valign-wrapper
                    bg-white-400
                    hover:bg-yellow-500
                  "
                  @click="checkOutPayal()"
                >
                  <svg
                    v-if="loadingPaypal"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    :class="{ 'animate-spin': loadingPaypal }"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    class="text-blue-800"
                  >
                    <path
                      fill="currentColor"
                      d="M8.32,21.97C8.21,21.92 8.08,21.76 8.06,21.65C8.03,21.5 8,21.76 8.66,17.56C9.26,13.76 9.25,13.82 9.33,13.71C9.46,13.54 9.44,13.54 10.94,13.53C12.26,13.5 12.54,13.5 13.13,13.41C16.38,12.96 18.39,11.05 19.09,7.75C19.13,7.53 19.17,7.34 19.18,7.34C19.18,7.33 19.25,7.38 19.33,7.44C20.36,8.22 20.71,9.66 20.32,11.58C19.86,13.87 18.64,15.39 16.74,16.04C15.93,16.32 15.25,16.43 14.05,16.46C13.25,16.5 13.23,16.5 13,16.65C12.83,16.82 12.84,16.79 12.45,19.2C12.18,20.9 12.08,21.45 12.04,21.55C11.97,21.71 11.83,21.85 11.67,21.93L11.56,22H10C8.71,22 8.38,22 8.32,21.97V21.97M3.82,19.74C3.63,19.64 3.5,19.47 3.5,19.27C3.5,19 6.11,2.68 6.18,2.5C6.27,2.32 6.5,2.13 6.68,2.06L6.83,2H10.36C14.27,2 14.12,2 15,2.2C17.62,2.75 18.82,4.5 18.37,7.13C17.87,10.06 16.39,11.8 13.87,12.43C13,12.64 12.39,12.7 10.73,12.7C9.42,12.7 9.32,12.71 9.06,12.85C8.8,13 8.59,13.27 8.5,13.6C8.46,13.67 8.23,15.07 7.97,16.7C7.71,18.33 7.5,19.69 7.5,19.72L7.47,19.78H5.69C4.11,19.78 3.89,19.78 3.82,19.74V19.74Z"
                    />
                  </svg>
                  <span class="font-sans text-xl pl-2"
                    >Pay<span class="text-blue-500">pal</span></span
                  >
                  <svg
                    class="spinner"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      class="spinner__path"
                      fill="none"
                      stroke-width="7"
                      stroke-linecap="round"
                      cx="33"
                      cy="33"
                      r="29"
                    ></circle>
                  </svg>
                </button>
                <!-- end of paypal -->
                <!-- start of stripe -->

                <button
                  class="
                    mx-2
                    btn
                    stripe
                    valign-wrapper
                    bg-pink-500
                    hover:bg-pink-600
                  " 
                  @click="stripeCheckout"
                >
                  <svg
                    v-if="loadingStripe"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    :class="{ 'animate-spin': loadingStripe }"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                    />
                  </svg>
                  Card
                </button>
              </div>

              <div class="alert bg-pink-100 mt-4 mx-2" v-if="authErr">
                <div class="flex-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="w-6 h-6 mx-2 stroke-current"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    ></path>
                  </svg>
                  <label>
                    {{ authErr }}
                  </label>
                </div>
              </div>
              <div class="form-control w-full mt-2">
                <label class="label">
                  <span class="label-text dark:text-gray-200"
                    >Enter your coupon code here</span
                  >
                </label>
                <input
                  v-model="couponInput"
                  type="text"
                  placeholder="coupon code"
                  class="input input-bordered w-full dark:text-gray-200"
                />
                <button
                  class="btn btn-active bg-pink-100 hover:bg-pink-200"
                  role="button"
                  @click="
                    selectedPkg.price == 0
                      ? verifyAndActivate()
                      : verifyCoupon()
                  "
                >
                  Verify
                </button>
                <!-- <button class="btn">Go</button> -->
              </div>

              <!-- end of stripe -->
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- <div>
      <center>
        <p
          class="
            font-sans
            pb-10
            pt-5
            font-bold
            lg:text-5xl
            md:text-4xl
            sm:text-3xl
            text-3xl text-gray-800 uppercase
            dark:text-gray-200
          "
        >
          Our Packages
          
        </p>
      </center>
    </div> -->


    <div class=" grid lg:grid-cols-12 px-4 py-5 gap-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8">
      
      <div class="col-span-3 hidden lg:block ">
        <!-- Your navigation content goes here -->
        <div class="bg-orange-100 rounded-xl shadow-lg h-full bg-pink-100 border-white border-4 p-4 col-span-3"
          >
          <div class=" shadow-none rounded-xl  bg-orange-200">

            <user-avatar /> 
            <!-- <span class="uppercase py-2 bg-white">Notification</span> -->
            <div class="text-center bg-pink-200  mt- h-32">
              
              <Notify/>
              <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
                <div></div>
                <div></div>
              </div>

            </div>

          </div>


          <!-- <div class="text-center bg-orange-100 mt-44 h-96 overflow-hidden">
            <div class="py-2 justify-center text-center bg-white">
              <span class="uppercase py-2 ">Visitors</span>

            </div>
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>
           
            <YellowsideHomeSelector />

          </div> -->
        </div>

      </div>
      
      
      
      <div
      class="
       lg:col-span-9
        grid grid-cols-1
        sm:grid-cols-2
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-3
        gap-5
        mb-6
        
        
      "
      
    >
      <div
        class="card text-center shadow-2xl rounded-2xl dark:bg-gray-700 your-div border-0 rounded-none dark:border-gray-50 transition transform hover:-translate-y-2 motion-reduce:transition-none motion-reduce:hover:transform-none"
        
        :class="pkg.packageType == 'infny' ?  'border-4 border-yellow -500 dark:border-pink-500 rounded-xl shadow-xl' : ''" 
       
        
        :style="{
          background: $store.state.core.darkTheme
            ? ''
            : 'linear-gradient(rgb(255, 242, 249), rgb(255, 249, 252))',
        }"
        v-for="(pkg, idx) in all_packages.packages"
        :key="idx"
      > <!--end of temporary product hight light for time packages--><!--end of temporary product hight light for time packages-->
             <!-- infiniy package highlight --> <!-- infiniy package highlight --> 
             
            

  


             <div
                class="mx-0  "
                :style="{
                  background: $store.state.core.darkTheme
                    ? ''
                    : 'linear-gradient(#fff2f9, fff9fc)',
                }"
              >
            <div class=" py-1 rounded-none text-center bg-pink-600 glass"  > 
              <div  :class="pkg.packageType == 'timed' ?  'text-center border-0 border-pink-500 dark:border-pink-500 bg-purple-50  float-right mt-1 mr-10 w-72 ' : ''"    style="transform:translateX(50%) rotate(45deg);" >
                <!-- <div class=" text-pink-500 mb-5 mt-5 text-sm font-sans font-bold uppercase">Best Option</div> -->
           </div> 
          <p
            class="font-bold bg-pink-600  dark:text-white text-purple-50  glass font-sans uppercase  text-center text-lg pb-1 pt-1 " 
            v-text="pkg.packageName"
          ></p>
          <!-- <p
            class="font-bold bg-pink-500  dark:text-white text-white font-sans uppercase md:text-lg lg:text-xl text-xl pb-1 pt-1 " 
            v-text="pkg.packageName"
          ></p> -->
          <span>
              <!-- <p
                v-if="pkg.creditValue && pkg.packageType != 'timed'"
                class="text-lg font-bold dark:text-white font-sans text-gray-50 uppercase "
                
              >
                {{ pkg.creditValue }} Flirts
              </p>
              <p v-else class="text-2xl text-gray-50 font-black mb-8"></p> -->
            </span> <span class="text-white text-3xl font-sans font-bold">{{ getCurrencySign() }} </span><span v-text="getPackagePrice(pkg)" class="pt-12 font-sans text-3xl font-bold  text-yellow-50"></span></div>
          <!-- <div class="container">
            <div class="flex flex-row justify-center absolute w-full">
              <img :src="getURL(pkg.cover)" class="w-40 object-cover my-5" />
            </div>
            <div class="h-60 glossy"></div>
          </div> -->
          <div class=" font-sans uppercase  " v-for="(x, ix) in pkg.packageDesc.split('\n')" :key="ix">
            <div class="flex flex-row mx-7 pt-3 text-center text-yellow-800 font-semi-bold ">
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mt-4 mx-1 text-pink-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg> -->
              <p class="md:text-lg lg:text-md sm:text-xs w-full text-left dark:text-white">{{ x }}</p>
            </div>
          </div>
          <div class="my-10" style="padding-top: 0.1%"></div>
          <p
            class="
              your-div-span
              font-black
              lg:text-2xl
              xl:text-5xl
              md:text-5xl
              sm:text-5xl
              text-3xl
              pb-7
              text-pink-400
            "
          >
            <!-- <span>
              <p
                v-if="pkg.creditValue && pkg.packageType != 'timed'"
                class="text-2xl dark:text-white text-gray-500 font-black"
                style="padding-bottom: 20px"
              >
                {{ pkg.creditValue }} Flirts
              </p>
              <p v-else class="text-2xl text-gray-500 font-black mb-8"></p>
            </span> -->
            <!-- $<span v-text="pkg.price" class="pt-12"></span> -->
          </p>
          <!-- <p>text block fixed at the bottom</p> -->
          <div
            style="
              left: 0;
              bottom: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
              width: 100%;
              position: absolute;
            "
          >
            <!-- position: absolute;
              right: 0;
              bottom: 0;
              margin-left: auto;
              margin-right: auto; -->
            <center>
              <div class="mb-3">
                <a
                  v-if="checkPackageActivated(pkg) == 'activated' && pkg.is_available"
                  class="
                    btn
                    mb-1
                    text-white
                    hover:bg-yellow-700
                    hover:text-white
                    bg-pink-300 glass
                    btn-block
                  "  
                  >Activated</a
                >
                <button
                  v-else-if="!pkg.is_available"
                  style="position: absolute; right: 0; bottom: 0"
                  class="
                    btn
                    mb-4
                    w-49

                    text-white
                    hover:bg-pink-700
                    bg-gray-500
                    btn-block
                    font-sans 
                    
                  "
                  v-text="'Not Available'"
                ></button>
                <button
                  v-else
                  style="position: absolute; right: 0; bottom: 0"
                  @click="buyPackage(pkg)"
                  class="
                    btn
                    mb-4
                    w-49

                    text-white
                    hover:bg-pink-900
                    bg-pink-500 glass
                    btn-block
                    font-sans 
                    
                  "
                  v-text="pkg.price > 0 ? 'Buy Now' : 'Activate Now'"
                ></button>
              </div>
            </center>
          </div>
        </div>
      </div>
      <div class="h-40"></div>
    </div></div>
    
  </div>
</template>
<script>
import NotificationBar from "../../components/NotificationBar.vue";
import {
  CHECKOUT_PAYPAL,
  CHECKOUT_STRIPE,
  GET_PACKAGES,
  VERIFY_CPN,
  VERIFY_CPN_AC,
} from "../../queries/matches";
import { LOGGEDIN_DATA } from '../../queries/auth'
import {} from "../../utils/basicAuth";
import { getURL } from "../../utils/helpers";
import { getLocations } from '../../utils/helpers'
import UserAvatar from "@/components/userAvatar.vue";
import Notify from "@/components/Notify.vue"

export default {
  components: { NotificationBar,UserAvatar,Notify },
  apollo: {
    all_packages: {
      query: GET_PACKAGES,
    },
    me: {
			query: LOGGEDIN_DATA,
			variables() {
				return {
					addr: this.$store.state.core.addr
				}
			},
			result({ data: { me: { user, credits, notificationCount } } }) {
				// store.commit("setUserData", user)
        this.$store.commit("setUserData", user)
				// this.me = user
				return user
			}
		}
  },
  data: () => ({
    all_packages: [],
    selectedPkg: {},
    modal: false,
    modalV: false,
    authErr: null,
    discountObj: null,
    loadingPaypal: false,
    couponInput: null,
    loadingStripe: false,
  }),
  created() {
    this.$apollo.queries.all_packages.refetch();
  },
  methods: {
    checkPackageActivated(pkg) {
      let activatedPackages = this.all_packages.active_packages
      let isActivated = activatedPackages.find(p => p.id == pkg.id)
      if(isActivated){
        return 'activated'
      }
      if(pkg.price == 0 ) return 'activated'
      return 'not-activated'
    },
    verifyAndActivate() {
      if (this.couponInput != "") {
        this.$apollo
          .mutate({
            mutation: VERIFY_CPN_AC,
            variables: {
              cpn: this.couponInput,
              input: JSON.stringify(this.selectedPkg.id),
            },
          })
          .then(() => {
            this.authErr = null;
            this.modal = false;
            this.$router.push("/find-matches");
            // this.modalV = true;
          })
          .catch(() => {
            this.authErr = "coupon code is invalid";
          });
      } else {
        this.authErr = "coupon code can't be empty";
        return;
      }
    },
    getURL,
    verifyCoupon() {
      console.warn(this.couponInput, "Asdf");
      if (this.couponInput != "") {
        this.$apollo
          .mutate({
            mutation: VERIFY_CPN,
            variables: {
              input: {
                code: this.couponInput,
                pkg: JSON.stringify(this.selectedPkg.id),
              },
            },
          })
          .then(
            ({
              data: {
                verifyCoupon: { id },
              },
            }) => {
              this.discountObj = JSON.stringify(id);
              this.authErr = null;
              this.modal = false;
              this.modalV = true;
            }
          )
          .catch(() => {
            this.authErr = "coupon code is invalid";
          });
      } else {
        this.authErr = "coupon code can't be empty";
        return;
      }
    },
    buyPackage(pkg) {
      this.modal = true;
      this.selectedPkg = pkg;
    },
    getCurrencySign() {
      let locations = getLocations()
      let userLocation = this.$store.getters.getUserData.user_basic.location
      if(!userLocation) return '$'
      let userCountry = locations.find(c => c.code == userLocation)
      if(!userCountry) return '$'
      if(userCountry.currency == 'eur'){
        return '€'
      }else if(userCountry.currency == 'usd') {
        return '$ '
      }else if (userCountry.currency == 'gbp'){
        return '£'
      } 
      return '$'
    },
    getPackagePrice(pkg) {
        let currencySign = this.getCurrencySign()
        if(currencySign == '$'){
          return pkg.price
        }
        if(currencySign == '€'){
          return pkg.euroPrice
        }
        if(currencySign == '£'){
          return pkg.poundPrice
        }
        return pkg.price
    },
    getcurrency(){
      let currencySign = this.getCurrencySign()
        if(currencySign == '$'){
          return 'usd'
        }
        if(currencySign == '€'){
          return 'eur'
        }
        if(currencySign == '£'){
          return 'gbp'
        }
        return 'usd'
    },
    clickedOutsideV() {
      this.modalV = false;
    },
    clickedOutside() {
      this.modal = false;
    },
    checkOutPayal() {
      this.loadingPaypal = true;
      this.$apollo
        .mutate({
          mutation: CHECKOUT_PAYPAL,
          variables: {
            input: {
              package: this.selectedPkg.packageId,
              discount: this.discountObj,
              coupon: this.couponInput,
            },
          },
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loadingPaypal = false;
          const link = data.createPaypalCheckout.find(
            (x) => x.rel == "approval_url"
          );
          // Define height and width of popup
          window.open(link.href, false);
        })
        .catch(() => {
          this.loadingPaypal = false;
        });
    },
    stripeCheckout() {
      this.loadingStripe = true;
      this.$apollo
        .mutate({
          mutation: CHECKOUT_STRIPE,
          variables: {
            input: {
              package: this.selectedPkg.packageId,
              discount: this.discountObj,
              coupon: this.couponInput,
              currency : this.getcurrency()
            },
          },
        })
        .then(({ data }) => {
          window.open(data.createStripeCheckout);
          this.loadingStripe = false;
        })
        .catch(() => {
          this.loadingStripe = false;
        });
    },
  },
};
</script>
<style scoped>
.your-div {
  position: relative;
}

.your-div-span {
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  left: 0;
  widows: 100%;
  right: 0;
}
.divider:after,
.divider:before {
  content: "";
  flex-grow: 1;
  --tw-bg-opacity: 1;
  --tw-bg-opacity: var(--tw-border-opacity, 0.1);
  background-color: #ed5899;
  height: 0.425rem;
}
.modalx {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
}

.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modalx,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}

button {
  padding: 7px;
  margin-top: 10px;
  font-size: 1.1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  /* transition: opacity s ease; */
}
.container {
  width: 260px;
  height: 200px;
  position: relative;
  margin: auto;
  margin-bottom: 40px;
}
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.stack-top {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}
.glossy {
  border-radius: 33px;
  background: linear-gradient(
    rgb(255 252 254),
    rgb(211 144 202 / 78%) 50% 50px,
    rgb(255 252 254)
  );
  opacity: 27%;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 27%), 0 4px 6px -2px rgb(0 0 0 / 27%);
}
</style>