'
<template>
	<!-- component -->
	<div class="w-full mx-auto">
		<!-- header -->
		<header>
			<!-- 1st nav bar -->
			<div
				class="
					px-4
					py-2
					mx-auto
					sm:max-w-xl
					md:max-w-full
					lg:max-w-screen-xl
					md:px-24
					lg:px-8
				"
			>
				<div class="relative flex items-center justify-between">
					<a
						href="/"
						aria-label="FlirtTool"
						title="FlirtTool"
						class="inline-flex items-center"
					>
						<span
							class="
								ml-2
								text-sm
								tracking-wide
								text-gray-800
								uppercase
								font-semi-bold
								animate-pulse
							"
							>Breaking News</span
						>
					</a>

					<ul class="flex items-center hidden space-x-8 lg:flex">
						<li></li>
					</ul>
					<!-- Mobile menu -->
				</div>
			</div>
			<div class="bg-pink-600 shadow-xl">
				<div
					class="
						px-4
						py-2
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8
					"
				>
					<div class="relative flex items-center justify-between">
						<a
							href="/"
							aria-label="flirttool"
							title="flirttool"
							class="inline-flex items-center"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-6 h-6 text-white"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
								/>
							</svg>
							<span
								class="
									ml-2
									text-sm
									font-bold
									tracking-wide
									text-gray-100
									uppercase
								"
								>20. November 2021</span
							>
						</a>
						<ul class="flex items-center hidden space-x-8 text-sm lg:flex">
							<li>
								<a
									href="/"
									aria-label="terms"
									title="terms"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
								>
									Terms</a
								>
							</li>
							<li>
								<a
									href="/"
									aria-label="Privacy"
									title="Privacy"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>Privacy</a
								>
							</li>
							<li>
								<a
									href="/"
									aria-label="Contact"
									title="Contact"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>Contact</a
								>
							</li>

							<li>
								<a
									href="/"
									class="
										inline-flex
										items-center
										justify-center
										h-12
										px-6
										font-bold
										tracking-wide
										text-white
										transition
										duration-200
										bg-pink-600
										rounded
										shadow-md
										hover:bg-white
										bg-deep-purple-accent-400
										hover:bg-deep-purple-accent-700
										focus:shadow-outline focus:outline-none
									"
									aria-label="Sign up"
									title="Sign up"
								>
									Sign up
								</a>
							</li>
							<li>
								<a
									class="
										inline-flex
										items-center
										justify-center
										h-12
										px-6
										font-bold
										tracking-wide
										text-white
										transition
										duration-200
										bg-pink-600
										rounded
										shadow-md
										hover:bg-white
										bg-deep-purple-accent-400
										hover:bg-deep-purple-accent-700
										focus:shadow-outline focus:outline-none
									"
									@click="$router.push('/login')"
									aria-label="Sign up"
									title="Sign up"
								>
									Sign in
								</a>
							</li>
						</ul>
						<!-- Mobile menu -->
						<div class="lg:hidden">
							<!--<button aria-label="Open Menu" title="Open Menu" class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline">
          <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
            <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"></path>
            <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"></path>
            <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"></path>
          </svg>
        </button>-->
							<!-- Mobile menu dropdown
        <div class="absolute top-0 left-0 w-full">
          <div class="p-5 bg-white border rounded shadow-sm">
            <div class="flex items-center justify-between mb-4">
              <div>
                <a href="/" aria-label="FlirtTool" title="FlirtTool" class="inline-flex items-center">
                  <svg class="w-8 text-deep-purple-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                    <rect x="3" y="1" width="7" height="12"></rect>
                    <rect x="3" y="17" width="7" height="6"></rect>
                    <rect x="14" y="1" width="7" height="6"></rect>
                    <rect x="14" y="11" width="7" height="12"></rect>
                  </svg>
                  <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">FlirtTool</span>
                </a>
              </div>
              <div>
                <button aria-label="Close Menu" title="Close Menu" class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <nav>
              <ul class="space-y-4">
                <li><a href="/" aria-label="Our product" title="Our product" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a></li>
                <li><a href="/" aria-label="Our product" title="Our product" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a></li>
                <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a></li>
                <li><a href="/" aria-label="About us" title="About us" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About us</a></li>
                <li>
                  <a
                    href="/"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-bold tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    aria-label="Sign up"
                    title="Sign up"
                  >
                    Sign up
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        -->
						</div>
					</div>
				</div>
			</div>

			<!-- 1st nav bar ends here -->
			<!-- 2nd nav bar  -->

			<div
				class="
					px-4
					py-5
					mx-auto
					sm:max-w-xl
					md:max-w-full
					lg:max-w-screen-xl
					md:px-24
					lg:px-8
				"
			>
				<div class="relative flex items-center justify-between">
					<a
						href="/"
						aria-label="FlirtTool"
						title="FlirtTool"
						class="inline-flex items-center"
					>
						<img
							src="https://funnyflirts.net/img/logo-light.6ebe202c.png "
							class="h-16"
						/>
					</a>

					<ul class="flex items-center hidden space-x-8 lg:flex">
						<!-- <img src="@/assets/flirttoollongbanner.jpg" class="h-32"> -->
					</ul>
					<!-- Mobile menu -->
					<div class="lg:hidden">
						<!-- <button aria-label="Open Menu" title="Open Menu" class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50">
        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
          <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"></path>
          <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"></path>
          <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"></path>
        </svg>
      </button>-->
						<!-- Mobile menu dropdown 
      <div class="absolute top-0 left-0 w-full">
        <div class="p-5 bg-white border rounded shadow-sm">
          <div class="flex items-center justify-between mb-4">
            <div>
              <a href="/" aria-label="FlirtTool" title="FlirtTool" class="inline-flex items-center">
                <svg class="w-8 text-deep-purple-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                  <rect x="3" y="1" width="7" height="12"></rect>
                  <rect x="3" y="17" width="7" height="6"></rect>
                  <rect x="14" y="1" width="7" height="6"></rect>
                  <rect x="14" y="11" width="7" height="12"></rect>
                </svg>
                <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">FlirtTool</span>
              </a>
            </div>
            <div>
              <button aria-label="Close Menu" title="Close Menu" class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <nav>
            <ul class="space-y-4">
              <li><a href="/" aria-label="Our product" title="Our product" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a></li>
              <li><a href="/" aria-label="Our product" title="Our product" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a></li>
              <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a></li>
              <li><a href="/" aria-label="About us" title="About us" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About us</a></li>
              <li>
                <a
                  href="/"
                  class="inline-flex items-center justify-center w-full h-12 px-6 font-bold tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  aria-label="Sign up"
                  title="Sign up"
                >
                  Sign up
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      -->
					</div>
				</div>
			</div>

			<!-- 2nd nav bar ends here -->

			<!-- 3rd nav bar  -->

			<div class="bg-pink-600">
				<div
					class="
						px-4
						py-4
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8
					"
				>
					<div
						class="
							relative
							flex
							grid grid-cols-2
							text-sm
							items-left
							lg:grid-cols-1
						"
					>
						<ul class="flex items-center hidden space-x-12 lg:flex">
							<li>
								<a
									href="/"
									aria-label="About FlirtTool"
									title="About FlirtTool"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>About FlirtTool</a
								>
							</li>
							<li>
								<a
									href="/"
									aria-label="Features"
									title="Featurest"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>Features</a
								>
							</li>
							<li>
								<a
									href="/"
									aria-label="Community"
									title="Community"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>Community</a
								>
							</li>
							<li>
								<a
									href="/"
									aria-label="Love Stories"
									title="Love Stories"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>Love Stories</a
								>
							</li>
							<li>
								<a
									href="/"
									aria-label="Become a Partner"
									title="Become a Partner"
									class="
										font-bold
										tracking-wide
										text-gray-100
										transition-colors
										duration-200
										hover:text-teal-accent-400
									"
									>Become a Partner
								</a>
							</li>
						</ul>
						<a
							href="/"
							aria-label="FlirtTool"
							title="FlirtTool"
							class="inline-flex items-center lg:mx-auto"
						>
							<span
								class="
									ml-2
									text-xl
									font-bold
									tracking-wide
									text-gray-100
									uppercase
								"
							></span>
						</a>
						<ul class="flex items-center hidden ml-auto space-x-8 lg:flex">
							<!-- component -->
							<div class="flex flex-wrap justify-center gap-2">
								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-blue-500
										rounded
									"
								>
									<svg
										class="w-5 h-5 fill-current"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
									>
										<path
											d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
										/>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-blue-400
										rounded
									"
								>
									<svg
										class="w-5 h-5 fill-current"
										role="img"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
									>
										<path
											d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
										/>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-red-500
										rounded
										hover-bg
									"
								>
									<svg
										class="w-5 h-5 fill-current"
										role="img"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z"
										/>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-pink-600
										rounded
									"
								>
									<svg
										class="w-5 h-5 fill-current"
										role="img"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"
										/>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-blue-600
										rounded
									"
								>
									<svg
										class="w-5 h-5 fill-current"
										role="img"
										viewBox="0 0 256 256"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g>
											<path
												d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"
											></path>
										</g>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-red-600
										rounded
									"
								>
									<svg
										class="w-5 h-5 fill-current"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 16 16"
									>
										<path
											d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
										/>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-gray-700
										rounded
									"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										aria-hidden="true"
										role="img"
										class="w-5"
										preserveAspectRatio="xMidYMid meet"
										viewBox="0 0 24 24"
									>
										<g fill="none">
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12z"
												fill="currentColor"
											/>
										</g>
									</svg>
								</button>

								<button
									class="
										inline-flex
										items-center
										p-2
										space-x-2
										font-semibold
										text-white
										bg-red-500
										rounded
									"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										aria-hidden="true"
										role="img"
										class="w-5"
										preserveAspectRatio="xMidYMid meet"
										viewBox="0 0 24 24"
									>
										<g fill="none">
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M12 0C5.372 0 0 5.373 0 12s5.372 12 12 12c6.627 0 12-5.373 12-12S18.627 0 12 0zm.14 19.018c-3.868 0-7-3.14-7-7.018c0-3.878 3.132-7.018 7-7.018c1.89 0 3.47.697 4.682 1.829l-1.974 1.978v-.004c-.735-.702-1.667-1.062-2.708-1.062c-2.31 0-4.187 1.956-4.187 4.273c0 2.315 1.877 4.277 4.187 4.277c2.096 0 3.522-1.202 3.816-2.852H12.14v-2.737h6.585c.088.47.135.96.135 1.474c0 4.01-2.677 6.86-6.72 6.86z"
												fill="currentColor"
											/>
										</g>
									</svg>
								</button>
							</div>
						</ul>
						<!-- Mobile menu -->
						<div class="ml-auto lg:hidden">
							<button
								aria-label="Open Menu"
								title="Open Menu"
								class="
									p-2
									-mr-1
									transition
									duration-200
									rounded
									focus:outline-none focus:shadow-outline
								"
							>
								<svg class="w-5 text-gray-600" viewBox="0 0 24 24">
									<path
										fill="currentColor"
										d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
									></path>
									<path
										fill="currentColor"
										d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
									></path>
									<path
										fill="currentColor"
										d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
									></path>
								</svg>
							</button>
							<!-- Mobile menu dropdown 
        <div class="absolute top-0 left-0 w-full">
          <div class="p-5 bg-white border rounded shadow-sm">
            <div class="flex items-center justify-between mb-4">
              <div>
                <a href="/" aria-label="FlirtTool" title="FlirtTool" class="inline-flex items-center">
                  <svg class="w-8 text-deep-purple-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                    <rect x="3" y="1" width="7" height="12"></rect>
                    <rect x="3" y="17" width="7" height="6"></rect>
                    <rect x="14" y="1" width="7" height="6"></rect>
                    <rect x="14" y="11" width="7" height="12"></rect>
                  </svg>
                  <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">FlirtTool</span>
                </a>
              </div>
              <div>
                <button aria-label="Close Menu" title="Close Menu" class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <nav>
              <ul class="space-y-4">
                <li><a href="/" aria-label="Our product" title="Our product" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a></li>
                <li><a href="/" aria-label="Our product" title="Our product" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a></li>
                <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a></li>
                <li><a href="/" aria-label="Sign in" title="Sign in" class="font-bold tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Sign in</a></li>
                <li>
                  <a
                    href="/"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-bold tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    aria-label="Sign up"
                    title="Sign up"
                  >
                    Sign up
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        -->
						</div>
					</div>
				</div>
			</div>

			<!-- 3rd nav bar ends here -->
		</header>
		<!-- header ends here -->

		<main
			class="
				justify-center
				px-4
				py-0
				mx-auto
				shadow-2xl
				sm:max-w-xl
				md:max-w-full
				lg:max-w-screen-xl
				md:px-24
				lg:px-8 lg:py-0
			"
		>
			<div
				class="relative w-full px-5 mx-auto mb-4 font-serif text-left md:mb-0"
			>
				<div
					class="
						px-4
						py-16
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8 lg:py-20
					"
				>
					<div
						class="
							max-w-xl
							mb-10
							md:mx-auto
							sm:text-center
							lg:max-w-2xl
							md:mb-12
						"
					>
						<div>
							<p
								class="
									inline-block
									px-3
									py-px
									mb-4
									text-xs
									font-semibold
									tracking-wider
									text-teal-900
									uppercase
									rounded-full
									bg-teal-accent-400
								"
							>
								Brand new
							</p>
						</div>
						<h2
							class="
								max-w-lg
								mb-6
								font-sans
								text-3xl
								font-bold
								leading-none
								tracking-tight
								text-gray-900
								sm:text-4xl
								md:mx-auto
							"
						>
							<span class="relative inline-block">
								<span class="relative">The</span>
							</span>
							{{ blog.title }}
						</h2>
						<!-- <p class="text-base text-gray-700 md:text-lg">
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque rem aperiam, eaque ipsa quae.
						</p> -->
					</div>
				</div>
			</div>
			<div class="flex flex-col px-4 text-left lg:flex-row lg:space-x-12">
				<div
					class="
						w-full
						px-4
						text-lg
						leading-relaxed
						text-gray-700
						lg:px-0 lg:w-3/4
					"
					v-html="final"
				></div>

				<div class="w-full max-w-screen-sm m-auto mt-12 lg:w-1/4">
					<div class="p-4 border-t border-b md:border md:rounded">
						<div class="flex py-2">
							<img
								:src="blog.authorPicture.url"
								class="object-cover w-10 h-10 mr-2 rounded-full"
							/>
							<div>
								<p
									class="text-sm font-semibold text-gray-700"
									v-text="blog.authorName"
								></p>
								<p class="text-xs font-semibold text-gray-600">Editor</p>
							</div>
						</div>
						<p class="py-3 text-gray-700" v-text="blog.authorDescription"></p>
					</div>
				</div>
				<br />
			</div>
			<div
				class="m-5 text-2xl font-bold text-center pt-12 text-gray-800 heading"
			>
				Video Gallery
			</div>

			<div
				class="
					grid
					w-10/12
					holder
					mx-auto
					sm:grid-cols-1
					md:grid-cols-3
					lg:grid-cols-4
				"
			>
				<!-- {{ videoGalleryCollection }} -->
				<div
					v-for="(item, idx) in videoGalleryCollection.items"
					:key="idx"
					class="relative m-2 mb-10 bg-gray-100 border-gray-800 shadow-lg each"
				>
					<img class="w-full h-30" :src="item.thumbnail.url" alt="" />
					<div
						class="
							absolute
							top-0
							right-0
							p-1
							px-2
							m-1
							text-xs
							font-bold
							text-gray-200
							bg-red-500
							rounded
							badge
						"
					>
						New
					</div>

					<div class="p-4 text-gray-800 desc">
						<a
							:href="item.youtubeLink"
							target="_new"
							class="
								block
								text-lg
								font-bold
								cursor-pointer
								title
								hover:underline
							"
							v-text="item.title"
						></a>
						<span
							class="block py-2 mb-2 text-sm border-gray-400 description"
							v-text="item.description"
						></span>
					</div>
				</div>
			</div>
			<!-- component -->
			<div class="flex items-center justify-center bg-white">
				<div class="container">
					<div class="p-5 mx-2 bg-white rounded-lg shadow-lg md:p-20">
						<div class="text-center">
							<h2
								class="m-5 text-2xl font-bold text-center text-gray-800 heading"
							>
								Follow Me <span class="text-pink-500">On</span>
							</h2>
							<h3 class="mt-10 text-xl md:text-3xl"></h3>
						</div>
						<div class="flex flex-wrap justify-center mt-10">
							<div class="m-3">
								<a
									:href="blog.facebook"
									title="FLirtTool On Facebook"
									class="
										inline-flex
										items-center
										px-6
										py-2
										font-bold
										tracking-wide
										text-gray-800
										bg-white
										border-2 border-blue-600
										rounded
										shadow-md
										md:w-32
										hover:border-blue-600 hover:bg-blue-600 hover:text-white
									"
								>
									<span class="mx-auto">Facebook</span>
								</a>
							</div>
							<div class="m-3">
								<a
									:href="blog.twitter"
									title="Quicktoolz On Twitter"
									class="
										inline-flex
										items-center
										px-6
										py-2
										font-bold
										tracking-wide
										text-gray-800
										bg-white
										border-2 border-blue-500
										rounded
										shadow-md
										md:w-32
										hover:border-blue-500 hover:bg-blue-500 hover:text-white
									"
								>
									<span class="mx-auto">Twitter</span>
								</a>
							</div>
							<div class="m-3">
								<a
									:href="blog.instagram"
									title="Quicktoolz On Pinterest"
									class="
										inline-flex
										items-center
										px-6
										py-2
										font-bold
										tracking-wide
										text-gray-800
										bg-white
										border-2 border-red-600
										rounded
										shadow-md
										md:w-32
										hover:border-red-600 hover:bg-red-600 hover:text-white
									"
								>
									<span class="mx-auto">Instagram</span>
								</a>
							</div>
							<div class="m-3">
								<a
									:href="blog.youtube"
									title="FLirtTool On Facebook"
									class="
										inline-flex
										items-center
										px-6
										py-2
										font-bold
										tracking-wide
										text-gray-800
										bg-white
										border-2 border-orange-500
										rounded
										shadow-md
										md:w-32
										hover:border-orange-500 hover:bg-red-600 hover:text-white
									"
								>
									<span class="mx-auto">Youtube</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div
				class="
					px-4
					py-16
					mx-auto
					sm:max-w-xl
					md:max-w-full
					lg:max-w-screen-xl
					md:px-24
					lg:px-8 lg:py-20
				"
			>
				<div
					class="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full"
				>
					<div
						class="
							overflow-hidden
							transition-shadow
							duration-300
							bg-white
							rounded
							shadow-sm
						"
					>
						<img
							src="https://images.pexels.com/photos/2408666/pexels-photo-2408666.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
							class="object-cover w-full h-64"
							alt=""
						/>
						<div class="p-5 border border-t-0">
							<p class="mb-3 text-xs font-semibold tracking-wide uppercase">
								<a
									href="/"
									class="
										transition-colors
										duration-200
										text-blue-gray-900
										hover:text-deep-purple-accent-700
									"
									aria-label="Category"
									title="traveling"
									>traveling</a
								>
								<span class="text-gray-600">— 28 Dec 2020</span>
							</p>
							<a
								href="/"
								aria-label="Category"
								title="Visit the East"
								class="
									inline-block
									mb-3
									text-2xl
									font-bold
									leading-5
									transition-colors
									duration-200
									hover:text-deep-purple-accent-700
								"
								>Visit the East</a
							>
							<p class="mb-2 text-gray-700">
								Sed ut perspiciatis unde omnis iste natus error sit sed quia
								consequuntur magni voluptatem doloremque.
							</p>
							<a
								href="/"
								aria-label=""
								class="
									inline-flex
									items-center
									font-semibold
									transition-colors
									duration-200
									text-deep-purple-accent-400
									hover:text-deep-purple-800
								"
								>Learn more</a
							>
						</div>
					</div>
					<div
						class="
							overflow-hidden
							transition-shadow
							duration-300
							bg-white
							rounded
							shadow-sm
						"
					>
						<img
							src="https://images.pexels.com/photos/447592/pexels-photo-447592.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
							class="object-cover w-full h-64"
							alt=""
						/>
						<div class="p-5 border border-t-0">
							<p class="mb-3 text-xs font-semibold tracking-wide uppercase">
								<a
									href="/"
									class="
										transition-colors
										duration-200
										text-blue-gray-900
										hover:text-deep-purple-accent-700
									"
									aria-label="Category"
									title="traveling"
									>traveling</a
								>
								<span class="text-gray-600">— 28 Dec 2020</span>
							</p>
							<a
								href="/"
								aria-label="Category"
								title="Simple is better"
								class="
									inline-block
									mb-3
									text-2xl
									font-bold
									leading-5
									transition-colors
									duration-200
									hover:text-deep-purple-accent-700
								"
								>Simple is better</a
							>
							<p class="mb-2 text-gray-700">
								Sed ut perspiciatis unde omnis iste natus error sit sed quia
								consequuntur magni voluptatem doloremque.
							</p>
							<a
								href="/"
								aria-label=""
								class="
									inline-flex
									items-center
									font-semibold
									transition-colors
									duration-200
									text-deep-purple-accent-400
									hover:text-deep-purple-800
								"
								>Learn more</a
							>
						</div>
					</div>
					<div
						class="
							overflow-hidden
							transition-shadow
							duration-300
							bg-white
							rounded
							shadow-sm
						"
					>
						<img
							src="https://images.pexels.com/photos/139829/pexels-photo-139829.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
							class="object-cover w-full h-64"
							alt=""
						/>
						<div class="p-5 border border-t-0">
							<p class="mb-3 text-xs font-semibold tracking-wide uppercase">
								<a
									href="/"
									class="
										transition-colors
										duration-200
										text-blue-gray-900
										hover:text-deep-purple-accent-700
									"
									aria-label="Category"
									title="traveling"
									>traveling</a
								>
								<span class="text-gray-600">— 28 Dec 2020</span>
							</p>
							<a
								href="/"
								aria-label="Category"
								title="Film It!"
								class="
									inline-block
									mb-3
									text-2xl
									font-bold
									leading-5
									transition-colors
									duration-200
									hover:text-deep-purple-accent-700
								"
								>Film It!</a
							>
							<p class="mb-2 text-gray-700">
								Sed ut perspiciatis unde omnis iste natus error sit sed quia
								consequuntur magni voluptatem doloremque.
							</p>
							<a
								href="/"
								aria-label=""
								class="
									inline-flex
									items-center
									font-semibold
									transition-colors
									duration-200
									text-deep-purple-accent-400
									hover:text-deep-purple-800
								"
								>Learn more</a
							>
						</div>
					</div>
				</div>
			</div> -->
		</main>
		<!-- main ends here -->

		<!-- footer -->
	</div>
</template>

<script>
import { BL_DETAIL, VIDEO_GALLERY } from '../queries/cms'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
export default {
	data: () => ({
		blog: {},
		videoGalleryCollection: {},
		final: "",
		limit: 4,
	}),
	apollo: {
		videoGalleryCollection: {
			query: VIDEO_GALLERY,
			client: "cms",
			context: {
				headers: {
					"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
				},
			},
			variables() {
				return {
					limit: this.limit
				}
			}
		},
		blog: {
			client: "cms",
			query: BL_DETAIL,
			context: {
				headers: {
					"Authorization": "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A"
				},
			},
			variables() {
				return {
					id: this.$route.params["id"]
				}
			},
			result({ data }) {
				this.final = documentToHtmlString(data.blog.content.json)
				return data.blog
			}
		}
	}
}
</script>'