<template>
  <!-- <LoginIndex/> -->
  <!-- <MatchesIndex/> -->
  <LikeIndex/>
</template>
<script>
import LikeIndex from '@/templates/Likes/Index.vue'
export default {
  components:{
    LikeIndex
  }
}
</script>