<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div class="globalcompress" style="">
    <!-- <div class="col-span-1 bordered">
      <div class="box-border w-full border-0 md:mx-20">
       
        <div class="hidden lg:block">
          <user-avatar />
          <side-selector />
        </div>
       
      </div>
    </div> -->
    <div
      v-if="likes.length > 0 "
     
       class="grid grid-cols-2 mb-1 mt- sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 justify-start mt-10"
    >
      <!-- <div v-for="(user, idx) in visits" :key="idx" class="-mt-9">
        <user-cards :user="user.visitor" :page="'visitors'"></user-cards>
      </div> -->
      <div v-for="(item, idx) in likes" :key="idx" class="-mt-9">
          <user-cards 
            :user="item.liked_by"
            :page="'likes'" />
        </div>
    </div>
    <!-- empty block to fix padding -->
    <div class="col-span-1"></div>
    <div class="col-span-9 compressedx">
      <!-- Content loader start -->
      <div class="lds-ripple mt-44" v-if="loadingContent">
        <div></div>
        <div></div>
      </div>
      <!-- Content loader end -->

      <!-- <div class="text-left mb-7" v-if="visits.length > 0 && !loadingContent">
        <a class="pb-10 mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="rgb(126 214 0)"
            viewBox="0 0 24 24"
            class="inline-block w-8 h-8 mr-2 text-pink-500 stroke-current  dark:text-white"
          >
            <path
              fill="currentColor"
              d="M22.59,7.92L23.75,9.33L19,14.08L16.25,11.08L17.41,9.92L19,11.5L22.59,7.92M6,5A3,3 0 0,1 9,8A3,3 0 0,1 6,11A3,3 0 0,1 3,8A3,3 0 0,1 6,5M11,5A3,3 0 0,1 14,8A3,3 0 0,1 11,11C10.68,11 10.37,10.95 10.08,10.85C10.65,10.04 11,9.06 11,8C11,6.94 10.65,5.95 10.08,5.14C10.37,5.05 10.68,5 11,5M6,13C8,13 12,14 12,16V18H0V16C0,14 4,13 6,13M12.62,13.16C14.63,13.5 17,14.46 17,16V18H14V16C14,14.82 13.45,13.88 12.62,13.16Z"
            ></path>
          </svg>

          <span
            class="px-5 font-sans text-sm text-center text-pink-500 uppercase  font-semi-bold text-md dark:text-white card-title"
          >
            Visitors
          </span>
        </a>
      </div> -->

      <!-- <button
        v-if="visits.length > 0 && !loadingContent"
        class="mb-10  btn btn-accent rounded-xl btn-lg btn-outline btn-wide btn-warning"
      >
        <a style="color: #dc4b78"> Load More </a>
      </button> -->
    </div>
  </div>
</template>
<style scoped></style>
<script>
// import UserAvatar from "../../components/userAvatar.vue";
// import SideSelector from "../../components/sideSelector.vue";
import { LIKES } from "../queries/matches";
// import { getURL } from "../utils/helpers";
import UserCards from "../components/userCards.vue";
const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    // UserAvatar,
    // SideSelector,
    UserCards,
  },
  apollo: {
    likes: {
      query: LIKES,
    },
  },
  data: () => ({
    features,
    matches: [],
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    dislikes: null,
    mobile: false,
  }),
  methods: {
    // getURL,
    // clicked() {
    //   alert("clicked");
    // },
    // detectOrientationChange() {
    //   switch (window.orientation) {
    //     case -90 || 90:
    //       // landscape
    //       this.mobile = false;
    //       break;
    //     default:
    //       // portrait
    //       this.mobile = true;
    //       break;
    //   }
    // },
    popItem(idx) {
      this.likes.splice(idx, 1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener(
        "onorientationchange",
        this.detectOrientationChange
      );
    });
  },
  created() {
    this.$apollo.queries.likes.refetch();
    this.detectOrientationChange(); // when instance is created
  },
};
</script>
<style>
@import "../assets/css/style.css";
</style>
I
