<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolLogin from './FlirttoolLogin.vue'
import YellowLogin from './YellowLogin.vue'

import EroticLogin from './EroticLogin.vue'
import PurplethemeLogin from './PurplethemeLogin.vue'
import RedthemeLogin from './RedthemeLogin.vue'
export default {
  components: {
    FlirttoolLogin,
    EroticLogin,
    PurplethemeLogin,
    YellowLogin,
    RedthemeLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolLogin'
        case 'Erotic':
          return 'EroticLogin'
        case 'Yellow':
          return 'YellowLogin'
          case 'Purple':
          return 'PurplethemeLogin'
          case 'Red':
            return 'RedthemeLogin'
        default:
          return 'FlirttoolLogin'
      }
    }
  }
}
</script>
