<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolFlirts from './FlirttoolFlirts.vue'
import YellowFlirts from './YellowFlirts.vue'
import PurpleFlirts from './PurpleFlirts.vue'
import RedFlirts from './RedFlirts.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirttoolFlirts,
    YellowFlirts,
    PurpleFlirts,
    RedFlirts
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolFlirts'
        case 'Erotic':
          return 'FlirttoolFlirts'
        case 'Yellow':
          return 'YellowFlirts'
          case 'Purple':
          return 'PurpleFlirts'
          case 'Red':
            return 'RedFlirts'
        default:
          return 'FlirttoolFlirts'
      }
    }
  }
}
</script>
