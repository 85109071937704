<template>
    <component :is="appliedComponent" />
   </template>

<script>
import {activeTheme} from '@/themes.js'
import FlirttoolPrivacy from './FlirttoolPrivacy.vue'
import PurplePrivacy from './PurplePrivacy.vue'
import RedPrivacy from './RedPrivacy.vue'

export default {
 components: {
   FlirttoolPrivacy,
   // YellowPrivacy,
   PurplePrivacy,
   RedPrivacy
   // EroticLogin
 },
 computed: {
   appliedComponent:function() {
     switch(activeTheme) {
       case 'Flirttool':
         return 'FlirttoolPrivacy'
       // case 'Erotic':
       //   return 'FlirttoolPrivacy'
       // case 'Yellow':
       //   return 'YellowPrivacy'
         case 'Purple':
         return 'PurplePrivacy'
         case 'Red':
          return 'RedPrivacy'
       default:
         return 'FlirttoolPrivacy'
     }
   }
 }
}

</script>

