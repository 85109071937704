<template>
  <div>
    <div
      class="flex mb-8 justify-center h-1/2"
      style="
        background: url(https://i.picsum.photos/id/1057/536/354.jpg?hmac=Bx6hxppJnxgdVDhSwJp0YFx6GxVMKj8XIRbuQmKysz4);
        background-repeat: no-repeat;
        background-size: cover;
      "
    >
      <div class="mb-20 mt-10 px-2">
        <center>
          <router-link to="/">
            <img src="@/assets/Flirttoolhelpcenter.png" class="w-1/5" alt="" />
          </router-link>
        </center>
        <p class="font-semibold text-xl py-5 text-white">
          Here you will find guides to use flirttool and answeres for any
          questions you may have
        </p>
        <input
          class="
            hidden
            shadow
            appearance-none
            border
            rounded-xl
            max-w-7xl
            h-12
            w-full
            py-2
            px-3
            text-gray-700
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          type="text"
          placeholder="search for anything..."
        />
      </div>
    </div>
    <div
      class="flex justify-center my-2"
      v-for="(item, i) in helpItems"
      :key="i"
    >
      <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
        <router-link
          class="
            btn
            w-72
            h-16
            hover:bg-pink-300 hover:text-white hover:shadow-2xl
            delay-75
          "
          :to="btn.link"
          v-for="(btn, ix) in item"
          :key="ix"
        >
          {{ btn.text }}
        </router-link>
      </div>
    </div>
    <div style="flex-grow: 1" class="mb-72"></div>
    <page-footer class="mt-auto" />
  </div>
</template>
<script>
import pageFooter from "../pageFooter.vue";
export default {
  components: { pageFooter },
  data: () => ({
    helpItems: [
      [
        { text: "The FlirtTool Guide", link: "/guide" },
        { text: "Trouble Shooting", link: "/troubleshooting" },
        { text: "Community GuideLines", link: "/community-guidelines" },
        // { text: "Second Title", link:"" },
        // { text: "Third Title", link:"" },
      ],
      [{ text: "Safety And Privacy", link: "/safety-and-privacy" }],
    ],
  }),
};
</script>