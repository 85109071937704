<template>
  <div>
    <notification-bar />
    <!-- checkout popup start -->
    <div>
      <transition name="modalx">
        <div v-if="modal" class="dark:bg-white">
          <div class="overlay">
            <div
              class="modalx my-10 dark:bg-gray-800 bg-white mx-10"
              v-click-outside="clickedOutside"
            >
              <h1
                class="text-3xl font-black dark:text-gray-200"
                v-text="event.name"
              ></h1>
              <p class="text-xl font-sans font-normal pb-5 dark:text-gray-200">
                Select your payment option
              </p>
              <div
                v-if="event.price > 0"
                class="
                  grid grid-cols-1
                  sm:grid-cols-2
                  md:grid-cols-3
                  lg:grid-cols-2
                "
              >
                <button
                  class="
                    mx-2
                    btn btn-md
                    border-none
                    paypal
                    valign-wrapper
                    bg-yellow-400
                    hover:bg-yellow-500
                  "
                  @click="checkOutPayal()"
                >
                  <svg
                    v-if="loadingPaypal"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    :class="{ 'animate-spin': loadingPaypal }"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    class="text-blue-800"
                  >
                    <path
                      fill="currentColor"
                      d="M8.32,21.97C8.21,21.92 8.08,21.76 8.06,21.65C8.03,21.5 8,21.76 8.66,17.56C9.26,13.76 9.25,13.82 9.33,13.71C9.46,13.54 9.44,13.54 10.94,13.53C12.26,13.5 12.54,13.5 13.13,13.41C16.38,12.96 18.39,11.05 19.09,7.75C19.13,7.53 19.17,7.34 19.18,7.34C19.18,7.33 19.25,7.38 19.33,7.44C20.36,8.22 20.71,9.66 20.32,11.58C19.86,13.87 18.64,15.39 16.74,16.04C15.93,16.32 15.25,16.43 14.05,16.46C13.25,16.5 13.23,16.5 13,16.65C12.83,16.82 12.84,16.79 12.45,19.2C12.18,20.9 12.08,21.45 12.04,21.55C11.97,21.71 11.83,21.85 11.67,21.93L11.56,22H10C8.71,22 8.38,22 8.32,21.97V21.97M3.82,19.74C3.63,19.64 3.5,19.47 3.5,19.27C3.5,19 6.11,2.68 6.18,2.5C6.27,2.32 6.5,2.13 6.68,2.06L6.83,2H10.36C14.27,2 14.12,2 15,2.2C17.62,2.75 18.82,4.5 18.37,7.13C17.87,10.06 16.39,11.8 13.87,12.43C13,12.64 12.39,12.7 10.73,12.7C9.42,12.7 9.32,12.71 9.06,12.85C8.8,13 8.59,13.27 8.5,13.6C8.46,13.67 8.23,15.07 7.97,16.7C7.71,18.33 7.5,19.69 7.5,19.72L7.47,19.78H5.69C4.11,19.78 3.89,19.78 3.82,19.74V19.74Z"
                    />
                  </svg>
                  <span class="font-sans text-xl pl-2"
                    >Pay<span class="text-blue-500">pal</span></span
                  >
                  <svg
                    class="spinner"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      class="spinner__path"
                      fill="none"
                      stroke-width="7"
                      stroke-linecap="round"
                      cx="33"
                      cy="33"
                      r="29"
                    ></circle>
                  </svg>
                </button>
                <!-- end of paypal -->
                <!-- start of stripe -->

                <button
                  class="
                    mx-2
                    btn
                    stripe
                    valign-wrapper
                    bg-pink-500
                    hover:bg-pink-600
                  "
                  @click="stripeCheckout"
                >
                  <svg
                    v-if="loadingStripe"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    :class="{ 'animate-spin': loadingStripe }"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                    />
                  </svg>
                  Card
                </button>
              </div>

              <div class="alert bg-pink-100 mt-4 mx-2" v-if="authErr">
                <div class="flex-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="w-6 h-6 mx-2 stroke-current"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    ></path>
                  </svg>
                  <label>
                    {{ authErr }}
                  </label>
                </div>
              </div>
              <div class="form-control w-full mt-2">
                <label class="label">
                  <span class="label-text dark:text-gray-200"
                    >Enter your coupon code here</span
                  >
                </label>
                <input
                  v-model="couponInput"
                  type="text"
                  placeholder="coupon code"
                  class="input input-bordered w-full dark:text-gray-200"
                />
                <button
                  class="btn btn-active bg-pink-100 hover:bg-pink-200 mt-3"
                  role="button"
                  @click="
                    selectedPkg.price == 0
                      ? verifyAndActivate()
                      : verifyCoupon()
                  "
                >
                  Verify
                </button>
                <!-- <button class="btn">Go</button> -->
              </div>

              <!-- end of stripe -->
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- checkout popup end -->
    <div
      class="
        "
    >
      <div
        class="
          w-full
          max-w-6xl
          rounded
          bg-white
          dark:bg-gray-800
          shadow-xl
          p-10
          lg:p-20
          mx-auto
          text-gray-800
          relative
          md:text-left
        "
      >
        <div class="md:flex items-center -mx-10">
          <div class="w-full md:w-1/2 px-10 mb-10 md:mb-0">
            <div class="relative">
              <img
                :src="getURL(event.cover)"
                class="w-full relative z-0"
                alt=""
              />
            </div>
          </div>
          <div class="w-full md:w-2/3 lg: px-10 dark:text-white">
            <div class="mb-10">
              <h1
                class="font-bold uppercase text-2xl mb-5"
                v-text="event.name"
              ></h1>
              <p class="text-sm">
                {{ event.detail }}
              </p>
            </div>
            <div>
              <div class="inline-block align-bottom mr-5">
                <span class="text-2xl leading-none align-baseline">$</span>
                <span
                  class="font-bold text-4xl leading-none align-baseline"
                  v-text="event.price"
                ></span>
              </div>
              <div class="inline-block align-bottom mt-3">
                <button
                  class="
                    bg-yellow-300
                    opacity-75
                    hover:opacity-100
                    text-yellow-900
                    hover:text-gray-900
                    rounded-full
                    px-10
                    py-2
                    font-semibold
                  "
                  v-if="false"
                  @click="initCheckout"
                >
                  <i class="mdi mdi-cart -ml-2 mr-2"></i> BUY TICKETS NOW
                </button>
                <button
                  class="
                    bg-yellow-300
                    opacity-75
                    hover:opacity-100
                    text-yellow-900
                    hover:text-gray-900
                    rounded-full
                    px-10
                    py-2
                    font-semibold
                  "
                  @click="initFreeTicket"
                >
                  <i class="mdi mdi-cart -ml-2 mr-2"></i> GET FREE ACCESS
                </button>
                <router-link
                  class="
                    bg-yellow-300
                    opacity-75
                    hover:opacity-100
                    text-yellow-900
                    hover:text-gray-900
                    rounded-full
                    px-10
                    py-2
                    font-semibold
                  "
                  to="/flirts"
                >
                  <i class="mdi mdi-cart -ml-2 mr-2"></i>Join With Infinity
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-center">
          <div class="bg-slate-800">
            <div
              class="
                bg-slate-700
                shadow-md
                rounded rounded-xl
                m-16
                border border-indigo-500/50
                shadow-xl shadow-indigo-500/50
              "
            >
              <div
                class="
                  flex flex-col
                  p-10
                  px-16
                  space-y-6
                  items-center
                  text-center
                "
              >
                <h1
                  class="
                    font-light
                    md:text-2xl
                    text-2xl
                    uppercase
                    dark:text-white
                    text-gray-800
                    tracking-wide
                  "
                >
                  More Events
                  <span
                    id="spin"
                    class="
                      text-transparent
                      font-normal
                      bg-clip-text bg-gradient-to-r
                      from-indigo-400
                      to-cyan-300
                    "
                  ></span>
                  <br />
                </h1>
                <p class="text-gray-400 md:text-2xl text-xl px-18">
                  All Videos in one corner! Enjoy our media center for free
                </p>
                <router-link
                  to="/events"
                  class="
                    rounded-full
                    bg-pink-500
                    shadow-lg shadow-indigo-500/50
                    font-sans
                    text-white text-lg
                    py-4
                    px-6
                  "
                >
                  Back to Event List
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
import { GET_EVENT } from "../../queries/auth";
import { getURL } from "../../utils/helpers";
import {
  STRIPE_EVENT_CHECKOUT,
  PAYPAL_EVENT_CHECKOUT,
  VERIFY_CPN,
} from "../../queries/matches";
import NotificationBar from '../../components/NotificationBar.vue';

Vue.use(VueConfetti);

export default {
  components: { NotificationBar },
  data: () => ({
    event: {},
    sevent: null,
    loadingPaypal: false,
    loadingStripe: false,
    modal: false,
  }),
  created() {
    this.getEvent();
  },
  methods: {
    getURL,
    initFreeTicket(){
      window.open(this.event.access_link, true)
    },
    initCheckout() {
      this.modal = true;
    },
    verifyCoupon() {
      console.warn(this.couponInput, "Asdf");
      if (this.couponInput != "") {
        this.$apollo
          .mutate({
            mutation: VERIFY_CPN,
            variables: {
              input: {
                code: this.couponInput,
                pkg: JSON.stringify(this.selectedPkg.id),
              },
            },
          })
          .then(
            ({
              data: {
                verifyCoupon: { id },
              },
            }) => {
              this.discountObj = JSON.stringify(id);
              this.authErr = null;
              this.modal = false;
              this.modalV = true;
            }
          )
          .catch(() => {
            this.authErr = "coupon code is invalid";
          });
      } else {
        this.authErr = "coupon code can't be empty";
        return;
      }
    },
    clickedOutside() {
      this.modal = false;
    },
    getEvent() {
      this.$apollo
        .query({
          query: GET_EVENT,
          variables: {
            event: this.$route.params["id"],
          },
          fetchPolicy: "network-only",
        })
        .then(({ data: { flirttoolEvent } }) => {
          this.event = flirttoolEvent;
        })
        .catch((e) => console.error(e));
    },
    checkOutPayal() {
      this.loadingPaypal = true;
      this.$apollo
        .mutate({
          mutation: PAYPAL_EVENT_CHECKOUT,
          variables: {
            input: {
              event: this.event.packageId,
            },
          },
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loadingPaypal = false;
          const link = data.createPaypalEventCheckout.find(
            (x) => x.rel == "approval_url"
          );
          console.warn(link, "dawg");
          // Define height and width of popup
          window.open(link.href, false);
        })
        .catch(() => {
          this.loadingPaypal = false;
        });
    },
    stripeCheckout() {
      this.loadingStripe = true;
      this.$apollo
        .mutate({
          mutation: STRIPE_EVENT_CHECKOUT,
          variables: {
            input: {
              event: this.event.packageId,
            },
          },
        })
        .then(({ data }) => {
          window.open(data.createEventsStripeCheckout);
          this.loadingStripe = false;
        })
        .catch(() => {
          this.loadingStripe = false;
        });
    },
    start() {
      this.$confetti.start();
    },

    stop() {
      this.$confetti.stop();
    },

    love() {
      this.$confetti.update({
        particles: [
          {
            type: "heart",
          },
          {
            type: "circle",
          },
        ],
        defaultColors: ["red", "pink", "#ba0000"],
      });
    },
  },
};
</script>
<style scoped>
.modalx {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
}

.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modalx,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}
</style>