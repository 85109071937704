<template>
  <div>
    <div
      class="flex flex-wrap w-full h-full object-cover justify-center"
      style="object-fit: cover; width: 100%; height: 100%"
    >
      <!-- Login Section -->
      <img
        :src="require('@/assets/flirttoolbackground.jpg')"
        style="filter: blur(4px)"
        class="absolute inset-0 justify-center object-cover w-full h-screen sm:"
        alt=""
      />
      <div
        class="
          px-4
          py-12
          mx-auto
          sm:max-w-xl
          md:max-w-full
          lg:max-w-screen-lg
          md:px-24
          lg:px-8 lg:py-12
        "
      >
        <div
          class="
            flex flex-col
            justify-center
            bg-white
            shadow-2xl
            rounded-3xl
            opacity-95
            px-8
            pt-8
            my-auto
            lg:my-10
            xl:my-10
            md:justify-start md:pt-0 md:px-24
            relative
            h-full
            lg:full
            text-left
          "
        >
          <router-link to="/">
            <div
              class="
                flex flex-col flex-wrap
                content-center
                mb-5
                xl:mb-16
                lg:mb-16
                md:mb-16
                sm:mb-10
                mt-5
              "
            >
            <img :src="getCommunityMeta.logo" class="h-16" alt="">
            </div>
          </router-link>
          <div v-if="!sendConfirmation">
            <p class="text-2xl font-bold font-sans">Reset Your Password</p>
            <p>
             {{$t('resetlink')}}
            </p>
            <p class="font-black text-red-600" v-if="error">Sorry we can't find your email in our system</p>
            <input
              class="
                px-3
                py-2
                w-full
                mt-5
                mb-5
                border-2 border-gray-300
                rounded-full
                form__input
                shadow-xl
                dark:bg-gray-700
                focus:outline-none
                focus:ring-2
                focus:ring-gray-400
                focus:border-transparent
                dark:text-gray-200
              "
              v-model="resetEmail"
              id="my-textfield"
              type="text"
              value=""
              placeholder="E-mail Address"
            />
            <button
              @click="sendReset"
              type="button"
              class="
                mx-2
                mb-16
                w-full
                h-12
                btn-sm
                bg-gray-600
                hover:bg-gray-700
                btn btn-primary glass
              "
            >
              <span class="py-2">Reset Password</span>
            </button>
          </div>
          <div class="lg:max-w-lg" v-else>
            <p class="text-3xl font-bold font-sans">Reset Link Sent</p>
            <p class="font-sans pb-10">
              You should recieve a confirmation E-mail at
              <b>{{ resetEmail }}</b> click on your confirmation link for an
              option to reset your password
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RESET_PASS } from "../queries/auth";
import { getCommunityMeta } from "@/utils/helpers.js";
export default {
  components:{getCommunityMeta},
  methods: {
    sendReset() {
      this.$apollo
        .mutate({
          mutation: RESET_PASS,
          variables: {
            email: this.resetEmail,
          },
        })
        .then(({ data }) => {
          this.sendConfirmation = true;
          this.error = false;
          console.warn(data);
        })
        .catch((e) => {
          this.error = true;
          console.error(e, "error verifying your email");
        });
    },
  },
  data: () => ({
    getCommunityMeta,
    error: false,
    resetEmail: "",
    sendConfirmation: false,
  }),
};
</script>