export default {
  locale: "be",
  messages: {
    en: {
      Register:"Register",
      secondtext:"offers you new levels of online dating. Global Chat ultimate creation of AI personality will help users be paired with someone that is 94% their perfect match",
      SomeRandomHeadingGoesHere:"Some Random Text Goes Here",
      noflirtsrecharge: "You dont have enough credits",
      heygetflirts: "Get flirts",
      SEARCHINGFORNOTIFICAITON:"SEARCHING FOR NOTIFICAITON",
      Newconversation:"Newconversation",
      MyPurchases: "My Purchases",
      mypurchasedes:
        "Here you will find the flirts and packages you have bought.",
      mypurchacedess: "You dont have any transactions yet",
      resetlink:
        "Enter your E-mail address and we will send you your reset link",
      // transaction

      ChatsConversation: "CHATS (CONVERSATION)",
      message: "Message",
      likes: "likes",
      matches: "Matches",
      Liked: "Liked",
      Disliked: "Disliked",
      JustVisitedYou: "Just Visited You",
      LoadMore: "Load More",
      FliterUsers: "Filter Users",
      Youdonthaveanynotifications: "You dont have any notifications",
      ActiveNow: "Active Now",

      // landing
      SignIn: "Sign In",
      FlirtToolNews: "FlirtTool News",
      Join: "Join",
      Free: "Free",
      Today: "Today",
      BasicPackage: "Basic Package",
      twohoursfreechatexperience: "2 hours free chat experience",
      Tryitforfree: "Try it for free",
      FlirtToolfor1Day: "FlirtTool for 1 Day",
      Gblobalfor1Day:"Gloabl for 1 Day",
      RealChatFlatrate: "Real Chat Flatrate ONLY $2",
      Grabit: "Grab it",
      Createanaccount: "Create an account",
      orgettheapp: "or get the app",
      OVER1MILLIONUSERSWORLDWIDE: "Over 1 Million Users worldwide",
      MatchingProfiles: "Matching Profiles",
      UpcomingonFlirtTool: "Upcoming on FlirtTool",
      FindRealLifeWithFlirtTool: "Find Real Life With FlirtTool!",
      HowFlirtToolWorks: "How FlirtTool Works",
      HowFlirtToolWorksdes:
        " FlirtTool offers you new levels of online dating. FlirtTools ultimate creation of AI personality MAZU will help users be paired with someone that is 94% their perfect match",
      LEARNMORE: "LEARN MORE",
      About: "About",
      PressAndMedia: "Press And Media",
      Blogs: "Blogs",
      LiveChat: "Live Chat",
      ChatNow: "Chat Now",
      SocialMedia: "Social Media",
      ContactUs: "Contact Us",
      WelcometoFlirttool: "Welcome to Flirttool",
      // end of landing

      // login
      OrCreateanAccount: "Or Create an Account",
      UsernameorEmail: "Username or Email",
      YourPassword: "Your Password",
      Forgotpassword: "Forgot password",
      Reset: "Reset",
      IAGREE: "I AGREE TO THE TERMS, CONDITIONS AND PRIVACY POLICY",
      Clickhereto: "CLICK HERE TO VIEW OUR TERMS",

      // login

      // register
      FIRSTNAME: "FIRST NAME",
      LastName: "Last Name",
      Emailisnotvalid: "Email is not valid",
      Username: "Username",
      Yourpasswords: "Your password",
      Passwordisnotvalid: "Password is not valid",
      Confirmpassword: "Confirm password",
      Usernamemusthaveatleast: "Username must have at least",
      Alreadyhaveanaccount: "Already have an account",
      LoginHere: "Login Here",
      // register
      starthourlypackage: " Start Hourly Package",
      // Profile
      SubmitReport: "Submit Report",
      Blockcon: "Are you sure want block",
      Yes: "Yes",
      No: "No",
      reportreason: "Enter you reason for this report",
      Visits: "Visits",
      Photos: "Photos",
      UnlockExclusive: "Unlock Exclusive Pictures",
      UnlockNow: "Unlock Now",
      Personal: "Personal",
      Ethnicity: "Ethnicity",
      BodyType: "BodyType",
      Gender: "Gender",
      Height: "Height",
      Relationship: "Relationship",
      Work: "Work",
      Education: "Education",
      LIFESTYLE: "LIFE STYLE",
      LivesWith: "Lives With",
      Car: "Car",
      Religion: "Religion",
      Smoke: "Smoke",
      Drink: "Drink",
      Travel: "Travel",
      Education: "Education",
      // profile

      // settings
      PhoneNumber: "Phone Number",
      GeneralSettings: " General Settings",
      SaveChanges: "Save Changes",
      RemoveAccount: "Remove Account",
      Yourprofile: "Your profile",
      AboutMe: "About Me",
      Relationshipstatus: "Relationship status",
      YourCountry: "Your Country",
      ChooseYourCountry: "Choose Your Country",
      Looks: "Looks",
      Personality: "Personality",
      Character: "Character",
      Children: "Children",
      Friends: "Friends",
      Pets: "Pets",
      LivingWith: "Living With",
      MusicGenre: "MusicGenre",
      Dish: "Dish",
      Song: "Song",
      Hobby: "Hobby",
      Book: "Book",
      Movie: "Movie",
      ChangePassword: "ChangePassword",
      NewPassword: "NewPassword",

      // settings

      // profile setup
      SETUPYOURDATINGPROFILE: "SETUP YOUR DATING PROFILE",
      uploadphoto: "Upload photo to set your Profile Picture",
      picnotupdated: "Profile picture is not updated",
      pleaseupdatepicture: "You should upload profile picture to continue",
      BirthYear: "Birth Year",
      Date: "Date",
      Month: "Month",
      Day: "Day",
      SexualPreference: "Sexual Preference",
      SexualPreferences: "Whats your sexual prefrence",
      countrycode: "country code",
      PhonNumber: "Phone Number",
      // profile setup

      nolikesyet: "You dont have any Likes yet",
      nomatches: "You dont have any matches yet",
      likematches: "You can start matching with profile by liking them",
      terms:"Terms",
      Privacy:"Privacy",
      imprint:"Imprint",
      Address: "Address"
    },
    // be: {
    //   noflirtsrecharge: "Yeterli krediniz yok",
    //   heygetflirts: "Flörtleri al",
    //   // transcation
    //   MyPurchases: "Satın Alımlarım",
    //   mypurchasedes:
    //     "Burada satın aldığınız flörtler ve paketleri bulacaksınız",
    //   mypurchacedess: "Henüz herhangi bir işlem yapmadınız",
    //   resetlink:
    //     "E-posta adresinizi girin ve size sıfırlama bağlantısını göndereceğiz",

    //   // transaction

    //   ChatsConversation: "Konuşmalar or Sohbetler",
    //   likematches: "Profilleri beğenerek eşleşmeye başlayabilirsiniz.",
    //   message: "mesaj",
    //   likes: "beğeniler",
    //   matches: "Eşleşmeler",
    //   Liked: "Beğendi",
    //   Disliked: "Beğenmedi",
    //   LoadMore: "Daha Fazla Yükle",
    //   FliterUsers: "Kullanıcıları Filtrele",
    //   Youdonthaveanynotifications: "Sizin herhangi bir bildiriminiz yok.",
    //   ActiveNow: "Şu Anda Aktif",

    //   // ladning

    //   SignIn: "Giriş Yap",
    //   FlirtToolNews: "FlirtTool Haberler",
    //   Join: "Bugün",
    //   Free: "ücretsiz",
    //   Today: "katıl",
    //   BasicPackage: "Standart Paket",
    //   twohoursfreechatexperience: "2 saat ücretsiz konuşma deneyimi",
    //   Tryitforfree: "Ücretsiz deneyin",
    //   FlirtToolfor1Day: "1 Gün için FlirtTool.",
    //   RealChatFlatrate: "Gerçek Sohbet Sabit Ücret SADECE $2",
    //   Grabit: "Kapın",
    //   Createanaccount: "Bir hesap oluşturun",
    //   orgettheapp: "ya da mobil uygulamayı indir",
    //   OVER1MILLIONUSERSWORLDWIDE: " Dünya çapına 1 milyon kullanıcı",
    //   MatchingProfiles: "Eşleşen Profil",
    //   UpcomingonFlirtTool: "FlirtTool da Yakında",
    //   FindRealLifeWithFlirtTool: "FlirtTool ile Gerçek Hayatı Bulun!",
    //   HowFlirtToolWorks: "FlirtTool Nasıl Çalışır?",
    //   HowFlirtToolWorksdes:
    //     "FlirtTool size çevrimiçi flörtün yeni seviyelerini sunuyor. FlirtTool un son derece gelişmiş yapay zeka kişiliği MAZU, kullanıcıların %94 lük mükemmel eşleşmeleriyle eşleşmelerine yardımcı olacak.",
    //   LEARNMORE: "Daha Fazla Öğren",
    //   About: "Hakkımızda",
    //   PressAndMedia: "Basın ve medya",
    //   Blogs: "Bloglar",
    //   LiveChat: "Canlı destek",
    //   ChatNow: "Şimdi konuş",
    //   SocialMedia: "SOSYAL MEDYA",
    //   ContactUs: "BİZİMLE İLETİŞİME GEÇİN",
    //   WelcometoFlirttool: "Flirttoola hoş geldiniz",
    //   // landing
    //   // login
    //   OrCreateanAccount: "ya da hesap aç",
    //   UsernameorEmail: "Kullanıcı adı ya da E-mail",
    //   YourPassword: "Şifre",
    //   Forgotpassword: "ŞİFREMİ UNUTTUM",
    //   Reset: "SIFIRLA",
    //   IAGREE: "ŞARTLAR, KOŞULLAR VE GİZLİLİK POLİTİKASINI KABUL EDİYORUM",
    //   Clickhereto: "ŞARTLARIMIZI GÖRÜNTÜLEMEK İÇİN BURAYA TIKLAYIN",

    //   // login

    //   // register

    //   FIRSTNAME: "İsim",
    //   LastName: "Soyadı",
    //   Emailisnotvalid: "E-posta geçerli değil",
    //   Username: "Kullanıcı Adı",
    //   Yourpasswords: "Parolanız",
    //   Passwordisnotvalid: "Parola geçerli değil",
    //   Confirmpassword: "Parolayı Onayla",
    //   Usernamemusthaveatleast: "Kullanıcı adı en az sahip olmalıdır",
    //   Alreadyhaveanaccount: "Zaten bir hesabınız var",
    //   LoginHere: "Buradan Giriş Yapın",
    //   // register

    //   // profile
    //   SubmitReport: "Rapor Gönder",
    //   Blockcon: "Engellemek istediğinizden emin misiniz?",
    //   Yes: "Evet",
    //   No: "Hayır",
    //   reportreason: "Bu rapor için nedeninizi girin",
    //   Visits: "Ziyaretler",
    //   Photos: "Fotoğraflar",
    //   UnlockExclusive: "Özel Resimleri Aç",
    //   UnlockNow: "Şimdi Aç",
    //   Personal: "Kişisel",
    //   Ethnicity: "Etnik Köken",
    //   BodyType: "Vücut Tipi",
    //   Gender: "Cinsiyet",
    //   Height: "Boy",
    //   Relationship: "İlişki Durumu",
    //   Work: "Çalışma Durumu",
    //   Education: "Eğitim",
    //   LIFESTYLE: "YAŞAM TARZI",
    //   LivesWith: "Kiminle Yaşıyor?",
    //   Car: "Araba",
    //   Religion: "Din",
    //   Smoke: "Sigara",
    //   Drink: "Alkol",
    //   Travel: "Seyahat",
    //   Education: "Eğitim",
    //   HairColor: "Hair Color",

    //   // profile

    //   // settings

    //   PhoneNumber: "Telefon Numarası",
    //   GeneralSettings: "Genel Ayarlar",
    //   SaveChanges: "Değişiklikleri Kaydet",
    //   RemoveAccount: "Hesabı Kaldır",
    //   Yourprofile: "Profiliniz",
    //   AboutMe: "Hakkımda",
    //   Relationshipstatus: "İlişki Durumu",
    //   YourCountry: "Ülkeniz",
    //   ChooseYourCountry: "Ülkenizi Seçin",
    //   Looks: "Görünüm",
    //   Personality: "Kişilik",
    //   Character: "Karakter",
    //   Children: "Çocuklar",
    //   Friends: "Arkadaşlar",
    //   Pets: "Evcil Hayvanlar",
    //   LivingWith: "Kiminle Yaşıyor?",
    //   MusicGenre: "Müzik Türü",
    //   Dish: "Yemek",
    //   Song: "Şarkı",
    //   Hobby: "Hobi",
    //   Book: "Kitap",
    //   Movie: "Film",
    //   ChangePassword: "Şifre Değiştir",
    //   NewPassword: "Yeni Şifre",

    //   // setiings

    //   // profile
    //   SETUPYOURDATINGPROFILE: "DATING PROFİLİNİZİ AYARLAYIN",
    //   uploadphoto: "Profil Resminizi Ayarlamak İçin Fotoğraf Yükleyin",
    //   picnotupdated: "Profil resmi güncellenmedi",
    //   pleaseupdatepicture: "Devam etmek için profil resmi yüklemelisiniz",
    //   BirthYear: "Doğum Yılı",
    //   Date: "Tarih",
    //   Month: "Ay",
    //   Day: "Gün",
    //   SexualPreference: "Cinsel Tercih",
    //   SexualPreferences: "Cinsel tercihiniz nedir?",
    //   countrycode: "Ülke Kodu",
    //   PhonNumber: "Telefon Numarası",

    //   // profile
    //   nolikesyet: "Henüz hiç Beğeni almadınız",
    //   nomatches: "Henüz eşleşmeniz yok",
    // },
    // German
    de: {
      Register:"Registrieren",
      noflirtsrecharge: "No tienes suficientes créditos",
      heygetflirts: "Obtener flirts",
      resetlink:
        "Ingresa tu dirección de correo electrónico y te enviaremos el enlace de reinicio.",

      MyPurchases: "Mis Compras",
      mypurchasedes:
        "Aquí encontrarás los coqueteos y paquetes que has comprado",
      mypurchacedess: "Todavía no tienes ninguna transacción",
      ChatsConversation: "Chats or Conversación",
      likematches:
        "Puedes empezar a hacer coincidencias con perfiles dándoles Me gusta",
      message: "Mensaje",
      likes: "Me gusta",
      matches: "Coincidencias",
      Liked: "Gustado",
      Disliked: "No Gustado",
      JustVisitedYou: "Te Acaba de Visitar",
      LoadMore: "Cargar Más",
      FliterUsers: "Filtrar Usuarios",
      Youdonthaveanynotifications: "No tienes notificaciones",
      ActiveNow: "Activo Ahora",

      // landing
      SignIn: "Iniciar Sesión",
      FlirtToolNews: "Noticias de FlirtTool",
      Join: "Unirse",
      Free: "Gratis",
      Today: "Hoy",
      BasicPackage: "Paquete Básico",
      twohoursfreechatexperience: "2 horas de experiencia de chat gratis",
      Tryitforfree: "Pruébalo gratis",
      FlirtToolfor1Day: "FlirtTool por 1 Día",
      Gblobalfor1Day:"GlobalChat por 1 Día",
      RealChatFlatrate: "Tarifa Plana de Chat Real SOLO $2",
      Grabit: "Agárralo",
      Createanaccount: "Crear una cuenta",
      orgettheapp: "o consigue la aplicación",
      OVER1MILLIONUSERSWORLDWIDE:
        "Más de 1 millón de usuarios en todo el mundo",
      MatchingProfiles: "Perfiles Coincidentes",
      UpcomingonFlirtTool: "Próximamente en FlirtTool",
      FindRealLifeWithFlirtTool: "Encuentra la Vida Real con FlirtTool",
      HowFlirtToolWorks: "Cómo Funciona FlirtTool",
      HowFlirtToolWorksdes:
        "FlirtTool te ofrece nuevos niveles de citas en línea. La creación definitiva de personalidad de IA de FlirtTool, MAZU, ayudará a los usuarios a emparejarse con alguien que es un 94% su pareja perfecta",
      LEARNMORE: "APRENDE MÁS",
      About: "Acerca de",
      PressAndMedia: "Prensa y Medios",
      Blogs: "Blogs",
      LiveChat: "Chat en Vivo",
      ChatNow: "Chatea Ahora",
      SocialMedia: "Redes Sociales",
      ContactUs: "Contáctanos",
      WelcometoFlirttool: "Bienvenido a Flirttool",
      // end of landing

      // login
      OrCreateanAccount: "O Crea una Cuenta",
      UsernameorEmail: "Nombre de Usuario o Correo Electrónico",
      YourPassword: "Tu Contraseña",
      Forgotpassword: "¿Olvidaste tu contraseña?",
      Reset: "Restablecer",
      IAGREE: "ACEPTO LOS TÉRMINOS, CONDICIONES Y POLÍTICA DE PRIVACIDAD",
      Clickhereto: "HAGA CLIC AQUÍ PARA VER NUESTROS TÉRMINOS",

      // register
      Register:"Register",
      FIRSTNAME: "NOMBRE",
      LastName: "Apellido",
      Emailisnotvalid: "El correo electrónico no es válido",
      Username: "Nombre de Usuario",
      Yourpasswords: "Tu Contraseña",
      Passwordisnotvalid: "La contraseña no es válida",
      Confirmpassword: "Confirmar Contraseña",
      Usernamemusthaveatleast: "El nombre de usuario debe tener al menos",
      Alreadyhaveanaccount: "Ya tienes una cuenta",
      LoginHere: "Iniciar Sesión Aquí",

      // Profile
      SubmitReport: "Enviar Reporte",
      Blockcon: "¿Estás seguro de que quieres bloquear?",
      Yes: "Sí",
      No: "No",
      reportreason: "Ingresa tu razón para este reporte",
      Visits: "Visitas",
      Photos: "Fotos",
      UnlockExclusive: "Desbloquear Fotos Exclusivas",
      UnlockNow: "Desbloquear Ahora",
      Personal: "Personal",
      Ethnicity: "Etnia",
      BodyType: "Tipo de Cuerpo",
      Gender: "Género",
      Height: "Altura",
      Relationship: "Estado Civil",
      Work: "Trabajo",
      Education: "Educación",
      LIFESTYLE: "ESTILO DE VIDA",
      LivesWith: "Vive Con",
      Car: "Auto",
      Religion: "Religión",
      Smoke: "Fuma",
      Drink: "Bebe",
      Travel: "Viajes",
      Education: "Educación",

      // settings
      PhoneNumber: "Número de Teléfono",
      GeneralSettings: "Configuración General",
      SaveChanges: "Guardar Cambios",
      RemoveAccount: "Eliminar Cuenta",
      Yourprofile: "Tu Perfil",
      AboutMe: "Acerca de Mí",
      Relationshipstatus: "Estado Civil",
      YourCountry: "Tu País",
      ChooseYourCountry: "Elige tu País",
      Looks: "Apariencia",
      Personality: "Personalidad",
      Character: "Carácter",
      Children: "Hijos",
      Friends: "Amigos",
      Pets: "Mascotas",
      LivingWith: "Vive Con",
      MusicGenre: "Género Musical",
      Dish: "Comida",
      Song: "Canción",
      Hobby: "Pasatiempo",
      Book: "Libro",
      Movie: "Película",
      ChangePassword: "Cambiar Contraseña",
      NewPassword: "Nueva Contraseña",

      // profile setup
      SETUPYOURDATINGPROFILE: "CONFIGURA TU PERFIL DE CITAS",
      uploadphoto: "Sube una foto para establecer tu Foto de",
      nolikesyet: "Todavía no tienes Me gusta",
      nomatches: "Todavía no tienes ningún partido.",
    },
    // hr: {
    //   message: "shan4",
    //   mazurandomsusers: "Mazu Random Users3",
    //   // landingpage
    //   weshouldbefriends: "Landing Page Hero title",
    //   itallstartwithadate: "It all start with a Date",
    //   desctiption:
    //     "FlirtTool offers you new levels of online dating. FlirtTool’s ultimate creation of AI personality MAZU will help users be paired with someone that is 94% their perfect match",
    //   improvedatingprofile: "How to improve you Dating life",
    //   // landingpage

    //   // login
    //   orcreataccount: "OR CREATE AN ACCOUNT",
    //   iagree:
    //     "I AGREE TO THE TERMS, CONDITIONS AND PRIVACY POLICY CLICK HERE TO VIEW OUR TERMS",
    //   forgotpassword: "FORGOT PASSWORD ? RESET",
    //   // login
    //   // register
    //   loginhere: "Already have an account? Login Here.",
    //   // register
    //   // findmatches
    //   
    //   SearchingFornotificaiton:"Searching For notificaiton",
    //   // findmatches
    //   // userprofile
    //   sendmessage: "Message",
    //   poke: "Poke",
    //   photos: "Photos",
    //   personal: "personal",
    //   lifestyle: "Lifystyle",
    //   likes: "likes",
    //   visits: "visits",

    //   // userprofile
    //   loadmore: "loadmore",
    // },
    tr: {
      Register:"Kayıt",
      secondtext: ", size çevrimiçi flörtte yeni seviyeler sunuyor. Global Chat'in son yapay zeka kişiliği yaratımı, kullanıcıların %94 oranında mükemmel eşleşmeye sahip biriyle eşleşmelerine yardımcı olacaktır.",
      SomeRandomHeadingGoesHere: "Bugün Ücretsiz Katılın",
      starthourlypackage: "Saatlik paket başlangıcı",
      Newconversation: "Nouvelle conversation",
      SearchingFornotificaiton: "Bildirim aranıyor",
      Address: "Adres",
      noflirtsrecharge: "Yeterli krediniz yok",
      heygetflirts: "Flört al",
      MyPurchases: "Satın Alımlarım",
      mypurchasedes: "Burada satın aldığınız flörtler ve paketleri bulacaksınız.",
      mypurchacedess: "Henüz herhangi bir işlem yapmadınız",
      resetlink: "E-posta adresinizi girin ve sıfırlama bağlantınızı size göndereceğiz",
      ChatsConversation: "SOHBETLER (KONUŞMA)",
      message: "Mesaj",
      likes: "beğeniler",
      matches: "Maçlar",
      Liked: "Beğenildi",
      Disliked: "Beğenilmedi",
      JustVisitedYou: "Sizi Ziyaret Etti",
      LoadMore: "Daha Fazla Yükle",
      FliterUsers: "Kullanıcıları Filtrele",
      Youdonthaveanynotifications: "Herhangi bir bildiriminiz yok",
      ActiveNow: "Şu Anda Aktif",
      SignIn: "Giriş Yap",
      FlirtToolNews: "FlirtTool Haberleri",
      Join: "Katıl",
      Free: "Ücretsiz",
      Today: "Bugün",
      BasicPackage: "Temel Paket",
      twohoursfreechatexperience: "2 saat ücretsiz sohbet deneyimi",
      Tryitforfree: "Ücretsiz deneyin",
      FlirtToolfor1Day: "1 Gün için FlirtTool",
      Gblobalfor1Day:"1 Gün için  GlobalChat",
      RealChatFlatrate: "Sadece $2'ye Gerçek Sohbet Paketi",
      Grabit: "Al",
      Createanaccount: "Hesap Oluştur",
      orgettheapp: "veya uygulamayı indir",
      OVER1MILLIONUSERSWORLDWIDE: "Dünya çapında 1 milyondan fazla kullanıcı",
      MatchingProfiles: "Eşleşen Profiller",
      UpcomingonFlirtTool: "FlirtTool'da Yaklaşanlar",
      FindRealLifeWithFlirtTool: "FlirtTool ile Gerçek Hayatı Bul!",
      HowFlirtToolWorks: "FlirtTool Nasıl Çalışır",
      HowFlirtToolWorksdes: "FlirtTool, çevrimiçi arkadaşlıkta yeni seviyeler sunar. FlirtTool'un en son yaratımı olan yapay zeka kişiliği MAZU, kullanıcıların %94 uygun bir eşleşme bulmalarına yardımcı olur.",
      LEARNMORE: "DAHA FAZLA BİLGİ AL",
      About: "Hakkında",
      PressAndMedia: "Basın ve Medya",
      Blogs: "Bloglar",
      LiveChat: "Canlı Sohbet",
      ChatNow: "Şimdi Sohbet Et",
      SocialMedia: "Sosyal Medya",
      ContactUs: "Bize Ulaşın",
      WelcometoFlirttool: "Flirttool'a Hoş Geldiniz",
      OrCreateanAccount: "Veya Hesap Oluştur",
      UsernameorEmail: "Kullanıcı Adı veya E-posta",
      YourPassword: "Şifreniz",
      Forgotpassword: "Şifreyi unuttum",
      Reset: "Sıfırla",
      IAGREE: "ŞARTLARI, KOŞULLARI VE GİZLİLİK POLİTİKASINI KABUL EDİYORUM",
      Clickhereto: "ŞARTLARIMIZI GÖRÜNTÜLEMEK İÇİN BURAYA TIKLAYIN",
      FIRSTNAME: "AD",
      LastName: "Soyadı",
      Emailisnotvalid: "E-posta geçerli değil",
      Username: "Kullanıcı Adı",
      Yourpasswords: "Şifreniz",
      Passwordisnotvalid: "Şifre geçerli değil",
      Confirmpassword: "Şifreyi Onayla",
      Usernamemusthaveatleast: "Kullanıcı adı en az",
      Alreadyhaveanaccount: "Zaten bir hesabınız var",
      LoginHere: "Buradan Giriş Yapın",
      SubmitReport: "Rapor Gönder",
      Blockcon: "Engellemek istediğinizden emin misiniz",
      Yes: "Evet",
      No: "Hayır",
      reportreason: "Bu raporunuzun nedenini girin",
      Visits: "Ziyaretler",
      Photos: "Fotoğraflar",
      UnlockExclusive: "Özel Resimleri Aç",
      UnlockNow: "Şimdi Aç",
      Personal: "Kişisel",
      Ethnicity: "Etnik Köken",
      BodyType: "Vücut Tipi",
      Gender: "Cinsiyet",
      Height: "Boy",
      Relationship: "İlişki Durumu",
      Work: "İş",
      Education: "Eğitim",
      LIFESTYLE: "YAŞAM TARZI",
      LivesWith: "İle Yaşar",
      Car: "Araba",
      Religion: "Din",
      Smoke: "Sigara İçer",
      Drink: "İçki",
      Travel: "Seyahat",
      PhoneNumber: "Telefon Numarası",
      GeneralSettings: "Genel Ayarlar",
      SaveChanges: "Değişiklikleri Kaydet",
      RemoveAccount: "Hesabı Kaldır",
      Yourprofile: "Profiliniz",
      AboutMe: "Hakkımda",
      Relationshipstatus: "İlişki Durumu",
      YourCountry: "Ülkeniz",
      ChooseYourCountry: "Ülkenizi Seçin",
      Looks: "Görünüş",
      Personality: "Kişilik",
      Character: "Karakter",
      Children: "Çocuklar",
      Friends: "Arkadaşlar",
      Pets: "Ev Hayvanları",
      LivingWith: "Beraber Yaşadığı",
      MusicGenre: "Müzik Türü",
      Dish: "Yemek",
      Song: "Şarkı",
      Hobby: "Hobi",
      Book: "Kitap",
      Movie: "Film",
      ChangePassword: "Şifre Değiştir",
      NewPassword: "Yeni Şifre",
      SETUPYOURDATINGPROFILE: "TANIŞMA PROFİLİNİZİ AYARLAYIN",
      uploadphoto: "Profil Resminizi Ayarlamak İçin Fotoğraf Yükleyin",
      picnotupdated: "Profil resmi güncellenmedi",
      pleaseupdatepicture: "Devam etmek için profil resmi yüklemeniz gerekiyor",
      BirthYear: "Doğum Yılı",
      Date: "Tarih",
      Month: "Ay",
      Day: "Gün",
      SexualPreference: "Cinsel Tercih",
      SexualPreferences: "Cinsel tercihiniz nedir",
      countrycode: "ülke kodu",
      PhonNumber: "Telefon Numarası",
      nolikesyet: "Henüz hiç beğeniniz yok",
      nomatches: "Henüz eşleşmeniz yok",
      likematches: "Profil beğenerek eşleşmeye başlayabilirsiniz",
      terms: "Şartlar",
      privacy: "Gizlilik",
      imprint: "Baskı",
      copyright: "© Telif Hakkı 2024 Global Chat. Tüm hakları saklıdır. Tarafından desteklenmektedir"
  },
  fr: {
    Register:"Enregistrer",
    secondtext: "vous propose de nouveaux niveaux de rencontres en ligne. La création ultime de la personnalité IA de Global Chat aidera les utilisateurs à être associés à quelqu'un qui est à 94 % leur partenaire idéal.",
    SomeRandomHeadingGoesHere: "Rejoignez Gratuitement Aujourd'hui",
    starthourlypackage: "Début du forfait horaire",
    SearchingFornotificaiton: "Recherche de notification",
    Newconversation: "Nouvelle conversation",
    noflirtsrecharge: "Vous n'avez pas assez de crédits",
    heygetflirts: "Obtenir des flirts",
    copyright: "© Copyright 2024 Global Chat. Tous droits réservés. Propulsé par",
    MyPurchases: "Mes Achats",
    mypurchasedes: "Ici, vous trouverez les flirts et les forfaits que vous avez achetés.",
    mypurchacedess: "Vous n'avez encore effectué aucune transaction",
    resetlink: "Entrez votre adresse e-mail et nous vous enverrons votre lien de réinitialisation",
    ChatsConversation: "CHATS (CONVERSATION)",
    message: "Message",
    likes: "likes",
    matches: "Correspondances",
    Liked: "Apprécié",
    Disliked: "Pas aimé",
    JustVisitedYou: "Vient de vous rendre visite",
    LoadMore: "Charger plus",
    FliterUsers: "Filtrer les utilisateurs",
    Youdonthaveanynotifications: "Vous n'avez aucune notification",
    ActiveNow: "Actif maintenant",

    SignIn: "Se Connecter",
    FlirtToolNews: "Actualités FlirtTool",
    Join: "Rejoindre",
    Free: "Gratuit",
    Today: "Aujourd'hui",
    BasicPackage: "Forfait de Base",
    twohoursfreechatexperience: "2 heures d'expérience de chat gratuit",
    Tryitforfree: "Essayez-le gratuitement",
    FlirtToolfor1Day: "FlirtTool pour 1 jour",
    Gblobalfor1Day:" GlobalChat pour 1 jour",
    RealChatFlatrate: "Forfait de Chat Réel SEULEMENT 2 $",
    Grabit: "Attrapez-le",
    Createanaccount: "Créer un compte",
    orgettheapp: "ou obtenez l'application",
    OVER1MILLIONUSERSWORLDWIDE: "Plus de 1 million d'utilisateurs dans le monde",
    MatchingProfiles: "Profils correspondants",
    UpcomingonFlirtTool: "À venir sur FlirtTool",
    FindRealLifeWithFlirtTool: "Trouvez la vraie vie avec FlirtTool !",
    HowFlirtToolWorks: "Comment FlirtTool Fonctionne",
    HowFlirtToolWorksdes: "FlirtTool vous propose de nouveaux niveaux de rencontres en ligne. La création ultime de FlirtTool, la personnalité artificielle MAZU, aidera les utilisateurs à être associés à quelqu'un qui est à 94% leur match parfait.",
    LEARNMORE: "EN SAVOIR PLUS",
    About: "À propos",
    PressAndMedia: "Presse et Médias",
    Blogs: "Blogs",
    LiveChat: "Chat en direct",
    ChatNow: "Chattez maintenant",
    SocialMedia: "Médias sociaux",
    ContactUs: "Contactez-nous",
    WelcometoFlirttool: "Bienvenue sur Flirttool",

    OrCreateanAccount: "Ou Créez un Compte",
    UsernameorEmail: "Nom d'utilisateur ou E-mail",
    YourPassword: "Votre Mot de passe",
    Forgotpassword: "Mot de passe oublié",
    Reset: "Réinitialiser",
    IAGREE: "J'ACCEPTE LES CONDITIONS ET LA POLITIQUE DE CONFIDENTIALITÉ",
    Clickhereto: "CLIQUEZ ICI POUR CONSULTER NOS CONDITIONS",

    FIRSTNAME: "PRÉNOM",
    LastName: "Nom de Famille",
    Emailisnotvalid: "L'e-mail n'est pas valide",
    Username: "Nom d'utilisateur",
    Yourpasswords: "Votre mot de passe",
    Passwordisnotvalid: "Le mot de passe n'est pas valide",
    Confirmpassword: "Confirmez le mot de passe",
    Usernamemusthaveatleast: "Le nom d'utilisateur doit avoir au moins",
    Alreadyhaveanaccount: "Vous avez déjà un compte",
    LoginHere: "Connectez-vous ici",

    SubmitReport: "Soumettre un Rapport",
    Blockcon: "Êtes-vous sûr de vouloir bloquer",
    Yes: "Oui",
    No: "Non",
    reportreason: "Entrez votre raison pour ce rapport",
    Visits: "Visites",
    Photos: "Photos",
    UnlockExclusive: "Déverrouiller des Images Exclusives",
    UnlockNow: "Déverrouiller maintenant",
    Personal: "Personnel",
    Ethnicity: "Ethnicité",
    BodyType: "Type de Corps",
    Gender: "Genre",
    Height: "Taille",
    Relationship: "Relation",
    Work: "Travail",
    Education: "Éducation",
    LIFESTYLE: "STYLE DE VIE",
    LivesWith: "Vit Avec",
    Car: "Voiture",
    Religion: "Religion",
    Smoke: "Fume",
    Drink: "Boit",
    Travel: "Voyage",
    Education: "Éducation",

    PhoneNumber: "Numéro de Téléphone",
    GeneralSettings: "Paramètres Généraux",
    SaveChanges: "Enregistrer les Modifications",
    RemoveAccount: "Supprimer le Compte",
    Yourprofile: "Votre profil",
    AboutMe: "À Propos de Moi",
    Relationshipstatus: "Statut Relationnel",
    YourCountry: "Votre Pays",
    ChooseYourCountry: "Choisissez Votre Pays",
    Looks: "Apparence",
    Personality: "Personnalité",
    Character: "Caractère",
    Children: "Enfants",
    Friends: "Amis",
    Pets: "Animaux de Compagnie",
    LivingWith: "Vit Avec",
    MusicGenre: "Genre Musical",
    Dish: "Plat",
    Song: "Chanson",
    Hobby: "Passe-temps",
    Book: "Livre",
    Movie: "Film",
    ChangePassword: "Changer le Mot de Passe",
    NewPassword: "Nouveau Mot de Passe",

    SETUPYOURDATINGPROFILE: "CONFIGUREZ VOTRE PROFIL DE RENCONTRE",
    uploadphoto: "Téléchargez une photo pour définir votre photo de profil",
    picnotupdated: "La photo de profil n'est pas mise à jour",
    pleaseupdatepicture: "Vous devez télécharger une photo de profil pour continuer",
    BirthYear: "Année de Naissance",
    Date: "Date",
    Month: "Mois",
    Day: "Jour",
    SexualPreference: "Préférence Sexuelle",
    SexualPreferences: "Quelle est votre préférence sexuelle",
    countrycode: "Code Pays",
    PhonNumber: "Numéro de Téléphone",
    nolikesyet: "Vous n'avez pas encore de Likes",
    nomatches: "Vous n'avez pas encore de correspondances",
    likematches: "Vous pouvez commencer à faire correspondre des profils en les aimant",
   
      terms: "Conditions",
      privacy: "Confidentialité",
      imprint: "Mentions légales",
      Address: "Adresse",
 
  
},
de: {
  Register:"Registrieren",
  secondtext: "bietet Ihnen neue Ebenen des Online-Datings. Die ultimative Kreation der KI-Persönlichkeit von Global Chat wird den Benutzern helfen, mit jemandem zusammengebracht zu werden, der zu 94 % ihr perfektes Gegenüber ist.",
  SomeRandomHeadingGoesHere: "Treten Sie heute kostenlos bei",
  starthourlypackage: "Start des Stundenpakets",
  SearchingFornotificaiton: "Suche nach Benachrichtigung",
  Newconversation: "Nouvelle conversation",
  Address: "Adresse",
  terms: "Nutzungsbedingungen",
    privacy: "Datenschutz",
    imprint: "Impressum",
  noflirtsrecharge: "Sie haben nicht genügend Guthaben",
  heygetflirts: "Flirts erhalten",
  copyright: "© Copyright 2024 Global Chat. Alle Rechte vorbehalten. Powered by",
  MyPurchases: "Meine Käufe",
  mypurchasedes: "Hier finden Sie die Flirts und Pakete, die Sie gekauft haben.",
  mypurchacedess: "Sie haben noch keine Transaktionen getätigt",
  resetlink: "Geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen den Rücksetzlink",
  ChatsConversation: "CHATS (UNTERHALTUNG)",
  message: "Nachricht",
  likes: "Gefällt mir",
  matches: "Matches",
  Liked: "Gemocht",
  Disliked: "Nicht gemocht",
  JustVisitedYou: "Hat dich gerade besucht",
  LoadMore: "Mehr laden",
  FliterUsers: "Benutzer filtern",
  Youdonthaveanynotifications: "Sie haben keine Benachrichtigungen",
  ActiveNow: "Jetzt aktiv",

  SignIn: "Anmelden",
  FlirtToolNews: "FlirtTool Nachrichten",
  Join: "Beitreten",
  Free: "Kostenlos",
  Today: "Heute",
  BasicPackage: "Grundpaket",
  twohoursfreechatexperience: "2 Stunden kostenlose Chat-Erfahrung",
  Tryitforfree: "Probieren Sie es kostenlos aus",
  FlirtToolfor1Day: "FlirtTool für 1 Tag",
  Gblobalfor1Day:" GlobalChat für 1 Tag",
  RealChatFlatrate: "Echter Chat Flatrate NUR $2",
  Grabit: "Hol es dir",
  Createanaccount: "Konto erstellen",
  orgettheapp: "oder die App holen",
  OVER1MILLIONUSERSWORLDWIDE: "Über 1 Million Benutzer weltweit",
  MatchingProfiles: "Passende Profile",
  UpcomingonFlirtTool: "Demnächst auf FlirtTool",
  FindRealLifeWithFlirtTool: "Finde das echte Leben mit FlirtTool!",
  HowFlirtToolWorks: "Wie FlirtTool funktioniert",
  HowFlirtToolWorksdes: "FlirtTool bietet Ihnen neue Ebenen des Online-Datings. FlirtTools ultimative Kreation der KI-Persönlichkeit MAZU wird Benutzern helfen, mit jemandem gepaart zu werden, der zu 94% ihr perfektes Match ist.",
  LEARNMORE: "MEHR ERFAHREN",
  About: "Über",
  PressAndMedia: "Presse und Medien",
  Blogs: "Blogs",
  LiveChat: "Live-Chat",
  ChatNow: "Jetzt chatten",
  SocialMedia: "Soziale Medien",
  ContactUs: "Kontaktiere uns",
  WelcometoFlirttool: "Willkommen bei Flirttool",

  OrCreateanAccount: "Oder ein Konto erstellen",
  UsernameorEmail: "Benutzername oder E-Mail",
  YourPassword: "Ihr Passwort",
  Forgotpassword: "Passwort vergessen",
  Reset: "Zurücksetzen",
  IAGREE: "ICH STIMME DEN BEDINGUNGEN UND DATENSCHUTZRICHTLINIEN ZU",
  Clickhereto: "HIER KLICKEN, UM UNSERE BEDINGUNGEN ANZUSEHEN",

  FIRSTNAME: "VORNAME",
  LastName: "Nachname",
  Emailisnotvalid: "E-Mail ist nicht gültig",
  Username: "Benutzername",
  Yourpasswords: "Ihr Passwort",
  Passwordisnotvalid: "Passwort ist ungültig",
  Confirmpassword: "Passwort bestätigen",
  Usernamemusthaveatleast: "Benutzername muss mindestens haben",
  Alreadyhaveanaccount: "Sie haben bereits ein Konto",
  LoginHere: "Hier einloggen",

  SubmitReport: "Bericht senden",
  Blockcon: "Sind Sie sicher, dass Sie blockieren möchten",
  Yes: "Ja",
  No: "Nein",
  reportreason: "Geben Sie Ihren Grund für diesen Bericht ein",
  Visits: "Besuche",
  Photos: "Fotos",
  UnlockExclusive: "Exklusive Bilder freischalten",
  UnlockNow: "Jetzt freischalten",
  Personal: "Persönlich",
  Ethnicity: "Ethnizität",
  BodyType: "Körpertyp",
  Gender: "Geschlecht",
  Height: "Höhe",
  Relationship: "Beziehung",
  Work: "Arbeit",
  Education: "Bildung",
  LIFESTYLE: "LIFESTYLE",
  LivesWith: "Lebt mit",
  Car: "Auto",
  Religion: "Religion",
  Smoke: "Rauchen",
  Drink: "Trinken",
  Travel: "Reisen",
  Education: "Bildung",

  PhoneNumber: "Telefonnummer",
  GeneralSettings: "Allgemeine Einstellungen",
  SaveChanges: "Änderungen speichern",
  RemoveAccount: "Konto entfernen",
  Yourprofile: "Ihr Profil",
  AboutMe: "Über mich",
  Relationshipstatus: "Beziehungsstatus",
  YourCountry: "Ihr Land",
  ChooseYourCountry: "Wählen Sie Ihr Land",
  Looks: "Aussehen",
  Personality: "Persönlichkeit",
  Character: "Charakter",
  Children: "Kinder",
  Friends: "Freunde",
  Pets: "Haustiere",
  LivingWith: "Lebt mit",
  MusicGenre: "Musikgenre",
  Dish: "Gericht",
  Song: "Lied",
  Hobby: "Hobby",
  Book: "Buch",
  Movie: "Film",
  ChangePassword: "Passwort ändern",
  NewPassword: "Neues Passwort",

  SETUPYOURDATINGPROFILE: "STELLEN SIE IHR DATING-PROFIL EIN",
  uploadphoto: "Laden Sie ein Foto hoch, um Ihr Profilbild festzulegen",
  picnotupdated: "Profilbild wurde nicht aktualisiert",
  pleaseupdatepicture: "Sie sollten ein Profilbild hochladen, um fortzufahren",
  BirthYear: "Geburtsjahr",
  Date: "Datum",
  Month: "Monat",
  Day: "Tag",
  SexualPreference: "Sexuelle Vorlieben",
  SexualPreferences: "Was ist Ihre sexuelle Vorliebe",
  countrycode: "Ländercode",
  PhonNumber: "Telefonnummer",
  nolikesyet: "Sie haben noch keine Likes",
  nomatches: "Sie haben noch keine Übereinstimmungen",
  likematches: "Sie können damit beginnen, Profile zu mögen und sich mit ihnen abzustimmen",
},


// 	Mandarin-Chinesisch
mc:{
  SomeRandomHeadingGoesHere: "今天免费加入",
  secondtext: "为您提供了全新的在线约会体验。Global Chat 的AI人格的最终创造将帮助用户与94%符合其完美匹配的人配对。",
  noflirtsrecharge: "你没有足够的信用",
  heygetflirts: "获取招待",
  SEARCHINGFORNOTIFICAITON: "搜索通知",
  Newconversation: "新对话",
  MyPurchases: "我的购买",
  mypurchasedes: "在这里，您将找到您购买的招待和套餐。",
  mypurchacedess: "您还没有任何交易",
  resetlink: "输入您的电子邮件地址，我们将向您发送重置链接",
  ChatsConversation: "聊天（对话）",
  message: "消息",
  likes: "喜欢",
  matches: "匹配",
  Liked: "喜欢",
  Disliked: "不喜欢",
  JustVisitedYou: "刚刚访问过您",
  LoadMore: "加载更多",
  FliterUsers: "过滤用户",
  Youdonthaveanynotifications: "您没有任何通知",
  ActiveNow: "当前在线",
  SignIn: "登入",
  FlirtToolNews: "FlirtTool 新闻",
  Join: "加入",
  Free: "免费",
  Today: "今天",
  BasicPackage: "基本套餐",
  twohoursfreechatexperience: "2小时免费聊天体验",
  Tryitforfree: "免费试用",
  FlirtToolfor1Day: "FlirtTool 1天",
  Gblobalfor1Day:" GlobalChat 1天",

  RealChatFlatrate: "真实聊天包月仅2美元",
  Grabit: "抓住机会",
  Createanaccount: "创建帐户",
  orgettheapp: "或获取应用程序",
  OVER1MILLIONUSERSWORLDWIDE: "全球超过100万用户",
  MatchingProfiles: "匹配的档案",
  UpcomingonFlirtTool: "FlirtTool 即将推出",
  FindRealLifeWithFlirtTool: "在 FlirtTool 中找到真实生活！",
  HowFlirtToolWorks: "FlirtTool 如何运作",
  HowFlirtToolWorksdes: "FlirtTool 为您提供全新的在线约会体验。FlirtTool 的 AI 个性 MAZU 的终极创作将帮助用户与其94%完美匹配的人配对",
  LEARNMORE: "了解更多",
  About: "关于",
  PressAndMedia: "新闻和媒体",
  Blogs: "博客",
  LiveChat: "在线聊天",
  ChatNow: "立即聊天",
  SocialMedia: "社交媒体",
  ContactUs: "联系我们",
  WelcometoFlirttool: "欢迎来到Flirttool",
  OrCreateanAccount: "或创建一个帐户",
  UsernameorEmail: "用户名或电子邮件",
  YourPassword: "您的密码",
  Forgotpassword: "忘记密码",
  Reset: "重置",
  IAGREE: "我同意条款、条件和隐私政策",
  Clickhereto: "点击这里查看我们的条款",
  FIRSTNAME: "名字",
  LastName: "姓氏",
  Emailisnotvalid: "电子邮件无效",
  Username: "用户名",
  Yourpasswords: "您的密码",
  Passwordisnotvalid: "密码无效",
  Confirmpassword: "确认密码",
  Usernamemusthaveatleast: "用户名必须至少有",
  Alreadyhaveanaccount: "已经有账号",
  LoginHere: "在此登录",
  starthourlypackage: "开始每小时套餐",
  SubmitReport: "提交报告",
  Blockcon: "您确定要屏蔽吗",
  Yes: "是",
  No: "否",
  reportreason: "输入您对此报告的原因",
  Visits: "访问次数",
  Photos: "照片",
  UnlockExclusive: "解锁独家图片",
  UnlockNow: "立即解锁",
  Personal: "个人",
  Ethnicity: "种族",
  BodyType: "体型",
  Gender: "性别",
  Height: "身高",
  Relationship: "关系",
  Work: "工作",
  Education: "教育",
  LIFESTYLE: "生活方式",
  LivesWith: "居住",
  Car: "汽车",
  Religion: "宗教",
  Smoke: "吸烟",
  Drink: "饮酒",
  Travel: "旅行",
  PhoneNumber: "电话号码",
  GeneralSettings: "常规设置",
  SaveChanges: "保存更改",
  RemoveAccount: "删除帐户",
  Yourprofile: "您的个人资料",
  AboutMe: "关于我",
  Relationshipstatus: "关系状态",
  YourCountry: "你的国家",
  ChooseYourCountry: "选择您的国家",
  Looks: "外表",
  Personality: "个性",
  Character: "性格",
  Children: "孩子",
  Friends: "朋友",
  Pets: "宠物",
  LivingWith: "同住",
  MusicGenre: "音乐类型",
  Dish: "菜肴",
  Song: "歌曲",
  Hobby: "爱好",
  Book: "书籍",
  Movie: "电影",
  ChangePassword: "更改密码",
  NewPassword: "新密码",
  SETUPYOURDATINGPROFILE: "设置您的约会个人资料",
  uploadphoto: "上传照片以设置您的个人资料照片",
  picnotupdated: "个人资料图片未更新",
  pleaseupdatepicture: "您应该上传个人资料图片以继续",
  BirthYear: "出生年份",
  Date: "日期",
  Month: "月份",
  Day: "日期",
  SexualPreference: "性取向",
  SexualPreferences: "你的性取向",
  countrycode: "国家代码",
  PhonNumber: "电话号码",
  nolikesyet: "您还没有任何喜欢",
  nomatches: "您还没有任何匹配",
  likematches: "您可以开始通过喜欢他们与个人匹配",
  terms: "条款",
  Privacy: "隐私",
  imprint: "印记",
  Address: "地址"
  },
  

// 	Mandarin-Chinesisch

  // Hindi
in:
{
SomeRandomHeadingGoesHere: "आज नि: शुल्क शामिल हों",
noflirtsrecharge: "आपके पास पर्याप्त क्रेडिट नहीं है",
heygetflirts: "फ्लर्ट्स प्राप्त करें",
SEARCHINGFORNOTIFICAITON: "सूचना खोज रहा है",
Newconversation: "नई बातचीत",
MyPurchases: "मेरी खरीदारियाँ",
mypurchasedes: "यहाँ आपको उन फ्लर्ट्स और पैकेजों का पता लगेगा जिन्हें आपने खरीदा है।",
mypurchacedess: "आपका कोई लेन-देन अभी तक नहीं है",
resetlink: "अपना ईमेल पता दर्ज करें और हम आपको रीसेट लिंक भेज देंगे",
ChatsConversation: "चैट (बातचीत)",
message: "संदेश",
likes: "पसंद",
matches: "मैचेस",
Liked: "पसंद",
Disliked: "नापसंद",
JustVisitedYou: "आपको अभी अभी देखा",
LoadMore: "और लोड करें",
FliterUsers: "उपयोगकर्ता को फ़िल्टर करें",
Youdonthaveanynotifications: "आपके पास कोई सूचनाएं नहीं हैं",
ActiveNow: "वर्तमान में सक्रिय",

SignIn: "साइन इन करें",
FlirtToolNews: "फ्लर्टटूल समाचार",
Join: "शामिल हों",
Free: "मुफ्त",
Today: "आज",
BasicPackage: "मूल पैकेज",
twohoursfreechatexperience: "2 घंटे की मुफ्त चैट अनुभव",
Tryitforfree: "इसे मुफ्त में प्रयास करें",
FlirtToolfor1Day: "1 दिन के लिए फ्लर्टटूल",
Gblobalfor1Day:" GlobalChat 1天",
RealChatFlatrate: "रियल चैट फ्लैटरेट केवल $2",
Grabit: "पकड़ो",
Createanaccount: "खाता बनाएं",
orgettheapp: "या ऐप डाउनलोड करें",
OVER1MILLIONUSERSWORLDWIDE: "वैश्विक रूप से 1 मिलियन से अधिक उपयोगकर्ता",
MatchingProfiles: "मिलान योग्य प्रोफ़ाइल",
UpcomingonFlirtTool: "फ्लर्टटूल पर आने वाला",
FindRealLifeWithFlirtTool: "फ्लर्टटूल के साथ वास्तविक जीवन खोजें!",
HowFlirtToolWorks: "फ्लर्टटूल कैसे काम करता है",
HowFlirtToolWorksdes: "फ्लर्टटूल आपको ऑनलाइन डेटिंग के नए स्तर प्रदान करता है। फ्लर्टटूल की अंतिम रचना एआई व्यक्तित्व माज़ू आपकी मदद करेगी ताकि उपयोगकर्ता किसी के साथ जुड़े जो उनके 94% पूर्ण मिलान है",
LEARNMORE: "और अधिक जानें",
About: "के बारे में",
PressAndMedia: "प्रेस और मीडिया",
Blogs: "ब्लॉग",
LiveChat: "लाइव चैट",
ChatNow: "अभी चैट करें",
SocialMedia: "सोशल मीडिया",
ContactUs: "संपर्क करें",
WelcometoFlirttool: "फ्लर्टटूल में आपका स्वागत है",

OrCreateanAccount: "या एक खाता बनाएं",
UsernameorEmail: "उपयोगकर्ता नाम या ईमेल",
YourPassword: "आपका पासवर्ड",
Forgotpassword: "पासवर्ड भूल गए",
Reset: "रीसेट",
IAGREE: "मैं नियमों, शर्तों और गोपनीयता नीति से सहमत हूँ",
Clickhereto: "हमारे नियमों को देखने के लिए यहाँ क्लिक करें",

FIRSTNAME: "पहला नाम",
LastName: "अंतिम नाम",
Emailisnotvalid: "ईमेल मान्य नहीं है",
Username: "उपयोगकर्ता नाम",
Yourpasswords: "आपका पासवर्ड",
Passwordisnotvalid: "पासवर्ड मान्य नहीं है",
Confirmpassword: "पासवर्ड की पुष्टि करें",
Usernamemusthaveatleast: "उपयोगकर्ता नाम कम से कम होना चाहिए",
Alreadyhaveanaccount: "पहले से ही एक खाता है",
LoginHere: "यहाँ लॉग इन करें",

starthourlypackage: "घंटे की पैकेज शुरू करें",
SubmitReport: "रिपोर्ट सबमिट करें",
Blockcon: "क्या आप वाकई ब्लॉक करना चाहते हैं",
Yes: "हां",
No: "नहीं",
reportreason: "इस रिपोर्ट के लिए आपका कारण दर्ज करें",
Visits: "दौरे",
Photos: "तस्वीरें",
UnlockExclusive: "अनलॉक विशेष तस्वीरें",
UnlockNow: "अब अनलॉक करें",
Personal: "व्यक्तिगत",
Ethnicity: "जाति",
BodyType: "शारीरिक प्रकार",
Gender: "लिंग",
Height: "ऊचाई",
Relationship: "रिश्ता",
Work: "काम",
Education: "शिक्षा",
LIFESTYLE: "जीवन शैली",
LivesWith: "साथ रहना",
Car: "गाड़ी",
Religion: "धर्म",
Smoke: "धूम्रपान",
Drink: "पीना",
Travel: "यात्रा",
PhoneNumber: "फ़ोन नंबर",
GeneralSettings: "सामान्य सेटिंग्स",
SaveChanges: "परिवर्तन सहेजें",
RemoveAccount: "खाता हटाएं",
Yourprofile: "आपका प्रोफ़ाइल",
AboutMe: "मेरे बारे में",
Relationshipstatus: "संबंध की स्थिति",
YourCountry: "आपका देश",
ChooseYourCountry: "अपना देश चुनें",
Looks: "दिखता है",
Personality: "व्यक्तित्व",
Character: "चरित्र",
Children: "बच्चे",
Friends: "दोस्त",
Pets: "पालतू जानवर",
LivingWith: "के साथ रहना",
MusicGenre: "संगीत जाति",
Dish: "डिश",
Song: "गाना",
Hobby: "शौक",
Book: "किताब",
Movie: "फ़िल्म",
ChangePassword: "पासवर्ड बदलें",
NewPassword: "नया पासवर्ड",
SETUPYOURDATINGPROFILE: "अपनी डेटिंग प्रोफ़ाइल सेट करें",
uploadphoto: "प्रोफ़ाइल चित्र सेट करने के लिए फोटो अपलोड करें",
picnotupdated: "प्रोफ़ाइल चित्र अद्यतित नहीं है",
pleaseupdatepicture: "आपको जारी रखने के लिए प्रोफ़ाइल चित्र अपलोड करना चाहिए",
BirthYear: "जन्म साल",
Date: "तारीख",
Month: "महीना",
Day: "दिन",
SexualPreference: "लैंगिक पसंद",
SexualPreferences: "आपकी लैंगिक पसंद",
countrycode: "देश कोड",
PhonNumber: "फ़ोन नंबर",
nolikesyet: "आपके पास अभी तक कोई पसंद नहीं हैं",
nomatches: "आपके पास अभी तक कोई मैच नहीं है",
likematches: "आप प्रोफ़ाइल के साथ मैचिंग शुरू कर सकते हैं उन्हें पसंद करके",
terms: "शर्तें",
Privacy: "गोपनीयता",
imprint: "प्रतिलिपि",
Address: "पता"
},
  // Hindi


  // Portugiesisch
pp:{
  secondtext: "oferece novos níveis de encontros online. A criação final da personalidade de IA do Global Chat ajudará os usuários a serem pareados com alguém que é 94% o seu par perfeito.",
  SomeRandomHeadingGoesHere: "Junte-se Gratuitamente Hoje",
  noflirtsrecharge: "Você não tem créditos suficientes",
  heygetflirts: "Obter flertes",
  SEARCHINGFORNOTIFICAITON: "BUSCANDO POR NOTIFICAÇÃO",
  Newconversation: "Nova conversa",
  MyPurchases: "Minhas Compras",
  mypurchasedes: "Aqui você encontrará os flertes e pacotes que comprou.",
  mypurchacedess: "Você ainda não tem transações",
  resetlink: "Digite seu endereço de e-mail e enviaremos o link de redefinição para você",
  ChatsConversation: "CONVERSAS (CHAT)",
  message: "Mensagem",
  likes: "curtidas",
  matches: "Combinações",
  Liked: "Curtido",
  Disliked: "Não gostei",
  JustVisitedYou: "Acabou de visitar você",
  LoadMore: "Carregar mais",
  FliterUsers: "Filtrar Usuários",
  Youdonthaveanynotifications: "Você não tem notificações",
  ActiveNow: "Ativo Agora",
  SignIn: "Entrar",
  FlirtToolNews: "Notícias do FlirtTool",
  Join: "Junte-se",
  Free: "Grátis",
  Today: "Hoje",
  BasicPackage: "Pacote Básico",
  twohoursfreechatexperience: "Experiência de bate-papo grátis de 2 horas",
  Tryitforfree: "Experimente grátis",
  FlirtToolfor1Day: "FlirtTool por 1 dia",
  Gblobalfor1Day:" GlobalChat por 1 dia",
  RealChatFlatrate: "Chat real, taxa fixa APENAS $2",
  Grabit: "Pegue",
  Createanaccount: "Criar uma conta",
  orgettheapp: "ou obtenha o aplicativo",
  OVER1MILLIONUSERSWORLDWIDE: "Mais de 1 milhão de usuários em todo o mundo",
  MatchingProfiles: "Perfis Correspondentes",
  UpcomingonFlirtTool: "Próximos no FlirtTool",
  FindRealLifeWithFlirtTool: "Encontre a vida real com o FlirtTool!",
  HowFlirtToolWorks: "Como o FlirtTool Funciona",
  HowFlirtToolWorksdes: "O FlirtTool oferece a você novos níveis de namoro online. A criação final do FlirtTool, a personalidade de IA MAZU, ajudará os usuários a serem pareados com alguém que seja 94% seu par perfeito",
  LEARNMORE: "SAIBA MAIS",
  About: "Sobre",
  PressAndMedia: "Imprensa e Mídia",
  Blogs: "Blogs",
  LiveChat: "Chat ao vivo",
  ChatNow: "Chat Agora",
  SocialMedia: "Mídias Sociais",
  ContactUs: "Contate-Nos",
  WelcometoFlirttool: "Bem-vindo ao Flirttool",
  OrCreateanAccount: "Ou Crie uma Conta",
  UsernameorEmail: "Nome de Usuário ou E-mail",
  YourPassword: "Sua Senha",
  Forgotpassword: "Esqueceu a senha",
  Reset: "Redefinir",
  IAGREE: "EU CONCORDO COM OS TERMOS, CONDIÇÕES E POLÍTICA DE PRIVACIDADE",
  Clickhereto: "CLIQUE AQUI PARA VER NOSSOS TERMOS",
  FIRSTNAME: "NOME",
  LastName: "Sobrenome",
  Emailisnotvalid: "E-mail não é válido",
  Username: "Nome de usuário",
  Yourpasswords: "Sua senha",
  Passwordisnotvalid: "Senha não é válida",
  Confirmpassword: "Confirmar senha",
  Usernamemusthaveatleast: "O nome de usuário deve ter pelo menos",
  Alreadyhaveanaccount: "Já tem uma conta",
  LoginHere: "Faça login aqui",
  starthourlypackage: "Iniciar Pacote por Hora",
  SubmitReport: "Enviar Relatório",
  Blockcon: "Você tem certeza de que deseja bloquear",
  Yes: "Sim",
  No: "Não",
  reportreason: "Digite o motivo deste relatório",
  Visits: "Visitas",
  Photos: "Fotos",
  UnlockExclusive: "Desbloquear Fotos Exclusivas",
  UnlockNow: "Desbloquear Agora",
  Personal: "Pessoal",
  Ethnicity: "Etnia",
  BodyType: "Tipo de Corpo",
  Gender: "Gênero",
  Height: "Altura",
  Relationship: "Relacionamento",
  Work: "Trabalho",
  Education: "Educação",
  LIFESTYLE: "ESTILO DE VIDA",
  LivesWith: "Vive Com",
  Car: "Carro",
  Religion: "Religião",
  Smoke: "Fuma",
  Drink: "Bebida",
  Travel: "Viagem",
  PhoneNumber: "Número de Telefone",
  GeneralSettings: "Configurações Gerais",
  SaveChanges: "Salvar Alterações",
  RemoveAccount: "Remover Conta",
  Yourprofile: "Seu Perfil",
  AboutMe: "Sobre Mim",
  Relationshipstatus: "Estado Civil",
  YourCountry: "Seu País",
  ChooseYourCountry: "Escolha Seu País",
  Looks: "Aparência",
  Personality: "Personalidade",
  Character: "Caráter",
  Children: "Crianças",
  Friends: "Amigos",
  Pets: "Animais de Estimação",
  LivingWith: "Morando Com",
  MusicGenre: "Gênero Musical",
  Dish: "Prato",
  Song: "Música",
  Hobby: "Hobby",
  Book: "Livro",
  Movie: "Filme",
  ChangePassword: "Alterar Senha",
  NewPassword: "Nova Senha",
  SETUPYOURDATINGPROFILE: "CONFIGURE SEU PERFIL DE ENCONTROS",
  uploadphoto: "Faça o upload da foto para definir sua Foto de Perfil",
  picnotupdated: "A foto de perfil não foi atualizada",
  pleaseupdatepicture: "Você deve fazer o upload da foto de perfil para continuar",
  BirthYear: "Ano de Nascimento",
  Date: "Data",
  Month: "Mês",
  Day: "Dia",
  SexualPreference: "Preferência Sexual",
  SexualPreferences: "Qual é a sua preferência sexual",
  countrycode: "Código do País",
  PhonNumber: "Número de Telefone",
  nolikesyet: "Você ainda não recebeu curtidas",
  nomatches: "Você ainda não tem combinações",
  likematches: "Você pode começar a combinar com perfis dando curtidas neles",
  terms: "Termos",
  Privacy: "Privacidade",
  imprint: "Impressão",
  Address: "Endereço"
  },
  // Portugiesisch


	// Russisch
  ru: {
    secondtext: "globalchat.us предлагает вам новые уровни онлайн знакомств. Создание искусственного интеллекта Global Chat поможет пользователям быть с кем-то, кто на 94% их идеальный партнер.",
    SomeRandomHeadingGoesHere: "Присоединяйтесь бесплатно сегодня",
    noflirtsrecharge: "У вас недостаточно кредитов",
    heygetflirts: "Получить флирты",
    SEARCHINGFORNOTIFICAITON: "ПОИСК УВЕДОМЛЕНИЯ",
    Newconversation: "Новый разговор",
    MyPurchases: "Мои покупки",
    mypurchasedes: "Здесь вы найдете флирты и пакеты, которые вы купили.",
    mypurchacedess: "У вас пока нет транзакций",
    resetlink: "Введите свой адрес электронной почты, и мы вышлем вам ссылку для сброса",

    // transaction
    ChatsConversation: "ЧАТЫ (РАЗГОВОР)",
    message: "Сообщение",
    likes: "Лайки",
    matches: "Матчи",
    Liked: "Понравилось",
    Disliked: "Не понравилось",
    JustVisitedYou: "Только что посетил вас",
    LoadMore: "Загрузить еще",
    FliterUsers: "Фильтровать пользователей",
    Youdonthaveanynotifications: "У вас нет уведомлений",
    ActiveNow: "Активен сейчас",

    // landing
    SignIn: "Войти",
    FlirtToolNews: "Новости FlirtTool",
    Join: "Присоединиться",
    Free: "Бесплатно",
    Today: "Сегодня",
    BasicPackage: "Базовый пакет",
    twohoursfreechatexperience: "2 часа бесплатного опыта чата",
    Tryitforfree: "Попробуйте бесплатно",
    FlirtToolfor1Day: "FlirtTool на 1 день",
  Gblobalfor1Day:" GlobalChat на 1 день",
    RealChatFlatrate: "Реальный чат за фиксированную плату ТОЛЬКО $2",
    Grabit: "Захватить",
    Createanaccount: "Создать аккаунт",
    orgettheapp: "или загрузить приложение",
    OVER1MILLIONUSERSWORLDWIDE: "Более 1 миллиона пользователей по всему миру",
    MatchingProfiles: "Совпадающие профили",
    UpcomingonFlirtTool: "Предстоящее на FlirtTool",
    FindRealLifeWithFlirtTool: "Найдите настоящую жизнь с FlirtTool!",
    HowFlirtToolWorks: "Как работает FlirtTool",
    HowFlirtToolWorksdes: "FlirtTool предлагает вам новые уровни онлайн-знакомств. Окончательное творение FlirtTool - это искусственный интеллект персонализации MAZU, который поможет пользователям быть спаренными с тем, кто на 94% соответствует их идеальному партнеру",
    LEARNMORE: "УЗНАТЬ БОЛЬШЕ",
    About: "О нас",
    PressAndMedia: "Пресса и медиа",
    Blogs: "Блоги",
    LiveChat: "Онлайн-чат",
    ChatNow: "Чат сейчас",
    SocialMedia: "Социальные медиа",
    ContactUs: "Свяжитесь с нами",
    WelcometoFlirttool: "Добро пожаловать в Flirttool",
    // end of landing

    // login
    OrCreateanAccount: "Или создайте аккаунт",
    UsernameorEmail: "Имя пользователя или электронная почта",
    YourPassword: "Ваш пароль",
    Forgotpassword: "Забыли пароль",
    Reset: "Сброс",
    IAGREE: "Я СОГЛАШАЮСЬ С УСЛОВИЯМИ, ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ И ПРАВИЛАМИ",
    Clickhereto: "НАЖМИТЕ ЗДЕСЬ, ЧТОБЫ ПРОСМОТРЕТЬ НАШИ УСЛОВИЯ",

    // login

    // register
    Register:"Регистр (Registr)",
    FIRSTNAME: "ИМЯ",
    LastName: "Фамилия",
    Emailisnotvalid: "Email недействителен",
    Username: "Имя пользователя",
    Yourpasswords: "Ваш пароль",
    Passwordisnotvalid: "Пароль недействителен",
    Confirmpassword: "Подтвердите пароль",
    Usernamemusthaveatleast: "Имя пользователя должно содержать не менее",
    Alreadyhaveanaccount: "Уже есть аккаунт",
    LoginHere: "Войдите здесь",
    // register
    starthourlypackage: " Начать почасовой пакет",
    // Profile
    SubmitReport: "Отправить отчет",
    Blockcon: "Вы уверены, что хотите заблокировать",
    Yes: "Да",
    No: "Нет",
    reportreason: "Введите причину этого отчета",
    Visits: "Посещения",
    Photos: "Фотографии",
    UnlockExclusive: "Разблокировать эксклюзивные фотографии",
    UnlockNow: "Разблокировать сейчас",
    Personal: "Личная информация",
    Ethnicity: "Этническая принадлежность",
    BodyType: "Тип тела",
    Gender: "Пол",
    Height: "Рост",
    Relationship: "Отношения",
    Work: "Работа",
    Education: "Образование",
    LIFESTYLE: "СТИЛЬ ЖИЗНИ",
    LivesWith: "Проживает с",
    Car: "Машина",
    Religion: "Религия",
    Smoke: "Курение",
    Drink: "Алкоголь",
    Travel: "Путешествия",
    // profile

    // settings
    PhoneNumber: "Номер телефона",
    GeneralSettings: " Общие настройки",
    SaveChanges: "Сохранить изменения",
    RemoveAccount: "Удалить аккаунт",
    Yourprofile: "Ваш профиль",
    AboutMe: "Обо мне",
    Relationshipstatus: "Семейное положение",
    YourCountry: "Ваша страна",
    ChooseYourCountry: "Выберите вашу страну",
    Looks: "Внешность",
    Personality: "Личность",
    Character: "Характер",
    Children: "Дети",
    Friends: "Друзья",
    Pets: "Домашние питомцы",
    LivingWith: "Проживает с",
    MusicGenre: "Музыкальный жанр",
    Dish: "Блюдо",
    Song: "Песня",
    Hobby: "Хобби",
    Book: "Книга",
    Movie: "Фильм",
    ChangePassword: "Изменить пароль",
    NewPassword: "Новый пароль",

    // settings

    // profile setup
    SETUPYOURDATINGPROFILE: "Настройте свой профиль знакомств",
    uploadphoto: "Загрузите фотографию, чтобы установить свое фото профиля",
    picnotupdated: "Фотография профиля не обновлена",
    pleaseupdatepicture: "Вы должны загрузить фотографию профиля, чтобы продолжить",
    BirthYear: "Год рождения",
    Date: "Дата",
    Month: "Месяц",
    Day: "День",
    SexualPreference: "Сексуальные предпочтения",
    SexualPreferences: "Какие у вас сексуальные предпочтения",
    countrycode: "код страны",
    PhonNumber: "Номер телефона",
    // profile setup

    nolikesyet: "У вас пока нет лайков",
    nomatches: "У вас пока нет совпадений",
    likematches: "Вы можете начать подбирать профили, нравясь им",
    terms: "Условия",
    Privacy: "Конфиденциальность",
    imprint: "Оттиск",
    Address: "Адрес"
  },

  // Russisch

  // Urdu

  ur: {
    secondtext: "globalchat.us آپ کو آن لائن ڈیٹنگ کے نئے درجات فراہم کرتا ہے۔ Global Chat کی آخری ایجاد کا آئی اے شخصیت آپ کو ان کے مکمل میچ کے 94٪ کے ساتھ ملانے میں مدد فراہم کرے گا۔",
    SomeRandomHeadingGoesHere: "آج مفت شامل ہوں ",
    noflirtsrecharge: "آپ کے پاس کافی کریڈٹ نہیں ہیں",
    heygetflirts: "فلرٹس حاصل کریں",
    SEARCHINGFORNOTIFICAITON: "نوٹیفیکیشن کی تلاش",
    Newconversation: "نیا مکالمہ",
    MyPurchases: "میری خریداریاں",
    mypurchasedes: "یہاں آپ کو ملیں گے فلرٹس اور پیکیج جو آپ نے خریدا ہے۔",
    mypurchacedess: "آپ کے پاس ابھی کوئی لین دین نہیں ہوئی۔",
    resetlink: "اپنا ای میل ایڈریس درج کریں اور ہم آپ کو آپ کا ری سیٹ لنک بھیجیں گے",

    // transaction
    ChatsConversation: "چیٹ (مکالمہ)",
    message: "پیغام",
    likes: "پسند",
    matches: "میچز",
    Liked: "پسند کیا",
    Disliked: "ناپسندیدہ",
    JustVisitedYou: "ابھی آپ کو دور سے دیکھا",
    LoadMore: "مزید لوڈ کریں",
    FliterUsers: "صارفین کو فلٹر کریں",
    Youdonthaveanynotifications: "آپ کے پاس کوئی اطلاعات نہیں ہیں",
    ActiveNow: "ابھی فعال",

    // landing
    SignIn: "سائن ان",
    FlirtToolNews: "فلرٹ ٹول کی خبریں",
    Join: "شامل ہوں",
    Free: "مفت",
    Today: "آج",
    BasicPackage: "بنیادی پیکیج",
    twohoursfreechatexperience: "2 گھنٹے مفت چیٹ کا تجربہ",
    Tryitforfree: "مفت میں آزمائیں",
    FlirtToolfor1Day: "فلرٹ ٹول 1 دن کے لئے",
    RealChatFlatrate: "حقیقی چیٹ فلیٹ ریٹ صرف $ 2",
    Grabit: "پکڑو",
    Createanaccount: "اکاؤنٹ بنائیں",
    orgettheapp: "یا ایپ حاصل کریں",
    OVER1MILLIONUSERSWORLDWIDE: "دنیا بھر میں 1 ملین سے زیادہ صارفین",
    MatchingProfiles: "مطابقتی پروفائلز",
    UpcomingonFlirtTool: "فلرٹ ٹول پر آنے والا",
    FindRealLifeWithFlirtTool: "فلرٹ ٹول کے ساتھ حقیقی زندگی تلاش کریں!",
    HowFlirtToolWorks: "فلرٹ ٹول کس طرح کام کرتا ہے",
    HowFlirtToolWorksdes: "فلرٹ ٹول آپ کو آنلائن ڈیٹنگ کے نئے درجے پیش کرتا ہے۔ فلرٹ ٹول کا آخری ایجادی AI شخصیت MAZU آپ کو کسی کے ساتھ جو 94٪ ان کے مکمل میچ ہے، میچ کرنے میں مدد کرے گا",
    LEARNMORE: "مزید جانیں",
    About: "کے بارے میں",
    PressAndMedia: "پریس اور میڈیا",
    Blogs: "بلاگ",
    LiveChat: "زندہ چیٹ",
    ChatNow: "اب چیٹ کریں",
    SocialMedia: "سوشل میڈیا",
    ContactUs: "ہم سے رابطہ کریں",
    WelcometoFlirttool: "فلرٹ ٹول میں خوش آمدید",
    // end of landing

    // login
    OrCreateanAccount: "یا اکاؤنٹ بنائیں",
    UsernameorEmail: "صارف کا نام یا ای میل",
    YourPassword: "آپ کا پاس ورڈ",
    Forgotpassword: "پاس ورڈ بھول گئے ہیں",
    Reset: "ری سیٹ",
    IAGREE: "میں اصولوں، شرائط اور رازداری کی پالیسی سے متفق ہوں",
    Clickhereto: "ہماری شرائط دیکھنے کیلئے یہاں کلک کریں",

    // login

    // register
    FIRSTNAME: "پہلا نام",
    LastName: "آخری نام",
    Emailisnotvalid: "ای میل درست نہیں ہے",
    Username: "صارف کا نام",
    Yourpasswords: "آپ کا پاس ورڈ",
    Passwordisnotvalid: "پاس ورڈ درست نہیں ہے",
    Confirmpassword: "پاس ورڈ کی تصدیق کریں",
    Usernamemusthaveatleast: "صارف کا نام کم از کم ہونا چاہئے",
    Alreadyhaveanaccount: "پہلے ہی اکاؤنٹ ہے",
    LoginHere: "یہاں لاگ ان کریں",
    // register
    starthourlypackage: " گھنٹے کے پیکیج کا آغاز",
    // Profile
    SubmitReport: "رپورٹ جمع کرائیں",
    Blockcon: "کیا آپ واقعی بلاک کرنا چاہتے ہیں",
    Yes: "ہاں",
    No: "نہیں",
    reportreason: "اس رپورٹ کا سبب درج کریں",
    Visits: "دورہ",
    Photos: "تصاویر",
    UnlockExclusive: "اختصاصی تصاویر کو آن لاک کریں",
    UnlockNow: "ابھی آن لاک کریں",
    Personal: "ذاتی",
    Ethnicity: "نسلی",
    BodyType: "جسمانی قسم",
    Gender: "صنف",
    Height: "قد",
    Relationship: "تعلقات",
    Work: "کام",
    Education: "تعلیم",
    LIFESTYLE: "زندگی کا طریقہ",
    LivesWith: "کے ساتھ رہتا ہے",
    Car: "گاڑی",
    Religion: "مذہب",
    Smoke: "دھواں",
    Drink: "پینا",
    Travel: "سفر",
    // profile

    // settings
    PhoneNumber: "فون نمبر",
    GeneralSettings: " عام ترتیبات",
    SaveChanges: "ترمیمیں محفوظ کریں",
    RemoveAccount: "اکاؤنٹ ہٹائیں",
    Yourprofile: "آپ کا پروفائل",
    AboutMe: "میرے بارے میں",
    Relationshipstatus: "تعلقات کی حیثیت",
    YourCountry: "آپ کی ملک",
    ChooseYourCountry: "اپنا ملک منتخب کریں",
    Looks: "بہت کی نظر",
    Personality: "شخصیت",
    Character: "کردار",
    Children: "بچے",
    Friends: "دوست",
    Pets: "پالتو جانور",
    LivingWith: "کے ساتھ رہتا ہے",
    MusicGenre: "موسیقی کی قسم",
    Dish: "ڈش",
    Song: "گانا",
    Hobby: "شوق",
    Book: "کتاب",
    Movie: "فلم",
    ChangePassword: "پاس ورڈ تبدیل کریں",
    NewPassword: "نیا پاس ورڈ",

    // settings

    // profile setup
    SETUPYOURDATINGPROFILE: "اپنا ڈیٹنگ پروفائل سیٹ کریں",
    uploadphoto: "اپنی پروفائل تصویر مقرر کرنے کے لئے تصویر اپ لوڈ کریں",
    picnotupdated: "پروفائل تصویر اپ ڈیٹ نہیں ہوئی",
    pleaseupdatepicture: "آپ کو جاری رکھنے کے لئے پروفائل تصویر اپ لوڈ کرنی چاہئے",
    BirthYear: "سال پیدائش",
    Date: "تاریخ",
    Month: "مہینہ",
    Day: "دن",
    SexualPreference: "جنسی ترجیح",
    SexualPreferences: "آپ کی جنسی ترجیحات کیا ہیں",
    countrycode: "ملکی کوڈ",
    PhonNumber: "فون نمبر",
    // profile setup

    nolikesyet: "آپ کو ابھی تک کوئی لائکس نہیں ہیں",
    nomatches: "آپ کو ابھی تک کوئی میچ نہیں ملے",
    likematches: "آپ مخلوط کرنا شروع کرسکتے ہیں پروفائل کو پسند کر کے",
    terms: "شرائط",
    Privacy: "رازداری",
    imprint: "چھپائی",
    Address: "پتہ"
  },

  // Urdu

  // Indonesisch
  id: {
    secondtext: "globalchat.us menawarkan Anda tingkat baru dalam kencan online. Ciptaan utama kepribadian AI Global Chat akan membantu pengguna dipasangkan dengan seseorang yang merupakan 94% pasangan sempurna mereka.",
    SomeRandomHeadingGoesHere: "Bergabung Gratis Hari Ini",
    noflirtsrecharge: "Anda tidak memiliki cukup kredit",
    heygetflirts: "Dapatkan flirts",
    SEARCHINGFORNOTIFICAITON: "MENCARI NOTIFIKASI",
    Newconversation: "Percakapan Baru",
    MyPurchases: "Pembelian Saya",
    mypurchasedes: "Di sini Anda akan menemukan flirts dan paket yang Anda beli.",
    mypurchacedess: "Anda belum memiliki transaksi apa pun",
    resetlink: "Masukkan alamat email Anda dan kami akan mengirimkan tautan reset Anda",
    
    // transaksi
    ChatsConversation: "OBROLAN (PERCAKAPAN)",
    message: "Pesan",
    likes: "Suka",
    matches: "Pertandingan",
    Liked: "Disukai",
    Disliked: "Tidak Disukai",
    JustVisitedYou: "Baru Saja Mengunjungi Anda",
    LoadMore: "Muat Lebih",
    FliterUsers: "Saring Pengguna",
    Youdonthaveanynotifications: "Anda tidak memiliki notifikasi",
    ActiveNow: "Aktif Sekarang",
    
    // landing
    SignIn: "Masuk",
    FlirtToolNews: "Berita FlirtTool",
    Join: "Gabung",
    Free: "Gratis",
    Today: "Hari Ini",
    BasicPackage: "Paket Dasar",
    twohoursfreechatexperience: "Pengalaman Obrolan Gratis 2 Jam",
    Tryitforfree: "Cobalah Gratis",
    FlirtToolfor1Day: "FlirtTool selama 1 Hari",
    Gblobalfor1Day:" GlobalChat selama 1 Hari",

    RealChatFlatrate: "Obrolan Nyata Tarif Tetap HANYA $2",
    Grabit: "Dapatkan",
    Createanaccount: "Buat Akun",
    orgettheapp: "atau dapatkan aplikasinya",
    OVER1MILLIONUSERSWORLDWIDE: "Lebih dari 1 Juta Pengguna di Seluruh Dunia",
    MatchingProfiles: "Profil yang Cocok",
    UpcomingonFlirtTool: "Yang Akan Datang di FlirtTool",
    FindRealLifeWithFlirtTool: "Temukan Kehidupan Nyata dengan FlirtTool!",
    HowFlirtToolWorks: "Bagaimana FlirtTool Bekerja",
    HowFlirtToolWorksdes: "FlirtTool menawarkan Anda tingkat baru dalam kencan online. Ciptaan terakhir FlirtTool, kepribadian AI MAZU, akan membantu pengguna dipasangkan dengan seseorang yang merupakan 94% pasangan sempurna mereka",
    LEARNMORE: "PELAJARI LEBIH LANJUT",
    About: "Tentang",
    PressAndMedia: "Pers dan Media",
    Blogs: "Blog",
    LiveChat: "Obrolan Langsung",
    ChatNow: "Obrolan Sekarang",
    SocialMedia: "Media Sosial",
    ContactUs: "Hubungi Kami",
    WelcometoFlirttool: "Selamat Datang di Flirttool",
    // akhir dari landing
    
    // masuk
    OrCreateanAccount: "Atau Buat Akun",
    UsernameorEmail: "Nama Pengguna atau Email",
    YourPassword: "Kata Sandi Anda",
    Forgotpassword: "Lupa kata sandi",
    Reset: "Reset",
    IAGREE: "SAYA SETUJU DENGAN SYARAT, KETENTUAN, DAN KEBIJAKAN PRIVASI",
    Clickhereto: "KLIK DI SINI UNTUK MELIHAT SYARAT KAMI",
    
    // masuk
    
    // daftar
    FIRSTNAME: "NAMA DEPAN",
    LastName: "Nama Belakang",
    Emailisnotvalid: "Email tidak valid",
    Username: "Nama Pengguna",
    Yourpasswords: "Kata sandi Anda",
    Passwordisnotvalid: "Kata sandi tidak valid",
    Confirmpassword: "Konfirmasi kata sandi",
    Usernamemusthaveatleast: "Nama pengguna harus memiliki setidaknya",
    Alreadyhaveanaccount: "Sudah memiliki akun",
    LoginHere: "Login di sini",
    // daftar
    starthourlypackage: " Mulai Paket per Jam",
    // Profil
    SubmitReport: "Kirim Laporan",
    Blockcon: "Apakah Anda yakin ingin memblokir",
    Yes: "Ya",
    No: "Tidak",
    reportreason: "Masukkan alasan Anda untuk laporan ini",
    Visits: "Kunjungan",
    Photos: "Foto",
    UnlockExclusive: "Buka Kunci Gambar Eksklusif",
    UnlockNow: "Buka Sekarang",
    Personal: "Pribadi",
    Ethnicity: "Etnisitas",
    BodyType: "Tipe Tubuh",
    Gender: "Jenis Kelamin",
    Height: "Tinggi",
    Relationship: "Hubungan",
    Work: "Pekerjaan",
    Education: "Pendidikan",
    LIFESTYLE: "GAYA HIDUP",
    LivesWith: "Hidup Dengan",
    Car: "Mobil",
    Religion: "Agama",
    Smoke: "Merokok",
    Drink: "Minum",
    Travel: "Perjalanan",
    // Profil
    
    // pengaturan
    PhoneNumber: "Nomor Telepon",
    GeneralSettings: "Pengaturan Umum",
    SaveChanges: "Simpan Perubahan",
    RemoveAccount: "Hapus Akun",
    Yourprofile: "Profil Anda",
    AboutMe: "Tentang Saya",
    Relationshipstatus: "Status Hubungan",
    YourCountry: "Negara Anda",
    ChooseYourCountry: "Pilih Negara Anda",
    Looks: "Penampilan",
    Personality: "Kepribadian",
    Character: "Karakter",
    Children: "Anak-anak",
    Friends: "Teman",
    Pets: "Hewan Peliharaan",
    LivingWith: "Hidup Dengan",
    MusicGenre: "Genre Musik",
    Dish: "Hidangan",
    Song: "Lagu",
    Hobby: "Hobi",
    Book: "Buku",
    Movie: "Film",
    ChangePassword: "Ubah Kata Sandi",
    NewPassword: "Kata Sandi Baru",
    
    // pengaturan
    
    // setup profil
    SETUPYOURDATINGPROFILE: "SETEL PROFIL KENCAN ANDA",
    uploadphoto: "Unggah foto untuk mengatur Foto Profil Anda",
    picnotupdated: "Gambar profil tidak diperbarui",
    pleaseupdatepicture: "Anda harus mengunggah foto profil untuk melanjutkan",
    BirthYear: "Tahun Lahir",
    Date: "Tanggal",
    Month: "Bulan",
    Day: "Hari",
    SexualPreference: "Preferensi Seksual",
    SexualPreferences: "Apa preferensi seksual Anda",
    countrycode: "Kode negara",
    PhonNumber: "Nomor Telepon",
    // setup profil
    
    nolikesyet: "Anda belum memiliki Suka",
    nomatches: "Anda belum memiliki pertandingan",
    likematches: "Anda dapat memulai mencocokkan profil dengan menyukainya",
    terms: "Syarat dan Ketentuan",
    Privacy: "Privasi",
    imprint: "Cetak",
    Address: "Alamat"
  },

  // Indonesisch


  // Japanisch

  ja: {
    Register:" 登録する (Tōroku suru)",
    secondtext: "globalchat.us は、オンラインデートの新たなレベルを提供します。Global Chatの究極のAIパーソナリティの創造により、ユーザーは94％の完璧な相手とペアになるのを手助けされます。",
    SomeRandomHeadingGoesHere: "今日無料で参加する",
    noflirtsrecharge: "クレジットが不足しています",
    heygetflirts: "フリートを取得",
    SEARCHINGFORNOTIFICAITON: "通知を検索中",
    Newconversation: "新しい会話",
    MyPurchases: "私の購入",
    mypurchasedes: "ここには、購入したフリートとパッケージが表示されます。",
    mypurchacedess: "まだ取引はありません",
    resetlink: "メールアドレスを入力して、リセットリンクを送信します",
    
    // トランザクション
    ChatsConversation: "チャット（会話）",
    message: "メッセージ",
    likes: "いいね",
    matches: "マッチ",
    Liked: "いいね済み",
    Disliked: "嫌い",
    JustVisitedYou: "あなたを訪問したばかり",
    LoadMore: "さらに読み込む",
    FliterUsers: "ユーザーをフィルター",
    Youdonthaveanynotifications: "通知がありません",
    ActiveNow: "現在アクティブ",
    
    // ランディング
    SignIn: "サインイン",
    FlirtToolNews: "FlirtToolニュース",
    Join: "参加する",
    Free: "無料",
    Today: "今日",
    BasicPackage: "基本パッケージ",
    twohoursfreechatexperience: "2時間の無料チャット体験",
    Tryitforfree: "無料で試す",
    FlirtToolfor1Day: "1日のFlirtTool",
    Gblobalfor1Day:" 1日の GlobalChat",

    RealChatFlatrate: "リアルチャット定額 $2のみ",
    Grabit: "手に入れる",
    Createanaccount: "アカウントを作成する",
    orgettheapp: "またはアプリを入手",
    OVER1MILLIONUSERSWORLDWIDE: "世界中で100万人以上のユーザー",
    MatchingProfiles: "一致するプロフィール",
    UpcomingonFlirtTool: "FlirtToolの今後",
    FindRealLifeWithFlirtTool: "FlirtToolでリアルライフを見つける！",
    HowFlirtToolWorks: "FlirtToolの仕組み",
    HowFlirtToolWorksdes: "FlirtToolはオンラインデートの新しいレベルを提供します。 FlirtToolの究極の作品、AIパーソナリティMAZUは、ユーザーが94％の完璧なマッチングでペアになるのを助けます",
    LEARNMORE: "詳細を見る",
    About: "約",
    PressAndMedia: "プレスとメディア",
    Blogs: "ブログ",
    LiveChat: "ライブチャット",
    ChatNow: "今すぐチャット",
    SocialMedia: "ソーシャルメディア",
    ContactUs: "お問い合わせ",
    WelcometoFlirttool: "Flirttoolへようこそ",
    // ランディング
    
    // ログイン
    OrCreateanAccount: "またはアカウントを作成",
    UsernameorEmail: "ユーザー名またはメール",
    YourPassword: "あなたのパスワード",
    Forgotpassword: "パスワードをお忘れですか",
    Reset: "リセット",
    IAGREE: "利用規約、条件、およびプライバシーポリシーに同意します",
    Clickhereto: "利用規約を表示するにはここをクリック",
    
    // 登録
    
    FIRSTNAME: "名前",
    LastName: "苗字",
    Emailisnotvalid: "メールアドレスが無効です",
    Username: "ユーザー名",
    Yourpasswords: "あなたのパスワード",
    Passwordisnotvalid: "パスワードが無効です",
    Confirmpassword: "パスワードを確認",
    Usernamemusthaveatleast: "ユーザー名は少なくとも持っている必要があります",
    Alreadyhaveanaccount: "すでにアカウントをお持ちです",
    LoginHere: "ここでログイン",
    // 登録
    
    starthourlypackage: "毎時パッケージを開始",
    // プロフィール
    SubmitReport: "レポートを提出",
    Blockcon: "本当にブロックしますか",
    Yes: "はい",
    No: "いいえ",
    reportreason: "このレポートの理由を入力してください",
    Visits: "訪問",
    Photos: "写真",
    UnlockExclusive: "独占写真をアンロック",
    UnlockNow: "今すぐアンロック",
    Personal: "個人的な",
    Ethnicity: "民族",
    BodyType: "体型",
    Gender: "性別",
    Height: "高さ",
    Relationship: "関係",
    Work: "作業",
    Education: "教育",
    LIFESTYLE: "ライフスタイル",
    LivesWith: "一緒に暮らす",
    Car: "車",
    Religion: "宗教",
    Smoke: "喫煙",
    Drink: "飲む",
    Travel: "旅行",
    // プロフィール
    
    // 設定
    PhoneNumber: "電話番号",
    GeneralSettings: "一般設定",
    SaveChanges: "変更を保存",
    RemoveAccount: "アカウントを削除",
    Yourprofile: "あなたのプロフィール",
    AboutMe: "私について",
    Relationshipstatus: "関係の状態",
    YourCountry: "あなたの国",
    ChooseYourCountry: "あなたの国を選択",
    Looks: "外見",
    Personality: "性格",
    Character: "キャラクター",
    Children: "子供",
    Friends: "友達",
    Pets: "ペット",
    LivingWith: "一緒に住む",
    MusicGenre: "音楽のジャンル",
    Dish: "料理",
    Song: "曲",
    Hobby: "趣味",
    Book: "本",
    Movie: "映画",
    ChangePassword: "パスワードを変更",
    NewPassword: "新しいパスワード",
    
    // 設定
    
    // プロフィールの設定
    SETUPYOURDATINGPROFILE: "あなたのデートプロフィールを設定する",
    uploadphoto: "プロフィール写真を設定するために写真をアップロードする",
    picnotupdated: "プロフィール写真が更新されていません",
    pleaseupdatepicture: "続行するにはプロフィール写真をアップロードしてください",
    BirthYear: "誕生年",
    Date: "日付",
    Month: "月",
    Day: "日",
    SexualPreference: "性的嗜好",
    SexualPreferences: "あなたの性的嗜好は何ですか",
    countrycode: "国コード",
    PhonNumber: "電話番号",
    // プロフィールの設定
    
    nolikesyet: "まだいいねがありません",
    nomatches: "まだマッチがありません",
    likematches: "プロフィールをいいねすることでマッチングを開始できます",
    terms: "利用規約",
    Privacy: "プライバシー",
    imprint: "版権表示",
    Address: "住所"
  },


  // Japanisch


  // Marathi
  mr: {
    secondtext: "globalchat.us आपल्याला ऑनलाइन डेटिंगच्या नव्या पातळींवर आणतं. Global Chatच्या AI व्यक्तिमत्वाची शेवटची निर्मिती वापरकर्त्यांना त्यांच्या 94% सहिताचा पूर्ण साथी मिळवण्यात मदत करेल.",
    SomeRandomHeadingGoesHere: "आज मोफत सामील व्हा",
    noflirtsrecharge: "तुमच्याकडून क्रेडिट नाही",
    heygetflirts: "फ्लर्ट्स मिळवा",
    SEARCHINGFORNOTIFICAITON: "सूचना शोधत आहोत",
    Newconversation: "नवीन चर्चा",
    MyPurchases: "माझं खरेदी",
    mypurchasedes: "येथे आपल्याला विकत घेण्यात आलेल्या फ्लर्ट्स आणि पॅकेज्स मिळतील.",
    mypurchacedess: "तुमच्याकडून अद्याप व्यवहार नाही",
    resetlink: "आपला ई-मेल पत्ता प्रविष्ट करा आणि आम्ही आपल्या रीसेट लिंक पाठवू",
    
    // संवाद
    ChatsConversation: "चॅट्स (चर्चा)",
    message: "संदेश",
    likes: "आवड",
    matches: "मॅचेस",
    Liked: "आवडले",
    Disliked: "अवगड",
    JustVisitedYou: "आपल्याला उपचारले",
    LoadMore: "आधी लोड करा",
    FliterUsers: "फिल्टर युजर्स",
    Youdonthaveanynotifications: "तुमच्याकडून कोणत्याही सूचना नाहीत",
    ActiveNow: "सध्या सक्रिय",
    
    // लँडिंग
    SignIn: "साइन इन करा",
    FlirtToolNews: "फ्लर्ट टूल बातम्या",
    Join: "सामील व्हा",
    Free: "मुक्त",
    Today: "आज",
    BasicPackage: "मूल पॅकेज",
    twohoursfreechatexperience: "2 तास विनामूल्य चॅट अनुभव",
    Tryitforfree: "विनामूल्य प्रयत्न करा",
    FlirtToolfor1Day: "1 दिवसाच्या फ्लर्ट टूल",
    Gblobalfor1Day:" एक दिवसासाठी ग्लोबलचॅट च्या पाठांचे अनुवाद करा",

    RealChatFlatrate: "रिअल चॅट फ्लॅटरेट केवळ $ 2",
    Grabit: "आपल्याचं घेतलं",
    Createanaccount: "खाते तयार करा",
    orgettheapp: "किंवा अॅप घ्या",
    OVER1MILLIONUSERSWORLDWIDE: "जगभरात 1 मिलियन वापरकर्ते",
    MatchingProfiles: "मिळालेले प्रोफाइल्स",
    UpcomingonFlirtTool: "फ्लर्ट टूलवर येत आहे",
    FindRealLifeWithFlirtTool: "फ्लर्ट टूलसह वास्तविक जीवन शोधा!",
    HowFlirtToolWorks: "फ्लर्ट टूल कसं कार्य करतं",
    HowFlirtToolWorksdes: "फ्लर्ट टूल तुमच्याला ऑनलाइन डेटिंगच्या नवीन आणि मोठी स्तरे प्रदान करतं. फ्लर्ट टूलचं अंतिम निर्मिती एआय पर्सनॅलिटी MAZU याच्यामध्ये 94% तुमच्या पूर्ण साथीच्या पूर्ण मॅचिंगमध्ये सहाय्य करेल",
    LEARNMORE: "आणखी ओळखा",
    About: "बद्दल",
    PressAndMedia: "प्रेस आणि मीडिया",
    Blogs: "ब्लॉग्स",
    LiveChat: "लाइव चॅट",
    ChatNow: "आता चॅट करा",
    SocialMedia: "सोशल मीडिया",
    ContactUs: "आमच्याशी संपर्क साधा",
    WelcometoFlirttool: "फ्लर्टटूलवर स्वागत आहे",
    // लँडिंग
    
    // लॉगीन
    OrCreateanAccount: "किंवा एक खाते तयार करा",
    UsernameorEmail: "वापरकर्तानाव किंवा ईमेल",
    YourPassword: "तुमचा संकेतशब्द",
    Forgotpassword: "संकेतशब्द विसरलात",
    Reset: "रीसेट करा",
    IAGREE: "मी टर्म्स, शर्ते आणि गोपनीयता धोरणांशी सहमत आहे",
    Clickhereto: "आमच्या टर्म्स पहा क्लिक करा",
    
    // नोंदणी
    FIRSTNAME: "प्रथम नाव",
    LastName: "आडनाव",
    Emailisnotvalid: "ईमेल अवैध आहे",
    Username: "वापरकर्तानाव",
    Yourpasswords: "तुमचे संकेतशब्द",
    Passwordisnotvalid: "संकेतशब्द अवैध आहे",
    Confirmpassword: "संकेतशब्द पुष्टी करा",
    Usernamemusthaveatleast: "वापरकर्तानाव किमान",
    Alreadyhaveanaccount: "पहिल्यांदा खाते आहे",
    LoginHere: "येथे लॉगिन करा",
    // नोंदणी
    
    starthourlypackage: "प्रति तास पॅकेज सुरू करा",
    // प्रोफाइल
    SubmitReport: "रिपोर्ट सबमिट करा",
    Blockcon: "तुम्ही खात्री आहात की ब्लॉक करायचं",
    Yes: "हो",
    No: "नाही",
    reportreason: "या अहवालासाठी आपली कारणे प्रविष्ट करा",
    Visits: "भेटी",
    Photos: "फोटो",
    UnlockExclusive: "विशेष चित्रे अनलॉक करा",
    UnlockNow: "आत्ता अनलॉक करा",
    Personal: "वैयक्तिक",
    Ethnicity: "जात",
    BodyType: "शारीरिक प्रकार",
    Gender: "लिंग",
    Height: "उंची",
    Relationship: "नाते",
    Work: "काम",
    Education: "शिक्षण",
    LIFESTYLE: "जीवनशैली",
    LivesWith: "सोबत राहतात",
    Car: "गाडी",
    Religion: "धर्म",
    Smoke: "धूप",
    Drink: "पेय",
    Travel: "प्रवास",
    // प्रोफाइल
    
    // सेटिंग्ज
    PhoneNumber: "फोन नंबर",
    GeneralSettings: "सामान्य सेटिंग्ज",
    SaveChanges: "बदल जतन करा",
    RemoveAccount: "खाते काढून टाका",
    Yourprofile: "तुमचे प्रोफाइल",
    AboutMe: "माझ्याबद्दल",
    Relationshipstatus: "नातेची स्थिती",
    YourCountry: "तुमचा देश",
    ChooseYourCountry: "तुमचा देश निवडा",
    Looks: "दिसणे",
    Personality: "व्यक्तिमत्त्व",
    Character: "वर्तन",
    Children: "मुले",
    Friends: "मित्र",
    Pets: "पालतू प्राणी",
    LivingWith: "सोबत राहणे",
    MusicGenre: "संगीतचा प्रकार",
    Dish: "डिश",
    Song: "गाणं",
    Hobby: "खोबर",
    Book: "पुस्तक",
    Movie: "चित्रपट",
    ChangePassword: "संकेतशब्द बदला",
    NewPassword: "नवीन संकेतशब्द",
    
    // सेटिंग्ज
    
    // प्रोफाइल सेटअप
    SETUPYOURDATINGPROFILE: "तुमचा डेटिंग प्रोफाइल सेट करा",
    uploadphoto: "तुमच्या प्रोफाइल पिक्चर सेट करण्यासाठी फोटो अपलोड करा",
    picnotupdated: "प्रोफाइल चित्र अद्यात अपडेट झालेला नाही",
    pleaseupdatepicture: "आगे बढण्यासाठी कृपया प्रोफाइल फोटो अपलोड करा",
    BirthYear: "जन्म वर्ष",
    Date: "तारीख",
    Month: "महिना",
    Day: "दिवस",
    SexualPreference: "लैंगिक पसंती",
    SexualPreferences: "तुमच्या लैंगिक पसंती काय आहे",
    countrycode: "देश कोड",
    PhonNumber: "फोन नंबर",
    // प्रोफाइल सेटअप
    
    nolikesyet: "तुमच्याकडून अद्याप कोणत्याही आवडत नाही",
    nomatches: "तुमच्याकडून अद्याप कोणत्याही मॅचेस नाहीत",
    likematches: "आपल्याला प्रोफाइल सोबत मॅच करण्यास सुरू करा",
    terms: "अटी",
    Privacy: "गोपनीयता",
    imprint: "इम्प्रिंट",
    Address: "पत्ता"
  },

  // Marathi
  // Vietnamesisch
  vi: {
    secondtext: "globalchat.us mang đến cho bạn những cấp độ mới của hẹn hò trực tuyến. Sự tạo ra cuối cùng của nhân cách trí tuệ nhân tạo của Global Chat sẽ giúp người dùng được ghép đôi với ai đó là 94% người hoàn hảo của họ.",
    SomeRandomHeadingGoesHere:"Tham gia miễn phí ngày hôm nay",
    noflirtsrecharge: "Bạn không có đủ tín dụng",
    heygetflirts: "Nhận lời tán tỉnh",
    SEARCHINGFORNOTIFICAITON:"ĐANG TÌM KIẾM THÔNG BÁO",
    Newconversation:"Cuộc trò chuyện mới",
    MyPurchases: "Các giao dịch của tôi",
    mypurchasedes:
      "Ở đây bạn sẽ tìm thấy những lời tán tỉnh và gói bạn đã mua.",
    mypurchacedess: "Bạn chưa có bất kỳ giao dịch nào",
    resetlink:
      "Nhập địa chỉ email của bạn và chúng tôi sẽ gửi cho bạn liên kết đặt lại của bạn",
    // transaction

    ChatsConversation: "CUỘC TRÒ CHUYỆN",
    message: "Tin nhắn",
    likes: "Thích",
    matches: "Phù hợp",
    Liked: "Đã thích",
    Disliked: "Không thích",
    JustVisitedYou: "Vừa thăm bạn",
    LoadMore: "Tải thêm",
    FliterUsers: "Lọc người dùng",
    Youdonthaveanynotifications: "Bạn không có bất kỳ thông báo nào",
    ActiveNow: "Đang hoạt động",

    // landing
    SignIn: "Đăng nhập",
    FlirtToolNews: "Thông tin về FlirtTool",
    Join: "Tham gia",
    Free: "Miễn phí",
    Today: "Hôm nay",
    BasicPackage: "Gói Cơ bản",
    twohoursfreechatexperience: "2 giờ trải nghiệm trò chuyện miễn phí",
    Tryitforfree: "Thử miễn phí",
    FlirtToolfor1Day: "FlirtTool trong 1 Ngày",
    Gblobalfor1Day:"Global trong 1 Ngày",

    RealChatFlatrate: "Giá cố định Chat thực sự CHỈ $2",
    Grabit: "Nắm bắt",
    Createanaccount: "Tạo tài khoản",
    orgettheapp: "hoặc tải ứng dụng",
    OVER1MILLIONUSERSWORLDWIDE: "Hơn 1 Triệu Người dùng trên toàn thế giới",
    MatchingProfiles: "Các hồ sơ phù hợp",
    UpcomingonFlirtTool: "Sắp có trên FlirtTool",
    FindRealLifeWithFlirtTool: "Tìm Cuộc sống Thực với FlirtTool!",
    HowFlirtToolWorks: "Cách FlirtTool Hoạt động",
    HowFlirtToolWorksdes:
      " FlirtTool mang đến cho bạn các cấp độ mới của hẹn hò trực tuyến. Sáng tạo cuối cùng của FlirtTool với trí tuệ nhân tạo MAZU sẽ giúp người dùng được ghép với ai đó mà là 94% người phù hợp hoàn hảo của họ",
    LEARNMORE: "TÌM HIỂU THÊM",
    About: "Về chúng tôi",
    PressAndMedia: "Báo chí và Truyền thông",
    Blogs: "Blogs",
    LiveChat: "Trò chuyện trực tiếp",
    ChatNow: "Trò chuyện ngay",
    SocialMedia: "Mạng xã hội",
    ContactUs: "Liên hệ",
    WelcometoFlirttool: "Chào mừng đến với Flirttool",
    // end of landing

    // login
    OrCreateanAccount: "Hoặc Tạo tài khoản",
    UsernameorEmail: "Tên người dùng hoặc Email",
    YourPassword: "Mật khẩu của bạn",
    Forgotpassword: "Quên mật khẩu",
    Reset: "Đặt lại",
    IAGREE: "TÔI ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN, ĐIỀU KIỆN VÀ CHÍNH SÁCH BẢO MẬT",
    Clickhereto: "NHẤN VÀO ĐÂY ĐỂ XEM ĐIỀU KHOẢN CỦA CHÚNG TÔI",

    // login

    // register
    FIRSTNAME: "TÊN",
    LastName: "Họ",
    Emailisnotvalid: "Email không hợp lệ",
    Username: "Tên người dùng",
    Yourpasswords: "Mật khẩu của bạn",
    Passwordisnotvalid: "Mật khẩu không hợp lệ",
    Confirmpassword: "Xác nhận mật khẩu",
    Usernamemusthaveatleast: "Tên người dùng phải có ít nhất",
    Alreadyhaveanaccount: "Đã có tài khoản",
    LoginHere: "Đăng nhập tại đây",
    // register
    starthourlypackage: "Bắt đầu Gói theo giờ",
    // Profile
    SubmitReport: "Gửi Báo cáo",
    Blockcon: "Bạn có chắc muốn chặn",
    Yes: "Có",
    No: "Không",
    reportreason: "Nhập lý do của bạn cho báo cáo này",
    Visits: "Lượt ghé thăm",
    Photos: "Ảnh",
    UnlockExclusive: "Mở khóa Ảnh độc quyền",
    UnlockNow: "Mở khóa Ngay",
    Personal: "Cá nhân",
    Ethnicity: "Chủng tộc",
    BodyType: "Dáng người",
    Gender: "Giới tính",
    Height: "Chiều cao",
    Relationship: "Mối quan hệ",
    Work: "Công việc",
    Education: "Giáo dục",
    LIFESTYLE: "LỐI SỐNG",
    LivesWith: "Sống cùng",
    Car: "Xe hơi",
    Religion: "Tôn giáo",
    Smoke: "Hút thuốc",
    Drink: "Uống rượu",
    Travel: "Du lịch",
    // profile

    // settings
    PhoneNumber: "Số điện thoại",
    GeneralSettings: "Cài đặt chung",
    SaveChanges: "Lưu thay đổi",
    RemoveAccount: "Xóa tài khoản",
    Yourprofile: "Hồ sơ của bạn",
    AboutMe: "Về tôi",
    Relationshipstatus: "Tình trạng mối quan hệ",
    YourCountry: "Quốc gia của bạn",
    ChooseYourCountry: "Chọn Quốc gia của bạn",
    Looks: "Ngoại hình",
    Personality: "Tính cách",
    Character: "Bản chất",
    Children: "Trẻ em",
    Friends: "Bạn bè",
    Pets: "Thú cưng",
    LivingWith: "Sống cùng",
    MusicGenre: "Thể loại âm nhạc",
    Dish: "Món ăn",
    Song: "Bài hát",
    Hobby: "Sở thích",
    Book: "Sách",
    Movie: "Phim",
    ChangePassword: "Đổi mật khẩu",
    NewPassword: "Mật khẩu mới",

    // settings

    // profile setup
    SETUPYOURDATINGPROFILE: "THIẾT LẬP HỒ SƠ HẸN HÒ CỦA BẠN",
    uploadphoto: "Tải ảnh lên để đặt Ảnh đại diện của bạn",
    picnotupdated: "Ảnh hồ sơ chưa được cập nhật",
    pleaseupdatepicture: "Bạn nên tải ảnh hồ sơ lên để tiếp tục",
    BirthYear: "Năm sinh",
    Date: "Ngày",
    Month: "Tháng",
    Day: "Ngày",
    SexualPreference: "Sở thích tình dục",
    SexualPreferences: "Sở thích tình dục của bạn là gì",
    countrycode: "Mã quốc gia",
    PhonNumber: "Số điện thoại",
    // profile setup

    nolikesyet: "Bạn chưa có bất kỳ Lượt thích nào",
    nomatches: "Bạn chưa có bất kỳ trận đấu nào",
    likematches: "Bạn có thể bắt đầu phù hợp với hồ sơ bằng cách thích họ",
    terms:"Điều khoản",
    Privacy:"Quyền riêng tư",
    imprint:"Dấu ấn",
    Address: "Địa chỉ"
  },

  // Vietnamesisch


  // Tagalog

  tl: {
    secondtext: "Nag-aalok ang globalchat.us ng bagong antas ng online dating. Ang huling likha ng personalidad ng AI ng Global Chat ay tutulong sa mga gumagamit na maging magkapareho sa sinumang may 94% na perpektong katugma nila.",
    SomeRandomHeadingGoesHere:"Sali na ng Libre Ngayon",
    noflirtsrecharge: "Wala kang sapat na mga kredito",
    heygetflirts: "Kumuha ng mga flirts",
    SEARCHINGFORNOTIFICAITON:"NAGHAHANAP PARA SA PAALALA",
    Newconversation:"Bagong Pag-uusap",
    MyPurchases: "Aking Mga Binili",
    mypurchasedes:
      "Dito mo matatagpuan ang mga flirts at mga package na binili mo.",
    mypurchacedess: "Wala ka pang mga transaksyon",
    resetlink:
      "Ilagay ang iyong E-mail address at ipadala namin sa iyo ang iyong reset link",
    // transaction

    ChatsConversation: "USAPAN (KAGUSTUHAN)",
    message: "Mensahe",
    likes: "gusto",
    matches: "Mga Pagtutugma",
    Liked: "Pinili",
    Disliked: "Hindi nagustuhan",
    JustVisitedYou: "Bagong Bumisita Sa'yo",
    LoadMore: "Mag-load ng Marami",
    FliterUsers: "Pumili ng Mga User",
    Youdonthaveanynotifications: "Wala kang anumang mga abiso",
    ActiveNow: "Aktibo Ngayon",

    // landing
    SignIn: "Mag-sign In",
    FlirtToolNews: "Balita ng FlirtTool",
    Join: "Sumali",
    Free: "Libre",
    Today: "Ngayon",
    BasicPackage: "Basic Package",
    twohoursfreechatexperience: "2 oras na libreng karanasan sa pakikipag-usap",
    Tryitforfree: "Subukan ito nang libre",
    FlirtToolfor1Day: "FlirtTool para sa 1 Araw",
    Gblobalfor1Day:"GlobalChatpara sa 1 Araw",

    RealChatFlatrate: "Tunay na Chat Flatrate LAMANG $2",
    Grabit: "Kunin ito",
    Createanaccount: "Gumawa ng account",
    orgettheapp: "o kumuha ng app",
    OVER1MILLIONUSERSWORLDWIDE: "Higit sa 1 Milyong User sa buong mundo",
    MatchingProfiles: "Mga Katugmang Profile",
    UpcomingonFlirtTool: "Pamamahagi sa FlirtTool",
    FindRealLifeWithFlirtTool: "Mahanap ang Tunay na Buhay sa FlirtTool!",
    HowFlirtToolWorks: "Paano Gumagana ang FlirtTool",
    HowFlirtToolWorksdes:
      "Ang FlirtTool ay nag-aalok sa iyo ng mga bagong antas ng online na pakikipag-date. Ang pangwakas na likha ng FlirtTool na AI personality MAZU ay tutulong sa mga gumagamit na maipares sa isang tao na 94% ang kanilang perpektong katugma",
    LEARNMORE: "MATUTO PA",
    About: "Tungkol sa",
    PressAndMedia: "Pindutin at Media",
    Blogs: "Mga Blog",
    LiveChat: "Live na Usapan",
    ChatNow: "Chat Ngayon",
    SocialMedia: "Social Media",
    ContactUs: "Makipag-ugnayan sa Amin",
    WelcometoFlirttool: "Maligayang Pagdating sa Flirttool",
    // end of landing

    // login
    OrCreateanAccount: "O Lumikha ng Account",
    UsernameorEmail: "Username o Email",
    YourPassword: "Ang Iyong Password",
    Forgotpassword: "Nakalimutan ang password",
    Reset: "I-reset",
    IAGREE: "SUMASANG-AYON AKO SA MGA TUNTUNIN, KONDISYON, AT PATAKARAN SA PRIVACY",
    Clickhereto: "PUMINDOT DITO PARA MAKITA ANG AMING MGA TUNTUNIN",

    // login

    // register
    FIRSTNAME: "UNANG PANGALAN",
    LastName: "Huling Pangalan",
    Emailisnotvalid: "Ang Email ay Hindi Bago",
    Username: "Username",
    Yourpasswords: "Ang iyong password",
    Passwordisnotvalid: "Ang password ay hindi wasto",
    Confirmpassword: "Kumpirmahin ang password",
    Usernamemusthaveatleast: "Ang Username ay dapat may hindi bababa sa",
    Alreadyhaveanaccount: "Mayroon ka nang account",
    LoginHere: "Mag-login Dito",
    // register
    starthourlypackage: "Magsimula ng Oras na Package",
    // Profile
    SubmitReport: "Ipasa ang Ulat",
    Blockcon: "Sigurado ka bang gusto mong i-block",
    Yes: "Oo",
    No: "Hindi",
    reportreason: "Maglagay ng iyong dahilan para sa ulat na ito",
    Visits: "Mga Pagdalaw",
    Photos: "Mga Larawan",
    UnlockExclusive: "I-unlock ang mga eksklusibong larawan",
    UnlockNow: "I-unlock Ngayon",
    Personal: "Personal",
    Ethnicity: "Lahi",
    BodyType: "Uri ng Katawan",
    Gender: "Kasarian",
    Height: "Taas",
    Relationship: "Relasyon",
    Work: "Trabaho",
    Education: "Edukasyon",
    LIFESTYLE: "ESTILO NG BUHAY",
    LivesWith: "Nakatira Kasama",
    Car: "Sasakyan",
    Religion: "Relihiyon",
    Smoke: "Nag-yoyosi",
    Drink: "Umiinom",
    Travel: "Paglalakbay",
    // profile

    // settings
    PhoneNumber: "Numero ng Telepono",
    GeneralSettings: "Pangkalahatang Mga Setting",
    SaveChanges: "I-save ang mga Pagbabago",
    RemoveAccount: "Tanggalin ang Account",
    Yourprofile: "Ang iyong profile",
    AboutMe: "Tungkol sa Akin",
    Relationshipstatus: "Katayuan sa Relasyon",
    YourCountry: "Iyong Bansa",
    ChooseYourCountry: "Pumili ng Iyong Bansa",
    Looks: "Itsura",
    Personality: "Personalidad",
    Character: "Karakter",
    Children: "Mga Bata",
    Friends: "Mga Kaibigan",
    Pets: "Mga Alaga",
    LivingWith: "Nakatira Kasama",
    MusicGenre: "Genre ng Musika",
    Dish: "Lutuin",
    Song: "Kanta",
    Hobby: "Libangan",
    Book: "Aklat",
    Movie: "Pelikula",
    ChangePassword: "Baguhin ang Password",
    NewPassword: "Bagong Password",

    // settings

    // profile setup
    SETUPYOURDATINGPROFILE: "ITAKDA ANG IYONG PROFILE NG PAGTATAKWIL",
    uploadphoto: "Mag-upload ng larawan para sa iyong Profile Picture",
    picnotupdated: "Ang larawan sa profile ay hindi na-update",
    pleaseupdatepicture: "Dapat mong i-upload ang larawan sa profile upang magpatuloy",
    BirthYear: "Taon ng Kapanganakan",
    Date: "Petsa",
    Month: "Buwan",
    Day: "Araw",
    SexualPreference: "Pagnanasa sa Sekswal",
    SexualPreferences: "Anong mga pagnanasa sa sekswal mo",
    countrycode: "Kodigo ng Bansa",
    PhonNumber: "Numero ng Telepono",
    // profile setup

    nolikesyet: "Wala kang anumang Mga Gusto pa",
    nomatches: "Wala kang anumang mga tugma pa",
    likematches: "Maaari kang magsimula ng pagtugma sa profile sa pamamagitan ng pag-gusto sa kanila",
    terms:"Mga Tuntunin",
    Privacy:"Privacy",
    imprint:"Imprint",
    Address: "Address"
  },

  // Tagalog


  // Koreanisch

  ko: {
    Register:"등록하다 (Deungnokhada)",
    secondtext: "globalchat.us는 온라인 데이트의 새로운 수준을 제공합니다. Global Chat의 궁극적인 AI 개성의 창조는 사용자가 94%의 완벽한 매치와 짝을 이룰 수 있도록 도와줍니다.",
    SomeRandomHeadingGoesHere:"오늘 무료로 가입하세요",
    noflirtsrecharge: "크레딧이 충분하지 않습니다",
    heygetflirts: "플립 얻기",
    SEARCHINGFORNOTIFICAITON:"알림 검색 중",
    Newconversation:"새 대화",
    MyPurchases: "내 구매",
    mypurchasedes:
      "여기에서 구매한 플립 및 패키지를 찾을 수 있습니다.",
    mypurchacedess: "아직 거래가 없습니다",
    resetlink:
      "귀하의 이메일 주소를 입력하시면 재설정 링크를 보내드리겠습니다",
    // transaction

    ChatsConversation: "채팅 (대화)",
    message: "메시지",
    likes: "좋아요",
    matches: "매치",
    Liked: "좋아함",
    Disliked: "싫어함",
    JustVisitedYou: "방문한 사용자",
    LoadMore: "더 불러오기",
    FliterUsers: "사용자 필터링",
    Youdonthaveanynotifications: "알림이 없습니다",
    ActiveNow: "현재 활동 중",

    // landing
    SignIn: "로그인",
    FlirtToolNews: "플립 도구 뉴스",
    Join: "가입하기",
    Free: "무료",
    Today: "오늘",
    BasicPackage: "기본 패키지",
    twohoursfreechatexperience: "2 시간 무료 채팅 체험",
    Tryitforfree: "무료로 시도해 보세요",
    FlirtToolfor1Day: "1 일 동안의 플립 도구",
    Gblobalfor1Day:"하루 동안의 플립 도구",

    RealChatFlatrate: "실제 채팅 요금 고정 가격 $2",
    Grabit: "가져 가기",
    Createanaccount: "계정 만들기",
    orgettheapp: "또는 앱을 다운로드하세요",
    OVER1MILLIONUSERSWORLDWIDE: "전 세계 100만 명 이상의 사용자",
    MatchingProfiles: "매칭 프로필",
    UpcomingonFlirtTool: "플립 도구 예정 사항",
    FindRealLifeWithFlirtTool: "플립 도구로 진짜 삶 찾기!",
    HowFlirtToolWorks: "플립 도구가 작동하는 방법",
    HowFlirtToolWorksdes:
      "플립 도구는 온라인 데이트의 새로운 수준을 제공합니다. 플립 도구의 궁극적인 창조물 인공 지능 인 MAZU는 사용자가 94% 완벽한 매치인 누군가와 짝을 짓도록 도와줍니다",
    LEARNMORE: "더 알아보기",
    About: "소개",
    PressAndMedia: "언론 및 미디어",
    Blogs: "블로그",
    LiveChat: "실시간 채팅",
    ChatNow: "지금 채팅",
    SocialMedia: "소셜 미디어",
    ContactUs: "문의하기",
    WelcometoFlirttool: "플립 도구에 오신 것을 환영합니다",
    // end of landing

    // login
    OrCreateanAccount: "또는 계정 만들기",
    UsernameorEmail: "사용자 이름 또는 이메일",
    YourPassword: "비밀번호",
    Forgotpassword: "비밀번호를 잊으셨나요",
    Reset: "재설정",
    IAGREE: "약관, 조건 및 개인 정보 보호 정책에 동의합니다",
    Clickhereto: "우리의 약관을 보려면 여기를 클릭하세요",

    // login

    // register
    FIRSTNAME: "이름",
    LastName: "성",
    Emailisnotvalid: "유효하지 않은 이메일",
    Username: "사용자 이름",
    Yourpasswords: "비밀번호",
    Passwordisnotvalid: "유효하지 않은 비밀번호",
    Confirmpassword: "비밀번호 확인",
    Usernamemusthaveatleast: "사용자 이름은 적어도 가져야 함",
    Alreadyhaveanaccount: "이미 계정이 있으십니까",
    LoginHere: "여기서 로그인",
    // register
    starthourlypackage: "시간당 패키지 시작",
    // Profile
    SubmitReport: "보고서 제출",
    Blockcon: "차단하시겠습니까",
    Yes: "예",
    No: "아니오",
    reportreason: "이 보고서의 이유를 입력하세요",
    Visits: "방문",
    Photos: "사진",
    UnlockExclusive: "독점 사진 잠금 해제",
    UnlockNow: "지금 잠금 해제",
    Personal: "개인",
    Ethnicity: "민족",
    BodyType: "체형",
    Gender: "성별",
    Height: "키",
    Relationship: "관계",
    Work: "작업",
    Education: "교육",
    LIFESTYLE: "라이프 스타일",
    LivesWith: "함께 사는 사람",
    Car: "차",
    Religion: "종교",
    Smoke: "흡연",
    Drink: "음주",
    Travel: "여행",
    // profile

    // settings
    PhoneNumber: "전화 번호",
    GeneralSettings: "일반 설정",
    SaveChanges: "변경 사항 저장",
    RemoveAccount: "계정 제거",
    Yourprofile: "당신의 프로필",
    AboutMe: "나에 대해서",
    Relationshipstatus: "관계 상태",
    YourCountry: "당신의 나라",
    ChooseYourCountry: "당신의 나라를 선택하세요",
    Looks: "외모",
    Personality: "성격",
    Character: "캐릭터",
    Children: "어린이",
    Friends: "친구",
    Pets: "애완 동물",
    LivingWith: "함께 살기",
    MusicGenre: "음악 장르",
    Dish: "요리",
    Song: "노래",
    Hobby: "취미",
    Book: "도서",
    Movie: "영화",
    ChangePassword: "비밀번호 변경",
    NewPassword: "새 비밀번호",

    // settings

    // profile setup
    SETUPYOURDATINGPROFILE: "데이트 프로필 설정",
    uploadphoto: "프로필 사진을 설정하려면 사진을 업로드하세요",
    picnotupdated: "프로필 사진이 업데이트되지 않았습니다",
    pleaseupdatepicture: "계속하려면 프로필 사진을 업로드해야합니다",
    BirthYear: "태어난 해",
    Date: "날짜",
    Month: "월",
    Day: "일",
    SexualPreference: "성적 선호도",
    SexualPreferences: "당신의 성적 취향은 무엇입니까",
    countrycode: "국가 코드",
    PhonNumber: "전화 번호",
    // profile setup

    nolikesyet: "아직 좋아하는 것이 없습니다",
    nomatches: "아직 일치하는 것이 없습니다",
    likematches: "프로필을 좋아함으로써 일치시키기를 시작할 수 있습니다",
    terms:"약관",
    Privacy:"개인정보 정책",
    imprint:"인쇄",
    Address: "주소"
  },


  // Koreanisch


  // Iranisches Persisch
  fa: {
    Register:"",
    secondtext: "globalchat.us سطوح جدیدی از دوست یابی آنلاین را برای شما فراهم می کند. ایجاد نهایی شخصیت هوش مصنوعی Global Chat به کاربران کمک خواهد کرد تا با کسی که 94٪ مطابقت کامل خود را دارد هماهنگ شوند.",
    SomeRandomHeadingGoesHere:"امروز به صورت رایگان عضو شوید" ,
    noflirtsrecharge: "اعتبار شما کافی نیست",
    heygetflirts: "فلرت بگیرید",
    SEARCHINGFORNOTIFICAITON:"در جستجوی اعلان",
    Newconversation:"گفتگوی جدید",
    MyPurchases: "خریدهای من",
    mypurchasedes:
      "در اینجا فلرت‌ها و بسته‌هایی که خریده‌اید را پیدا خواهید کرد.",
    mypurchacedess: "هنوز هیچ تراکنشی ندارید",
    resetlink:
      "آدرس ایمیل خود را وارد کنید و ما لینک بازنشانی شما را ارسال خواهیم کرد",
    // transaction

    ChatsConversation: "گفتگوها (مکالمه)",
    message: "پیام",
    likes: "پسندیده‌ها",
    matches: "تطبیق‌ها",
    Liked: "پسندیده",
    Disliked: "نپسندیده",
    JustVisitedYou: "تازه به شما سر زده است",
    LoadMore: "بارگذاری بیشتر",
    FliterUsers: "فیلتر کاربران",
    Youdonthaveanynotifications: "شما هیچ اعلانی ندارید",
    ActiveNow: "در حال حاضر فعال است",

    // landing
    SignIn: "ورود",
    FlirtToolNews: "اخبار ابزار فلرت",
    Join: "پیوستن",
    Free: "رایگان",
    Today: "امروز",
    BasicPackage: "بسته پایه",
    twohoursfreechatexperience: "تجربه چت رایگان ۲ ساعته",
    Tryitforfree: "برای آزمایش رایگان کنید",
    FlirtToolfor1Day: "ابزار فلرت برای ۱ روز",
    Gblobalfor1Day:"ابزار فلیپ برای یک روز",

    RealChatFlatrate: "نرخ ثابت چت واقعی فقط $2",
    Grabit: "دریافت کنید",
    Createanaccount: "ایجاد حساب کاربری",
    orgettheapp: "یا برنامه را دریافت کنید",
    OVER1MILLIONUSERSWORLDWIDE: "بیش از ۱ میلیون کاربر در سراسر جهان",
    MatchingProfiles: "پروفایل‌های تطبیقی",
    UpcomingonFlirtTool: "آینده در ابزار فلرت",
    FindRealLifeWithFlirtTool: "زندگی واقعی را با ابزار فلرت پیدا کنید!",
    HowFlirtToolWorks: "چگونه ابزار فلرت کار می‌کند",
    HowFlirtToolWorksdes:
      "ابزار فلرت سطوح جدیدی از دوستیابی آنلاین را ارائه می‌دهد. ایجاد بی نظیر فلرت با شخصیت هوش مصنوعی MAZU کمک می‌کند تا کاربران با کسی که ۹۴٪ بهترین تطبیق آنها است، ترکیب شوند",
    LEARNMORE: "بیشتر بدانید",
    About: "درباره",
    PressAndMedia: "مطبوعات و رسانه ها",
    Blogs: "وبلاگ‌ها",
    LiveChat: "گفتگوی زنده",
    ChatNow: "اکنون چت کنید",
    SocialMedia: "رسانه های اجتماعی",
    ContactUs: "تماس با ما",
    WelcometoFlirttool: "خوش آمدید به ابزار فلرت",
    // end of landing

    // login
    OrCreateanAccount: "یا ایجاد حساب کاربری",
    UsernameorEmail: "نام کاربری یا ایمیل",
    YourPassword: "رمز عبور شما",
    Forgotpassword: "رمز عبور را فراموش کرده‌اید",
    Reset: "بازنشانی",
    IAGREE: "من با شرایط، مقررات و سیاست حفظ حریم خصوصی موافقم",
    Clickhereto: "اینجا را کلیک کنید تا شرایط ما را ببینید",

    // login

    // register
    FIRSTNAME: "نام",
    LastName: "نام خانوادگی",
    Emailisnotvalid: "ایمیل معتبر نیست",
    Username: "نام کاربری",
    Yourpasswords: "رمز عبور شما",
    Passwordisnotvalid: "رمز عبور معتبر نیست",
    Confirmpassword: "تأیید رمز عبور",
    Usernamemusthaveatleast: "نام کاربری باید حداقل داشته باشد",
    Alreadyhaveanaccount: "قبلاً حساب کاربری دارید",
    LoginHere: "اینجا وارد شوید",
    // register
    starthourlypackage: "بسته ساعتی را شروع کنید",
    // Profile
    SubmitReport: "ارسال گزارش",
    Blockcon: "آیا مطمئن هستید که می‌خواهید مسدود کنید",
    Yes: "بله",
    No: "خیر",
    reportreason: "دلیل گزارش را وارد کنید",
    Visits: "بازدیدها",
    Photos: "تصاویر",
    UnlockExclusive: "باز کردن تصاویر اختصاصی",
    UnlockNow: "اکنون باز کنید",
    Personal: "شخصی",
    Ethnicity: "نژاد",
    BodyType: "نوع بدن",
    Gender: "جنسیت",
    Height: "قد",
    Relationship: "رابطه",
    Work: "کار",
    Education: "تحصیلات",
    LIFESTYLE: "سبک زندگی",
    LivesWith: "زندگی با",
    Car: "ماشین",
    Religion: "دین",
    Smoke: "سیگار کشیدن",
    Drink: "مشروب",
    Travel: "سفر",
    // profile

    // settings
    PhoneNumber: "شماره تلفن",
    GeneralSettings: "تنظیمات عمومی",
    SaveChanges: "ذخیره تغییرات",
    RemoveAccount: "حذف حساب کاربری",
    Yourprofile: "پروفایل شما",
    AboutMe: "درباره من",
    Relationshipstatus: "وضعیت رابطه",
    YourCountry: "کشور شما",
    ChooseYourCountry: "کشور خود را انتخاب کنید",
    Looks: "ظاهر",
    Personality: "شخصیت",
    Character: "شخصیت",
    Children: "فرزندان",
    Friends: "دوستان",
    Pets: "حیوانات خانگی",
    LivingWith: "زندگی با",
    MusicGenre: "سبک موسیقی",
    Dish: "غذا",
    Song: "آهنگ",
    Hobby: "سرگرمی",
    Book: "کتاب",
    Movie: "فیلم",
    ChangePassword: "تغییر رمز عبور",
    NewPassword: "رمز عبور جدید",

    // settings

    // profile setup
    SETUPYOURDATINGPROFILE: "راه اندازی پروفایل دوستیابی خود",
    uploadphoto: "برای تنظیم عکس پروفایل خود عکس را آپلود کنید",
    picnotupdated: "تصویر پروفایل به روز نشده است",
    pleaseupdatepicture: "برای ادامه، باید تصویر پروفایل خود را آپلود کنید",
    BirthYear: "سال تولد",
    Date: "تاریخ",
    Month: "ماه",
    Day: "روز",
    SexualPreference: "ترجیح جنسی",
    SexualPreferences: "ترجیحات جنسی شما چیست",
    countrycode: "کد کشور",
    PhonNumber: "شماره تلفن",
    // profile setup

    nolikesyet: "هنوز پسندیده‌هایی ندارید",
    nomatches: "هنوز هیچ تطبیقی ندارید",
    likematches: "می‌توانید با پسندیدن پروفایل‌ها با آنها تطبیق بگیرید",
    terms:"شرایط و ضوابط",
    Privacy:"حریم خصوصی",
    imprint:"مهر",
    Address: "آدرس"
  },

  // Iranisches Persisch
// Hausa
ha: {
  secondtext: "globalchat.us ya ba ka matsala mafi yawa na tattaunawa kan yanar gizo ta intanet. Ayyuka na farko na tsarin AI na Global Chat zai taimaka wa masu amfani su dace da wani wanda ya fi 94% da ita a kanta cikin abin da suka fi danganci.",
  SomeRandomHeadingGoesHere:"Zama shiga baya na yau",
  noflirtsrecharge: "Ba Ka Da Kudin Kudin Ba",
  heygetflirts: "Samu Flirts",
  SEARCHINGFORNOTIFICAITON:"A Bincike Don Bayyana",
  Newconversation:"Lambar Fassar",
  MyPurchases: "Na Kara",
  mypurchasedes:
    "A nan za ku samu flirts da kudin da kake samu.",
  mypurchacedess: "Ba ka samu misalin rashin samun ba",
  resetlink:
    "Shigar Littafi na Imel daga dukkaninmUba",
  // transaction

  ChatsConversation: "Maganganu (Fassara)",
  message: "Wasa",
  likes: "Zauna",
  matches: "Kauri",
  Liked: "Zaune",
  Disliked: "Ba Zaune",
  JustVisitedYou: "Da Ta Yi Ka Faduwa",
  LoadMore: "Zaɓi Dubu",
  FliterUsers: "Yiwa Rukunin Sadarwa",
  Youdonthaveanynotifications: "Ba Ka Da Bayyana Ba",
  ActiveNow: "Na Farko Yanzu",

  // landing
  SignIn: "Shiga",
  FlirtToolNews: "Labaran FlirtTool",
  Join: "Shiga",
  Free: "Ransa",
  Today: "Yau",
  BasicPackage: "Kayayyaki Na Asali",
  twohoursfreechatexperience: "2 Hours Free Chat Experience",
  Tryitforfree: "Don Gano",
  FlirtToolfor1Day: "FlirtTool Don 1 Day",
  Gblobalfor1Day:"GlobalChat Don 1 Day",

  RealChatFlatrate: "Real Chat Flatrate Naira 2 Kawai",
  Grabit: "Ciro",
  Createanaccount: "Shiga",
  orgettheapp: "Kada Ku Yi",
  OVER1MILLIONUSERSWORLDWIDE: "Over 1 Million Users worldwide",
  MatchingProfiles: "Rukunin Sadarwa",
  UpcomingonFlirtTool: "Tsakanin FlirtTool",
  FindRealLifeWithFlirtTool: "Duba Rayuwa Da FlirtTool!",
  HowFlirtToolWorks: "Yadda FlirtTool Ta Aiki",
  HowFlirtToolWorksdes:
    "FlirtTool ta bayyana sabbin kwarewarsa na tarihin sana'a ta cibiyar duniya. Al'ada na AI na FlirtTool na MAZU za ta taimaka wa masu amfani da su amfani da wani mai fita 94% masu daidaitaccen sadarwa",
  LEARNMORE: "Sai Wa",
  About: "Game da",
  PressAndMedia: "Press Da Media",
  Blogs: "Blogs",
  LiveChat: "Live Chat",
  ChatNow: "Shirya Yanzu",
  SocialMedia: "Social Media",
  ContactUs: "Muku",
  WelcometoFlirttool: "Barka Da Zuwa Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "Kuma Yi Hada",
  UsernameorEmail: "Sunan Ma'aikaci ko Imel",
  YourPassword: "Kalmar Ka",
  Forgotpassword: "Ka Sani Kalmar Ka",
  Reset: "Sake",
  IAGREE: "Na tabbata da Hukumomin, Shari'arwa Da Sauran Batutuwa",
  Clickhereto: "Danna nan Don Gane Shari'arwa Muke",

  // login

  // register
  FIRSTNAME: "Sunan",
  LastName: "Sunan Mu",
  Emailisnotvalid: "Imel Baza'a Amfani Ba",
  Username: "Sunan Ma'aikaci",
  Yourpasswords: "Kalmar Ka",
  Passwordisnotvalid: "Kalmar ka Ba ta Amfani Ba",
  Confirmpassword: "Sake Kiyaye Kalmar Ka",
  Usernamemusthaveatleast: "Sunan Ma'aikaci yana bukatar",
  Alreadyhaveanaccount: "An kai muku kira",
  LoginHere: "Shiga nan",
  // register
  starthourlypackage: "Fara Package Na Hours",
  // Profile
  SubmitReport: "Sanya Rubutu",
  Blockcon: "Me zai sa ka so ka kada",
  Yes: "Eh",
  No: "A'a",
  reportreason: "Yi bayyanar kasa",
  Visits: "Ziyarar",
  Photos: "Hoto",
  UnlockExclusive: "Kwashe Kayayyaki",
  UnlockNow: "Kai Tsallake",
  Personal: "Na Duniya",
  Ethnicity: "Halitta",
  BodyType: "BodyType",
  Gender: "Jinsi",
  Height: "Jerin",
  Relationship: "Aminci",
  Work: "Aiki",
  Education: "Ilimi",
  LIFESTYLE: "Layin Rayuwa",
  LivesWith: "Mai Tafi",
  Car: "Motocin",
  Religion: "Al'ada",
  Smoke: "Smoke",
  Drink: "Aiki",
  Travel: "Sanya",
  // profile

  // settings
  PhoneNumber: "Nambu Na Wayar",
  GeneralSettings: "Hada-hada Na General",
  SaveChanges: "Kayyade",
  RemoveAccount: "Cire",
  Yourprofile: "Jerin Ka",
  AboutMe: "Game Da Ni",
  Relationshipstatus: "Aminci",
  YourCountry: "Matakin Ka",
  ChooseYourCountry: "Za Ka Zabi Matakin Ka",
  Looks: "Don Gani",
  Personality: "Jinkirar",
  Character: "Fuska",
  Children: "Yara",
  Friends: "Yaran",
  Pets: "Anmali",
  LivingWith: "Mai Tafi",
  MusicGenre: "MusicGenre",
  Dish: "Dish",
  Song: "Waka",
  Hobby: "Hobby",
  Book: "Littafi",
  Movie: "Fina-finai",
  ChangePassword: "Sanya Kalmar Ka",
  NewPassword: "Kalmar Sabo",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "Set Up Profil Na Mai Kayan Zamani",
  uploadphoto: "Sanya Hoto Don Kashe Kayan Zamani",
  picnotupdated: "Kayayyaki ba ta sabunta ba",
  pleaseupdatepicture: "Zaku iya sake sanya hoton ma'aikacin ka don ci gaba",
  BirthYear: "Shekarun Rigakafin",
  Date: "Date",
  Month: "Wata",
  Day: "Kwanan",
  SexualPreference: "Zaune Na Jima'i",
  SexualPreferences: "Abin Zauna Na Jima'i",
  countrycode: "Shirye-shiryen Wakilai",
  PhonNumber: "Nambu Na Wayar",
  // profile setup

  nolikesyet: "Ba Ka Da Zaune Ba Ba",
  nomatches: "Ba Ka Da Kaurin Kaurin Ba",
  likematches: "Zaku iya fara kaurin sadarwa da profiles da kuka baiwa zaune",
  terms:"Hukumomin",
  Privacy:"Maganganun Sirri",
  imprint:"Majalisar Gida",
  Address: "Adireshin"
},

// Hausa



// Ägyptisch-Arabisch

arz: {
  Register:"تسجيل",
  secondtext: "globalchat.us بيقدملك مستويات جديدة فى التعارف اونلاين. الشخصية الإصطناعية الأساسية فى جلوبال شات هتساعد المستخدمين يتماشوا مع حد يكون ٩٤٪ مطابق لهم.",
  SomeRandomHeadingGoesHere:"انضم مجانًا اليوم",
  noflirtsrecharge: "مفيش كريديت كافي",
  heygetflirts: "احصل على فليرتس",
  SEARCHINGFORNOTIFICAITON:"بندور على إشعار",
  Newconversation:"محادثة جديدة",
  MyPurchases: "مشترياتي",
  mypurchasedes:
    "هنا هتلاقي الفليرتس و الباقات اللي انت اشتريتها.",
  mypurchacedess: "مفيش مشتريات حتى الآن",
  resetlink:
    "ادخل عنوان بريدك الإلكتروني وهنبعتلك اللينك عشان تعمل ريسيت للحساب",
  // transaction

  ChatsConversation: "محادثات (محادثة)",
  message: "رسالة",
  likes: "إعجابات",
  matches: "متطابقين",
  Liked: "عجبك",
  Disliked: "معجبتكش",
  JustVisitedYou: "زارك لسة",
  LoadMore: "تحميل المزيد",
  FliterUsers: "فلترة المستخدمين",
  Youdonthaveanynotifications: "مفيش إشعارات",
  ActiveNow: "متواجد الآن",

  // landing
  SignIn: "سجل الدخول",
  FlirtToolNews: "أخبار أداة الفليرت",
  Join: "انضم",
  Free: "مجانًا",
  Today: "اليوم",
  BasicPackage: "الباقة الأساسية",
  twohoursfreechatexperience: "تجربة الدردشة المجانية لمدة ساعتين",
  Tryitforfree: "جربها مجانًا",
  FlirtToolfor1Day: "أداة الفليرت ليوم واحد",
  Gblobalfor1Day:"أداة التحويل لمدة يوم واحد",

  RealChatFlatrate: "نسبة الدردشة الحقيقية فقط $ 2",
  Grabit: "احصل عليه",
  Createanaccount: "انشئ حسابًا",
  orgettheapp: "أو احصل على التطبيق",
  OVER1MILLIONUSERSWORLDWIDE: "أكثر من مليون مستخدم في جميع أنحاء العالم",
  MatchingProfiles: "ملفات تطابق",
  UpcomingonFlirtTool: "القادم على أداة الفليرت",
  FindRealLifeWithFlirtTool: "اعثر على الحياة الحقيقية مع أداة الفليرت!",
  HowFlirtToolWorks: "كيفية عمل أداة الفليرت",
  HowFlirtToolWorksdes:
    "أداة الفليرت تقدم لك مستويات جديدة من التعارف عبر الإنترنت. إن إنشاء الشخصية الاصطناعية MAZU الخاصة بأداة الفليرت سيساعد المستخدمين على الإقتران بشخص يكون 94٪ توافقًا تامًا معهم",
  LEARNMORE: "تعرف أكثر",
  About: "حول",
  PressAndMedia: "الصحافة ووسائل الإعلام",
  Blogs: "المدونات",
  LiveChat: "دردشة مباشرة",
  ChatNow: "دردش الآن",
  SocialMedia: "وسائل التواصل الاجتماعي",
  ContactUs: "اتصل بنا",
  WelcometoFlirttool: "مرحبًا بك في أداة الفليرت",
  // end of landing

  // login
  OrCreateanAccount: "أو أنشئ حسابًا",
  UsernameorEmail: "اسم المستخدم أو البريد الإلكتروني",
  YourPassword: "كلمة المرور الخاصة بك",
  Forgotpassword: "هل نسيت كلمة المرور؟",
  Reset: "إعادة تعيين",
  IAGREE: "أوافق على الشروط والأحكام وسياسة الخصوصية",
  Clickhereto: "انقر هنا لعرض شروطنا",

  // login

  // register
  FIRSTNAME: "الاسم الأول",
  LastName: "الاسم الأخير",
  Emailisnotvalid: "البريد الإلكتروني غير صالح",
  Username: "اسم المستخدم",
  Yourpasswords: "كلمة المرور الخاصة بك",
  Passwordisnotvalid: "كلمة المرور غير صالحة",
  Confirmpassword: "تأكيد كلمة المرور",
  Usernamemusthaveatleast: "يجب أن يحتوي اسم المستخدم على الأقل",
  Alreadyhaveanaccount: "هل لديك حساب بالفعل؟",
  LoginHere: "تسجيل الدخول هنا",
  // register
  starthourlypackage: "ابدأ باقة ساعية",
  // Profile
  SubmitReport: "إرسال تقرير",
  Blockcon: "هل أنت متأكد أنك تريد حظر",
  Yes: "نعم",
  No: "لا",
  reportreason: "أدخل سبب التقرير هنا",
  Visits: "الزيارات",
  Photos: "الصور",
  UnlockExclusive: "فتح الصور الحصرية",
  UnlockNow: "فتح الآن",
  Personal: "الشخصي",
  Ethnicity: "الأصل",
  BodyType: "نوع الجسم",
  Gender: "الجنس",
  Height: "الطول",
  Relationship: "العلاقة",
  Work: "العمل",
  Education: "التعليم",
  LIFESTYLE: "نمط الحياة",
  LivesWith: "يعيش مع",
  Car: "السيارة",
  Religion: "الديانة",
  Smoke: "التدخين",
  Drink: "الشرب",
  Travel: "السفر",
  // profile

  // settings
  PhoneNumber: "رقم الهاتف",
  GeneralSettings: "الإعدادات العامة",
  SaveChanges: "حفظ التغييرات",
  RemoveAccount: "إزالة الحساب",
  Yourprofile: "ملفك الشخصي",
  AboutMe: "عني",
  Relationshipstatus: "حالة العلاقة",
  YourCountry: "بلدك",
  ChooseYourCountry: "اختر بلدك",
  Looks: "المظهر",
  Personality: "الشخصية",
  Character: "الطابع",
  Children: "الأطفال",
  Friends: "الأصدقاء",
  Pets: "الحيوانات الأليفة",
  LivingWith: "يعيش مع",
  MusicGenre: "نوع الموسيقى",
  Dish: "الطبق",
  Song: "الأغنية",
  Hobby: "الهواية",
  Book: "الكتاب",
  Movie: "الفيلم",
  ChangePassword: "تغيير كلمة المرور",
  NewPassword: "كلمة مرور جديدة",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "إعداد ملف التعارف الخاص بك",
  uploadphoto: "قم بتحميل الصورة لتعيين صورة الملف الشخصي الخاصة بك",
  picnotupdated: "لم يتم تحديث الصورة الشخصية",
  pleaseupdatepicture: "يجب عليك تحميل صورة الملف الشخصي للمتابعة",
  BirthYear: "سنة الميلاد",
  Date: "تاريخ",
  Month: "شهر",
  Day: "يوم",
  SexualPreference: "التفضيل الجنسي",
  SexualPreferences: "ما هو تفضيلك الجنسي",
  countrycode: "كود الدولة",
  PhonNumber: "رقم الهاتف",
  // profile setup

  nolikesyet: "مفيش لايكات بعد",
  nomatches: "مفيش تطابقات بعد",
  likematches: "يمكنك البدء في التطابق مع الملفات الشخصية عن طريق الإعجاب بها",
  terms:"الشروط",
  Privacy:"الخصوصية",
  imprint:"النقش",
  Address: "العنوان"
},

// Ägyptisch-Arabisch
// Swahili
Swahili:{
  secondtext: "globalchat.us inakupa viwango vipya vya uchumbiano mtandaoni. Uumbaji wa mwisho wa utu wa AI wa Global Chat utasaidia watumiaji kupatana na mtu ambaye ni 94% mkamilifu kwao.",
  SomeRandomHeadingGoesHere: "Jiunge Bure Leo",
  noflirtsrecharge: "Huna mikopo ya kutosha",
  heygetflirts: "Pata mizungumzo",
  SEARCHINGFORNOTIFICAITON: "KUTAFUTA KWA TAARIFA",
  Newconversation: "Mazungumzo Mapya",
  MyPurchases: "Manunuzi Yangu",
  mypurchasedes: "Hapa utapata mizungumzo na vifurushi ulivyonunua.",
  mypurchacedess: "Bado huna shughuli yoyote",
  resetlink: "Ingiza anwani yako ya barua pepe na tutakutumia kiungo chako cha kurejesha",
  ChatsConversation: "MAZUNGUMZO (MAWASILIANO)",
  message: "Ujumbe",
  likes: "vipendwa",
  matches: "Sawa",
  Liked: "Kupendwa",
  Disliked: "Kutopendwa",
  JustVisitedYou: "Ame kutembelea",
  LoadMore: "Pakia Zaidi",
  FliterUsers: "Chuja Watumiaji",
  Youdonthaveanynotifications: "Huna taarifa yoyote",
  ActiveNow: "Anafanya Kazi Sasa Hivi",
  SignIn: "Ingia",
  FlirtToolNews: "Habari za FlirtTool",
  Join: "Jiunge",
  Free: "Bure",
  Today: "Leo",
  BasicPackage: "Paketi ya Msingi",
  twohoursfreechatexperience: "Uzoefu wa Mazungumzo ya Bure ya Masaa 2",
  Tryitforfree: "Jaribu Bure",
  FlirtToolfor1Day: "FlirtTool kwa Siku 1",
  Gblobalfor1Day:"GlobalChat kwa Siku 1",

  RealChatFlatrate: "Mazungumzo Halisi kwa Dola 2 TU",
  Grabit: "Kamata",
  Createanaccount: "Unda akaunti",
  orgettheapp: "au pata programu",
  OVER1MILLIONUSERSWORLDWIDE: "Zaidi ya Watumiaji Milioni 1 Kote Ulimwenguni",
  MatchingProfiles: "Profaili Zinazolingana",
  UpcomingonFlirtTool: "Inayokuja kwenye FlirtTool",
  FindRealLifeWithFlirtTool: "Pata Maisha Halisi na FlirtTool!",
  HowFlirtToolWorks: "Jinsi FlirtTool Inavyofanya Kazi",
  HowFlirtToolWorksdes: " FlirtTool inakupa viwango vipya vya uhusiano wa mtandaoni. Uumbaji wa mwisho wa FlirtTool wa kibinafsi MAZU utasaidia watumiaji kupatanishwa na mtu ambaye ni asilimia 94% mpenzi wao kamili",
  LEARNMORE: "JIFUNZE ZAIDI",
  About: "Kuhusu",
  PressAndMedia: "Mkusanyiko na Vyombo vya Habari",
  Blogs: "Blogu",
  LiveChat: "Mazungumzo ya Moja kwa Moja",
  ChatNow: "Mazungumzo Sasa",
  SocialMedia: "Mitandao ya Kijamii",
  ContactUs: "Wasiliana Nasi",
  WelcometoFlirttool: "Karibu kwenye Flirttool",
  OrCreateanAccount: "Au Unda Akaunti",
  UsernameorEmail: "Jina la Mtumiaji au Barua pepe",
  YourPassword: "Nywila Yako",
  Forgotpassword: "Umesahau nenosiri",
  Reset: "Rudisha",
  IAGREE: "NAKUBALIANA NA MASHARTI, HALI NA SHERIA YA FARAGHA",
  Clickhereto: "BONYEZA HAPA KUONA MASHARTI YETU",
  FIRSTNAME: "JINA LA KWANZA",
  LastName: "Jina la Mwisho",
  Emailisnotvalid: "Barua pepe haikubaliki",
  Username: "Jina la Mtumiaji",
  Yourpasswords: "Nywila yako",
  Passwordisnotvalid: "Nenosiri sio sahihi",
  Confirmpassword: "Thibitisha nywila",
  Usernamemusthaveatleast: "Jina la mtumiaji lazima liwe na angalau",
  Alreadyhaveanaccount: "Unayo akaunti tayari",
  LoginHere: "Ingia Hapa",
  starthourlypackage: "Anza Paketi ya Kila Saa",
  SubmitReport: "Wasilisha Ripoti",
  Blockcon: "Je! Una uhakika unataka kuzuia",
  Yes: "Ndiyo",
  No: "Hapana",
  reportreason: "Ingiza sababu yako kwa ripoti hii",
  Visits: "Tembelezi",
  Photos: "Picha",
  UnlockExclusive: "Fungua Picha za Kipekee",
  UnlockNow: "Fungua Sasa",
  Personal: "Binafsi",
  Ethnicity: "Kabila",
  BodyType: "Aina ya Mwili",
  Gender: "Jinsia",
  Height: "Urefu",
  Relationship: "Mahusiano",
  Work: "Kazi",
  Education: "Elimu",
  LIFESTYLE: "MTINDO WA MAISHA",
  LivesWith: "Anaishi Na",
  Car: "Gari",
  Religion: "Dini",
  Smoke: "Kuvuta",
  Drink: "Kunywa",
  Travel: "Safari",
  PhoneNumber: "Nambari ya Simu",
  GeneralSettings: "Mipangilio Muhimu",
  SaveChanges: "Hifadhi Mabadiliko",
  RemoveAccount: "Ondoa Akaunti",
  Yourprofile: "Wasifu Wako",
  AboutMe: "Kuhusu Mimi",
  Relationshipstatus: "Hali ya Mahusiano",
  YourCountry: "Nchi Yako",
  ChooseYourCountry: "Chagua Nchi Yako",
  Looks: "Muonekano",
  Personality: "Shakhsia",
  Character: "Tabia",
  Children: "Watoto",
  Friends: "Marafiki",
  Pets: "Wanyama wa Kipenzi",
  LivingWith: "Anaishi na",
  MusicGenre: "Aina ya Muziki",
  Dish: "Chakula",
  Song: "Wimbo",
  Hobby: "Kipendwa",
  Book: "Kitabu",
  Movie: "Filamu",
  ChangePassword: "Badilisha Nenosiri",
  NewPassword: "Nenosiri Mpya",
  SETUPYOURDATINGPROFILE: "WEKA PROFIL YA KUTAFUTA",
  uploadphoto: "Pakia picha kuweka Picha Yako ya Wasifu",
  picnotupdated: "Picha ya wasifu haijasasishwa",
  pleaseupdatepicture: "Unapaswa kupakia picha ya wasifu kuendelea",
  BirthYear: "Mwaka wa Kuzaliwa",
  Date: "Tarehe",
  Month: "Mwezi",
  Day: "Siku",
  SexualPreference: "Mapendeleo ya Kijinsia",
  SexualPreferences: "Je! Una mapendeleo gani ya kijinsia",
  countrycode: "Msimbo wa nchi",
  PhonNumber: "Namba ya Simu",
  nolikesyet: "Bado huna vipendwa",
  nomatches: "Bado huna mechi",
  likematches: "Unaweza kuanza kufanana na wasifu kwa kuwapenda",
  terms: "Masharti",
  Privacy: "Faragha",
  imprint: "Alama",
  Address: "Anwani"
},


// Swahili

// Javanisch
Javanisch:{
  secondtext: "globalchat.us ngundang sampeyan marang tingkat anyar nangingan panggolèkan online. Kreasi pungkasan saka kepriben AI Global Chat bakal nulungi panganggo dadi dipasangkan karo wong sing yaiku 94% pasangan apiké.",
  SomeRandomHeadingGoesHere: "Mgabung Gratis Sak Wonten Iki",
  noflirtsrecharge: "Sampeyan ora duwe kredhit cukup",
  heygetflirts: "Get flirts",
  SEARCHINGFORNOTIFICAITON: "MANGSULAKE NOTIFICAITON",
  Newconversation: "Conversi Anyar",
  MyPurchases: "Pamundhut Kuwi",
  mypurchasedes: "Ing kéné sampeyan bakal nemokaké flirts lan paket sing sampeyan tuku.",
  mypurchacedess: "Sampeyan durung ana transaksi apa-apa",
  resetlink: "Lebokaké alamat email sampeyan lan kita bakal ngirimake tautan reset sampeyan",
  ChatsConversation: "CATHETAN (KONVERSAI)",
  message: "Pesen",
  likes: "Kepethak",
  matches: "Cocokan",
  Liked: "Kepethak",
  Disliked: "Disliked",
  JustVisitedYou: "Neng kene Mburi-mburi",
  LoadMore: "Gudhuk Luwih",
  FliterUsers: "Fliter Pengguna",
  Youdonthaveanynotifications: "Sampeyan durung duwe pangumuman apa-apa",
  ActiveNow: "Aktif Saiki",
  SignIn: "Gabung",
  FlirtToolNews: "Kabar FlirtTool",
  Join: "Gabung",
  Free: "Gratis",
  Today: "Dinten Iki",
  BasicPackage: "Paket Dasar",
  twohoursfreechatexperience: "Pengalaman Obrolan Gratis Dua Jam",
  Tryitforfree: "Coba gratis",
  FlirtToolfor1Day: "FlirtTool kanggo 1 Dina",
  Gblobalfor1Day:"GlobalChat kanggo 1 Dina",

  RealChatFlatrate: "Obrolan Sesungguhé Flatrate UGA 2",
  Grabit: "Gentèng iki",
  Createanaccount: "Gawe akun",
  orgettheapp: "utawa gawa aplikasi",
  OVER1MILLIONUSERSWORLDWIDE: "Lebih saking 1 Juta Pengguna ing Seru",
  MatchingProfiles: "Profil Cocok",
  UpcomingonFlirtTool: "Wujudake nang FlirtTool",
  FindRealLifeWithFlirtTool: "Déleng Kehidupan Nyata Kanthi FlirtTool!",
  HowFlirtToolWorks: "Carane FlirtTool Bekerja",
  HowFlirtToolWorksdes: " FlirtTool menehi panggolèkan anyar saka hubungan online. Kreasi panganggo FlirtTools panggonan pribadi MAZU bakal nulungi panganggo disandingake karo wong sing saiki 94% cocok kanggo",
  LEARNMORE: "DUWEKÉ PENGALAMEN",
  About: "Mengenai",
  PressAndMedia: "Pers lan Media",
  Blogs: "Blog",
  LiveChat: "Obrolan Langsung",
  ChatNow: "Obrolan Saiki",
  SocialMedia: "Media Sosial",
  ContactUs: "Kanthi kita",
  WelcometoFlirttool: "Sugeng rawuh ing Flirttool",
  OrCreateanAccount: "Utawa Gawe Akun",
  UsernameorEmail: "Jeneng Pengguna utawa Email",
  YourPassword: "Saben Kunci",
  Forgotpassword: "Ketiga Nenowi Kunci",
  Reset: "Reset",
  IAGREE: "AKU SETUJU KANGGO SYARAT, KONDISI, LAN KAWIYATAAN PRIBADI",
  Clickhereto: "KLIK ING KENE KANGGO WICARA SYARAT KITA",
  FIRSTNAME: "JENENG SAKIWIKI",
  LastName: "Jeneng Panggonan",
  Emailisnotvalid: "Email ora sah",
  Username: "Jeneng Pengguna",
  Yourpasswords: "Saben Kunci",
  Passwordisnotvalid: "Kunci ora sah",
  Confirmpassword: "Konfirmasi kunci",
  Usernamemusthaveatleast: "Jeneng pangguna minimal kudu",
  Alreadyhaveanaccount: "Sudah duwe akun",
  LoginHere: "Gabung Kene",
  starthourlypackage: "Mula Paket per Jam",
  SubmitReport: "Kirim Laporan",
  Blockcon: "Apa Sampeyan yakin pengin mblokir",
  Yes: "Iya",
  No: "Ora",
  reportreason: "Lebokake dalanane kanggo laporan iki",
  Visits: "Wisit",
  Photos: "Foto",
  UnlockExclusive: "Unlokal Foto Eksklusif",
  UnlockNow: "Unlokal Saiki",
  Personal: "Pribadi",
  Ethnicity: "Etnis",
  BodyType: "Jinis Badan",
  Gender: "Jenis kelamin",
  Height: "Pengalaman",
  Relationship: "Hubungan",
  Work: "Kerja",
  Education: "Pendidikan",
  LIFESTYLE: "GAYA KEHIDUPAN",
  LivesWith: "Mangan Karo",
  Car: "Mobil",
  Religion: "Agama",
  Smoke: "Ngunjuk",
  Drink: "Ngudhi",
  Travel: "Mlaku",
  PhoneNumber: "Nomor Telepon",
  GeneralSettings: "Pengaturan Umum",
  SaveChanges: "Simpen Owah",
  RemoveAccount: "Mbuang Akun",
  Yourprofile: "Profil Sampeyan",
  AboutMe: "Mengenai Aku",
  Relationshipstatus: "Status Hubungan",
  YourCountry: "Nagara Sampeyan",
  ChooseYourCountry: "Pilih Nagara Sampeyan",
  Looks: "Bentuk",
  Personality: "Gaya",
  Character: "Watak",
  Children: "Anak-anak",
  Friends: "Sahabat",
  Pets: "Hewan Peliharaan",
  LivingWith: "Neng kene Ana",
  MusicGenre: "Jenis Musik",
  Dish: "Manganan",
  Song: "Gendhing",
  Hobby: "Hobi",
  Book: "Buku",
  Movie: "Film",
  ChangePassword: "Ganti Kunci",
  NewPassword: "Kunci Anyar",
  SETUPYOURDATINGPROFILE: "NGATUR PROFIL DATING SAMPEYAN",
  uploadphoto: "Unggah foto kanggo nggawe Gambar Profil Sampeyan",
  picnotupdated: "Gambar Profil ora diperbarui",
  pleaseupdatepicture: "Sampeyan kudu ngunggah gambar profil kanggo terus",
  BirthYear: "Tahun Lahir",
  Date: "Tanggal",
  Month: "Wulan",
  Day: "Dina",
  SexualPreference: "Preferensi Seksual",
  SexualPreferences: "Apa Preferensi Seksual Sampeyan",
  countrycode: "Kode Nagara",
  PhonNumber: "Nomor Telepon",
  nolikesyet: "Sampeyan durung ana Kepethak",
  nomatches: "Sampeyan durung ana cocok",
  likematches: "Sampeyan bisa mulai cocok karo profil nganggo nuduhake kepethak",
  terms: "Syarat",
  Privacy: "Privasi",
  imprint: "Cangkang",
  Address: "Alamat"
},
// Javanisch

// Italienisch
Italienisch:{
  secondtext: "globalchat.us offre nuovi livelli di appuntamenti online. La creazione ultima della personalità IA di Global Chat aiuterà gli utenti ad essere abbinati con qualcuno che è il 94% il loro partner perfetto.",
  SomeRandomHeadingGoesHere: "Iscriviti Gratis Oggi",
  noflirtsrecharge: "Non hai abbastanza crediti",
  heygetflirts: "Ottieni flirt",
  SEARCHINGFORNOTIFICAITON: "RICERCA DI NOTIFICA",
  Newconversation: "Nuova Conversazione",
  MyPurchases: "I Miei Acquisti",
  mypurchasedes: "Qui troverai i flirt e i pacchetti che hai acquistato.",
  mypurchacedess: "Non hai ancora transazioni",
  resetlink: "Inserisci il tuo indirizzo email e ti invieremo il link di reset",
  ChatsConversation: "CONVERSAZIONI (CHAT)",
  message: "Messaggio",
  likes: "Mi piace",
  matches: "Corrispondenze",
  Liked: "Piace",
  Disliked: "Non mi piace",
  JustVisitedYou: "Ti ha appena visitato",
  LoadMore: "Carica Altri",
  FliterUsers: "Filtra Utenti",
  Youdonthaveanynotifications: "Non hai nessuna notifica",
  ActiveNow: "Attivo Ora",
  SignIn: "Accedi",
  FlirtToolNews: "Notizie su FlirtTool",
  Join: "Unisciti",
  Free: "Gratis",
  Today: "Oggi",
  BasicPackage: "Pacchetto Base",
  twohoursfreechatexperience: "Esperienza di Chat Gratuita di Due Ore",
  Tryitforfree: "Provalo Gratis",
  FlirtToolfor1Day: "FlirtTool per 1 Giorno",
  Gblobalfor1Day:"GlobalChat per 1 Giorno",

  RealChatFlatrate: "Chat Reale Tariffa Fissa SOLO $2",
  Grabit: "Prendilo",
  Createanaccount: "Crea un Account",
  orgettheapp: "o scarica l'app",
  OVER1MILLIONUSERSWORLDWIDE: "Oltre 1 Milione di Utenti in tutto il Mondo",
  MatchingProfiles: "Profili Corrispondenti",
  UpcomingonFlirtTool: "Prossimamente su FlirtTool",
  FindRealLifeWithFlirtTool: "Trova la Vita Reale con FlirtTool!",
  HowFlirtToolWorks: "Come Funziona FlirtTool",
  HowFlirtToolWorksdes: "FlirtTool offre nuovi livelli di appuntamenti online. La creazione definitiva di FlirtTools della personalità AI MAZU aiuterà gli utenti a essere accoppiati con qualcuno che è il 94% della loro perfetta corrispondenza.",
  LEARNMORE: "SCOPRI DI PIÙ",
  About: "Informazioni",
  PressAndMedia: "Stampa e Media",
  Blogs: "Blog",
  LiveChat: "Chat Live",
  ChatNow: "Chat Ora",
  SocialMedia: "Social Media",
  ContactUs: "Contattaci",
  WelcometoFlirttool: "Benvenuto su Flirttool",
  OrCreateanAccount: "Oppure Crea un Account",
  UsernameorEmail: "Nome Utente o Email",
  YourPassword: "La Tua Password",
  Forgotpassword: "Password dimenticata",
  Reset: "Resetta",
  IAGREE: "ACCETTO I TERMINI, LE CONDIZIONI E LA POLITICA SULLA PRIVACY",
  Clickhereto: "CLICCA QUI PER VISUALIZZARE I NOSTRI TERMINI",
  FIRSTNAME: "NOME",
  LastName: "Cognome",
  Emailisnotvalid: "L'email non è valida",
  Username: "Nome Utente",
  Yourpasswords: "La tua password",
  Passwordisnotvalid: "La password non è valida",
  Confirmpassword: "Conferma password",
  Usernamemusthaveatleast: "Il nome utente deve avere almeno",
  Alreadyhaveanaccount: "Hai già un account",
  LoginHere: "Accedi Qui",
  starthourlypackage: "Inizia Pacchetto Orario",
  SubmitReport: "Invia Segnalazione",
  Blockcon: "Sei sicuro di voler bloccare",
  Yes: "Sì",
  No: "No",
  reportreason: "Inserisci il motivo per questa segnalazione",
  Visits: "Visite",
  Photos: "Foto",
  UnlockExclusive: "Sblocca Immagini Esclusive",
  UnlockNow: "Sblocca Ora",
  Personal: "Personale",
  Ethnicity: "Etnia",
  BodyType: "Tipo di Corpo",
  Gender: "Genere",
  Height: "Altezza",
  Relationship: "Relazione",
  Work: "Lavoro",
  Education: "Educazione",
  LIFESTYLE: "STILE DI VITA",
  LivesWith: "Vive Con",
  Car: "Auto",
  Religion: "Religione",
  Smoke: "Fuma",
  Drink: "Beve",
  Travel: "Viaggiare",
  PhoneNumber: "Numero di Telefono",
  GeneralSettings: "Impostazioni Generali",
  SaveChanges: "Salva Modifiche",
  RemoveAccount: "Rimuovi Account",
  Yourprofile: "Il Tuo Profilo",
  AboutMe: "Su di Me",
  Relationshipstatus: "Stato della Relazione",
  YourCountry: "Il Tuo Paese",
  ChooseYourCountry: "Scegli Il Tuo Paese",
  Looks: "Aspetto",
  Personality: "Personalità",
  Character: "Carattere",
  Children: "Bambini",
  Friends: "Amici",
  Pets: "Animali Domestici",
  LivingWith: "Vive Con",
  MusicGenre: "Genere Musicale",
  Dish: "Piatto",
  Song: "Canzone",
  Hobby: "Hobby",
  Book: "Libro",
  Movie: "Film",
  ChangePassword: "Cambia Password",
  NewPassword: "Nuova Password",
  SETUPYOURDATINGPROFILE: "CONFIGURA IL TUO PROFILO DI APPUNTAMENTO",
  uploadphoto: "Carica foto per impostare la tua immagine del profilo",
  picnotupdated: "Immagine del profilo non aggiornata",
  pleaseupdatepicture: "Dovresti caricare un'immagine del profilo per continuare",
  BirthYear: "Anno di Nascita",
  Date: "Data",
  Month: "Mese",
  Day: "Giorno",
  SexualPreference: "Preferenza Sessuale",
  SexualPreferences: "Quali sono le tue preferenze sessuali",
  countrycode: "Codice del Paese",
  PhonNumber: "Numero di Telefono",
  nolikesyet: "Non hai ancora Mi Piace",
  nomatches: "Non hai ancora corrispondenze",
  likematches: "Puoi iniziare a corrispondere con i profili piacendoli",
  terms: "Termini",
  Privacy: "Privacy",
  imprint: "Impronta",
  Address: "Indirizzo"
},
// Italienisch
// Westpanjabi
Westpanjabi:{
  secondtext: "globalchat.us تُہانوں آن لائن ڈیٹنگ دی نویں سطح فراہم کردا ہے۔ Global Chat دی آخری AI شخصیت دا بنانا صارفاں نوں کسی نوں مدد کرے گا جو کہ اُنہان دا 94% تے ان کا مکمل موافقت ہو۔",
  SomeRandomHeadingGoesHere: "ج ਮਫ਼ਤ ਸ਼ਾਮਲ ਹੋਵੋ",
  noflirtsrecharge: "Non hai abbastanza crediti",
  heygetflirts: "Ottieni flirt",
  SEARCHINGFORNOTIFICAITON: "RICERCA DI NOTIFICA",
  Newconversation: "Nuova Conversazione",
  MyPurchases: "I Miei Acquisti",
  mypurchasedes: "Qui troverai i flirt e i pacchetti che hai acquistato.",
  mypurchacedess: "Non hai ancora transazioni",
  resetlink: "Inserisci il tuo indirizzo email e ti invieremo il link di reset",
  ChatsConversation: "CONVERSAZIONI (CHAT)",
  message: "Messaggio",
  likes: "Mi piace",
  matches: "Corrispondenze",
  Liked: "Piace",
  Disliked: "Non mi piace",
  JustVisitedYou: "Ti ha appena visitato",
  LoadMore: "Carica Altri",
  FliterUsers: "Filtra Utenti",
  Youdonthaveanynotifications: "Non hai nessuna notifica",
  ActiveNow: "Attivo Ora",
  SignIn: "Accedi",
  FlirtToolNews: "Notizie su FlirtTool",
  Join: "Unisciti",
  Free: "Gratis",
  Today: "Oggi",
  BasicPackage: "Pacchetto Base",
  twohoursfreechatexperience: "Esperienza di Chat Gratuita di Due Ore",
  Tryitforfree: "Provalo Gratis",
  FlirtToolfor1Day: "FlirtTool per 1 Giorno",
  Gblobalfor1Day:"GlobalChat per 1 Giorno",

  RealChatFlatrate: "Chat Reale Tariffa Fissa SOLO $2",
  Grabit: "Prendilo",
  Createanaccount: "Crea un Account",
  orgettheapp: "o scarica l'app",
  OVER1MILLIONUSERSWORLDWIDE: "Oltre 1 Milione di Utenti in tutto il Mondo",
  MatchingProfiles: "Profili Corrispondenti",
  UpcomingonFlirtTool: "Prossimamente su FlirtTool",
  FindRealLifeWithFlirtTool: "Trova la Vita Reale con FlirtTool!",
  HowFlirtToolWorks: "Come Funziona FlirtTool",
  HowFlirtToolWorksdes: "FlirtTool offre nuovi livelli di appuntamenti online. La creazione definitiva di FlirtTools della personalità AI MAZU aiuterà gli utenti a essere accoppiati con qualcuno che è il 94% della loro perfetta corrispondenza.",
  LEARNMORE: "SCOPRI DI PIÙ",
  About: "Informazioni",
  PressAndMedia: "Stampa e Media",
  Blogs: "Blog",
  LiveChat: "Chat Live",
  ChatNow: "Chat Ora",
  SocialMedia: "Social Media",
  ContactUs: "Contattaci",
  WelcometoFlirttool: "Benvenuto su Flirttool",
  OrCreateanAccount: "Oppure Crea un Account",
  UsernameorEmail: "Nome Utente o Email",
  YourPassword: "La Tua Password",
  Forgotpassword: "Password dimenticata",
  Reset: "Resetta",
  IAGREE: "ACCETTO I TERMINI, LE CONDIZIONI E LA POLITICA SULLA PRIVACY",
  Clickhereto: "CLICCA QUI PER VISUALIZZARE I NOSTRI TERMINI",
  FIRSTNAME: "NOME",
  LastName: "Cognome",
  Emailisnotvalid: "L'email non è valida",
  Username: "Nome Utente",
  Yourpasswords: "La tua password",
  Passwordisnotvalid: "La password non è valida",
  Confirmpassword: "Conferma password",
  Usernamemusthaveatleast: "Il nome utente deve avere almeno",
  Alreadyhaveanaccount: "Hai già un account",
  LoginHere: "Accedi Qui",
  starthourlypackage: "Inizia Pacchetto Orario",
  SubmitReport: "Invia Segnalazione",
  Blockcon: "Sei sicuro di voler bloccare",
  Yes: "Sì",
  No: "No",
  reportreason: "Inserisci il motivo per questa segnalazione",
  Visits: "Visite",
  Photos: "Foto",
  UnlockExclusive: "Sblocca Immagini Esclusive",
  UnlockNow: "Sblocca Ora",
  Personal: "Personale",
  Ethnicity: "Etnia",
  BodyType: "Tipo di Corpo",
  Gender: "Genere",
  Height: "Altezza",
  Relationship: "Relazione",
  Work: "Lavoro",
  Education: "Educazione",
  LIFESTYLE: "STILE DI VITA",
  LivesWith: "Vive Con",
  Car: "Auto",
  Religion: "Religione",
  Smoke: "Fuma",
  Drink: "Beve",
  Travel: "Viaggiare",
  PhoneNumber: "Numero di Telefono",
  GeneralSettings: "Impostazioni Generali",
  SaveChanges: "Salva Modifiche",
  RemoveAccount: "Rimuovi Account",
  Yourprofile: "Il Tuo Profilo",
  AboutMe: "Su di Me",
  Relationshipstatus: "Stato della Relazione",
  YourCountry: "Il Tuo Paese",
  ChooseYourCountry: "Scegli Il Tuo Paese",
  Looks: "Aspetto",
  Personality: "Personalità",
  Character: "Carattere",
  Children: "Bambini",
  Friends: "Amici",
  Pets: "Animali Domestici",
  LivingWith: "Vive Con",
  MusicGenre: "Genere Musicale",
  Dish: "Piatto",
  Song: "Canzone",
  Hobby: "Hobby",
  Book: "Libro",
  Movie: "Film",
  ChangePassword: "Cambia Password",
  NewPassword: "Nuova Password",
  SETUPYOURDATINGPROFILE: "CONFIGURA IL TUO PROFILO DI APPUNTAMENTO",
  uploadphoto: "Carica foto per impostare la tua immagine del profilo",
  picnotupdated: "Immagine del profilo non aggiornata",
  pleaseupdatepicture: "Dovresti caricare un'immagine del profilo per continuare",
  BirthYear: "Anno di Nascita",
  Date: "Data",
  Month: "Mese",
  Day: "Giorno",
  SexualPreference: "Preferenza Sessuale",
  SexualPreferences: "Quali sono le tue preferenze sessuali",
  countrycode: "Codice del Paese",
  PhonNumber: "Numero di Telefono",
  nolikesyet: "Non hai ancora Mi Piace",
  nomatches: "Non hai ancora corrispondenze",
  likematches: "Puoi iniziare a corrispondere con i profili piacendoli",
  terms: "Termini",
  Privacy: "Privacy",
  imprint: "Impronta",
  Address: "Indirizzo"
},
// Westpanjabi
// Kannada
Kannada:{

  Register:"ನೋಂದಣಿ (Nō̄ṇdaṇi)",
  secondtext: "globalchat.us ನಿಮಗೆ ಹೊಸ ಮಟ್ಟದ ಆನ್‌ಲೈನ್ ಡೇಟಿಂಗ್ ಹೆಚ್ಚುವರಿ ಮಟ್ಟಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ. ಗ್ಲೋಬಲ್ ಚಾಟ್ ಯಾವುದೋ 94% ಅವರ ಸಂಪೂರ್ಣ ಸರಿಯಾದ ಜೊತೆಯಿಂದ ಬದುಕುವವನೊಂದಿಗೆ ಬಂದಿದೆ.",
  SomeRandomHeadingGoesHere: "ಇಂದು ಉಚಿತವಾಗಿ ಸೇರಿಕೊಳ್ಳಿ",
  noflirtsrecharge: "ನಿಮಗೆ ಸಾಕಾದ ಕ್ರೆಡಿಟ್‌ಗಳಿಲ್ಲ",
  heygetflirts: "ಫ್ಲರ್ಟ್‌ಗಳನ್ನು ಪಡೆಯಿರಿ",
  SEARCHINGFORNOTIFICAITON: "ಅಧಿಸೂಚನೆ ಹುಡುಕುತ್ತಿದೆ",
  Newconversation: "ಹೊಸ ಸಂಭಾಷಣೆ",
  MyPurchases: "ನನ್ನ ಖರಿದಿಗಳು",
  mypurchasedes: "ಇಲ್ಲಿ ನೀವು ಖರಿದಿಸಿದ ಫ್ಲರ್ಟ್‌ಗಳು ಮತ್ತು ಪ್ಯಾಕೆಟ್‌ಗಳನ್ನು ಹುಡುಕುತ್ತೀರಿ.",
  mypurchacedess: "ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಲಂಬವಿಲ್ಲ",
  resetlink: "ನಿಮ್ಮ ಇಮೇಲ್‌ಅಡ್ರೆಸ್‌ನನ್ನು ನಮಗೆ ನೀಡಿ, ನಿಮ್ಮ ಮರುಹೊಂದಿಸುವ ಲಿಂಕ್‌ನನ್ನು ಕಳುಹಿಸುತ್ತೇವೆ",
  ChatsConversation: "ಚಾಟ್‌ಗಳು (ಸಂಭಾಷಣೆ)",
  message: "ಸಂದೇಶ",
  likes: "ಇಷ್ಟಗಳು",
  matches: "ಹೊಂದಿಕೆಗಳು",
  Liked: "ಇಷ್ಟಪಟ್ಟ",
  Disliked: "ಅಸಂತೋಷ",
  JustVisitedYou: "ನಿಮ್ಮನ್ನು ಹೊಂದಿದ್ದಾರೆ",
  LoadMore: "ಹೆಚ್ಚಿನ ಲೋಡ್ ಮಾಡಿ",
  FliterUsers: "ಬಳಕೆದಾರರನ್ನು ಶೋಧಿಸಿ",
  Youdonthaveanynotifications: "ನಿಮಗೆ ಯಾವ ಅಧಿಸೂಚನೆಗಳೂ ಇಲ್ಲ",
  ActiveNow: "ಈಗ ಸಕ್ರಿಯ",
  SignIn: "ಸೈನ್ ಇನ್ ಮಾಡಿ",
  FlirtToolNews: "ಫ್ಲರ್ಟ್ ಟೂಲ್ ಸುದ್ದಿಗಳು",
  Join: "ಸೇರಿ",
  Free: "ಉಚಿತ",
  Today: "ಇಂದು",
  BasicPackage: "ಮೂಲ ಪ್ಯಾಕೆಟ್",
  twohoursfreechatexperience: "ಎರಡು ಗಂಟೆಗಳ ಉಚಿತ ಚಾಟ್ ಅನುಭವ",
  Tryitforfree: "ಉಚಿತವಾಗಿ ಪ್ರಯತ್ನಿಸಿ",
  FlirtToolfor1Day: "1 ದಿನಕ್ಕಾಗಿ ಫ್ಲರ್ಟ್ ಟೂಲ್",
  Gblobalfor1Day:"GlobalChat For one Day",

  RealChatFlatrate: "ನಿರಪೇಕ್ಷ ಚಾಟ್ ಫ್ಲಾಟ್ ದರ ಮಾತ್ರ $2",
  Grabit: "ಹಿಡಿಯಿರಿ",
  Createanaccount: "ಖಾತೆ ರಚಿಸಿ",
  orgettheapp: "ಅಥವಾ ಅಪ್‌ನು ಸಾಧಿಸಿ",
  OVER1MILLIONUSERSWORLDWIDE: "ವಿಶ್ವದಾದ್ಯಂತ 1 ಮಿಲಿಯನ್ ಬಳಕೆದಾರರು",
  MatchingProfiles: "ಹೊಂದಿಕೆಗಳ ಪ್ರೊಫೈಲ್‌ಗಳು",
  UpcomingonFlirtTool: "ಫ್ಲರ್ಟ್ ಟೂಲ್‌ನಲ್ಲಿ ಬರಲಿರುವಂತೆ",
  FindRealLifeWithFlirtTool: "ಫ್ಲರ್ಟ್ ಟೂಲ್‌ನೊಂದಿಗೆ ನಿಜವಾದ ಜೀವನವನ್ನು ಹುಡುಕಿ!",
  HowFlirtToolWorks: "ಹೇಗೆ ಫ್ಲರ್ಟ್ ಟೂಲ್ ಕಾರ್ಯ ಮಾಡುತ್ತದೆ",
  HowFlirtToolWorksdes: "ಫ್ಲರ್ಟ್ ಟೂಲ್ ನಿಮಗೆ ಹೊಸ ಮಟ್ಟಗಳನ್ನು ಆನ್‌ಲೈನ್ ಡೇಟಿಂಗ್‌ನಲ್ಲಿ ಒದಗಿಸುತ್ತದೆ. ಫ್ಲರ್ಟ್‌ಟೂಲ್‌ನ ಅತ್ಯುತ್ತಮ ಸೃಷ್ಟಿಯಾದ AI ವ್ಯಕ್ತಿತ್ವ MAZU ನಿಮಗೆ 94% ಅದ್ಭುತ ಹೊಂದಿಕೆಯ ಗಣಿತ ಜೋಡಣೆ ಮಾಡುತ್ತದೆ",
  LEARNMORE: "ಹೆಚ್ಚಿನ ತಿಳಿಯಿರಿ",
  About: "ಸಂಬಂಧಿಸಿದ",
  PressAndMedia: "ಪ್ರೆಸ್ ಮತ್ತು ಮಾಧ್ಯಮ",
  Blogs: "ಬ್ಲಾಗ್‌ಗಳು",
  LiveChat: "ಲೈವ್ ಚಾಟ್",
  ChatNow: "ಈಗ ಚಾಟ್ ಮಾಡಿ",
  SocialMedia: "ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ",
  ContactUs: "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
  WelcometoFlirttool: "Flirttool ಗೆ ಸುಸ್ವಾಗತ",
  OrCreateanAccount: "ಅಥವಾ ಖಾತೆ ರಚಿಸಿ",
  UsernameorEmail: "ಬಳಕೆದಾರನ ಹೆಸರು ಅಥವಾ ಇಮೇಲ್",
  YourPassword: "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್",
  Forgotpassword: "ಪಾಸ್‌ವರ್ಡ್ ಮರೆತಿದ್ದೀರಾ",
  Reset: "ಮರುಹೊಂದಿಸಿ",
  IAGREE: "ನಾನು ನಿಯಮಗಳ, ಶರತ್ತುಗಳ ಮತ್ತು ಗೌಪ್ಯತೆ ನೀತಿಯನ್ನು ಒಪ್ಪುತ್ತೇನೆ",
  Clickhereto: "ನಮ್ಮ ನಿಯಮಗಳನ್ನು ನೋಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
  FIRSTNAME: "ಮೊದಲ ಹೆಸರು",
  LastName: "ಕೊನೆಯ ಹೆಸರು",
  Emailisnotvalid: "ಇಮೇಲ್ ಸಾಕಾರದಲ್ಲಿಲ್ಲ",
  Username: "ಬಳಕೆದಾರ ಹೆಸರು",
  Yourpasswords: "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್‌ಗಳು",
  Passwordisnotvalid: "ಪಾಸ್‌ವರ್ಡ್ ಸಾಕಾರದಲ್ಲಿಲ್ಲ",
  Confirmpassword: "ಪಾಸ್‌ವರ್ಡ್ ದೃಢೀಕರಿಸಿ",
  Usernamemusthaveatleast: "ಬಳಕೆದಾರ ಹೆಸರು ಕನಿಷ್ಠವಾಗಿಯೂ",
  Alreadyhaveanaccount: "ನಿಮಗೆ ಈಗಾಗಲೇ ಖಾತೆ ಇದೆ",
  LoginHere: "ಇಲ್ಲಿ ಲಾಗಿನ್ ಮಾಡಿ",
  starthourlypackage: "ಗಂಟೆಗಳಿಗಾಗಿ ಪ್ಯಾಕೆಟ್ ಆರಂಭಿಸಿ",
  SubmitReport: "ವರದಿ ಸಲ್ಲಿಸಿ",
  Blockcon: "ನೀವು ಖಾತೆ ಮುಚ್ಚಲು ಖಚಿತರಾ?",
  Yes: "ಹೌದು",
  No: "ಇಲ್ಲ",
  reportreason: "ಈ ವರದಿಗಾರಿಕೆಗೆ ನಿಮ್ಮ ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
  Visits: "ಭೇಟಿಗಳು",
  Photos: "ಫೋಟೋಗಳು",
  UnlockExclusive: "ಅಂತರ್ನಿಹಿತ ಚಿತ್ರಗಳನ್ನು ಅನ್ಲಾಕ್ ಮಾಡಿ",
  UnlockNow: "ಈಗ ಅನ್ಲಾಕ್ ಮಾಡಿ",
  Personal: "ವೈಯಕ್ತಿಕ",
  Ethnicity: "ನೆಲದಲ್ಲಿರುವ",
  BodyType: "ದೇಹದ ರೂಪ",
  Gender: "ಲಿಂಗ",
  Height: "ಉದಾತ್ತತೆ",
  Relationship: "ಸಂಬಂಧ",
  Work: "ಕೆಲಸ",
  Education: "ಶಿಕ್ಷಣ",
  LIFESTYLE: "ಜೀವನ ಶೈಲಿ",
  LivesWith: "ಜೀವನ ನಡೆಸುತ್ತಿದೆ",
  Car: "ಕಾರು",
  Religion: "ಧರ್ಮ",
  Smoke: "ಧೂಮಪಾನ",
  Drink: "ಕುಡಿಯುವ",
  Travel: "ಪ್ರಯಾಣ",
  PhoneNumber: "ಫೋನ್ ಸಂಖ್ಯೆ",
  GeneralSettings: "ಸಾಧಾರಣ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
  SaveChanges: "ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಿ",
  RemoveAccount: "ಖಾತೆಯನ್ನು ತೆಗೆಯಿರಿ",
  Yourprofile: "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್",
  AboutMe: "ನನ್ನ ಬಗ್ಗೆ",
  Relationshipstatus: "ಸಂಬಂಧದ ಸ್ಥಿತಿ",
  YourCountry: "ನಿಮ್ಮ ದೇಶ",
  ChooseYourCountry: "ನಿಮ್ಮ ದೇಶವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  Looks: "ನೋಟ",
  Personality: "ವ್ಯಕ್ತಿತ್ವ",
  Character: "ವ್ಯಕ್ತಿತ್ವ",
  Children: "ಮಕ್ಕಳು",
  Friends: "ಸ್ನೇಹಿತರು",
  Pets: "ಪಶುಗಳು",
  LivingWith: "ಸಹಿತ ಬಾಳುತ್ತಿದೆ",
  MusicGenre: "ಸಂಗೀತದ ವಿಧ",
  Dish: "ತಿಂಡಿ",
  Song: "ಹಾಡು",
  Hobby: "ಹವ್ಯಾಸ",
  Book: "ಪುಸ್ತಕ",
  Movie: "ಚಲನಚಿತ್ರ",
  ChangePassword: "ಪಾಸ್‌ವರ್ಡ್ ಬದಲಾಯಿಸಿ",
  NewPassword: "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್",
  SETUPYOURDATINGPROFILE: "ನಿಮ್ಮ ಡೇಟಿಂಗ್ ಪ್ರೊಫೈಲ್ ಸೆಟಪ್ ಮಾಡಿ",
  uploadphoto: "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಚಿತ್ರವನ್ನು ಹೊಂದಿಸಲು ಫೋಟೋ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
  picnotupdated: "ಪ್ರೊಫೈಲ್ ಚಿತ್ರ ನವೀನಗೊಳಿಸಲಾಗಿಲ್ಲ",
  pleaseupdatepicture: "ಮುಂದುವರಿಯಲು ನೀವು ಪ್ರೊಫೈಲ್ ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಬೇಕು",
  BirthYear: "ಜನ್ಮ ವರ್ಷ",
  Date: "ದಿನಾಂಕ",
  Month: "ತಿಂಗಳು",
  Day: "ದಿನ",
  SexualPreference: "ಲಿಂಗದ ಆದಾಯ",
  SexualPreferences: "ನಿಮ್ಮ ಲಿಂಗ ಆದಾಯಗಳು ಏನು",
  countrycode: "ದೇಶದ ಕೋಡ್",
  PhonNumber: "ಫೋನ್ ಸಂಖ್ಯೆ",
  nolikesyet: "ನಿಮಗೆ ಇನ್ನೂ ಇಷ್ಟಗಳು ಇಲ್ಲ",
  nomatches: "ನಿಮಗೆ ಇನ್ನೂ ಹೊಂದಿಕೆಗಳಿಲ್ಲ",
  likematches: "ನೀವು ಪ್ರೊಫೈಲ್‌ಗಳನ್ನು ಇಷ್ಟಪಟ್ಟು ಹೊಂದಿಕೆಗಳನ್ನು ಪ್ರಾರಂಭಿಸಬಹುದು",
  terms: "ಷರತ್ತುಗಳು",
  Privacy: "ಗೌಪ್ಯತೆ",
  imprint: "ಪ್ರತಿಮೆ",
  Address: "ವಿಳಾಸ"
},
// Kannada


// Gujarati
Gujarati:{
  SomeRandomHeadingGoesHere: "આજ મફત શામિલ થાઓ",
  noflirtsrecharge: "તમારી પાસે પોતાની ક્રેડિટ્સ નથી",
  heygetflirts: "ફ્લર્ટ્સ મેળવો",
  SEARCHINGFORNOTIFICAITON: "સૂચના શોધાઈ રહ્યું છે",
  Newconversation: "નવી મીઠાઈ",
  MyPurchases: "મારી ખરીદીઓ",
  mypurchasedes: "અહીં તમે જે ફ્લર્ટ્સ અને પેકેજો ખરીદ્યા છે તેને મળશે.",
  mypurchacedess: "તમારી હજી કોઈ લેન-દેન નથી",
  resetlink: "તમારો ઇમેઇલ એડ્રેસ દાખલ કરો અને અમે તમને તમારી રીસેટ લિંક મોકલીશું",
  ChatsConversation: "ચેટ્સ (સંભાષણ)",
  message: "સંદેશ",
  likes: "લાઇકો",
  matches: "મેચઝ",
  Liked: "પ્રિય",
  Disliked: "અનપ્રિય",
  JustVisitedYou: "જસ્ટ તમને મુલાકાત કરવા આવ્યા છે",
  LoadMore: "વધુ લોડ કરો",
  FliterUsers: "વપરાશકર્તાઓને ફિલ્ટર કરો",
  Youdonthaveanynotifications: "તમારી પાસે કોઈ સૂચનાઓ નથી",
  ActiveNow: "હાલમાં સક્રિય",
  SignIn: "સાઇન ઇન કરો",
  FlirtToolNews: "ફ્લર્ટ ટૂલ ન્યૂઝ",
  Join: "જોડાઓ",
  Free: "મુક્ત",
  Today: "આજે",
  BasicPackage: "બેસિક પેકેજ",
  twohoursfreechatexperience: "2 કલાક મફત ચેટ અનુભવ",
  Tryitforfree: "મુક્ત માટે પ્રયાસ કરો",
  FlirtToolfor1Day: "1 દિવસ માટે ફ્લર્ટ ટૂલ",
  Gblobalfor1Day:"GlobalChat For one Day",

  RealChatFlatrate: "ફક્ત $2 માટે વાસ્તવિક ચેટ ફ્લેટ રેટ",
  Grabit: "તેને પકડો",
  Createanaccount: "એક ખાતું બનાવો",
  orgettheapp: "અથવા ઍપ મેળવો",
  OVER1MILLIONUSERSWORLDWIDE: "વિશ્વમાં 1 મિલિયન વપરાશકર્તાઓ",
  MatchingProfiles: "મેચિંગ પ્રોફાઇલ્સ",
  UpcomingonFlirtTool: "ફ્લર્ટ ટૂલ પર આવતી",
  FindRealLifeWithFlirtTool: "ફ્લર્ટ ટૂલ સાથે વાસ્તવિક જીવન શોધો!",
  HowFlirtToolWorks: "ફ્લર્ટ ટૂલ કેવી રીતે કામ કરે છે",
  HowFlirtToolWorksdes: "ફ્લર્ટ ટૂલ તમને ઓનલાઇન ડેટિંગની નવી સતતાની સાથે મળવા માટે પૂરી પાડે છે. ફ્લર્ટ ટૂલનું અત્યુત્તમ રચનાત્મક AI વ્યક્તિત્વ MAZU તમને 94% તમારી પરફેક્ટ મેચ સાથે જોડવામાં મદદ કરશે",
  LEARNMORE: "વધુ જાણો",
  About: "વિશે",
  PressAndMedia: "પ્રેસ અને મીડિયા",
  Blogs: "બ્લોગ્‌સ",
  LiveChat: "લાઇવ ચેટ",
  ChatNow: "હવે ચેટ કરો",
  SocialMedia: "સામાજિક મીડિયા",
  ContactUs: "અમારો સંપર્ક કરો",
  WelcometoFlirttool: "ફ્લર્ટટૂલમાં આપનું સ્વાગત છે",
  OrCreateanAccount: "અથવા એક ખાતું બનાવો",
  UsernameorEmail: "બળતણીદારનું નામ અથવા ઇમેલ",
  YourPassword: "તમારો પાસવર્ડ",
  Forgotpassword: "પાસવર્ડ ભૂલ્યું છે",
  Reset: "રીસેટ",
  IAGREE: "મેં નીતિઓ, શરતો અને ગોપનીયતા નીતિને સ્વીકારું છું",
  Clickhereto: "અમારા નિયમો જોવા માટે અહીં ક્લિક કરો",
  FIRSTNAME: "પ્રથમ નામ",
  LastName: "છેલ્લું નામ",
  Emailisnotvalid: "ઇમેલ માન્ય નથી",
  Username: "બળતણીદારનું નામ",
  Yourpasswords: "તમારો પાસવર્ડ",
  Passwordisnotvalid: "પાસવર્ડ માન્ય નથી",
  Confirmpassword: "પાસવર્ડ ખાતરી કરો",
  Usernamemusthaveatleast: "બળતણીદાર નામ ઓળખવું જોઈએ",
  Alreadyhaveanaccount: "હાલમાં ખાતું છે",
  LoginHere: "અહીં લૉગ ઇન કરો",
  starthourlypackage: "ઘણા કલાકો માટે પેકેજ શરૂ કરો",
  SubmitReport: "અહીં રિપોર્ટ સબમિટ કરો",
  Blockcon: "શું તમે ખાતું બંધ કરવા માંગો છો?",
  Yes: "હા",
  No: "ના",
  reportreason: "આ રિપોર્ટ માટે તમારું કારણ દાખલ કરો",
  Visits: "મુલાકાતો",
  Photos: "ફોટો",
  UnlockExclusive: "અનલૉક પ્રિવેટ ચિત્રો",
  UnlockNow: "હવે અનલૉક કરો",
  Personal: "વૈયક્તિક",
  Ethnicity: "નસ્લ",
  BodyType: "શરીરનો પ્રકાર",
  Gender: "જાતિ",
  Height: "ઊંચાઈ",
  Relationship: "સંબંધ",
  Work: "કામ",
  Education: "શિક્ષણ",
  LIFESTYLE: "જીવન શૈલી",
  LivesWith: "સાથે જીવે",
  Car: "કાર",
  Religion: "ધર્મ",
  Smoke: "ધૂમ્રપાન",
  Drink: "પીણાં",
  Travel: "પ્રવાસ",
  PhoneNumber: "ફોન નંબર",
  GeneralSettings: "સામાન્ય સેટિંગ્સ",
  SaveChanges: "ફેરફારો સાચવો",
  RemoveAccount: "ખાતું દૂર કરો",
  Yourprofile: "તમારું પ્રોફાઇલ",
  AboutMe: "મારા વિશે",
  Relationshipstatus: "સંબંધની સ્થિતિ",
  YourCountry: "તમારો દેશ",
  ChooseYourCountry: "તમારો દેશ પસંદ કરો",
  Looks: "લુક",
  Personality: "વ્યક્તિત્વ",
  Character: "ક્યારેકાર",
  Children: "બાળકો",
  Friends: "મિત્રો",
  Pets: "પ્રાણીઓ",
  LivingWith: "સાથે વસવું",
  MusicGenre: "સંગીતનો જાત",
  Dish: "ડિશ",
  Song: "ગીત",
  Hobby: "હોબી",
  Book: "પુસ્તક",
  Movie: "ચલચિત્ર",
  ChangePassword: "પાસવર્ડ બદલો",
  NewPassword: "નવો પાસવર્ડ",
  SETUPYOURDATINGPROFILE: "તમારી ડેટિંગ પ્રોફાઇલ સેટ અપ કરો",
  uploadphoto: "તમારી પ્રોફાઇલ ચિત્ર સેટ કરવા ફોટો અપલોડ કરો",
  picnotupdated: "પ્રોફાઇલ ચિત્ર અપડેટ નથી",
  pleaseupdatepicture: "આગળ વધો માટે તમારે પ્રોફાઇલ ચિત્ર અપલોડ કરવો જોઈએ",
  BirthYear: "જન્મ વર્ષ",
  Date: "તારીખ",
  Month: "મહિનો",
  Day: "દિવસ",
  SexualPreference: "લિંગનું પ્રાધાન્ય",
  SexualPreferences: "તમારું લિંગનું પ્રાધાન્ય શું છે",
  countrycode: "દેશનું કોડ",
  PhonNumber: "ફોન નંબર",
  nolikesyet: "હજી સુધી તમને કોઈ લાઇક્સ નથી",
  nomatches: "હજી સુધી તમને કોઈ મેચઝ નથી",
  likematches: "તમે પ્રોફાઇલ્સને લાઇક કરીને મેચઝ શરૂ કરી શકો છો",
  terms: "શરતો",
  Privacy: "ગોપનીયતા",
  imprint: "છાપ",
  Address: "સરનામું"
},
// Gujarati
// Thai
Thai:{
  Register:"ลงทะเบียน",
  SomeRandomHeadingGoesHere: "เข้าร่วมฟรีวันนี้",
  noflirtsrecharge: "คุณไม่มีเครดิตเพียงพอ",
  heygetflirts: "รับการจีบ",
  SEARCHINGFORNOTIFICAITON: "กำลังค้นหาการแจ้งเตือน",
  Newconversation: "การสนทนาใหม่",
  MyPurchases: "การซื้อของฉัน",
  mypurchasedes: "ที่นี่คุณจะพบการจีบและแพ็คเกจที่คุณซื้อ",
  mypurchacedess: "คุณยังไม่มีธุรกรรมใดๆ",
  resetlink: "ป้อนที่อยู่อีเมลของคุณและเราจะส่งลิงก์รีเซ็ตให้คุณ",
  ChatsConversation: "การสนทนา (สนทนา)",
  message: "ข้อความ",
  likes: "ถูกใจ",
  matches: "คู่แข่ง",
  Liked: "ชอบ",
  Disliked: "ไม่ชอบ",
  JustVisitedYou: "เพิ่งเข้ามาเยี่ยมชมคุณ",
  LoadMore: "โหลดเพิ่ม",
  FliterUsers: "กรองผู้ใช้",
  Youdonthaveanynotifications: "คุณไม่มีการแจ้งเตือนใด ๆ",
  ActiveNow: "กำลังใช้งานอยู่",
  SignIn: "เข้าสู่ระบบ",
  FlirtToolNews: "ข่าวเครื่องมือเสน่ห์",
  Join: "ร่วมกัน",
  Free: "ฟรี",
  Today: "วันนี้",
  BasicPackage: "แพ็คเกจพื้นฐาน",
  twohoursfreechatexperience: "ประสบการณ์แชทฟรี 2 ชั่วโมง",
  Tryitforfree: "ลองใช้ฟรี",
  FlirtToolfor1Day: "FlirtTool 1 วัน",
  Gblobalfor1Day:"GlobalChat For one Day",

  RealChatFlatrate: "ค่าแชทแบบจริงเพียง $2",
  Grabit: "จับมัน",
  Createanaccount: "สร้างบัญชี",
  orgettheapp: "หรือได้รับแอป",
  OVER1MILLIONUSERSWORLDWIDE: "มีผู้ใช้กว่า 1 ล้านคนทั่วโลก",
  MatchingProfiles: "โปรไฟล์ที่เข้ากัน",
  UpcomingonFlirtTool: "เร็วๆ นี้ใน FlirtTool",
  FindRealLifeWithFlirtTool: "ค้นหาชีวิตจริงกับ FlirtTool!",
  HowFlirtToolWorks: "FlirtTool ทำงานอย่างไร",
  HowFlirtToolWorksdes: "FlirtTool นำเสนอระดับใหม่ของการหาคู่ออนไลน์ FlirtTools สร้าง AI บุคลิกภาพ MAZU จะช่วยผู้ใช้ที่จะถูกจับคู่กับคนที่เหมาะสมกับตัวเองถึง 94%",
  LEARNMORE: "เรียนรู้เพิ่มเติม",
  About: "เกี่ยวกับ",
  PressAndMedia: "สื่อและสื่อ",
  Blogs: "บล็อก",
  LiveChat: "สนทนาสด",
  ChatNow: "สนทนาเดี๋ยวนี้",
  SocialMedia: "สื่อสังคม",
  ContactUs: "ติดต่อเรา",
  WelcometoFlirttool: "ยินดีต้อนรับสู่ Flirttool",
  OrCreateanAccount: "หรือสร้างบัญชี",
  UsernameorEmail: "ชื่อผู้ใช้หรืออีเมล",
  YourPassword: "รหัสผ่านของคุณ",
  Forgotpassword: "ลืมรหัสผ่าน",
  Reset: "รีเซ็ต",
  IAGREE: "ฉันยินยอมกับข้อกำหนด นโยบายความเป็นส่วนตัว",
  Clickhereto: "คลิกที่นี่เพื่อดูข้อกำหนดของเรา",
  FIRSTNAME: "ชื่อจริง",
  LastName: "นามสกุล",
  Emailisnotvalid: "อีเมลไม่ถูกต้อง",
  Username: "ชื่อผู้ใช้",
  Yourpasswords: "รหัสผ่านของคุณ",
  Passwordisnotvalid: "รหัสผ่านไม่ถูกต้อง",
  Confirmpassword: "ยืนยันรหัสผ่าน",
  Usernamemusthaveatleast: "ชื่อผู้ใช้ต้องมีอย่างน้อย",
  Alreadyhaveanaccount: "มีบัญชีอยู่แล้ว",
  LoginHere: "เข้าสู่ระบบที่นี่",
  starthourlypackage: "เริ่มแพ็คเกจต่อชั่วโมง",
  SubmitReport: "ส่งรายงาน",
  Blockcon: "คุณแน่ใจหรือไม่ว่าต้องการบล็อก",
  Yes: "ใช่",
  No: "ไม่",
  reportreason: "ป้อนเหตุผลของคุณสำหรับรายงานนี้",
  Visits: "การเยี่ยมชม",
  Photos: "รูปภาพ",
  UnlockExclusive: "ปลดล็อคภาพ",
  UnlockNow: "ปลดล็อคเดี๋ยวนี้",
  Personal: "ส่วนตัว",
  Ethnicity: "เชื้อชาติ",
  BodyType: "ประเภทร่างกาย",
  Gender: "เพศ",
  Height: "ส่วนสูง",
  Relationship: "ความสัมพันธ์",
  Work: "งาน",
  Education: "การศึกษา",
  LIFESTYLE: "ไลฟ์สไตล์",
  LivesWith: "อาศัยอยู่กับ",
  Car: "รถ",
  Religion: "ศาสนา",
  Smoke: "สูบบุหรี่",
  Drink: "ดื่ม",
  Travel: "เดินทาง",
  PhoneNumber: "หมายเลขโทรศัพท์",
  GeneralSettings: "การตั้งค่าทั่วไป",
  SaveChanges: "บันทึกการเปลี่ยนแปลง",
  RemoveAccount: "ลบบัญชี",
  Yourprofile: "โปรไฟล์ของคุณ",
  AboutMe: "เกี่ยวกับฉัน",
  Relationshipstatus: "สถานะความสัมพันธ์",
  YourCountry: "ประเทศของคุณ",
  ChooseYourCountry: "เลือกประเทศของคุณ",
  Looks: "ลักษณะ",
  Personality: "บุคลิกภาพ",
  Character: "ลักษณะ",
  Children: "เด็ก",
  Friends: "เพื่อน",
  Pets: "สัตว์เลี้ยง",
  LivingWith: "อาศัยอยู่กับ",
  MusicGenre: "แนวเพลง",
  Dish: "จาน",
  Song: "เพลง",
  Hobby: "งานอดิเรก",
  Book: "หนังสือ",
  Movie: "ภาพยนตร์",
  ChangePassword: "เปลี่ยนรหัสผ่าน",
  NewPassword: "รหัสผ่านใหม่",
  SETUPYOURDATINGPROFILE: "ตั้งค่าโปรไฟล์การเดทของคุณ",
  uploadphoto: "อัปโหลดรูปเพื่อตั้งรูปโปรไฟล์ของคุณ",
  picnotupdated: "รูปโปรไฟล์ไม่ได้รับการอัปเดต",
  pleaseupdatepicture: "คุณควรอัปโหลดรูปโปรไฟล์เพื่อดำเนินการต่อ",
  BirthYear: "ปีเกิด",
  Date: "วัน",
  Month: "เดือน",
  Day: "วัน",
  SexualPreference: "ความชอบทางเพศ",
  SexualPreferences: "ความชอบทางเพศของคุณคืออะไร",
  countrycode: "รหัสประเทศ",
  PhonNumber: "หมายเลขโทรศัพท์",
  nolikesyet: "คุณยังไม่มีการถูกใจ",
  nomatches: "คุณยังไม่มีการจับคู่",
  likematches: "คุณสามารถเริ่มต้นการจับคู่โดยการกดปุ่มถูกใจกับโปรไฟล์ได้",
  terms: "ข้อตกลง",
  Privacy: "ความเป็นส่วนตัว",
  imprint: "แสตมป์",
  Address: "ที่อยู่"
},
// Thai

Amharisch:{
  SomeRandomHeadingGoesHere: "አሁን በነፃ ይቀላቀሉ",
  noflirtsrecharge: "የምንደኛው ክፍያ አይችልም",
  heygetflirts: "እባክዎን ተራዘመው ተንቀሳቃሽ ቅድሚያዎች ይጠቀሙ",
  SEARCHINGFORNOTIFICAITON: "ማጣቆሚያ ለመፈጸም",
  Newconversation: "አዲስ የውይይት",
  MyPurchases: "የእርስዎ ግዜ",
  mypurchasedes: "እዚህ ከሆነ ከምንም ማህበራዊ እና ቅናሽ እትም አልተገኘም።",
  mypurchacedess: "እስካሁን የምንለው ግዜ የለም",
  resetlink: "እባኮትን ኢሜል አድርጉን እና እባኮትዎን አድርጉን",
  ChatsConversation: "የአስተዳደር ከንቱነት",
  message: "መልዕክት",
  likes: "እንደምድር",
  matches: "መረጃዎች",
  Liked: "ተወዳጅ",
  Disliked: "ተመልከቱ",
  JustVisitedYou: "በለንጩ መነሻ",
  LoadMore: "ተጨማሪ ስም",
  FliterUsers: "ተጠቃሚዎች አዳርጋለሁ",
  Youdonthaveanynotifications: "እባኮትዎ ምንም ማጣቆሚያ የለም",
  ActiveNow: "አካባቢ እንደሚገኙ",
  SignIn: "ግባ",
  FlirtToolNews: "የውይይት መረጃ",
  Join: "ከተሰራ",
  Free: "ነጻ",
  Today: "ዛሬ",
  BasicPackage: "አጠቃቀም ግዜ",
  twohoursfreechatexperience: "ከፋና የአስተዳደር ማዕከል ከነፃ ሰዓት በኋላ",
  Tryitforfree: "ነፃ አጥቻ በተለያዩ ማግኘት",
  FlirtToolfor1Day: "የአንዳንድ ቀን ማዕከል",
  Gblobalfor1Day:"GlobalChat For one Day",

  RealChatFlatrate: "የሴቶች ክፍያ ከተለያዩ $2",
  Grabit: "ይምረጡ",
  Createanaccount: "አካውንት መዝገብ",
  orgettheapp: "ወደእባኮት ይሂዱ",
  OVER1MILLIONUSERSWORLDWIDE: "በዓለም ላይ 1 ሚሊዮን ተጠቃሚዎች",
  MatchingProfiles: "የመረጃዎቹን መረጃዎች",
  UpcomingonFlirtTool: "በ FlirtTool በተጨማሪ",
  FindRealLifeWithFlirtTool: "ያለውን ህይወት እንደ FlirtTool ያግዛል!",
  HowFlirtToolWorks: "እንዴት FlirtTool እንደሚሆን ማግኘት",
  HowFlirtToolWorksdes: "FlirtTool በከፍተኛ ስርዓት የተለመዱትን አካባቢ አይ ፓርላን MAZU በ 94% እንዴት መክፈያ ይችላል",
  LEARNMORE: "ተጨማሪ ተረትና",
  About: "ስለ",
  PressAndMedia: "ፕሬስ እና መገናኛ",
  Blogs: "ብልጥና",
  LiveChat: "በቀጥታ ይከታተሉ",
  ChatNow: "በዚህ አድራሻ ቀጥታ ይከታተሉ",
  SocialMedia: "የማህበረሰብ መሳሪያ",
  ContactUs: "እንዴት እንደሚገኝ",
  WelcometoFlirttool: "እንኳን Flirttool ውድ በደህና መጡ",
  OrCreateanAccount: "ወደ አካውንት ይሂዱ",
  UsernameorEmail: "የተጠቃሚ ስም ወይም ኢሜል",
  YourPassword: "የምስጢርዎ",
  Forgotpassword: "የምስጢር ቅርጽ ረሳ",
  Reset: "አድርግ",
  IAGREE: "እችላለሁ የግል ምርጫዎችን እና ግል መረጃዎችን ተዘጋጅቻለሁ",
  Clickhereto: "ይጠቀሙ ይህን ጠቅ ያድርጉ",
  FIRSTNAME: "ስም",
  LastName: "የአባት ስም",
  Emailisnotvalid: "ኢሜል አልተሳካም",
  Username: "የተጠቃሚ ስም",
  Yourpasswords: "የምስጢርዎ",
  Passwordisnotvalid: "የምስጢር ቅርጽ አልተሳካም",
  Confirmpassword: "የምስጢርዎን ተጨማሪ አረጋግጥ",
  Usernamemusthaveatleast: "የተጠቃሚው በተጨማሪ ስም አልተመኘም",
  Alreadyhaveanaccount: "አካውንት አሁን ያለው",
  LoginHere: "እንዴት በዚህ ግባ",
  starthourlypackage: "በሰዓት በኩል አጠቃቀም እንደሚጀምሩ",
  SubmitReport: "ሪፖርት አስገባ",
  Blockcon: "እርስዎ በጣም ተጣልቷል ይህንን ይሄ እርስዎ እንደምንቀርብ",
  Yes: "አዎ",
  No: "አይደለም",
  reportreason: "እንዴት ለመሪ ይጠቀሙ",
  Visits: "መልክዎን",
  Photos: "ፎቶዎች",
  UnlockExclusive: "የተጠቃሚውን ምስል አድርግ",
  UnlockNow: "እንዴትም አድርግ",
  Personal: "ግልባጥ",
  Ethnicity: "ምርጫ",
  BodyType: "አካባቢ አይነት",
  Gender: "ጾታ",
  Height: "በፊት",
  Relationship: "መረጃዎች",
  Work: "ስራ",
  Education: "ትምህርት",
  LIFESTYLE: "የህይወት ህይወት",
  LivesWith: "ከተማ ነው",
  Car: "መኪና",
  Religion: "ክርስቲያን",
  Smoke: "ቁርጥቁርጥ",
  Drink: "መጠጣት",
  Travel: "ወጣቶች",
  PhoneNumber: "ስልክ ቁጥር",
  GeneralSettings: "አጠቃላይ ቅንብሮች",
  SaveChanges: "ተረት አስቀምጥ",
  RemoveAccount: "አካውንት አጥፋ",
  Yourprofile: "የገበያ መግለጫዎችዎ",
  AboutMe: "ስለ እኔ",
  Relationshipstatus: "መረጃ ሁኔታ",
  YourCountry: "አካባቢዎ",
  ChooseYourCountry: "አካባቢዎን ይምረጡ",
  Looks: "መዝነስ",
  Personality: "ሰውነቱ",
  Character: "መለያ",
  Children: "ልጆች",
  Friends: "ጓደኛዎች",
  Pets: "የግምት አይነቶች",
  LivingWith: "ከተማ አካባቢ",
  MusicGenre: "ሙዚቃዎችን ከሰዓት",
  Dish: "ባህላዊ",
  Song: "መዝሙር",
  Hobby: "ባህላዊ ነበልባል",
  Book: "መጽሐፍ",
  Movie: "ፊልም",
  ChangePassword: "የይለፍ ቃል አድርግ",
  NewPassword: "የአዲስ ይለፍ ቃል",
  SETUPYOURDATINGPROFILE: "የመዝገበቃንና እይታዎን ይቀይሩ",
  uploadphoto: "ስንት ሙሉ አድራሻ ይጠቀሙ",
  picnotupdated: "ፎቶ አይደለም",
  pleaseupdatepicture: "እባኮት ሙሉ አድራሻ ይጠቀሙ",
  BirthYear: "የልደት ዓመት",
  Date: "ቀን",
  Month: "ወር",
  Day: "ቀን",
  SexualPreference: "ስለመደንደር የጾታ",
  SexualPreferences: "ጾታ ማድረግህ ያለው ምልክት ምንድን ነው",
  countrycode: "አገልግሎት ኮድ",
  PhonNumber: "ስልክ ቁጥር",
  nolikesyet: "እንደምንደርሰው ምንም የማይወድ",
  nomatches: "እንደምንመረጥ ምንም የማይታወቅ",
  likematches: "ምንም የማይመረጥ ስልኩን በሚያደርጉ ቀናት በከፍተኛው እንደሚያገኙ አድርግ",
  terms: "ውል",
  Privacy: "ግላዊ",
  imprint: "ቀረጥ",
  Address: "አድራሻ"
},
Bhojpuri:{
  SomeRandomHeadingGoesHere: "आज मुफ्त में शामिल होईं",
  noflirtsrecharge: "आपके पास पर्याप्त क्रेडिट नहीं है",
  heygetflirts: "फ्लर्ट्स प्राप्त करें",
  SEARCHINGFORNOTIFICAITON: "सूचना के लिए खोज रहा है",
  Newconversation: "नई बातचीत",
  MyPurchases: "मेरी खरीदारी",
  mypurchasedes: "यहाँ आपको वह फ्लर्ट्स और पैकेज मिलेंगे जो आपने खरीदा है।",
  mypurchacedess: "आपके पास अभी तक कोई लेन-देन नहीं है",
  resetlink: "अपना ई-मेल पता दर्ज करें और हम आपको अपना रीसेट लिंक भेज देंगे",
  ChatsConversation: "चैट (बातचीत)",
  message: "संदेश",
  likes: "पसंद",
  matches: "मैच",
  Liked: "पसंद किया",
  Disliked: "अच्छा नहीं लगा",
  JustVisitedYou: "आपको अभी अभी देखा",
  LoadMore: "अधिक लोड करें",
  FliterUsers: "उपयोगकर्ताओं को फ़िल्टर करें",
  Youdonthaveanynotifications: "आपके पास कोई सूचनाएँ नहीं हैं",
  ActiveNow: "अब सक्रिय",

  SignIn: "साइन इन करें",
  FlirtToolNews: "फ्लर्टटूल समाचार",
  Join: "शामिल हों",
  Free: "मुफ़्त",
  Today: "आज",
  BasicPackage: "मूल पैकेज",
  twohoursfreechatexperience: "2 घंटे का मुफ़्त चैट अनुभव",
  Tryitforfree: "इसे मुफ़्त में आज़माएं",
  FlirtToolfor1Day: "1 दिन के लिए फ्लर्टटूल",
  Gblobalfor1Day:"GlobalChat For one Day",

  RealChatFlatrate: "वास्तविक चैट फ्लैटरेट केवल $2",
  Grabit: "इसे पकड़ो",
  Createanaccount: "खाता बनाएं",
  orgettheapp: "या ऐप प्राप्त करें",
  OVER1MILLIONUSERSWORLDWIDE: "विश्वभर में 1 मिलियन से अधिक उपयोगकर्ता",
  MatchingProfiles: "मिलान प्रोफ़ाइल",
  UpcomingonFlirtTool: "फ्लर्टटूल पर आने वाला",
  FindRealLifeWithFlirtTool: "फ्लर्टटूल के साथ असली जीवन खोजें!",
  HowFlirtToolWorks: "फ्लर्टटूल कैसे काम करता है",
  HowFlirtToolWorksdes: "फ्लर्टटूल आपको ऑनलाइन डेटिंग के नए स्तर प्रदान करता है। FlirtTool की अंतिम रचना AI व्यक्तित्व MAZU आपको किसी के साथ मिलान करती है जो उनके परिपूर्ण मिलान का 94% है",
  LEARNMORE: "और अधिक जानें",
  About: "के बारे में",
  PressAndMedia: "प्रेस और मीडिया",
  Blogs: "ब्लॉग्स",
  LiveChat: "लाइव चैट",
  ChatNow: "अभी चैट करें",
  SocialMedia: "सामाजिक मीडिया",
  ContactUs: "संपर्क करें",
  WelcometoFlirttool: "फ्लर्टटूल में आपका स्वागत है",
  OrCreateanAccount: "या खाता बनाएं",
  UsernameorEmail: "उपयोगकर्ता नाम या ईमेल",
  YourPassword: "आपका पासवर्ड",
  Forgotpassword: "पासवर्ड भूल गए",
  Reset: "रीसेट",
  IAGREE: "मैं शर्तों, नियमों और गोपनीयता नीति से सहमत हूं",
  Clickhereto: "हमारी शर्तों को देखने के लिए यहां क्लिक करें",
  FIRSTNAME: "पहला नाम",
  LastName: "अंतिम नाम",
  Emailisnotvalid: "ईमेल वैध नहीं है",
  Username: "उपयोगकर्ता नाम",
  Yourpasswords: "आपका पासवर्ड",
  Passwordisnotvalid: "पासवर्ड वैध नहीं है",
  Confirmpassword: "पासवर्ड की पुष्टि करें",
  Usernamemusthaveatleast: "उपयोगकर्ता नाम में कम से कम",
  Alreadyhaveanaccount: "पहले से ही खाता है",
  LoginHere: "यहां लॉगिन करें",
  starthourlypackage: "प्रति घंटा पैकेज शुरू करें",
  SubmitReport: "रिपोर्ट सबमिट करें",
  Blockcon: "क्या आप ब्लॉक करना चाहते हैं",
  Yes: "हां",
  No: "नहीं",
  reportreason: "इस रिपोर्ट के लिए आपका कारण दर्ज करें",
  Visits: "आगंतुक",
  Photos: "तस्वीरें",
  UnlockExclusive: "विशेष तस्वीरों को अनलॉक करें",
  UnlockNow: "अब अनलॉक करें",
  Personal: "व्यक्तिगत",
  Ethnicity: "जाति",
  BodyType: "शरीर का प्रकार",
  Gender: "लिंग",
  Height: "ऊचाई",
  Relationship: "संबंध",
  Work: "काम",
  Education: "शिक्षा",
  LIFESTYLE: "जीवन शैली",
  LivesWith: "के साथ रहता है",
  Car: "कार",
  Religion: "धर्म",
  Smoke: "धूम्रपान",
  Drink: "पीना",
  Travel: "यात्रा",
  PhoneNumber: "फ़ोन नंबर",
  GeneralSettings: "सामान्य सेटिंग्स",
  SaveChanges: "परिवर्तन सहेजें",
  RemoveAccount: "खाता हटाएं",
  Yourprofile: "आपका प्रोफ़ाइल",
  AboutMe: "मेरे बारे में",
  Relationshipstatus: "संबंध की स्थिति",
  YourCountry: "आपका देश",
  ChooseYourCountry: "अपना देश चुनें",
  Looks: "दिखता है",
  Personality: "व्यक्तित्व",
  Character: "स्वभाव",
  Children: "बच्चे",
  Friends: "दोस्त",
  Pets: "पालतू जानवर",
  LivingWith: "के साथ रहना",
  MusicGenre: "संगीत शैली",
  Dish: "डिश",
  Song: "गाना",
  Hobby: "शौक",
  Book: "किताब",
  Movie: "फिल्म",
  ChangePassword: "पासवर्ड बदलें",
  NewPassword: "नया पासवर्ड",
  SETUPYOURDATINGPROFILE: "अपना डेटिंग प्रोफ़ाइल सेट करें",
  uploadphoto: "प्रोफ़ाइल चित्र सेट करने के लिए फ़ोटो अपलोड करें",
  picnotupdated: "प्रोफ़ाइल चित्र अपडेट नहीं है",
  pleaseupdatepicture: "आपको जारी रखने के लिए कृपया प्रोफ़ाइल चित्र अपलोड करें",
  BirthYear: "जन्म वर्ष",
  Date: "दिनांक",
  Month: "महीना",
  Day: "दिन",
  SexualPreference: "लैंगिक पसंद",
  SexualPreferences: "आपकी लैंगिक पसंद",
  countrycode: "देश कोड",
  PhonNumber: "फोन नंबर",
  nolikesyet: "आपके पास अभी तक कोई पसंद नहीं है",
  nomatches: "आपके पास अभी तक कोई मैच नहीं है",
  likematches: "आप प्रोफाइल को पसंद करके मैचिंग शुरू कर सकते हैं",
  terms: "शर्तें",
  Privacy: "गोपनीयता",
  imprint: "प्रभाव",
  Address: "पता"
},
Ostpanjabi:{
  SomeRandomHeadingGoesHere: "ਅੱਜ ਮੁਫ਼ਤ ਵਿੱਚ ਸ਼ਾਮਿਲ ਹੋਓ",
  noflirtsrecharge: "ਤੁਹਾਡੇ ਕੋਲ ਪੰਜੀ ਨਹੀਂ ਹੈ",
  heygetflirts: "ਫਲਰਟਸ ਪ੍ਰਾਪਤ ਕਰੋ",
  SEARCHINGFORNOTIFICAITON: "ਸੂਚਨਾ ਲੱਭ ਰਿਹਾ ਹੈ",
  Newconversation: "ਨਵੀਂ ਗੱਲਬਾਤ",
  MyPurchases: "ਮੇਰੀ ਖਰੀਦਾਰੀ",
  mypurchasedes: "ਇੱਥੇ ਤੁਸੀਂ ਉਹ ਫਲਰਟਸ ਅਤੇ ਪੈਕੇਜਾਂ ਲੱਭੋਗੇ ਜੋ ਤੁਸੀਂ ਖਰੀਦਿਆ ਹੈ",
  mypurchacedess: "ਤੁਹਾਡੇ ਕੋਲ ਹਾਲ ਹੀ ਕੋਈ ਲੇਨਦਾਰੀ ਨਹੀਂ ਹੈ",
  resetlink: "ਆਪਣਾ ਈ-ਮੇਲ ਪਤਾ ਦਾਖਲ ਕਰੋ ਅਤੇ ਅਸੀਂ ਤੁਹਾਨੂੰ ਆਪਣਾ ਰੀਸੈਟ ਲਿੰਕ ਭੇਜਿਆ ਜਾਵੇਗਾ",
  ChatsConversation: "ਚੈਟ (ਗੱਲਬਾਤ)",
  message: "ਸੁਨੇਹਾ",
  likes: "ਪਸੰਦ",
  matches: "ਮੈਚ",
  Liked: "ਪਸੰਦ",
  Disliked: "ਨਾਪਸੰਦ",
  JustVisitedYou: "ਤੁਹਾਨੂੰ ਹਾਲ ਹੀ ਵਿਆਹੀ ਕਰਨ ਵਾਲਾ ਦੇਖਿਆ",
  LoadMore: "ਹੋਰ ਲੋਡ ਕਰੋ",
  FliterUsers: "ਯੂਜ਼ਰਾਂ ਨੂੰ ਫ਼ਿਲਟਰ ਕਰੋ",
  Youdonthaveanynotifications: "ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਸੂਚਨਾ ਨਹੀਂ ਹੈ",
  ActiveNow: "ਹੁਣ ਚਲ ਰਿਹਾ ਹੈ",

  SignIn: "ਸਾਈਨ ਇਨ ਕਰੋ",
  FlirtToolNews: "ਫਲਰਟ ਸਾਧਨ ਨਵਾਂ",
  Join: "ਸ਼ਾਮਲ ਹੋਵੋ",
  Free: "ਮੁਫ਼ਤ",
  Today: "ਅੱਜ",
  BasicPackage: "ਮੁਖਾਂ ਪੈਕੇਜ",
  twohoursfreechatexperience: "2 ਘੰਟੇ ਦੀ ਮੁਫ਼ਤ ਚੈਟ ਅਨੁਭਵ",
  Tryitforfree: "ਇਸ ਨੂੰ ਮੁਫ਼ਤ ਅਜ਼ਮਾਓ",
  FlirtToolfor1Day: "1 ਦਿਨ ਲਈ ਫਲਰਟ ਸਾਧਨ",
  Gblobalfor1Day:"GlobalChat For one Day",

  RealChatFlatrate: "ਅਸਲ ਚੈਟ ਫਲੈਟਰੇਟ ਸਿਰਫ $2",
  Grabit: "ਇਸ ਨੂੰ ਪੱਕੜੋ",
  Createanaccount: "ਖਾਤਾ ਬਣਾਓ",
  orgettheapp: "ਜਾਂ ਐਪ ਪ੍ਰਾਪਤ ਕਰੋ",
  OVER1MILLIONUSERSWORLDWIDE: "ਵਿਸ਼ਵਵਿਆਪੀ 1 ਮਿਲੀਅਨ ਤੋਂ ਵੱਧ ਯੂਜ਼ਰ",
  MatchingProfiles: "ਮੈਚਿੰਗ ਪ੍ਰੋਫਾਈਲ",
  UpcomingonFlirtTool: "ਫਲਰਟ ਟੂਲ ਤੇ ਆਉਣ ਵਾਲਾ",
  FindRealLifeWithFlirtTool: "ਫਲਰਟ ਟੂਲ ਨਾਲ ਅਸਲ ਜੀਵਨ ਲੱਭੋ!",
  HowFlirtToolWorks: "ਫਲਰਟ ਟੂਲ ਕੈਸੇ ਕੰਮ ਕਰਦਾ ਹੈ",
  HowFlirtToolWorksdes: "ਫਲਰਟ ਟੂਲ ਤੁਹਾਨੂੰ ਆਨਲਾਈਨ ਡੇਟਿੰਗ ਦੇ ਨਵੇਂ ਦਰਜੇ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ। ਫਲਰਟ ਟੂਲ ਦਾ ਆਖਰੀ ਨਿਰਮਾਣ AI ਸ਼ਖ਼ਸੀਅਤ MAZU ਤੁਹਾਨੂੰ 94% ਉਨ੍ਹਾਂ ਦੀ ਪੂਰੀ ਮੈਚ ਨਾਲ ਮੁੱਕਣ ਵਾਲਾ ਵਿਅਕਤੀ ਨਾਲ ਮਿਲਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰੇਗਾ",
  LEARNMORE: "ਹੋਰ ਜਾਣੋ",
  About: "ਬਾਰੇ",
  PressAndMedia: "ਪ੍ਰੈਸ ਅਤੇ ਮੀਡੀਆ",
  Blogs: "ਬਲੌਗ",
  LiveChat: "ਲਾਈਵ ਚੈਟ",
  ChatNow: "ਹੁਣ ਚੈਟ ਕਰੋ",
  SocialMedia: "ਸੋਸ਼ਲ ਮੀਡੀਆ",
  ContactUs: "ਸੰਪਰਕ ਕਰੋ",
  WelcometoFlirttool: "ਫਲਰਟ ਟੂਲ ਵਲੋਂ ਜੀ ਆਇਆ ਨੂੰ",
  OrCreateanAccount: "ਜਾਂ ਖਾਤਾ ਬਣਾਓ",
  UsernameorEmail: "ਯੂਜ਼ਰਨਾਮ ਜਾਂ ਈਮੇਲ",
  YourPassword: "ਤੁਹਾਡਾ ਪਾਸਵਰਡ",
  Forgotpassword: "ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ",
  Reset: "ਰੀਸੈਟ",
  IAGREE: "ਮੈਂ ਸ਼ਰਤਾਂ, ਨਿਯਮਾਂ ਅਤੇ ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਨਾਲ ਸਹਿਮਤ ਹਾਂ",
  Clickhereto: "ਸਾਡੀਆਂ ਸ਼ਰਤਾਂ ਨੂੰ ਵੇਖਣ ਲਈ ਇੱਥੇ ਕਲਿਕ ਕਰੋ",

  FIRSTNAME: "ਪਹਿਲਾ ਨਾਂ",
  LastName: "ਆਖਰੀ ਨਾਂ",
  Emailisnotvalid: "ਈਮੇਲ ਵੈਧ ਨਹੀਂ ਹੈ",
  Username: "ਯੂਜ਼ਰ ਨਾਂ",
  Yourpasswords: "ਤੁਹਾਡਾ ਪਾਸਵਰਡ",
  Passwordisnotvalid: "ਪਾਸਵਰਡ ਵੈਧ ਨਹੀਂ ਹੈ",
  Confirmpassword: "ਪਾਸਵਰਡ ਪੁਸ਼ਟੀ ਕਰੋ",
  Usernamemusthaveatleast: "ਯੂਜ਼ਰਨਾਮ ਵਿਚ ਘੱਟੋ ਘੱਟ",
  Alreadyhaveanaccount: "ਪਹਿਲਾਂ ਹੀ ਖਾਤਾ ਹੈ",
  LoginHere: "ਇੱਥੇ ਲਾਗਇਨ ਕਰੋ",

  starthourlypackage: "ਘੰਟੇ ਵਾਲਾ ਪੈਕੇਜ ਸ਼ੁਰੂ ਕਰੋ",

  SubmitReport: "ਰਿਪੋਰਟ ਪੇਸ਼ ਕਰੋ",
  Blockcon: "ਕੀ ਤੁਸੀਂ ਬਲਾਕ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
  Yes: "ਹਾਂ",
  No: "ਨਹੀਂ",
  reportreason: "ਇਸ ਰਿਪੋਰਟ ਲਈ ਆਪਣਾ ਕਾਰਨ ਦਾਖਲ ਕਰੋ",
  Visits: "ਦੌਰੇ",
  Photos: "ਤਸਵੀਰਾਂ",
  UnlockExclusive: "ਖਾਸ ਤਸਵੀਰਾਂ ਖੋਲੋ",
  UnlockNow: "ਹੁਣ ਖੋਲੋ",
  Personal: "ਨਿੱਜੀ",
  Ethnicity: "ਨਸਲ",
  BodyType: "ਬਾਡੀ ਕਿਸਮ",
  Gender: "ਲਿੰਗ",
  Height: "ਉਚਾਈ",
  Relationship: "ਸੰਬੰਧ",
  Work: "ਕੰਮ",
  Education: "ਸਿੱਖਿਆ",
  LIFESTYLE: "ਜੀਵਨ ਸ਼ੈਲੀ",
  LivesWith: "ਨਾਲ ਰਹਿੰਦਾ ਹੈ",
  Car: "ਕਾਰ",
  Religion: "ਧਰਮ",
  Smoke: "ਧੂਆਂ",
  Drink: "ਪੀਓ",
  Travel: "ਯਾਤਰਾ",
  PhoneNumber: "ਫੋਨ ਨੰਬਰ",
  GeneralSettings: "ਆਮ ਸੈਟਿੰਗ",
  SaveChanges: "ਬਦਲਾਓ ਸੰਭਾਲੋ",
  RemoveAccount: "ਖਾਤਾ ਹਟਾਓ",
  Yourprofile: "ਤੁਹਾਡਾ ਪ੍ਰੋਫਾਈਲ",
  AboutMe: "ਮੇਰੇ ਬਾਰੇ ਵਿੱਚ",
  Relationshipstatus: "ਸੰਬੰਧ ਦੀ ਸਥਿਤੀ",
  YourCountry: "ਤੁਹਾਡਾ ਦੇਸ਼",
  ChooseYourCountry: "ਆਪਣਾ ਦੇਸ਼ ਚੁਣੋ",
  Looks: "ਦਿਖਾਵਟ",
  Personality: "ਸਵਭਾਵ",
  Character: "ਚਰਿਤਰ",
  Children: "ਬੱਚੇ",
  Friends: "ਦੋਸਤ",
  Pets: "ਪਾਲਤੂ ਜਾਨਵਰ",
  LivingWith: "ਨਾਲ ਰਹਿਣਾ",
  MusicGenre: "ਸੰਗੀਤ ਕਿਸਮ",
  Dish: "ਡਿਸ਼",
  Song: "ਗੀਤ",
  Hobby: "ਸ਼ੌਕ",
  Book: "ਕਿਤਾਬ",
  Movie: "ਫ਼ਿਲਮ",
  ChangePassword: "ਪਾਸਵਰਡ ਬਦਲੋ",
  NewPassword: "ਨਵਾਂ ਪਾਸਵਰਡ",
  SETUPYOURDATINGPROFILE: "ਆਪਣਾ ਡੇਟਿੰਗ ਪ੍ਰੋਫਾਈਲ ਸੈਟ ਕਰੋ",
  uploadphoto: "ਆਪਣੀ ਪ੍ਰੋਫਾਈਲ ਤਸਵੀਰ ਸੈਟ ਕਰਨ ਲਈ ਫੋਟੋ ਅੱਪਲੋਡ ਕਰੋ",
  picnotupdated: "ਪ੍ਰੋਫਾਈਲ ਤਸਵੀਰ ਅੱਪਡੇਟ ਨਹੀਂ ਹੈ",
  pleaseupdatepicture: "ਜਾਰੀ ਰੱਖਣ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਪ੍ਰੋਫਾਈਲ ਤਸਵੀਰ ਅਪਲੋਡ ਕਰੋ",
  BirthYear: "ਜਨਮ ਸਾਲ",
  Date: "ਮਿਤੀ",
  Month: "ਮਹੀਨਾ",
  Day: "ਦਿਨ",
  SexualPreference: "ਲੈਂਗਿਕ ਪਸੰਦ",
  SexualPreferences: "ਤੁਹਾਡੀ ਲੈਂਗਿਕ ਪਸੰਦ",
  countrycode: "ਦੇਸ਼ ਕੋਡ",
  PhonNumber: "ਫੋਨ ਨੰਬਰ",
  nolikesyet: "ਤੁਹਾਡੇ ਕੋਲ ਹਾਲੇ ਤੱਕ ਕੋਈ ਪਸੰਦ ਨਹੀਂ ਹੈ",
  nomatches: "ਤੁਹਾਡੇ ਕੋਲ ਹਾਲੇ ਤੱਕ ਕੋਈ ਮੈਚ ਨਹੀਂ ਹੈ",
  likematches: "ਤੁਸੀਂ ਪਰੋਫਾਈਲਾਂ ਨੂੰ ਪਸੰਦ ਕਰਕੇ ਮੈਚ ਬਣਾ ਸਕਦੇ ਹੋ",
  terms: "ਸ਼ਰਤਾਂ",
  Privacy: "ਗੋਪਨੀ",
  imprint: "ਪ੍ਰਤੀਕਰਨ",
  Address: "ਪਤਾ"
},
JinChinesisch:{
  SomeRandomHeadingGoesHere: "今天免费加入",
  noflirtsrecharge: "您冇足夠嘅信用額",
  heygetflirts: "搵返啲情話",
  SEARCHINGFORNOTIFICAITON: "尋找通知",
  Newconversation: "新對話",
  MyPurchases: "我嘅購買",
  mypurchasedes: "喺度你會搵到你買嘅情話同套餐",
  mypurchacedess: "你暫時冇任何交易",
  resetlink: "輸入你嘅電子郵件地址，我哋會送你重設鏈接",
  ChatsConversation: "傾偈（對話）",
  message: "信息",
  likes: "喜歡",
  matches: "匹配",
  Liked: "已喜歡",
  Disliked: "不喜歡",
  JustVisitedYou: "剛剛拜訪你",
  LoadMore: "加載更多",
  FliterUsers: "過濾用戶",
  Youdonthaveanynotifications: "你冇任何通知",
  ActiveNow: "現正活躍",

  SignIn: "登入",
  FlirtToolNews: "情話工具新聞",
  Join: "加入",
  Free: "免費",
  Today: "今日",
  BasicPackage: "基本套餐",
  twohoursfreechatexperience: "兩小時免費聊天體驗",
  Tryitforfree: "試試免費",
  FlirtToolfor1Day: "一天情話工具",
  Gblobalfor1Day:"一天的翻转工具",

  RealChatFlatrate: "真實聊天固定費僅$2",
  Grabit: "抓住它",
  Createanaccount: "創建帳戶",
  orgettheapp: "或者下載應用程序",
  OVER1MILLIONUSERSWORLDWIDE: "全球超過100萬用戶",
  MatchingProfiles: "匹配檔案",
  UpcomingonFlirtTool: "情話工具即將推出",
  FindRealLifeWithFlirtTool: "用情話工具尋找真實生活！",
  HowFlirtToolWorks: "情話工具如何運作",
  HowFlirtToolWorksdes: "情話工具為您提供線上約會的新水平。情話工具的AI個性MAZU將幫助用戶與其94%的完美匹配相配",
  LEARNMORE: "了解更多",
  About: "關於",
  PressAndMedia: "新聞和媒體",
  Blogs: "博客",
  LiveChat: "即時聊天",
  ChatNow: "立即聊天",
  SocialMedia: "社交媒體",
  ContactUs: "聯繫我們",
  WelcometoFlirttool: "歡迎來到情話工具",
  OrCreateanAccount: "或者創建一個帳戶",
  UsernameorEmail: "用戶名或電子郵件",
  YourPassword: "你的密碼",
  Forgotpassword: "忘記密碼",
  Reset: "重設",
  IAGREE: "我同意條款、條件和隱私政策",
  Clickhereto: "點擊這裡查看我們的條款",

  FIRSTNAME: "名字",
  LastName: "姓氏",
  Emailisnotvalid: "電子郵件無效",
  Username: "用戶名",
  Yourpasswords: "你的密碼",
  Passwordisnotvalid: "密碼無效",
  Confirmpassword: "確認密碼",
  Usernamemusthaveatleast: "用戶名至少必須",
  Alreadyhaveanaccount: "已經有一個帳戶",
  LoginHere: "在這裡登錄",

  starthourlypackage: "開始每小時套餐",

  SubmitReport: "提交報告",
  Blockcon: "你確定要封鎖",
  Yes: "是",
  No: "不",
  reportreason: "輸入你對此報告的原因",
  Visits: "訪問次數",
  Photos: "照片",
  UnlockExclusive: "解鎖獨家圖片",
  UnlockNow: "立即解鎖",
  Personal: "個人",
  Ethnicity: "種族",
  BodyType: "體型",
  Gender: "性別",
  Height: "身高",
  Relationship: "關係",
  Work: "工作",
  Education: "教育",
  LIFESTYLE: "生活方式",
  LivesWith: "居住",
  Car: "汽車",
  Religion: "宗教",
  Smoke: "吸煙",
  Drink: "喝酒",
  Travel: "旅行",
  PhoneNumber: "電話號碼",
  GeneralSettings: "通用設置",
  SaveChanges: "保存更改",
  RemoveAccount: "刪除帳戶",
  Yourprofile: "您的個人資料",
  AboutMe: "關於我",
  Relationshipstatus: "關係狀態",
  YourCountry: "你的國家",
  ChooseYourCountry: "選擇你的國家",
  Looks: "外觀",
  Personality: "個性",
  Character: "品格",
  Children: "孩子",
  Friends: "朋友",
  Pets: "寵物",
  LivingWith: "與誰同住",
  MusicGenre: "音樂類型",
  Dish: "菜",
  Song: "歌曲",
  Hobby: "愛好",
  Book: "書",
  Movie: "電影",
  ChangePassword: "修改密碼",
  NewPassword: "新密碼",
  SETUPYOURDATINGPROFILE: "設置你的約會個人資料",
  uploadphoto: "上傳照片以設置您的個人資料照片",
  picnotupdated: "個人資料照片未更新",
  pleaseupdatepicture: "請上傳個人資料照片以繼續",
  BirthYear: "出生年份",
  Date: "日期",
  Month: "月份",
  Day: "天",
  SexualPreference: "性取向",
  SexualPreferences: "您的性取向",
  countrycode: "國家代碼",
  PhonNumber: "電話號碼",
  nolikesyet: "你還沒有任何喜歡",
  nomatches: "你還沒有任何匹配",
  likematches: "您可以通過喜歡他們來開始與用戶配對",
  terms: "條款",
  Privacy: "隱私",
  imprint: "印記",
  Address: "地址"
},
Yoruba:{
  SomeRandomHeadingGoesHere: "Se Awo-Free Lonii",
  noflirtsrecharge: "Ko si asejo ti o da lori eebu re",
  heygetflirts: "Gba eebu",
  SEARCHINGFORNOTIFICAITON: "Wa fun Efidaviti",
  Newconversation: "Iroyin Titun",
  MyPurchases: "Owo Awon Nọmba Mi",
  mypurchasedes: "Nibi ti o ba wo, o yio ri awọn eebu ati awọn aami ti o ti di",
  mypurchacedess: "Ko si iroyin ti o ba",
  resetlink: "Firọ lati fi Imei rẹ, a yoo fi ikọ gbigba rẹ siiwọ",

  ChatsConversation: "Awọn iroyin (Iroyin)",
  message: "Meji",
  likes: "Njẹ",
  matches: "Awọn itọsọna",
  Liked: "T'ọsan",
  Disliked: "Ko t'ọsan",
  JustVisitedYou: "Níbẹ ti mo pa o",
  LoadMore: "Pọ",
  FliterUsers: "Sọ awọn olumọ",
  Youdonthaveanynotifications: "Ko si iroyin kan sọ fun rẹ",
  ActiveNow: "Nọmba ẹnikan",

  SignIn: "Mọ",
  FlirtToolNews: "Iroyin FlirtTool",
  Join: "Pẹlu",
  Free: "Iye",
  Today: "Ǹĺèyìn",
  BasicPackage: "Aṣa alaafia",
  twohoursfreechatexperience: "Iṣọkan itọsọna alaye kan",
  Tryitforfree: "Ṣiyan fun iye",
  FlirtToolfor1Day: "FlirtTool fun 1 Ojo",
  Gblobalfor1Day:"GlobalChat fun 1 Ojo",

  RealChatFlatrate: "Real Chat Flatrate N̄$2 N̄$2 lẹhinna",
  Grabit: "Pọ si",
  Createanaccount: "Ṣẹ alaye",
  orgettheapp: "tabi ni gbe app",
  OVER1MILLIONUSERSWORLDWIDE: "Ni ayika ọkan wa laarin awọn olumọ 1",
  MatchingProfiles: "Awọn iroyin ti a ko awọn awọn",
  UpcomingonFlirtTool: "Atilẹyin nipa FlirtTool",
  FindRealLifeWithFlirtTool: "Wọn fun niwaju nla pẹlu FlirtTool!",
  HowFlirtToolWorks: "Bẹrẹ nipa bii FlirtTool Mọ",
  HowFlirtToolWorksdes: "FlirtTool ti pese awọn iroyin oke ati iroyin lati gba wa pade oju opolopo ti wọn ti 94% ti wọn ti a tọ",
  LEARNMORE: "Kọ Lọwọ",
  About: "Ńkọ",
  PressAndMedia: "ẹran ati ẹrọ iṣọkan",
  Blogs: "Blogs",
  LiveChat: "Live Chat",
  ChatNow: "Iroyin Loni",
  SocialMedia: "Iroyin Iroyin",
  ContactUs: "N wa",
  WelcometoFlirttool: "Ṣe alaye si Flirttool",
  OrCreateanAccount: "Tabi ṣe alaye kan",
  UsernameorEmail: "Orukọ olumọ tabi imeeli",
  YourPassword: "ẹtọ rẹ",
  Forgotpassword: "Kini iṣẹtọ rẹ",
  Reset: "Se Aṣẹgun",
  IAGREE: "Mo ti gba awọn alaye, awọn ọtọ ati awọn iwọn ìtọsọna",
  Clickhereto: "Kọwọ iduro wa fun awọn alaye wa",

  FIRSTNAME: "Oruko mi",
  LastName: "Orukọ",
  Emailisnotvalid: "Imeeli ko da lori ise",
  Username: "Orukọ olumọ",
  Yourpasswords: "ẹtọ rẹ",
  Passwordisnotvalid: "Ẹtọ ko da lori ise",
  Confirmpassword: "Se aṣẹgun iṣẹtọ",
  Usernamemusthaveatleast: "Orukọ olumọ gbogbo yi ni iru",
  Alreadyhaveanaccount: "Emi ni alaye kan tó ṣe",
  LoginHere: "Mọ n'iwe yii",

  starthourlypackage: "Bẹrẹ ibere ti a n ṣe aṣojọ kan",

  SubmitReport: "Ṣi iroyin",
  Blockcon: "Ṣe o sure fun ra wọn si",
  Yes: "Bẹẹni",
  No: "Kò",
  reportreason: "Tẹ ṣẹ idaani fun iroyin yii",
  Visits: "Igbeyawo",
  Photos: "Awọn eto",
  UnlockExclusive: "Fọwọsi Aamiiran Nla",
  UnlockNow: "Fọwọsi Lẹẹkan",
  Personal: "Rẹ",
  Ethnicity: "Ẹgbẹ",
  BodyType: "Ile-ẹkọ",
  Gender: "Irò",
  Height: "Ọya",
  Relationship: "Igbeyawo",
  Work: "Iṣẹ",
  Education: "Iṣẹ",
  LIFESTYLE: "Iṣẹ lọ",
  LivesWith: "N wọ ile pẹlu",
  Car: "Gari",
  Religion: "Igbagbo",
  Smoke: "Gbo",
  Drink: "Mu",
  Travel: "Ṣe ikoko",
  PhoneNumber: "Nọmba foonu",
  GeneralSettings: "Aṣọ laarin aṣọ",
  SaveChanges: "Tun itọwọpọ wa",
  RemoveAccount: "Yẹ alaye",
  Yourprofile: "Alaye rẹ",
  AboutMe: "Nípa mi",
  Relationshipstatus: "Ise ọna",
  YourCountry: "Orile-ede rẹ",
  ChooseYourCountry: "Fọ orile-ede rẹ",
  Looks: "Yara",
  Personality: "Igbagbọ",
  Character: "Itan",
  Children: "Awọn omo",
  Friends: "Awọn ọrẹ",
  Pets: "Awọn eniyan ara",
  LivingWith: "N wọ ile pẹlu",
  MusicGenre: "Ẹkọ musin",
  Dish: "Awọn aami",
  Song: "Orin",
  Hobby: "Amusements",
  Book: "Iwe",
  Movie: "Aami",
  ChangePassword: "Tun itọwọpọ iṣẹtọ",
  NewPassword: "Aṣẹgun Tuntun",
  SETUPYOURDATINGPROFILE: "WAPE ẸYI NIPA IPINLE AWON APEJUWE RERE",
  uploadphoto: "Yi eto lori lati wapo iwe eto re",
  picnotupdated: "Eyi ko ya lati tunto",
  pleaseupdatepicture: "Jọwọ wapo eto lati jẹun",
  BirthYear: "Ọdún Ajọ ti o",
  Date: "Ọjọ",
  Month: "Osù",
  Day: "Ọjọ",
  SexualPreference: "Igbeyawo ẹgbẹ",
  SexualPreferences: "Igbeyawo rẹ",
  countrycode: "Alaye igbẹsẹ",
  PhonNumber: "Nọmba foonu",
  nolikesyet: "Ko si itọsọna kan lọwọ",
  nomatches: "Ko si awọn itọsọna kan lọwọ",
  likematches: "O le bẹrẹ pẹlu awọn aami nipa feran wọn",
  terms: "Awọn iroyin",
  Privacy: "Privacy",
  imprint: "Imprint",
  Address: "Eso Adirẹsì"
},

Hakka:{
  SomeRandomHeadingGoesHere: "今日免費加入",
  noflirtsrecharge: "你冇足夠嘅信用額",
  heygetflirts: "攞返啲情話",
  SEARCHINGFORNOTIFICAITON: "搵通知",
  Newconversation: "新對話",
  MyPurchases: "我嘅買",
  mypurchasedes: "喺度你會搵到你買嘅情話同套餐",
  mypurchacedess: "你暫時冇任何交易",
  resetlink: "輸入你嘅電子郵件地址，我哋會送你重設鏈接",

  ChatsConversation: "傾偈（對話）",
  message: "信息",
  likes: "喜歡",
  matches: "對手",
  Liked: "已喜歡",
  Disliked: "嫌惡",
  JustVisitedYou: "剛拜你",
  LoadMore: "加載多啲",
  FliterUsers: "過濾用戶",
  Youdonthaveanynotifications: "你冇任何通知",
  ActiveNow: "依家活躍",

  SignIn: "登入",
  FlirtToolNews: "情話工具新聞",
  Join: "加入",
  Free: "免費",
  Today: "今日",
  BasicPackage: "基本套餐",
  twohoursfreechatexperience: "兩小時免費傾偈體驗",
  Tryitforfree: "試吓免費",
  FlirtToolfor1Day: "一日情話工具",
  Gblobalfor1Day:"一日嘅翻轉工具",

  RealChatFlatrate: "真實傾偈固定價N̄$2",
  Grabit: "攞嚟",
  Createanaccount: "註冊帳戶",
  orgettheapp: "或者下載應用",
  OVER1MILLIONUSERSWORLDWIDE: "全世界超過一百萬用戶",
  MatchingProfiles: "匹配檔案",
  UpcomingonFlirtTool: "即將推出",
  FindRealLifeWithFlirtTool: "用情話工具尋找真實生活！",
  HowFlirtToolWorks: "情話工具點樣運作",
  HowFlirtToolWorksdes: "情話工具會透過人工智能幫你搵到你嘅94%完美對手",
  LEARNMORE: "知多啲",
  About: "關於",
  PressAndMedia: "新聞同媒體",
  Blogs: "博客",
  LiveChat: "即時傾偈",
  ChatNow: "即時傾偈",
  SocialMedia: "社交媒體",
  ContactUs: "聯絡我哋",
  WelcometoFlirttool: "歡迎黎用情話工具",
  OrCreateanAccount: "或者註冊帳戶",
  UsernameorEmail: "用戶名或者電郵",
  YourPassword: "你嘅密碼",
  Forgotpassword: "忘記密碼",
  Reset: "重設",
  IAGREE: "我同意條款、條件同私隱政策",
  Clickhereto: "按呢度睇我哋嘅條款",

  FIRSTNAME: "名",
  LastName: "姓",
  Emailisnotvalid: "電郵無效",
  Username: "用戶名",
  Yourpasswords: "你嘅密碼",
  Passwordisnotvalid: "密碼無效",
  Confirmpassword: "確認密碼",
  Usernamemusthaveatleast: "用戶名至少要",
  Alreadyhaveanaccount: "已經有帳戶",
  LoginHere: "喺呢度登入",

  starthourlypackage: "開始時鐘套餐",

  SubmitReport: "提交報告",
  Blockcon: "你確定要封鎖",
  Yes: "係",
  No: "唔係",
  reportreason: "填寫報告嘅原因",
  Visits: "訪問次數",
  Photos: "相片",
  UnlockExclusive: "解鎖獨家圖片",
  UnlockNow: "立即解鎖",
  Personal: "個人",
  Ethnicity: "種族",
  BodyType: "體型",
  Gender: "性別",
  Height: "高度",
  Relationship: "關係",
  Work: "工作",
  Education: "教育",
  LIFESTYLE: "生活方式",
  LivesWith: "同住",
  Car: "汽車",
  Religion: "宗教",
  Smoke: "吸煙",
  Drink: "飲酒",
  Travel: "旅遊",
  PhoneNumber: "電話號碼",
  GeneralSettings: "通用設置",
  SaveChanges: "保存更改",
  RemoveAccount: "刪除帳戶",
  Yourprofile: "你嘅個人檔案",
  AboutMe: "關於我",
  Relationshipstatus: "關係狀態",
  YourCountry: "你嘅國家",
  ChooseYourCountry: "揀你嘅國家",
  Looks: "外貌",
  Personality: "性格",
  Character: "特性",
  Children: "兒女",
  Friends: "朋友",
  Pets: "寵物",
  LivingWith: "同住",
  MusicGenre: "音樂類型",
  Dish: "菜餚",
  Song: "歌曲",
  Hobby: "嗜好",
  Book: "書",
  Movie: "電影",
  ChangePassword: "改密碼",
  NewPassword: "新密碼",
  SETUPYOURDATINGPROFILE: "設置你嘅約會檔案",
  uploadphoto: "上載相片設置你嘅個人檔案相片",
  picnotupdated: "個人檔案相片未更新",
  pleaseupdatepicture: "請上載個人檔案相片以繼續",
  BirthYear: "出世年份",
  Date: "日期",
  Month: "月份",
  Day: "日",
  SexualPreference: "性傾向",
  SexualPreferences: "你嘅性傾向",
  countrycode: "國家代碼",
  PhonNumber: "電話號碼",
  nolikesyet: "你仲未有任何鐘意",
  nomatches: "你仲未有任何配對",
  likematches: "你可以開始鐘意用戶同佢哋配對",
  terms: "條款",
  Privacy: "私隱",
  imprint: "印記",
  Address: "地址"
},

// Burmesisch:,
// Sindhi

// 	Sindhi
Sindhi:{
  SomeRandomHeadingGoesHere: "ڄ مفت ڳالڻ جوءِ",
  noflirtsrecharge: "توهان کي ڪافي ڪريڊٽس نٿا آهن",
  heygetflirts: "پٽريا گهرائو",
  SEARCHINGFORNOTIFICAITON: "اطلاع جي خياڻ",
  Newconversation: "نون گپ",
  MyPurchases: "منهنجي خريدي",
  mypurchasedes: "ادا ڪريڪر ۽ پيڪيجز جو سرڪار ڪريو.",
  mypurchacedess: "توهان کي اڪيڊن جو کوئي ٽرانزيڪشن نہين",
  resetlink: "توهان جو اي ميل ايڊريس درج ڪريو ۽ اسان توهان کي ٽڪ ڪري سنديو",
  ChatsConversation: "گپ گپ (گپ شروع ڪريو)",
  message: "پيغام",
  likes: "پسند کردا",
  matches: "ميچز",
  Liked: "پسند کيا",
  Disliked: "ناپسند",
  JustVisitedYou: "فقط توهان کي وزيت ڪيو",
  LoadMore: "وڌيڪ لوڊ ڪريو",
  FliterUsers: "فلٽر ٽول گهرائيو",
  Youdonthaveanynotifications: "توهان کي ڪو اطلاعات نہين",
  ActiveNow: "اڪتيو ٿيو",
  SignIn: "سائن ان",
  FlirtToolNews: "فلرٽٽول نيوز",
  Join: "شامل ڪريو",
  Free: "مفت",
  Today: "اڄ",
  BasicPackage: "بنيادي پيڪيج",
  twohoursfreechatexperience: "2 گهٽن مفت گپ",
  Tryitforfree: "مفت آزمائيو",
  FlirtToolfor1Day: "1 ڏينهن لاءِ فلرٽ ٽول",
  Gblobalfor1Day:"هڪ ڏينهن جي فلائيٽ ٽول",

  RealChatFlatrate: "حقيقي گپ فليٽريٽ صرف $ 2",
  Grabit: "اُنھائيو",
  Createanaccount: "اکاؤنٽ بنايو",
  orgettheapp: "يا اپليڪيشن حاصل ڪريو",
  OVER1MILLIONUSERSWORLDWIDE: "1 ملين سان وڏو صارفان طرفدار جهڙو",
  MatchingProfiles: "ميل ٻڪ ڪاڻا",
  UpcomingonFlirtTool: "فلرٽٽول ۾ آئندو",
  FindRealLifeWithFlirtTool: "فلرٽٽول سان حقيقي زندگي ڪجهه",
  HowFlirtToolWorks: "فلرٽٽول ڪيسے ڪام ڪري",
  HowFlirtToolWorksdes: "فلرٽٽول توهان کي آن لائين ڊيٽنگ جي نويٽيو انہين نئين ٻملاءِ کيو. فلرٽٽول جو انتہائي مخلوق، اي آئي مظوئو جي اطلاع سان گهرائيو جي مدد ڪندي ھو ته، صارفن کي ڪري 94% انھن جي مکمل ميل ٻملاءِ کيو",
  LEARNMORE: "وڌيڪ سيڏو",
  About: "بابت",
  PressAndMedia: "پريس ۽ ميڊيا",
  Blogs: "بلاگز",
  LiveChat: "لاءِ گفتگو",
  ChatNow: "اھيون گپ",
  SocialMedia: "سوشل ميڊيا",
  ContactUs: "سان رابطو",
  WelcometoFlirttool: "فلرٽٽول کي خوش آمديد",
  OrCreateanAccount: "يا اکاؤنٽ بنايو",
  UsernameorEmail: "صارف نالو ڪيا اهڙو",
  YourPassword: "توهان جو پاسورڊ",
  Forgotpassword: "پاسورڊ بولا",
  Reset: "ري سيٽ",
  IAGREE: "مان مچڻ ۽ شرايط ا۽ پرائيويسي پاليسي",
  Clickhereto: "اها ڪلڪ ڪريو تہ",

  FIRSTNAME: "پهرست جو نالو",
  LastName: "آخري نالو",
  Emailisnotvalid: "اي ميل درست نہين",
  Username: "صارف نالو",
  Yourpasswords: "توهان جو پاسورڊ",
  Passwordisnotvalid: "پاسورڊ درست نہين",
  Confirmpassword: "پاسورڊ ڊيٽا ضرور",
  Usernamemusthaveatleast: "صارف نالو کم از کم ۽ حروف واپسي",
  Alreadyhaveanaccount: "پهريا اکاؤنٽ ھو",
  LoginHere: "اها لاگ ان کي",

  starthourlypackage: "گهڻي جي پيڪيج چالو ڪريو",
  SubmitReport: "رپورٽ جمع ڪريو",
  Blockcon: "کيا آپ يقيني ڪريو بلاڪ ڪريو؟",
  Yes: "ها",
  No: "نه",
  reportreason: "پاسي ڪيا وجهو وڃيو اڳو",
  Visits: "وزيت",
  Photos: "عڪسون",
  UnlockExclusive: "ايڪسس ڪيون واڌي",
  UnlockNow: "حال ڪولو",
  Personal: "ذاتي",
  Ethnicity: "ايٿنڪيٽيٽي",
  BodyType: "جسماني قسم",
  Gender: "جنس",
  Height: "اونھ",
  Relationship: "تعلق",
  Work: "کام",
  Education: "تعليم",
  LIFESTYLE: "جي لائف اسٽائل",
  LivesWith: "سان ويسي",
  Car: "گاڑي",
  Religion: "مذهب",
  Smoke: "ڪور سگريٽ",
  Drink: "پئيو",
  Travel: "سفر",
  PhoneNumber: "فون نمبر",
  GeneralSettings: "عام ترتيبات",
  SaveChanges: "تبديليون وڃيندا",
  RemoveAccount: "اکاؤنٽ ڊاهيو",
  Yourprofile: "توهان جو پروفائل",
  AboutMe: "منهنجي بابت",
  Relationshipstatus: "تعلق جو حالت",
  YourCountry: "توهان جو ملڪ",
  ChooseYourCountry: "توهان جو ملڪ انتخاب ڪريو",
  Looks: "شگاف",
  Personality: "شخصيت",
  Character: "خاصيت",
  Children: "بچو",
  Friends: "دوست",
  Pets: "پالتو جانور",
  LivingWith: "سان ويسي",
  MusicGenre: "موسيقي جي قسم",
  Dish: "ڊش",
  Song: "گانو",
  Hobby: "ڪرتوت",
  Book: "ڪتاب",
  Movie: "مووي",
  ChangePassword: "پاسورڊ ڪيون وڌيو",
  NewPassword: "نئون پاسورڊ",
  SETUPYOURDATINGPROFILE: "توهان جو ڊيٽنگ پروفائل سيٽ اپ ڪريو",
  uploadphoto: "عڪس ڊائونلوڊ ڪرڻ جي لاءِ پروفائل پچر",
  picnotupdated: "پروفائل عڪس ڊائونلوڊ نہين ڪيا",
  pleaseupdatepicture: "توهان کي پروفائل عڪس ڊائونلوڊ ڪرڻي لاءِ",
  BirthYear: "پيدائش جو سال",
  Date: "تاريخ",
  Month: "مهينو",
  Day: "ڏينهن",
  SexualPreference: "جنسي مرض",
  SexualPreferences: "توهان جو جنسي مرض کيا آهي؟",
  countrycode: "ملڪ جي ڪوڊ",
  PhonNumber: "فون نمبر",
  nolikesyet: "توهان کي اڪيڊن پسند ڪردا نہين",
  nomatches: "توهان کي اڪيڊن ميچز نہين",
  likematches: "توهان کي پروفائل کي پسند ڪرڻ جي شروعات ڪري سگهي ويو",
  terms: "شراطون",
  Privacy: "رازداري",
  imprint: "مٿي",
  Address: "پتو"
},

Amharisch:{
  Register:"ምዝገባ",
  SomeRandomHeadingGoesHere: "አሁን በነፃ ይቀላቀል።",
  noflirtsrecharge: "እርስዎን በማከማቻ አንድ መነጠል የሚችል የማንኛውንም ክፍያ የለም",
  heygetflirts: "ፍልስ ይጠብቁ",
  SEARCHINGFORNOTIFICAITON: "ማስታወሻ ለ",
  Newconversation: "አዲስ የውይይት",
  MyPurchases: "የተመደበው እርምጃዎች",
  mypurchasedes: "እና ሎክ እንዳትገኙት ያለውን ፍልሞች እና ፕሮቪዎች ይከፈታል።",
  mypurchacedess: "እናንተ አንዱንም ደምበኛ የእቃ እንቅስቃሴ አይልም",
  resetlink: "እስከ እስከዚህ ያውጣሉት እስከ እስከዚያ እስከዚያ እድል ይላኩ",
  ChatsConversation: "የካርታ እይታ",
  message: "መልዕክት",
  likes: "ተወዳጆች",
  matches: "አስተያየቶች",
  Liked: "ተወዳጆች",
  Disliked: "አልተወዳጆች",
  JustVisitedYou: "ቀኑ በለው።",
  LoadMore: "ተጨማሪ አንዳች አስወግድ",
  FliterUsers: "ተረጋግጧል ተጠቃሚዎች",
  Youdonthaveanynotifications: "ምንም ማሳወቂያዎች አልተያዙም",
  ActiveNow: "አካባቢ አሁን",
  SignIn: "ማስታወሻ አስተውሉ",
  FlirtToolNews: "የፍልስ መረጃዎች",
  Join: "እንዳልኩ",
  Free: "ነፃ",
  Today: "ዛሬ",
  BasicPackage: "በመሰከረም ፕሮች",
  twohoursfreechatexperience: "ሁለት ሰዓቶች በነፃ የእንቅስቃሴ",
  Tryitforfree: "በነፃ ይጠብቁ",
  FlirtToolfor1Day: "አንድ ቀን የፍልስ ተከፋፍ",
  RealChatFlatrate: "የስልክ ማእከል ፍርስት፡ $2",
  Gblobalfor1Day:"Global Chat for one day",

  Grabit: "በእንቅስቃሴ ይጠብቃል",
  Createanaccount: "አካውንት ይፍጠሩ",
  orgettheapp: "ወደ መተግበሪያ",
  OVER1MILLIONUSERSWORLDWIDE: "በዓለም አቀፍ በሮች በሩቅ",
  MatchingProfiles: "ለማስቀመጥ የሚረዱ",
  UpcomingonFlirtTool: "ከልደት ይላቸዋል",
  FindRealLifeWithFlirtTool: "በፍልስ ተከፋፍ ተለይተው ያስከብሩ!",
  HowFlirtToolWorks: "እንዴት ሆነ ለፍልስ ተከፋፍ እንችላለን",
  HowFlirtToolWorksdes: "ፍልስ ተከፋፍን ለመተንተን ያለው አስተያየት MAZU ከየት ያለው ስራ አንድነት 94% በመሆኑ በተለያዩ በተከፋፉ አይኖር",
  LEARNMORE: "ተጨማሪ ይሁኑ",
  About: "ስለ",
  PressAndMedia: "ወጣቶች እና ሚዲያ",
  Blogs: "ቦሌቶች",
  LiveChat: "ሐሳቦችን በማዳን ተከታታይ",
  ChatNow: "አሁን ይላኩ",
  SocialMedia: "የማህበረሰብ መደብና መረጃ",
  ContactUs: "አስተያየት ይፈልጋሉ",
  WelcometoFlirttool: "እንኳን ደህና መጡ እናቴትን",
  OrCreateanAccount: "ወደ አካውንት ለመፍጠር",
  UsernameorEmail: "የመጠቀሚያ ስም ወይም ኢሜይል",
  YourPassword: "የምስጢርህ",
  Forgotpassword: "የምስጢርህን ማስታወሻን ዘምን",
  Reset: "የተወካ ማስታወሻ",
  IAGREE: "ትክክለኛነትንና ግልጽነትን ተመልከቱ እና ፍላሽን ተሰብስበዋል",
  Clickhereto: "ለማወቅ ይጫኑ",
  FIRSTNAME: "መጀመሪያ ስም",
  LastName: "የአያት ስም",
  Emailisnotvalid: "ኢሜይል አይነት ነው",
  Username: "የመጠቀሚያ ስም",
  Yourpasswords: "የምስጢርህ",
  Passwordisnotvalid: "የምስጢርህ አይነት ነው",
  Confirmpassword: "ምስጢርህ ይረጋጋል",
  Usernamemusthaveatleast: "መጠቀሚያ ስምን በቀኝ ልክ እንደምንለው",
  Alreadyhaveanaccount: "አካውንት አሁን አለ",
  LoginHere: "እንደገና በዚህ ምግብ ለመገንባት",
  starthourlypackage: "ቀንበር አካባቢ ለጀምረው",
  SubmitReport: "ማሳወቂያ ይላኩ",
  Blockcon: "እስከ እስከዚህ እስከዚያ ይከፍላል",
  Yes: "አዎ",
  No: "አይ",
  reportreason: "አካባቢው የሚከፍለውን አሳዛኝነት ያስገቡ",
  Visits: "ገበያዎች",
  Photos: "ፎቶዎች",
  UnlockExclusive: "የሚታይ ምስሎችን አክል",
  UnlockNow: "አክል አሁን",
  Personal: "ግልብ",
  Ethnicity: "ትውልድ",
  BodyType: "የታካል ኮከቦች",
  Gender: "ጾታ",
  Height: "ቁመት",
  Relationship: "የግብ እና የሴት መብት",
  Work: "ስራ",
  Education: "ትምህርት",
  LIFESTYLE: "ህዝብ ዘዴ",
  LivesWith: "በማንኛውም እድሜ አለው",
  Car: "መኪና",
  Religion: "ሃይማኖት",
  Smoke: "መስማት",
  Drink: "መጠጣት",
  Travel: "ጉዞ",
  PhoneNumber: "ስልክ ቁጥር",
  GeneralSettings: "አጠቃላይ ቅንብሮች",
  SaveChanges: "ለማስቀመጥ አስቀምጥ",
  RemoveAccount: "አካውንት ይወጣ",
  Yourprofile: "የገበያ መግለጫህ",
  AboutMe: "ስለ እኔ",
  Relationshipstatus: "የግብ ሁኔታ",
  YourCountry: "ከሚባሉት ሀገር አገር",
  ChooseYourCountry: "ከሚባሉት ሀገር ይምረጡ",
  Looks: "የግል አለመደብ",
  Personality: "ተግባር",
  Character: "ልዩ እና በጎ ሀንብ",
  Children: "ልዩነት",
  Friends: "መረጃዎች",
  Pets: "የእንጨት አክስቶች",
  LivingWith: "በማንኛውም እድሜ",
  MusicGenre: "ሙዚቃዎች ቅንብሮች",
  Dish: "የቅርጫ አበላም",
  Song: "የሙዚቃ መልዕክት",
  Hobby: "ህንፃ",
  Book: "መጽሐፍ",
  Movie: "ፊልም",
  ChangePassword: "የምስጢርህን ቀረበ",
  NewPassword: "አዲስ የምስጢርህ",

  SETUPYOURDATINGPROFILE: "የውይይት ስም ይጠቁ",
  uploadphoto: "ስዕል እንደገና ይሞክሩ",
  picnotupdated: "ስዕል አይዞአል",
  pleaseupdatepicture: "ስዕልዎን ያውቃሉ እናመሰግናለን",
  BirthYear: "የልደት ዓመት",
  Date: "ቀን",
  Month: "ወር",
  Day: "ቀኑ",
  SexualPreference: "ፍቅር ማድረግ",
  SexualPreferences: "ምርመራውን ያግዛሉ",
  countrycode: "የሀገር ኮድ",
  PhonNumber: "ስልክ ቁጥር",
  nolikesyet: "ምንም ተወዳጆች የለም",
  nomatches: "ምንም መግብያ የለም",
  likematches: "ተወዳጆችን በመግብያ መጠቀም ይጀምራል",
  terms: "ርቀት",
  Privacy: "ግላግሎት",
  imprint: "ዝርዝር",
  Address: "አድራሻ"
},
// Nepali
ne: {
  SomeRandomHeadingGoesHere: "आज नि: शुल्क समेत जोडिनुहोस्",
  noflirtsrecharge: "तपाईंलाई पर्याप्त क्रेडिट छैन",
  heygetflirts: "फ्लर्टहरू प्राप्त गर्नुहोस्",
  SEARCHINGFORNOTIFICAITON: "सूचना खोजी गर्दै",
  Newconversation: "नयाँ बार्तावार",
  MyPurchases: "मेरो किनमेलहरू",
  mypurchasedes: "यहाँ तपाईंले किनेको फ्लर्ट र प्याकेजहरू प्राप्त गर्नुहोस्।",
  mypurchacedess: "तपाईंको कुनै लेनदेन अहिले सम्म छैन",
  resetlink: "तपाईंको ई-मेल ठेगाना लेख्नुहोस् र हामी तपाईंलाई तपाईंको रिसेट लिंक पठाउनेछौं",
  // transaction

  ChatsConversation: "च्याट (बार्तावार)",
  message: "संदेश",
  likes: "लाइकहरू",
  matches: "मिलानहरू",
  Liked: "मन पराएको",
  Disliked: "नापसन्द",
  JustVisitedYou: "हालै तपाईंलाई भेटेको",
  LoadMore: "अधिक लोड गर्नुहोस्",
  FliterUsers: "फिल्टर गर्ने प्रयोगकर्ताहरू",
  Youdonthaveanynotifications: "तपाईंको कुनै सूचना छैन",
  ActiveNow: "अब्यालिक",

  // landing
  SignIn: "साइन इन",
  FlirtToolNews: "फ्लर्टटुल समाचार",
  Join: "सामिल हुनुहोस्",
  Free: "नि: शुल्क",
  Today: "आज",
  BasicPackage: "मौलिक प्याकेज",
  twohoursfreechatexperience: "२ घण्टा नि: शुल्क च्याट अनुभव",
  Tryitforfree: "नि: शुल्क कोसी",
  FlirtToolfor1Day: "फ्लर्टटुल एक दिनका लागि",
  Gblobalfor1Day:"Global Chat for one day",

  RealChatFlatrate: "वास्तविक च्याट फ्ल्याट रेट मात्र $२",
  Grabit: "ग्रब गर",
  Createanaccount: "खाता बनाउनुहोस्",
  orgettheapp: "वा एप्लिकेशन प्राप्त गर्नुहोस्",
  OVER1MILLIONUSERSWORLDWIDE: "विश्वभरी अधिकतम १ मिलियन प्रयोगकर्ता",
  MatchingProfiles: "मिलानहरूको प्रोफाइलहरू",
  UpcomingonFlirtTool: "फ्लर्टटुलमा आगामी",
  FindRealLifeWithFlirtTool: "फ्लर्टटुलसँग वास्तविक जीवन खोज।",
  HowFlirtToolWorks: "फ्लर्टटुल कसरी काम गर्दछ",
  HowFlirtToolWorksdes: "फ्लर्टटुलले तपाईंलाई अनलाइन मिलनको नयाँ स्तरहरू प्रदान गर्दछ। फ्लर्टटुलको अन्तिम निर्माण एम् ए जेड यू भावनात्मक व्यक्तित्व माजुको प्रमुख रचनात्मक जन्म युक्ति विशेषज्ञ हो, जसले प्रयोगकर्तालाई कसैको योग्य मिल्ने ९४% उनीहरूको पूर्ण मिलानसँग जोडनेछ",
  LEARNMORE: "अधिक जान्नुहोस्",
  About: "बारेमा",
  PressAndMedia: "प्रेस र मिडिया",
  Blogs: "ब्लगहरू",
  LiveChat: "लाइभ च्याट",
  ChatNow: "हालै च्याट गर्नुहोस्",
  SocialMedia: "सामाजिक मिडिया",
  ContactUs: "सम्पर्क गर्नुहोस्",
  WelcometoFlirttool: "फ्लर्टटुलमा स्वागत छ",
  // end of landing

  // login
  OrCreateanAccount: "वा खाता बनाउनुहोस्",
  UsernameorEmail: "प्रयोगकर्ता नाम वा ई-मेल",
  YourPassword: "तपाईंको पासवर्ड",
  Forgotpassword: "पासवर्ड भुल्नु भयो",
  Reset: "रिसेट",
  IAGREE: "म तपाईंलाई विधि, शर्तहरू र गोपनीयता नीति संरक्षित छ मान्नु छ",
  Clickhereto: "हाम्रा अवधिहरू हेर्नको लागि यहाँ क्लिक गर्नुहोस्",

  // login

  // register
  FIRSTNAME: "पहिलो नाम",
  LastName: "थर",
  Emailisnotvalid: "ई-मेल मान्य छैन",
  Username: "प्रयोगकर्ता नाम",
  Yourpasswords: "तपाईंको पासवर्डहरू",
  Passwordisnotvalid: "पासवर्ड मान्य छैन",
  Confirmpassword: "पासवर्ड पुष्टि गर्नुहोस्",
  Usernamemusthaveatleast: "प्रयोगकर्ताको नाम कम्तिमा हुनुपर्छ",
  Alreadyhaveanaccount: "पहिले नै खाता छ",
  LoginHere: "यहाँ लगइन गर्नुहोस्",
  // register
  starthourlypackage: "घण्टावारी प्याकेज सुरू गर्नुहोस्",
  // Profile
  SubmitReport: "रिपोर्ट पेश गर्नुहोस्",
  Blockcon: "तपाईं निश्चित रूपमा ब्लक गर्न चाहनुहुन्छ",
  Yes: "हो",
  No: "होइन",
  reportreason: "यस रिपोर्टको कारण लेख्नुहोस्",
  Visits: "भ्रमणहरू",
  Photos: "तस्वीरहरू",
  UnlockExclusive: "अनलॉक अनन्य तस्वीरहरू",
  UnlockNow: "अब अनलॉक गर्नुहोस्",
  Personal: "व्यक्तिगत",
  Ethnicity: "जाति",
  BodyType: "शरीरको प्रकार",
  Gender: "लिंग",
  Height: "उचाइ",
  Relationship: "सम्बन्ध",
  Work: "काम",
  Education: "शिक्षा",
  LIFESTYLE: "जीवन शैली",
  LivesWith: "संग बस्नुहोस्",
  Car: "गाडी",
  Religion: "धर्म",
  Smoke: "सिगरेट पिउन्छन्",
  Drink: "पिउन्छन्",
  Travel: "यात्रा",
  // profile

  // settings
  PhoneNumber: "फोन नम्बर",
  GeneralSettings: "सामान्य सेटिङ्ग्स",
  SaveChanges: "परिवर्तनहरू सुरक्षित गर्नुहोस्",
  RemoveAccount: "खाता हटाउनुहोस्",
  Yourprofile: "तपाईंको प्रोफाइल",
  AboutMe: "मेरो बारेमा",
  Relationshipstatus: "सम्बन्ध स्थिति",
  YourCountry: "तपाईंको देश",
  ChooseYourCountry: "तपाईंको देश छनौट गर्नुहोस्",
  Looks: "हेर्ने",
  Personality: "व्यक्तित्व",
  Character: "चरित्र",
  Children: "बालबालिकाहरू",
  Friends: "साथीहरू",
  Pets: "पालतु जनावरहरू",
  LivingWith: "संग बस्नुहोस्",
  MusicGenre: "संगीत शैली",
  Dish: "भोजन",
  Song: "गीत",
  Hobby: "शौक",
  Book: "पुस्तक",
  Movie: "चलचित्र",
  ChangePassword: "पासवर्ड परिवर्तन गर्नुहोस्",
  NewPassword: "नयाँ पासवर्ड",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "तपाईंको मित्रता प्रोफाइल बनाउनुहोस्",
  uploadphoto: "तपाईंको प्रोफाइल तस्वीर सेट गर्नको लागि तस्वीर अपलोड गर्नुहोस्",
  picnotupdated: "प्रोफाइल तस्वीर अद्यावधिक गरिएको छैन",
  pleaseupdatepicture: "कृपया जारी राख्न तपाईंले प्रोफाइल तस्वीर अपलोड गर्नुहोस्",
  BirthYear: "जन्म वर्ष",
  Date: "मिति",
  Month: "महिना",
  Day: "दिन",
  SexualPreference: "लैङ्गिक पसन्द",
  SexualPreferences: "तपाईंको लैङ्गिक पसन्द के हो?",
  countrycode: "देशको कोड",
  PhonNumber: "फोन नम्बर",
  // profile setup

  nolikesyet: "तपाईंले अहिले सम्म कुनै प्रियता छैन",
  nomatches: "तपाईंको कुनै मिलान छैन",
  likematches: "तपाईं उनीहरूलाई मन पराउनेर उनीहरूलाई मिलाउन सक्नुहुन्छ",
  terms: "सर्तहरू",
  Privacy: "गोपनीयता",
  imprint: "प्रिन्ट",
  Address: "ठेगाना",
},

// Nepali
// Khmer
Khmer:    {
  SomeRandomHeadingGoesHere: "ចូលរួមដោយឥតគ្នានៅថ្ងៃនេះ",
  noflirtsrecharge: "អ្នកមិនមានឥតគិតថ្លៃគ្រប់គ្រាន់ទេ",
  heygetflirts: "ទិញគ្រប់គ្រាន់",
  SEARCHINGFORNOTIFICAITON: "កំពុងស្វែងរកសំបុត្រ",
  Newconversation: "ការពិភាក្សាថ្មី",
  MyPurchases: "ការទិញរបស់ខ្ញុំ",
  mypurchasedes: "នៅទីនេះអ្នកនឹងរកឃើញការគ្រប់គ្រងនិងកញ្ចប់ដែលអ្នកបានទិញហើយ។",
  mypurchacedess: "អ្នកមិនទាន់មានប្រតិបត្តិការទេ",
  resetlink: "បញ្ចូលអាសយដ្ឋានអ៊ីម៉ែលរបស់អ្នកហើយយើងនឹងផ្ញើអ៊ីម៉ែលសំណើមរបស់អ្នក",
  // transaction

  ChatsConversation: "ការជជែកពាក់ព័ន្ធ (ការពិភាក្សា)",
  message: "សារ",
  likes: "ចូលចិត្ត",
  matches: "ការផ្គូផ្គង",
  Liked: "ចូលចិត្ត",
  Disliked: "មិនចូលចិត្ត",
  JustVisitedYou: "ដែលជាជម្រើសរួចហើយ",
  LoadMore: "ផ្ទុកឡើងវិញ",
  FliterUsers: "អ្នកប្រើប្រាស់តម្រង",
  Youdonthaveanynotifications: "អ្នកមិនមានសំបុត្រណាមួយទេ",
  ActiveNow: "សកម្មភាពបច្ចុប្បន្ន",

  // landing
  SignIn: "ចូល",
  FlirtToolNews: "ព័ត៌មាន FlirtTool",
  Join: "ចូលរួម",
  Free: "ឥតគិតថ្លៃ",
  Today: "ថ្ងៃនេះ",
  BasicPackage: "កញ្ចប់ម៉ាស៊ីន",
  twohoursfreechatexperience: "បទពិសោធន៍ការជជែកពាក់ព័ន្ធឥតគិតថ្លៃ ២ ម៉ោង",
  Tryitforfree: "ព្យាយាមវាសនា",
  FlirtToolfor1Day: "ឧបករណ៍ជជែកពាក់ព័ន្ធសម្រាប់ ១ ថ្ងៃ",
  Gblobalfor1Day:"Global Chat for one day",

  RealChatFlatrate: "ការជជែកពាក់ព័ន្ធពិតប្រាក់ $2",
  Grabit: "យកវាសនា",
  Createanaccount: "បង្កើតគណនី",
  orgettheapp: "ឬទទួលម៉ូឌុល",
  OVER1MILLIONUSERSWORLDWIDE: "ច្រកចេញមានចំនួនលើសពី ១ លានអ្នកប្រើប្រាស់ការជជែកពាក់ព័ន្ធទេសចរណ៍ប្រទេសពិភពលោក",
  MatchingProfiles: "ការផ្គូផ្គងគម្រោង",
  UpcomingonFlirtTool: "កញ្ចប់ម៉ាស៊ីននៅលើ FlirtTool",
  FindRealLifeWithFlirtTool: "ស្វែងរកជីវិតមានជីវិតជាមួយ FlirtTool!",
  HowFlirtToolWorks: "របៀបការប្រើ FlirtTool",
  HowFlirtToolWorksdes: " FlirtTool ផ្ដល់អ្នកការធ្វើឱ្យលោកអ្នកដូចជាមានការរីករាយថ្នាក់ថ្មីនៅក្នុងការប្រើប្រាស់បណ្ដឹងសម្រាប់ទំនាក់ទំនងលោកអ្នក ៩៤% គឺជាមួយអ្នកផ្គូផ្គងរបស់ពួកគេ",
  LEARNMORE: "សិក្សាបន្ថែម",
  About: "អំពី",
  PressAndMedia: "ចម្ងាយនិងប្រព័ន្ធ",
  Blogs: "ប្លក់",
  LiveChat: "ជជែកពាក់ព័ន្ធជាបន្តដំណើរ",
  ChatNow: "ជជែកពាក់ព័ន្ធឥតគិតថ្លៃឥតគិតថ្លៃ",
  SocialMedia: "បណ្តាញសង្គម",
  ContactUs: "ទាក់ទង​យើង",
  WelcometoFlirttool: "ស្វាគមន៍មកកាន់ Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "ឬបង្កើតគណនី",
  UsernameorEmail: "ឈ្មោះអ្នកប្រើឬអ៊ីម៉ែល",
  YourPassword: "ពាក្យសម្ងាត់របស់អ្នក",
  Forgotpassword: "ភ្លេចលេខសំងាត់",
  Reset: "កំណត់ឡើងវិញ",
  IAGREE: "ខ្ញុំយល់ព្រមនឹងលក្ខខណ្ឌសម្រាប់ភាសាក្រុមហ៊ុនមិនលើសពីនិងគណនីឯកជន",
  Clickhereto: "ចុចនៅទីនេះដើម្បីមើលលក្ខខណ្ឌ​របស់យើង",

  // login

  // register
  FIRSTNAME: "នាមត្រកូល",
  LastName: "នាមចុងក្រោយ",
  Emailisnotvalid: "អ៊ីមែលមិនត្រឹមត្រូវ",
  Username: "ឈ្មោះអ្នកប្រើប្រាស់",
  Yourpasswords: "ពាក្យសម្ងាត់របស់អ្នក",
  Passwordisnotvalid: "ពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
  Confirmpassword: "បញ្ជាក់ពាក្យសម្ងាត់",
  Usernamemusthaveatleast: "ឈ្មោះអ្នកប្រើត្រូវមានយ៉ាងហោចណាត់",
  Alreadyhaveanaccount: "មានគណនីរួចហើយ",
  LoginHere: "ចូលទីនេះ",
  // register
  starthourlypackage: "កំឡុងតែម៉ោងចាប់ផ្ដើម",
  // Profile
  SubmitReport: "ដាក់បញ្ជូនរបាយការណ៍",
  Blockcon: "តើអ្នកប្រើប្រាស់ចង់រារាងឬទេ",
  Yes: "បាទ",
  No: "ទេ",
  reportreason: "បញ្ចូលមានប្រវត្តិសំបុត្ររបស់អ្នកទោលនេះ",
  Visits: "ការចូលមើល",
  Photos: "រូបភាព",
  UnlockExclusive: "ដោះលែងរូបភាពផ្ទាល់ខ្លួន",
  UnlockNow: "ដោះលែងឥតគិតថ្លៃឥតគិតថ្លៃ",
  Personal: "ផ្ទាល់ខ្លួន",
  Ethnicity: "ប្រវត្តិរូប",
  BodyType: "ប្រភេទសត្វ",
  Gender: "ភេទ",
  Height: "កម្ពស់",
  Relationship: "ការទំនាក់ទំនង",
  Work: "ការងារ",
  Education: "ការអប់រំ",
  LIFESTYLE: "បច្ចុប្បន្ន",
  LivesWith: "រស់ជាមួយ",
  Car: "រថយន្ត",
  Religion: "សាសនា",
  Smoke: "អីត្រា",
  Drink: "ទទួលស្រឡាញ់",
  Travel: "ធ្វើដំណើរ",
  Education: "ការអប់រំ",
  // profile

  // settings
  PhoneNumber: "លេខទូរស័ព្ទ",
  GeneralSettings: "ការកំណត់ទូទៅ",
  SaveChanges: "រក្សាកំណត់ឡើងវិញ",
  RemoveAccount: "ដកគណនី",
  Yourprofile: "គណនីរបស់អ្នក",
  AboutMe: "អំពីខ្ញុំ",
  Relationshipstatus: "ស្ថានភាពសកម្មភាព",
  YourCountry: "ប្រទេសរបស់អ្នក",
  ChooseYourCountry: "ជ្រើសរើសប្រទេសរបស់អ្នក",
  Looks: "ទូទៅ",
  Personality: "ប្រវត្តិរូប",
  Character: "លទ្ធភាព",
  Children: "កូនក្មេង",
  Friends: "មិត្ដភក្តិ",
  Pets: "សត្វព្រឺសត្វ",
  LivingWith: "រស់ជាមួយ",
  MusicGenre: "ប្រភេទតន្ត្រី",
  Dish: "បាយសម្រាប់បានបង្កើត",
  Song: "បទចម្រៀង",
  Hobby: "ស្ថាបនា",
  Book: "សៀវភៅ",
  Movie: "ភាពយន្ត",
  ChangePassword: "ប្ដូរពាក្យសម្ងាត់",
  NewPassword: "ពាក្យសម្ងាត់ថ្មី",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "កំណត់សំបុត្រប្រចាំថ្ងៃរបស់អ្នក",
  uploadphoto: "បញ្ចូលរូបភាពដើម្បីកំណត់រូបភាពផ្ទាល់ខ្លួនរបស់អ្នក",
  picnotupdated: "រូបភាពផ្ទាល់ខ្លួនមិនត្រូវបានធ្វើបច្ចុប្បន្នទេ",
  pleaseupdatepicture: "អ្នកគួរតែផ្លាស់ប្តូររូបភាពរូបភាពផ្ទាល់ខ្លួនដើម្បីបន្តជាមួយ",
  BirthYear: "ឆ្នាំកំណើត",
  Date: "កាលបរិច្ឆេទ",
  Month: "ខែ",
  Day: "ថ្ងៃ",
  SexualPreference: "ចម្រើនរបស់ការរកស្រាល",
  SexualPreferences: "តើអ្នកចូលចិត្តបានអ្វីក្នុងប្រកាសនេះ",
  countrycode: "លេខកូដប្រទេស",
  PhonNumber: "លេខទូរស័ព្ទ",
  // profile setup

  nolikesyet: "អ្នកមិនទាន់មានចូលចិត្តណាមួយទេ",
  nomatches: "អ្នកមិនទាន់មានការផ្គូផ្គងណាមួយទេ",
  likematches: "អ្នកអាចចាប់ផ្គូផ្គងជាមួយប្រភេទរបស់អ្នកដោយចូលចិត្តពីព្រោះពួកគេ",
  terms: "លក្ខខណ្ឌ",
  Privacy: "ភាពឯកជន",
  imprint: "ដឹកជញ្ជាំង",
  Address: "អាសយដ្ឋាន"
},
// Khmer

// Turkmenisch
Turkmenisch:{
  SomeRandomHeadingGoesHere: "Şu gün bäsleşmek üçin gatnaň",
  noflirtsrecharge: "Iň az ýeteleme baryş ýok",
  heygetflirts: "Şatlyklar",
  SEARCHINGFORNOTIFICAITON: "BILDIRIŞ GÖZLEG",
  Newconversation: "Täze söhbet",
  MyPurchases: "Meni satyn alyşlar",
  mypurchasedes: "Burada satyn alyşlar we paketleri taparsyňyz.",
  mypurchacedess: "Hasaplama ýok",
  resetlink: "E-poçta salgysyny giriziň we sizin ugruk salgynyňyzy ugratarys",
  // transaction

  ChatsConversation: "SÖHBEPLER (SÖHBE)",
  message: "Habar",
  likes: "Beýeniler",
  matches: "Soňky",
  Liked: "Beýenilen",
  Disliked: "Hoşgörmedim",
  JustVisitedYou: "Göçürilen",
  LoadMore: "Dowam et",
  FliterUsers: "Ulanyjylary süz",
  Youdonthaveanynotifications: "Sizda bildirişler ýok",
  ActiveNow: "Hözir aktiw",

  // landing
  SignIn: "Giriş",
  FlirtToolNews: "Flirt Aleti Habar",
  Join: "Gatnaş",
  Free: "Ýöritele",
  Today: "Bugün",
  BasicPackage: "Esasy Paket",
  twohoursfreechatexperience: "2 sagat tölegli söhbet taýýarlaň",
  Tryitforfree: "Möň",
  FlirtToolfor1Day: "1 günlük Flirt Aleti",
  Gblobalfor1Day:"1 günlük Global Aleti",

  RealChatFlatrate: "Real Çat Çatlygynyň pul aralığı TÄZE $2",
  Grabit: "Aldyň",
  Createanaccount: "Hasap aç",
  orgettheapp: "şolary ýa-da ylgalara al",
  OVER1MILLIONUSERSWORLDWIDE: "Halkara derejede 1 million ulanyjy bar",
  MatchingProfiles: "Serhety wezipelemek",
  UpcomingonFlirtTool: "Flirt Aleti üstünde gelýär",
  FindRealLifeWithFlirtTool: "Gündelik ýaşama Flirt Aleti bilen tap",
  HowFlirtToolWorks: "Flirt Aleti näme işleýär",
  HowFlirtToolWorksdes:
    " Flirt Aleti size onlaýn hünärmenlik derejesi berýär. Flirt Aletinin iň soňky AI persenk MAZU-niň öz özgeren meýdany üsti gelenlerini 94% mukaddebe mükafata çykaryp bilner",
  LEARNMORE: "DAHA BIL",
  About: "Hakynda",
  PressAndMedia: "Basman we Mediýa",
  Blogs: "Bloglar",
  LiveChat: "Häzirki söhbet",
  ChatNow: "Häzir söhbet et",
  SocialMedia: "Ijtimai mediýa",
  ContactUs: "Habarlaşmak",
  WelcometoFlirttool: "Flirt aletine ýatdaş bolun",

  // end of landing

  // login
  OrCreateanAccount: "Ýa-da hasap aç",
  UsernameorEmail: "Ulanyjy ady ýa-da E-poçta",
  YourPassword: "Seni parolyň",
  Forgotpassword: "Parol esgerdik",
  Reset: "Täzelen",
  IAGREE: "UMUMY ŞERTLER, ŞERTLER WE GIZLINLIK SYLYŞMANY AGZA ALYARYN",
  Clickhereto: "ÝA-GIRIŞIMIZI GÖRKEZMEK ÜÇIN ŞU ÝERE BAS",

  // login

  // register
  FIRSTNAME: "ILK ADY",
  LastName: "Soňky ady",
  Emailisnotvalid: "E-poçta dogry däl",
  Username: "Ulanyjy ady",
  Yourpasswords: "Seni parolyň",
  Passwordisnotvalid: "Parol dogry däl",
  Confirmpassword: "Paroly tassyklamak",
  Usernamemusthaveatleast: "Ulanyjy ady azyndan bolmaly",
  Alreadyhaveanaccount: "Eger hasap bar bolsa",
  LoginHere: "Ýöritele Giriş",

  // register
  starthourlypackage: "Sagatlary işleýin başla",
  // Profile
  SubmitReport: "Bellik goşmak",
  Blockcon: "Hokmändimi",
  Yes: "Hawa",
  No: "Ýok",
  reportreason: "Bu bellik üçin siziň sebäbinizi giriziň",
  Visits: "Çäkler",
  Photos: "Suratlar",
  UnlockExclusive: "Eýebilik suratlarynyny aç",
  UnlockNow: "Häzir eýebilik",
  Personal: "Kişisel",
  Ethnicity: "Janlaw",
  BodyType: "Bedenny görnüş",
  Gender: "Jyns",
  Height: "Boý",
  Relationship: "Arza",
  Work: "Iş",
  Education: "Okuw",
  LIFESTYLE: "JOGAP",
  LivesWith: "Görkezilenlerle",
  Car: "Mashyn",
  Religion: "Din",
  Smoke: "Dym ýakyň",
  Drink: "Içmek",
  Travel: "Syýahat",
  Education: "Okuw",
  // profile

  // settings
  PhoneNumber: "Telefon belgisi",
  GeneralSettings: "Umumy sazlamalar",
  SaveChanges: "Uytgetmeleri sakla",
  RemoveAccount: "Hasaby aýyryň",
  Yourprofile: "Seni profili",
  AboutMe: "Men hakynda",
  Relationshipstatus: "Arza statusy",
  YourCountry: "Siziň dohwonyn",
  ChooseYourCountry: "Dohwonyny saýla",
  Looks: "Görnüş",
  Personality: "Şahsyýet",
  Character: "Işlem",
  Children: "Çagalar",
  Friends: "Dostlar",
  Pets: "Haywanlar",
  LivingWith: "Görkezilenlerle",
  MusicGenre: "Muzika janr",
  Dish: "Çörek",
  Song: "Geçiş",
  Hobby: "Hobby",
  Book: "Kitap",
  Movie: "Film",
  ChangePassword: "Paroly üýtgetmek",
  NewPassword: "Täze parol",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "Hakykatlan profili tertip et",
  uploadphoto: "Surat goşmak profil suratyn düzmek üçin",
  picnotupdated: "Profil suraty güncellenmedi",
  pleaseupdatepicture: "Dowamy etmek üçin profil suratyny goşmaly",
  BirthYear: "Doglan ýyly",
  Date: "Sana",
  Month: "Aý",
  Day: "Gün",
  SexualPreference: "Cinsel taýýarlyk",
  SexualPreferences: "Siz ýene kimdir?",
  countrycode: "Döwlet belgisi",
  PhonNumber: "Telefon belgisi",
  // profile setup

  nolikesyet: "Sizde beýenilýän resminiz ýok",
  nomatches: "Sizde aýyrylan ýok",
  likematches: "Dostlar bilen täze resimler berip bilersiňiz",
  terms: "Şertler",
  Privacy: "Gizlinlik",
  imprint: "Sázlaşma",
  Address: "Salgy"
},
// Turkmenisch

// Assamesisch
Assamesisch: {
  Register:"ምዝገባ",
  SomeRandomHeadingGoesHere: "আজি মুক্তত যোগদান কৰক",
  noflirtsrecharge: "Iň az ýeteleme baryş ýok",
  heygetflirts: "Şatlyklar",
  SEARCHINGFORNOTIFICAITON: "BILDIRIŞ GÖZLEG",
  Newconversation: "Täze söhbet",
  MyPurchases: "Meni satyn alyşlar",
  mypurchasedes: "Burada satyn alyşlar we paketleri taparsyňyz.",
  mypurchacedess: "Hasaplama ýok",
  resetlink: "E-poçta salgysyny giriziň we sizin ugruk salgynyňyzy ugratarys",
  // transaction

  ChatsConversation: "SÖHBEPLER (SÖHBE)",
  message: "Habar",
  likes: "Beýeniler",
  matches: "Soňky",
  Liked: "Beýenilen",
  Disliked: "Hoşgörmedim",
  JustVisitedYou: "Göçürilen",
  LoadMore: "Dowam et",
  FliterUsers: "Ulanyjylary süz",
  Youdonthaveanynotifications: "Sizda bildirişler ýok",
  ActiveNow: "Hözir aktiw",

  // landing
  SignIn: "Giriş",
  FlirtToolNews: "Flirt Aleti Habar",
  Join: "Gatnaş",
  Free: "Ýöritele",
  Today: "Bugün",
  BasicPackage: "Esasy Paket",
  twohoursfreechatexperience: "2 sagat tölegli söhbet taýýarlaň",
  Tryitforfree: "Möň",
  FlirtToolfor1Day: "1 günlük Flirt Aleti",
  Gblobalfor1Day:"1 günlük Global Aleti",

  RealChatFlatrate: "Real Çat Çatlygynyň pul aralığı TÄZE $2",
  Grabit: "Aldyň",
  Createanaccount: "Hasap aç",
  orgettheapp: "şolary ýa-da ylgalara al",
  OVER1MILLIONUSERSWORLDWIDE: "Halkara derejede 1 million ulanyjy bar",
  MatchingProfiles: "Serhety wezipelemek",
  UpcomingonFlirtTool: "Flirt Aleti üstünde gelýär",
  FindRealLifeWithFlirtTool: "Gündelik ýaşama Flirt Aleti bilen tap",
  HowFlirtToolWorks: "Flirt Aleti näme işleýär",
  HowFlirtToolWorksdes:
    " Flirt Aleti size onlaýn hünärmenlik derejesi berýär. Flirt Aletinin iň soňky AI persenk MAZU-niň öz özgeren meýdany üsti gelenlerini 94% mukaddebe mükafata çykaryp bilner",
  LEARNMORE: "DAHA BIL",
  About: "Hakynda",
  PressAndMedia: "Basman we Mediýa",
  Blogs: "Bloglar",
  LiveChat: "Häzirki söhbet",
  ChatNow: "Häzir söhbet et",
  SocialMedia: "Ijtimai mediýa",
  ContactUs: "Habarlaşmak",
  WelcometoFlirttool: "Flirt aletine ýatdaş bolun",

  // end of landing

  // login
  OrCreateanAccount: "Ýa-da hasap aç",
  UsernameorEmail: "Ulanyjy ady ýa-da E-poçta",
  YourPassword: "Seni parolyň",
  Forgotpassword: "Parol esgerdik",
  Reset: "Täzelen",
  IAGREE: "UMUMY ŞERTLER, ŞERTLER WE GIZLINLIK SYLYŞMANY AGZA ALYARYN",
  Clickhereto: "ÝA-GIRIŞIMIZI GÖRKEZMEK ÜÇIN ŞU ÝERE BAS",

  // login

  // register
  FIRSTNAME: "ILK ADY",
  LastName: "Soňky ady",
  Emailisnotvalid: "E-poçta dogry däl",
  Username: "Ulanyjy ady",
  Yourpasswords: "Seni parolyň",
  Passwordisnotvalid: "Parol dogry däl",
  Confirmpassword: "Paroly tassyklamak",
  Usernamemusthaveatleast: "Ulanyjy ady azyndan bolmaly",
  Alreadyhaveanaccount: "Eger hasap bar bolsa",
  LoginHere: "Ýöritele Giriş",

  // register
  starthourlypackage: "Sagatlary işleýin başla",
  // Profile
  SubmitReport: "Bellik goşmak",
  Blockcon: "Hokmändimi",
  Yes: "Hawa",
  No: "Ýok",
  reportreason: "Bu bellik üçin siziň sebäbinizi giriziň",
  Visits: "Çäkler",
  Photos: "Suratlar",
  UnlockExclusive: "Eýebilik suratlarynyny aç",
  UnlockNow: "Häzir eýebilik",
  Personal: "Kişisel",
  Ethnicity: "Janlaw",
  BodyType: "Bedenny görnüş",
  Gender: "Jyns",
  Height: "Boý",
  Relationship: "Arza",
  Work: "Iş",
  Education: "Okuw",
  LIFESTYLE: "JOGAP",
  LivesWith: "Görkezilenlerle",
  Car: "Mashyn",
  Religion: "Din",
  Smoke: "Dym ýakyň",
  Drink: "Içmek",
  Travel: "Syýahat",
  Education: "Okuw",
  // profile

  // settings
  PhoneNumber: "Telefon belgisi",
  GeneralSettings: "Umumy sazlamalar",
  SaveChanges: "Uytgetmeleri sakla",
  RemoveAccount: "Hasaby aýyryň",
  Yourprofile: "Seni profili",
  AboutMe: "Men hakynda",
  Relationshipstatus: "Arza statusy",
  YourCountry: "Siziň dohwonyn",
  ChooseYourCountry: "Dohwonyny saýla",
  Looks: "Görnüş",
  Personality: "Şahsyýet",
  Character: "Işlem",
  Children: "Çagalar",
  Friends: "Dostlar",
  Pets: "Haywanlar",
  LivingWith: "Görkezilenlerle",
  MusicGenre: "Muzika janr",
  Dish: "Çörek",
  Song: "Geçiş",
  Hobby: "Hobby",
  Book: "Kitap",
  Movie: "Film",
  ChangePassword: "Paroly üýtgetmek",
  NewPassword: "Täze parol",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "Hakykatlan profili tertip et",
  uploadphoto: "Surat goşmak profil suratyn düzmek üçin",
  picnotupdated: "Profil suraty güncellenmedi",
  pleaseupdatepicture: "Dowamy etmek üçin profil suratyny goşmaly",
  BirthYear: "Doglan ýyly",
  Date: "Sana",
  Month: "Aý",
  Day: "Gün",
  SexualPreference: "Cinsel taýýarlyk",
  SexualPreferences: "Siz ýene kimdir?",
  countrycode: "Döwlet belgisi",
  PhonNumber: "Telefon belgisi",
  // profile setup

  nolikesyet: "Sizde beýenilýän resminiz ýok",
  nomatches: "Sizde aýyrylan ýok",
  likematches: "Dostlar bilen täze resimler berip bilersiňiz",
  terms: "Şertler",
  Privacy: "Gizlinlik",
  imprint: "Sázlaşma",
  Address: "Salgy"
},


// Assamesisch

// Somali
// Somali:,
// Somali
Magahi:{
  SomeRandomHeadingGoesHere: "आज मुफ्त में शामिल हों",
noflirtsrecharge: "आपके पास पर्याप्त क्रेडिट नहीं है",
heygetflirts: "फ्लर्ट्स प्राप्त करें",
SEARCHINGFORNOTIFICAITON: "सूचना खोज रहे हैं",
Newconversation: "नई बातचीत",
MyPurchases: "मेरी खरीददारी",
mypurchasedes: "यहाँ आपको वह फ्लर्ट्स और पैकेज मिलेगा जिन्हें आपने खरीदा है।",
mypurchacedess: "अब तक आपका कोई लेन-देन नहीं है",
resetlink: "अपना ईमेल पता दर्ज करें और हम आपको अपना रीसेट लिंक भेजेंगे",
ChatsConversation: "चैट (बातचीत)",
message: "संदेश",
likes: "पसंद",
matches: "मैचेस",
Liked: "पसंद किया",
Disliked: "अपसंगत",
JustVisitedYou: "आपको अभी अभिनय किया",
LoadMore: "और लोड करें",
FliterUsers: "उपयोगकर्ता को फिल्टर करें",
Youdonthaveanynotifications: "आपके पास कोई सूचनाएं नहीं हैं",
ActiveNow: "अब सक्रिय",

SignIn: "साइन इन करें",
FlirtToolNews: "फ्लर्टटूल समाचार",
Join: "शामिल हों",
Free: "मुफ्त",
Today: "आज",
BasicPackage: "मूल पैकेज",
twohoursfreechatexperience: "2 घंटे की मुफ्त चैट अनुभव",
Tryitforfree: "यह मुफ्त में प्रयास करें",
FlirtToolfor1Day: "1 दिन के लिए फ्लर्टटूल",
Gblobalfor1Day:"1 günlük Global Aleti",

RealChatFlatrate: "रियल चैट फ्लैटरेट केवल $2",
Grabit: "इसे पकड़ो",
Createanaccount: "खाता बनाएं",
orgettheapp: "या ऐप प्राप्त करें",
OVER1MILLIONUSERSWORLDWIDE: "विश्वभर में 1 मिलियन से अधिक उपयोगकर्ता",
MatchingProfiles: "मिलान प्रोफाइल्स",
UpcomingonFlirtTool: "फ्लर्टटूल पर आगामी",
FindRealLifeWithFlirtTool: "फ्लर्टटूल के साथ वास्तविक जीवन खोजें!",
HowFlirtToolWorks: "फ्लर्टटूल कैसे काम करता है",
HowFlirtToolWorksdes: "फ्लर्टटूल आपको ऑनलाइन डेटिंग के नए स्तर प्रदान करता है। एआई व्यक्तित्व MAZU का अंतिम सृजन फ्लर्टटूल उपयोगकर्ताओं को किसी के साथ मिलान करेगा जो 94% उनका पूरा मिलान है",
LEARNMORE: "अधिक जानें",
About: "के बारे में",
PressAndMedia: "प्रेस और मीडिया",
Blogs: "ब्लॉग्स",
LiveChat: "लाइव चैट",
ChatNow: "अभी चैट करें",
SocialMedia: "सोशल मीडिया",
ContactUs: "संपर्क करें",
WelcometoFlirttool: "फ्लर्टटूल में आपका स्वागत है",

OrCreateanAccount: "या एक खाता बनाएं",
UsernameorEmail: "उपयोगकर्ता नाम या ईमेल",
YourPassword: "आपका पासवर्ड",
Forgotpassword: "पासवर्ड भूल गए",
Reset: "रीसेट",
IAGREE: "मैं नियम, शर्तें और गोपनीयता नीति से सहमत हूँ",
Clickhereto: "हमारे नियमों को देखने के लिए यहाँ क्लिक करें",

FIRSTNAME: "पहला नाम",
LastName: "उपनाम",
Emailisnotvalid: "ईमेल मान्य नहीं है",
Username: "उपयोगकर्ता नाम",
Yourpasswords: "आपका पासवर्ड",
Passwordisnotvalid: "पासवर्ड मान्य नहीं है",
Confirmpassword: "पासवर्ड की पुष्टि कीजिये",
Usernamemusthaveatleast: "उपयोगकर्ता नाम कम से कम होना चाहिए",
Alreadyhaveanaccount: "पहले से ही एक खाता है",
LoginHere: "यहाँ लॉगिन करें",

starthourlypackage: "प्रति घंटा पैकेज शुरू करें",

SubmitReport: "रिपोर्ट सबमिट करें",
Blockcon: "क्या आप वाकई ब्लॉक करना चाहते हैं",
Yes: "हाँ",
No: "नहीं",
reportreason: "इस रिपोर्ट के लिए अपने कारण दर्ज करें",
Visits: "यात्रा",
Photos: "तस्वीरें",
UnlockExclusive: "अनलॉक विशेष तस्वीरें",
UnlockNow: "अब अनलॉक करें",
Personal: "व्यक्तिगत",
Ethnicity: "नस्ल",
BodyType: "शरीर का प्रकार",
Gender: "लिंग",
Height: "ऊंचाई",
Relationship: "संबंध",
Work: "काम",
Education: "शिक्षा",
LIFESTYLE: "जीवन शैली",
LivesWith: "के साथ रहता है",
Car: "गाड़ी",
Religion: "धर्म",
Smoke: "धूम्रपान",
Drink: "पीना",
Travel: "यात्रा",
Education: "शिक्षा",

PhoneNumber: "फ़ोन नंबर",
GeneralSettings: "सामान्य सेटिंग्स",
SaveChanges: "परिवर्तन सहेजें",
RemoveAccount: "खाता हटाएं",
Yourprofile: "आपका प्रोफ़ाइल",
AboutMe: "मेरे बारे में",
Relationshipstatus: "संबंध स्थिति",
YourCountry: "आपका देश",
ChooseYourCountry: "अपना देश चुनें",
Looks: "दिखावट",
Personality: "व्यक्तित्व",
Character: "चरित्र",
Children: "बच्चे",
Friends: "दोस्त",
Pets: "पालतू जानवर",
LivingWith: "साथ रहना",
MusicGenre: "संगीत जाति",
Dish: "डिश",
Song: "गाना",
Hobby: "शौक",
Book: "पुस्तक",
Movie: "फिल्म",
ChangePassword: "पासवर्ड बदलें",
NewPassword: "नया पासवर्ड",

SETUPYOURDATINGPROFILE: "अपनी डेटिंग प्रोफ़ाइल सेट करें",
uploadphoto: "प्रोफ़ाइल चित्र सेट करने के लिए फोटो अपलोड करें",
picnotupdated: "प्रोफ़ाइल चित्र अपडेट नहीं हुआ है",
pleaseupdatepicture: "आपको जारी रखने के लिए प्रोफ़ाइल चित्र अपलोड करना चाहिए",
BirthYear: "जन्म वर्ष",
Date: "तारीख",
Month: "महीना",
Day: "दिन",
SexualPreference: "लैंगिक पसंद",
SexualPreferences: "आपकी यौन पसंद क्या है",
countrycode: "देश कोड",
PhonNumber: "फ़ोन नंबर",

nolikesyet: "अभी तक आपको कोई पसंद नहीं आई हैं",
nomatches: "अभी तक आपको कोई मैच नहीं मिले हैं",
likematches: "आप प्रोफ़ाइल को पसंद करके उन्हें मैच करना शुरू कर सकते हैं",
terms: "नियम",
Privacy: "गोपनीयता",
imprint: "मुद्रण",
Address: "पता"
},

Haryanvi:{
  SomeRandomHeadingGoesHere: "आज मुफ्त छोड़ो",
noflirtsrecharge: "तुहाड़े को क्रेडिट नहीं है",
heygetflirts: "फ्लर्ट्स लेण लेण",
SEARCHINGFORNOTIFICAITON: "सूचना खोज रहे नहीं",
Newconversation: "नई बातचीत",
MyPurchases: "मेरी खरीदी",
mypurchasedes: "इथे तुहाड़ी खरीदी गई फ्लर्ट्स ते पैकेज मिलेगा।",
mypurchacedess: "तुसी अजैके कोई लेन-देन नहीं कीती",
resetlink: "तुहाड़ा ईमेल पत्ता दर्ज करो ते साड़ी तुहाड़ी रिसेट लिंक भेजण्गे",
ChatsConversation: "चैट (बातचीत)",
message: "संदेश",
likes: "पसंद",
matches: "मैच",
Liked: "पसंद किया",
Disliked: "नापसंद किया",
JustVisitedYou: "ताजा आया तुम्हारे पास",
LoadMore: "और लोड करें",
FliterUsers: "यूजर फिल्टर करें",
Youdonthaveanynotifications: "तुहाड़े को कोई सूचना नहीं है",
ActiveNow: "अभी चल रहे हैं",

SignIn: "साइन इन करो",
FlirtToolNews: "फ्लर्टटूल समाचार",
Join: "जुड़ो",
Free: "मुफ्त",
Today: "आज",
BasicPackage: "मूल पैकेज",
twohoursfreechatexperience: "2 घंटे तक मुफ्त चैट अनुभव",
Tryitforfree: "मुफ्त में आजमाओ",
FlirtToolfor1Day: "1 दिन के लिए फ्लर्टटूल",
RealChatFlatrate: "रियल चैट फ्लैट रेट केवल $2",
Grabit: "पकड़ लो",
Createanaccount: "खाता बनाओ",
orgettheapp: "या ऐप लाओ",
OVER1MILLIONUSERSWORLDWIDE: "विश्व भर में 1 मिलियन से अधिक उपयोगकर्ता",
MatchingProfiles: "मैचिंग प्रोफ़ाइल्स",
UpcomingonFlirtTool: "आगामी फ्लर्टटूल पर",
FindRealLifeWithFlirtTool: "फ्लर्टटूल के साथ वास्तविक जिंदगी खोजें!",
HowFlirtToolWorks: "फ्लर्टटूल कैसे काम करता है",
HowFlirtToolWorksdes: "फ्लर्टटूल आपको ऑनलाइन डेटिंग के नए स्तर प्रदान करता है। फ्लर्टटूल की अंतिम खोज AI व्यक्तित्व MAZU उपयोगकर्ताओं को 94% उनके पूरे मैच के साथ मिलाएगा",
LEARNMORE: "और जानो",
About: "के बारे में",
PressAndMedia: "प्रेस और मीडिया",
Blogs: "ब्लॉग्स",
LiveChat: "लाइव चैट",
ChatNow: "अब चैट करें",
SocialMedia: "सोशल मीडिया",
ContactUs: "संपर्क करो",
WelcometoFlirttool: "फ्लर्टटूल में आपका स्वागत है",

OrCreateanAccount: "या एक खाता बनाओ",
UsernameorEmail: "उपयोगकर्ता नाम या ईमेल",
YourPassword: "तुम्हारा पासवर्ड",
Forgotpassword: "पासवर्ड भूल गए",
Reset: "रीसेट",
IAGREE: "मैं संबंध, शर्तें और गोपनीयता नीति से सहमत हूँ",
Clickhereto: "हमारे नियम देखने के लिए यहाँ क्लिक करें",

FIRSTNAME: "पहला नाम",
LastName: "उपनाम",
Emailisnotvalid: "ईमेल मान्य नहीं है",
Username: "उपयोगकर्ता नाम",
Yourpasswords: "तुम्हारा पासवर्ड",
Passwordisnotvalid: "पासवर्ड मान्य नहीं है",
Confirmpassword: "पासवर्ड की पुष्टि करें",
Usernamemusthaveatleast: "उपयोगकर्ता नाम कम से कम होना चाहिए",
Alreadyhaveanaccount: "पहले से ही एक खाता है",
LoginHere: "यहाँ लॉगिन करें",

starthourlypackage: "प्रति घंटा पैकेज शुरू करो",

SubmitReport: "रिपोर्ट दर्ज करो",
Blockcon: "क्या तुम्हें वाकई ब्लॉक करना है",
Yes: "हां",
No: "नहीं",
reportreason: "इस रिपोर्ट के लिए अपना कारण दर्ज करें",
Visits: "यात्राएँ",
Photos: "तस्वीरें",
UnlockExclusive: "अनलॉक विशेष तस्वीरें",
UnlockNow: "अब अनलॉक करो",
Personal: "व्यक्तिगत",
Ethnicity: "नस्ल",
BodyType: "शरीर का प्रकार",
Gender: "लिंग",
Height: "ऊँचाई",
Relationship: "संबंध",
Work: "काम",
Education: "शिक्षा",
LIFESTYLE: "जीवन शैली",
LivesWith: "साथ रहता है",
Car: "गाड़ी",
Religion: "धर्म",
Smoke: "धूम्रपान",
Drink: "पीना",
Travel: "यात्रा",
Education: "शिक्षा",

PhoneNumber: "फ़ोन नंबर",
GeneralSettings: "सामान्य सेटिंग्स",
SaveChanges: "परिवर्तन सहेजें",
RemoveAccount: "खाता हटाएं",
Yourprofile: "तुम्हारी प्रोफ़ाइल",
AboutMe: "मेरे बारे में",
Relationshipstatus: "संबंध की स्थिति",
YourCountry: "तुम्हारा देश",
ChooseYourCountry: "तुम्हारा देश चुनो",
Looks: "दिखावट",
Personality: "व्यक्तित्व",
Character: "चरित्र",
Children: "बच्चे",
Friends: "दोस्त",
Pets: "पालतू जानवर",
LivingWith: "साथ रहता है",
MusicGenre: "संगीत जाति",
Dish: "डिश",
Song: "गाना",
Hobby: "शौक",
Book: "पुस्तक",
Movie: "फिल्म",
ChangePassword: "पासवर्ड बदलो",
NewPassword: "नया पासवर्ड",

SETUPYOURDATINGPROFILE: "अपनी डेटिंग प्रोफ़ाइल सेट करो",
uploadphoto: "प्रोफ़ाइल चित्र सेट करने के लिए फोटो अपलोड करो",
picnotupdated: "प्रोफ़ाइल चित्र अपडेट नहीं हुआ है",
pleaseupdatepicture: "तुम्हें जारी रखने के लिए प्रोफ़ाइल चित्र अपलोड करना चाहिए",
BirthYear: "जन्म वर्ष",
Date: "तारीख",
Month: "महीना",
Day: "दिन",
SexualPreference: "लैंगिक पसंद",
SexualPreferences: "तुम्हारी यौन पसंद क्या है",
countrycode: "देश कोड",
PhonNumber: "फ़ोन नंबर",

nolikesyet: "तुम्हारी कोई पसंद अब तक नहीं आई है",
nomatches: "तुम्हारी कोई मैच अब तक नहीं है",
likematches: "तुम प्रोफ़ाइल को पसंद करके उन्हें मैच करना शुरू कर सकते हो",
terms: "नियम",
Privacy: "गोपनीयता",
imprint: "मुद्रण",
Address: "पता"

},
polish: {
  Register:"Rejestracja",
  secondtext:"globalchat.us oferuje nowe poziomy randkowania online. Ostateczna kreacja osobowości AI Global Chat pomoże użytkownikom znaleźć kogoś, kto jest w 94% ich idealnym dopasowaniem",
  SomeRandomHeadingGoesHere:"Tu Idzie Jakiś Losowy Tekst",
  noflirtsrecharge: "Nie masz wystarczającej liczby kredytów",
  heygetflirts: "Otrzymaj flirty",
  SEARCHINGFORNOTIFICAITON:"SZUKANIE POWIADOMIENIA",
  Newconversation:"Nowa rozmowa",
  MyPurchases: "Moje Zakupy",
  mypurchasedes:
    "Tutaj znajdziesz flirty i pakiety, które kupiłeś.",
  mypurchacedess: "Nie masz jeszcze żadnych transakcji",
  resetlink:
    "Podaj swój adres e-mail, a wyślemy Ci link resetujący",
  // transaction

  ChatsConversation: "ROZMOWY (KONWERSACJE)",
  message: "Wiadomość",
  likes: "polubienia",
  matches: "Dopasowania",
  Liked: "Polubione",
  Disliked: "Niepolubione",
  JustVisitedYou: "Właśnie Cię odwiedził",
  LoadMore: "Wczytaj więcej",
  FliterUsers: "Filtruj użytkowników",
  Youdonthaveanynotifications: "Nie masz żadnych powiadomień",
  ActiveNow: "Aktywny teraz",

  // landing
  SignIn: "Zaloguj się",
  FlirtToolNews: "Aktualności FlirtTool",
  Join: "Dołącz",
  Free: "Darmowy",
  Today: "Dziś",
  BasicPackage: "Podstawowy Pakiet",
  twohoursfreechatexperience: "2 godziny darmowego doświadczenia czatu",
  Tryitforfree: "Wypróbuj za darmo",
  FlirtToolfor1Day: "FlirtTool na 1 dzień",
  Gblobalfor1Day:"Global na 1 dzień",
  RealChatFlatrate: "Realny czat w cenie tylko 2 dol.",
  Grabit: "Złap to",
  Createanaccount: "Stwórz konto",
  orgettheapp: "lub pobierz aplikację",
  OVER1MILLIONUSERSWORLDWIDE: "Ponad 1 milion użytkowników na całym świecie",
  MatchingProfiles: "Dopasowane profile",
  UpcomingonFlirtTool: "Nadchodzące na FlirtTool",
  FindRealLifeWithFlirtTool: "Odkryj prawdziwe życie dzięki FlirtTool!",
  HowFlirtToolWorks: "Jak działa FlirtTool",
  HowFlirtToolWorksdes:
    " FlirtTool oferuje nowe poziomy randkowania online. Ostateczna kreacja osobowości AI, MAZU, pomoże użytkownikom znaleźć kogoś, kto jest w 94% ich idealnym dopasowaniem",
  LEARNMORE: "DOWIEDZ SIĘ WIĘCEJ",
  About: "O nas",
  PressAndMedia: "Prasa i media",
  Blogs: "Blogi",
  LiveChat: "Czat na żywo",
  ChatNow: "Czatuj teraz",
  SocialMedia: "Media społecznościowe",
  ContactUs: "Skontaktuj się z nami",
  WelcometoFlirttool: "Witaj w Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "Lub Stwórz Konto",
  UsernameorEmail: "Nazwa użytkownika lub e-mail",
  YourPassword: "Twoje hasło",
  Forgotpassword: "Zapomniałeś hasła",
  Reset: "Zresetuj",
  IAGREE: "ZGADZAM SIĘ Z REGULAMINEM, WARUNKAMI I POLITYKĄ PRYWATNOŚCI",
  Clickhereto: "KLIKNIJ TUTAJ, ABY ZOBACZYĆ NASZ REGULAMIN",

  // login

  // register
  FIRSTNAME: "IMIĘ",
  LastName: "Nazwisko",
  Emailisnotvalid: "Adres e-mail jest nieprawidłowy",
  Username: "Nazwa użytkownika",
  Yourpasswords: "Twoje hasło",
  Passwordisnotvalid: "Hasło jest nieprawidłowe",
  Confirmpassword: "Potwierdź hasło",
  Usernamemusthaveatleast: "Nazwa użytkownika musi mieć co najmniej",
  Alreadyhaveanaccount: "Masz już konto",
  LoginHere: "Zaloguj się tutaj",
  // register
  starthourlypackage: "Rozpocznij pakiet godzinowy",
  // Profile
  SubmitReport: "Wyślij raport",
  Blockcon: "Jesteś pewien, że chcesz zablokować",
  Yes: "Tak",
  No: "Nie",
  reportreason: "Wprowadź powód tego zgłoszenia",
  Visits: "Odwiedziny",
  Photos: "Zdjęcia",
  UnlockExclusive: "Odblokuj ekskluzywne zdjęcia",
  UnlockNow: "Odblokuj teraz",
  Personal: "Osobiste",
  Ethnicity: "Etniczność",
  BodyType: "Typ ciała",
  Gender: "Płeć",
  Height: "Wzrost",
  Relationship: "Związek",
  Work: "Praca",
  Education: "Edukacja",
  LIFESTYLE: "STYL ŻYCIA",
  LivesWith: "Mieszka z",
  Car: "Samochód",
  Religion: "Religia",
  Smoke: "Palenie",
  Drink: "Picie",
  Travel: "Podróże",
  Education: "Edukacja",
  // profile

  // settings
  PhoneNumber: "Numer telefonu",
  GeneralSettings: "Ustawienia ogólne",
  SaveChanges: "Zapisz zmiany",
  RemoveAccount: "Usuń konto",
  Yourprofile: "Twój profil",
  AboutMe: "O mnie",
  Relationshipstatus: "Status związku",
  YourCountry: "Twoje państwo",
  ChooseYourCountry: "Wybierz swoje państwo",
  Looks: "Wygląd",
  Personality: "Osobowość",
  Character: "Charakter",
  Children: "Dzieci",
  Friends: "Przyjaciele",
  Pets: "Zwierzęta domowe",
  LivingWith: "Żyje z",
  MusicGenre: "Gatunek muzyczny",
  Dish: "Danie",
  Song: "Piosenka",
  Hobby: "Hobby",
  Book: "Książka",
  Movie: "Film",
  ChangePassword: "Zmień hasło",
  NewPassword: "Nowe hasło",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "USTAW SWÓJ PROFIL RANDKOWY",
  uploadphoto: "Prześlij zdjęcie, aby ustawić zdjęcie profilowe",
  picnotupdated: "Zdjęcie profilowe nie jest zaktualizowane",
  pleaseupdatepicture: "Aby kontynuować, powinieneś załadować zdjęcie profilowe",
  BirthYear: "Rok urodzenia",
  Date: "Data",
  Month: "Miesiąc",
  Day: "Dzień",
  SexualPreference: "Preferencje seksualne",
  SexualPreferences: "Jakie są Twoje preferencje seksualne",
  countrycode: "Kod kraju",
  PhonNumber: "Numer telefonu",
  // profile setup

  nolikesyet: "Nie masz jeszcze żadnych polubień",
  nomatches: "Nie masz jeszcze żadnych dopasowań",
  likematches: "Możesz zacząć dopasowywać profile, lubiąc je",
  terms:"Regulamin",
  Privacy:"Prywatność",
  imprint:"Odbicie",
  Address: "Adres"
},


Spanish: {
  Register:"Registro",
  secondtext:"globalchat.us te ofrece nuevos niveles de citas en línea. La creación definitiva de personalidad de AI de Global Chat ayudará a los usuarios a ser emparejados con alguien que sea un 94% su pareja perfecta",
  SomeRandomHeadingGoesHere:"Alguno Texto Aleatorio Va Aquí",
  noflirtsrecharge: "No tienes suficientes créditos",
  heygetflirts: "Obtener flirteos",
  SEARCHINGFORNOTIFICAITON:"BUSCANDO NOTIFICACIÓN",
  Newconversation:"Nueva conversación",
  MyPurchases: "Mis Compras",
  mypurchasedes:
    "Aquí encontrarás los flirteos y paquetes que has comprado.",
  mypurchacedess: "Todavía no tienes ninguna transacción",
  resetlink:
    "Introduce tu dirección de correo electrónico y te enviaremos tu enlace de restablecimiento",
  // transaction

  ChatsConversation: "CONVERSACIONES (CHATS)",
  message: "Mensaje",
  likes: "me gusta",
  matches: "Coincidencias",
  Liked: "Gustado",
  Disliked: "No Gustado",
  JustVisitedYou: "Acaba de visitarte",
  LoadMore: "Cargar más",
  FliterUsers: "Filtrar usuarios",
  Youdonthaveanynotifications: "No tienes ninguna notificación",
  ActiveNow: "Activo ahora",

  // landing
  SignIn: "Iniciar sesión",
  FlirtToolNews: "Noticias de FlirtTool",
  Join: "Unirse",
  Free: "Gratis",
  Today: "Hoy",
  BasicPackage: "Paquete Básico",
  twohoursfreechatexperience: "2 horas de experiencia de chat gratis",
  Tryitforfree: "Pruébalo gratis",
  FlirtToolfor1Day: "FlirtTool por 1 día",
  Gblobalfor1Day:"Global por 1 día",
  RealChatFlatrate: "Tarifa plana de chat real SOLO $2",
  Grabit: "Cógelo",
  Createanaccount: "Crear una cuenta",
  orgettheapp: "o consigue la aplicación",
  OVER1MILLIONUSERSWORLDWIDE: "Más de 1 millón de usuarios en todo el mundo",
  MatchingProfiles: "Perfiles coincidentes",
  UpcomingonFlirtTool: "Próximamente en FlirtTool",
  FindRealLifeWithFlirtTool: "¡Encuentra la vida real con FlirtTool!",
  HowFlirtToolWorks: "Cómo funciona FlirtTool",
  HowFlirtToolWorksdes:
    " FlirtTool te ofrece nuevos niveles de citas en línea. La creación definitiva de personalidad de AI, MAZU, ayudará a los usuarios a ser emparejados con alguien que sea un 94% su pareja perfecta",
  LEARNMORE: "APRENDE MÁS",
  About: "Acerca de",
  PressAndMedia: "Prensa y medios",
  Blogs: "Blogs",
  LiveChat: "Chat en vivo",
  ChatNow: "Chatea ahora",
  SocialMedia: "Redes sociales",
  ContactUs: "Contáctenos",
  WelcometoFlirttool: "Bienvenido a Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "O Crea una Cuenta",
  UsernameorEmail: "Nombre de usuario o correo electrónico",
  YourPassword: "Tu contraseña",
  Forgotpassword: "¿Olvidaste tu contraseña?",
  Reset: "Restablecer",
  IAGREE: "ACEPTO LOS TÉRMINOS, CONDICIONES Y POLÍTICA DE PRIVACIDAD",
  Clickhereto: "CLIC AQUÍ PARA VER NUESTROS TÉRMINOS",

  // login

  // register
  FIRSTNAME: "NOMBRE",
  LastName: "Apellido",
  Emailisnotvalid: "El correo electrónico no es válido",
  Username: "Nombre de usuario",
  Yourpasswords: "Tu contraseña",
  Passwordisnotvalid: "La contraseña no es válida",
  Confirmpassword: "Confirmar contraseña",
  Usernamemusthaveatleast: "El nombre de usuario debe tener al menos",
  Alreadyhaveanaccount: "¿Ya tienes una cuenta?",
  LoginHere: "Inicia sesión aquí",
  // register
  starthourlypackage: "Comenzar paquete por hora",
  // Profile
  SubmitReport: "Enviar reporte",
  Blockcon: "¿Estás seguro de que quieres bloquear",
  Yes: "Sí",
  No: "No",
  reportreason: "Ingresa tu motivo para este reporte",
  Visits: "Visitas",
  Photos: "Fotos",
  UnlockExclusive: "Desbloquear imágenes exclusivas",
  UnlockNow: "Desbloquear ahora",
  Personal: "Personal",
  Ethnicity: "Etnia",
  BodyType: "Tipo de cuerpo",
  Gender: "Género",
  Height: "Altura",
  Relationship: "Relación",
  Work: "Trabajo",
  Education: "Educación",
  LIFESTYLE: "ESTILO DE VIDA",
  LivesWith: "Vive con",
  Car: "Automóvil",
  Religion: "Religión",
  Smoke: "Fumar",
  Drink: "Beber",
  Travel: "Viajar",
  Education: "Educación",
  // profile

  // settings
  PhoneNumber: "Número de teléfono",
  GeneralSettings: "Configuración general",
  SaveChanges: "Guardar cambios",
  RemoveAccount: "Eliminar cuenta",
  Yourprofile: "Tu perfil",
  AboutMe: "Acerca de mí",
  Relationshipstatus: "Estado de relación",
  YourCountry: "Tu país",
  ChooseYourCountry: "Elige tu país",
  Looks: "Aspecto",
  Personality: "Personalidad",
  Character: "Carácter",
  Children: "Niños",
  Friends: "Amigos",
  Pets: "Mascotas",
  LivingWith: "Viviendo con",
  MusicGenre: "Género musical",
  Dish: "Plato",
  Song: "Canción",
  Hobby: "Pasatiempo",
  Book: "Libro",
  Movie: "Película",
  ChangePassword: "Cambiar contraseña",
  NewPassword: "Nueva contraseña",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "CONFIGURA TU PERFIL DE CITAS",
  uploadphoto: "Sube una foto para establecer tu foto de perfil",
  picnotupdated: "La foto de perfil no está actualizada",
  pleaseupdatepicture: "Debes cargar una foto de perfil para continuar",
  BirthYear: "Año de nacimiento",
  Date: "Fecha",
  Month: "Mes",
  Day: "Día",
  SexualPreference: "Preferencia sexual",
  SexualPreferences: "¿Cuáles son tus preferencias sexuales",
  countrycode: "Código de país",
  PhonNumber: "Número de teléfono",
  // profile setup

  nolikesyet: "Todavía no tienes ningún Me gusta",
  nomatches: "Todavía no tienes ninguna coincidencia",
  likematches: "Puedes comenzar a hacer coincidir perfiles dándoles Me gusta",
  terms:"Términos",
  Privacy:"Privacidad",
  imprint:"Impresión",
  Address: "Dirección"
},
italic: {
  Register:"Registrare",
  secondtext:"*globalchat.us* te ofrece nuevos niveles de citas en línea. La creación definitiva de personalidad de AI de *Global Chat* ayudará a los usuarios a ser emparejados con alguien que sea un 94% su pareja perfecta",
  SomeRandomHeadingGoesHere:"*Alguno Texto Aleatorio Va Aquí*",
  noflirtsrecharge: "No tienes suficientes créditos",
  heygetflirts: "Obtener flirteos",
  SEARCHINGFORNOTIFICAITON:"*BUSCANDO NOTIFICACIÓN*",
  Newconversation:"*Nueva conversación*",
  MyPurchases: "*Mis Compras*",
  mypurchasedes:
    "*Aquí encontrarás los flirteos y paquetes que has comprado.*",
  mypurchacedess: "*Todavía no tienes ninguna transacción*",
  resetlink:
    "*Introduce tu dirección de correo electrónico y te enviaremos tu enlace de restablecimiento*",
  // transaction

  ChatsConversation: "*CONVERSACIONES (CHATS)*",
  message: "*Mensaje*",
  likes: "*me gusta*",
  matches: "*Coincidencias*",
  Liked: "*Gustado*",
  Disliked: "*No Gustado*",
  JustVisitedYou: "*Acaba de visitarte*",
  LoadMore: "*Cargar más*",
  FliterUsers: "*Filtrar usuarios*",
  Youdonthaveanynotifications: "*No tienes ninguna notificación*",
  ActiveNow: "*Activo ahora*",

  // landing
  SignIn: "*Iniciar sesión*",
  FlirtToolNews: "*Noticias de FlirtTool*",
  Join: "*Unirse*",
  Free: "*Gratis*",
  Today: "*Hoy*",
  BasicPackage: "*Paquete Básico*",
  twohoursfreechatexperience: "*2 horas de experiencia de chat gratis*",
  Tryitforfree: "*Pruébalo gratis*",
  FlirtToolfor1Day: "*FlirtTool por 1 día*",
  Gblobalfor1Day:"*Global por 1 día*",
  RealChatFlatrate: "*Tarifa plana de chat real SOLO $2*",
  Grabit: "*Cógelo*",
  Createanaccount: "*Crear una cuenta*",
  orgettheapp: "*o consigue la aplicación*",
  OVER1MILLIONUSERSWORLDWIDE: "*Más de 1 millón de usuarios en todo el mundo*",
  MatchingProfiles: "*Perfiles coincidentes*",
  UpcomingonFlirtTool: "*Próximamente en FlirtTool*",
  FindRealLifeWithFlirtTool: "*¡Encuentra la vida real con FlirtTool!*",
  HowFlirtToolWorks: "*Cómo funciona FlirtTool*",
  HowFlirtToolWorksdes:
    "*FlirtTool te ofrece nuevos niveles de citas en línea. La creación definitiva de personalidad de AI, MAZU, ayudará a los usuarios a ser emparejados con alguien que sea un 94% su pareja perfecta*",
  LEARNMORE: "*APRENDE MÁS*",
  About: "*Acerca de*",
  PressAndMedia: "*Prensa y medios*",
  Blogs: "*Blogs*",
  LiveChat: "*Chat en vivo*",
  ChatNow: "*Chatea ahora*",
  SocialMedia: "*Redes sociales*",
  ContactUs: "*Contáctenos*",
  WelcometoFlirttool: "*Bienvenido a Flirttool*",
  // end of landing

  // login
  OrCreateanAccount: "*O Crea una Cuenta*",
  UsernameorEmail: "*Nombre de usuario o correo electrónico*",
  YourPassword: "*Tu contraseña*",
  Forgotpassword: "*¿Olvidaste tu contraseña?*",
  Reset: "*Restablecer*",
  IAGREE: "*ACEPTO LOS TÉRMINOS, CONDICIONES Y POLÍTICA DE PRIVACIDAD*",
  Clickhereto: "*CLIC AQUÍ PARA VER NUESTROS TÉRMINOS*",

  // login

  // register
  FIRSTNAME: "*NOMBRE*",
  LastName: "*Apellido*",
  Emailisnotvalid: "*El correo electrónico no es válido*",
  Username: "*Nombre de usuario*",
  Yourpasswords: "*Tu contraseña*",
  Passwordisnotvalid: "*La contraseña no es válida*",
  Confirmpassword: "*Confirmar contraseña*",
  Usernamemusthaveatleast: "*El nombre de usuario debe tener al menos*",
  Alreadyhaveanaccount: "*¿Ya tienes una cuenta?*",
  LoginHere: "*Inicia sesión aquí*",
  // register
  starthourlypackage: "*Comenzar paquete por hora*",
  // Profile
  SubmitReport: "*Enviar reporte*",
  Blockcon: "*¿Estás seguro de que quieres bloquear*",
  Yes: "*Sí*",
  No: "*No*",
  reportreason: "*Ingresa tu motivo para este reporte*",
  Visits: "*Visitas*",
  Photos: "*Fotos*",
  UnlockExclusive: "*Desbloquear imágenes exclusivas*",
  UnlockNow: "*Desbloquear ahora*",
  Personal: "*Personal*",
  Ethnicity: "*Etnia*",
  BodyType: "*Tipo de cuerpo*",
  Gender: "*Género*",
  Height: "*Altura*",
  Relationship: "*Relación*",
  Work: "*Trabajo*",
  Education: "*Educación*",
  LIFESTYLE: "*ESTILO DE VIDA*",
  LivesWith: "*Vive con*",
  Car: "*Automóvil*",
  Religion: "*Religión*",
  Smoke: "*Fumar*",
  Drink: "*Beber*",
  Travel: "*Viajar*",
  Education: "*Educación*",
  // profile

  // settings
  PhoneNumber: "*Número de teléfono*",
  GeneralSettings: "*Configuración general*",
  SaveChanges: "*Guardar cambios*",
  RemoveAccount: "*Eliminar cuenta*",
  Yourprofile: "*Tu perfil*",
  AboutMe: "*Acerca de mí*",
  Relationshipstatus: "*Estado de relación*",
  YourCountry: "*Tu país*",
  ChooseYourCountry: "*Elige tu país*",
  Looks: "*Aspecto*",
  Personality: "*Personalidad*",
  Character: "*Carácter*",
  Children: "*Niños*",
  Friends: "*Amigos*",
  Pets: "*Mascotas*",
  LivingWith: "*Viviendo con*",
  MusicGenre: "*Género musical*",
  Dish: "*Plato*",
  Song: "*Canción*",
  Hobby: "*Pasatiempo*",
  Book: "*Libro*",
  Movie: "*Película*",
  ChangePassword: "*Cambiar contraseña*",
  NewPassword: "*Nueva contraseña*",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "*CONFIGURA TU PERFIL DE CITAS*",
  uploadphoto: "*Sube una foto para establecer tu foto de perfil*",
  picnotupdated: "*La foto de perfil no está actualizada*",
  pleaseupdatepicture: "*Debes cargar una foto de perfil para continuar*",
  BirthYear: "*Año de nacimiento*",
  Date: "*Fecha*",
  Month: "*Mes*",
  Day: "*Día*",
  SexualPreference: "*Preferencia sexual*",
  SexualPreferences: "*¿Cuáles son tus preferencias sexuales*",
  countrycode: "*Código de país*",
  PhonNumber: "*Número de teléfono*",
  // profile setup

  nolikesyet: "*Todavía no tienes ningún Me gusta*",
  nomatches: "*Todavía no tienes ninguna coincidencia*",
  likematches: "*Puedes comenzar a hacer coincidir perfiles dándoles Me gusta*",
  terms:"*Términos*",
  Privacy:"*Privacidad*",
  imprint:"*Impresión*",
  Address: "*Dirección*"
},

português: {
  Register:"Registrar",
  secondtext:"globalchat.us oferece novos níveis de encontros online. A criação definitiva de personalidade de IA do Global Chat ajudará os usuários a serem pareados com alguém que seja 94% o par perfeito deles",
  SomeRandomHeadingGoesHere:"Algum Texto Aleatório Vai Aqui",
  noflirtsrecharge: "Você não tem créditos suficientes",
  heygetflirts: "Obter flertes",
  SEARCHINGFORNOTIFICAITON:"BUSCANDO POR NOTIFICAÇÃO",
  Newconversation:"Nova conversa",
  MyPurchases: "Minhas Compras",
  mypurchasedes:
    "Aqui você encontrará os flertes e pacotes que comprou.",
  mypurchacedess: "Você ainda não tem transações",
  resetlink:
    "Digite seu endereço de e-mail e nós enviaremos o link de redefinição para você",
  // transaction

  ChatsConversation: "CONVERSAS",
  message: "Mensagem",
  likes: "curtidas",
  matches: "Matches",
  Liked: "Gostei",
  Disliked: "Não Gostei",
  JustVisitedYou: "Acabou de Visitar Você",
  LoadMore: "Carregar Mais",
  FliterUsers: "Filtrar Usuários",
  Youdonthaveanynotifications: "Você não tem notificações",
  ActiveNow: "Ativo Agora",

  // landing
  SignIn: "Entrar",
  FlirtToolNews: "Notícias do FlirtTool",
  Join: "Junte-se",
  Free: "Grátis",
  Today: "Hoje",
  BasicPackage: "Pacote Básico",
  twohoursfreechatexperience: "2 horas de experiência de chat grátis",
  Tryitforfree: "Experimente de graça",
  FlirtToolfor1Day: "FlirtTool por 1 Dia",
  Gblobalfor1Day:"Global por 1 Dia",
  RealChatFlatrate: "Pacote de Chat Real APENAS $2",
  Grabit: "Pegue",
  Createanaccount: "Criar uma conta",
  orgettheapp: "ou obtenha o aplicativo",
  OVER1MILLIONUSERSWORLDWIDE: "Mais de 1 milhão de usuários em todo o mundo",
  MatchingProfiles: "Perfis Correspondentes",
  UpcomingonFlirtTool: "Próximas no FlirtTool",
  FindRealLifeWithFlirtTool: "Encontre a Vida Real com o FlirtTool!",
  HowFlirtToolWorks: "Como o FlirtTool Funciona",
  HowFlirtToolWorksdes:
    "O FlirtTool oferece novos níveis de encontros online. A criação definitiva do FlirtTool de personalidade de IA, MAZU, ajudará os usuários a serem pareados com alguém que seja 94% o par perfeito deles",
  LEARNMORE: "SAIBA MAIS",
  About: "Sobre",
  PressAndMedia: "Imprensa e Mídia",
  Blogs: "Blogs",
  LiveChat: "Chat Ao Vivo",
  ChatNow: "Converse Agora",
  SocialMedia: "Mídias Sociais",
  ContactUs: "Contate-nos",
  WelcometoFlirttool: "Bem-vindo ao Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "Ou Crie uma Conta",
  UsernameorEmail: "Nome de Usuário ou Email",
  YourPassword: "Sua Senha",
  Forgotpassword: "Esqueceu a senha",
  Reset: "Redefinir",
  IAGREE: "EU CONCORDO COM OS TERMOS, CONDIÇÕES E POLÍTICA DE PRIVACIDADE",
  Clickhereto: "CLIQUE AQUI PARA VER NOSSOS TERMOS",

  // login

  // register
  FIRSTNAME: "NOME",
  LastName: "Sobrenome",
  Emailisnotvalid: "O Email não é válido",
  Username: "Nome de Usuário",
  Yourpasswords: "Sua senha",
  Passwordisnotvalid: "A senha não é válida",
  Confirmpassword: "Confirme a senha",
  Usernamemusthaveatleast: "O nome de usuário deve ter pelo menos",
  Alreadyhaveanaccount: "Já tem uma conta",
  LoginHere: "Faça login aqui",
  // register
  starthourlypackage: "Iniciar Pacote por Hora",
  // Profile
  SubmitReport: "Enviar Relatório",
  Blockcon: "Tem certeza de que deseja bloquear",
  Yes: "Sim",
  No: "Não",
  reportreason: "Digite o motivo deste relatório",
  Visits: "Visitas",
  Photos: "Fotos",
  UnlockExclusive: "Desbloquear Fotos Exclusivas",
  UnlockNow: "Desbloquear Agora",
  Personal: "Pessoal",
  Ethnicity: "Etnia",
  BodyType: "Tipo de Corpo",
  Gender: "Gênero",
  Height: "Altura",
  Relationship: "Relacionamento",
  Work: "Trabalho",
  Education: "Educação",
  LIFESTYLE: "ESTILO DE VIDA",
  LivesWith: "Mora Com",
  Car: "Carro",
  Religion: "Religião",
  Smoke: "Fuma",
  Drink: "Bebe",
  Travel: "Viagem",
  Education: "Educação",
  // profile

  // settings
  PhoneNumber: "Número de Telefone",
  GeneralSettings: "Configurações Gerais",
  SaveChanges: "Salvar Alterações",
  RemoveAccount: "Remover Conta",
  Yourprofile: "Seu perfil",
  AboutMe: "Sobre Mim",
  Relationshipstatus: "Estado Civil",
  YourCountry: "Seu País",
  ChooseYourCountry: "Escolha Seu País",
  Looks: "Aparência",
  Personality: "Personalidade",
  Character: "Caráter",
  Children: "Filhos",
  Friends: "Amigos",
  Pets: "Animais de Estimação",
  LivingWith: "Morando Com",
  MusicGenre: "Gênero Musical",
  Dish: "Prato",
  Song: "Música",
  Hobby: "Hobby",
  Book: "Livro",
  Movie: "Filme",
  ChangePassword: "Alterar Senha",
  NewPassword: "Nova Senha",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "CONFIGURE SEU PERFIL DE ENCONTROS",
  uploadphoto: "Faça upload de uma foto para definir sua Foto de Perfil",
  picnotupdated: "Foto do perfil não atualizada",
  pleaseupdatepicture: "Você deve fazer upload de uma foto de perfil para continuar",
  BirthYear: "Ano de Nascimento",
  Date: "Data",
  Month: "Mês",
  Day: "Dia",
  SexualPreference: "Preferência Sexual",
  SexualPreferences: "Qual é a sua preferência sexual",
  countrycode: "código do país",
  PhonNumber: "Número de Telefone",
  // profile setup

  nolikesyet: "Você ainda não tem curtidas",
  nomatches: "Você ainda não tem matches",
  likematches: "Você pode começar a combinar com perfis curtindo-os",
  terms:"Termos",
  Privacy:"Privacidade",
  imprint:"Impressão",
  Address: "Endereço"
},


// Schwedisch
sv: {
  Register:"Registrera",
  secondtext:"globalchat.us erbjuder nya nivåer av online-dating. Global Chat ultimata skapande av AI-personlighet kommer att hjälpa användare att paras ihop med någon som är 94% deras perfekta matchning",
  SomeRandomHeadingGoesHere:"Någon Slumpmässig Text Går Här",
  noflirtsrecharge: "Du har inte tillräckligt med krediter",
  heygetflirts: "Få flörtar",
  SEARCHINGFORNOTIFICAITON:"SÖKER EFTER NOTIFIKATION",
  Newconversation:"Ny konversation",
  MyPurchases: "Mina Köp",
  mypurchasedes:
    "Här hittar du de flörtar och paket du har köpt.",
  mypurchacedess: "Du har inga transaktioner än",
  resetlink:
    "Ange din e-postadress så skickar vi din återställningslänk",
  // transaction

  ChatsConversation: "SAMTAL (KONVERSATION)",
  message: "Meddelande",
  likes: "gillar",
  matches: "Matcher",
  Liked: "Gillad",
  Disliked: "Ogillad",
  JustVisitedYou: "Besökte dig nyss",
  LoadMore: "Ladda mer",
  FliterUsers: "Filtrera Användare",
  Youdonthaveanynotifications: "Du har inga aviseringar",
  ActiveNow: "Aktiv nu",

  // landing
  SignIn: "Logga in",
  FlirtToolNews: "FlirtTool Nyheter",
  Join: "Gå med",
  Free: "Gratis",
  Today: "Idag",
  BasicPackage: "Grundpaket",
  twohoursfreechatexperience: "2 timmars gratis chattupplevelse",
  Tryitforfree: "Prova gratis",
  FlirtToolfor1Day: "FlirtTool för 1 Dag",
  Gblobalfor1Day:"Global för 1 Dag",
  RealChatFlatrate: "Verklig Chatt Fast Pris ENDAST $2",
  Grabit: "Ta det",
  Createanaccount: "Skapa ett konto",
  orgettheapp: "eller få appen",
  OVER1MILLIONUSERSWORLDWIDE: "Över 1 miljon användare över hela världen",
  MatchingProfiles: "Matchande Profiler",
  UpcomingonFlirtTool: "Kommande på FlirtTool",
  FindRealLifeWithFlirtTool: "Hitta Verkliga Livet Med FlirtTool!",
  HowFlirtToolWorks: "Hur FlirtTool Fungerar",
  HowFlirtToolWorksdes:
    "FlirtTool erbjuder nya nivåer av online-dating. FlirtTools ultimata skapande av AI-personlighet MAZU kommer att hjälpa användare att paras ihop med någon som är 94% deras perfekta matchning",
  LEARNMORE: "LÄS MER",
  About: "Om",
  PressAndMedia: "Press och Media",
  Blogs: "Bloggar",
  LiveChat: "Livechatt",
  ChatNow: "Chatta Nu",
  SocialMedia: "Sociala Medier",
  ContactUs: "Kontakta Oss",
  WelcometoFlirttool: "Välkommen till Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "Eller Skapa ett Konto",
  UsernameorEmail: "Användarnamn eller E-post",
  YourPassword: "Ditt Lösenord",
  Forgotpassword: "Glömt lösenord",
  Reset: "Återställ",
  IAGREE: "JAG GODKÄNNER VILLKOR, REGLER OCH SEKRETESSPOLICY",
  Clickhereto: "KLICKA HÄR FÖR ATT SE VÅRA VILLKOR",

  // login

  // register
  FIRSTNAME: "FÖRNAMN",
  LastName: "Efternamn",
  Emailisnotvalid: "E-post är inte giltig",
  Username: "Användarnamn",
  Yourpasswords: "Ditt lösenord",
  Passwordisnotvalid: "Lösenordet är inte giltigt",
  Confirmpassword: "Bekräfta lösenord",
  Usernamemusthaveatleast: "Användarnamnet måste ha minst",
  Alreadyhaveanaccount: "Har redan ett konto",
  LoginHere: "Logga in här",
  // register
  starthourlypackage: "Börja Timmespaket",
  // Profile
  SubmitReport: "Skicka Rapport",
  Blockcon: "Är du säker på att du vill blockera",
  Yes: "Ja",
  No: "Nej",
  reportreason: "Ange din anledning till denna rapport",
  Visits: "Besök",
  Photos: "Foton",
  UnlockExclusive: "Lås upp Exklusiva Bilder",
  UnlockNow: "Lås upp Nu",
  Personal: "Personligt",
  Ethnicity: "Etnicitet",
  BodyType: "Kroppstyp",
  Gender: "Kön",
  Height: "Höjd",
  Relationship: "Relation",
  Work: "Arbete",
  Education: "Utbildning",
  LIFESTYLE: "LIVSSTIL",
  LivesWith: "Bor Med",
  Car: "Bil",
  Religion: "Religion",
  Smoke: "Röker",
  Drink: "Dricker",
  Travel: "Resa",
  Education: "Utbildning",
  // profile

  // settings
  PhoneNumber: "Telefonnummer",
  GeneralSettings: "Allmänna Inställningar",
  SaveChanges: "Spara Ändringar",
  RemoveAccount: "Ta bort konto",
  Yourprofile: "Din profil",
  AboutMe: "Om Mig",
  Relationshipstatus: "Relationsstatus",
  YourCountry: "Ditt Land",
  ChooseYourCountry: "Välj Ditt Land",
  Looks: "Utseende",
  Personality: "Personlighet",
  Character: "Karaktär",
  Children: "Barn",
  Friends: "Vänner",
  Pets: "Husdjur",
  LivingWith: "Bor Med",
  MusicGenre: "Musikgenre",
  Dish: "Rätt",
  Song: "Låt",
  Hobby: "Hobby",
  Book: "Bok",
  Movie: "Film",
  ChangePassword: "Ändra Lösenord",
  NewPassword: "Nytt Lösenord",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "INSTÄLLNING AV DIN DATINGPROFIL",
  uploadphoto: "Ladda upp foto för att ställa in din Profilbild",
  picnotupdated: "Profilbild är inte uppdaterad",
  pleaseupdatepicture: "Du bör ladda upp en profilbild för att fortsätta",
  BirthYear: "Födelseår",
  Date: "Datum",
  Month: "Månad",
  Day: "Dag",
  SexualPreference: "Sexuell Preferens",
  SexualPreferences: "Vad är din sexuella preferens",
  countrycode: "landskod",
  PhonNumber: "Telefonnummer",
  // profile setup

  nolikesyet: "Du har inga gillanden ännu",
  nomatches: "Du har inga matcher ännu",
  likematches: "Du kan börja matcha med profiler genom att gilla dem",
  terms:"Villkor",
  Privacy:"Integritet",
  imprint:"Impressum",
  Address: "Adress"
},


// Schwedisch
// Danish
da: {
  Register:"Registrer",
  secondtext:"globalchat.us tilbyder nye niveauer af online dating. Global Chat ultimative skabelse af AI-personlighed vil hjælpe brugerne med at blive matchet med nogen, der er 94% deres perfekte match",
  SomeRandomHeadingGoesHere:"Noget Tilfældig Tekst Går Her",
  noflirtsrecharge: "Du har ikke nok kreditter",
  heygetflirts: "Få flirt",
  SEARCHINGFORNOTIFICAITON:"SØGER EFTER MEDDELELSE",
  Newconversation:"Ny samtale",
  MyPurchases: "Mine Køb",
  mypurchasedes:
    "Her finder du de flirt og pakker, du har købt.",
  mypurchacedess: "Du har ikke foretaget nogen transaktioner endnu",
  resetlink:
    "Indtast din e-mail-adresse, og vi sender dig dit nulstillingslink",
  // transaction

  ChatsConversation: "CHAT (SAMTALE)",
  message: "Besked",
  likes: "synes godt om",
  matches: "Matcher",
  Liked: "Kunne lide",
  Disliked: "Misliked",
  JustVisitedYou: "Besøgte dig lige",
  LoadMore: "Indlæs mere",
  FliterUsers: "Filtrer Brugere",
  Youdonthaveanynotifications: "Du har ingen meddelelser",
  ActiveNow: "Aktiv nu",

  // landing
  SignIn: "Log ind",
  FlirtToolNews: "FlirtTool Nyheder",
  Join: "Bliv medlem",
  Free: "Gratis",
  Today: "I dag",
  BasicPackage: "Basispakke",
  twohoursfreechatexperience: "2 timers gratis chatoplevelse",
  Tryitforfree: "Prøv det gratis",
  FlirtToolfor1Day: "FlirtTool i 1 dag",
  Gblobalfor1Day:"Global for 1 dag",
  RealChatFlatrate: "Rigtig chat Flatrate KUN $2",
  Grabit: "Tag det",
  Createanaccount: "Opret en konto",
  orgettheapp: "eller få appen",
  OVER1MILLIONUSERSWORLDWIDE: "Over 1 million brugere over hele verden",
  MatchingProfiles: "Matchende profiler",
  UpcomingonFlirtTool: "Kommende på FlirtTool",
  FindRealLifeWithFlirtTool: "Find det virkelige liv med FlirtTool!",
  HowFlirtToolWorks: "Sådan fungerer FlirtTool",
  HowFlirtToolWorksdes:
    "FlirtTool tilbyder nye niveauer af online dating. FlirtTools ultimative skabelse af AI-personlighed, MAZU, vil hjælpe brugerne med at blive matchet med nogen, der er 94% deres perfekte match",
  LEARNMORE: "LÆR MERE",
  About: "Om",
  PressAndMedia: "Presse og Medier",
  Blogs: "Blogs",
  LiveChat: "Live Chat",
  ChatNow: "Chat Nu",
  SocialMedia: "Sociale medier",
  ContactUs: "Kontakt os",
  WelcometoFlirttool: "Velkommen til Flirttool",
  // end of landing

  // login
  OrCreateanAccount: "Eller Opret en Konto",
  UsernameorEmail: "Brugernavn eller E-mail",
  YourPassword: "Dit kodeord",
  Forgotpassword: "Glemt adgangskode",
  Reset: "Nulstil",
  IAGREE: "JEG ACCEPTERER VILKÅR, BETINGELSER OG PRIVATLIVSPOLITIK",
  Clickhereto: "KLIK HER FOR AT SE VORES VILKÅR",

  // login

  // register
  FIRSTNAME: "FORNAVN",
  LastName: "Efternavn",
  Emailisnotvalid: "E-mail er ikke gyldig",
  Username: "Brugernavn",
  Yourpasswords: "Dit kodeord",
  Passwordisnotvalid: "Adgangskoden er ikke gyldig",
  Confirmpassword: "Bekræft adgangskode",
  Usernamemusthaveatleast: "Brugernavnet skal have mindst",
  Alreadyhaveanaccount: "Har allerede en konto",
  LoginHere: "Log ind her",
  // register
  starthourlypackage: "Start Timepakke",
  // Profile
  SubmitReport: "Send Rapport",
  Blockcon: "Er du sikker på, at du vil blokere",
  Yes: "Ja",
  No: "Nej",
  reportreason: "Angiv din grund til denne rapport",
  Visits: "Besøg",
  Photos: "Billeder",
  UnlockExclusive: "Lås eksklusive billeder op",
  UnlockNow: "Lås op nu",
  Personal: "Personlig",
  Ethnicity: "Etnicitet",
  BodyType: "Kropstype",
  Gender: "Køn",
  Height: "Højde",
  Relationship: "Forhold",
  Work: "Arbejde",
  Education: "Uddannelse",
  LIFESTYLE: "LIVSSTIL",
  LivesWith: "Bor sammen med",
  Car: "Bil",
  Religion: "Religion",
  Smoke: "Røg",
  Drink: "Drikke",
  Travel: "Rejse",
  Education: "Uddannelse",
  // profile

  // settings
  PhoneNumber: "Telefonnummer",
  GeneralSettings: "Generelle indstillinger",
  SaveChanges: "Gem ændringer",
  RemoveAccount: "Fjern konto",
  Yourprofile: "Din profil",
  AboutMe: "Om mig",
  Relationshipstatus: "Forholdsstatus",
  YourCountry: "Dit land",
  ChooseYourCountry: "Vælg dit land",
  Looks: "Udseende",
  Personality: "Personlighed",
  Character: "Karakter",
  Children: "Børn",
  Friends: "Venner",
  Pets: "Kæledyr",
  LivingWith: "Bor sammen med",
  MusicGenre: "Musikgenre",
  Dish: "Ret",
  Song: "Sang",
  Hobby: "Hobby",
  Book: "Bog",
  Movie: "Film",
  ChangePassword: "Skift adgangskode",
  NewPassword: "Ny adgangskode",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "OPSÆT DIN DATINGPROFIL",
  uploadphoto: "Upload foto for at indstille dit profilbillede",
  picnotupdated: "Profilbillede er ikke opdateret",
  pleaseupdatepicture: "Du skal uploade et profilbillede for at fortsætte",
  BirthYear: "Fødselsår",
  Date: "Dato",
  Month: "Måned",
  Day: "Dag",
  SexualPreference: "Seksuel Præference",
  SexualPreferences: "Hvad er din seksuelle præference",
  countrycode: "landskode",
  PhonNumber: "Telefonnummer",
  // profile setup

  nolikesyet: "Du har endnu ingen likes",
  nomatches: "Du har endnu ingen matches",
  likematches: "Du kan begynde at matche med profiler ved at kunne lide dem",
  terms:"Vilkår",
  Privacy:"Privatliv",
  imprint:"Aftryk",
  Address: "Adresse"
},


// Danish

// Finnish
fi: {
  Register:"Rekisteröidy",
  secondtext:"globalchat.us tarjoaa uusia tasoja verkko-​seuranhakuun. Global Chatin lopullinen luomus, tekoälyn persoonallisuus, auttaa käyttäjiä löytämään parin, joka on 94% heidän täydellisestä vastineestaan",
  SomeRandomHeadingGoesHere:"Jotain Satunnaista Tekstiä Tässä",
  noflirtsrecharge: "Sinulla ei ole tarpeeksi krediittejä",
  heygetflirts: "Hae flirttejä",
  SEARCHINGFORNOTIFICAITON:"Etsitään Ilmoitusta",
  Newconversation:"Uusi keskustelu",
  MyPurchases: "Ostokseni",
  mypurchasedes:
    "Täältä löydät ostamasi flirtit ja paketit.",
  mypurchacedess: "Sinulla ei ole vielä tapahtumia",
  resetlink:
    "Syötä sähköpostiosoitteesi, niin lähetämme sinulle nollauslinkin",
  // transaction

  ChatsConversation: "KESKUSTELUT",
  message: "Viesti",
  likes: "tykkäystä",
  matches: "Vastinetta",
  Liked: "Tykätty",
  Disliked: "Ei Tykätty",
  JustVisitedYou: "Kävi juuri luonasi",
  LoadMore: "Lataa Lisää",
  FliterUsers: "Suodata Käyttäjiä",
  Youdonthaveanynotifications: "Sinulla ei ole ilmoituksia",
  ActiveNow: "Aktiivinen Nyt",

  // landing
  SignIn: "Kirjaudu sisään",
  FlirtToolNews: "FlirtTool Uutiset",
  Join: "Liity",
  Free: "Ilmainen",
  Today: "Tänään",
  BasicPackage: "Peruspaketti",
  twohoursfreechatexperience: "2 tunnin ilmainen chat-kokemus",
  Tryitforfree: "Kokeile ilmaiseksi",
  FlirtToolfor1Day: "FlirtTool 1 päiväksi",
  Gblobalfor1Day:"Gloabl 1 päiväksi",
  RealChatFlatrate: "Oikea Chat Kiinteähinta VAIN $2",
  Grabit: "Tartu siihen",
  Createanaccount: "Luo tili",
  orgettheapp: "tai lataa sovellus",
  OVER1MILLIONUSERSWORLDWIDE: "Yli 1 miljoona käyttäjää maailmanlaajuisesti",
  MatchingProfiles: "Sopivat Profiilit",
  UpcomingonFlirtTool: "Tulevat FlirtToolissa",
  FindRealLifeWithFlirtTool: "Löydä Todellinen Elämä FlirtToolilla!",
  HowFlirtToolWorks: "Kuinka FlirtTool Toimii",
  HowFlirtToolWorksdes:
    "FlirtTool tarjoaa uusia tasoja verkko-​seuranhakuun. FlirtToolin lopullinen luomus, tekoälyn persoonallisuus MAZU, auttaa käyttäjiä löytämään parin, joka on 94% heidän täydellisestä vastineestaan",
  LEARNMORE: "OPPIA LISÄÄ",
  About: "Tietoja",
  PressAndMedia: "Lehdistö ja Media",
  Blogs: "Blogit",
  LiveChat: "Live Chat",
  ChatNow: "Keskustele Nyt",
  SocialMedia: "Sosiaalinen Media",
  ContactUs: "Ota Yhteyttä",
  WelcometoFlirttool: "Tervetuloa Flirttooliin",
  // end of landing

  // login
  OrCreateanAccount: "Tai Luo Tili",
  UsernameorEmail: "Käyttäjänimi tai Sähköposti",
  YourPassword: "Salasanasi",
  Forgotpassword: "Unohtuiko salasana",
  Reset: "Nollaa",
  IAGREE: "HYVÄKSYN EHDOT, KÄYTTÖEHDOT JA YKSITYISYYSPOLITIIKKA",
  Clickhereto: "KLIKATKAAN TÄSTÄ NÄHDÄKSESI EHTOMME",

  // login

  // register
  FIRSTNAME: "ETUNIMI",
  LastName: "Sukunimi",
  Emailisnotvalid: "Sähköposti ei ole kelvollinen",
  Username: "Käyttäjänimi",
  Yourpasswords: "Salasanasi",
  Passwordisnotvalid: "Salasana ei ole kelvollinen",
  Confirmpassword: "Vahvista salasana",
  Usernamemusthaveatleast: "Käyttäjänimen on oltava vähintään",
  Alreadyhaveanaccount: "Onko sinulla jo tili",
  LoginHere: "Kirjaudu sisään täällä",
  // register
  starthourlypackage: "Aloita Tuntipaketti",
  // Profile
  SubmitReport: "Lähetä Raportti",
  Blockcon: "Oletko varma, että haluat estää",
  Yes: "Kyllä",
  No: "Ei",
  reportreason: "Syötä syy tälle raportille",
  Visits: "Vierailut",
  Photos: "Valokuvat",
  UnlockExclusive: "Avaa eksklusiiviset kuvat",
  UnlockNow: "Avaa Nyt",
  Personal: "Henkilökohtainen",
  Ethnicity: "Etnisyys",
  BodyType: "Vartalon Tyyppi",
  Gender: "Sukupuoli",
  Height: "Pituus",
  Relationship: "Suhde",
  Work: "Työ",
  Education: "Koulutus",
  LIFESTYLE: "ELÄMÄNTYYLI",
  LivesWith: "Asuu Yhdessä",
  Car: "Auto",
  Religion: "Uskonto",
  Smoke: "Savu",
  Drink: "Juoma",
  Travel: "Matkustaa",
  Education: "Koulutus",
  // profile

  // settings
  PhoneNumber: "Puhelinnumero",
  GeneralSettings: "Yleiset Asetukset",
  SaveChanges: "Tallenna Muutokset",
  RemoveAccount: "Poista Tili",
  Yourprofile: "Profiilisi",
  AboutMe: "Minusta",
  Relationshipstatus: "Suhdetilanne",
  YourCountry: "Maasi",
  ChooseYourCountry: "Valitse Maasi",
  Looks: "Ulkonäkö",
  Personality: "Persoonallisuus",
  Character: "Luonne",
  Children: "Lapset",
  Friends: "Ystävät",
  Pets: "Lemmikit",
  LivingWith: "Asuu Yhdessä",
  MusicGenre: "Musiikkityyli",
  Dish: "Ruokalaji",
  Song: "Kappale",
  Hobby: "Harrastus",
  Book: "Kirja",
  Movie: "Elokuva",
  ChangePassword: "Vaihda Salasana",
  NewPassword: "Uusi Salasana",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "ASETUKSESI DATING-PROFIILISI",
  uploadphoto: "Lataa valokuva asettaaksesi profiilikuvan",
  picnotupdated: "Profiilikuva ei ole päivitetty",
  pleaseupdatepicture: "Sinun on ladattava profiilikuva jatkaaksesi",
  BirthYear: "Syntymävuosi",
  Date: "Päivämäärä",
  Month: "Kuukausi",
  Day: "Päivä",
  SexualPreference: "Seksuaalinen Mieltymys",
  SexualPreferences: "Mikä on seksuaalinen mieltymyksesi",
  countrycode: "maakoodi",
  PhonNumber: "Puhelinnumero",
  // profile setup

  nolikesyet: "Sinulla ei ole vielä tykkäyksiä",
  nomatches: "Sinulla ei ole vielä vastinetta",
  likematches: "Voit aloittaa profiilien vastaamisen tykkäämällä niistä",
  terms:"Ehdot",
  Privacy:"Yksityisyys",
  imprint:"Jälki",
  Address: "Osoite"
},

// Finnish



// Serbian
sr: {
  Register:"Региструј се",
  secondtext:"globalchat.us nudi nove nivoe online upoznavanja. Global Chat ultimate kreacija veštačke inteligencije pomoći će korisnicima da budu upareni sa nekim ko je 94% njihov savršen spoj",
  SomeRandomHeadingGoesHere:"Neki Nasumičan Tekst Ide Ovde",
  noflirtsrecharge: "Nemate dovoljno kredita",
  heygetflirts: "Dobijte flertove",
  SEARCHINGFORNOTIFICAITON:"TRAŽENJE OBAVEŠTENJA",
  Newconversation:"Nova konverzacija",
  MyPurchases: "Moje Kupovine",
  mypurchasedes:
    "Ovde ćete pronaći flertove i pakete koje ste kupili.",
  mypurchacedess: "Još uvek nemate transakcija",
  resetlink:
    "Unesite svoju email adresu i poslaćemo vam link za resetovanje",
  // transaction

  ChatsConversation: "RAZGOVORI",
  message: "Poruka",
  likes: "sviđanja",
  matches: "Spojevi",
  Liked: "Sviđa se",
  Disliked: "Ne sviđa se",
  JustVisitedYou: "Upravo Vas je posetio",
  LoadMore: "Učitaj više",
  FliterUsers: "Filtriraj Korisnike",
  Youdonthaveanynotifications: "Nemate nikakvih obaveštenja",
  ActiveNow: "Aktivan sada",

  // landing
  SignIn: "Prijavi se",
  FlirtToolNews: "Vesti o FlirtAlatu",
  Join: "Pridruži se",
  Free: "Besplatno",
  Today: "Danas",
  BasicPackage: "Osnovni Paket",
  twohoursfreechatexperience: "2 sata besplatnog iskustva u četovanju",
  Tryitforfree: "Isprobaj besplatno",
  FlirtToolfor1Day: "FlirtAlat na 1 dan",
  Gblobalfor1Day:"Global na 1 dan",
  RealChatFlatrate: "Pravi Čet Fiksna Cena SAMO $2",
  Grabit: "Uhvati",
  Createanaccount: "Napravi nalog",
  orgettheapp: "ili preuzmi aplikaciju",
  OVER1MILLIONUSERSWORLDWIDE: "Preko 1 Milion Korisnika širom Sveta",
  MatchingProfiles: "Profili koji se Podudaraju",
  UpcomingonFlirtTool: "Nadolazeće na FlirtAlatu",
  FindRealLifeWithFlirtTool: "Pronađi Stvarni Život uz FlirtAlat!",
  HowFlirtToolWorks: "Kako FlirtAlat Funkcioniše",
  HowFlirtToolWorksdes:
    "FlirtAlat nudi nove nivoe online upoznavanja. Krajnja kreacija FlirtAlata, veštačka inteligencija MAZU, pomoći će korisnicima da budu upareni sa nekim ko je 94% njihov savršen spoj",
  LEARNMORE: "SAZNAJ VIŠE",
  About: "O nama",
  PressAndMedia: "Štampa i Mediji",
  Blogs: "Blogovi",
  LiveChat: "Čet uživo",
  ChatNow: "Čet sada",
  SocialMedia: "Društvene Mreže",
  ContactUs: "Kontaktirajte nas",
  WelcometoFlirttool: "Dobrodošli na FlirtAlat",
  // end of landing

  // login
  OrCreateanAccount: "Ili Napravi Nalog",
  UsernameorEmail: "Korisničko ime ili Email",
  YourPassword: "Vaša Lozinka",
  Forgotpassword: "Zaboravljena lozinka",
  Reset: "Resetuj",
  IAGREE: "SAGLASAN SAM SA USLOVIMA, PRAVILIMA I POLITIKOM PRIVATNOSTI",
  Clickhereto: "KLIKNI OVDE DA POGLEDAS NAŠE USLOVE",

  // login

  // register
  FIRSTNAME: "IME",
  LastName: "Prezime",
  Emailisnotvalid: "Email nije validan",
  Username: "Korisničko ime",
  Yourpasswords: "Vaša lozinka",
  Passwordisnotvalid: "Lozinka nije validna",
  Confirmpassword: "Potvrdi lozinku",
  Usernamemusthaveatleast: "Korisničko ime mora imati barem",
  Alreadyhaveanaccount: "Već imate nalog",
  LoginHere: "Prijavite se ovde",
  // register
  starthourlypackage: "Počni Paket Po Satu",
  // Profile
  SubmitReport: "Pošalji Izveštaj",
  Blockcon: "Da li ste sigurni da želite da blokirate",
  Yes: "Da",
  No: "Ne",
  reportreason: "Unesite razlog za ovaj izveštaj",
  Visits: "Posete",
  Photos: "Fotografije",
  UnlockExclusive: "Otključajte ekskluzivne slike",
  UnlockNow: "Otključaj Sada",
  Personal: "Lično",
  Ethnicity: "Etnička Pripadnost",
  BodyType: "Tip Tela",
  Gender: "Pol",
  Height: "Visina",
  Relationship: "Veza",
  Work: "Posao",
  Education: "Obrazovanje",
  LIFESTYLE: "STIL ŽIVOTA",
  LivesWith: "Živi Sa",
  Car: "Auto",
  Religion: "Religija",
  Smoke: "Pušenje",
  Drink: "Pijenje",
  Travel: "Putovanja",
  Education: "Obrazovanje",
  // profile

  // settings
  PhoneNumber: "Broj Telefona",
  GeneralSettings: "Opšta Podešavanja",
  SaveChanges: "Sačuvaj Promene",
  RemoveAccount: "Ukloni Nalog",
  Yourprofile: "Vaš Profil",
  AboutMe: "O Meni",
  Relationshipstatus: "Status Veze",
  YourCountry: "Vaša Zemlja",
  ChooseYourCountry: "Izaberite Vašu Zemlju",
  Looks: "Izgled",
  Personality: "Ličnost",
  Character: "Karakter",
  Children: "Deca",
  Friends: "Prijatelji",
  Pets: "Kućni Ljubimci",
  LivingWith: "Živi Sa",
  MusicGenre: "Muzički Žanr",
  Dish: "Jelo",
  Song: "Pesma",
  Hobby: "Hobi",
  Book: "Knjiga",
  Movie: "Film",
  ChangePassword: "Promeni Lozinku",
  NewPassword: "Nova Lozinka",

  // settings

  // profile setup
  SETUPYOURDATINGPROFILE: "Podesi Svoj Profil za Upoznavanje",
  uploadphoto: "Postavi fotografiju da postaviš Svoju Profilnu Sliku",
  picnotupdated: "Profilna slika nije ažurirana",
  pleaseupdatepicture: "Morate postaviti profilnu sliku da biste nastavili",
  BirthYear: "Godina Rođenja",
  Date: "Datum",
  Month: "Mesec",
  Day: "Dan",
  SexualPreference: "Seksualna Preferencija",
  SexualPreferences: "Koja je tvoja seksualna preferencija",
  countrycode: "Pozivni Broj",
  PhonNumber: "Broj Telefona",
  // profile setup

  nolikesyet: "Još uvek nemate lajkova",
  nomatches: "Još uvek nemate spojeva",
  likematches: "Možete početi da se uparujete sa profilima lajkovanjem",
  terms:"Uslovi",
  Privacy:"Privatnost",
  imprint:"Štampa",
  Address: "Adresa"
},

// Serbian
  },
};
