import gql from "graphql-tag"


export const GET_BLOGS = gql`query blogCollection($limit:Int!){
  blogCollection(limit:$limit){
    total
    skip
    items{
      title
      subtitle
      sys{
        id
        spaceId
        publishedVersion
      }
      cover{
        url
      }
      content{
        json
      }
    }
  }
}`

export const GET_THEADS = gql`query {
  topHeaderCollection{
    items{
      title
      description
      buttonLabel
      picture{
        url
      }
    }
  }
}`
export const THEAD_PROMO = gql`query {
  topInternalPromoCollection{
    items{
      promoCover{
        url
      }      
    }
  }
}`

export const PROMO_PIC = gql`query {
  promoCollection {
    items {
      promotionName
      picture {
        url
      }
    }
  }
}`

export const ADD_COL = gql`query{
  additionalCollection{
    items{
      title
      subtitle
      cover{
        url
      }
    } 
  }
}`

export const VIDEO = gql`query {
  promoVideoCollection{
    items{
      videolink
    }
  }
}`

export const BL_DETAIL = gql`query blog($id:String!){
  blog(id:$id){
    title
    subtitle
    content{
      json
    }
    cover{
      url
    }
    authorPicture{
      url
    }
    authorType
    authorName
    authorDescription
    instagram
    facebook
    twitter
    youtube
  }
}`


export const VIDEO_GALLERY = gql`query videoGalleryCollection($limit:Int!){
	videoGalleryCollection(limit:$limit){
    items{
      title
      description
      thumbnail{
        url
      }
      youtubeLink
    }
  }
}`
export const SEND_SUPPORT_EMAIL = gql`query sendSupportEmail($name:String!,$email:String!,$message:String!){
	sendSupportEmail(name:$name,email:$email,message:$message)
}`

export const ALL_AUTHORS = gql`query authorCollection($limit:Int!){
   authorCollection(limit:$limit){
    items{
      name
      picture{
        url
      }
      shortDesc
      picture{
        url
      }
      authShort
      sys{
        firstPublishedAt
      }
    }
  }
}`
export const GET_AUTHOR_CONTENT = gql`query affilateContentCollection($where: AffilateContentFilter!){
  affilateContentCollection(where:$where){
    total
    items{
      title
      subtitle
      youtubeVideo
      tags
      cover{
        url
      }
      sys{
    		firstPublishedAt
      }
      content{
        json
      }
    }
  }
}`

export const GET_AUTHOR_EXTRAS = gql`query affilateExtrasCollection($where: AffilateExtrasFilter!){
  affilateExtrasCollection(where:$where){
    total
    items{
      title
      coverImage{
        url
      }
      buttonText
      link
      affilateShort
    }
  }
}`

export const SINGLE_AUTHOR_CONTENT = gql`query affilateContentCollection($limit: Int!, $skip:Int!){
  affilateContentCollection(limit:$limit, skip:$skip){
    total
    items{
      title
      youtubeVideo
      subtitle
      tags
      cover{
        url
      }
      author{
        sys{
          id
        }
      }
      content{
        json
      }
    }
  }
}`

export const GET_AUTHOR = gql`query  author($id: String!){
  author(id: $id) {
    name
    picture {
      url
    }
    shortDesc
    picture {
      url
    }
    authShort
    sys {
      firstPublishedAt
    }
  }
}
`
export const GET_RECENT_POST = gql`query affilateContentCollection(
  $order:[AffilateContentOrder]!,
  $limit:Int!
){
  affilateContentCollection(
    order:$order,
    limit:$limit
  ){
    items{
      title      
      subtitle
      youtubeVideo
      affilateShort
      cover{url}
      sys{
        publishedAt
      }
    }
  }
}`

export const AUTHOR_COLLECTION = gql`query authorCollection($limit:Int!,$order:[AuthorOrder]!,$where:AuthorFilter!){
  authorCollection(limit:$limit,order:$order, where:$where){
    items{
			name
      instagram
      facebook
      picture{
        url
      }
      sys{
    		firstPublishedAt
      }
      youtube
			twitter
      shortDesc
      authShort     
    }
  }
}`
export const GET_AUTHOR_WSHORT = gql`query getAuthor($where:AuthorFilter!){
  authorCollection(where:$where){
    items{
			name
      instagram
      facebook
      picture{
        url
      }
      sys{
    		firstPublishedAt
      }
      youtube
			twitter
      shortDesc
      authShort     
    }
  }
}`

export const GUIDE_PAGES = gql`query {
	guidePagesCollection(where:{type_contains:"basicGuide"}){
    total
    items{
      # content{
      #   json
      # }
      slug
      type
      title
      headerTitle
    }
  }
}`

export const GUIDE_WSLUG = gql`query 	guidePagesCollection($where:GuidePagesFilter!){
	guidePagesCollection(where:$where){
    total
    items{
      content{
        json
      }
      slug
      type
      title
      headerTitle
    }
  }
}`

export const BG_SLIDERS = gql`query {
	backgroundSliderCollection{
    total
    items{
      picture{
        url
      }
    }
  }
}`