import axios from 'axios'
import store from '../store'
import router from '../router'
import { flirttool, onLogout } from '../v-apollo'
import { USER_LOGIN, USER_REGISTER } from "../queries/auth"

let apollo = flirttool.apolloClient;

export const logout = () => {
    store.commit('setUserData', {})
    store.commit('setFlirtTok', null)
    onLogout(flirttool)
    router.push('/')
    // window.location.assign('/login')
}

export const login = (emailOrUname, password, callback) => {
    apollo
        .mutate({
            mutation: USER_LOGIN,
            // authErrs: [],
            variables: {
                usernameOrEmail: emailOrUname,
                password: password,
            },
        })
        .then(({ data }) => {
            // snippet to clear the cookie
            // document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            callback({ data: data, fail: false })
        })
        .catch((e) => {
            callback({ data: e, fail: true })
            console.log(e);
        });
};

export const register = (username, email, fname, lname, pass, afStr, addr,userType,preference, callback) => {
    apollo.mutate({
        mutation: USER_REGISTER,
        variables: {
            addr: addr,
            options: {
                af_id: afStr,
                email: email,
                username: username,
                password: pass,
                firstName: fname,
                lastName: lname,
            },
            userType:userType != null ? userType : '',
            preference:preference != null ? preference : '',
            modelData:null
        },
    }).then(({ data }) => {
        callback({ data: data, fail: false })
    })
        .catch((e) => {
            console.log(Object.keys(e), "enumerate")
            callback({ data: e, fail: true })
        });

}
export const countryRouting = (to, from, next) => {
    // const allowed_codes = ["UK", "GB", "ENG", "US", "AU", "NZ", "CA", "ET", "TW", "LK", "IN", "IND", "PH", "MX"]
    axios.get("https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29").then(() => {
        next()
        // if (allowed_codes.includes(data.country_code)) {
        //     next()
        // } else {
        //     router.push("/country-exempt")
        // }
    }).catch(() => {
        next()
    })
}
export const authGuard = (to, from, next) => {
    const cook = store.state.core.flirtok
    if (cook) {
        if (store.state.core.user.profileComplete) {
            next()
        } else {
            router.push("/profile-setup")
        }
    } else { router.push("/login") }

};

export const affGuard = (to, from, next) => {
    const isAf = store.state.core.afStat
    if (isAf) { next() } else {
        router.go("/find-matches")
    }
}
export const affsGuard = (to, from, next) => {
    const isAf = store.state.core.afSstat
    if (isAf) { next() } else {
        router.go("/find-matches")
    }
}