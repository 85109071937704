<template>
     <component :is="appliedComponent" />
    </template>

<script>
import {activeTheme} from '@/themes.js'
import FlirttoolImprint from './FlirttoolImprint.vue'
import PurpleImprint from './PurpleImprint.vue'
import RedImprint from './RedImprint.vue'

export default {
  components: {
    FlirttoolImprint,
    // YellowImprint,
    PurpleImprint,
    RedImprint
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolImprint'
        // case 'Erotic':
        //   return 'FlirttoolImprint'
        // case 'Yellow':
        //   return 'YellowImprint'
        case 'Red':
          return 'RedImprint'
          case 'Purple':
          return 'PurpleImprint'
        default:
          return 'FlirttoolImprint'
      }
    }
  }
}

</script>

