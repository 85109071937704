<template>
	<div class="mb-96">
		<template>
			<div
				class="text-left font-medium lg:w-1/3 xl:w-1/3 w-full xl:mt-28 lg:mt-28"
			>
				<div class="md:flex dark:bg-gray-700 bg-pink-00 shadow-xl border-2" style="height: 85%">
					<div class="w-full p-4">
						<div class="relative">
							<input
								type="text"
								class="
									w-full
									dark:text-gray-200
									dark:bg-gray-800
									h-12
									rounded
									focus:outline-none
									px-3
									focus:shadow-md
								"
								placeholder="Search..."
							/>
							<i class="fa fa-search absolute right-3 top-4 text-gray-300"></i>
						</div> <center v-if="loadingChats">
						<div class="mt-14 lds-ellipsis">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</center>
						<ul class="overflow-y-scroll" style="height: 100%">
							<li
								v-if="newDm"
								@click="dmTargetSelected"
								class="
									flex
									justify-between
									items-center
									bg-white border-2 shadow-2xl
									dark:bg-gray-800
									mt-2
									p-2
									hover:shadow-lg
									rounded
									cursor-pointer
									transition
								"
							>
								<div class="flex ml-2">
									<img
										class="rounded-full mr-3 w-16 h-16"
										:src="getURL(newTarget.profilePic)"
									/>
									<div class="flex flex-col ml-2 text-black dark:text-white">
										<span class="font-medium"
											>{{ newTarget.base_profile.firstName }}
											{{ newTarget.base_profile.lastName }}</span
										>
									</div>
								</div>
								<div class="flex flex-col items-center">
									<span class="text-gray-300">11:26</span>
									<i class="fa fa-star text-green-400"></i>
								</div>
							</li>

							<li
								@click="userSelected(item)"
								v-for="(item, idx) in allConversations"
								:key="idx"
								class="
									flex
									justify-between
									items-center
									bg-white
									dark:bg-gray-800
									mt-2
									p-2
									hover:shadow-lg
									rounded
									cursor-pointer
									transition
								"
							>
								<div class="flex ml-2">
									<img
										class="rounded-full mr-3 w-16 h-16"
										:src="getURL(item.m_one.profilePic)"
									/>
									<div class="flex flex-col ml-2 text-black dark:text-white">
										<span class="font-medium"
											>{{ item.m_one.base_profile.firstName }}
											{{ item.m_one.base_profile.lastName }}</span
										>
										<span class="text-sm text-gray-400 truncate w-44">
											{{ item.lastMessage.body }}</span
										>
									</div>
								</div>
								<div class="flex flex-col items-center">
									<span class="text-gray-300">{{
										parseTimeStr(item.lastMessage.createdAt)
									}}</span>
									<i class="fa fa-star text-green-400"></i>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<!-- </div>
		</div> -->
			</div>
		</template>
	</div>
</template>
<script>
import { DM_TARGET_INFO } from '../../queries/auth';
import { ALL_CONVERSATIONS } from '../../queries/chat';
import { getURL, parseTimeStr } from '../../utils/helpers'
export default {
	// components: { SideChatSelector },
	apollo: {
		allConversations: {
			query: ALL_CONVERSATIONS,
			result({ data: { allConversations } }) {
				this.allConversations.forEach(cv => {
					// console.log(new Date(parseInt(cv.lastMessage.createdAt).toLocaleTimeString()),"SHEESH")
					cv.lastMessage.createdAt = new Date(parseInt(cv.lastMessage.createdAt)).toLocaleTimeString()
				})
				this.allConversations = allConversations
			}
		},
		dmTargetInfo: {
			query: DM_TARGET_INFO,
			variables() {
				return {
					dtarget: this.$route.query.dm
				}
			},
			result({ data: { dmTargetInfo: { target, convExsists } } }) {
				if (!convExsists) {
					this.newDm = true;
					this.newTarget = target
					this.disabled = false
				} else {
					const selected = this.allConversations.find((cv) => cv.m_one.username === target.username)
					this.selectedConvo = selected
					// this.fetchConvo(selected)
					this.userSelected(selected)
					console.log(selected, "YEAA")
					console.log(this.allConversations, "FUCK")
					console.log(target)
				}
			},
			error(e) {
				this.$refs.sideSelector.pickDmTarget(parseInt(this.$route.query.dm))
				console.error(e)
			}
		},

	},
	methods: {
		getURL,
		parseTimeStr,
		fetchTargetInfo() {
			this.$apollo.query({
				query: DM_TARGET_INFO,
				variables: {
					dtarget: this.$route.query.dm
				}
			}).then(({ data: { dmTargetInfo } }) => {
				this.newDm = true;
				this.newTarget = dmTargetInfo
				this.disabled = false
				console.log(dmTargetInfo, "Targe Info")
			}).catch(e => {
				// This means either conversation exists or some network issue
				// TODO: select the dm user from the list 
				this.$refs.sideSelector.pickDmTarget(parseInt(this.$route.query.dm))
				console.error(e)
			})
		},
		dmTargetSelected() {
			this.$router.push(`/chat-details/${this.newTarget.id}?dm=1`)
		},
		userSelected(item) {
			this.$router.push('/chat-details/' + item.id)
			// this.$emit('userSelected', item)
		},
		onResize() {
			if (window.innerWidth >= 1200) {
				this.$router.push(this.$route.query.dm ? `/chat?dm=${this.$route.query.dm}` : '/chat')
			} else if (window.innerWidth >= 992) {
				this.$router.push(this.$route.query.dm ? `/chat?dm=${this.$route.query.dm}` : '/chat')
			} else if (window.innerWidth >= 768) {
				console.log("Medium", window.innerWidth)
			} else if (window.innerWidth >= 576) {
				console.log("Small", window.innerWidth)
			} else if (window.innerWidth < 576) {
				console.log("X-Small")
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize)
	},

	created() {
		if (this.$route.query.dm) {
			// this.fetchTargetInfo()
			this.$apollo.queries.dmTargetInfo.refetch()
		}
		this.$apollo.queries.allConversations.refetch()
		window.addEventListener('resize', this.onResize)
	},
	data: () => ({
		// userSelected: false,
		newDm: false,
		disabled: true,
		allConversations: [],
		newTarget: null
	}),
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
	width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 20px;
	background: rgba(150, 148, 148, 0.212);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(218, 213, 213, 0.274);
}
</style>
