<!-- <template>



    <div class="grid grid-cols-11 rounded-lg shadow-xl gap-4 mt-8 bg-orange-100" style="background-color: #F3E5CD;" >
    <div class="bg-orange-100 p-4"> <img src="@/assets/mazu/New folder/home.png" alt=""> </div>
    <div class="bg-orange-100 p-4">  <img src="@/assets/mazu/New folder/chaticon.png" alt=""></div>
    <div class="bg-orange-100 p-4"> 3</div>
    <div class="bg-orange-100 p-4"> 4</div>
    <div class="bg-orange-100 p-4"> 5</div>
    <div class="bg-orange-100 p-4"> 6</div>
    <div class="bg-orange-100 p-4"> 7</div>
    <div class="bg-orange-100 p-4"> 8</div>
    <div class="bg-orange-100 p-4"> 9</div>
    <div class="bg-orange-100 p-4"> 10</div>
    <div class="bg-orange-100 p-4"> 11</div>
  </div>
</template> -->


<!-- <template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"><img src="@/assets/mazu/New folder/home.png" alt=""></div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"><img src="@/assets/mazu/New folder/chaticon.png" alt=""></div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">3</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">4</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">5</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">6</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">7</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">8</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">9</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">10</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">11</div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>
<script>
import Swiper from 'swiper';


export default {
  mounted() {
    const mySwiper = new Swiper('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
};
</script>
<style>
.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 0 -16px;
}

.carousel-item {
  flex-shrink: 0;
  width: 100%;
  margin: 0 16px;
  scroll-snap-align: center;
}

</style> -->

<!-- <template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"><img src="@/assets/mazu/New folder/home.png" alt=""></div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"><img src="@/assets/mazu/New folder/chaticon.png" alt=""></div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">3</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">4</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">5</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">6</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">7</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">8</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">9</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">10</div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4">11</div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>
<script>
import Swiper from 'swiper';
// import 'swiper/swiper-bundle.css';

export default {
  mounted() {
    const mySwiper = new Swiper('.swiper-container', {
      slidesPerView: 8,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
};
</script>
<style>
.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 0 -16px;
}

.carousel-item {
  flex-shrink: 0;
  width: 100%;
  margin: 0 16px;
  scroll-snap-align: center;
}

</style> -->
<template>
  <div class="swiper-container border-white hidden lg:block  border-4 rounded-xl">
    <div class="swiper-wrapper">

      <div class="swiper-slide flex justify-center ">
        <div class="bg-orange-100 p-4  "> <router-link to="/"> <svg xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#C48C00" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg></router-link>

        </div>
      </div>
      <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"><router-link to="/chat"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#C48C00" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
            </svg></router-link>
        </div>
      </div>
      <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"> <router-link to="/matches"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#C48C00" class="w-12 h-12 font">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg></router-link>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"> <router-link to="/liked"><img src="@/assets/mazu/matchmaking.png"
              class="mt-2 pl-4" alt=""></router-link></div>
      </div>
      <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"> <router-link to="/likes"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#C48C00" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
            </svg></router-link>
        </div>
      </div>
      <!-- <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="#C48C00" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
          </svg>
        </div>
      </div> -->
      <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="#C48C00" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
        </div>
      </div>
      <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="#C48C00" class="w-12 h-12">
            <path stroke-linecap="round"
              d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
          </svg>
        </div>
      </div>
      <div class="swiper-slide flex justify-center">
        <div class="bg-orange-100 p-4"> <router-link to="/flirts"><img src="@/assets/mazu/infinite.png" class="mt-3 pl-1" alt=""></router-link>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"><img src="@/assets/mazu/mazumenu.png" class="mt-3 pl-4" alt=""></div>
      </div>

      <div class="swiper-slide">
        <div class="bg-orange-100 p-4"><img src="@/assets/mazu/market.png" class="mt-3 pl-4" alt=""></div>
      </div>
    </div>

    <!-- <div class="swiper-pagination"></div> -->
    <!-- <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div> -->
  </div>
</template>
<script>
import Swiper from 'swiper';
// import 'swiper/swiper-bundle.css';

export default {
  mounted() {
    const mySwiper = new Swiper('.swiper-container', {
      slidesPerView: 8,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  },
};
</script>
<style>
.swiper-slide {
  border-right: 1px solid #ccc;
}

.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 0 -16px;
}

.carousel-item {
  flex-shrink: 0;
  width: 100%;
  margin: 0 16px;
  scroll-snap-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.swiper-container {
  background-color: #F3E5CD;
}

/* responsive styles */
@media (max-width: 640px) {

  /* for screens up to 640px wide */
  .swiper-slide {
    /* show 2 slides per view */
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (min-width: 641px) and (max-width: 768px) {

  /* for screens between 641px and 768px wide */
  .swiper-slide {
    /* show 3 slides per view */
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {

  /* for screens between 769px and 1024px wide */
  .swiper-slide {
    /* show 4 slides per view */
    flex: 0 0 calc(25% - 20px);
    max-width: calc(25% - 20px);
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {

  /* for screens between 1025px and 1280px wide */
  .swiper-slide {
    /* show 5 slides per view */
    flex: 0 0 calc(20% - 20px);
    max-width: calc(20% - 20px);
  }
}

@media (min-width: 1281px) {

  /* for screens wider than 1280px */
  .swiper-slide {
    /* show 8 slides per view */
    flex: 0 0 calc(12.5% - 20px);
    max-width: calc(12.5% - 20px);
  }
}</style>

