import gql from "graphql-tag";

export const ALL_CONVERSATIONS = gql`
  query {
    allConversations {
      id
      m_one {
        id
        username
        usrType
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
      m_two {
        id
        username
        usrType
        base_profile {
          id
          firstName
          lastName
        }
      }
      lastMessage {
        id
        body
        createdAt
      }
    }
  }
`;

export const USER_CONVERSATION = gql`
  query getUserConversation($with_user: Float!) {
    getUserConversation(with_user: $with_user) {
      id
      m_one {
        id
        username
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
      m_two {
        id
        username
        base_profile {
          id
          firstName
          lastName
        }
      }
      lastMessage {
        id
        body
        createdAt
      }
    }
  }
`;

export const CONV_STAT = gql`
  query {
    convoStatus
  }
`;

export const GET_MESSAGES = gql`
  query allMessages($conv: Float!) {
    allMessages(conv: $conv) {
      messages {
        id
        status
        body
        message_type
        paid
        translations {
          en
          ge
        }
        sender {
          id
          username
          email
          profilePic
          base_profile {
            id
            firstName
            lastName
          }
        }
        attachment_type
        attachment_url
      }
      convo {
        id
        m_one {
          id
          username
          profilePic
          base_profile {
            id
            firstName
            lastName
          }
        }
        m_two {
          id
          username
          base_profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_DM_MESSAGES = gql`
  query allDmMessages($t: String!) {
    allDmMessages(t: $t) {
      id
      status
      body
      sender {
        id
        username
        email
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
      attachment_type
      attachment_url
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($input: ChatMessageInput!, $file: Upload) {
    sendMessage(input: $input, file: $file) {
      id
      status
      body
      paid
      message_type
      attachment_url
      sender {
        id
        username
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
        createdAt
      }
      flirts
    }
  }
`;

export const DELETE_USER_MESSAGES = gql`
  mutation deleteUserMessages($convo_id: Float!) {
    deleteUserMessages(convo_id: $convo_id)
  }
`;

export const BUY_NUDE = gql`
  mutation buyNude($id: Float!) {
    buyNude(id: $id) {
      url
      balance
    }
  }
`;

export const MESSAGE_LISTEN = gql`
  subscription messageAdded {
    messageAdded {
      mes {
        id
        status
        body
        attachment_url
        message_type
        paid
        sender {
          id
          username
          profilePic
          base_profile {
            id
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`;

export const NOTIFICATION_ADDED = gql`
  subscription {
    notificationAdded {
      id
      read
      message
      ref_user {
        id
        username
        email
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
      }
      user {
        id
        username
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
      }
    }
  }
`;

export const IKM_AFFILATES = gql`
  query {
    ikmAffilates {
      id
      fullName
      phone
      email
      affilateId
      targetGroups
      address {
        id
        country
        state
        zip
        street
      }
      createdAt
      targetAgeStart
      targetAgeEnd
      bankAccount
      paymentMethod
      affilatePic
      creditPercent
      serviceTypes
      owned_by {
        id
        fullName
      }
    }
  }
`;

export const AFFILATES = gql`
  query {
    affilates {
      id
      fullName
      phone
      email
      affilateId
      targetGroups
      address {
        id
        country
        state
        zip
        street
      }
      createdAt
      targetAgeStart
      targetAgeEnd
      bankAccount
      paymentMethod
      affilatePic
      creditPercent
      serviceTypes
      owned_by {
        id
        fullName
      }
    }
  }
`;

export const ALL_GIFTS = gql`
  query {
    gifts {
      id
      name
      asset
      cost
    }
  }
`;
