<template>
  <div
    class="overflow-hidden  dark:bg-gray-800 bg-white"
    style="min-height: 100vh; display: flex; flex-direction: column"
  >
    <notification-bar />
    <nav-bar
      v-if="
        !(($route.name == 'Chat' || $route.name == 'DirectChat') && $mq == 'sm')
      "
    />
    <main
      :class="{
        'mt-16': $route.path === '/notification',
        'mt-28': $route.path != '/notification',
      }"
      class="md:mx-0 "
      style="min-height: 60vh; flex-direction: column"
    >
      <router-view></router-view>
    </main>
    <div class="spacer"></div>
    <mobilenavbar/> <page-footer
      v-if="!($route.name == 'Chat' || $route.name == 'DirectChat')"
    />
  </div>
</template>

<script>
import NavBar from "@/components/navBar.vue";
import NotificationBar from "@/components/NotificationBar.vue";
import pageFooter from "@/components/pageFooter.vue";
import { NOTIFICATION_ADDED } from "@/queries/chat";
import mobilenavbar from "@/components/Mobilenavbar.vue";
import store from "@/store";
// import obilenavbar from "../components/Mobilenavbar.vue";

export default {
  data: () => ({
    nCount: 0,
  }),
  created() {
    this.nCount = store.state.core.notifiationCount;
  },
  computed: {
    notificationObject() {
      return this.$store.getters.notificationObject;
    },
  },
  watch: {
    notificationObject(value) {
      this.$notify(
        {
          group: "top",
          title: value.message,
          text: "Notification Message",
          type: "info", // info, warning
        },
        5000
      );
    },
  },
  apollo: {
    $subscribe: {
      notificationAdded: {
        query: NOTIFICATION_ADDED,
        result({ data }) {
          // change notification state
          this.nCount++;
          store.commit("addNotification", data.notificationAdded);
          store.commit("changeNotificationCount", this.nCount);
        },
        error: (e) => {
          console.error(e, "what an error");
        },
      },
    },
  },
  components: { pageFooter, NavBar, NotificationBar, mobilenavbar },
  name: "Home",
};
</script>


<style scoped>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
.spacer {
  margin-top: 2%;
  flex-grow: 1;
}
</style>
