<template>
  <div class="px-1 cursor-pointer">
    <div class="mt-2 text-center border-4 shadow-none rounded-full border-white  lg:h-24 lg:w-24 w-10 h-10 gap card bg-pink-50 dark:bg-gray-800 hover:bg-purple-200" @click="$router.push('ft/@' + user.username)" >
      <figure>
        <img :src="user.profilePic ? getURL(user.profilePic) : require('@/assets/pholder.webp')" class="object-cover  w-full h-full" style="object-fit: cover;">
        <div class="absolute top-0 right-0 h-16 w-16 px-2 mt-1 text-right avatar online rounded-full">
          <svg class="h-3 w-3 shadow-lg text-green-300 bg-green-300 rounded-full shadow-green-500 animate-pulse text-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
          </svg>
        </div>
      </figure>
    </div>
    <div v-if="loadingContent" class="text-center">Loading...</div>
  </div>
</template>



<script>
import { DISLIKE_PROFILE, LIKE_PROFILE } from "@/queries/matches";
import { getURL } from "@/utils/helpers";
export default {
  data: () => ({
    loadingContent: true,
  }),
  props: {
    user: Object,
    page: String,
    geocode: Boolean,
  },
  methods: {
    getURL,
    unlike() {
      this.$emit("popIndex");
      this.$apollo.mutate({
        mutation: DISLIKE_PROFILE,
        variables: {
          user: this.user.username,
        },
      });
    },
    unmatch() {
      this.$emit("popIndex");
    },
    likeProfile() {
      this.$emit("popIndex");
      this.$apollo
        .mutate({
          mutation: LIKE_PROFILE,
          variables: {
            user: this.user.username,
          },
        })
        .then(() => {
          console.log("request completed");
        })
        .catch((e) => {
          // catch exception
          console.error(e);
        });
    },
    disLikeProfile() {
      this.$emit("popIndex");
    },
    removeDisliked() {
      this.$emit("popIndex");
    },
  },
  mounted() {
    this.loadingContent = false;
  },
};
</script>
