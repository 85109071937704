<template>
  <div>
    <p>Upload Test</p>
    <input @change="upload" type="file" />
    <p style="padding-top: 20px">Upload Multiple Test</p>
    <input @change="multipleUpload" multiple type="file" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
  methods: {
    upload({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateProfilePic($file: Upload!) {
              updateProfilePic(file: $file)
            }
          `,
          variables: {
            file: files[0],
          },
        })
        .then(({ data }) => {
          console.warn(data);
        });
    },
    multipleUpload({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation addProfilePics($files: [Upload!]!) {
              addProfilePics(files: $files)
            }
          `,
          variables: {
            files: files,
          },
        })
        .then(({ data }) => {
          console.warn(data);
        });
    },
  },
});
</script>