<template>
  <div>
    <h1>This is erotic dating theme login</h1>
  </div>
</template>
<script>
import axios from "axios";
import { UPDATE_LOC } from "@/queries/matches";
import { createProvider, onLogin } from "@/v-apollo";
import { required, minLength } from "vuelidate/lib/validators";
import { SOCIAL_AUTH_GOOGLE, USER_LOGIN } from "@/queries/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
export default {
  data: () => ({
    showPass: false,
    emailOrUname: "",
    password: "",
    passFocus: false,
    loadingLogin: false,
    usernameFocus: false,
    authErr: "",
    acceptedTerms: false,
    loadingSocialAuth: false,
  }),
  created() {
    console.clear();
    console.warn(this.$route.query, "dog");
    this.setAddress();
    if (this.$store.state.core.flirtok) {
      this.$router.push("/find-matches");
    }
  },
  methods: {
    setAddress() {
      axios
        .get(
          "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
        )
        .then(({ data }) => {
          this.$store.commit("setAddr", data.ip);
          this.$apollo.mutate({
            mutation: UPDATE_LOC,
            variables: {
              addr: data.ip,
            },
          });
        });
    },
    socialLoginFb() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          console.warn("*************************");
          console.warn(result);
          console.warn("*************************");
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                // onLogin(createProvider().defaultClient)
                this.$store.commit("setUserData", user);
                credit.flirtonsAvailable === true 
                ? this.$store.commit("setFlirtonsAvailable",true) :
                this.$store.commit("setFlirtonsAvailable",false)
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ?  this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  if (this.$route.query.to) {
                    window.location.assign("/" + this.$route.query.to);
                  } else {
                    window.location.assign("/find-matches");
                  }
                } else {
                  this.$router.push("/profile-setup");
                }
                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    socialLoginGoogle() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you continue",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
                 userType:'',
                preference:""
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                onLogin(createProvider().defaultClient);
                this.$store.commit("setUserData", user);
                credit.flirtonsAvailable === true 
                ? this.$store.commit("setFlirtonsAvailable",true) :
                this.$store.commit("setFlirtonsAvailable",false)
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  if (this.$route.query.to) {
                    window.location.assign("/" + this.$route.query.to);
                  } else {
                    window.location.assign("/find-matches");
                  }
                } else {
                  this.$router.push("/profile-setup");
                }
                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    doLogin() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (!this.$v.$invalid) {
        this.loadingLogin = true;
        this.$apollo
          .getClient()
          .mutate({
            mutation: USER_LOGIN,
            // authErrs: [],
            variables: {
              addr: this.$store.state.core.addr,
              usernameOrEmail: this.emailOrUname,
              password: this.password,
            },
          })
          .then(({ data: { login } }) => {
            console.log(login, "What the heck");
            const { user, credit, token } = login;
            credit.flirtonsAvailable === true 
            ? this.$store.commit("setFlirtonsAvailable",true) :
             this.$store.commit("setFlirtonsAvailable",false)
            onLogin(createProvider().defaultClient);
            this.$store.commit("setUserData", user);
            //this.$store.commit("setBalance", credit.balance);
             this.$store.commit("setBalance", credit.balance + credit.flirtons);
            this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("setFlirtTok", token);
            // reset apollo client
            // console.log(user, "user is logged in");
            // window.location.assign("/")
            if (user.profileComplete) {
              // this.$router.push("/find-matches");
              if (this.$route.query.to) {
                window.location.assign("/" + this.$route.query.to);
              } else {
                window.location.assign("/find-matches");
              }
            } else {
              this.$router.push("/profile-setup");
            }
          })
          .catch((e) => {
            console.warn(Object.keys(e));
            Object.keys(e).forEach((x) => {
              console.warn(e[x]);
            });
            console.error(e);
            this.authErr = "You have entered incorrect credentials";
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
    setUname(value) {
      this.emailOrUname = value;
      this.$v.emailOrUname.$touch();
    },
    setPass(value) {
      this.password = value;
      this.$v.password.$touch();
    },
  },
  validations: {
    emailOrUname: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
    },
  },
  metaInfo() {
    return {
      title: "Login | FlirtTool | The finest global  dating website around. ",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool is the first dating community based on a special artificial intelligence (AI) system. Connect with local singles & start your personal online dating adventure!",
        },
        {
          property: "og:title",
          content:
            "Login | FlirtTool | The finest global  dating website around.",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>