<template>
   <component :is="appliedComponent" />
</template>
<script>
import {activeTheme} from '@/themes.js'
import FlirttoolDislikes from './FlirttoolDislikes.vue'
import PurpleDislikes from './PurpleDislikes.vue'
import RedDislikes from './RedDislikes.vue'

// import EroticLogin from './EroticLogin.vue'
export default {
  components: {
    FlirttoolDislikes,
    PurpleDislikes,
    RedDislikes,
    // EroticLogin
  },
  computed: {
    appliedComponent:function() {
      switch(activeTheme) {
        case 'Flirttool':
          return 'FlirttoolDislikes'
        case 'Erotic':
          return 'FlirttoolDislikes'
        case 'Purple':
          return 'PurpleDislikes'
          case 'Red':
            return 'RedDislikes'
        default:
          return 'FlirttoolDislikes'
      }
    }
  }
}
</script>
