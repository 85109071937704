<template>
  <div class="mt-12">
    <center>
      <div
        class="border border-pink-100 card dark:border-gray-700 dark:bg-gray-700"
      >
        <div
          class="pt-5 mx-3 mb-0 bg-accent text-accent-content dark:text-gray-200"
        >
          <p class="pb-3 text-left sm:px-40">Verify Your Phonenumber</p>
          <p class="pb-3 text-left sm:px-40">
            You will recieve an sms with a verification code please fill that in
            here to verify your number
          </p>
          <!-- <p class="pb-3 text-left sm:px-40">Verify Your Email Address</p>
					<p class="pb-3 text-left sm:px-40">
						You will recieve an E-mail with a verification code please fill that
						in here to verify your account
					</p> -->
          <!-- start of pin input -->
          <otp-input
            class="justify-center mt-2"
            ref="otpInput"
            input-classes="otp-input"
            separator="<b class='bro'>-</b>"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          ></otp-input>
          <!-- end of pin -->
          <div class="flex justify-start gap-4 pt-5 pb-5 mx-40 md:gap-6">
            <button
              class="w-auto px-4 btn py-2 text-lg font-medium text-white bg-pink-400 rounded-lg shadow-xl btn-outline hover:bg-pink-500 hover:text-white"
              :class="{ loading: loading }"
              @click="verifyOtp"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </center>
  </div>
</template>
<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import { VERIFY_OTP } from "../../queries/auth";

export default {
  components: {
    OtpInput,
  },
  data: () => ({
    otp: "",
    loading: false,
  }),
  created() {
    this.otp = "0000";
    this.verifyOtp();
  },
  methods: {
    verifyOtp() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: VERIFY_OTP,
          variables: {
            otp: this.otp,
          },
        })
        .then(({ data }) => {
          if (data) {
            this.$store.commit("setUserData", data.verifyOtp.user);
            this.$store.commit("setBalance", data.verifyOtp.credit.balance);
            this.$store.commit("setRandState", Math.random(100));
            let userType = this.$store.state.core.user.usrType;
            if (userType == "model") {
              window.location.replace("/ftmodels");
            } else {
              window.location.replace("/find-matches");
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
      this.$emit("nextstep");
    },
    handleOnComplete(value) {
      this.otp = value;
      console.log("OTP completed: ", value);
    },
    handleOnChange(value) {
      this.otp = value;
      console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
};
</script>
<style>
.otp-input {
  width: 40%;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  font-weight: bold;
  color: #8f5668;
  border-color: blue;
  border-color: transparent;
  border: 1px solid #f16592;
  text-align: center;
  /* &.error {
		border: 1px solid red !important;
	} */
}
.otp-input:focus {
  outline: none !important;
  border-color: #719ece;
  border: 2px solid #f16592;
  /* box-shadow: 0 0 10px #719ece; */
}
/* textarea:focus {
	outline: none !important;
	border-color: #719ece;
	box-shadow: 0 0 10px #719ece;
} */
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bro {
  color: rgb(105, 98, 98);
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
