<template>
    <component :is="componentId" />
</template>

<script>
import { activeTheme } from '@/themes.js'
import Flirttoolvisitor from './FlirttoolVisitors.vue'
// import PurpleVisitors from './PurpleVisitors.vue'

export default {
    components: {
        Flirttoolvisitor,
        // PurpleVisitors
    },
    computed: {
        appliedComponent: function () {
            switch (activeTheme) {
                case 'Flirttool':
                    return 'Flirttoolvisitor'
                // case 'Purple':
                //     return 'Purplevisitors'
                default:
                    return 'Flirttoolvisitor'
            }
        }
    }
}







</script>