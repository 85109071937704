<template>

<div>
    <div class="px-2 py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl hidden lg:block md:px-4 lg:px-2 lg:py-">
      <div class="grid grid-cols-2 gap-1  justify-center lg:max-w-screen-sm">
        <div v-for="user in users" :key="user.id" class="w-36 ">
          <router-link :to="'/ft/@'+user.username+''">
            <div class="relative pb-56 mb-4 rounded shadow-lg ">
              <img class="absolute object-cover w-full h-full rounded" :src="getURL(user.profilePic)" alt="Person" />
              <div class="absolute bottom-2 left-2 flex items-center gap-2 bg-gray-800 bg-opacity-75 px-2 py-1 rounded text-white text-xs">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{{ user.username }}</span>
              </div>
            </div>
          </router-link>
          
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { getURL } from "@/utils/helpers";

export default {
  created() {
    // alert("sdfsdf")
    console.log(this.users,"usersusersusersusersusersusers")
  },
	props:['users'],
	methods: {
		getURL
	}
}
</script>
