<template>
  <div>
    <video width="320" height="240" controls ref="videoPlayer" @error="getDefaultVIdeo">
      <source src="http://localhost:8080/rest/stream/54?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEzOCwiaWF0IjoxNjc1NzUxOTc5fQ.0bJl5VsiUq7_uhgGXxjSYbuR9_rGfN-qs-12O_53Sa0" type="video/mp4" />
      <source src="http://localhost:8080/rest/stream/54?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEzOCwiaWF0IjoxNjc1NzUxOTc5fQ.0bJl5VsiUq7_uhgGXxjSYbuR9_rGfN-qs-12O_53Sa0" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <img src="http://localhost:8080/rest/stream/58?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEzOCwiaWF0IjoxNjc1NzUxOTc5fQ.0bJl5VsiUq7_uhgGXxjSYbuR9_rGfN-qs-12O_53Sa0" @error="getDefaultImage">
  </div>
</template>

<script>
import axios from "axios";
import streamToBlob from "stream-to-blob";
export default {
  async mounted() {
    
    //https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
    // https://www.npmjs.com/package/mediasource
  },
  methods:{
    getDefaultVIdeo(event) {
      alert('df')
    },
    getDefaultImage(event) {
      // alert('dfg')
      // console.log(event)
      // console.log(event)
      event.target.src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png'
      //https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png
    },
    async getImageUrl(event) {
      try {
          const response = await fetch("http://localhost:8080/rest/stream/58?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEzOCwiaWF0IjoxNjc1NzUxOTc5fQ.0bJl5VsiUq7_uhgGXxjSYbuR9_rGfN-qs-12O_53Sa0");
          const bodyStream = response.body;
          const newResponse = new Response(bodyStream);
          console.log(bodyStream)
          const blob = await newResponse.blob();
          const url = URL.createObjectURL(blob);
          console.log(url)
          return url
          // document.querySelector("img").src = url;
        } catch (error) {
          console.error(error);
        }
    }
  }
};
</script>
