

<template>
  <div class="lg:-mt-28">
    <div
      class="px-2 py-4 mx-auto bg-white hidden lg:block border-pink-200 h-full  shadow-2xl drop-shadow-2xl border-4 max-w-screen-xl  md:px-2 lg:px-2">
      <div class="grid grid-cols-12    ">

        <div class="col-span-1 flex flex-wrap justify-start">

          <new-side-selector />
        </div>
        <div style=" height: 810px;" class=" col-span-2 flex justify-center mx-auto   overflow-hidden  bg-white p-0">

          <div class="px-1 lds-ripple " v-if="loadingContent">
            <div></div>
            <div></div>
          </div>
          <div class="grid grid-cols-2 h-full   text-left  md:grid-cols-2 xl:grid-cols-2" v-if="!loadingContent">
            <vip-user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip" :key="idx"
              :user="user" :page="'find_matches'" />
          </div>
        </div>

        <div class="col-span-9 mx-auto justify-center  grid grid-cols-12 gap-1">
          <div class="col-span-8 px-5">
            <hot-or-not />
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="col-span-4 hidden lg:block">
            <!-- Your content for the right half of the main area goes here -->
            <div> <!-- Friends card -->
              <div
                class="grid grid-cols-1 bg-white items-center text-center space-x-3 font-semibold text-gray-900 text-xl leading-8" >
                <div class="flex justify-center mb-7">
                  <user-avatar />
                </div>
                <div class="overflow-hidden" style=" height: 570px;" >
                  <Notify />

                  <!-- <div class="search-container flex justify-center mx-auto mt-36">
        <div class="search-icon">🔍</div>
        <div class="search-text font-sans uppercase">For Matches</div>
    </div> -->

                </div>

              </div>

              <!-- End of friends card -->
            </div>
          </div>
          
          <!-- <div>
            <div class="grid col-span-4">
              <div class=" sm:hidden md:hidden lg:block flex justify-center items-center mx-auto">
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="col-span-12  px-44 px- hidden lg:block h-full relative -mt-24  ">
          <div class="mt-10"><span
              class="bg-purple-700 py-2 px-2 text-xl font-sans font-bold text-white rounded-full">MAZU USERS</span></div>
          <div class="dark:bg-gray-800  " v-if="!loadingContent">
            <select @change="filterChanged" v-model="nFilter"
              class="w-36 py-2 px-2 mt-5 mb-1 justify-start flex border-2 text-sm  bg-white dark:bg-gray-700 dark:text-gray-200 rounded-2xl shadow-xl">
              <option value="" disabled selected class="text-sm">Filter Users</option>
              <option disabled="disabled" selected="selected">I am looking for</option>
              <option>Women</option>
              <option>Men</option>
              <option>Gay</option>
              <option>Lesbian</option>
            </select>
          </div>


          <div class="grid grid-cols-5 gap-1 py-0 overflow-auto h-full">
            <Purpleusercards :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches" :key="idx"
              :user="user" :page="'find_matches'" /> <div class="flex justify-center mx-auto"><button v-if="!loadingContent"
              class="mb-10 mt-0 border-yellow-500 rounded-full  items-center shadow-xl  boder-0 border-" @click="loadMore">
              <div v-if="loadingUsers" class="mt-5 lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <a v-else class="font-sans text-yellow-500 text-xs uppercase font-bold"> Load More </a>
            </button></div>
          </div>
        </div> -->
      </div>
      <div class="px-2 py-4 -mt- mx-auto   hidden lg:block shadow-none drop-shadow-xl border-0 max-w-screen-xl  md:px-2 lg:px-2 ">
        <div class="flex flex-wrap justify-center mx-auto">
            <div class="dark:bg-gray-800  flex flex-wrap " v-if="!loadingContent">
              <select @change="filterChanged" v-model="nFilter"
                class="w-36 py-2 px-2 mt-5 mb-1 justify-start flex border-0 text-sm bg-white glass dark:bg-gray-700 dark:text-gray-200 rounded-2xl shadow-xl">
                <option value="" disabled selected class="text-sm">{{$t('FliterUsers')}}</option>
                <option disabled="disabled" selected="selected">I am looking for</option>
                <option>Women</option>
                <option>Men</option>
                <option>Gay</option>
                <option>Lesbian</option>
              </select>
            </div>
            <div class="grid grid-cols-5 gap-1 py-0 overflow-auto" style="height: 100%; width: 100%;">
              <user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches" :key="idx"
                :user="user" :page="'find_matches'" />
            </div>
            <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
              <div></div>
              <div></div>
            </div>

            <button v-if="!loadingContent" class="mb-10 mt-6 border-yellow-500 rounded-full  boder-0 border-"
              @click="loadMore">
              <div v-if="loadingUsers" class="mt-0">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a v-else class="font-sans text-purple-50 bg-pink-600 btn glass px-2 py-2 rounded-xl text-xs uppercase font-bold">
               {{$t('LoadMore')}}</a>
            </button>
          </div></div>
    </div>
<div class="lg:hidden xl:hidden 2xl:hidden   dark:bg-gray-700 p-5 -mt-10">
<div class="flex items-top justify-center  w-full pl-1 pr-1 mt-3  md:w-full md:mb-0 bg-white dark:bg-gray-700">
  <div class="mt-0">
    <hot-or-not />
    <!-- <div class="px-3 lds-ripple mt-36" v-if="loadingContent">
            <div></div>
            <div></div>
          </div> -->
    <br />
    <!---->
  </div>
  <div></div>
</div>
<div class="grid grid-cols-1">
  <p class="-mt-4 mb-4"> <img src="@/assets/newchat/24.png" class="h-5" alt=""></p>
  <div
    class=" items-center shadow-sm justify-center grid-cols-1 w-full  h-full  pl-0 pr-0 overflow-auto    dark:bg-gray-700 scroll-smooth scrollbar-hide whitespace-nowrap  md:mb-0 bg-white lg:grid-cols-1 md:grid-cols-2">
    <div style="height: ;">
      <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
        <div></div>
        <div></div>
      </div>
      <div class="grid grid-cols-3    text-left md:col-span-5 md:grid-cols-4 xl:grid-cols-2" v-if="!loadingContent">
        <vip-user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches_vip"
          :key="idx" :user="user" :page="'find_matches'" />
      </div>
    </div>





  </div>
  <p class="mt-4"><img src="@/assets/icons/User.png" class="h-5" alt=""></p>
  <div class="  dark:bg-gray-800 " v-if="!loadingContent">



    <select @change="filterChanged" v-model="nFilter"
      class=" items-start  w-full mt-5 pt-3 mb-1 font-sans glass lg:hidden xl:hidden 2xl:hidden glass text-sm text-left text-gray-700  select border-0 bg-pink-500 dark:bg-gray-700 dark:text-gray-200">
      <option value="" disabled selected class="text-sm">Fliter Users</option>
      <option disabled="disabled" selected="selected">I am looking for</option>
      <option>Women</option>
      <option>Men</option>
      <!-- <option>Couples</option> -->
      <option>Gay</option>
      <option>Lesbian</option>
    </select>
  </div>
  <div
    class=" items-center mt-5 z-0 justify-center grid-cols-1 w-full  h-full  pl-0 pr-0    dark:bg-gray-700 scroll-smooth scrollbar-hide whitespace-nowrap  md:mb-0 bg-white lg:grid-cols-1 md:grid-cols-2">
    <div style="height: ;">
      <div class="px-1 lds-ripple mt-36" v-if="loadingContent">
        <div></div>
        <div></div>
      </div>
      <div class="grid grid-cols-2   text-left md:col-span-5 md:grid-cols-4 xl:grid-cols-2" v-if="!loadingContent">
        <user-card :geocode="true" @popIndex="removeItem(idx)" v-for="(user, idx) in find_matches" :key="idx"
          :user="user" :page="'find_matches'" />
      </div>

    </div>

    <button v-if="!loadingContent"
      class="mb-10 mt-6 border-pink-500 rounded-full shadow-xl shadow-none boder-0 border-" @click="loadMore">
      <div v-if="loadingUsers" class="mt-5 lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <a v-else class="font-sans text-pink-500 text-xs uppercase font-bold"> Load More </a>
    </button>


    <Mobilenavbar />
  </div>

</div>
</div>

  </div>
</template>
<script>
import Adsense from 'vue-google-adsense'
import axios from "axios";
import Notify from "@/components/Notify.vue"
import HotOrNot from "@/components/HotOrNot.vue";
import UserAvatar from "@/components/userAvatar.vue";
//import SideSelector from "../../components/sideSelector.vue";
import SideHomeSelector from "@/components/sideHomeSelector.vue";
import { LOGGEDIN_DATA, START_PASS } from "@/queries/auth";
import UserCard from "@/components/userCard.vue";
import { FIND_MATCHES_LIST, UPDATE_LOC, FIND_VIP_MATCHES_LIST } from "@/queries/matches";
//import MessageField from "../../components/chat/messageField.vue";
//import HomeChat from "../../components/homeChat.vue";
import store from "../../store";
// import PromoAlert from '../../components/promoAlert.vue';
//import PromoSection from "../../components/promoSection.vue";
// import VueConfetti from "VueConfetti"
import NewSideSelector from "@/components/newsideselector ";
import SideMatchSelector from '@/components/sideMatchSelector';
import popup from "@/components/popup.vue";
import { Location } from '@/utils/locations'
import VipUserCard from "@/components/vipUserCard.vue";
import Mobilenavbar from '@/components/Mobilenavbar.vue';
import Vipchatsideselector from '@/components/Vipchatsideselector.vue';
import navBar from '@/components/navBar.vue';


const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    HotOrNot,
    UserAvatar,
    Adsense,
    Vipchatsideselector,
    // MessageField,
    NewSideSelector,
    SideHomeSelector,
    SideMatchSelector,
    UserCard,
    Notify,
    VipUserCard,
    Mobilenavbar,
    navBar,
    // HomeChat,
    // PromoAlert,
    //PromoSection,
    // CreditsChip,
    //MobileSideSelector,
    popup,
    Location
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    me: {
      query: LOGGEDIN_DATA,
      variables() {
        return {
          addr: this.$store.state.core.addr,
        };
      },
      result({
        data: {
          me: { user, credits, notificationCount, isAff, isSuper, isIkm },
        },
      }) {
        store.commit("setAfStat", isAff);
        store.commit("setAfSstat", isSuper);
        store.commit("setIsIkm", isIkm);
        store.commit("setUserData", user);
        store.commit("setBalance", credits.balance + credits.flirtons);
        store.commit("changeNotificationCount", notificationCount);
        store.commit("setTempLocationDetail", user.tempLocationDetail);
        this.me = user;
      },
    },
  },
  data: () => ({
    pagin: {
      page: 1,
      pageSize: 5,
      filter: "all",
    },
    paginVip: {
      page: 1,
      pageSize: 10,
      filter: "all",
    },
    nFilter: "",
    showing: false,
    welcomeBanner: true,
    loadingContent: false,
    loadingUsers: false,
    bgColor: "#dc4b78",
    position: "bottom-right",
    fabActions: [
      {
        name: "alertMe",
        icon: "chat",
      },
    ],

    features,
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    filter: "all",
    find_matches: [],
    find_matches_vip: [],

    me: null,
    mobile: false,
    showPop: false,
  }),
  methods: {
    startPackage() {
      const minMin = 1;
      const createdMs =
        new Date() - new Date(parseInt(this.$store.state.core.user.createdAt));
      console.warn("created MS: ", createdMs);
      const min = Math.floor((createdMs / 1000 / 60) << 0);
      const recentOnline = min <= minMin;
      console.warn(
        "Recent online time: ",
        recentOnline,
        "min: ",
        min,
        "minMin: ",
        minMin
      );
      if (recentOnline) {
        this.$notify(
          {
            group: "top",
            title: "🎉 Welcome To FlirtTool 🎉",
            text: "🎉🎊 Congrats and enjoy 1 hour free chat access 🎉🎊",
            type: "success", // info, warning
          },
          8000
        );

        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
        // this.loading = true;
        this.$apollo
          .mutate({
            mutation: START_PASS,
          })
          .then(() => {
            this.passGranted();
          })
          .catch(() => {
            alert("err");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    findMatches() {
      this.loadingContent = true;
      this.$apollo
        .query({
          query: FIND_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.pagin,
            addr: this.$store.state.core.addr,
          },
        })
        .then(({ data }) => {
          this.find_matches = data.find_matches.data;
        })
        .finally(() => {
          this.loadingContent = false;
        });
    },
    findMatchesVip() {
      // this.loadingContent = true;
      this.$apollo
        .query({
          query: FIND_VIP_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.paginVip,
          },
        })
        .then(({ data }) => {
          this.find_matches_vip = data.find_matches_vip.data;
        }).catch((e) => {

        })
        .finally(() => {
          // this.loadingContent = false;
        });
    },
    filterChanged() {
      this.find_matches = [];
      switch (this.nFilter) {
        case "Women":
          this.pagin.filter = "female";
          break;
        case "Men":
          this.pagin.filter = "male";
          break;
        case "Lesbian":
          this.pagin.filter = "lesbain";
          break;
        // case "Transgender":
        // 	this.pagin.filter = "trans"
        // 	break;
        case "Gay":
          this.pagin.filter = "gay";
          break;
        default:
          break;
      }
      this.loadingContent = true;
      this.findMatches();
    },
    loadMore() {
      this.loadingUsers = true;
      this.pagin.page++;
      this.$apollo
        .query({
          query: FIND_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.pagin,
            addr: this.$store.state.core.addr,
          },
        })
        .then(
          ({
            data: {
              find_matches: { data },
            },
          }) => {
            // loop and just push
            for (let x = 0; x < data.length; x++) {
              const element = data[x];
              this.find_matches.push(element);
            }
            // console.log(this.find_matches())
            // this.find_matches.push(data)
            // let loadedUsers = data.find_matches.data
            // let finalSet = [...this.find_matches, ...loadedUsers]
            // this.find_matches = finalSet
          }
        )
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    loadMoreVip() {
      this.loadingUsers = true;
      this.pagin.page++;
      this.$apollo
        .query({
          query: FIND_VIP_MATCHES_LIST,
          fetchPolicy: "network-only",
          variables: {
            input: this.paginVip,
          },
        })
        .then(
          ({
            data: {
              find_matches: { data },
            },
          }) => {
            // loop and just push
            for (let x = 0; x < data.length; x++) {
              const element = data[x];
              this.find_matches_vip.push(element);
            }
            // console.log(this.find_matches())
            // this.find_matches.push(data)
            // let loadedUsers = data.find_matches.data
            // let finalSet = [...this.find_matches, ...loadedUsers]
            // this.find_matches = finalSet
          }
        )
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    xalert() {
      alert("bruv");
    },
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
    passGranted() {
      this.$apollo.queries.me.refetch();
    },
    removeItem(idx) {
      this.find_matches.splice(idx, 1);
    },
    async trackLocation() {
      let locationInterval = store.getters.updateLocationInterval
      if (!locationInterval) {
        let location = new Location
        await location.geoLocate()
        await location.setUpdateLocationInterval()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("onorientationchange", this.detectOrientationChange);
    });
  },
  created() {
    setTimeout(() => {
      if (this.$store.getters.showFlirton) this.showing = true
    }, 3000)
    this.trackLocation()
    //   show popup on initial registration
    this.showPop = true;
    // refetch on route change
    this.pagin = {
      page: 1,
      pageSize: 5,
    };
    // this.loadingContent = true
    axios
      .get(
        "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
      )
      .then(({ data }) => {
        this.$store.commit("setAddr", data.ip);
        this.$apollo.mutate({
          mutation: UPDATE_LOC,
          variables: {
            addr: data.ip,
          },
        });
      })
      .finally(() => {
        this.$apollo.queries.me.refetch().then(() => {
          setTimeout(() => {
            // this.startPackage();
          }, 1000);
        });
        this.findMatches();
        this.findMatchesVip()
      });
    this.$apollo.queries.visits.refetch().finally(() => {
      this.loadingContent = false;
    });
    this.detectOrientationChange(); // when instance is created

    // const token = this.$store.state.core.flirtok ? `${this.$store.state.core.flirtok}` : null;
  },
};
</script>

<!-- <style>
.search-container {
     display: flex;
     align-items: center;
 }

 .search-icon {
     font-size: 32px;
     color: #3498db;
     animation: bounce 2s infinite;
 }

 .search-text {
     font-size: 24px;
     margin-left: 10px;
     color: #333;
     animation: fadeIn 2s infinite;
 }

 @keyframes bounce {
     0%, 20%, 50%, 80%, 100% {
         transform: translateY(0);
     }
     40% {
         transform: translateY(-10px);
     }
     60% {
         transform: translateY(-5px);
     }
 }

 @keyframes fadeIn {
     0%, 100% {
         opacity: 0;
     }
     50% {
         opacity: 1;
     }
 }
</style> -->