<template>
	<div class="h-full pb-52">
		<div class="flex justify-center px-5 mt-5">
			<div class="flex">
				<h1
					class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
				>
					{{$t('Looks')}}
				</h1>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-1 mt-1 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Ethnicity')}}</label
				>

				<select
					v-model="ethnicity"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Ethnicity')}}</option>
					<option
						v-for="(sl, idx) in lTypes.ethnicity"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('BodyType')}}</label
				>
				<select
					v-model="bodyType"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('BodyType')}}</option>
					<option
						v-for="(sl, idx) in lTypes.bodyType"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>

		<div class="grid grid-cols-1 gap-5 mt-1 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Height')}}</label
				>
				<input
					v-model="height"
					type="text"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				/>
				
				<br>
					<div class="some-class">
						<input class="ml-2" type="radio" v-model="height_units" name="x" value="ft"/> feet
						<input class="ml-2" type="radio"  v-model="height_units" name="x" value="cm"/> cm
						<input class="ml-2" type="radio"  v-model="height_units" name="x" value="in"/> Inches
				</div>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="my- text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('HairColor')}}</label
				>
				<select
					v-model="hairColor"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('HairColor')}}</option>
					<option
						v-for="(sl, idx) in lTypes.hairColor"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>

		<!-- Personality section -->
		<div class="flex justify-center px-5 mt-10">
			<div class="flex">
				<h1
					class="mb-1 font-sans text-sm text-center text-gray-500 uppercase md:text-sm dark:text-gray-200 text-light"
				>
					{{$t('Personality')}}
				</h1>
			</div>
		</div>

		<div class="grid grid-cols-2 gap-5 mt-1 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Character')}}</label
				>
				<select
					v-model="character"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 dark:text-gray-200 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Character')}}</option>
					<option
						v-for="(sl, idx) in lTypes.character"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Children')}}</label
				>
				<select
					v-model="children"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Children')}}</option>
					<option
						v-for="(sl, idx) in lTypes.children"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>
		<div class="grid grid-cols-2 gap-5 mt-1 md:grid-cols-2 md:gap-8 mx-7">
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Friends')}}</label
				>
				<select
					v-model="friend"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered focus:outline-none dark:text-gray-200 focus:ring-2 focus:ring-gray-500 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Friends')}}</option>
					<option
						v-for="(sl, idx) in lTypes.friends"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
			<div class="grid grid-cols-1">
				<label
					class="my-5 text-xs font-semibold text-left text-gray-500 uppercase dark:text-gray-200 md:text-sm text-light"
					>{{$t('Pets')}}</label
				>
				<select
					v-model="pet"
					class="w-full max-w-md border-2 border-gray-300 rounded-none shadow-xl select select-bordered focus:outline-none focus:ring-2 focus:ring-gray-500 dark:text-gray-200 focus:border-transparent"
				>
					<option disabled="disabled" selected="selected">{{$t('Pets')}}</option>
					<option
						v-for="(sl, idx) in lTypes.pets"
						:key="idx"
						v-text="sl"
					></option>
				</select>
			</div>
		</div>

		<!-- end of personality section -->

		<div class="flex gap-4 pt-5 pb-5 mx-7 pb-16 md:gap-6">
			<button
				@click="updateUserLooksDetail"
				class="w-auto px-4 py-2 font-medium text-white bg-gray-500 glass rounded-lg shadow-xl hover:bg-gray-700"
			>
				<div v-if="loadingUpdate" class="mt-3 lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<p v-else class="-mt-4">{{$t("SaveChanges")}}</p>
			</button>
		</div>
		<div class="mb-70"></div>
	</div>
</template>
<script>
import { LOGGEDIN_DATA, LOOKS_PERSONALITY_TYPES, UPDATE_USER_LOOKS } from '../../../queries/auth';
export default {
	created() {
		this.$apollo.queries.me.refetch()
		this.$apollo.queries.looksAndPersonalitySetupTypes.refetch()
	},
	data: () => ({
		lTypes: null,
		ethnicity: "",
		bodyType: "",
		height: "",
		hairColor: "",
		character: "",
		children: "",
		friend: "",
		pet: "",
		height_units:"ft",
		loadingUpdate: false,
		row: null,
	}),
	apollo: {
		looksAndPersonalitySetupTypes: {
			query: LOOKS_PERSONALITY_TYPES,
			result({ data: { looksAndPersonalitySetupTypes } }) {
				this.lTypes = looksAndPersonalitySetupTypes
			},
			error(e) {
				console.error(e)
			}
		},
		me: {
			query: LOGGEDIN_DATA,
			variables:{
				addr:"ASd"
			},
			result({ data: { me: { user } } }) {
				this.ethnicity = user.looks.ethnicity
				this.bodyType = user.looks.body_type
				this.height = user.looks.height
				this.hairColor = user.looks.hair_color
				this.character = user.personality.character
				this.children = user.personality.children
				this.friend = user.personality.friends
				this.pet = user.personality.pets
				this.height_units = user.looks.height_units ? user.looks.height_units  : 'ft'
			},
			error(e) {
				console.error(e)
			}
		}
	},
	methods: {
		updateUserLooksDetail() {
			let updateInputs = {
				ethnicity: this.ethnicity,
				bodyType: this.bodyType,
				hairColor: this.hairColor,
				character: this.character,
				children: this.children,
				friends: this.friend,
				pets: this.pet,
				height:this.height,
				height_units:this.height_units
			}
			Object.keys(updateInputs).forEach((e) => {
				if (updateInputs[e] === "") {
					delete (updateInputs[e])
				}
			})

			this.loadingUpdate = true
			console.log(updateInputs)
			this.$apollo.mutate({
				mutation: UPDATE_USER_LOOKS,
				variables: {
					input: updateInputs
				}
			}).then(({ data }) => {
				console.log(data)
			}).finally(() => {
				this.loadingUpdate = false
			})
		},
	}
};
</script>