<template>
  <!-- <LoginIndex/> -->
  <!-- <MatchesIndex/> -->
  <LandingIndex/>
</template>
<script>
import LandingIndex from '@/templates/Landing/Index.vue'
export default {
  components:{
    LandingIndex
  }
}
</script>