<template>
	<div class="text-left text-gray-800" v-on-clickaway="closeMenu">
		<div
			class="
				right-0
				shadow-lg
				text-sm
				overflow-hidden
				border-none
				z-20
			"
		>
			<div
				class=" dark:bg-gray-700 shadow-xs"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<center v-if="loadingNotification">
					<div class="mt-14 lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</center>
				<div v-else>
					<ul
						class=" overflow-y-auto "
						v-if="notifications.length > 0"
					>
						<li
							v-for="(notice, idx) in notifications"
							:key="idx"
							@click="toLink(notice)"
							class="
								flex
								justify-between
								items-center
								bg-pink-50 glass border-b-4 border-pink-200 hover:bg-pink-900  rounded-2xl
								dark:bg-gray-500 hover:text-white
								mt-2
								p-2 pz-
                             shadow-lg
								hover:shadow-2xl
								
								cursor-pointer
								transition
							"
						>
							<div class="flex ml-2">
								<div class="flex-none">
									<div class="avatar h-8 w-8">
										<img
											:src="getURL(notice.ref_user.profilePic)"
											width="40"
											height="40"
											class="rounded-full"
										/>
									</div>
								</div>
								<!-- {{ notice.message }} -->
								<div class="flex flex-col ml-2 mt-0">
									<span
										class="font-sans text-sm mt-1 "
										:class="{ 'font-sans': !notice.read }"
										>{{ notice.message }}</span
									>
								</div>
							</div>
							<div class="flex flex-col items-center">
								<span class="text-gray-300"></span>
								<i class="fa fa-star text-green-400"></i>
							</div>
						</li>
					</ul>
					<ul v-else class="overflow-y-scroll max-h-96">
						<li>
							<div
								class="font-sans text-center text-lg my-10 dark:text-gray-200"
							>
								{{$t('Youdonthaveanynotifications')}}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- <div class="h-12 mx-3">
			<span
				v-if="$store.state.core.notifiationCount > 0"
				class="
					absolute
					top-3
					right-3
					inline-flex
					items-center
					justify-center
					px-2
					py-1
					text-xs
					font-bold
					leading-none
					text-red-100
					transform
					translate-x-1/2
					-translate-y-1/2
					bg-red-600
					rounded-full
				"
				>{{ $store.state.core.notifiationCount }}</span
			>
		</div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
		</transition> -->
	</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { getURL } from "../utils/helpers";
import { logout } from "../utils/basicAuth";
import { NOTIFICATIONS } from '../queries/matches';
export default {
	mixins: [clickaway],
	data: () => ({
		notifications: [],
		label: "First label",
		loadingNotification: false,
	}),
	apollo: {
		notifications: {
			query: NOTIFICATIONS,
			result({ data: { notifications } }) {
				this.notifications = notifications;
				this.loadingNotification = false
				setTimeout(() => {
					// Empty notificaiton count
					this.$store.commit("changeNotificationCount", 0)
				}, 3000);
			}
		}
	},
	methods: {
		logout,
		getURL,
		toLink(notice) {
			if (notice.sparoute) {
				const finalLink = notice.link == "/chat" ? `/chat?dm=${notice.ref_user.id}` : notice.link
				this.$router.push(finalLink)
			} else {
				window.location.assign(notice.link)
			}
			// this.$router.push(link)
		},
		closeMenu() {
			this.isMenuOpen = false;
		},
	},
	computed: {
		notificationObject() {
			return this.$store.getters.notificationObject
		}
	},
	watch: {
		notificationObject(value) {
			const freshNots = [value].concat(this.notifications)
			this.notifications = freshNots;
		}
	}
};
</script>