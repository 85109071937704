<template>
  <ModelMediaComponent />
</template>
<script>
import ModelMediaComponent from "../components/valentinegallery/ModelMediaComponent.vue";
export default {
  components: {
    ModelMediaComponent,
  },
};
</script>
