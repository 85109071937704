<template>
	<div class="mx-auto">
		<div class="flex flex-col max-w-sm ">
			<div class="flex md:flex-row md:gap-3 p-0 pl-1 mx-auto cursor-pointer  bg-transparent dark:bg-gray-600 items-center rounded-lg"
				@click="$router.push('/general-settings')">
				<div class="flex flex-col w-58  items-center pb-10 ">
					<div class="flex flex-col items-center rounded-xl  pb-10 px-5 py-5">
						<img class="mb-3 w-44 border-4 h-44 broder-2 border-white rounded-full shadow-lg"
							@click="isMenuOpen = !isMenuOpen" :src="getURL($store.state.core.user.profilePic)"
							alt="Bonnie image">
						<h3 class="mb-1 text-2xl font-medium font-sans text-gray-900 dark:text-white">{{
							$store.state.core.user.base_profile.firstName }}
							{{ $store.state.core.user.base_profile.lastName }}</h3>
						<span class="text-xl text-green-500 font-bold dark:text-gray-400">Active Now</span>
						<span class="text-sm text-gray-500 dark:text-gray-400">@{{ $store.state.core.user.username }}</span>

					</div>



					<!-- <img class="mb-3 w-36 h-36 mt-10 rounded-full shadow-lg" @click="isMenuOpen = !isMenuOpen"
				:src="getURL($store.state.core.user.profilePic)" alt="Bonnie image">
                <h3 class="mb-1 text-xl font-medium text-gray-900 dark:text-white font-bold">{{ $store.state.core.user.base_profile.firstName }}
						{{ $store.state.core.user.base_profile.lastName }}</h3>
                <span class="text-sm text-gray-500 dark:text-gray-400">Active Now</span>
                <span class="text-sm text-gray-500 dark:text-gray-400">@{{ $store.state.core.user.username }}</span> -->

				</div>

				<!-- 
				<div class="avatar  h-16 w-16" @click="isMenuOpen = !isMenuOpen">
					<img
						class="rounded-full border-2 border-pink-600 mr-3"
						:src="getURL($store.state.core.user.profilePic)"
					/>
				</div> -->
				<!-- <div class="flex flex-col md:text-left mt-3 mx-2">
					<div class="font-bold text-lg font-sans text-gray-800 dark:text-white">
						{{ $store.state.core.user.base_profile.firstName }}
						{{ $store.state.core.user.base_profile.lastName }}
					</div>
					<div class="text-gray-500 mb-3 whitespace-nowrap font-sans ">
						<span class="text-pink-500 text-sm font-sans font-semi-bold rounded-md px-1">Active Now</span>
						<br><span class="bg-pink-500 font-sans text-sm text-white rounded-md px-1">@{{ $store.state.core.user.username }}</span>
					</div>
					
					<img class="w-20" src="@/assets/infinity_logo.png" v-if="$store.state.core.user.activePackage"/>
					<div
						class="
							flex flex-row
							gap-4
							text-gray-800
							my-auto
							text-2xl
							mx-auto
							md:mx-0
						"
					>
						<a class="hover:cursor-pointer hover:text-blue-500"
							><i class="fab fa-linkedin"></i
						></a>
						<a class="hover:cursor-pointer hover:text-blue-500"
							><i class="fab fa-facebook-square"></i
						></a>
						<a class="hover:cursor-pointer hover:text-blue-500"
							><i class="fab fa-github-square"></i
						></a>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
import { getURL } from "@/utils/helpers";

export default {
	methods: {
		getURL,
	},
};
</script>